import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ColDefsService } from 'src/app/services/mailbox/get-ColDefsService.service';
import { InboxService } from 'src/app/services/inbox.service';
import { UserDataService } from "src/app/services/user-data.service";
import { QmaConstant } from "src/app/constant/qma-constant";
import { GridOperationService } from "src/app/services/mailbox/grid-operation.service";
import { OverlayPanel } from 'primeng/overlaypanel';
import { AppUtils } from 'src/app/common/utility/appUtil'
import { InboxViewComponent } from "./inboxView.component";
import { DateFormatPipe } from 'src/app/common/pipes/date-format.pipe';
import * as moment from 'moment';
import { TabDataService } from '../../services/tab-data.service';
import { DragGripCellRenderer } from "./drag-grip-cell-renderer.component";
import { GridUpdateService } from 'src/app/services/mailbox/mailbox-grid-update.service';
import { ContextMenuService } from "../../services/contextMenu/context-menu.service";
import { Subscription } from "rxjs";
import { RequestCacheService } from 'src/app/services/cache/request-cache.service';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { Module, GridOptions } from '@ag-grid-community/core';
import { WebsocketService } from '../../services/websocket/websocket.service';
import * as $ from 'jquery';
import { MicrofrontendInboxService } from 'src/app/microfrontend-inbox/microfrontend-inbox.service';
@Component({
    selector: 'app-detail-cell-renderer',
    styleUrls: ['./inboxView.component.scss'],
    template: `
    <div class="full-width-panel">
        <!-- <div class="full-width-details">
            <div class="qma-show-secondary-clmns-container" style="padding: 5px 0px 21px 10px; box-sizing: border-box;">
                <div style="height:10px;" (click)="opSecondaryColumns.show($event)">
                    <img src="assets/Inbox/eye.svg" class="qma-show-secondary-clmns-eye-img">
                        Show secondary inquiry data
                </div>
                <div ref="eDetailGrid" style="height: 90%;"></div>
            </div>
        </div> -->
        <ag-grid-angular
            #agGrid
            id="convhistory"
            style="width: 100%;"
            [style.height.px]="height"
            class="ag-theme-balham conversation-grid mailbox-grid-tyle context-menu-style-override"
            [allowContextMenuWithControlKey]="true"
            [columnDefs]="colDefs"
            [rowData] = "rowData"
            [headerHeight] = 0
            [getRowHeight] = getRowHeight
            [allowContextMenuWithControlKey] = true
            [popupParent]="popupParent"
            [suppressMenuHide] = true
            [rowSelection]="rowSelection"
            (gridReady)="onGridReady($event)"
            [getContextMenuItems]="getContextMenuItems"
            [rowClassRules]="rowClassRules"
            (rowClicked)= "onRowClicked($event)"
            [modules]="modules"
            [suppressNoRowsOverlay] = true
            [suppressHorizontalScroll] = true
            [suppressPropertyNamesCheck] =true
            (rowDataChanged)="rowDataChanged($event)"
            [defaultColDef]="defaultColDef"
            [gridOptions]="gridOptions"
        >
        </ag-grid-angular>
    </div>
    <!-- <p-overlayPanel #opSecondaryColumns  [appendTo]="'body'" [styleClass]="'qma-secondary-overlay'">
    <div class="qma-secondary-data-header-container">
        <div class="qma-secondary-data-header">
            INQUIRY SECONDARY DATA
        </div>
        <div class="qma-secondary-data-close">
            <img [src]="'assets/Inbox/close_secondary_columns.svg'" (click)="opSecondaryColumns.hide()" class="qma-secondary-close-icon">
        </div>
    </div>
    <div class="grid-text container-fluid">
        <div class="row">
            <div class="col-3 secondary-row-min-height" *ngFor="let secondaryMenu of secondaryMenu">
                <div pTooltip="{{secondaryMenu.headerName}}" tooltipPosition="bottom" class="grid-detail-header">
                    {{secondaryMenu.headerName}}
                    </div>
                <div pTooltip="{{secondaryMenu.field}}" tooltipPosition="bottom" class="grid-detail-data">
                    {{secondaryMenu.field}}
                </div>
            </div>
        </div>
    </div>
    </p-overlayPanel> -->
    `
})
export class DetailCellRenderer implements OnDestroy, ICellRendererAngularComp {
    modules: Module[] = [ClientSideRowModelModule];

    // C153176-4456: cache of opened conversation histories, aged with periodic timer
    private static cacheMap: any = {};
    private static cacheMapCleanupTimer: any;
    private static CACHE_TIMER_INTERVAL: number = 300000; // 5-minute
    private static CACHE_TTL_MS = 3600*1000; // 1-hour
    private cacheMatch = {
        NOT_FOUND: 0,
        PARTIAL: 1,
        EXACT: 2
    };

    public params: any;
    public masterGridApi;
    public masterRowIndex;
    public selectedColumns = [];
    public colDefs = [];
    public rowData = [];
    public popupParent: any;
    public rowSelection: string = 'single';
    public secondaryMenuData = [];
    public secondaryMenu = [];
    public childInqueryarr = new Array();
    public guserPrefDateFormat: string = '';
    public dateFormatPipe: DateFormatPipe;
    public rowClassRules: any;
    public loginUserInfo: any;
    public currentTab: string;
    public dateFormatterUnixMap = new Map<string, string>();
    public format: string;
    public clickedIcon: string;
    public height = 200;
    public gridApi: any;
    public previousSelectedConv = [];
    public selectedConv = [];
    private reDrawSubs: Subscription;
    private convContextSubs: Subscription;
    private allConvReadUnreadSubs: Subscription;
    private inqSelection: Subscription;
    private skippedSelectedNode: any;
    // websocket subscription
    private webSocketSubscription: Subscription;
    // keys to ignore for ws update of inq
    private wsKeysToIgnore = ["action",	"approvalBypassed",	"attachments",	"audFlag",	"bubbleContent",	"content",	
                             "crtBy",	"crtDate",	"id",	"inActive",	"inquiryId",	"isS3Migrated",	"latestMsgRef",	"memo",	
                             "modBy",	"modDate",	"nominatedRecipients",	"parentConversationId",	"readBy",	"receiptDate",	
                             "recipients",	"sentDate",	"subject",	"suggestionAvailable",	"suggestionStatus",	"urgentFlag",	
                             "version"];
    private isWebSocketRedesignEnable = false;
    public gridOptions: any;
    defaultColDef = {
        cellStyle: function (params) {
            // C170665-260 AG Grid Issues
            // algin detail grid with parent grid
            if (!params.context) {
              return {
                marginLeft: '12px',
              };
            }
          },
        resizable: true,
    };
    conversationIdforInquiry: any[];
    constructor(
        private colDefsService: ColDefsService,
        private inboxService: InboxService,
        private userDataService: UserDataService,
        private gridOprService: GridOperationService,
        private tabDataService: TabDataService,
        private gridUpdateService: GridUpdateService,
        private contextMenuService: ContextMenuService,
        private requestCacheService: RequestCacheService,
        private wsService: WebsocketService,
        private micrfrontendInboxService : MicrofrontendInboxService,
    ) {
        this.dateFormatPipe = new DateFormatPipe();
        this.dateFormatterUnixMap.set('dd/MMM/yy h:mm:ss tt', 'DD/MMM/YY h:mm:ss A');
        this.dateFormatterUnixMap.set('M/dd/yyyy h:mm:ss tt', 'M/DD/YYYY h:mm:ss A');
        this.dateFormatterUnixMap.set('dd/M/yyyy h:mm:ss tt', 'DD/M/YYYY h:mm:ss A');
        this.dateFormatterUnixMap.set('dd/M/yyyy hh:mm:ss', 'DD/M/YYYY hh:mm:ss');
        this.dateFormatterUnixMap.set('dd Month', 'DD MMMM');
        this.dateFormatterUnixMap.set('dd Mmm yy hh:mm tt', 'DD MMM YY hh:mm A');
        this.gridOptions = <GridOptions>{
            navigateToNextCell: (params) => {
                let suggestedNextCell = params.nextCellPosition;
                let KEY_UP : any = 38;
                let KEY_DOWN : any = 40;
                switch (params.key) {
                    case KEY_DOWN: {
                        let previousCell = params.previousCellPosition;
                        let lastRowIndex = this.gridApi.rowModel.rowsToDisplay.length - 1;
                        if (previousCell.rowIndex !== lastRowIndex) {
                            // set selected cell on current cell + 1
                            this.gridApi.forEachNode((node) => {
                                if (previousCell.rowIndex + 1 === node.rowIndex) {
                                    // node.setSelected(true,true);
                                    this.onRowClicked(node, true);
                                } else {
                                    node.setSelected(false);
                                }
                    })
                       
                    }
                    break;
                }
                  case KEY_UP: {
                    let previousCell = params.previousCellPosition;
                    // set selected cell on current cell - 1
                    if (params.previousCellPosition.rowIndex !== 0) {
                      this.gridApi.forEachNode((node) => {
                        if (previousCell.rowIndex - 1 === node.rowIndex) {
                          node.setSelected(true);
                          this.onRowClicked(node, true);
                        } else {
                          node.setSelected(false);
                        }
                      });
                    }
                    break;
                  }
                }
                return suggestedNextCell;
              }
        };
    }
    ngOnInit() {
        
        this.currentTab = AppUtils.getCurrentTab().toUpperCase();
        this.colDefs = this.colDefsService.getStoredArrColDefs(this.currentTab);
        console.log('this.colDefs: ', this.colDefs);
         // C170665-103 Email Icon is cropped in intensity heatmap graphs
        // remove symphony column from inner grid if not in parent grid
        this.colDefs.splice(this.colDefs.findIndex(col => col.field === "cheveron"), 1)
        if (!this.params.context.componentParent.gridApi.getAllDisplayedColumns().some(c=>c.colId === "isSymphonyChatGridCol")) {
            if (this.colDefs) {
                this.colDefs.splice(this.colDefs.findIndex(col => col.field === "isSymphonyChatGridCol"), 1)
            }
        }
        if (!this.colDefs) {
            return;
        }
        this.colDefs.forEach((col, key) => {
            if (col.field && col.field != '') {
                if (col.field.indexOf(".$date") != -1) {
                    let colArr = col.field.split('.');
                    col.field = colArr[0];
                    col.tooltipField = colArr[0];
                }
                if (col.field == "expander") {
                    col.cellClass = "qma-mailbox-cell"
                    col.cellRenderer = ""
                    col.enableRowGroup = true
                    col.hide = false
                    col.resizable = true
                    col.showInSecondary = false
                    col.suppressMenu = true
                    col.suppressMovable = false
                    col.cellStyle = ""
                    col.checkboxSelection = false
                    col.width = 17
                }else if (col.cellRendererFramework === DragGripCellRenderer) {
                    col.cellClass = "qma-mailbox-cell"
                    col.cellRendererFramework = ""
                    col.cellRenderer = ""
                    col.enableRowGroup = true
                    col.hide = false
                    col.resizable = false
                    col.showInSecondary = false
                    col.suppressMenu = true
                    col.suppressMovable = false
                    col.cellStyle = ""
                    col.width = 15
                }
                if (col.field == "latestUserName") {
                    
                    // col.cellStyle = {color: '#FF0000'}
                    col.cellStyle = (params) => {
                        // Access `origEmail` from the row data to determine the color
                        if (params.data && params.data.origType ) {
                            let orgType = params.data.origType.toUpperCase();
                            if(orgType==='INTERNAL'){
                                return { color: '#000F29',paddingLeft:'32px' };
                            }
                            else{
                                return { color: '#F28430',paddingLeft:'32px' }; 
                            }
                        }
                        return { color: '#F28430',paddingLeft:'32px' }; 
                        }


                        // if (params.data && params.data.latestEmail ) {
                        //     let latestEmailTrim = params.data.latestEmail.trim();
                        //     let isCitiDomain = true;
                        //     if (latestEmailTrim && latestEmailTrim.includes("@")) {
                        //         isCitiDomain = this.loginUserInfo.citiDomainList.some(x => latestEmailTrim.split('@')[1].toUpperCase().includes(x));
                        //       }
                        //       if(isCitiDomain){
                        //         return { color: '#000F29',paddingLeft:'22px' };
                        //       }
                        //     }
                        // }
                        //  return { color: '#F28430',paddingLeft:'22px' };
                         
                }
            }
        });
        this.popupParent = document.querySelector("body");
        // Jira 5652
        this.selectedColumns = this.colDefs.filter(col => col.field !== "cheveron" && col.field !== "expander"
            && col.cellRendererFramework !== DragGripCellRenderer && col.showInSecondary);

        this.selectedColumns.forEach(element => {
            // C153176-4660: add null check
            let data = (this.params && this.params.data) ? this.params.data[element.field]: undefined;           
            if (data && (typeof data === "string" || typeof data === "number")) {
                this.secondaryMenuData[element.field] = data.toString();
            }
            else if (element.field.includes('$date')) {
                data = this.params.data[element.field.replace('$date', '').replace('.', '')];
                this.secondaryMenuData[element.field] = this.retiriveDate(data);
            }
            else {
                this.secondaryMenuData[element.field] = this.retiriveDate(data);
            }
        });

        this.reDrawConvGrid();
        this.convContextMenu();
        this.markAllConvReadUnread();
        this.subscribeInquirySelection();
    }

    getContextMenuItems = (params: any) => {
        params['menu'] = 'secondaryMenu';
        params['childInqueryarr'] = this.childInqueryarr;
        //params.node.data = selectedRows;
        return this.params.context.componentParent.getContextMenuItems(params);
    }

    retiriveDate(dateField) {
        if (dateField) {
            return this.convertDate(dateField.$date, this.guserPrefDateFormat);
        }
        else {
            return "";
        }
    }

    getRowHeight = (params: any) => {
        return QmaConstant.inboxConfiguration.gridRowHeight;
    };

    // called on init
    agInit(params: any): void {
        let value = '';
        this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserData => {
            this.loginUserInfo = loginUserData;
            this.isWebSocketRedesignEnable = loginUserData.cvWebsocketConfig.isWebSocketRedesignEnable;
            if(loginUserData.cvWebsocketConfig.isWebSocketRedesignEnable) {
                // subscribe to ws Update for redesign
                this.subscribeToWebSocketUpdate();
            }
            if (loginUserData.preferences) {
                loginUserData.preferences.map(function (val) {
                    /* if (val.key === "conversationViewId") {
                      convView = val.value;
                    } */
                    if (val.key === "dateFormatterSelectId") {
                        value = val.value;
                    }
                })
            }
        });
        this.guserPrefDateFormat = value;

        this.rowClassRules = {
            //Hiding read / unread in conversation history, jira -4572
            "qma-inbox-row-read": (params) => {
                var isReadby = false;
                if (params && params.data && params.data.readBy && this.currentTab !== 'SENT') {
                    for (let i = 0; i < params.data.readBy.length; i++) {
                        if (this.loginUserInfo.userId == params.data.readBy[i]) {
                            isReadby = true;
                        }
                    }
                    if (isReadby) {
                        return isReadby
                    }
                } else if(this.currentTab === 'SENT'){
                    return true;
                }
            },
            "qma-inbox-row": (params) => {
                var isUnread = true;
                if (params && params.data && params.data.readBy && this.currentTab !== 'SENT') {
                    for (let i = 0; i < params.data.readBy.length; i++) {
                        if (this.loginUserInfo.userId == params.data.readBy[i]) {
                            isUnread = false;
                        }
                    }

                }
                if (isUnread &&  this.currentTab !== 'SENT') {
                    return isUnread;
                } else if(this.currentTab === 'SENT'){
                    return false;
                }
            },
            "conversation-background-color": function (params) {
                return true;
            }

        };
        let inquiryData = {
            id: params.data._id,
            selectInqAssignedGroupId: [params.data.workflows[0].assignedGroupId]
        };
        if (params && params.node && params.node.data) {
            this.conversationIdforInquiry = this.micrfrontendInboxService.getConversationIdForInquiry(params.data._id)
            // C153176-4456: first look-up cache, if there is exact match, use cache look-up, or trigger service request
            let keys = this.generateKeys(params);
            const cacheLookup = this.lookupCache(keys);
            let convCount = 1;
            if ((cacheLookup.result !== this.cacheMatch.EXACT || !cacheLookup.data) || this.isWebSocketRedesignEnable) {
                // not exact cache update, requesting service
                let requestObjectConversation  = {}
                requestObjectConversation = {
                    id: params.node.data._id,
                    selectInqAssignedGroupId: [params.node.data.workflows[0].assignedGroupId],
                    isAllConvContent: false, // C153176-4660: do not load all content for conversation history
                }
                if (this.conversationIdforInquiry && this.conversationIdforInquiry.length > 0) {
                    requestObjectConversation = {
                        id: params.node.data._id,
                        selectInqAssignedGroupId: [params.node.data.workflows[0].assignedGroupId],
                        isAllConvContent: false, // C153176-4660: do not load all content for conversation history
                        conversationIds : this.conversationIdforInquiry ? this.conversationIdforInquiry : ''
                    }
                }
                this.inboxService.getInquiryIdSelected(
                 requestObjectConversation, params.node.data).subscribe(selectedMail => {
                    // keys to Ignore for ws Update
                   /*  const copyOfConv = $.extend(true,[],Object.keys(selectedMail.conversationList[0]));
                    this.wsKeysToIgnore = $.extend(true,[],Object.keys(copyOfConv)); */
                    this.requestCacheService.updateInquiryCache(params.node.data, false, selectedMail);
                    // Merging 2 API into 1 - Jira 4456
                    // using gridrowdata inq reference
                    // this.childInqueryarr[selectedMail.inquiry.id] = selectedMail.inquiry;
                    this.childInqueryarr[selectedMail.inquiry.id] = params.node.data;
                    selectedMail.conversationList.forEach((element, key) => {
                        // fix for C153176-5175 Show secondary Data issue
                        selectedMail.conversationList[key]['_id'] = selectedMail.conversationList[key]['inquiryId'];
                        selectedMail.conversationList[key]['urgentFlag'] = 'N';  

                        // C153176-5523 | NLP icon should popuate from converation list NLP status
                        let suggestionIndicator = "";
                        if (selectedMail.conversationList[key]['suggestionAvailable']) {
                            let suggestionStatus = selectedMail.conversationList[key]['suggestionStatus'];
                            switch (suggestionStatus) {
                                case 'red': {
                                    suggestionIndicator = "R";
                                    break;
                                }
                                case 'orange': {
                                    suggestionIndicator = "O";
                                    break;
                                }
                                case 'green': {
                                    suggestionIndicator = "G";
                                    break;
                                }
                                case 'black': {
                                    suggestionIndicator = "B";
                                    break;
                                }
                                default: {
                                    suggestionIndicator = "";
                                    break;
                                }
                            }
                        }
                        selectedMail.conversationList[key]['suggestionIndicator'] = suggestionIndicator;
                        // replace getAllconv inqiory with getgridviewData inq other than notes and audit
                        let convGridInq = $.extend(true, {},params.data);//deep copy of inquiry
                        convGridInq.userNotes = selectedMail.inquiry.userNotes;
                        convGridInq.workflowAudit = selectedMail.inquiry.workflowAudit;

                        for (var objKey in convGridInq) {
                            // C153176-4660: nlp icon displayed in conversation as per Abhijeet mail dated on 10/01/2020                             
                            // if(params.node.data.suggestionIndicator){
                            //     selectedMail.conversationList[key]['suggestionIndicator'] = params.node.data.suggestionIndicator;
                            // }  
                            
                            /* selectedMail.conversationList[key]['latestUserName'] = element.recipients[0].displayName; */
                            // C153176-5596 Email sender name is not consistent
                            selectedMail.conversationList[key]['latestUserName'] = this.isNull(element.recipients[0].displayUserName)?element.recipients[0].displayName:element.recipients[0].displayUserName;
                            if (selectedMail.conversationList[key] && !selectedMail.conversationList[key].hasOwnProperty(objKey)) {
                                selectedMail.conversationList[key][objKey] =
                                  (convGridInq[objKey] == null) ? '' : convGridInq[objKey];
                                if (objKey === 'conversCount' || objKey === 'convCount') {
                                    selectedMail.conversationList[key]['convCount'] = selectedMail.conversationList.length;
                                } /* else if (objKey === 'openGroups') {
                                    selectedMail.conversationList[key][objKey] =
                                      //this.filterOpenGroups(objKey, selectedMail.inquiry,params.node.data.workflows[0].assignedGroupId);
                                } else if (objKey === 'openUsers') {
                                    selectedMail.conversationList[key][objKey] =
                                      this.filterOpenUsers(objKey, selectedMail.inquiry,params.node.data.workflows[0].assignedGroupId);
                                }  */                            
                            }
                        }
                        selectedMail.conversationList[key] = this.dateFormatGrid(selectedMail.conversationList[key]); // Function rename
                        selectedMail.conversationList[key]['assignedGroupId'] = params.data.workflows[0].assignedGroupId;
                        selectedMail.conversationList[key]['attchFlag'] = this.getAttachFlag(selectedMail.conversationList[key], params.data);
                    });
                    // always set conversation list length to 'convCount'
                    convCount = selectedMail.conversationList.length;
                    if (convCount > 0 && params.data) {
                        params.data.convCount = selectedMail.conversationList.length;
                    }
                    this.height = this.getHeight(convCount);
                    this.rowData = selectedMail.conversationList;
                    if (params.node.expanded !== false) { // important to use '!==' instead of '!='
                        this.colDefsService.setOpenConvData({ inquiry: params.node.data, convRowData: this.rowData, tabName: this.currentTab});
                        // C153176-4456: add 'expanded' field on params.data (inquiry) and cache the item on component
                        params.node.expanded = true;
                        this.handleSelectedConv(params.node.data, params.node.data.openGroups, [params.data.workflows[0].assignedGroupId]);
                        if (params.context && params.context.componentParent) {
                            params.context.componentParent.setOpenedConvHistInquiry({ node: params.node, data: params.node.data});
                        }
                    } else {
                        // C153176-4456: triggering closing of this Conv History. This is needed because Ag-grid
                        // instantiates this cell renderer a second time, needlessly, when app requests its closing actually.
                        this.colDefsService.setCloseConvData(params.node.data);
                        params.node.expanded = undefined;
                        // do not update params.node.data.expanded, let ngDestroy do
                    }
                    this.updateCache(keys);
                   
                });
            }
            if (cacheLookup.result !== this.cacheMatch.NOT_FOUND && cacheLookup.data) {
                // exact or less exact cache lookup, use the cache and send open/close notif so that other conv closes
                let v = cacheLookup.data;
                this.rowData = v.rowData;
                if (params.node.data && params.node.data.convCount > 0) {
                    this.height = this.getHeight(params.node.data.convCount);
                } else {
                    this.height = v.height;
                }
                this.childInqueryarr = v.childInqueryarr;
               // C153176-6030 Focus lost -Expand conversation history and reply
              /*   setTimeout(() => { */
                    if (params.node.expanded !== false) { // important to use '!==' instead of '!='
                        // C153176-4456: add 'expanded' field on params.data (inquiry) and cache the item on component
                        params.node.expanded = true;
                        this.colDefsService.setOpenConvData({ inquiry: params.node.data, convRowData: this.rowData, tabName: this.currentTab});
                        this.handleSelectedConv(params.node.data, params.node.data.openGroups, [params.data.workflows[0].assignedGroupId]);
                    } else {
                        // C153176-4456: triggering closing of this Conv History. This is needed because Ag-grid
                        // instantiates this cell renderer a second time, needlessly, when app requests its closing actually.
                        this.colDefsService.setCloseConvData(params.node.data);
                        params.node.expanded = undefined;
                        
                        // do not update params.node.data.expanded, let ngDestroy do
                    }
               /*  }, 0); */
            }
            this.params = params;
            this.masterGridApi = params.api;
            // reset detailgrid row height
            // [C170665-1884] Websocket: Conv history scroll issue impacting visibility of first conversation
            // to reset height of conversation panel whenever new websocket update comes in so all conv will be visible in inner grid.
            if (this.masterGridApi.resetRowHeights) { // null check for resetRowHeights
                setTimeout(() => {// set time out to make sure grid is rendered before resetting its height.
                    // set orignal height to null for detail or inner grid
                    this.masterGridApi.forEachNode(row => row.detailNode && row.detailNode.setRowHeight(null));
                    // reset grid height
                    this.masterGridApi.resetRowHeights();   
                }, 300); // this is asynch operation so will not affect rendering or cause any delay in rendering.
            }
            this.masterRowIndex = params.rowIndex;
            // C153176-4456: add 'expanded' field on params.data (inquiry) and cache the item on component
            if (params.node.expanded !== false) { // important to use '!==' instead of '!='
                params.node.data.expanded = true;
                if (params.context && params.context.componentParent) {
                    params.context.componentParent.setOpenedConvHistInquiry({ node: params.node, data: params.node.data, setrefcount: true});
                }
            }
        }
    }
    /*To adjust conversation history panel height */
    getHeight(convCount) {
        // C153176-4660: conversation history vertical scrollbar issue - height adjust
        //return (QmaConstant.inboxConfiguration.detailRowHeight + (convCount * 30) > 210) ? 200 : 20 + (convCount * 30);
        // C153176-5191: refactor with constants
        return 5 + ((convCount * QmaConstant.inboxConfiguration.gridRowHeight > 180) ? 180 : (convCount * QmaConstant.inboxConfiguration.gridRowHeight));
    }

    onGridReady(params) {
        this.gridApi = params.api;
        var detailGridId = "detail_" + this.masterRowIndex;

        var gridInfo = {
            id: detailGridId,
            api: params.api,
            columnApi: params.columnApi
        };
        let key = 0;
        this.selectedColumns.forEach((element) => {
            if (element.field !== 'cheveron_1') {
                this.secondaryMenu[key] = { headerName: element.headerName, field: this.secondaryMenuData[element.field] };
                key++;
            }
        });
        if (this.masterGridApi) {
            this.masterGridApi.addDetailGridInfo(detailGridId, gridInfo);
        }
        // C153176-4660: enforce conv selection
        setTimeout(()=> {
            this.handleSelectedConv(this.params.node.data, 
                this.params.node.data.openGroups, 
                [this.params.data.workflows[0].assignedGroupId]);
        }, 100);
    }

    // called when the cell is refreshed
    refresh(params: any): boolean {
        return false;
    }

    // function rename
    dateFormatGrid(inq) {
        //To Format date as per Profile Settings
        if (inq.crtDate) {
            if (inq.crtDate.$date) {
                inq.crtDate.$date = Object(this.dateFormatPipe.transform(inq.crtDate.$date, this.guserPrefDateFormat));
            } else {
                inq.crtDate = this.convertDate(inq.crtDate, this.guserPrefDateFormat);
            }
        }
        if (inq.modDate) {
            if (inq.modDate.$date) {
                inq.modDate.$date = Object(this.dateFormatPipe.transform(inq.modDate.$date, this.guserPrefDateFormat));
            } else {
                inq.modDate = this.convertDate(inq.modDate, this.guserPrefDateFormat);
            }
        }
        if (inq.reAgeDate) {
            if (inq.reAgeDate.$date) {
                inq.reAgeDate.$date = Object(this.dateFormatPipe.transform(inq.reAgeDate.$date, this.guserPrefDateFormat));
            } else {
                inq.reAgeDate = this.convertDate(inq.reAgeDate, this.guserPrefDateFormat);
            }
        }
        if (inq.lockedDate) {
            if (inq.lockedDate.$date) {
                inq.lockedDate.$date = Object(this.dateFormatPipe.transform(inq.lockedDate.$date, this.guserPrefDateFormat));
            } else {
                inq.lockedDate = this.convertDate(inq.lockedDate, this.guserPrefDateFormat);
            }
        }
        if (inq.lastActionTime) {
            if (inq.lastActionTime.$date) {
                inq.lastActionTime.$date = Object(this.dateFormatPipe.transform(inq.lastActionTime.$date, this.guserPrefDateFormat));
            } else {
                inq.lastActionTime = this.convertDate(inq.lastActionTime, this.guserPrefDateFormat);
            }
        }
        if (inq.reOpenDate) {
            if (inq.reOpenDate.$date) {
                inq.reOpenDate.$date = Object(this.dateFormatPipe.transform(inq.reOpenDate.$date, this.guserPrefDateFormat));
            } else {
                inq.reOpenDate = this.convertDate(inq.reOpenDate, this.guserPrefDateFormat);
            }
        }
        return inq;
    }

    convertDate(unixDate, dateFormat) {
        this.dateFormatterUnixMap.forEach((value: string, key: string) => {
            if (key === dateFormat) {
                this.format = value;
            }
        });
        return moment(unixDate).format(this.format) === "Invalid date" ? unixDate : moment(unixDate).format(this.format);
    }

    /**
     * C153176-4660: handle selected conversation to avoid flickering of its details view 
     */
    handleSelectedConv(inquiry, assignedGroupName, assignedGroupId) {
        let tabName = AppUtils.getCurrentTab();
        let inquiryMatched = this.gridOprService.matchSelectedConversation(false, tabName,
            inquiry, assignedGroupName, assignedGroupId);
        if (inquiryMatched) {
            let matchedConv = this.gridOprService.getSelectedConversation(tabName);
            if (matchedConv && matchedConv.convId && this.gridApi) {
                this.gridApi.forEachNode((node, index) => {
                    if (node && node.data && node.data.id === matchedConv.convId) {
                        this.skippedSelectedNode = node;
                        node.setSelected(true, true);
                    }
                });
            }
        }
    }

    /**
     * C153176-5317: select the right assigned group
     */
    findMatchingWorkflow(conv) {
        if (!conv || !conv.workflows || !conv.workflows.length) {
            return null;
        }
        if (!conv || !conv.openGroups) {
            return conv.workflows[0];
        }
        let groups = conv.openGroups.split(';');
        let workflows = conv.workflows.filter(wf => groups.some(grp => grp === wf.assignedGroupName));
        if (workflows && workflows.length) {
            return workflows[0];
        } else {
            return conv.workflows[0];
        }
    }

    onRowClicked(event, isKeyboardNavigation?) {
        // ws redesign
        if(this.isWebSocketRedesignEnable) {
            if(this.gridApi) {
                this.gridApi.forEachNode((node) => {
                    if (node.data.id === event.data.id) {
                        node.setSelected(true,true);
                    }
             });
            }
        }
        let tabName = AppUtils.getCurrentTab();
        // C153176-5317: identify the right workflow to set assigned group id/name
        let matchedWorkflow = this.findMatchingWorkflow(event.data);
        let selectedInqConv = {
            "assignedGrpId": [matchedWorkflow? matchedWorkflow.assignedGroupId: event.data.workflows[0].assignedGroupId],
            "selectedConvId": event.data.id,
            "selectedInqId": event.data.inquiryId,
            "currentTab": this.currentTab,
            "assignedGroup": matchedWorkflow? matchedWorkflow.assignedGroupName: event.data.workflows[0].assignedGroupName,
            "workflow": matchedWorkflow? matchedWorkflow: event.data.workflows[0],
            // C153176-4660: add 'inquiry' field as it is needed by conversation view
            "inquiry": (this.params && this.params.node) ? this.params.node.data : undefined
        };
        // C170665-1282 Navigation : Extend Keyboard shortcut option
        selectedInqConv.inquiry.isKeyboardNavigation = isKeyboardNavigation;
        // C153176-5948 | Add Workflow audit to inquiry
        if (event.data && event.data.workflowAudit) {
            selectedInqConv.inquiry.workflowAudit = event.data.workflowAudit;
        }

        sessionStorage.setItem("selectedConv_" + selectedInqConv.currentTab, JSON.stringify(selectedInqConv));
        this.gridOprService.setSelectedConversation(tabName, 
            event.data.inquiryId,
            event.data.id,
            event.data.openGroups ? event.data.openGroups: event.data.workflows[0].assignedGroupName,
            event.data.workflows.map(wf => wf.assignedGroupId));

        let selectedInqConvArr = [];
        selectedInqConvArr.push(selectedInqConv);
        this.gridOprService.setConversation(selectedInqConvArr);
        this.skippedSelectedNode = undefined;
        this.onSelectionChanged(event);
    }

    reDrawConvGrid() {
        this.reDrawSubs = this.colDefsService.getConvRow().subscribe(conversationNode => {
            if (Object.entries(conversationNode).length > 0 && this.gridApi) {
                conversationNode.forEach(conv => {
                    this.rowData.forEach((rData, key) => {
                        if (rData.id === conv.id) {
                            this.rowData[key].read = conv.readBy;
                        }
                    })
                })
                this.gridApi.redrawRows();
                //this.gridApi.redrawRows({rowNodes: conversationNode});
                //
            }
        })
    }

    convContextMenu() {
        this.convContextSubs = this.gridUpdateService.getReadUnreadConvInqIds().subscribe(conversation => {
            if (Object.entries(conversation).length > 0) {
                if (conversation.readStatus) {
                    this.markConvRead(conversation);
                } else {
                    this.markConvUnread(conversation);
                }

            }
        });
    }

    markConvRead(conversation) {
        // C153176-4669: skip checking 'this.gridApi' here so that the readBy is updated in rowData
        if (Object.entries(conversation).length > 0) {
            if (conversation.data) {
                let readBy = new Array();
                let readInqIdList = new Array();
                let convIds = [];
                conversation.data.forEach(conv => {
                    this.rowData.forEach((rData, key) => {
                        if (rData.id === conv.id) {
                            if (rData.readBy) {
                                // C153176-4669: avoid duplication of readBy elements
                                if (rData.readBy.findIndex(r => r && r === this.loginUserInfo.userId) === -1) {
                                    rData.readBy.push(this.loginUserInfo.userId);
                                }
                            } else {
                                readBy.push(this.loginUserInfo.userId);
                                rData.readBy = readBy;
                            }
                        }
                        if (rData && rData.readBy && rData.readBy.includes(this.loginUserInfo.userId)) {
                            // C153176-4669: avoid count dups in 'readInqIdList' as its count will be used to determine whether the inquiry should be set to Read
                            if (convIds.findIndex(v => v && v === rData.id) === -1) {
                                convIds.push(rData.id);
                                readInqIdList.push({ inqId: conversation.data[0].inquiryId, agrpId: this.params.node.data.workflows[0].assignedGroupId });
                            }
                        }
                    });
                });
                if (this.gridApi) {
                    this.gridApi.redrawRows();
                    if (readInqIdList.length == this.rowData.length) {
                        // Inquery and group id updated - Jira - 4456
                        this.gridUpdateService.setreadInqIds([{ inqId: conversation.data[0].inquiryId, 
                            // null check
                            agrpId: (this.params.node && this.params.node.data && this.params.node.data.workflows && this.params.node.data.workflows.length) ? 
                              this.params.node.data.workflows[0].assignedGroupId : undefined, status: 'conversation' }]);
                    }
                }
            }
        }
        return;
    }

    markConvUnread(conversation) {
        // C153176-4669: skip checking 'this.gridApi' here so that the readBy is updated in rowData
        if (Object.entries(conversation).length > 0) {
            if (conversation.data) {
                let inquiryIndex;
                let unreadInqIdList = new Array();
                conversation.data.forEach(conv => {
                    // C153176-4669: act on the conversation which matches the incoming one, by matching conv id, not the inquiryId
                    this.rowData.forEach(rData => {
                        if (rData.id === conv.id) {
                            let readById = this.loginUserInfo.userId;
                            let readyBy = rData.readBy ? rData.readBy.filter(d => d !== readById) : undefined;
                            // assign new readBy array
                            rData.readBy = [];
                            rData.readBy = readyBy;
                            // null check
                            let groupId = (this.params.node && this.params.node.data && 
                              this.params.node.data.workflows && this.params.node.data.workflows.length) ?
                              this.params.node.data.workflows[0].assignedGroupId : undefined;
                            // C153176-4669: avoid dup entry in 'unreadInqIdList'
                            if (unreadInqIdList.findIndex(em => em && em.inqId === rData.inquiryId && em.agrpId === groupId) === -1) {
                                unreadInqIdList.push({ inqId: rData.inquiryId, agrpId: groupId });
                            }
                        }
                    });
                });
                if (this.gridApi) {
                    this.gridApi.redrawRows();
                    this.gridUpdateService.setunreadInqIds(unreadInqIdList);
                }
            }
        }
        return;
    }

    onSelectionChanged(params) {
        if(!this.gridApi) {
            return;
        }
        let rowNodes = this.gridApi.getSelectedNodes();
        let selectedRows = this.gridApi.getSelectedRows();
        if (selectedRows.length === 1 && rowNodes.length == 1) {
            let rowIndex = rowNodes[0].rowIndex;
            // C153176-4660: check whether the node should be skipped for change notification
            if (this.skippedSelectedNode && rowIndex === this.skippedSelectedNode.rowIndex) {
                this.skippedSelectedNode = undefined;
                return;
            }
            let previousConv = this.colDefsService.getPrevConv();
            if (previousConv.length === 0 || selectedRows[0].id !== previousConv[0]['id']) {
                selectedRows[0].convRowIndex = rowIndex;
                selectedRows[0].tabName = this.currentTab;
                let currentConvData = {
                    'conv': selectedRows[0],
                    'data': this.rowData,
                    'parentGridData': this.params.node.data
                }
                this.colDefsService.setChangeConv(currentConvData);
            }
        }
    }

    markAllConvReadUnread() {
        this.allConvReadUnreadSubs = this.gridUpdateService.getReadUnreadInqIds().subscribe(conversationIds => {
            if (Object.entries(conversationIds).length > 0) {
                if (conversationIds[0].status === 'read') {
                    this.markAllConvRead(conversationIds);
                } else {
                    this.markAllConvUnRead(conversationIds);
                }
            }
        })
    }

    markAllConvRead(conversationIds) {
        // C153176-4669: skip checking 'this.gridApi' here so that the readBy is updated in rowData
        if (Object.entries(conversationIds).length > 0) {
            if (conversationIds[0].inqId) {
                let readInqIdList = new Array();
                conversationIds.forEach(conv => {
                    this.rowData.forEach((rData, key) => {
                        if (rData.inquiryId === conv.inqId) {
                            if (rData.readBy) {
                                rData.readBy.push(this.loginUserInfo.userId);
                            } else {
                                // C153176-4660: set readBy to conversation in separate array
                                let readBy = new Array();
                                readBy.push(this.loginUserInfo.userId);
                                rData.readBy = readBy;
                            }
                        }
                    });
                });
                if (this.gridApi) {
                    this.gridApi.redrawRows();
                }
            }
        }
    }

    markAllConvUnRead(conversationIds) {
        // C153176-4669: skip checking 'this.gridApi' here so that the readBy is updated in rowData
        if (Object.entries(conversationIds).length > 0) {
            if (conversationIds[0].inqId) {
                let inquiryIndex;
                conversationIds.forEach(conv => {
                    // C153176-4669: should use 'inqId' vs. 'inquiryId'
                    inquiryIndex = this.rowData.findIndex(irowData => irowData.inquiryId === conv.inqId);
                    if (inquiryIndex === -1) {
                        return;
                    }
                    let readById = this.loginUserInfo.userId;
                    let readyBy = this.rowData[inquiryIndex].readBy ?
                       this.rowData[inquiryIndex].readBy.filter(d => d !== readById) : undefined;
                    this.rowData.forEach((rData, key) => {
                        if (rData.inquiryId === conv.inqId) {
                            // C153176-4669: set readBy to conversation in separate array
                            rData.readBy = [];
                            if (readyBy && readyBy.length) {
                                rData.readBy = [...readyBy];
                            }
                        }
                    });
                });
                if (this.gridApi) {
                    this.gridApi.redrawRows();
                }
            }
        }
    }

    ngOnDestroy(): void {
        var detailGridId = "detail_" + this.masterRowIndex;

        // ag-Grid is automatically destroyed
        this.masterGridApi.removeDetailGridInfo(detailGridId);
        if (this.reDrawSubs) {
            this.reDrawSubs.unsubscribe();
        }
        if (this.convContextSubs) {
            this.convContextSubs.unsubscribe();
        }
        if (this.allConvReadUnreadSubs) {
            this.allConvReadUnreadSubs.unsubscribe();
        }
        if (this.inqSelection) {
            this.inqSelection.unsubscribe();
        }
        if (this.params && this.params.data) {
            // C153176-4456: clear 'expanded' field on params.data (inquiry) and set cached item on component
            if (this.params.context && this.params.context.componentParent) {
                if (this.params.context.componentParent.resetOpenedConvHistInquiry({ node: this.params.node, data: this.params.node.data})) {
                    // only if reset returns true will the 'data.expanded' be set to false
                    this.params.data.expanded = false;
                }
            }
        }
        if (this.isWebSocketRedesignEnable && this.webSocketSubscription) {
            this.webSocketSubscription.unsubscribe();
          }

          this.gridOprService.clearConversation();
     // remove selection fromsession when conv his collapsed
     /*    sessionStorage.setItem("selectedConv_" + this.currentTab, ""); */
    }

    /**
     * C153176-4456: generate cache keys
     */
    generateKeys(params) {
        const keys = [];
        // key1: partial match key, format: inquiryId_grpId
        let key1 = '' + params.node.data._id;
        if (params.node.data.workflows && params.node.data.workflows.length) {
            key1 += '_' + params.node.data.workflows[0].assignedGroupId;
        }
        key1 += '_' + (params.node.data.readBy && params.node.data.readBy.findIndex(r => r === this.loginUserInfo.userId) > -1);
        keys.push(key1);
        // key2: exact match key, format: rowIndex_inquiryId_grpId_<version or modDate>
        let key2 = '' + (this.masterRowIndex !== undefined? this.masterRowIndex: params.rowIndex) + '_' + key1;
        key2 += '_' + (params.node.data.version ? params.node.data.version : params.node.data.modDate.$date); // C153176-5120: restore keys
        /*
        key2 += '_' + (params.node.data.version ? params.node.data.version : '');  // C153176-5120 separate version and modDate in key
        key2 += '_' + (params.node.data.modDate ? params.node.data.modDate.$date : '');
        key2 += '_' + (params.node.data.convCount ? params.node.data.convCount : 0); // C153176-5120 add convCount
        */
        key2 += this.getLastActionTime(params.node.data);
        key2 += this.getOpenUser(params.node.data);
        keys.push(key2);
        return keys;
    }

    /**
     * C153176-4456: cache look-up
     * @return: cache look-up match result
     */
    lookupCache(keys) {
        let result = this.cacheMatch.NOT_FOUND;
        if (!keys || !keys.length) {
            return { data: undefined, result: result};
        }
        let v = DetailCellRenderer.cacheMap[keys[1]];
        if (!v) {
            v = DetailCellRenderer.cacheMap[keys[0]];
            if (v) {
                result = this.cacheMatch.PARTIAL;
            }
        } else {
            result = this.cacheMatch.EXACT;
        }
        if (v && this.isValidTimestamp(v.timestamp, Date.now())) {
            return {data: Object.assign({}, v), result: result};
        }
        return { data: undefined, result: result};
    }

    /**
     * C153176-4456: update cache
     */
    updateCache(keys) {
        if (!keys || !keys.length) {
            return;
        }
        let v = DetailCellRenderer.cacheMap[keys[1]];
        if (!v) {
            v = {};
            DetailCellRenderer.cacheMap[keys[1]] = v;
        }
        v.rowData = this.rowData;
        v.height = this.height;
        v.childInqueryarr = this.childInqueryarr;
        v.timestamp = Date.now();
        DetailCellRenderer.cacheMap[keys[0]] = v;
        if (DetailCellRenderer.cacheMapCleanupTimer === undefined) {
            DetailCellRenderer.cacheMapCleanupTimer = setInterval(()=> {
                this.cleanupCache()
            }, DetailCellRenderer.CACHE_TIMER_INTERVAL);
            console.log('agg: detail-cell-renderer timer:', DetailCellRenderer.cacheMapCleanupTimer)
        }
    }

    isValidTimestamp(t, now) {
        return t && ((now - t) < DetailCellRenderer.CACHE_TTL_MS);
    }

    /**
     * C153176-4456: cache clean-up routine
     */
    cleanupCache() {
        const now = Date.now();
        const deletedKeys = Object.keys(DetailCellRenderer.cacheMap).filter(k => DetailCellRenderer.cacheMap[k] && !this.isValidTimestamp(DetailCellRenderer.cacheMap[k].timestamp, now));
        if (deletedKeys) {
            deletedKeys.forEach(k => {
                delete DetailCellRenderer.cacheMap[k];
                DetailCellRenderer.cacheMap[k] = undefined;
            });
        }
    }

  /**
   * C153176-4668: Filter and return assigned group name based on group id
   */
  filterOpenGroups(key, inq, groupId) {
    const v = inq[key];
    // C153176-4668: check assigned group against workflow's group id even it is populated at inquiry level
    if (!inq.workflows || !inq.workflows.length) {
        return v;
    }
    let res = undefined;
    inq.workflows.forEach(wf => {
        if (wf.assignedGroupId === groupId) {
            if(!res) {
                res = wf.assignedGroupName;
            }
        }
    });
    return res ? res : v;
  }

  /**
   * C153176-4668: Filter and return assigned user  name based on group id
   */
  filterOpenUsers(key, inq, groupId) {
    const v = inq[key];
    // C153176-4668: check assigned user against workflow's group id even it is populated at inquiry level
    if (!inq.workflows || !inq.workflows.length) {
        return v;
    }
    let res = undefined;
    inq.workflows.forEach(wf => {
        if (wf.assignedGroupId === groupId) {
            if(!res) {
                res = wf.assignedUserName;
            }
        }
    });
    return res ? res : v;
  }

  getOpenUser(data) {
    let assignedTo = data.openUsers;
    //remove assigned group from assignedTo
    if (assignedTo && assignedTo.includes("[")) {
      return '_' + assignedTo.split("[")[0]
    } if (assignedTo && !assignedTo.includes("[")) {//if assigned to not contains group do nothing
      return '_' + assignedTo;
    }

    //Adding whitespace so will be availale in grp By
    if (!assignedTo) {
      return "";
    }

  }

  /**
   * C153176-4668: retrieve last action time or latest conversation time from inquiry
   */
  private getLastActionTime(data) {
    if (!data.workflows ||!data.workflows.length) {
      return '';
    }
    let res = 0;
    res = data.workflows.reduce((r, wf) => {
      let t = wf.lastActionTime ? Date.parse(wf.lastActionTime.$date) : 0;
      if (!t) {
          t = wf.latestConversationTime ? Date.parse(wf.latestConversationTime.$date): 0;
      }
      return (t && t > r) ? t : r;
    }, res);
    return '_' + res;
  }

  subscribeInquirySelection() {
    this.inqSelection = this.gridOprService.getInquirySelection().subscribe(data => {
        this.handleInquirySelection(data);
    });
  }

  /**
   * C153176-4660: deselect selected conversation history if any.
   */
  handleInquirySelection(data) {
    if (!this.gridApi || !data || !data._id) {
        return;
    }
    // deselect all the conversations if any
    const selectedNodes = this.gridApi.getSelectedNodes();
    if (selectedNodes) {
        selectedNodes.forEach(node => {
            if (node && node.isSelected()) {
                node.setSelected(false);
            }
        });
    }
  }

  /**
   * C153176-4660: determine 'attchFlag' for conversation
   */
  getAttachFlag(cvs, inq) {
    if (!cvs || !inq) {
        return undefined;
    }
    if (cvs.attchFlag === 'Y' && (!cvs.attachments || !cvs.attachments.length)) {
        // if conversation already has its 'attchFlag', keep it as is.
        return 'N';
    } else if (inq.attchFlag === 'Y' && cvs.attachments && cvs.attachments.length) {
        return 'Y';
    }
    // in other cases, return undefined
    return undefined;
  }
 // C153176-5596 Email sender name is not consistent
  // Null value check function
  isNull(input) {
    if (input === "" || input === undefined || input == null) {
      return true;
    }
    return false;
  }
 


  subscribeToWebSocketUpdate() {
    this.webSocketSubscription = this.wsService.getwebSocketUpdate().subscribe(

        (response: MessageEvent): any => {
          try {
            // add null check //Jira - C153176-3381  
            if (response.data) {
              let inqIdArray = JSON.parse(response.data);
              if (inqIdArray && inqIdArray.wsInquiryList && inqIdArray.wsInquiryList.length > 0) {
                console.log("Processing websocket update")
                this.processWebSocketUpdate(inqIdArray.wsInquiryList);
                
              }
            }
          }
          catch (exception) {
              console.log(exception);
          }
        }
      );
  }
    processWebSocketUpdate(inqIdArray) {
        if (inqIdArray && inqIdArray.length > 0) {
            let wsInq = inqIdArray[0];
            let convToUpdate = false;
            if (this.params && this.params.node && this.params.node.data && this.params.node.data._id === wsInq._id &&
                this.params.node.data.workflows[0].assignedGroupId === wsInq.workflows[0].assignedGroupId &&
                    this.params.node.data.workflows[0].direction === wsInq.workflows[0].direction) {
                convToUpdate = true;
            }
            if (convToUpdate) {
                for (let inqIndex = 0; inqIndex < this.rowData.length; inqIndex++) {
                    this.updateInqLevelData(wsInq, inqIndex);
                    this.UpdateData(this.rowData[inqIndex], inqIndex);
                }

            }
        }
    }

    updateInqLevelData(wsInq,inqIndex) {
        for (let key in wsInq) {
            // check if the property/key is defined in the object itself, not in parent
            if (wsInq.hasOwnProperty(key)) {
                if(key === "openUsers") {
                    console.log(wsInq)
                }
                // dont override conv filelds with inq fields
                if (!this.wsKeysToIgnore.concat(["_id", "id", "suggestionAvailable", "suggestionIndicator", "suggestionStatus",
                    "urgentFlag", "latestUserName", "conversCount", "openGroups","modDate"])
                    .includes(key))
                    this.rowData[inqIndex][key] = wsInq[key];
            }
        }
        /* let inqToUpdateIndex = this.rowData.findIndex(rd => wsInq._id === rd._id &&
            wsInq.workflows[0].assignedGroupId === rd.workflows[0].assignedGroupId
            && wsInq.workflows[0].direction === rd.workflows[0].direction); */
     
        /* return inqToUpdateIndex; */
    }

    UpdateData(inq, inqIndex) {
        this.gridApi.forEachNode((rowNode, index) => {
            if (index === inqIndex) {
                if (inq._id === rowNode.data._id && inq.workflows[0].assignedGroupId === rowNode.data.workflows[0].assignedGroupId
                    && inq.workflows[0].direction === rowNode.data.workflows[0].direction)
                    rowNode.setData(inq);
            }
        });
    }
    rowDataChanged(params) {
        if(!this.isWebSocketRedesignEnable) {
            return;
        }
        const selectedConvJSON = sessionStorage.getItem("selectedConv_" + this.currentTab);
        if (selectedConvJSON) {
            const selectedConv = JSON.parse(selectedConvJSON);
            params.api.forEachNode((node) => {
                if (node.data.id === selectedConv.selectedConvId) {
                    node.setSelected(true,true);
                    // this.onRowClicked(node);
                }

            });
        }
    }
  
}