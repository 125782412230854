import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DashSnapshotCnts } from '../../model/DashSnapshotCnts/DashSnapshotCnts';
/* import { DashboardSettings } from '../../model/DashboardSettings/DashboardSetting'; */
import { TrendCount, Trends } from '../../model/TrendCount';
import { QmaConstant } from "src/app/constant/qma-constant";
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { AppUtils } from 'src/app/common/utility/appUtil';
import { UserDataService } from "src/app/services/user-data.service";
import { timeInterval } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  })
};

const httpOptions2 = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Accept': '*'
  })
};

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  baseURL: string;
  genericURL: string;
  dashboardURL: string;
  isDashboardInlineView: boolean = false;

  dashSnapshotCnts: DashSnapshotCnts[] = [];
  dashSnapshotCntSubj = new BehaviorSubject({});
  getDashboardSettingDataSubj = new BehaviorSubject({});
  getDashboardChartData: BehaviorSubject<Object>; //= new BehaviorSubject({});
  getOpenInquiryGroupSubject: BehaviorSubject<Object>;
  getInquiriesTrend: BehaviorSubject<Object>;
  getInquiriesTimeline: BehaviorSubject<Object>;
  getIntensityHeatMap: BehaviorSubject<Object>;
  getDashboardCounts: BehaviorSubject<Object>;
  getDashboardSettingData: BehaviorSubject<Object>;
  private _listners = new Subject<any>();
  getmailBoxStatsSubject: BehaviorSubject<Object>;
  //getDashboardChartDefaultData:BehaviorSubject<Object>;

  // C153176-4911: getDashboardCounts() invocation timer
  getDashboardCountsTimer: any;
  UpdatedUnReadCount$ = new BehaviorSubject({});

  // For Performance. This flag is to check whether we need to call dashboardcount service or not.
  countflag: boolean = true;
  // C153176-6010 - Dashboard refresh interval
  dashboardRefreshInterval = QmaConstant.GETDASHBOARDCOUNTS_INERVAL_IN_MS;


  constructor(private httpClient: HttpClient, private userDataService: UserDataService) {
    this.baseURL = QmaConstant.BASE_URL + '/inquiry';
    this.genericURL = QmaConstant.BASE_URL + '/generic';
    this.dashboardURL = QmaConstant.BASE_URL + '/dashboard';


    this.getDashboardChartData = new BehaviorSubject({});
    this.getOpenInquiryGroupSubject = new BehaviorSubject({});
    this.getInquiriesTrend = new BehaviorSubject({});
    this.getInquiriesTimeline = new BehaviorSubject({});
    this.getIntensityHeatMap = new BehaviorSubject({});
    this.getDashboardCounts = new BehaviorSubject({});
    this.getDashboardSettingData = new BehaviorSubject({}); //= new BehaviorSubject({});  
    this.getmailBoxStatsSubject = new BehaviorSubject({});
  }

  /**
   * C153176-4911: enable periodic timer to invoke getDashboardCounts()
   */
  enableGetDashboardCountsTimer(loggedInUser) {
    // let interval = QmaConstant.GETDASHBOARDCOUNTS_INERVAL_IN_MS;
    // if (loggedInUser && loggedInUser.dashboardCountRefreshIntervalInMs) {
    //   interval = loggedInUser.dashboardCountRefreshIntervalInMs;
    // }
    // if (this.getDashboardCountsTimer === undefined) {
    //   this.getDashboardCountsTimer = setInterval(() => {
    //     this.retrieveDashboardCounts();
    //   }, interval);
    //   console.log("Started periodic timer to invoke getDashboardCounts(), timer =", this.getDashboardCountsTimer, ", interval (ms)=" + interval);
    // }
  }

  /**
   * Method to get the Snapshot Counts.
   */
  getDashSnapshotCnts() {
    // this will get each folder's open inquiry counts
    this.httpClient.get<DashSnapshotCnts[]>(this.baseURL + '/getDashSnapshotCnts', httpOptions)
      .subscribe(dashSnapshotCnts => {
        this.dashSnapshotCntSubj.next(dashSnapshotCnts);
      });
  }

  getHomePageChartsData() {
    this.httpClient.get(this.genericURL + '/getHomePageChartsData', httpOptions)
      .subscribe(dsdata => {
        this.getDashboardChartData.next(dsdata);
      });
  }


  getOpenInquiryGroupData() {
    this.httpClient.get(this.dashboardURL + '/getOpenInquiryByGroup', httpOptions)
      .subscribe(inqGrpdata => {
        this.getOpenInquiryGroupSubject.next(inqGrpdata);
      });
  }

  /**
   * Method to get Intensity Heatmap counts
   */
  getIntensityHeatMapCnts(groupName = "All") {
    let body = JSON.stringify({ "groupName": groupName });
    this.httpClient.post<DashSnapshotCnts[]>(this.dashboardURL + '/getIntensityHeatMapCntByGroup', body, httpOptions)
      .subscribe(heatmap => {
        if (heatmap) {
          this.getIntensityHeatMap.next(heatmap);
        } else {
          console.log('Invalid heatmap: ' + heatmap);
        }
      });
  }

  /**
   * Method to get the Snapshot Counts.
   */
  getInquiriesTrendCnts(groupName = "All") {
    let body = JSON.stringify({ "groupName": groupName });
    // this will get each folder's inquiry trend counts
    this.httpClient.post<Trends>(this.dashboardURL + '/getInquiryTrendChartData', body, httpOptions)
      .subscribe(trend => {
        if (!trend) {
          console.log("No data returned from /getInquiryTrendChartData!");
        } else {
          const inquiryTrend = { ...trend };
          this.getInquiriesTrend.next(inquiryTrend);
        }
      });
  }

  /**
   * Method to get the Snapshot Counts.
   */
  getInquiriesTimelineCnts(date, groupName = "All", trendChartType = "inquiries") {

    let d = (new Date(date)),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    const dateCustom = [year, month, day].join('-');

    // this will get each folder's inquiry timeline counts
    console.log('getInquiriesTimelineCnts, date = ' + date)
    let body = JSON.stringify({ "groupName": groupName, date: dateCustom, trendChartType: trendChartType });

    this.httpClient.post(this.dashboardURL + '/getInquiryTrendChartDataForDate', body, httpOptions2)
      .subscribe(timeline => {
        if (!timeline) {
          console.log("No data returned from /getInquiryTrendChartDataForDate!");
        } else {
          timeline['date'] = AppUtils.formatDate(new Date(date));
          this.getInquiriesTimeline.next(timeline);
        }
      });
  }

  /**
  * Method to get the mailstats (new).
  */
  getmailBoxStats(groupName = "All") {
    // this will get each folder's inquiry timeline counts
    console.log('getmailBoxStats, date = ' + groupName)
    try {
      let mailBoxStats = JSON.parse(localStorage.getItem('mailBoxStats'));
      let messages = mailBoxStats['messages'];
      let views = mailBoxStats['myViews'];
      const count = messages.length + views.length;

      let mailStatstics = [];
      messages.forEach(msg =>  this.getMailBoxStatsData(count, mailStatstics, groupName, msg));
      views.forEach(view =>  this.getMailBoxStatsData(count, mailStatstics, groupName, '', view));
    } catch (e) {
      console.error('Error while getting response from getmailBoxStats()', e);
    }
  }

  private getMailBoxStatsData(count: number, mailStatstics: any[], groupName: string, viewName: string = '', savedView: string = '', ) {
    const totalCount$ = this.getMailBoxStats(groupName, 'totalCount', viewName, savedView);
    //const totalUnreadCount$ = this.getMailBoxStats(groupName, 'totalUnreadCount', viewName, savedView);
    const mailBoxStats$ = forkJoin([totalCount$]);

    mailBoxStats$.subscribe(mailStatsData => {
      if (!mailStatsData) {
        console.log("No data returned from /getMailBoxStats!");
      } else {
        let totalData = mailStatsData[0]['mailBoxStat'];
        //let unreadData = mailStatsData[1]['mailBoxStat'];
        const mergeData = { ...totalData[0]};
        mailStatstics.push(mergeData);
        let mailBoxStat = { 'mailBoxStat': mailStatstics };
        if (mailStatstics.length == count) {
          this.getmailBoxStatsSubject.next(mailBoxStat);
        }
      }
    }, error => {
      console.error('Error while getting response from getmailBoxStats()', error);
    });
  }

  private getMailBoxStats(groupName: string, reqType: string, viewName: string = '', savedView: string = ''): Observable<any> {
    const requestBody = JSON.stringify({ "groupName": groupName, "reqType": reqType, "messages": viewName, "myViews": savedView });
    return this.httpClient.post(this.dashboardURL + '/getMailBoxStats', requestBody, httpOptions);
  }

  /*
    * Method : To get the layout of dashboard chart dropdown setting.
    * Date : 14-08-2019
  */
  getDashboardLayout() {
    return this.httpClient.get<any>(this.dashboardURL + '/getDashboardLayout ', httpOptions);
  }
  /*
    * Method : To post the data after click on "Set as default" for dashboard chart dropdown setting.
    * Date : 14-08-2019
  */
  postDashboardSettingDefault(settingData) {
    return this.httpClient.post(this.dashboardURL + '/saveDashboardLayout ', JSON.stringify(settingData), httpOptions);
  }

  postDashboardSettingData(dashboardSetting) {
    return this.httpClient.post(this.dashboardURL + '/saveDashboardSettings', JSON.stringify(dashboardSetting), httpOptions);
  }
  getDashBoardSettingData() {
    return this.httpClient.get<any>(this.dashboardURL + '/getDashboardSettings', httpOptions);
  }
  getDashboardCountsService() {
    // this will get each folder's open inquiry counts
    this.httpClient.get<DashSnapshotCnts[]>(this.dashboardURL + '/getDashboardCounts', httpOptions)
      .subscribe(result => {
        this.getDashboardCounts.next(result);
      });
  }


  getDashboardCountsServiceSubject(): Observable<any> {
    return this.getDashboardCounts.asObservable();
  }
  // C153176-5577 Blue Circle on tab is not showing correct number
  getUpdatedUnreadCount(): Observable<any> {
    return this.UpdatedUnReadCount$.asObservable();
  }
  // C153176-5577 Blue Circle on tab is not showing correct number
  setUpdatedUnreadCount(updatedCountObj) {
    this.UpdatedUnReadCount$.next(updatedCountObj);
  }
  listenSaveFlag(): Observable<any> {
    return this._listners.asObservable();
  }

  passSaveFlag(filterBy: any) {
    this._listners.next(filterBy);
  }

  setDashboardInline(isDashboardInlineView: any): void {
    this.isDashboardInlineView = isDashboardInlineView;
  }

  getDashboardInline() {
    return this.isDashboardInlineView;
  }

  retrieveDashboardCounts() {
    // C153176-4911: directly invoke getDashboardCountsService, no need to check currentTab
    this.getDashboardCountsService();
  }

  /**
   * Method to call the dashboard counts and user should only call this after a specific interval of time.
   * This will improve performance. This service would be called based on requirement
   */
  getDashboardCountsEvent() {
    try {
      // Get it from login user info
      this.dashboardRefreshInterval = this.userDataService.dashboardRefreshInterval;
      console.log("The dashboard count service will run after : ", this.dashboardRefreshInterval);

      if (this.countflag) {
        this.countflag = false;
        this.getDashboardCountsService();

        setTimeout(() => {
          this.countflag = true;
        }, this.dashboardRefreshInterval);
      }
    } catch (e) {
      console.error("Exception in QMA 2 while getDashboardCountsEvent() : ", e);
    }
  }

  onLoad(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpClient.get<any>(this.dashboardURL + '/validateUser', httpOptions).subscribe(dashboardSetting => {
        console.log('Response from server API: ', dashboardSetting);
        // For new user there is no dashboard setting.
        try {
          let mailBoxtStats = dashboardSetting['mailBoxStats'];
          if (mailBoxtStats && ((mailBoxtStats['messages'] && mailBoxtStats['messages'].length > 0) ||
            (mailBoxtStats['myViews'] && mailBoxtStats['myViews'].length > 0))) {
            console.log('Mail Box Stats : ', mailBoxtStats);
            localStorage.setItem('mailBoxStats', JSON.stringify(mailBoxtStats));
          } else {
            console.log('Mail Box Stats : ', QmaConstant.MAIL_BOX_STATS);
            localStorage.setItem('mailBoxStats', JSON.stringify(QmaConstant.MAIL_BOX_STATS));
          }

          // Store the group names to local storage to show and hide group tags
          let assignedGroups = dashboardSetting['assignedGroups'];
          if (assignedGroups && assignedGroups.length > 0) {
            let groupNames = assignedGroups.map(assignGroup => assignGroup.groupName);
            localStorage.setItem("groupNames", JSON.stringify(groupNames));
          } else {
            localStorage.removeItem("groupNames");
          }
          resolve(true);
        }
        catch (e) {
          console.error('QMA 2.0 exception while onLoad() ', e);
          console.log('Mail Box Stats : ', QmaConstant.MAIL_BOX_STATS);
          localStorage.setItem('mailBoxStats', JSON.stringify(QmaConstant.MAIL_BOX_STATS));
          resolve(true);
        }
      },
        error => {
          console.log('You are not authorized to access the application through browser.');
          console.log("User Check: " + JSON.stringify(error));
          if (error.error.message == "User is not authorized" && error.error.status == 500) {
            window.alert('You are not authorized to access the application through browser.');
          }
          reject(false);
        });
    });
  }

  // Flag to check if user is allowed to access application.
  // private _isAllowed: boolean = true;

  // get isAllowed(): boolean {
  //   return this._isAllowed;
  // }
  // set isAllowed(isAllowed: boolean) {
  //   this._isAllowed = isAllowed;
  // }
}