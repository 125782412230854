import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ViewChild,
  ViewChildren,
  QueryList,
  AfterContentChecked,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  SimpleChanges,
  OnChanges,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import { GetContactsService } from '../../services/newMessage/get-contacts.service';
import { UserDataService } from '../../services/user-data.service';
import { InboxService } from '../../services/inbox.service';
import { map, filter } from 'rxjs/operators';
import {
  FormGroup,
  FormBuilder,
  Validators,
  ValidatorFn,
  Validator,
  AbstractControl,
  FormControl,
} from '@angular/forms';
import { UserSignature } from '../../model/LoginUserInfo/UserSignature';
import { ConfirmationService, MessageService } from 'primeng/api';
import {
  SaveDraft,
  SendInquiry,
  ScheduleModel,
} from '../../model/NewMessage/SaveDraft';
import { AppUtils } from '../../common/utility/appUtil';
import { NewMessageService } from '../../services/newMessage/new-message.service';
import { AllToCcDBUser } from '../../model/LoginUserInfo/AllToCcDBUser';
import { Subscription } from 'rxjs';
import { ScheduleMessageComponent } from 'src/app/new-message/schedule/schedule-message.component';
import { TabDataService } from 'src/app/services/tab-data.service';
import { NewMessageComponent } from 'src/app/new-message/new-message.component';
import { MyGroup } from '../../model/LoginUserInfo/MyGroup';
import { DateFormatPipe } from '../../common/pipes/date-format.pipe';
import { QmaConstant } from '../../constant/qma-constant';
import { LoginUserInfo } from 'src/app/model/LoginUserInfo/LoginUserInfo';
import { Preference } from 'src/app/model/LoginUserInfo/Preference';
import { OverlayPanel } from 'primeng/overlaypanel';
import * as _ from 'underscore';
import * as $ from 'jquery'; // C153176-5273
import { PiwikProUtils } from 'src/app/common/utility/piwikProUtils';
import { ReAgeComponent } from 'src/app/common/component/re-age/re-age.component';
import { TaskizeAuthService } from 'src/app/services/taskize-auth.service';

@Component({
  selector: 'new-message-actions',
  templateUrl: './new-message-actions.component.html',
  styleUrls: ['./new-message-actions.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)',
  },
  providers: [ConfirmationService, MessageService],
})
export class NewMessageActionsComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  // C153176-5273: add interface to handle afterViewInit

  dateFormatPipe: DateFormatPipe;
  groupName: string;
  templatesMap = new Map<string, string[]>();
  userTemplatesMap = new Map<string, string[]>();
  userSignaturesMap = new Map<string, string>();
  commonTemplates = new Set();
  templates = [];
  userTemplates = [];
  mergedMap: Map<string, string[]>;
  signatures = [];
  restrictedFiles = [];
  horizontalView: boolean = false;

  // C153176-5273: buttons and parent wrapper
  @ViewChildren('actionBtn') actionBtns: QueryList<ElementRef>;
  @ViewChild('actionWrapper', { static: true }) actionWrapper: ElementRef;
  @ViewChild('actionMenuOptions', { static: true })
  actionMenuOptions: ElementRef;

  // C153176-5273: button props
  // buttons = {
  //   sendButton: { index: 0, presence: true, visible: true, label: 'SEND', src: 'send' },
  //   cancelButton: { index: 1, presence: true, visible: true, label: 'CANCEL', src: 'cancel' },
  //   resolveButton: { index: 2, presence: true, visible: true, label: 'RESOLVE', src: 'resolve' },
  //   saveButton: { index: 3, presence: true, visible: true, label: 'SAVE', src: 'save' },
  //   attachButton: { index: 4, presence: true, visible: true, label: 'ATTACH', src: 'attach' },
  //   reAgeButton: { index: 5, presence: true, visible: true, label: 'RE-AGE', src: 'reAge' },
  //   formatButton: { index: 6, presence: true, visible: true, label: 'FORMAT', src: 'format' },
  //   importantButton: { index: 7, presence: true, visible: true, label: 'IMPORTANT', src: 'important' },
  //   secureButton: { index: 8, presence: true, visible: true, label: 'SECURE', src: 'secure' },
  //   approvalButton: { index: 9, presence: true, visible: true, label: 'APPROVAL', src: 'approval' },
  //   followupButton: { index: 10, presence: true, visible: true, label: 'FOLLOW UP', src: 'followup' },
  //   signatureButton: { index: 11, presence: true, visible: true, label: 'SIGNATURE', src: 'signature' },
  //   scheduleButton: { index: 12, presence: true, visible: true, label: 'SCHEDULE', src: 'schedule' },
  //   templateButton: { index: 13, presence: true, visible: true, label: 'TEMPLATES', src: 'templates' },
  //   ellipsisButton: { index: 14, presence: true, visible: true },
  // };
  buttons = {
    sendButton: {
      index: 0,
      presence: true,
      visible: true,
      label: 'SEND',
      src: 'send',
      width: 40,
    },
    cancelButton: {
      index: 1,
      presence: true,
      visible: true,
      label: 'CANCEL',
      src: 'cancel',
      width: 53,
    },
    resolveButton: {
      index: 2,
      presence: true,
      visible: true,
      label: 'RESOLVE',
      src: 'resolve',
      width: 59,
    },
    saveButton: {
      index: 3,
      presence: true,
      visible: true,
      label: 'SAVE',
      src: 'save',
      width: 39,
    },
    attachButton: {
      index: 4,
      presence: true,
      visible: true,
      label: 'ATTACH',
      src: 'attach',
      width: 200,
    },
    reAgeButton: {
      index: 5,
      presence: true,
      visible: false,
      label: 'RE-AGE',
      src: 'reAge',
    },
    formatButton: {
      index: 6,
      presence: true,
      visible: true,
      label: 'FORMAT',
      src: 'format',
      width: 54,
    },
    importantButton: {
      index: 7,
      presence: true,
      visible: true,
      label: 'IMPORTANT',
      src: 'important',
      width: 78,
    },
    secureButton: {
      index: 8,
      presence: false,
      visible: true,
      label: 'SECURE',
      src: 'secure',
    },
    approvalButton: {
      index: 9,
      presence: false,
      visible: true,
      label: 'APPROVAL',
      src: 'approval',
    },
    followupButton: {
      index: 10,
      presence: true,
      visible: true,
      label: 'FOLLOW UP',
      src: 'followup',
      width: 59,
    },
    signatureButton: {
      index: 11,
      presence: true,
      visible: false,
      label: 'SIGNATURE',
      src: 'signature',
      width: 76,
    },
    scheduleButton: {
      index: 12,
      presence: true,
      visible: false,
      label: 'SCHEDULE',
      src: 'schedule',
      width: 72,
    },
    templateButton: {
      index: 13,
      presence: true,
      visible: false,
      label: 'TEMPLATES',
      src: 'templates',
      width: 78,
    },
    ellipsisButton: {
      index: 14,
      presence: false,
      visible: true,
      width: 25,
    },
    fullScreen: {
      index: 15,
      presence: true,
      visible: true,
      label: 'Full Screen',
      src: 'fullscreen',
      width: 78,
    },
  };
  // C153176-5273: context menu keys
  contextmenuItemKeys = [
    'scheduleButton',
    'signatureButton',
    'reAgeButton',
    'templateButton',
  ];
  contextmenuItems = [
    {
      index: 12,
      presence: true,
      visible: false,
      label: 'Schedule',
      src: 'schedule',
      width: 72,
    },
    {
      index: 11,
      presence: true,
      visible: false,
      label: 'Signature',
      src: 'signature',
      width: 76,
    },
    {
      index: 5,
      presence: true,
      visible: false,
      label: 'Re-Age',
      src: 'reAge',
      width: 78,
    },

    {
      index: 13,
      presence: true,
      visible: false,
      label: 'Template',
      src: 'templates',
      width: 78,
    },
  ];

  @Output() valueChange = new EventEmitter();
  @ViewChild('urgentFlag', { static: true }) urgentFlag: ElementRef;
  @ViewChild('resolveFlag', { static: true }) resolveFlag: ElementRef;
  @ViewChild('sendButton') sendButton: ElementRef;
  @Input() parentForm: FormGroup;
  @Input() editorData: any;
  @Input() messageInstance: any;

  @Output() getAttachments = new EventEmitter();
  @Output() toBccData = new EventEmitter();
  @Output() formatTemplate = new EventEmitter();

  citiDomains: any[];
  externalAddresses = [];
  externalDomainsEmailIds = [];
  externalDomainsEmails = [];
  externalAddressesAndEmailIds = new Map();
  @ViewChild('uploadFiles', { static: true }) uploadFiles: ElementRef;
  files: FileList;
  toUsersList: AllToCcDBUser[] = [];
  ccUsersList: AllToCcDBUser[] = [];
  bccUsersList: AllToCcDBUser[] = [];
  attachmentPwdProtectionFlag: boolean = false;
  parentConversation: string = '';
  @Input() fromNewMessage: boolean = false;
  @Input() horizontalStyle: boolean = false;
  displayPasswordProtection: boolean = false;
  fileTypesSupportedForPasswordProtection = [
    'DOC',
    'DOCX',
    'PDF',
    'XLS',
    'XLSX',
    'PPT',
    'PPTX',
    'ZIP',
    'doc',
    'docx',
    'pdf',
    'xls',
    'xlsx',
    'ppt',
    'pptx',
    'zip',
  ];
  filesTobePwdProtected = '';
  filesAlreadySecuredByUser = '';
  showAllSupportedUnSecuredFilesList = [];
  showAllSupportedSecuredFilesList = [];
  showAllUnSupportedFilesList = [];
  errorMsg = '';
  errMsgRestrcitedCharsInFilename = '';
  mandatoryForResolveFlags = [];
  blackListDomains = [];
  errMsg = '';
  displayError: boolean = false;
  isMakerCheckerConfigured: boolean = false;
  highlevelreqtypeFlag: boolean = false;
  attachmentVerificationMandatoryOrgs: any;
  @Output() setResolve = new EventEmitter();

  @ViewChild('showAllSupportedUnSecuredFiles', { static: true })
  showAllSupportedUnSecuredFiles: ElementRef;

  @ViewChild('attachmentSelectAllCheckBox', { static: true })
  attachmentSelectAllCheckBox: ElementRef;
  @ViewChild('fileInfo', { static: true }) fileInfo: ElementRef;
  @ViewChild('secureFlag', { static: true }) secureFlag: ElementRef;
  @ViewChild('followupFlag', { static: true }) followupFlag: ElementRef;
  @ViewChild('apprReqdFlag', { static: true }) apprReqdFlag: ElementRef;
  @ViewChild('apprReqdBtn') apprReqdBtn: ElementRef;
  @Input('secure') getsecure;
  @Input('group') getGroup;
  @Input() inlineImageAttached: boolean; // C153176-5896 | This property gets updated on add new inline image.
  @Input() recipientUpdate: boolean; // C153176-5896 | On update recipient.

  @Input() notes: string[];
  @Input() conversationObj: any;
  @Input() isInlineReply: boolean = false;
  @Input() selectedCLCTrades: any[] = []; // C153176-4594 : to capture selected CLC trades

  @Output() cancelInlineReply = new EventEmitter();
  @Output() inlineReplySend = new EventEmitter();
  // C153176-4683: inline reply scheduled event
  @Output() inlineReplyScheduled = new EventEmitter();

  passwordProtectionForm: FormGroup = this.builder.group({
    fileInfo: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
    ]),
  });
  enableInquirySubStatus: boolean;
  taskizeEnabled: boolean;
  hideOptionsForTaskize: boolean;

  get f() {
    return this.passwordProtectionForm.controls;
  }

  // Subscriptions
  private draftSubscription: Subscription;
  private replySubscription: Subscription;
  private actionSubscription: Subscription;
  private rowDragDropSubscription: Subscription;
  private viewClickedSubscription: Subscription; // C153176-5273
  private editorFocusSubscription: Subscription;

  // Required for Reply action
  inquiryId: number;
  inquiryAction: string = 'NEW INQUIRY';
  suggestionConvId: number;
  sendSecureEmail: boolean = false;
  isVisible: boolean = false;
  displayReAge = true;
  lisfOfUploadedFiles: any[] = [];
  draftOnTabCloseSubscription: Subscription;

  // Emitter for Notes
  @Output() notesEvent = new EventEmitter();
  @ViewChild(ScheduleMessageComponent, { static: true }) schMsgComp: any;
  @ViewChild('reAge') reAgeComponent: ReAgeComponent;
  schConvId: any;
  schInquiryAction: any;
  schInquiryId: any;
  schEvent: any;
  scheduleTime: any;
  approvalReq: boolean = false;
  attachments = [];

  // User local draft Id instead of service to hold draftId.
  selDraftId: number;

  // LoggedIn User
  //loggedUserInfo: LoginUserInfo;

  // C153176-4459 : to capture nlp suggestion
  nlpSuggestion: boolean = false;

  // C153176-4594 : to capture CLC suggestion
  clcSuggestion: boolean = false;

  isMobile: boolean = false; // C153176-5273: added for mobile performance

  // C153176-5298 | Check if locked inquiry should be resolve
  lock: string = 'N';
  lockedBy: string = '';
  forceResolveConfirm: boolean = false;
  forceResolveConfirmMsg: string;
  borderDragDropHighlight = false; // C153176-5343: highlight drop target

  private totalAttachmentSize: number = 0;
  public isSent = false;
  public qmaConstant = QmaConstant;
  public processedFiles: any;

  //TODO:Performance :: Calculate the time.
  startTime: any;
  followup: boolean = false;
  invaliDEmailIdExists = false;
  invalidEmailIds = [];
  hasPrevAttach = false;
  @Input()
  updatePrevAttach = null;
  Qma_blocked_file_name_chars = '';
  errMsgMultipleDotsInFilename = '';
  displayApiError = false;
  constructor(
    private userDataService: UserDataService,
    private getContactsService: GetContactsService,
    private builder: FormBuilder,
    private confirmationService: ConfirmationService,
    private newMessageService: NewMessageService,
    private tabDataService: TabDataService,
    private messageService: MessageService,
    private parentComponent: NewMessageComponent,
    private inboxService: InboxService,
    private cdr: ChangeDetectorRef,
    private taskizeService: TaskizeAuthService
  ) {
    // C153176-5273 add change detector
    this.isMobile = window.innerWidth <= QmaConstant.MOBILE_WIDTH;
    this.dateFormatPipe = new DateFormatPipe();
    if (window.innerWidth <= QmaConstant.MOBILE_WIDTH) {
      this.getContactsService.setFormatToggleFlag(true);
    }

    //TODO:Performance :: Start Time.
    this.startTime = performance.now();
    console.log('constructor this.buttons: ', this.buttons);
  }

  ngOnInit() {
    if (this.horizontalStyle) {
      this.horizontalView = true;
    }
    let startTime = performance.now();
    this.isVisible = false;
    // Reset the reply variables -- Commented by XXXXX - this makes inquiry Id null. This code is push to onChanges, bcoz ngChanges executes before ngOnit
    // this.inquiryId = null;
    // this.suggestionConvId = null;

    this.getAllGroupTemplates();
    // this.populateCommonTemplates(); // Commented by XXXXX, why we need it doesn't populate any thig here.

    this.populateTemplates(this.groupName);
    this.templates = this.populateCommonTemplates();

    // Get the unique templates and  Sort the template array by name
    this.templates = _.uniq(this.templates, 'value');
    this.templates = _.sortBy(this.templates, function (i) {
      return i.label.toLowerCase();
    });

    this.draftOnTabCloseSubscription = this.tabDataService
      .getSaveDraftOnTabClose()
      .subscribe(
        (draftObj) => {
          if (
            draftObj.draftFlag &&
            this.messageInstance.includes(draftObj.id)
          ) {
            console.log('draftFlag');
            this.saveDraftToDatabase(true, this.selDraftId, this.notes);
          }
        },
        (error) => {
          console.log('error : ', error);
        }
      );

    this.draftSubscription = this.newMessageService.getDraftSubject().subscribe(
      (draftId) => {
        if (this.messageInstance.includes(draftId)) {
          if (draftId && draftId > 0) {
            // this.newMessageService.setDraftId(draftId);
            this.selDraftId = draftId;
            this.getDraftById(draftId);
            this.displayReAge = false;
          } else {
            this.selDraftId = null;
            ///this.newMessageService.setDraftId(draftId);
          }
        }
      },
      (error) => {
        console.log('error : ', error);
      }
    );

    // Subscribe the change happens when user click on reply actions
    /*  if (!this.fromNewMessage) {
       this.replySubscription = this.newMessageService.getReplyContents().subscribe(conversationDetails => {
         if (conversationDetails) {
          // this.getReplyContents(conversationDetails);
         }
         this.unSubscribeReply();
       },
         error => {
           console.log('Unable to populate new messagae component due to :', error);
         }
       );
     } */

    this.actionSubscription = this.tabDataService.getActionSubject().subscribe(
      (event) => {
        const tabName = event.tabName;
        const action = event.action;
        if (
          action === 'Send' &&
          (this.inquiryId
            ? tabName === this.parentComponent.tabName + ': ' + this.inquiryId
            : tabName.startsWith(this.inquiryAction) ||
              (this.inquiryAction.toUpperCase().startsWith('NEW') &&
                tabName.startsWith('NEW')))
        ) {
          this.sendInquiryAction(action);
        }
      },
      (error) => {
        console.log('Unable to apply action event due to :', error);
      }
    );

    this.rowDragDropSubscription = this.inboxService.rowDragDropEvent.subscribe(
      (event: any) => {
        if (
          event &&
          event.header &&
          event.data &&
          this.parentComponent &&
          this.parentComponent.messageInstance
        ) {
          if (
            event.header.toUpperCase() !==
            this.parentComponent.messageInstance.toUpperCase()
          ) {
            return;
          }
          const data = JSON.parse(
            decodeURIComponent(
              event.data.substring(QmaConstant.QMA_DND_HEADER.length)
            )
          );
          this.handleInquiryDrop(data);
        }
      }
    );

    this.getUSerSignatures();

    // C153176-5273: handling resize of action bar if user has clicked vertical or horizontal icon
    this.viewClickedSubscription = this.tabDataService
      .getClickedIcon()
      .subscribe((clickedIcon) => {
        console.log('clickedIcon: ', clickedIcon);
        if (!this.horizontalStyle) {
          if (clickedIcon.toLowerCase() === 'horizontal') {
            this.horizontalView = true;
          } else {
            this.horizontalView = false;
          }
        }

        if (this.isMobile) {
          // added for mobile performance
          return;
        }
        //C153176-5343: function null/type check
        if (
          typeof clickedIcon.toLowerCase === 'function' &&
          (clickedIcon.toLowerCase() === 'horizontal' ||
            clickedIcon.toLowerCase() === 'vertical') &&
          this.inquiryId
        ) {
          setTimeout(() => {
            this.onResize(null); // execute the resize in async so as to take latest width
          }, 0);
        } else {
          console.debug(
            'Skipping last resize event, clickedIcon/inquiryId=',
            clickedIcon,
            this.inquiryId
          );
        }
      });
    // C153176-5273: handling hiding of action popupmenu if focus is moved to editor
    this.editorFocusSubscription = this.newMessageService
      .getEditorFocus()
      .subscribe((focus) => {
        if (focus && this.actionMenuOptions) {
          setTimeout(() => {
            $('#actionMenuOptions').hide();
          }, 0);
        }
      });
    let endTime = performance.now();
    console.log(
      `QMA2.0 Performance :: New Message Actions oninit processing took : ${Math.ceil(
        endTime - startTime
      )} miliseconds`
    );

    if (
      this.userDataService.loggedInUserInfo.isTaskizeEnabledForUser &&
      this.userDataService.loggedInUserInfo.isTaskizeEnabledForUser?.toUpperCase() ==
        'Y'
    ) {
      this.taskizeEnabled = true;
    } else {
      this.taskizeEnabled = false;
    }
    // if (this.contextmenuItemKeys) {
    //   this.contextmenuItems = this.contextmenuItemKeys.map(
    //     (k) => this.buttons[k]
    //   );
    //   if (this.hideOptionsForTaskize) {
    //     this.contextmenuItems = this.contextmenuItems.filter(
    //       (item) =>
    //         item.src != 'schedule' &&
    //         item.src != 'followup' &&
    //         item.src != 'templates' &&
    //         item.src != 'resolve'
    //     );
    //   }
    // }
    setTimeout(() => {
      let inquirySourceCheckForTaskize = this.isNull(
        this.parentForm.value.inquirySource
      )
        ? ''
        : this.parentForm.value.inquirySource;
      if (
        (inquirySourceCheckForTaskize == 'Taskize' ||
          this.messageInstance.startsWith(QmaConstant.QMA_NEW_BUBBLE)) &&
        this.taskizeEnabled
      ) {
        this.hideOptionsForTaskize = true;
      }
    }, 0);
    console.log('ngOnInit end this.buttons: ', this.buttons);
  }
  ngAfterContentInit() {
    console.log('ngAfterContentInit start this.buttons: ', this.buttons);
    if (this.editorData) {
      this.editorData.sendEvent.subscribe((event: any) => {
        if (
          event &&
          event.messageInstance === this.messageInstance &&
          event.action === 'Send'
        ) {
          // do NOT enable other actions than 'Send'
          this.sendInquiryAction(event.action);
        } else if (event && event.action === 'drop' && event.data) {
          const data = JSON.parse(
            decodeURIComponent(
              event.data.substring(QmaConstant.QMA_DND_HEADER.length)
            )
          );
          this.handleInquiryDrop(data);
        }
      });
    }
    console.log('ngAfterContentInit end this.buttons: ', this.buttons);
  }
  /*   unSubscribeReply() {
      if (this.replySubscription) {
        this.replySubscription.unsubscribe();
      }
    } */

  // C153176-5273:
  ngAfterViewInit() {
    console.log('ngAfterViewInit start this.buttons: ', this.buttons);
    if (this.isMobile) {
      // added for mobile performance
      return;
    }
    // this.updateActionButtons(true);
    // C153176-5343: file drag event
    //TODO:Performance :: Unsubscribe the subsciption.
    this.inboxService.fileDragEvent.subscribe((evt: any) => {
      if (evt && evt.qma) {
        this.borderDragDropHighlight = evt.active;
      }
    });
    /*  let startTime = performance.now(); */
    //TODO:Performance :: End Time.
    let endTime = performance.now();
    console.log(
      `QMA2.0 Performance :: New Message Action loading : ${Math.ceil(
        endTime - this.startTime
      )} miliseconds, for group: ${this.groupName}`
    );
    console.log('ngAfterViewInit end this.buttons: ', this.buttons);
    // console.log(`QMA2.0 Performance :: New Message Actions afterViewInit processing took : ${Math.ceil(endTime - startTime)} miliseconds`);
  }

  /**
   * C153176-5273: update button visibility from native elements
   */
  updateActionButtons(init = false) {
    if (this.actionBtns) {
      // set all key's presence to false
      let keys = Object.keys(this.buttons);
      if (init && keys) {
        // if it is first time, set all presence to false, as it is determined by the component logic
        keys.forEach((key) => {
          this.buttons[key].presence = false;
        });
      }
      // set the presence of ones which are in actionBtns to true
      this.actionBtns.forEach((button, index) => {
        if (button.nativeElement.id) {
          let buttonProp = this.buttons[button.nativeElement.id];
          if (buttonProp) {
            if (init) {
              buttonProp.presence = true;
            }
            buttonProp.width = button.nativeElement.scrollWidth;
          }
        }
      });
      this.updateButtonDisplay();
    } else {
      console.log('No actionBtn found!');
    }
  }

  /**
   * C153176-5273: handle action button visibility
   */
  updateButtonDisplay() {
    let replyPanelWidth = this.actionWrapper.nativeElement.clientWidth;

    let keys = Object.keys(this.buttons);
    // sort the keys by index
    keys = keys.sort((a, b) => this.buttons[a].index - this.buttons[b].index);
    let actionBarWidth = 0;
    let prevItemKey = undefined;
    let lastItemKey = undefined;
    let otherItemKeys = [];
    keys.forEach((k) => {
      let prop = this.buttons[k];
      if (!prop.presence) {
        return;
      }
      if (lastItemKey) {
        otherItemKeys.push(k);
        return;
      }
      if (prop.width) {
        if (actionBarWidth + prop.width > replyPanelWidth) {
          // set the delta to 40
          lastItemKey = prevItemKey;
          otherItemKeys.push(k);
        } else {
          actionBarWidth += prop.width;
          prevItemKey = k;
        }
      }
    });
    console.debug(
      'lastItemKey =',
      lastItemKey,
      'remaining items =',
      otherItemKeys
    );
    // update visibility of buttons
    this.contextmenuItemKeys.forEach((k) => {
      if (k && !otherItemKeys.includes(k)) {
        this.buttons[k].visible = true;
      }
    });
    otherItemKeys.forEach((k) => {
      this.buttons[k].visible = false;
    });
    if (lastItemKey) {
      this.buttons.ellipsisButton.visible = true;
    } else {
      this.buttons.ellipsisButton.visible = false;
    }
    this.contextmenuItemKeys = otherItemKeys;
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }

  /**
   * C153176-5273: show popup menus of the unshown actions
   */
  showActionMenu(event, overlaypanel: OverlayPanel): void {
    console.log('overlaypanel: ', overlaypanel);
    console.log('event: ', event);

    console.log('this.contextmenuItemKeys: ', this.contextmenuItemKeys);
    // if (this.contextmenuItemKeys) {
    //   this.contextmenuItems = this.contextmenuItemKeys.map(k => this.buttons[k]);
    //   if (this.hideOptionsForTaskize) {
    //     this.contextmenuItems = this.contextmenuItems.filter(item => item.src != 'schedule' && item.src != 'followup' && item.src != 'templates' && item.src != 'resolve')
    //   }
    // }
    overlaypanel.toggle(event);
  }

  /**
   * C153176-5273: context menu action handler
   */
  onActionMenu(
    event: any,
    overlaypanel: OverlayPanel,
    tempPop: OverlayPanel,
    signPop: OverlayPanel
  ) {
    let action = event.target.innerText;
    if (!action) {
      console.debug('no action found!');
      return;
    }
    overlaypanel.hide();
    if (action === 'Template') {
      this.showTemplatesPopup(event, tempPop);
    } else if (action === 'Schedule') {
      this.scheduleInquiry(
        event,
        this.inquiryId,
        this.inquiryAction,
        this.suggestionConvId
      );
    } else if (action === 'Signature') {
      this.showSignaturePopup(event, signPop);
    } else if (action === 'FOLLOW UP') {
      this.markFollowUp();
    } else if (action === 'APPROVAL') {
      //????
      let formData = this.getGroupFromGroupName(
        this.parentForm.value.groupName
      );
      this.updateApprovalRequired(formData);
    } else if (action === 'SECURE') {
      this.markSecure();
    } else if (action === 'IMPORTANT') {
      this.markImportant(true);
    } else if (action === 'FORMAT') {
      this.fotmatToggle();
    } else if (action === 'ATTACH') {
      this.uploadFile();
    } else if (action === 'SAVE') {
      this.saveDraft(event, this.selDraftId, this.notes);
    } else if (action === 'CANCEL') {
      this.cancel(event, this.inquiryId, this.selDraftId);
    } else if (action === 'SEND') {
      this.sendInquiry(
        event,
        this.inquiryId,
        this.inquiryAction,
        this.suggestionConvId,
        this.notes
      );
    } else if (action === 'Re-Age') {
      this.onReAge();
    } else {
      console.log('Action not found, action=', action, event);
    }
  }

  onResize(event: any) {
    if (this.isMobile) {
      // added for mobile performance
      return;
    }
    // this.updateActionButtons(); // C153176-5273: update action bar buttons at ng-change
  }

  getAllGroupTemplates() {
    //TODO:Performance :: start time
    let strtTime = performance.now();

    const userInfo = this.userDataService.loggedInUserInfo;
    this.attachmentPwdProtectionFlag = userInfo.attachmentPwdProtectionFlag;
    this.sendSecureEmail = userInfo.sendSecureEmail;
    this.blackListDomains = userInfo.blackListDomains;
    this.highlevelreqtypeFlag = userInfo.highlevelRequestTypeFlag;
    this.attachmentVerificationMandatoryOrgs =
      userInfo.attachmentVerificationMandatoryOrgs;

    //TODO:Performance ::It should be moved to common service which can be cached.
    this.userDataService.LocalGetLoginUserInfo().subscribe((loginUserInfo) => {
      //this.loggedUserInfo = loginUserInfo;
      // this.attachmentPwdProtectionFlag = loginUserInfo.attachmentPwdProtectionFlag;
      // this.sendSecureEmail = loginUserInfo.sendSecureEmail;
      // this.blackListDomains = loginUserInfo.blackListDomains;
      // this.highlevelreqtypeFlag = loginUserInfo.highlevelRequestTypeFlag;
      // this.attachmentVerificationMandatoryOrgs = loginUserInfo.attachmentVerificationMandatoryOrgs;
      loginUserInfo.myGroups.forEach((myGroup) => {
        if (!this.isNull(myGroup.templates)) {
          for (let i = 0; i < myGroup.templates.length; i++) {
            // C170665-3128 Template Issue
            // flag added to differentiate template recepients
            this.addTemplateFlagToReceipients(myGroup.templates[i].toList);
            this.addTemplateFlagToReceipients(myGroup.templates[i].ccList);
            this.commonTemplates.add(myGroup.templates[i].templateName);
          }
          this.templatesMap.set(myGroup.groupName, myGroup.templates);
        }
      });

      if (loginUserInfo.userTemplateList) {
        loginUserInfo.userTemplateList.forEach((userTemplate) => {
          // C170665-3128 Template Issue
          // flag added to differentiate template recepients
          this.addTemplateFlagToReceipients(userTemplate.toList);
          this.addTemplateFlagToReceipients(userTemplate.ccList);
          this.userTemplates.push(userTemplate.templateName);
        });
      }
      this.userTemplatesMap.set('Default', loginUserInfo.userTemplateList);
      this.mergedMap = new Map([
        ...Array.from(this.templatesMap.entries()),
        ...Array.from(this.userTemplatesMap.entries()),
      ]);
      this.citiDomains = loginUserInfo.citiDomainList;
      if (
        loginUserInfo &&
        loginUserInfo.blockedFileNameCharacters &&
        Array.isArray(loginUserInfo.blockedFileNameCharacters)
      ) {
        this.Qma_blocked_file_name_chars =
          loginUserInfo.blockedFileNameCharacters.join(',');
      }
      //TODO:Performance :: End Time.
      let endTime = performance.now();
      console.log(
        `QMA2.0 Performance :: New Message Actions - getAllGroupTemplates() : ${Math.ceil(
          endTime - strtTime
        )} miliseconds`
      );
    });
  }

  populateCommonTemplates(): any[] {
    //TODO:Performance :: start time
    let strtTime = performance.now();

    let templates = [];
    for (let item of Array.from(this.commonTemplates.values()))
      templates.push({ label: item, value: item });
    for (let ut of this.userTemplates) templates.push({ label: ut, value: ut });

    //TODO:Performance :: End Time.
    let endTime = performance.now();
    console.log(
      `QMA2.0 Performance :: New Message Actions - populateCommonTemplates() : ${Math.ceil(
        endTime - strtTime
      )} miliseconds`
    );
    return templates;
  }

  populateGroupTemplates(groupName): any[] {
    //TODO:Performance :: start time
    let strtTime = performance.now();

    let templates = [];
    this.mergedMap.forEach((groupTemplates: any[], key: string) => {
      if (groupName === key || key === 'Default') {
        for (let i = 0; i < groupTemplates.length; i++) {
          templates.push({
            label: groupTemplates[i].templateName,
            value: groupTemplates[i].templateName,
          });
        }
      }
    });

    //TODO:Performance :: End Time.
    let endTime = performance.now();
    console.log(
      `QMA2.0 Performance :: New Message Actions - populateGroupTemplates() : ${Math.ceil(
        endTime - strtTime
      )} miliseconds`
    );
    return templates;
  }

  populateTemplates(groupName) {
    //TODO:Performance :: start time
    let strtTime = performance.now();

    this.templates = [];
    this.userDataService.LocalGetLoginUserInfo().subscribe((loginUserInfo) => {
      loginUserInfo.myGroups.forEach((myGroup) => {
        if (groupName === myGroup.groupName) {
          if (!this.isNull(myGroup.templates))
            this.templates = this.populateGroupTemplates(groupName);
          else this.templates = this.populateCommonTemplates();
        } else if (this.isNull(groupName))
          this.templates = this.populateCommonTemplates();
      });

      //TODO:Performance :: End Time.
      let endTime = performance.now();
      console.log(
        `QMA2.0 Performance :: New Message Actions - populateTemplates() : ${Math.ceil(
          endTime - strtTime
        )} miliseconds`
      );
    });
  }

  onTemplateChange(templateName, overlaypanel) {
    this.userDataService
      .userUsageStats('New Message', 'New Message')
      .subscribe((result) => console.log('Template EndTime:' + new Date()));
    this.mergedMap.forEach((value: any[], key: string) => {
      for (let i = 0; i < value.length; i++) {
        if (value[i].templateName === templateName) {
          this.valueChange.emit(value[i].templateContent);
          this.formatTemplate.emit([value[i]]);
          overlaypanel.hide();
          return;
        }
      }
    });
  }

  getUSerSignatures() {
    //TODO:Performance :: start time
    let strtTime = performance.now();

    // this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
    //   loginUserInfo.userSignatures.forEach(userSignature => {
    //     this.userSignaturesMap.set(userSignature.name, userSignature.content);
    //   });
    // });

    // this.userSignaturesMap.forEach((signs: string, key: string) => {
    //   this.signatures.push({ label: key, value: signs });
    // });

    this.userSignaturesMap = this.userDataService.getUserSignatures();
    this.signatures = [
      ...Array.from(this.userSignaturesMap, ([label, value]) => ({
        label,
        value,
      })),
    ];

    //TODO:Performance :: End Time.
    let endTime = performance.now();
    console.log(
      `QMA2.0 Performance :: New Message Actions - getUSerSignatures() - 2: ${Math.ceil(
        endTime - strtTime
      )} miliseconds`
    );
  }

  onSignatureChange(sign, overlaypanel) {
    this.userDataService
      .userUsageStats('New Message', 'New Message')
      .subscribe((result) => console.log('Signature EndTime:' + new Date()));
    this.userSignaturesMap.forEach((signs: string, key: string) => {
      if (key === sign) {
        this.getContactsService.setSignature(signs);
        overlaypanel.hide();
        return;
      }
    });
  }

  // Null value check function
  isNull(input) {
    if (input === '' || input === undefined || input == null) {
      return true;
    }
    return false;
  }

  imp: boolean = false;
  markImportant(isNew?: boolean) {
    if (isNew) {
      // Action performed on new message.
      this.imp = !this.imp;
      this.urgentFlag.nativeElement.value = this.imp ? 'Y' : 'N';
    } else {
      // Reply/ Reply All
      this.urgentFlag.nativeElement.value = this.imp ? 'Y' : 'N';
      // if (this.imp == false) {
      //   this.urgentFlag.nativeElement.value = "N";
      //   this.imp = false;
      // }
      // else {
      //   this.urgentFlag.nativeElement.value = "Y";
      //   this.imp = true;
      // }
    }
  }

  resolve: boolean = false;

  // C153176-5298: Lock functionality for resolve on reply/reply all
  markResolve(lock, lockedBy) {
    if (
      lock == 'Y' &&
      this.userDataService.getLoggedInUserName() !== lockedBy &&
      !this.resolve
    ) {
      console.log('The inquiry is locked by ', lockedBy);
      this.forceResolveConfirm = true;
      this.forceResolveConfirmMsg =
        'This Inquiry has been Locked by [' +
        lockedBy +
        '] and cannot be resolved.<br/><br/>Do you want to Force Resolve? ';
    } else {
      this.setResolveMark();
    }
    PiwikProUtils.trackJsonDataForEvent(
      this.userDataService.loggedInUserInfo.pivotProConfigEnabled,
      this.userDataService.loggedInUserInfo.userId,
      'InquiryView',
      'Resolve from reply',
      'Click',
      'Resolve from reply',
      0
    );
  }

  /**
   * C153176-5298| Method to select resolve icon for the inquiry.
   * Method to be called on agree force resolve
   */
  setResolveMark(): void {
    this.cancelResolveMark();
    this.userDataService.LocalGetLoginUserInfo().subscribe((loginUserInfo) => {
      loginUserInfo.myGroups.forEach((fromData) => {
        if (
          !AppUtils.isUndefinedNullOrBlank(fromData) &&
          !AppUtils.isUndefinedNullOrBlank(fromData.groupName) &&
          fromData.groupName.toUpperCase() ==
            this.parentForm.value.groupName.toUpperCase()
        ) {
          if (
            (loginUserInfo.enableInquirySubStatusFlag &&
              fromData.enableInquirySubStatus) ||
            loginUserInfo.isInquirySubStatusEnabledAtOrg
          ) {
            this.enableInquirySubStatus = true;
          }
        }
      });
    });
    if (this.resolve == false) {
      this.resolveFlag.nativeElement.value = 'Y';
      this.resolve = true;
      if (this.enableInquirySubStatus) {
        this.parentForm.get('inquirySubStatus').setValue('Completed');
      }
      this.setResolve.emit(this.resolveFlag.nativeElement.value);
    } else {
      this.resolveFlag.nativeElement.value = 'N';
      this.resolve = false;
      if (this.enableInquirySubStatus) {
        if (this.fromNewMessage) {
          this.parentForm.get('inquirySubStatus').setValue('New');
        } else {
          this.parentForm.get('inquirySubStatus').setValue('');
        }
      }

      this.setResolve.emit(this.resolveFlag.nativeElement.value);
    }
  }

  /**
   * Method to be called on disagree force resolve
   */
  cancelResolveMark(): void {
    this.forceResolveConfirm = false;
    this.forceResolveConfirmMsg = '';
  }

  formatTog: boolean = false;
  fotmatToggle() {
    if (this.formatTog == false) {
      this.formatTog = true;
      this.getContactsService.setFormatToggleFlag(true);
    } else {
      this.formatTog = false;
      this.getContactsService.setFormatToggleFlag(false);
    }
    this.userDataService
      .userUsageStats('New Message', 'Format')
      .subscribe((result) => console.log('EndTime:' + new Date()));
    PiwikProUtils.trackJsonDataForEvent(
      this.userDataService.loggedInUserInfo.pivotProConfigEnabled,
      this.userDataService.loggedInUserInfo.userId,
      'Menu',
      'New message - Format',
      'Click',
      'New message - Format',
      0
    );
  }

  showSignaturePopup(event, overlaypanel) {
    this.userDataService
      .userUsageStats('New Message', 'Signature')
      .subscribe((result) => console.log('Signature StartTime:' + new Date()));
    PiwikProUtils.trackJsonDataForEvent(
      this.userDataService.loggedInUserInfo.pivotProConfigEnabled,
      this.userDataService.loggedInUserInfo.userId,
      'Menu',
      'New message - Signature',
      'Click',
      'New message - Signature',
      0
    );
    overlaypanel.toggle(event);
  }

  showTemplatesPopup(event, overlaypanel) {
    this.userDataService
      .userUsageStats('New Message', 'Templates')
      .subscribe((result) => console.log('Templates StartTime:' + new Date()));
    PiwikProUtils.trackJsonDataForEvent(
      this.userDataService.loggedInUserInfo.pivotProConfigEnabled,
      this.userDataService.loggedInUserInfo.userId,
      'Menu',
      'New message - Templates',
      'Click',
      'New message - Templates',
      0
    );
    overlaypanel.toggle(event);
  }

  cancelNewMessage(this$) {
    if (!this.isInlineReply) {
      let closeId = this.getClosest(this$, 'p-tabview');
      if (closeId !== null) {
        let newCloseId = closeId.replace('_content', '_header_action')
        let closeClick: any = document.querySelectorAll("#" + newCloseId + " .p-element.p-icon-wrapper.ng-star-inserted")[0];
        if (closeClick) {
          closeClick.click();
        }
      }
    } else {
      this.cancelInlineReply.emit('true');
    }
    //this.userDataService.userUsageStats("Dashboard", "Dashboard").subscribe(result => console.log("EndTime:"+new Date()));
  }

  cancel($event, inquiryId?: any, selDraftId?: any) {
    this.confirmationService.confirm({
      // Jira : 4929 : Cancel Button not working
      message: '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Do you want to close ?',
      accept: () => {
        this.cancelNewMessage($event.srcElement);
        this.updateClcMap(inquiryId || selDraftId);
        if(this.horizontalStyle){
          this.closeTab()
        }
      },
    });
  }

  getClosest(elem, selector) {
    if (elem.currentTarget != null) {
      elem = elem.currentTarget;
    }

    for (; elem && elem !== document; elem = elem.parentNode) {
      if (elem.className) {
        if (elem.className.split(' ').indexOf('p-tabview-panel') != -1)
          return elem.id;
      }
    }
    return null;
  }

  saveDraft($event, draftId, notes) {
    this.confirmationService.confirm({
      message: 'Do you want to save changes?',
      accept: () => {
        this.userDataService
          .userUsageStats('Dashboard', 'Dashboard')
          .subscribe((result) => console.log('StartTime:' + new Date()));
        this.saveDraftToDatabase($event, draftId, notes);
      },
      reject: () => {
        this.userDataService
          .userUsageStats('New Message', 'New Message')
          .subscribe((result) => console.log('StartTime:' + new Date()));
      },
      acceptLabel: 'Ok',
      rejectLabel: 'Cancel',
    });
    this.userDataService
      .userUsageStats('New Message', 'Save Draft')
      .subscribe((result) => console.log('StartTime:' + new Date()));
    PiwikProUtils.trackJsonDataForEvent(
      this.userDataService.loggedInUserInfo.pivotProConfigEnabled,
      this.userDataService.loggedInUserInfo.userId,
      'Menu',
      'New Message - Save draft',
      'Click',
      'New Message - Save draft',
      0
    );
  }

  onReject() {
    this.messageService.clear('c');
    this.userDataService
      .userUsageStats('New Message', 'Save Draft')
      .subscribe((result) => console.log('EndTime:' + new Date()));
    PiwikProUtils.trackJsonDataForEvent(
      this.userDataService.loggedInUserInfo.pivotProConfigEnabled,
      this.userDataService.loggedInUserInfo.userId,
      'Menu',
      'New Message - Save draft',
      'Click',
      'New Message - Save draft',
      0
    );
  }

  scheduleInquiry($event, inquiryId, inquiryAction, convId) {
    if (this.isBlackListDomainInToCcBcc()) return false;
    if (this.validateNewQueryCount()) return false;
    if (
      this.isAttachmentVerificationRequired(this.parentForm.value.groupName)
    ) {
      if (!this.verifyAttachmentsSecurity()) return false;
    }
    if (
      this.validateRequestType(
        this.parentForm.value.groupName,
        this.parentForm.value.requestType
      )
    )
      return false;
    // Validate the required values
    this.validateAllFormFields(this.parentForm);
    if (this.parentForm.valid) {
      this.schEvent = $event;
      this.schInquiryId = inquiryId;
      this.schInquiryAction = inquiryAction;
      this.schConvId = convId;
      this.schMsgComp.scheduleTime = null;
      this.schMsgComp.validationMsg = '';
      this.schMsgComp.displaySchedule = true;
    }
    this.userDataService
      .userUsageStats('New Message', 'Schedule For Later')
      .subscribe((result) =>
        console.log('Schedule Inquiry EndTime:' + new Date())
      );
    PiwikProUtils.trackJsonDataForEvent(
      this.userDataService.loggedInUserInfo.pivotProConfigEnabled,
      this.userDataService.loggedInUserInfo.userId,
      'Menu',
      'New message - Schedule for later',
      'Click',
      'New message - Shedule for later',
      0
    );
  }
  openInNewTab($event, inquiryId, inquiryAction, convId) {
    this.newMessageService.setDraftSubject(inquiryId);
    this.tabDataService.sendTabData('DRAFT:' + inquiryId, 0);
    this.cancelNewMessage($event.srcElement);
        // this.updateClcMap(inquiryId || selDraftId);
  }
  onTimeChanged(date: Date): void {
    this.scheduleTime = date;
    this.messageService.add({
      key: 'custom',
      summary: 'Success Confirmation',
      detail: 'Your settings saved successfully !',
    });
    this.sendInquiry(
      this.schEvent,
      this.schInquiryId,
      this.schInquiryAction,
      this.schConvId,
      this.notes
    );
    this.inlineReplyScheduled.emit(false);
  }

  filterData(data, filterCLCorNlp = true) {
    let filteredContent = data;
    let index = data.indexOf(this.editorData.getPlaceholder());
    if (index != -1) {
      filteredContent = data.replace(this.editorData.getPlaceholder(), '');
    }

    if (filterCLCorNlp) {
      if (filteredContent.includes(QmaConstant.CLC_DELETE_SPAN)) {
        filteredContent = filteredContent.replace(
          /<span class="deleteColumnClc" style="float: right; cursor: pointer;">x<\/span>/g,
          ''
        );
      }

      if (filteredContent.includes(QmaConstant.NLP_DELETE_SPAN)) {
        filteredContent = filteredContent.replace(
          /<span class="deleteColumnNlp" style="float: right; cursor: pointer; margin-left: 5px;">x<\/span>/g,
          ''
        );
      }
    }
    return filteredContent;
  }

  saveDraftToDatabase($event, draftId, notes?: string[]) {
    let token = AppUtils.getToken();
    let content = this.filterData(this.editorData.getData(), false);
    let fontStyle = this.editorData.getSelectedFont();

    let groupName = this.isNull(this.parentForm.value.groupName)
      ? ''
      : this.parentForm.value.groupName;
    let inquirySource = this.isNull(this.parentForm.value.inquirySource)
      ? ''
      : this.parentForm.value.inquirySource;
    let inquirySubStatus = this.isNull(this.parentForm.value.inquirySubStatus)
      ? ''
      : this.parentForm.value.inquirySubStatus;
    let rootCause = this.isNull(this.parentForm.value.rootCause)
      ? ''
      : this.parentForm.value.rootCause;
    let processingRegion = this.isNull(this.parentForm.value.processingRegion)
      ? ''
      : this.parentForm.value.processingRegion;
    let tags = this.isNull(this.parentForm.value.tags)
      ? ''
      : this.parentForm.value.tags;
    let queryCount = this.isNull(this.parentForm.value.queryCount)
      ? ''
      : this.parentForm.value.queryCount;
    let gfpId = this.isNull(this.parentForm.value.gfpId)
      ? ''
      : this.parentForm.value.gfpId;
    let gfpName = this.isNull(this.parentForm.value.gfpName)
      ? ''
      : this.parentForm.value.gfpName;
    let gfcId = this.isNull(this.parentForm.value.gfcId)
      ? ''
      : this.parentForm.value.gfcId;
    let gfcName = this.isNull(this.parentForm.value.gfcName)
      ? ''
      : this.parentForm.value.gfcName;
    let subject = this.isNull(this.parentForm.value.subject)
      ? ''
      : this.parentForm.value.subject;
    let requestType = this.isNull(this.parentForm.value.requestType)
      ? ''
      : this.parentForm.value.requestType;
    //let draftId = this.newMessageService.getDraftId();
    let attachment = this.isNull(this.parentForm.value.attachments)
      ? []
      : this.parentForm.value.attachments;
    let skAccountNo = this.isNull(this.parentForm.value.skAccountNo)
      ? ''
      : this.parentForm.value.skAccountNo;
    let branch = this.isNull(this.parentForm.value.branch)
      ? ''
      : this.parentForm.value.branch;

    let note = [];
    if (notes.length > 0) {
      let addedNote = notes.filter((note) => undefined === note['userId']);
      if (addedNote.length > 0) {
        let noOfNotes = addedNote.length - 1;
        note.push(addedNote[noOfNotes]['comments']);
      }
    }

    let save = new SaveDraft(
      draftId,
      this.parentForm.value.toUsers,
      this.parentForm.value.ccUsers,
      groupName,
      this.urgentFlag.nativeElement.value,
      subject,
      requestType,
      attachment,
      'NEW DRAFT',
      false,
      false,
      token,
      true,
      fontStyle.name,
      fontStyle.size,
      inquirySource,
      rootCause,
      processingRegion,
      tags,
      queryCount,
      gfpId,
      gfpName,
      gfcId,
      gfcName,
      this.parentForm.value.bccUsers,
      note,
      inquirySubStatus
    );

    save.skAccountNo = skAccountNo;
    save.branch = branch;

    this.newMessageService.saveDraft(save, content).subscribe(
      (result) => {
        console.log('result of saving draft:', result);
        //C153176-4495 - draft issue fix
        if ($event.srcElement) this.cancelNewMessage($event.srcElement);
        this.newMessageService.setInsertedDraftIds(result);
        this.setSelectedFont(fontStyle.name, fontStyle.size);
        this.updateClcMap(draftId);
      },
      (error) => {
        console.log('error while saving draft:', error);
      }
    );
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  sendInquiryAction(action) {
    this.sendInquiry(
      { srcElement: this.sendButton.nativeElement },
      this.inquiryId,
      action,
      this.suggestionConvId,
      this.notes
    );
  }

  validateInquirySubStatusEnabled(grpName) {
    this.enableInquirySubStatus = false;
    if (!AppUtils.isUndefinedNullOrBlank(grpName)) {
      this.userDataService
        .LocalGetLoginUserInfo()
        .subscribe((loginUserInfo) => {
          loginUserInfo.myGroups.forEach((fromData) => {
            if (
              !AppUtils.isUndefinedNullOrBlank(fromData) &&
              !AppUtils.isUndefinedNullOrBlank(fromData.groupName) &&
              fromData.groupName.toUpperCase() == grpName.toUpperCase()
            ) {
              if (
                (loginUserInfo.enableInquirySubStatusFlag &&
                  fromData.enableInquirySubStatus) ||
                loginUserInfo.isInquirySubStatusEnabledAtOrg
              ) {
                this.enableInquirySubStatus = true;
              }
            }
          });
        });
      if (
        this.enableInquirySubStatus &&
        (this.parentForm.controls['inquirySubStatus'].untouched ||
          this.parentForm.controls['inquirySubStatus'].pristine ||
          this.parentForm.controls['inquirySubStatus'].value === 'Select' ||
          this.parentForm.controls['inquirySubStatus'].value === '')
      ) {
        this.parentForm.controls['inquirySubStatus'].setValidators(
          Validators.required
        );
        this.parentForm.controls['inquirySubStatus'].setErrors({
          notUnique: true,
        });
        this.parentForm.controls['inquirySubStatus'].updateValueAndValidity();
        console.log('Inquiry Sub-Status should be mandatory');
      } else {
        this.parentForm.get('inquirySubStatus').clearValidators();
        this.parentForm.get('inquirySubStatus').setErrors(null);
        this.parentForm.get('inquirySubStatus').updateValueAndValidity();
      }
    }
  }
  sendInquiry($event, inquiryId, inquiryAction, convId, notes) {
    // C153176-5273: prevent from firing request multiple times consecutively
    if (AppUtils.isStoppedPropagation($event)) {
      console.debug('skip sendInquiry due to event stopped propagation');
      return;
    }
    // if one of email id is invalid disable send Button
    // C170665-1290 Reply :Email Addresses drop off without notifiing user.
    if (!this.validateAllRecepientsEmailIds()) {
      return;
    }
    // return;

    //TODO:Performance :: start time
    let strtTime = performance.now();
    //[C170665-1719] DCC Requirement: Add Case status field
    this.validateInquirySubStatusEnabled(this.parentForm.value.groupName);

    // C170665-320 : QMA can not BCC/CC if TO is blank
    if (
      this.parentForm.value.bccUsers.length > 0 ||
      this.parentForm.value.ccUsers.length > 0
    ) {
      this.parentForm.get('toUsers').clearValidators();
      this.parentForm.get('toUsers').updateValueAndValidity();
    } else {
      this.parentForm.get('toUsers').setValidators([Validators.required]);
      this.parentForm.get('toUsers').updateValueAndValidity();
    }
    //dcc

    if (this.isNull(inquiryAction)) this.inquiryAction = 'NEW INQUIRY';
    this.validateAllFormFields(this.parentForm);
    if (this.isBlackListDomainInToCcBcc()) return false;
    if (this.validateNewQueryCount()) return false;
    // Jira : C153176-4678 High level Request type type validation not performed on Schedule for later button
    if (
      this.validateRequestType(
        this.parentForm.value.groupName,
        this.parentForm.value.requestType
      )
    )
      return false;
    if (
      this.isAttachmentVerificationRequired(this.parentForm.value.groupName)
    ) {
      if (!this.verifyAttachmentsSecurity()) return false;
    }

    let formData = this.getGroupFromGroupName(this.parentForm.value.groupName);
    this.isMakerCheckerConfigured = this.updateApprovalRequired(formData);
    let attachment = this.isNull(this.parentForm.value.attachments)
      ? []
      : this.parentForm.value.attachments;
    let token = AppUtils.getToken();
    let resolve = false;
    let followup = '';
    if (
      this.inquiryAction === 'NEW INQUIRY' &&
      this.resolveFlag.nativeElement.value === 'Y'
    ) {
      resolve = true;
      this.inquiryAction = 'NEW INQUIRY RESOLVE';
      inquiryId = null;
    }
    // C153176-5155: if the action is tagged with 'Resolve', do nothing, in particular, do not fall into the final else case (which would set the inquiryId to null)
    else if (
      this.inquiryAction === 'Reply Resolve' ||
      this.inquiryAction === 'ReplyAll Resolve' ||
      this.inquiryAction === 'NEW INQUIRY RESOLVE'
    ) {
      // do nothing
    } else if (
      this.inquiryAction === 'Reply' &&
      this.resolveFlag.nativeElement.value === 'Y'
    ) {
      resolve = true;
      this.inquiryAction = 'Reply Resolve';
    }
    //Jira : 4629 : Reply with Thank + Resolve Issue
    // C153176-5066 : Reply Thank You or Reply Later now consider inquiryAction as reply.
    else if (
      this.inquiryAction === 'ReplyAll' &&
      this.resolveFlag.nativeElement.value === 'Y'
    ) {
      resolve = true;
      this.inquiryAction = 'ReplyAll Resolve';
    } else {
      resolve = false;
      this.inquiryAction =
        inquiryId && inquiryAction ? inquiryAction : this.inquiryAction;
      // C153176-4605 forward check
      if (
        this.inquiryAction === 'Reply' ||
        this.inquiryAction === 'ReplyAll' ||
        this.inquiryAction === 'Forward'
      ) {
        inquiryId = inquiryId;
      } else {
        inquiryId = null;
      }
    }
    // C153176-4988 31 Jan Prod Issue - same correspondence with the client was splitted into different cases
    // restrict api call if inquity id is null for any sceanario other than new inquiry.
    if (
      this.inquiryAction === 'Reply Resolve' ||
      this.inquiryAction === 'ReplyAll Resolve' ||
      (this.inquiryAction === 'Reply' &&
        this.resolveFlag.nativeElement.value === 'Y') ||
      this.inquiryAction === 'Reply' ||
      this.inquiryAction === 'ReplyAll' ||
      this.inquiryAction === 'Forward'
    ) {
      if (this.isNull(inquiryId)) {
        return false;
      }
    }
    if (this.followupFlag.nativeElement.value === 'Y') followup = 'Generic';
    else followup = '';

    // C153176-4475: filter data
    let content = this.filterData(this.editorData.getData());
    let fontStyle = this.editorData.getSelectedFont();

    let parentConversation = '';
    if (this.isNull(this.parentConversation)) parentConversation = '';
    else parentConversation = this.parentConversation;

    //C153176-4605 |  Reset the externalAddresses arrays for external email address check.
    this.externalAddresses = [];
    this.externalDomainsEmailIds = [];
    this.externalDomainsEmails = [];

    this.checkExternalAddressesAndEmailIds(this.parentForm.value.toUsers);
    this.checkExternalAddressesAndEmailIds(this.parentForm.value.ccUsers);
    this.checkExternalAddressesAndEmailIds(this.parentForm.value.bccUsers);

    let externalDomains = this.externalAddressesAndEmailIds.get(
      'externalAddressesArray'
    );
    let externalDomainsEmailIds = this.externalAddressesAndEmailIds.get(
      'externalDomainsEmailIdsArray'
    );
    let draftId = this.selDraftId;

    //C153176-4634 | Newly added notes - We want to make sure only one note should be avaiable in request
    let note = [];
    if (notes.length > 0) {
      //C153176-6039 | Add note model change for date while WS re-design
      let addedNote = notes.filter((note) => 'messages' === note['origin']);
      if (addedNote.length > 0) {
        note = addedNote.map((item) => item.comments);
      }
    }

    let groupName = this.isNull(this.parentForm.value.groupName)
      ? ''
      : this.parentForm.value.groupName;
    let inquirySource = this.isNull(this.parentForm.value.inquirySource)
      ? ''
      : this.parentForm.value.inquirySource;
    let inquirySubStatus = this.isNull(this.parentForm.value.inquirySubStatus)
      ? ''
      : this.parentForm.value.inquirySubStatus;
    let rootCause = this.isNull(this.parentForm.value.rootCause)
      ? ''
      : this.parentForm.value.rootCause;
    let processingRegion = this.isNull(this.parentForm.value.processingRegion)
      ? ''
      : this.parentForm.value.processingRegion;
    let tags = this.isNull(this.parentForm.value.tags)
      ? ''
      : this.parentForm.value.tags;
    let queryCount = this.isNull(this.parentForm.value.queryCount)
      ? ''
      : this.parentForm.value.queryCount;
    let gfpId = this.isNull(this.parentForm.value.gfpId)
      ? ''
      : this.parentForm.value.gfpId;
    let gfpName = this.isNull(this.parentForm.value.gfpName)
      ? ''
      : this.parentForm.value.gfpName;
    let gfcId = this.isNull(this.parentForm.value.gfcId)
      ? ''
      : this.parentForm.value.gfcId;
    let gfcName = this.isNull(this.parentForm.value.gfcName)
      ? ''
      : this.parentForm.value.gfcName;
    let subject = this.isNull(this.parentForm.value.subject)
      ? ''
      : this.parentForm.value.subject;
    let requestType = this.isNull(this.parentForm.value.requestType)
      ? ''
      : this.parentForm.value.requestType;
    let skAccountNo = this.isNull(this.parentForm.value.skAccountNo)
      ? ''
      : this.parentForm.value.skAccountNo;
    let branch = this.isNull(this.parentForm.value.branch)
      ? ''
      : this.parentForm.value.branch;

    // C153176-4389 : As per analysis, On reply action only two field are required
    // 1. text - display name .
    // 2. value - emailid for individual or group id for group.
    const toRecipents = this.modifiedRecipients(this.parentForm.value.toUsers);
    const ccRecipents = this.modifiedRecipients(this.parentForm.value.ccUsers);
    const bccRecipents = this.modifiedRecipients(
      this.parentForm.value.bccUsers === "" ? null : this.parentForm.value.bccUsers
    );

    if (
      inquirySource == 'Taskize' &&
      this.taskizeEnabled &&
      this.inquiryAction != 'Reply' &&
      this.inquiryAction != 'ReplyAll' &&
      this.inquiryAction != 'Forward'
    ) {
      let sendMailForTaskizeInquiry =
        this.taskizeService.checkTaskizeTokenExpiryForNewInquiry(
          this.userDataService.loggedInUserInfo
        );
      if (!sendMailForTaskizeInquiry) {
        return false;
      }
    }
    let requestObj = new SendInquiry(
      draftId,
      toRecipents,
      ccRecipents,
      groupName,
      this.urgentFlag.nativeElement.value,
      subject,
      requestType,
      attachment,
      this.inquiryAction,
      resolve,
      this.isMakerCheckerConfigured,
      false,
      token,
      true,
      fontStyle.name,
      fontStyle.size,
      inquirySource,
      rootCause,
      processingRegion,
      tags,
      queryCount,
      gfpId,
      gfcId,
      gfpName,
      gfcName,
      bccRecipents,
      null,
      parentConversation,
      inquiryId,
      convId,
      followup,
      this.scheduleTime,
      inquirySubStatus,
      note
    );

    // C170665-5 | Add Account and Branch
    requestObj.skAccountNo = skAccountNo;
    requestObj.branch = branch;

    // C153176-4459 : if the reply is NLP Suggestion, then we need to add suggestion property.
    if (this.nlpSuggestion) {
      requestObj.suggestion = this.nlpSuggestion;
      requestObj.responseEditedFlag = this.editorData.contentEdited
        ? this.editorData.contentEdited
        : false;
    }

    // C153176-4594 - #1 | CLC Selected trades should be included in request.
    if (this.selectedCLCTrades && this.selectedCLCTrades.length > 0) {
      requestObj.selectedCLCTrades = this.selectedCLCTrades;
    }

    // C153176-4594 - #5 : if the reply is CLC Suggestion, then we need to add suggestion property.
    if (this.clcSuggestion) {
      requestObj.clcSuggestion = this.clcSuggestion;
    }

    if (!this.isNull(externalDomains) && externalDomains.length > 1) {
      this.showExternalEmailAddressPopupMessage($event, requestObj, content);
    } else {
      if (
        this.isNull(this.parentForm.value.subject) &&
        this.parentForm.status === 'VALID'
      )
        this.showSubjectMessage($event, requestObj, content);
      else if (
        !this.isNull(this.parentForm.value.subject) &&
        this.parentForm.status !== 'INVALID'
      )
        this.sendMail($event, requestObj, content);
    }

    //TODO:Performance :: End Time.
    let endTime = performance.now();
    console.log(
      `QMA2.0 Performance :: New Message Actions - sendInquiry() : ${Math.ceil(
        endTime - strtTime
      )} miliseconds`
    );
  }

  showSubjectMessage($event, requestObj, content) {
    this.confirmationService.confirm({
      message: 'Do you want to create a new inquiry without a subject?',
      accept: () => {
        this.sendMail($event, requestObj, content);
      },
      acceptLabel: 'Ok',
      rejectLabel: 'Cancel',
    });
  }

  showExternalEmailAddressPopupMessage($event, requestObj, content) {
    this.confirmationService.confirm({
      message:
        '<b>The mail you are going to send has more than one external email domain - </b><br>' +
        this.externalDomainsEmailIds +
        '.<br><br><b>Do you want to proceed?</b><br><br>',
      accept: () => {
    
      },
      reject: () => {
        this.sendMail($event, requestObj, content);
      },
    });
  }

  sendMail($event, requestObj, content) {
    const str = 'conatinerDivForCLCTable';
    if (content.includes(str)) {
      const removeVal = '<span style="float: right; cursor: pointer;">x</span>';
      content = content.replace(new RegExp(removeVal, 'gi'), '');
      content = content.replace(
        new RegExp(str, 'gi'),
        'containerDivForCLCTable'
      );
    }
    let requestObjSchedule: any = this.createRequestObjSchdForLater(requestObj);
    if (!this.isNull(requestObjSchedule.action)) {
      requestObj = requestObjSchedule;
      this.newMessageService
        .saveScheduleForLater(requestObj, content)
        .subscribe(
          (result) => {
            console.log('result of sch for later sending email:', result);
            this.cancelNewMessage($event.srcElement);
            this.getAttachments.emit('');
            this.setSelectedFont(
              requestObj.editorFontNameId,
              requestObj.editorFontSizeId
            );
            this.updateClcMap(requestObj.inquiryId || requestObj.draftId);
            // once msg is scheduled, insert the draft in drafts folder 4466
            this.newMessageService.setInsertedDraftIds(result);
          },
          (error) => {
            console.log('error while sch for later sending email:', error);
          }
        );
    } else {
      this.deleteDraftById(); // C153176-5124 : Draft only delete for send drafted mail
      this.newMessageService.saveInquiry(requestObj, content).subscribe(
        (result) => {
          //this.messageService.add({ key: 'custom', summary: 'Success Confirmation', detail: 'New inquiry created successfully !' });
          //this.inlineReplySend.emit(true); // C153176-4605 - Fwd functionality issue fixed
          console.log('result of sending email:', result);
          this.cancelNewMessage($event.srcElement);
          this.getAttachments.emit('');
          this.setSelectedFont(
            requestObj.editorFontNameId,
            requestObj.editorFontSizeId
          );
          this.updateClcMap(requestObj.inquiryId || requestObj.draftId);
          if (this.horizontalStyle) {
            this.closeTab();
          }
        },
        (error) => {
          // adding error popup if saveInquiry fails
          let displayMessage = 'Something went wrong. Please contact Support';
          this.messageService.add({
            severity: 'error',
            key: 'sendInquiry',
            summary: '',
            detail: displayMessage,
          });
          console.log('error while sending email:', error);
        }
      );
    }
    // C153176-4988 31 Jan Prod Issue - same correspondence with the client was splitted into different cases
    // disable sent button once user click on send
    this.isSent = true;
    this.inlineReplySend.emit(true); // C153176-4605 - Fwd functionality issue fixed
    this.cancelInlineReply.emit('true');
  }
  closeTab() {
    let tabName = 'DRAFT:' + this.inquiryId;
    this.tabDataService.removeTab(tabName);
  }

  createRequestObjSchdForLater(requestObj: any) {
    let action: string;
    if (!this.isNull(requestObj.scheduleTime)) {
      action = this.populateActionForSchedule(requestObj.inquiryAction);
    }
    let scheduleForLater: string = this.dateFormatPipe.transform(
      requestObj.scheduleTime,
      'dd/MM/yyyy HH:mm:ss zzzz'
    );
    let requestObjSchedule: any = new ScheduleModel(
      requestObj.draftId,
      scheduleForLater,
      requestObj.inquiryId,
      requestObj.to,
      requestObj.cc,
      requestObj.from,
      requestObj.urgentFlag,
      requestObj.subject,
      requestObj.requestType,
      requestObj.attachment,
      action,
      requestObj.makerCheckerRqd,
      requestObj.approvalBypassed,
      AppUtils.getToken(),
      requestObj.isUserEditorPrefUpdated,
      requestObj.editorFontNameId,
      requestObj.editorFontSizeId,
      requestObj.inquirySource,
      requestObj.rootCause,
      requestObj.processingRegion,
      requestObj.tags,
      requestObj.queryCount,
      requestObj.gfpId,
      requestObj.gfcid,
      requestObj.gfpName,
      requestObj.gfcName,
      requestObj.BCC,
      requestObj.notes
    );

    // C170665-5 | Add Account and Branch
    requestObjSchedule.skAccountNo = requestObj.skAccountNo;
    requestObjSchedule.branch = requestObj.branch;
    return requestObjSchedule;
  }
  populateActionForSchedule(inquiryAction: string): string {
    let action: string;
    switch (inquiryAction) {
      case 'NEW INQUIRY':
        action = 'Scheduled For Later - New Inquiry';
        break;
      //Jira : 4681 : Schedule for Later issue
      case 'NEW INQUIRY RESOLVE':
        action = 'Scheduled For Later - New Inquiry Resolve'; //Jira : C153176-5103 : Schedule for Later
        break;

      case 'ReplyAll':
        action = 'Scheduled For Later - ReplyAll';
        break;

      case 'Reply':
        action = 'Scheduled For Later - Reply';
        break;
      //Jira : C153176-4681 : 1st Issue (Schedule for Later Reply+Resolve)
      case 'Reply Resolve':
        action = 'Scheduled For Later - Reply Resolve';
        break;

      case 'Forward':
        action = 'Scheduled For Later - Forward';
        break;
    }
    return action;
  }
  deleteDraftById() {
    // let draftId = this.newMessageService.getDraftId();
    let draftId = this.selDraftId;
    if (!this.isNull(draftId)) {
      this.newMessageService.deleteDraftById(draftId).subscribe(
        (response) => {
          console.log('Result : ', response);
          // Once the draft is sent, it should be null.
          this.selDraftId = null;
          // it is being used to delete draft id from drafts folder
          this.newMessageService.setsentDraftId(draftId);
        },
        (error) => {
          console.log('error : ', error);
        }
      );
    }
  }

  checkExternalAddressesAndEmailIds(emailids) {
    for (let i = 0; i < emailids.length; i++) {
      if (
        !AppUtils.isUndefinedOrNull(i) &&
        !AppUtils.isUndefinedNullOrBlank(emailids[i].value) &&
        AppUtils.validateEmail(emailids[i].value) &&
        !AppUtils.isCitiDomainEmail(emailids[i].value, this.citiDomains)
      ) {
        let mailId = emailids[i].value;
        mailId = mailId.substr(mailId.indexOf('@') + 1, mailId.length);
        // Duplicate domain prevention
        if (
          this.externalAddresses.length == 0 ||
          this.externalAddresses
            .toString()
            .toLowerCase()
            .indexOf(mailId.toLowerCase()) == -1
        ) {
          let mailValue = emailids[i].value;
          if (mailValue.indexOf('#') != -1) {
            mailValue = mailValue.replace(/#/g, '\\#');
          }
          this.externalDomainsEmailIds.push(
            '<br>' + '<i>' + mailValue + '</i>'
          );
          this.externalAddresses.push(mailId);
          this.externalDomainsEmails.push(mailValue);
        }
      }
      this.externalAddressesAndEmailIds.set(
        'externalAddressesArray',
        this.externalAddresses
      );
      this.externalAddressesAndEmailIds.set(
        'externalDomainsEmailIdsArray',
        this.externalDomainsEmailIds.join('<br>')
      );
      this.externalAddressesAndEmailIds.set(
        'externalDomainsEmailsArray',
        this.externalDomainsEmails
      );
    }
  }

  uploadFile() {
    // C153176-5051 : User able to upload same file again and again.
    this.uploadFiles.nativeElement.value = '';
    this.uploadFiles.nativeElement.click();
    this.userDataService
      .userUsageStats('New Message', 'Upload Attachment')
      .subscribe((result) => console.log('Time:' + new Date()));
    PiwikProUtils.trackJsonDataForEvent(
      this.userDataService.loggedInUserInfo.pivotProConfigEnabled,
      this.userDataService.loggedInUserInfo.userId,
      'Menu',
      'New message - Upload attachment',
      'Click',
      'New message - Upload attachment',
      0
    );
  }

  getDraftById(draftId) {
    this.newMessageService.getDraftById(draftId).subscribe(
      (result) => {
        let attachments = [];
        console.log('result of fetching draft:', result);
        let recipients = result.draft.recipients;
        let from = '';
        let urgentFlag = result.draft.urgentFlag;
        this.imp = result.draft.urgentFlag === 'N' ? false : true;
        this.markImportant();
        this.toUsersList = [];
        this.ccUsersList = [];
        this.bccUsersList = [];
        for (let rec of recipients) {
          if (rec.toFrom === 'TO')
            this.toUsersList.push({
              text: rec.displayName,
              value: rec.emailAddr,
              email: rec.emailAddr,
              id: rec.groupId,
              country: '',
              timeZone: '',
              active: true,
            });
          if (rec.toFrom === 'CC')
            this.ccUsersList.push({
              text: rec.displayName,
              value: rec.emailAddr,
              email: rec.emailAddr,
              id: rec.groupId,
              country: '',
              timeZone: '',
              active: true,
            });
          if (rec.toFrom === 'BCC')
            this.bccUsersList.push({
              text: rec.displayName,
              value: rec.emailAddr,
              email: rec.emailAddr,
              id: rec.groupId,
              country: '',
              timeZone: '',
              active: true,
            });
          if (rec.toFrom === 'FROM') from = rec.displayName;
        }
        let data = [
          {
            templateContent: result.draft.content,
            templateSubject: result.draft.subject,
            toList: this.toUsersList,
            ccList: this.ccUsersList,
            bccList: this.bccUsersList,
          },
        ];
        //this.getContactsService.setFormatTemplate(data);
        // this.formatTemplate.emit(data);
        // this.getContactsService.setBody(result.draft.content);
        let recData = {
          from: from,
          queryCount: result.draft.queryCount,
          requestType: result.draft.requestType,
          inquirySource: result.draft.inquirySource,
          processingRegion: result.draft.processingRegion,
          rootCause: result.draft.rootCause,
          gfcName: result.draft.gfcName,
          gfcid: result.draft.gfcid,
          gfpName: result.draft.gfpName,
          gfpid: result.draft.gfpid,
          attachments: result.draft.attachments,
          userNotes: result.draft.userNotes,
          fromWhere: 'messages',
          inquirySubStatus: result.draft.inquirySubStatus,
        };
        this.notesEvent.emit(result.draft.userNotes);

        // C153176-4681 | Pass correct action for reply or reply all for draft
        let action = this.inquiryAction;
        if (null != result.draft.scheduleForLater) {
          action = this.getInquiryAction(result.draft.action);
        }

        const replyActionObj = {
          inquiryId: draftId,
          inquiryAction: action,
          suggestionConvId: result.draft.id,
          userNotes: result.draft.userNotes,
          templateData: data,
          content: result.draft.content,
          recipientData: recData,
          urgentFlag: urgentFlag,
          attachments: result.draft.attachments,
          draftedInquiryId: result.draft.inquiryId || null,
        };

        this.newMessageService.setInquiryReplyData(replyActionObj);
        //this.newMessageService.setInquiryReplyData()
        // this.getContactsService.setRecipientData(recData);
        // this.toBccData.emit(recData);
        // this.getReplyContents(recData);

        // 4431 - Show message if it is scheduled
        if (null != result.draft.scheduleForLater) {
          this.errMsg =
            'This Message is scheduled on <b>' +
            this.dateFormatPipe.transform(
              result.draft.scheduleForLater,
              'dd MMM hh:mm a'
            ) +
            '</b>';
          this.showErrorDialog();
        }
      },
      (error) => {
        console.log('error while fetching draft:', error);
      }
    );
  }

  /**
   * Method to get the reply action contents to populate the new message component.
   */
  getReplyContents(conversationDetails: any): void {
    if (this.isNull(conversationDetails)) {
      return;
    }

    //TODO:Performance :: start time
    let strtTime = performance.now();

    this.groupName = conversationDetails.recipientData.from;
    this.parentForm
      .get('toUsers')
      .setValue(this.conversationObj.templateData[0].toList);
    this.parentForm
      .get('ccUsers')
      .setValue(this.conversationObj.templateData[0].ccList);
    this.parentForm
      .get('bccUsers')
      .setValue(this.conversationObj.templateData[0].bccList);
    //TODO:Performance :: getReplyContents is gettingcalled from ngChagnes, then updateSecure is also getting called.
    // so no need to call here.
    // this.updateSecure();

    // C153176-4681 | Check if the inquiry is saved in draft, we have draftedInquiryId.
    this.inquiryId = !this.isNull(conversationDetails.draftedInquiryId)
      ? conversationDetails.draftedInquiryId
      : conversationDetails.inquiryId;
    if (this.parentComponent) {
      this.parentComponent.setInquiryId(this.inquiryId);
    }
    this.inquiryAction = conversationDetails.inquiryAction;
    this.suggestionConvId = conversationDetails.suggestionConvId;
    this.imp = conversationDetails.urgentFlag === 'N' ? false : true;
    this.markImportant();
    this.parentConversation = conversationDetails.suggestionConvId;
    this.notesEvent.emit(conversationDetails.userNotes);

    // C153176-4459:  Flag to keep the nlp suggestion
    this.nlpSuggestion = conversationDetails.nlpSuggestion;

    // C153176-4594 | To keep the selected CLC trades.
    this.selectedCLCTrades = conversationDetails.selectedCLCTrades;

    // C153176-4594 |  Flag to keep the clc suggestion
    this.clcSuggestion = conversationDetails.clcSuggestion;

    // C153176-5298 | Check if locked inquiry should be resolve
    this.lock = conversationDetails.lock;
    this.lockedBy = conversationDetails.lockedBy;

    this.setFollowupStatus(conversationDetails);
    //TODO:Performance :: End Time.
    let endTime = performance.now();
    console.log(
      `QMA2.0 Performance :: New Message Actions - getReplyContents() : ${Math.ceil(
        endTime - strtTime
      )} miliseconds`
    );
  }

  ngOnDestroy(): void {
    if (this.draftSubscription) {
      this.draftSubscription.unsubscribe();
    }
    if (this.replySubscription) {
      this.replySubscription.unsubscribe();
    }
    if (this.draftOnTabCloseSubscription) {
      this.draftOnTabCloseSubscription.unsubscribe();
    }
    if (this.actionSubscription) {
      this.actionSubscription.unsubscribe();
    }
    if (this.rowDragDropSubscription) {
      this.rowDragDropSubscription.unsubscribe();
    }
    if (this.viewClickedSubscription) {
      // C153176-5273
      this.viewClickedSubscription.unsubscribe();
    }
    if (this.editorFocusSubscription) {
      // C153176-5273
      this.editorFocusSubscription.unsubscribe();
    }
  }

  fileEvent(filelist) {
    console.debug('fileEvent, filelist =', filelist);
    // C153176-5051 | User get the popup when trying to upload file which exceeds the size.
    for (let i = 0; i < filelist.length; i++) {
      if (filelist[i].size > QmaConstant.ATTACHMENT_ALLOWED_SIZE) {
        let fileName = filelist[i].name;
        let fileSize = QmaConstant.ATTACHMENT_ALLOWED_SIZE / (1024 * 1024);
        this.errMsg =
          '<b>' +
          fileName +
          '</b> exceeds the maximum allowed file size of <b>' +
          fileSize +
          ' MB</b>.';
        this.showErrorDialog();
        return;
      }

      // C153176-5051 | User get the popup when trying to upload files which exceeds the maximum request size.
      this.totalAttachmentSize += filelist[i].size;
      if (this.totalAttachmentSize > QmaConstant.ALL_ATTACHMENT_SIZE) {
        this.errMsg =
          'Attachment size exceeds the maximum allowed size of 60 MB.';
        this.showErrorDialog();
        return;
      }
    }
    // C170665-255 Restrict specific file extensions in QMA 2.0
    this.processedFiles = AppUtils.getValidFilesTobeUploadedByExtension(
      filelist,
      this.userDataService.loggedInUserInfo.allowedFileExtensions,
      this.userDataService.loggedInUserInfo.blockedFileNameCharacters
    );
    filelist = this.processedFiles.validFiles;
    if (
      this.processedFiles.filenameContainingRestrictedChars &&
      this.processedFiles.filenameContainingRestrictedChars.length &&
      this.processedFiles.filenameContainingRestrictedChars.length > 0
    ) {
      this.setFileNameWithRestrictedCharacters(
        this.processedFiles.filenameContainingRestrictedChars
      );
      this.showErrorDialog();
    }

    // show error pop up if some of files uploaded are not permitted
    if (
      this.processedFiles &&
      this.processedFiles.inValidFiles &&
      this.processedFiles.inValidFiles.length > 0 &&
      !this.attachmentPwdProtectionFlag
    ) {
      // this.errMsg = QmaConstant.QMA_INVALID_FILES_MSG;
      this.setRestrictedFileNames(this.processedFiles.inValidFiles);
      this.showErrorDialog();
    }
    // show notification pop up if files uploaded contains multiple dots
    if (
      this.processedFiles &&
      this.processedFiles.fileNameWithMultipleDots &&
      this.processedFiles.fileNameWithMultipleDots.length > 0 &&
      !this.attachmentPwdProtectionFlag
    ) {
      // this.errMsg = QmaConstant.QMA_INVALID_FILES_MSG;
      this.setMultipleDotFileNames(
        this.processedFiles.fileNameWithMultipleDots
      );
      this.showErrorDialog();
    }
    this.files = filelist;

    let data = [];
    if (this.attachmentPwdProtectionFlag) {
      this.showPasswordProtectionWindow(this.files);
    } else {
      data.push({
        filesTobePwdProtected: '',
        fileInfo: '',
        filesAlreadySecured: '',
        attachmentPwdProtectionFlag: this.attachmentPwdProtectionFlag,
      });
      this.uploadFilesToServer(data);
    }

    this.lisfOfUploadedFiles = [];
  }

  uploadFilesToServer(newdata) {
    let data = new FormData();
    let filesTobePwdProtected = newdata[0].filesTobePwdProtected;
    let fileInfo = newdata[0].fileInfo;
    let filesAlreadySecured = newdata[0].filesAlreadySecured;
    let attachmentPwdProtectionFlag = newdata[0].attachmentPwdProtectionFlag;

    data.append('filesTobePwdProtected', filesTobePwdProtected);
    data.append('fileInfo', fileInfo);
    data.append('filesAlreadySecured', filesAlreadySecured);
    data.append('attachmentPwdProtectionFlag', attachmentPwdProtectionFlag);

    if (this.files) {
      // C153176-5343: extract file names for filtering
      let fileNames = newdata[0].filesTobePwdProtected
        ? newdata[0].filesTobePwdProtected.split('|')
        : [];
      fileNames = fileNames.concat(
        newdata[0].filesAlreadySecured
          ? newdata[0].filesAlreadySecured.split('|')
          : []
      );

      for (let i = 0; i < this.files.length; i++) {
        var ele = this.files[i];
        // C153176-5343: filter file name if the fileNames are populated with protected/secured entries
        if (
          ele &&
          ele.name &&
          (!fileNames || !fileNames.length || fileNames.includes(ele.name))
        ) {
          data.append('files', ele, ele.name);
        } else {
          data.append('files', ele, ele.name); // C153176-5682 | Issue- Unable to attach more that 11 attachments in QMa 2.0
        }
      }
    }

    this.newMessageService.uploadMultipleFiles(data).subscribe(
      (result) => {
        console.log('result of file upload:', result);
        //this.newMessageService.setAttachmentData(result);
        this.attachments = result;
        this.getAttachments.emit(result);
        if (this.isExternalEmailInToCc()) {
          let formData = this.getGroupFromGroupName(
            this.parentForm.value.groupName
          );
          this.isMakerCheckerConfigured = this.updateApprovalRequired(formData);
        }
        if (result && Array.isArray(result) && result.length === 0) {
          this.displayApiError = true;
        }
      },
      (error) => {
        console.log('error while upload :', error);
        this.displayApiError = true;
      }
    );
    this.lisfOfUploadedFiles = [];
    this.showAllSupportedUnSecuredFilesList = [];
    this.showAllSupportedSecuredFilesList = [];
    this.showAllUnSupportedFilesList = [];
    this.selectUnselectCheckboxes(false);
    this.attachmentSelectAllCheckBox.nativeElement.disabled = false;
    this.parentComponent.dropInProcessing = false;
  }

  showPasswordProtectionWindow(files) {
    this.selectUnselectCheckboxes(false);
    this.attachmentSelectAllCheckBox.nativeElement.checked = false;
    this.attachmentSelectAllCheckBox.nativeElement.disabled = false;
    this.fileInfo.nativeElement.disabled = false;
    this.fileInfo.nativeElement.value = '';
    this.lisfOfUploadedFiles = [];

    let data = new FormData();
    if (files) {
      for (let i = 0; i < this.files.length; i++) {
        var ele = this.files[i];
        data.append('files', ele, ele.name);
      }
    }

    this.newMessageService.checkSecureUploadedFiles(data).subscribe(
      (lisfOfUploadedFiles) => {
        this.lisfOfUploadedFiles = lisfOfUploadedFiles;
        console.log(
          'result of checkSecureUploadedFiles:',
          this.lisfOfUploadedFiles
        );
        this.showAllFiles(this.lisfOfUploadedFiles);
        this.displayPasswordProtection = true;
      },
      (error) => {
        console.log('error while checkSecureUploadedFiles :', error);
      }
    );
    this.lisfOfUploadedFiles = [];
  }

  showAllFiles(allFilesList) {
    this.filesAlreadySecuredByUser = '';
    let isAllFilesSecured = true;
    if (!this.isNull(allFilesList) && allFilesList.length !== 0) {
      for (var idx = 0; idx < allFilesList.length; idx++) {
        let file = allFilesList[idx];
        if (file !== null) {
          let fileName = file.name;
          let fileExtension = fileName.substring(
            fileName.lastIndexOf('.') + 1,
            fileName.length
          );

          if (
            !this.isNull(fileExtension) &&
            fileExtension.length > 0 &&
            this.fileTypesSupportedForPasswordProtection.indexOf(
              fileExtension.toUpperCase()
            ) == -1
          )
            this.showAllUnSupportedFilesList.push({ fileName: fileName });
          else if (file.secure == 'Y')
            this.showAllSupportedSecuredFilesList.push({ fileName: fileName });
          else {
            this.showAllSupportedUnSecuredFilesList.push({
              fileName: fileName,
            });
            isAllFilesSecured = false;
          }

          if (file.secure == 'Y') {
            this.filesAlreadySecuredByUser =
              this.filesAlreadySecuredByUser + fileName + '|';
          }
        }
      }
    }

    console.log('filesAlreadySecuredByUser:' + this.filesAlreadySecuredByUser);
    if (isAllFilesSecured) {
      this.attachmentSelectAllCheckBox.nativeElement.disabled = true;
      this.fileInfo.nativeElement.disabled = true;
    }
    this.lisfOfUploadedFiles = [];
  }

  cancelPasswordProtection() {
    this.errMsg = '';
    this.displayPasswordProtection = false;
    if (
      this.processedFiles &&
      this.processedFiles.inValidFiles &&
      this.processedFiles.inValidFiles.length > 0
    ) {
      this.setRestrictedFileNames(this.processedFiles.inValidFiles);
      this.showErrorDialog();
    }
    this.showAllSupportedUnSecuredFilesList = [];
    this.showAllSupportedSecuredFilesList = [];
    this.showAllUnSupportedFilesList = [];
    this.lisfOfUploadedFiles = [];
    this.attachmentSelectAllCheckBox.nativeElement.disabled = false;
    this.passwordProtectionForm.get('fileInfo').setValue('');
  }

  saveAllAttachedFiles() {
    this.filesTobePwdProtected = '';
    this.errorMsg = '';
    let elements =
      this.showAllSupportedUnSecuredFiles.nativeElement.querySelectorAll(
        'input'
      );
    elements.forEach((element) => {
      if (element.checked) {
        let fileName = element.name;
        this.filesTobePwdProtected =
          this.filesTobePwdProtected + fileName + '|';
      }
    });
    let fileInfo = this.passwordProtectionForm.get('fileInfo').value;
    if (
      !AppUtils.isUndefinedOrNull(this.filesTobePwdProtected) &&
      this.filesTobePwdProtected.length > 0
    ) {
      // password is empty then return.
      if (!AppUtils.isUndefinedOrNull(fileInfo) && fileInfo.trim().length < 6) {
        this.errorMsg = 'Password should be minimum 6 character long. ';
        return false;
      }
    } else {
      if (!AppUtils.isUndefinedNullOrBlankStrAfterTrim(fileInfo)) {
        this.errorMsg = 'Select files for Password Protection. ';
        return false;
      }
    }
    console.log('filesTobePwdProtected:' + this.filesTobePwdProtected);
    console.log('fileInfo:' + fileInfo);
    let data = [];
    data.push({
      filesTobePwdProtected: this.filesTobePwdProtected,
      fileInfo: fileInfo,
      filesAlreadySecured: this.filesAlreadySecuredByUser,
      attachmentPwdProtectionFlag: this.attachmentPwdProtectionFlag,
    });
    this.uploadFilesToServer(data);
    this.displayPasswordProtection = false;
    if (
      this.processedFiles &&
      this.processedFiles.inValidFiles &&
      this.processedFiles.inValidFiles.length > 0
    ) {
      this.setRestrictedFileNames(this.processedFiles.inValidFiles);
      this.showErrorDialog();
    }
    this.lisfOfUploadedFiles = [];
    this.showAllSupportedUnSecuredFilesList = [];
    this.showAllSupportedSecuredFilesList = [];
    this.showAllUnSupportedFilesList = [];
    this.errorMsg = '';
    this.passwordProtectionForm.get('fileInfo').setValue('');
  }

  secure: boolean = false;
  markSecure() {
    if (!this.secure) {
      this.secureFlag.nativeElement.value = 'Y';
      this.secure = true;
      this.sendSecureEmail = true;
      this.addSecuretoSubject();
    } else {
      this.secureFlag.nativeElement.value = 'N';
      this.secure = false;
      this.sendSecureEmail = false;
      this.removeSecureFromSubject();
    }
  }

  addSecuretoSubject() {
    let subject = this.parentForm.value.subject;
    let securePrefix = '(Secure)';
    let replyPrefix = 'RE: ';
    let fwdPrefix = 'FW: ';

    if (!AppUtils.isUndefinedNullOrBlank(subject)) {
      //Trim Leading spaces
      subject = subject.replace(/^ +/gm, '');
      let secureInd = subject.indexOf(securePrefix);
      if (secureInd == -1) {
        let replyInd = subject.indexOf(replyPrefix);
        let fwdInd = subject.indexOf(fwdPrefix);
        if (replyInd == 0) {
          // Add secure after 'RE: ' in case of reply
          subject =
            subject.substring(0, replyPrefix.length) +
            securePrefix +
            subject.substring(replyPrefix.length);
        } else if (fwdInd == 0) {
          // Add secure after 'FW: ' in case of forward
          subject =
            subject.substring(0, fwdPrefix.length) +
            securePrefix +
            subject.substring(fwdPrefix.length);
        } else {
          subject = securePrefix + subject;
        }
      }
    } else {
      subject = securePrefix;
    }
    this.parentForm.get('subject').setValue(subject);
  }

  removeSecureFromSubject() {
    let subject = this.parentForm.value.subject;
    let securePrefix = '(Secure)';

    if (!this.isNull(subject)) {
      let secureInd = subject.indexOf(securePrefix);
      if (secureInd != -1) {
        subject =
          subject.substring(0, secureInd) +
          subject.substring(secureInd + securePrefix.length);
        this.parentForm.get('subject').setValue(subject);
      }
    }
  }

  selectAllFiles(values: any) {
    this.lisfOfUploadedFiles = [];
    let selectAll = values.currentTarget.checked;
    if (selectAll === true) this.selectUnselectCheckboxes(true);
    else this.selectUnselectCheckboxes(false);
  }

  selectUnselectCheckboxes(condition) {
    let elements =
      this.showAllSupportedUnSecuredFiles.nativeElement.querySelectorAll(
        'input'
      );
    elements.forEach((element) => {
      element.checked = condition;
    });
  }

  showPassword(value) {
    let showPass = value.currentTarget.checked;
    let fileInfo = this.fileInfo.nativeElement;
    if (fileInfo.type === 'password') this.fileInfo.nativeElement.type = 'text';
    else this.fileInfo.nativeElement.type = 'password';
  }

  markFollowUp() {
    if (!this.followup) {
      this.followupFlag.nativeElement.value = 'Y';
      this.followup = true;
    } else {
      this.followupFlag.nativeElement.value = 'N';
      this.followup = false;
    }
  }

  isBlackListDomainInToCcBcc() {
    //TODO:Performance :: start time
    let strtTime = performance.now();

    let toAddresses = this.parentForm.value.toUsers;
    let ccAddresses = this.parentForm.value.ccUsers;
    let bccAddresses = this.parentForm.value.bccUsers;
    let isBlackListDomainPresent = false;
    let blackListRecepients = [];

    if (this.blackListDomains) {
      let blackListDomains = new Object(this.blackListDomains);

      this.getBlackListRecepientsPresentInToField(
        toAddresses,
        blackListDomains,
        blackListRecepients
      );
      this.getBlackListRecepientsPresentInToField(
        ccAddresses,
        blackListDomains,
        blackListRecepients
      );
      this.getBlackListRecepientsPresentInToField(
        bccAddresses,
        blackListDomains,
        blackListRecepients
      );
    }

    if (blackListRecepients.length != 0) {
      this.errMsg =
        'The message cannot be sent.<br>Following email address contain black listed domains:';

      for (let idx = 0; idx < blackListRecepients.length; idx++) {
        if (!AppUtils.isUndefinedOrNull(blackListRecepients[idx])) {
          this.errMsg = this.errMsg + '<br>' + blackListRecepients[idx];
        }
      }
      console.log('Message contains black listed domains.');
      this.showErrorDialog();
      return true;
    }

    //TODO:Performance :: End Time.
    let endTime = performance.now();
    console.log(
      `QMA2.0 Performance :: New Message Actions -  isBlackListDomainInToCcBcc(): ${Math.ceil(
        endTime - strtTime
      )} miliseconds`
    );
    return false;
  }

  showErrorDialog() {
    this.displayError = true;
  }

  closeModal(_str: any) {
    if (_str == 'error') {
      this.displayError = false;
      this.errMsg = '';
    }
  }

  getBlackListRecepientsPresentInToField(
    toAddresses,
    blackListDomains,
    blackListRecepients
  ) {
    let isBlackListDomainPresent = false;
    if (!AppUtils.isUndefinedOrNull(toAddresses)) {
      for (let i in toAddresses) {
        if (toAddresses.hasOwnProperty(i)) {
          if (
            toAddresses[i].value &&
            toAddresses[i].value.indexOf('@') > -1 &&
            AppUtils.isDomainPresentInList(
              toAddresses[i].value,
              blackListDomains
            )
          ) {
            isBlackListDomainPresent = true;
            blackListRecepients.push(toAddresses[i].value);
          }
        }
      }
    }
  }

  validateNewQueryCount() {
    let isValidNewQueryCount = true;
    let errMsg = '';
    let newQueryCount = this.parentForm.value.queryCount;
    if (this.getlength(newQueryCount) >= 5) {
      errMsg = ' Please enter a query count from 1-9999 inclusive';
      this.parentForm.controls['queryCount'].setValidators(Validators.required);
      this.parentForm.controls['queryCount'].setErrors({ notUnique: true });
      this.parentForm.controls['queryCount'].updateValueAndValidity();
      this.newMessageService.setQueryCountValidation(errMsg);
      return isValidNewQueryCount;
    } else if (
      !AppUtils.isUndefinedNullOrBlank(newQueryCount) &&
      (/\D/.test(newQueryCount) || newQueryCount < 1)
    ) {
      errMsg = ' Please enter valid query count!';
      this.parentForm.controls['queryCount'].setValidators(Validators.required);
      this.parentForm.controls['queryCount'].setErrors({ notUnique: true });
      this.parentForm.controls['queryCount'].updateValueAndValidity();
      this.newMessageService.setQueryCountValidation(errMsg);
      return isValidNewQueryCount;
    } else {
      this.parentForm.get('queryCount').clearValidators();
      this.parentForm.get('queryCount').setErrors(null);
      this.parentForm.get('queryCount').updateValueAndValidity();
      this.newMessageService.setQueryCountValidation('test');
      isValidNewQueryCount = false;
      return isValidNewQueryCount;
    }
  }

  getlength(number) {
    let queryCountLength = 0;
    if (!AppUtils.isUndefinedNullOrBlank(number)) {
      queryCountLength = number.toString().length;
    }
    return queryCountLength;
  }

  getGroupFromGroupName(selectedFromGroup): MyGroup {
    // let group;
    // if (!this.isNull(selectedFromGroup)) {
    //   group = new MyGroup();
    //   this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
    //     loginUserInfo.myGroups.forEach(myGroup => {
    //       if (myGroup.groupName && myGroup.groupName.toUpperCase() == selectedFromGroup.toUpperCase())
    //         group = myGroup
    //     });
    //   });
    // }
    //return group;

    return this.userDataService
      .getAssignedGroups()
      .find(
        (grp) =>
          selectedFromGroup &&
          selectedFromGroup.toUpperCase() === grp.groupName.toUpperCase()
      );
  }

  hasAttachments(attachArr) {
    if (!AppUtils.isUndefinedOrNull(attachArr)) {
      for (let i = 0; i < attachArr.length; i++) {
        if (attachArr[i] != null) {
          return true;
        }
      }
    }
    return false;
  }

  updateApprovalRequired(fromData): boolean {
    //TODO:Performance :: start time
    let strtTime = performance.now();

    if (this.isNull(fromData)) {
      this.approvalReq = false;
      return false;
    }

    let ccAddresses = this.parentForm.value.ccUsers;
    let toAddresses = this.parentForm.value.toUsers;
    let bccAddresses = this.parentForm.value.bccUsers;
    let isMakerCheckerConfigured = false;
    console.log(
      'updateApprovalRequired: groupName=[' +
        fromData.groupName +
        '] makerCheckerRqd=[' +
        fromData.makerCheckerRqd +
        '] makerCheckerDomains=[' +
        fromData.makerCheckerDomains +
        ']'
    );
    // C170665-1969 Issue- Maker checker not triggered when attachment from previous email attached during reply.
    // check if prev attchements added through reply/replyall
    // as its not freshly uploaded through upload still need to trigger maker checker

    let hasAttach =
      this.parentForm.get('attachments') &&
      this.parentForm.get('attachments').value &&
      Array.isArray(this.parentForm.get('attachments').value) &&
      this.parentForm.get('attachments').value.length > 0
        ? true
        : false;

    console.log('updateApprovalRequired: hasAttachments --> ' + hasAttach);

    let isAttachmentReviewReqd = this.checkAttachmentForReview(
      hasAttach,
      fromData
    );

    if (
      isAttachmentReviewReqd &&
      fromData.makerCheckerRqd &&
      !AppUtils.isUndefinedOrNull(fromData.makerCheckerDomains) &&
      fromData.makerCheckerDomains.length > 0
    ) {
      isMakerCheckerConfigured = true;

      for (let i in toAddresses) {
        if (toAddresses.hasOwnProperty(i)) {
          if (
            toAddresses[i].value &&
            toAddresses[i].value.indexOf('@') > -1 &&
            !AppUtils.isCitiDomainEmail(
              toAddresses[i].value,
              this.citiDomains
            ) &&
            !this.excludeDomain(fromData, toAddresses[i])
          ) {
            this.enableApprReqdBtn();
            return true;
          }
        }
      }

      for (let i in ccAddresses) {
        if (ccAddresses.hasOwnProperty(i)) {
          if (
            ccAddresses[i].value &&
            ccAddresses[i].value.indexOf('@') > -1 &&
            !AppUtils.isCitiDomainEmail(
              ccAddresses[i].value,
              this.citiDomains
            ) &&
            !this.excludeDomain(fromData, ccAddresses[i])
          ) {
            this.enableApprReqdBtn();
            return true;
          }
        }
      }

      for (let i in bccAddresses) {
        if (bccAddresses.hasOwnProperty(i)) {
          if (
            bccAddresses[i].value &&
            bccAddresses[i].value.indexOf('@') > -1 &&
            !AppUtils.isCitiDomainEmail(
              bccAddresses[i].value,
              this.citiDomains
            ) &&
            !this.excludeDomain(fromData, bccAddresses[i])
          ) {
            this.enableApprReqdBtn();
            return true;
          }
        }
      }
    } else if (
      isAttachmentReviewReqd &&
      fromData.makerCheckerRqd &&
      (fromData.makerCheckerDomains == null ||
        (fromData.makerCheckerDomains != null &&
          fromData.makerCheckerDomains.length == 0))
    ) {
      isMakerCheckerConfigured = true;
      for (let i in toAddresses) {
        if (toAddresses.hasOwnProperty(i)) {
          if (
            toAddresses[i].value &&
            toAddresses[i].value.indexOf('@') > -1 &&
            !AppUtils.isCitiDomainEmail(toAddresses[i].value, this.citiDomains)
          ) {
            this.enableApprReqdBtn();
            return true;
          }
        }
      }

      for (let i in ccAddresses) {
        if (ccAddresses.hasOwnProperty(i)) {
          if (
            ccAddresses[i].value &&
            ccAddresses[i].value.indexOf('@') > -1 &&
            !AppUtils.isCitiDomainEmail(ccAddresses[i].value, this.citiDomains)
          ) {
            this.enableApprReqdBtn();
            return true;
          }
        }
      }

      for (let i in bccAddresses) {
        if (bccAddresses.hasOwnProperty(i)) {
          if (
            bccAddresses[i].value &&
            bccAddresses[i].value.indexOf('@') > -1 &&
            !AppUtils.isCitiDomainEmail(bccAddresses[i].value, this.citiDomains)
          ) {
            this.enableApprReqdBtn();
            return true;
          }
        }
      }
    }
    this.apprReqdFlag.nativeElement.value = 'N';
    this.parentForm.get('imageAttach').setValue(false);
    this.approvalReq = false;
    isMakerCheckerConfigured = false;

    //TODO:Performance :: End Time.
    let endTime = performance.now();
    console.log(
      `QMA2.0 Performance :: New Message Actions - updateApprovalRequired() : ${Math.ceil(
        endTime - strtTime
      )} miliseconds`
    );
  }

  excludeDomain(fromData, address) {
    let flag = false;
    for (let j in fromData.makerCheckerDomains) {
      if (fromData.makerCheckerDomains.hasOwnProperty(j)) {
        if (
          address.value
            .toLowerCase()
            .indexOf(fromData.makerCheckerDomains[j].toLowerCase()) > -1
        ) {
          flag = true;
        }
      }
    }
    return flag;
  }

  isNewInlineImageAttached(element) {
    let inlineImage = false;

    if (!AppUtils.isUndefinedOrNull(element)) {
      let text = element;
      let textAlt;
      if (
        !AppUtils.isUndefinedOrNull(text) &&
        text.toString().indexOf('<img src="data:image') !== -1
      ) {
        inlineImage = true;
      }
    }
    console.log('hasInlineImage=' + inlineImage);
    return inlineImage;
  }

  checkAttachmentForReview(attachment, fromData) {
    let editorBody = '';
    if (!this.isNull(this.editorData.getData())) {
      editorBody = this.editorData.getData();
    }
    let isInlineImageAttached = this.isNewInlineImageAttached(editorBody);
    let attachmentIncludedForReview = false;

    this.userDataService.LocalGetLoginUserInfo().subscribe((loginUserInfo) => {
      loginUserInfo.myGroups.forEach((myGroup) => {
        if (myGroup.id === fromData.id) {
          if (AppUtils.isUndefinedOrNull(myGroup.attachmentBasedMakerChecker)) {
            attachmentIncludedForReview = true;
          } else {
            attachmentIncludedForReview = myGroup.attachmentBasedMakerChecker;
          }
          return false;
        }
      });
    });

    if (attachmentIncludedForReview) {
      return attachment || isInlineImageAttached;
    } else {
      return true;
    }
  }

  enableApprReqdBtn() {
    this.approvalReq = true;
    this.parentForm.get('imageAttach').setValue(true);
    this.apprReqdFlag.nativeElement.value = 'Y';
  }

  validateRequestType(fromGrpName, inqRequestType): boolean {
    if (!this.isRequestTypeMappingDone(fromGrpName, inqRequestType)) {
      this.errMsg =
        "<div class='customDialogContentScroll'>Please map the request type: &nbsp;<font  color='blue'><b>" +
        inqRequestType +
        '</b></font>' +
        ' &nbsp;with Generic Request Type from the group admin screen for group:&nbsp;' +
        "<font  color='blue'><b>" +
        fromGrpName +
        '</b></font>' +
        '&nbsp;&nbsp;' +
        '<br />' +
        "<input type='button' class='k-button k-primary' id ='genericReqTypeCheck' style='margin-left:125px; width: 75px;' value='Ok' onclick='closeErrorDialog(this);'></div>";
      if (!AppUtils.isUndefinedNullOrBlank(inqRequestType)) {
        if (this.highlevelreqtypeFlag) {
          this.showErrorDialog();
          return true; // C153176-5058 : Hard stop
        }
      }
    }
  }

  isRequestTypeMappingDone(fromGrpName, inqRequestType): boolean {
    let isRequestTypeMappingDone = false;
    let fromData = this.getGroupFromGroupName(fromGrpName);
    let requestTypeMappings = fromData.requestTypeMappings;
    console.log('requestTypeMappings===============>' + requestTypeMappings);
    if (!AppUtils.isUndefinedNullOrBlank(requestTypeMappings)) {
      for (let idx = 0; idx < requestTypeMappings.length; idx++) {
        if (
          !AppUtils.isUndefinedNullOrBlank(requestTypeMappings[idx]) &&
          !AppUtils.isUndefinedNullOrBlank(
            requestTypeMappings[idx].requestType
          ) &&
          !AppUtils.isUndefinedNullOrBlank(
            requestTypeMappings[idx].highlevelRequestType
          )
        ) {
          let reqType = requestTypeMappings[idx].requestType;
          let highLevelReqType = requestTypeMappings[idx].highlevelRequestType;
          if (
            reqType == inqRequestType &&
            !AppUtils.isUndefinedNullOrBlank(highLevelReqType)
          ) {
            isRequestTypeMappingDone = true;
            break;
          } else {
            isRequestTypeMappingDone = false;
          }
        }
      }
    }
    return isRequestTypeMappingDone;
  }

  isAttachmentVerificationRequired(fromGrpName) {
    let isAttachmentVerificationRequired = false;
    let fromData = this.getGroupFromGroupName(fromGrpName);

    if (
      !this.isSubjectContainsSecure() &&
      this.attachmentVerificationMandatoryOrgs
    ) {
      let AttachVerificationManOrgs = this.attachmentVerificationMandatoryOrgs;
      if (
        fromData &&
        fromData.heirarchyData &&
        fromData.heirarchyData.organisation
      ) {
        for (
          var idx = 0;
          idx < fromData.heirarchyData.organisation.length;
          idx++
        ) {
          var org = fromData.heirarchyData.organisation[idx];
          if (
            !AppUtils.isUndefinedNullOrBlank(org) &&
            AttachVerificationManOrgs.indexOf(org) > -1
          ) {
            isAttachmentVerificationRequired = true;
            break;
          }
        }
      }
    }
    if (isAttachmentVerificationRequired) {
      isAttachmentVerificationRequired = this.isExternalEmailInToCc();
    }
    return isAttachmentVerificationRequired;
  }

  isSubjectContainsSecure(): boolean {
    let subjectSecureCheck = false;

    let subject = this.parentForm.value.subject;
    let securePrefix = '(Secure)';

    if (!AppUtils.isUndefinedNullOrBlank(subject)) {
      let secureInd = subject.indexOf(securePrefix);
      if (secureInd != -1) {
        subjectSecureCheck = true;
      }
    }
    return subjectSecureCheck;
  }

  isExternalEmailInToCc() {
    let toAddresses = this.parentForm.value.toUsers;
    let ccAddresses = this.parentForm.value.ccUsers;
    let bccAddresses = this.parentForm.value.bccUsers;

    if (!AppUtils.isUndefinedOrNull(toAddresses)) {
      for (let i in toAddresses) {
        if (toAddresses.hasOwnProperty(i)) {
          if (
            toAddresses[i].value &&
            toAddresses[i].value.indexOf('@') > -1 &&
            !AppUtils.isCitiDomainEmail(toAddresses[i].value, this.citiDomains)
          ) {
            return true;
          }
        }
      }
    }
    if (!AppUtils.isUndefinedOrNull(ccAddresses)) {
      for (let i in ccAddresses) {
        if (ccAddresses.hasOwnProperty(i)) {
          if (
            ccAddresses[i].value &&
            ccAddresses[i].value.indexOf('@') > -1 &&
            !AppUtils.isCitiDomainEmail(ccAddresses[i].value, this.citiDomains)
          ) {
            return true;
          }
        }
      }
    }
    if (!AppUtils.isUndefinedOrNull(bccAddresses)) {
      for (let i in bccAddresses) {
        if (bccAddresses.hasOwnProperty(i)) {
          if (
            bccAddresses[i].value &&
            bccAddresses[i].value.indexOf('@') > -1 &&
            !AppUtils.isCitiDomainEmail(bccAddresses[i].value, this.citiDomains)
          ) {
            return true;
          }
        }
      }
    }
    return false;
  }

  verifyAttachmentsSecurity(): boolean {
    this.errMsg = '';
    let AttachSecureCheck = true;
    this.errMsg =
      'The message cannot be sent.<br>Following file(s) are not password protected :';

    // add null check
    if (this.parentForm.value && this.parentForm.value.attachments) {
      for (let idx = 0; idx < this.parentForm.value.attachments.length; idx++) {
        if (
          !AppUtils.isUndefinedOrNull(this.parentForm.value.attachments[idx]) &&
          !AppUtils.isUndefinedOrNull(
            this.parentForm.value.attachments[idx].secure
          ) &&
          this.parentForm.value.attachments[idx].secure == 'N'
        ) {
          AttachSecureCheck = false;
          this.errMsg =
            this.errMsg + '<br>' + this.parentForm.value.attachments[idx].name;
        }
      }
    }
    if (!AttachSecureCheck) {
      console.log('Message contains unsecure attachments.');
      this.displayError = true;
    }
    return AttachSecureCheck;
  }

  ngOnChanges(data: SimpleChanges) {
    if (!this.fromNewMessage && data.conversationObj) {
      // Reset the reply variables
      this.inquiryId = null;
      this.suggestionConvId = null;
      this.getReplyContents(this.conversationObj);
    } else if (this.fromNewMessage) {
      // Reset the reply variables
      this.inquiryId = null;
      this.suggestionConvId = null;
      this.displayReAge = false;
    }
    this.onGroupNameChange();
    this.updateSecure();
    this.isInlineImageAttached();
    if (!this.isMobile) {
      // this.updateActionButtons(); // C153176-5273: update action bar buttons at ng-change
    }
    // if(data.inlineImage.currentValue){
    //   this.updateApprovalRequired(this.groupName);
    // }
  }

  onGroupNameChange() {
    this.groupName = this.getGroup;
  }

  isInlineImageAttached() {
    if (this.inlineImageAttached || this.recipientUpdate) {
      // C153176-5896 - We need to pass the group data.
      this.updateApprovalRequired(this.getGroupFromGroupName(this.groupName));
    }
  }

  updateSecure() {
    //TODO:Performance :: start time
    let strtTime = performance.now();

    let formData = this.getGroupFromGroupName(this.parentForm.value.groupName);
    if (this.getsecure) {
      if (this.sendSecureEmail) {
        this.isVisible = true;
        // this.isAppReqVisible = true;
        this.parentForm.get('imageAttach').setValue(true);
        this.secureFlag.nativeElement.value = 'Y';
        this.secure = true;
        this.addSecuretoSubject();
        console.log('Secure button is displayed and is selected');
      } else {
        this.isVisible = true;
        this.secureFlag.nativeElement.value = 'N';
        this.secure = false;
        this.removeSecureFromSubject();
        console.log('Secure button is displayed and is but not selected');
      }
    } else if (this.isExternalEmailInToCc()) {
      this.isVisible = true;
      this.secureFlag.nativeElement.value = 'N';
      this.secure = false;
    } else if (!this.getsecure) {
      this.secure = false;
      this.removeSecureFromSubject();
    }
    this.isMakerCheckerConfigured = this.updateApprovalRequired(formData);

    //TODO:Performance :: End Time.
    let endTime = performance.now();
    console.log(
      `QMA2.0 Performance :: New Message Actions -  updateSecure(): ${Math.ceil(
        endTime - strtTime
      )} miliseconds`
    );
  }

  handleInquiryDrop(data) {
    const selGrpIds = [];
    if (data.assignedGroupIds && data.assignedGroupIds.length) {
      data.assignedGroupIds.forEach((id) => {
        if (this.userDataService.userGroupIdList.indexOf(id) > -1) {
          selGrpIds.push(id);
        }
      });
    }
    const inquiryData = {
      id: data.id,
      selectInqAssignedGroupId: selGrpIds,
      isAllConvContent: false, // C153176-4660: metadata only
    };
    let curConvId;
    let curInqSubject;
    this.inboxService.getInquiryIdExtSelected(inquiryData).subscribe(
      (inquiryDetail: any) => {
        console.log(
          'handleInquiryDrop:  getInquiryIdExtSelected() on ' + inquiryData.id,
          inquiryDetail
        );
        if (inquiryDetail) {
          if (
            inquiryDetail.conversationList &&
            inquiryDetail.conversationList.length
          ) {
            curConvId = inquiryDetail.conversationList[0].id;
          }
          if (inquiryDetail.inquiry) {
            curInqSubject = inquiryDetail.inquiry.origSubject;
          }
          const groupId = inquiryDetail?.inquiry?.workflows[0]?.assignedGroupId;
          const hasNominatedOwnership =
            inquiryDetail?.inquiry?.workflows[0]?.hasNominatedOwnership;
          this.download(
            curConvId,
            curInqSubject,
            groupId,
            hasNominatedOwnership
          );
        }
      },
      (error) => {
        console.error('Error from getInquiryIdExtSelected() from drag source');
        this.parentComponent.dropInProcessing = false;
      }
    );
  }

  download(
    convId,
    inqSubject,
    groupId: number,
    hasNominatedOwnership: boolean
  ) {
    if (!convId || !inqSubject) {
      console.log(
        'skipping download, convId =',
        convId,
        'inqSubject=',
        inqSubject
      );
      this.parentComponent.dropInProcessing = false;
      return;
    }
    const inqSub = inqSubject;
    this.inboxService
      .downloadAsEmailAndReturnFile(
        convId,
        inqSub,
        groupId,
        hasNominatedOwnership
      )
      .subscribe(
        (response) => {
          console.log('downloading converstion as ' + inqSubject + '.eml');
          const files = [];
          let filename = '' + inqSubject; // C153176-4706 | Handle special characters in backend
          const respfile = new File([response], filename + '.eml');
          files.push(respfile);
          this.fileEvent(files);
        },
        (error) => {
          this.parentComponent.dropInProcessing = false;
        }
      );
  }
  /**
   * Method to set the group id for group.
   */
  modifiedRecipients(recipents: any[]): any[] {
    if (this.inquiryAction === 'NEW INQUIRY') {
      return recipents;
    }

    // Perform this operation only for reply action.
    let modifiedRecipients = [];
    if (recipents.length > 0) {
      recipents.forEach((recipient) => {
        let rec = { ...recipient };
        if (recipient.isFromDLSearch) {
          rec.value = '' + recipient.email;
        } else if (recipient.id) {
          // if the recipient have id, it should copied to value
          rec.value = '' + recipient.id;
        }

        modifiedRecipients.push(rec);
      });
    }
    return modifiedRecipients;
  }

  /**
   * Method to set the font name and size.
   *
   * @param fontName
   * @param fontSize
   */
  setSelectedFont(fontName: string, fontSize: string): void {
    // Set the prefernce to login user
    let currUser = { ...this.userDataService.loggedInUserInfo };
    let preferences: Preference[] = [];
    currUser.preferences.map((pref, i) => {
      preferences.push({ key: pref.key, value: pref.value });
      if (pref.key === 'editorFontNameId') {
        preferences[i] = { key: pref.key, value: fontName };
      }
      if (pref.key === 'editorFontSizeId') {
        preferences[i] = { key: pref.key, value: fontSize };
      }
    });
    currUser.preferences = preferences;
    //this.userDataService.setLoginUserInfo(currUser);
  }

  /**
   * Method to delete the id from the clcMap.
   * @param id
   */
  updateClcMap(id: any): void {
    if (id) {
      let map = this.tabDataService.clcData;
      map.forEach((v, k, map) => {
        if (k === +id) map.delete(k);
      });
    }
  }

  /**
   * C153176-5273 : handling losing focus w.r.t. popup menu
   */
  @HostListener('document:click', ['$event'])
  onClick(e) {
    if (this.isMobile) {
      return;
    }
    let elems = $(e.target).parents('.action-menu-items');
    if (
      (this.actionMenuOptions &&
        this.actionMenuOptions.nativeElement &&
        this.actionMenuOptions.nativeElement.contains(e.target)) ||
      elems.length ||
      $(e.target).hasClass('action-menu-items')
    ) {
      $('#actionMenuOptions').show();
    } else {
      $('#actionMenuOptions').hide();
    }
  }

  /**
   * C153176-4681 : Method to get inquiry action from schedule for later inquiry.
   */
  getInquiryAction(inquiryAction): string {
    let action: string;
    switch (inquiryAction) {
      case 'Scheduled For Later - New Inquiry':
        action = 'NEW INQUIRY';
        break;
      case 'Scheduled For Later - New Inquiry Resolve':
        action = 'NEW INQUIRY RESOLVE';
        break;
      case 'Scheduled For Later - ReplyAll':
        action = 'ReplyAll';
        break;
      case 'Scheduled For Later - Reply':
        action = 'Reply';
        break;
      case 'Scheduled For Later - Reply Resolve':
        action = 'Reply Resolve';
        break;
      case 'Scheduled For Later - Forward':
        action = 'Forward';
        break;
    }
    return action;
  }
  showUploadFilesPanel(event, overlaypanel: OverlayPanel): void {
    overlaypanel.toggle(event);
  }
  setRestrictedFileNames(restrictedFilesList) {
    //this.errMsg = QmaConstant.QMA_INVALID_FILES_MSG;
    for (let i = 0; i < restrictedFilesList.length; i++) {
      this.restrictedFiles.push(this.processedFiles.inValidFiles[i].name);
      this.errMsg =
        this.errMsg + this.processedFiles.inValidFiles[i].name + '<br>';
    }
  }
  setFileNameWithRestrictedCharacters(restrictedFilesList) {
    // this.errMsgRestrcitedCharsInFilename = "<br>"+QmaConstant.QMA_BLOCKED_FILE_NAME_CHARS;
    this.errMsgRestrcitedCharsInFilename = '';
    for (let i = 0; i < restrictedFilesList.length; i++) {
      let renamedFile = ' ' + restrictedFilesList[i].name;
      this.userDataService.loggedInUserInfo.blockedFileNameCharacters.forEach(
        (bcr) => {
          const searchRegExp = new RegExp('\\' + bcr, 'g');
          renamedFile = renamedFile.replace(searchRegExp, '_');
        }
      );
      this.errMsgRestrcitedCharsInFilename =
        this.errMsgRestrcitedCharsInFilename +
        restrictedFilesList[i].name +
        ' is replaced by:- ' +
        renamedFile +
        '<br>';
    }
  }
  setMultipleDotFileNames(multipleDotFilesList) {
    // this.errMsgRestrcitedCharsInFilename = "<br>"+QmaConstant.QMA_BLOCKED_FILE_NAME_CHARS;
    this.errMsgMultipleDotsInFilename = '';
    for (let i = 0; i < multipleDotFilesList.length; i++) {
      let renamedFile = ' ' + multipleDotFilesList[i].name;
      let dotCount = multipleDotFilesList[i].name.match(/\./g) || [];
      for (let i = 0; i < dotCount.length - 1; i++) {
        renamedFile = renamedFile.replace('.', '_');
      }
      this.errMsgMultipleDotsInFilename =
        this.errMsgMultipleDotsInFilename +
        multipleDotFilesList[i].name +
        ' is replaced by:- ' +
        renamedFile +
        '<br>';
    }
  }
  setFollowupStatus(conversationDetails) {
    if (
      conversationDetails &&
      conversationDetails.inquiry &&
      conversationDetails.inquiry.workflows
    ) {
      const matchingWf = conversationDetails.inquiry.workflows.find(
        (wf) => wf.assignedGroupId === conversationDetails.groupId
      );
      if (matchingWf) {
        const followUp =
          matchingWf.followUp &&
          matchingWf.followUp.flag &&
          matchingWf.followUp.flag === 'Generic'
            ? true
            : false;
        if (followUp) {
          this.followup = true;
          this.followupFlag.nativeElement.value = 'Y';
        }
      }
    }
  }
  validateAllRecepientsEmailIds() {
    this.invalidEmailIds = [];
    if (
      this.parentForm &&
      this.parentForm.value &&
      this.parentForm.value.toUsers &&
      Array.isArray(this.parentForm.value.toUsers)
    ) {
      this.parentForm.value.toUsers.forEach((toUser: any) => {
        if (
          toUser &&
          toUser.email &&
          !this.newMessageService.email_regex.test(toUser.email)
        ) {
          this.invalidEmailIds.push(toUser.email);
        }
      });
    }
    // validate CC users
    if (
      this.parentForm &&
      this.parentForm.value &&
      this.parentForm.value.ccUsers &&
      Array.isArray(this.parentForm.value.ccUsers)
    ) {
      this.parentForm.value.ccUsers.forEach((ccUsers: any) => {
        if (
          ccUsers &&
          ccUsers.email &&
          !this.newMessageService.email_regex.test(ccUsers.email)
        ) {
          this.invalidEmailIds.push(ccUsers.email);
        }
      });
    }

    // validate BCC users
    if (
      this.parentForm &&
      this.parentForm.value &&
      this.parentForm.value.bccUsers &&
      Array.isArray(this.parentForm.value.bccUsers)
    ) {
      this.parentForm.value.bccUsers.forEach((bccUsers: any) => {
        if (
          bccUsers &&
          bccUsers.email &&
          !this.newMessageService.email_regex.test(bccUsers.email)
        ) {
          this.invalidEmailIds.push(bccUsers.email);
        }
      });
    }

    if (this.invalidEmailIds.length > 0) {
      this.invaliDEmailIdExists = true;
    }
    return this.invalidEmailIds.length > 0 ? false : true;
  }
  closeApiErrorPopup() {
    this.displayApiError = false;
  }
  // C170665-3128 Template Issue
  // flag added to differentiate template recepients
  addTemplateFlagToReceipients(receipients) {
    try {
      if (Array.isArray(receipients) && receipients.length > 0) {
        receipients.forEach((rec) => {
          rec.isAddedFromTemplate = true;
        });
      }
    } catch (e) {
      console.log('Exception in addTemplateFlagToReceipients -> ' + e);
    }
  }

  onReAge() {
    this.reAgeComponent.selectedMenuItem = Array.of(
      this.conversationObj.inquiry
    );
    this.reAgeComponent.triggerFalseClick();
  }
}
