<div id="adv" #advSearchContainer> <!-- Advance search pop up issue -->
  <form [formGroup]="advSearchForm" class="advance-search" (ngSubmit)="submitSearch(advSearchForm.value)">
    <div>
      <div>
        <div class="d-flex flex-row flex-nowrap align-items-end w-100 mb-3 align-items-center">
          <label class="mobile-view-flex2" style='width: 20%;'>Content Search:</label>
          <div class="w-100 pl-2 mobile-view-flex3">
            <input type="text" pInputText class="w-100" formControlName="content" variant="filled" style='width: 100%;' placeholder='Type here'>

          </div>
          <div>
            <img class="close-icon" (click)="closeAdvanceSearch()" [src]="'assets/qwyn/icons/close.svg'" alt="">
          </div>
        </div>
        <div class="d-flex flex-row flex-wrap justify-content-between top-adv-container py-3">
          <div class="d-flex flex-column mobile-attachment">
            <div >
              <ins>Search In</ins>
              <em *ngIf="shouldShow" class="fa fa-angle-up dropdown-section" (mouseenter)="showSlide(this)"
                style="font-size:26px;"></em>
              <em *ngIf="!shouldShow" class="fa fa-angle-down dropdown-section" (mouseenter)="showSlide(this)"
                style="font-size:26px;"></em>
            </div>
            <div class="d-flex flex-column flex-nowrap "
              [ngClass]="{showFilterContent: requestShouldShow, hideFilterContent: !requestShouldShow}">
              <app-checkbox-list [placeholder]="'Search'" [name]="'mailboxes'" [items]="mailboxesJson" [listStyle]="{'height':'158px'}"
                [scroll]="scroll" (onItemSelect)="getSelectedBox($event)"></app-checkbox-list>
            </div>
          </div>
          <div class="d-flex flex-column">
            <div >
              <ins>Assigned Groups</ins>
              <em *ngIf="assignedShouldShow" class="fa fa-angle-up dropdown-section"
                (mouseenter)="assignedShowSlide(this)" style="font-size:26px;"></em>
              <em *ngIf="!assignedShouldShow" class="fa fa-angle-down dropdown-section"
                (mouseenter)="assignedShowSlide(this)" style="font-size:26px;"></em>
            </div>
            <div class="d-flex flex-column flex-nowrap "
              [ngClass]="{showFilterContent: assignedShouldShow, hideFilterContent: !assignedShouldShow}">
              <div>
                <app-checkbox-list [placeholder]="'Search'" [name]="'groups'" [items]="assignedGroups"
                  [listStyle]="{'height':'158px'}" [scroll]="scroll"
                  (onItemSelect)="getAssignedGroups($event)"></app-checkbox-list>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column mobile-attachment">
            <div >
              <ins>Request Types</ins>
              <em *ngIf="requestShouldShow" class="fa fa-angle-up dropdown-section"
                (mouseenter)="requestShowSlide(this)" style="font-size:26px;"></em>
              <em *ngIf="!requestShouldShow" class="fa fa-angle-down dropdown-section"
                (mouseenter)="requestShowSlide(this)" style="font-size:26px;"></em>
            </div>
            <div class="d-flex flex-column flex-nowrap "
              [ngClass]="{showFilterContent: requestShouldShow, hideFilterContent: !requestShouldShow}">
              <app-checkbox-list [placeholder]="'Search'" [name]="'request'" [items]="requestTypes"
                [listStyle]="{'height':'158px'}" [scroll]="scroll"
                (onItemSelect)="getRequestGroups($event)"></app-checkbox-list>
            </div>
          </div>
          <div class="d-flex flex-column">
            <div >
              <ins>Tags</ins>
              <em *ngIf="tagsShouldShow" class="fa fa-angle-up dropdown-section" (mouseenter)="tagsShowSlide(this)"
                style="font-size:26px;"></em>
              <em *ngIf="!tagsShouldShow" class="fa fa-angle-down dropdown-section" (mouseenter)="tagsShowSlide(this)"
                style="font-size:26px;"></em>
            </div>
            <div class="d-flex flex-column flex-nowrap "
              [ngClass]="{showFilterContent: tagsShouldShow, hideFilterContent: !tagsShouldShow}">
              <app-checkbox-list [placeholder]="'Search'" [name]="'tag'" [items]="tags" [scroll]="scroll"
                [listStyle]="{'height':'158px'}" (onItemSelect)="getTags($event)"></app-checkbox-list>
            </div>
          </div>

          <div class="d-flex flex-column mobile-attachment">
            <div >
              <ins>Status</ins>
              <em *ngIf="statusShouldShow" class="fa fa-angle-up dropdown-section" (mouseenter)="statusShowSlide(this)"
                style="font-size:26px;"></em>
              <em *ngIf="!statusShouldShow" class="fa fa-angle-down dropdown-section"
                (mouseenter)="statusShowSlide(this)" style="font-size:26px;"></em>
            </div>
                <div class="d-flex flex-column flex-nowrap "
                  [ngClass]="{showFilterContent: statusShouldShow, hideFilterContent: !statusShouldShow}">
                  <app-checkbox-list [placeholder]="'Search'" [name]="'mailboxes'" [items]="statusJson"
                    [listStyle]="{'height':'158px'}" [scroll]="scroll" (onItemSelect)="getStatusBox($event)"></app-checkbox-list>
                </div>
          </div>
        </div>
        <div class="bottom-container w-100 mb-3">
          <!-- <div class="d-flex flex-row flex-nowrap align-items-end w-100">
          <label class="mobile-view-flex2">Content:</label>
          <div class="w-100 pl-2 mobile-view-flex3">
            <input type="text" class="w-100" formControlName="content">
          </div>
        </div> -->
          <div class="d-flex flex-row flex-nowrap mt-4 w-100 justify-content-between mobile-view-flex">
            <div class="d-flex flex-row flex-nowrap align-items-center w-50 pr-2 mobile-view-flex1 mobile-view-whitespace">
              <label class="mobile-view-flex2" style='width: 20%;'>Subject:</label>
              <!-- <div class="w-75 pl-2 mobile-view-flex1">
                <input type="text" class="w-100" formControlName="subject">
              </div> -->
              <div class="w-100 pl-2 mobile-view-flex3">
                <input type="text" pInputText class="w-100" formControlName="subject" variant="filled" style='width: 100%;'
                  placeholder='Type here'>

              </div>
            </div>
            <div class="d-flex flex-row flex-nowrap justify-content-end align-items-center w-50 pl-2 mobile-view-flex1">
              <label class="mobile-view-flex2" style='width: 20%;'>To or From:</label>
              <!-- <div class="w-75 pl-2 form-width mobile-view-flex1">
                <input type="text" class="w-100" formControlName="originator">
              </div> -->
                <div class="w-100 pl-2 mobile-view-flex3">
                  <input type="text" pInputText class="w-100" formControlName="originator" variant="filled" style='width: 100%;'
                    placeholder='Type here'>

                </div>
            </div>
          </div>
          <div class="d-flex flex-row flex-nowrap mt-4 w-100 justify-content-between mobile-view-flex">
            <div class="d-flex flex-row flex-nowrap align-items-center w-50 pr-2 mobile-view-flex1 mobile-view-whitespace">
              <label class="mobile-view-flex2" style='width: 20%;'>Dates:</label>
      <div class="w-75 pl-2 form-width mobile-view-flex1 d-flex flex-row align-items-center">
        <p-calendar dateFormat="dd/MM/yy" formControlName="startDt" [showOtherMonths]="true" [hideOnDateTimeSelect]="true"
          [selectOtherMonths]="false" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="'2010:2030'"
          [styleClass]="'advance-calendar'" [showIcon]="false"
          (onSelect)="startDateSelect($event)"
          placeholder="DD/MM/YY" style="margin-right: 10px;">
        </p-calendar>

        <p-calendar dateFormat="dd/MM/yy" formControlName="endDt" [showOtherMonths]="true" [hideOnDateTimeSelect]="true"
          [selectOtherMonths]="false" [readonlyInput]="false" [monthNavigator]="true" [yearNavigator]="true"
          [yearRange]="'2010:2030'" [styleClass]="'advance-calendar2'" [showIcon]="false"
          [showButtonBar]="true"
          (onSelect)="endDateSelect($event)"
          placeholder="DD/MM/YY">
        </p-calendar>
      </div>


            </div>
            <div class="d-flex flex-row flex-nowrap align-items-center w-50 pl-2 mobile-view-flex1">
                <label class="" style='width: 20%;'>Attachments:</label>
                <div class="pl-2">
                  <p-inputSwitch [styleClass]="'advance-inputSwitch'" formControlName="attachment"></p-inputSwitch>
                </div>
            </div>
          </div>
          <div class="w-100">
            <div *ngIf="error.isError"
               class="d-flex flex-row flex-nowrap justify-content-end text-danger error-calender">
              <img src="assets /newMessage/error.svg"  class="error" alt="Error">{{ error.errorMessage }}
            </div>
          </div>



          <div *ngIf="advSearchError" class="mt-4 pt-2 d-flex flex-row flex-nowrap align-items-center invalid-feedback">
            <div><img src="assets/newMessage/error.svg" class="error" alt="Error"> Please select valid search criteria
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row flex-wrap p-3 justify-content-end mobile-holder">

      <button type="button" class="btn clear-btn" (click)='clearSearch()'>Reset</button>
      <button type="submit" class="btn search-btn">Apply</button>
    </div>
  </form>
</div>