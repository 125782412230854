<div class="audit-container" >
  <div class="export-container">
    <div class="addNoteButtonText"  (click)="addNotesModalComponent.openModal($event)" [hidden]="!inquiryId"><div>+</div><div>Add Note</div></div>
    <div class="divider"></div>
    <div class="downloadAllButtonText" (click)="downloadNotes()" *ngIf="notesList.length > 0">
      <img  [src]="'assets/Inbox/downloadBlue.svg'" alt="Download" />
      <div>Download All</div>
    </div>
  </div>
  <div
    class="pinContainer"
    pTooltip="Pin Side Bar"
    tooltipPosition="top"
    tooltipStyleClass="rightTooltip p-tooltip-text"
  >
    <img class="default-icon" [src]="'assets/Inbox/pin.svg'" alt="PIN" />
    <img class="hover-icon" [src]="'assets/Inbox/pinHighlight.svg'" alt="PIN"  (click)="onPinPanel()"  />
  </div>
</div>

<div class="notes-progress">
  <ul>
    <li *ngFor="let note of notesList">
      <em class="fa fa-circle"></em>
      <div class='note-text'>
        <div class='dateText'>{{note.commentDate.$date | date:'dd MMM hh:mm a'}}</div>
        <div class='userText'>{{note.userName}}</div>
        <div class="commentsWrap">
          <div class="commentsText">{{note.comments}}</div>
        </div>
      </div>
    </li>
    <li *ngIf="noteCount > 0">
      <em class="fa fa-circle"></em>
      <div class='resolved'>
          <span></span>
        </div>
    </li>
  </ul>
</div>
<app-add-notes-modal (dataEmitter)="addNote($event)"></app-add-notes-modal>