import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttachmentComponent } from "./attachment/attachment.component";
import { AuditDetailComponent } from "./audit-detail/audit-detail.component";
import { NotesDetailsComponent } from "./notes-details/notes-details.component";
import { FormsModule } from "@angular/forms";
import { TooltipModule } from 'primeng/tooltip';
import { AddNotesModalComponent } from './add-notes-modal/add-notes-modal.component';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';

@NgModule({
  declarations: [
    AttachmentComponent,
    AuditDetailComponent,
    NotesDetailsComponent,
    AddNotesModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TooltipModule,
    DialogModule,
    ButtonModule
  ],
  exports: [
    AttachmentComponent,
    AuditDetailComponent,
    NotesDetailsComponent,
    TooltipModule,
    AddNotesModalComponent
  ],
})
export class InboxArtifactsModule { }
