<div>
  <div class="menuHeading">
    <div class="menuText">My Views</div>
    <div class="createIcon" (click)="addSelected($event)">+</div>
  </div>
  <div oncontextmenu="return false;" class="childWrapper">
    <div
      *ngFor="let item of (showMore ? filteredItems : filteredItems | slice:0:3)"
    >
      <div
        [ngClass]="{'active-menu': currentActiveTab?.header === item.label, 'menuChild': activeTab?.header !== item.label}"
      >
        <div class="textlink">
          <div
            class="menuChildText item-open"
            id="{{item.name}}"
            (click)="itemSelected($event)"
            (contextmenu)="onRightClick($event)"
          >
            {{item.label}}
          </div>
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="hoverIcon"
          >
            <g id="Vector">
              <path
                d="M3.27525 13H9.6003C10.8653 13 11.8843 11.981 11.8843 10.716V6.85065C11.8843 6.55197 11.6559 6.32357 11.3573 6.32357C11.0586 6.32357 10.8302 6.55197 10.8302 6.85065V10.716C10.8302 11.4012 10.2855 11.9458 9.6003 11.9458H3.27525C2.59004 11.9458 2.04538 11.4012 2.04538 10.716V4.39092C2.04538 3.7057 2.59004 3.16105 3.27525 3.16105H7.14056C7.43924 3.16105 7.66764 2.93264 7.66764 2.63396C7.66764 2.33528 7.43924 2.10687 7.14056 2.10687H3.27525C2.01025 2.10687 0.991211 3.12591 0.991211 4.39092V10.716C0.991211 11.981 2.01025 13 3.27525 13Z"
                fill="#7E819B"
              />
              <path
                d="M13.0089 4.75989V1.54466C13.0089 1.50952 13.0089 1.47438 12.9913 1.43924C12.9913 1.42167 12.9913 1.4041 12.9738 1.4041C12.9738 1.38653 12.9562 1.36896 12.9562 1.35139C12.9562 1.33382 12.9386 1.31625 12.921 1.29868C12.921 1.28111 12.9035 1.26354 12.9035 1.26354C12.8683 1.21083 12.8156 1.15813 12.7629 1.12299L12.7278 1.10542C12.6926 1.05271 12.6575 1.03514 12.6224 1.03514L12.5697 1.01757C12.5345 1.01757 12.4994 1 12.4642 1H9.24901C8.95033 1 8.72192 1.2284 8.72192 1.52709C8.72192 1.82577 8.95033 2.05417 9.24901 2.05417H11.1992L6.3676 6.90337C6.15677 7.11421 6.15677 7.44803 6.3676 7.64129C6.57844 7.85213 6.91226 7.85213 7.10552 7.64129L11.9547 2.80966V4.75989C11.9547 5.05857 12.1831 5.28697 12.4818 5.28697C12.7629 5.28697 12.9913 5.041 13.0089 4.75989Z"
                fill="#7E819B"
              />
              <path
                d="M3.27525 13H9.6003C10.8653 13 11.8843 11.981 11.8843 10.716V6.85065C11.8843 6.55197 11.6559 6.32357 11.3573 6.32357C11.0586 6.32357 10.8302 6.55197 10.8302 6.85065V10.716C10.8302 11.4012 10.2855 11.9458 9.6003 11.9458H3.27525C2.59004 11.9458 2.04538 11.4012 2.04538 10.716V4.39092C2.04538 3.7057 2.59004 3.16105 3.27525 3.16105H7.14056C7.43924 3.16105 7.66764 2.93264 7.66764 2.63396C7.66764 2.33528 7.43924 2.10687 7.14056 2.10687H3.27525C2.01025 2.10687 0.991211 3.12591 0.991211 4.39092V10.716C0.991211 11.981 2.01025 13 3.27525 13Z"
                stroke="white"
                stroke-width="0.25"
              />
              <path
                d="M13.0089 4.75989V1.54466C13.0089 1.50952 13.0089 1.47438 12.9913 1.43924C12.9913 1.42167 12.9913 1.4041 12.9738 1.4041C12.9738 1.38653 12.9562 1.36896 12.9562 1.35139C12.9562 1.33382 12.9386 1.31625 12.921 1.29868C12.921 1.28111 12.9035 1.26354 12.9035 1.26354C12.8683 1.21083 12.8156 1.15813 12.7629 1.12299L12.7278 1.10542C12.6926 1.05271 12.6575 1.03514 12.6224 1.03514L12.5697 1.01757C12.5345 1.01757 12.4994 1 12.4642 1H9.24901C8.95033 1 8.72192 1.2284 8.72192 1.52709C8.72192 1.82577 8.95033 2.05417 9.24901 2.05417H11.1992L6.3676 6.90337C6.15677 7.11421 6.15677 7.44803 6.3676 7.64129C6.57844 7.85213 6.91226 7.85213 7.10552 7.64129L11.9547 2.80966V4.75989C11.9547 5.05857 12.1831 5.28697 12.4818 5.28697C12.7629 5.28697 12.9913 5.041 13.0089 4.75989Z"
                stroke="white"
                stroke-width="0.25"
              />
            </g>
          </svg>
        </div>

        <div *ngIf="item.count" class="menuChildCount">
          {{item.counts !== undefined? item.counts: item.count}}
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="filteredItems.length>3" class="shoMoreArrow">
    <div *ngIf="!showMore" (click)="toggleShowMore()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="8"
        viewBox="0 0 12 8"
        fill="none"
      >
        <path
          d="M1 1.75L6 6.25L11 1.75"
          stroke="#000F29"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div *ngIf="showMore" (click)="toggleShowMore()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="8"
        viewBox="0 0 12 8"
        fill="none"
      >
        <path
          d="M1 6.25L6 1.75L11 6.25"
          stroke="#000F29"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>

  <div *ngIf="contextmenu==true">
    <app-search-list-contextmenu
      [x]="contextmenuX"
      [y]="contextmenuY"
      [target]="itemId"
    ></app-search-list-contextmenu>
  </div>
</div>
