import { enableProdMode, ApplicationRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
// import {LicenseManager} from "@ag-grid-enterprise/core";
import { ModuleRegistry } from '@ag-grid-community/core';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { enableDebugTools } from '@angular/platform-browser';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { MenuModule } from '@ag-grid-enterprise/menu';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { AgGridAngular } from '@ag-grid-community/angular';
if (environment.production) {
  enableProdMode();
}

// LicenseManager.setLicenseKey("XXXX");
ModuleRegistry.registerModules([ClientSideRowModelModule, RowGroupingModule, MenuModule, MasterDetailModule, ExcelExportModule
]);
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

/* platformBrowserDynamic().bootstrapModule(AppModule)
.then(moduleRef => {
  const applicationRef = moduleRef.injector.get(ApplicationRef);
  const componentRef = applicationRef.components[0];
  // allows to run `ng.profiler.timeChangeDetection();`
  enableDebugTools(componentRef);
})
.catch(err => console.error(err)); */
