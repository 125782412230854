import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreComponent } from './core.component';
/* LAYOUT COMPONENTS */
import { LeftNavComponent } from './leftnav/leftnav.component';
import { TopnavComponent } from './topnav/topnav.component';
import { TabViewModule } from 'primeng/tabview';
import { MultiSelectModule } from 'primeng/multiselect';
import { ListboxModule } from 'primeng/listbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { CalendarModule } from 'primeng/calendar';
import { NotificationComponent } from './notification/notification.component';
import { HelpComponent } from './help/help.component';
import { UserComponent } from './user/user.component';
import { AgGridCheckboxComponent } from './user/ag-grid-checkbox.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TooltipModule } from 'primeng/tooltip';
import { ToastModule } from 'primeng/toast';
/* COMMON COMPONENT */
import { SearchListComponent } from './search-list/search-list.component';
import { ContextMenuComponent } from './search-list/contextmenu/contextmenu.component';
import { TabsComponent } from './tabs/tabs.component';
import { AdvCriteriaComponent } from './adv-criteria/adv-criteria.component';

import { InboxModule } from '../inbox/inbox.module';
import { NewMessageModule } from '../new-message/new-message.module';

/* Directives */
import { OuterClickDirective } from "../common/directives/outer-click.directive";
import { SidebarModule } from 'primeng/sidebar';

/* Services */
import { NotificationService } from "../common/services/notifications.service";
import { SharedModule } from '../common/shared-module';
import { MyviewsComponent } from './myviews/myviews.component';
import { DashboardModule } from '../dashboard/dashboard.module';

import { DropdownModule } from 'primeng/dropdown';
import { SliderModule } from 'primeng/slider';
import { OverlayPanel } from 'primeng/overlaypanel';
import { PickListModule } from 'primeng/picklist';
import { EditorModule } from 'primeng/editor';
import { AgGridModule } from '@ag-grid-community/angular';
import { RadioButtonModule } from 'primeng/radiobutton';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ProfileSettingComponent } from './profile-setting/profile-setting.component';
import { DeleteCellRendererComponent } from './user/delete-cell-renderer.component';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ColorPickerComponent } from './user/color-picker.component';
import { UserEntitlementCheckboxComponent } from './user/user-entitlement-checkbox.component';
// Jira : C153176-5182 Group Admin - issue- Design change
import { UserEntitlementAddCheckboxComponent } from './user/user-entitlement-add-checkbox.component';
import { ReportsComponent } from './reports/reports.component';
import { TabDragDropDirective } from '../common/directives/tab-drag-drop.directive';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from '@dchtools/ngx-loading-v18';
import { InquiryOrderComponent } from './user/inquiry-order-component';
import { SymphonyModule } from '../symphony/symphony.module';
import { AdminModule } from '../admin/admin.module';
import { PersonalCalendarModule } from '../personal-calendar/personal-calendar.module';
import { PersonalEmailModule } from '../personal-email/personal-email.module';

import { SymphonyContextMenuComponent } from 'src/app/core/symphony-contextmenu/symphony.contextmenu.component'
import { ReqtypeRootcauseComponent } from './user/reqtype-rootcause/reqtype-rootcause.component';
import { SymphonyNotificationComponent } from './notification-symphony/notification-symphony.component';
import { SuperAdminSetupComponent } from '../admin/super-admin-setup/super-admin-setup.component';
import { SuperAdminCheckboxComponent } from '../admin/SuperAdminCheckboxComponent';
import { OrgMetaDataComponent } from '../admin/org-metadata/org-metadata.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import {Component} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSidenavModule} from '@angular/material/sidenav';
import { SidenavComponent } from './sidenav/sidenav.component';


@NgModule({
  declarations: [
    SidenavComponent,
    CoreComponent,
    LeftNavComponent,
    TopnavComponent,
    NotificationComponent,
    HelpComponent,
    UserComponent,
    SearchListComponent,
    ContextMenuComponent,
    TabsComponent,
    AdvCriteriaComponent,
    OuterClickDirective,
    MyviewsComponent,
    AgGridCheckboxComponent,
    ProfileSettingComponent,
    DeleteCellRendererComponent,
    ColorPickerComponent,
    ReportsComponent,
    TabDragDropDirective,
    UserEntitlementCheckboxComponent,
    // Jira : C153176-5182 Group Admin - issue- Design change
    UserEntitlementAddCheckboxComponent,
    InquiryOrderComponent,
    SymphonyContextMenuComponent,
    ReqtypeRootcauseComponent,
    // symphony change
    SymphonyNotificationComponent,
    SuperAdminSetupComponent,
    SuperAdminCheckboxComponent,
    // for display org metadata in group admin screen
    OrgMetaDataComponent,
  ],
  imports: [
    MatSidenavModule, MatFormFieldModule, MatSelectModule, MatButtonModule,
    DropdownModule,
    EditorModule,
    /* Jira : C153176-5182 Group Admin - issue- Design change */
    AgGridModule,
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    NgScrollbarModule,
    // primeng modules
    TabViewModule,
    MultiSelectModule,
    ListboxModule,
    InputSwitchModule,
    InputTextModule,
    CheckboxModule,
    InboxModule,
    SidebarModule,
    CalendarModule,
    NewMessageModule,
    ReactiveFormsModule,
    SharedModule,
    AutoCompleteModule,
    ReactiveFormsModule,
    DialogModule,
    DashboardModule,
    RadioButtonModule,
    KeyFilterModule,
    ConfirmDialogModule,
    TooltipModule,
    ToastModule,
    ColorPickerModule,
    SliderModule,
    SymphonyModule,
    AdminModule,
    PersonalCalendarModule,

    PickListModule,

    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      backdropBorderRadius: '4px',
      primaryColour: '#021e48',
      secondaryColour: '#021e48',
      tertiaryColour: '#021e48',
      fullScreenBackdrop: true
    }),
    PersonalEmailModule,
  ],
  providers: [
    NotificationService
  ],
  // entryComponents: [YourDialog],
  exports: [SidenavComponent,ReqtypeRootcauseComponent, CoreComponent, InboxModule, NewMessageModule, SharedModule, DashboardModule, AdminModule, PersonalCalendarModule, NotificationComponent]
})
export class CoreModule { }
