import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import * as HighCharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import OfflineExport from 'highcharts/modules/offline-exporting';
import ExportData from 'highcharts/modules/export-data';
import Annotations from 'highcharts/modules/annotations';
import customEvents from 'highcharts-custom-events';
import * as CustomizedPrinter from '../utils/customized-printer';
import { InboxService } from 'src/app/services/inbox.service';
import { InboxViewComponent } from 'src/app/inbox/inboxView/inboxView.component';
import { QmaConstant } from '../../constant/qma-constant';
import { FadingDialogComponent } from 'src/app/dashboard/fading-dialog/fading-dialog.component';
import * as $ from 'jquery';
import '../../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
import { DashboardLocalService } from 'src/app/services/dashboard/dashboard-local.service';
// C153176-5011: myview inline view handling
import { MyViewsService } from 'src/app/services/myViews/my-views.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { PiwikProUtils } from 'src/app/common/utility/piwikProUtils';

HC_exporting(HighCharts);
OfflineExport(HighCharts);
ExportData(HighCharts);
Annotations(HighCharts);
customEvents(HighCharts);
CustomizedPrinter(HighCharts);

@Component({
  selector: 'app-mailbox-statistics',
  templateUrl: './mailbox-statistics.component.html',
  styleUrls: ['./mailbox-statistics.component.scss']/* ,
  host: {
    '(document:keydown)': 'onKeydown($event)'
  } */
})
export class MailboxStatisticsComponent implements OnInit, AfterViewInit {
  ngAfterViewInit(): void {
    /* this.cdr.detach(); */
  }
  rootNode: any;
  isMobile: boolean = false;
  HighCharts = HighCharts; // required
  protected mailboxStatistics: any = {
    chart: {
      backgroundColor: '#FCFCFC',
      events: {
        load(e) {
          if (this.options && this.options.ngComponent) {
            this.options.ngComponent.redrawWidgets(this);
          } else {
            console.log('Cannot find ng component for mailbox statistics!');
          }
        },
        redraw(e) {
          if (this.options && this.options.ngComponent) {
            this.options.ngComponent.redrawWidgets(this);
          } else {
            console.log('Cannot find ng component for mailbox statistics!');
          }
        }
      }
    },
    annotations: [],
    title: {
      useHTML: true,
      text: '<span style="line-height: 15px;font-size: 15px;font-family: \'OpenSansLight\';">MAILBOX STATISTICS</span>',
      align: 'left'
    },
    subtitle: {
      useHTML: true,
      text: '<span style="font-size:13px;">Click status graph to view recent inquiries.</span>',
      align: 'center',
      verticalAlign: 'bottom',
      y: -7
    },
    labels: {
      items: []
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      pie: {
        states: {
          inactive: {
            opacity: 1
          },
          hover: {
            brightness: 0
          }
        },
        size: 136,
        innerSize: '83%'
      }
    },
    tooltip: {
      backgroundColor: 'rgba(252,252, 252,1)',
      formatter(tooltip) {
        const t = tooltip.defaultFormatter.call(this, tooltip);
        const component = this.point.series.chart.options.ngComponent;
        // C170665-1061 Mailbox stats changes for Resolved and Sent box
        // dont update tooltip for resolved and sent
        if (this.point.series && this.point.series.name && QmaConstant.MAILBOX_STATS_EXCLUDE_TOOLTIP_CHARTS.includes(this.point.series.name.toUpperCase())) {
          return false;
        }
        if (component) {
          const result = component.updateTooltip(tooltip, this.point, t);
          if (result) {
            return result;
          }
        }
        return t;
      },
      useHTML: true,
      shared: false,
      padding: 2,
      style: {
        zIndex: 1000
      }
    },
    series: [],
    navigation: {
      enabled: false,
      buttonOptions: {
        enabled: true,
        symbolSize: 28,
        symbolX: 25,
        symbolY: 22
      },
    },
    exporting: {
      // C153176-4479: support larger print max width
      printMaxWidth: 3000,
      chartOptions: {
        chart: {
          width: 1500,
          height: 500
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true
            }
          }
        }
      },
      menuItemDefinitions: {
        maximize: {
          onclick() {
            if (this.options && this.options.ngComponent) {
              this.options.ngComponent.enterFullScreen(this);
            } else {
              console.log('mailbox-statistics: Failed to enter full screen');
            }
          },
          text: 'Maximize'
        },
        downloadCsv: {
          onclick() {
            if (this.options && this.options.ngComponent) {
              this.options.ngComponent.downloadCustomCsv();
            } else {
              console.log('mailbox-statistics: Failed to download CSV');
            }
          },
          text: 'Download CSV'
        }
      },
      buttons: {
        contextButton: {
          menuItems: [{
            textKey: 'printChart',
            onclick() {
              this.print();
            }
          }, {
            textKey: 'downloadPNG',
            onclick: () => { this.localService.download("mailbox-statistics", "png"); }
          }, {
            textKey: 'downloadJPEG',
            onclick: () => { this.localService.download("mailbox-statistics", "jpg"); }
          }, {
            textKey: 'downloadPDF',
            onclick: () => { this.localService.download("mailbox-statistics", "pdf"); }
          }, "downloadCsv", "maximize"], // "viewFullscreen"],
          symbol: 'url(assets/dashboard/contextmenu.png)'
        }
      },
      csv: {
        decimalPoint: ":"
      }
    },
    credits: {
      enabled: false
    },
    ngComponent: null
  };
  protected barColors = { red: '#EF7B87', amber: '#FFBF00', green: '#00B0B9', darkgreen: '#00521D' };
  protected pieCenterIncrementX = 260;
  protected pieInitialPos = { x: 80, y: 60 };
  protected annotationInitialPos = { x: this.pieInitialPos.x + 20, y: this.pieInitialPos.y + 20 };
  protected annotationRadius = this.mailboxStatistics.plotOptions.pie.size / 2 - 11;
  protected categoryInitialPos = { x: this.pieInitialPos.x, y: this.pieInitialPos.y - this.annotationRadius - 12 };
  protected countInitialPos = { x: this.pieInitialPos.x - 5, y: this.pieInitialPos.y + 5 };
  protected trendInitialPos = { x: this.pieInitialPos.x - 15, y: this.pieInitialPos.y + 20 };
  protected pieColors = ['#B988C6', '#15C8F5', '#002D72', '#FAE24C'];
  protected trendColors = { up: '#EF7B87', down: '#00B0B9' };
  protected labelColor = '#101010';
  protected defaultColor = '#dddddd';
  protected closeCallbackSet = false;
  protected closeCallbackEnabled = false;
  protected selectedIndex: number = 0
  mailboxStats: Array<any> = [];

  protected mailboxStatisticsChart: any;
  protected chartData: any;
  protected chartWidth: number;
  protected chartHeight: number;
  public showInboxView = false;
  @ViewChild(InboxViewComponent) inboxViewComponent;
  protected inboxViewIndex = -1;
  protected drilldownLabels = {};
  // C153176-4700: server-side pagination requires caching of age data to support scrolling in inline view
  protected ageDataByName = {};
  protected fullScreen = false;
  @ViewChild(FadingDialogComponent, { static: true }) fadingDialogComponent;
  protected selectedGroup = "";
  public data: any;
  protected viewTypeSeparator = ' - ';
  // C153176-5011: cached myview request and options
  protected myViewRequestObject: any;
  protected myViewRequestOptions: any;

  //C153176-5690 Alignment of mailbox stats charts- double click on any group to see in tab then come back to dashboard
  protected isPreviousScreenSize = false;
  protected screenSize = 0;

  constructor(private dashboardService: DashboardService, private inboxService: InboxService,
    private localService: DashboardLocalService, private userDataService: UserDataService, private myViewService: MyViewsService, private cdr: ChangeDetectorRef) { // C153176-5011: myview handling
    this.mailboxStatistics.ngComponent = this;
    this.isMobile = (window.innerWidth <= QmaConstant.MOBILE_WIDTH);
    //C153176-5011: handling myview inline view request
    this.initMyViewHandler();
  }

  ngOnInit() {
    try {
      this.dashboardService.getmailBoxStatsSubject.subscribe(data => {
        this.resetInlineView();
        this.data = data;
        console.log(data);
        if (!this.checkEmptyObject(data) && this.handleMailboxStatistics(data)) {
          HC_exporting(HighCharts);
          OfflineExport(HighCharts);
          ExportData(HighCharts);
          Annotations(HighCharts);
          customEvents(HighCharts);
          CustomizedPrinter(HighCharts);
          const component = this;
          this.mailboxStats = this.data.mailBoxStat;
          setTimeout(() => {
            const elem = $('#mailbox-statistics');
            if (elem && elem.length) {
              /* if(this.isMobile){
                component.mailboxStatistics.subtitle.text = '';
              } */
              HighCharts.setOptions({ lang: { thousandsSep: "," } });
              component.mailboxStatisticsChart = HighCharts.chart('mailbox-statistics', component.mailboxStatistics);
              component.mailboxStatisticsChart.options.ngComponent = component;
              component.populateDrilldownLabels(component.mailboxStatisticsChart);
              component.setupEventHandler();

            }
          }, 500);
        }
      });
      this.inboxService.ageDataEvent.subscribe((ageData: any) => {
        this.handleAgeData(ageData);
      });

      /* C153176-4083: To enable mailbox stats for specific group selection*/
      this.localService.getSelectedGroupName.subscribe(groupName => {
        let selectedGroup = "All";
        if (groupName !== "") {
          selectedGroup = groupName;
        }
        if (this.selectedGroup === selectedGroup) {
          // the group data was just requested, skip requesting again.
          return;
        }
        this.selectedGroup = selectedGroup;
        this.dashboardService.getmailBoxStats(this.selectedGroup);
      });
    } catch (e) {
      console.log(e);
    }
  }

  checkEmptyObject(obj: any) {
    return Object.entries(obj).length === 0 && obj.constructor === Object;
  }

  redrawWidgets(chart) {
    if (chart && (this.chartWidth === undefined || chart.chartWidth !== this.chartWidth || chart.chartHeight !== this.chartHeight)) {

      //C153176-5690 Alignment of mailbox stats charts- double click on any group to see in tab then come back to dashboard
      if (!this.isPreviousScreenSize) {
        this.screenSize = chart.chartWidth;
        this.isPreviousScreenSize = true;
      }
      this.chartWidth = chart.chartWidth == this.screenSize ? chart.chartWidth : this.screenSize;

      this.chartHeight = chart.chartHeight;
      if (this.isMobile) {
        this.pieCenterIncrementX = this.chartWidth / 2;
      }
      else {
        this.pieCenterIncrementX = Math.max(160, Math.floor(this.chartWidth / 5));
      }
      this.pieInitialPos = { x: this.pieCenterIncrementX - 30, y: this.pieInitialPos.y };
      this.categoryInitialPos = { x: this.pieInitialPos.x, y: this.categoryInitialPos.y };
      this.countInitialPos = { x: this.pieInitialPos.x - 5, y: this.countInitialPos.y };
      this.trendInitialPos = { x: this.pieInitialPos.x - 15, y: this.trendInitialPos.y };
      this.annotationInitialPos = { x: this.pieInitialPos.x + 20, y: this.pieInitialPos.y + 20 };
      const component = this;
      setTimeout(() => {
        component.updateChartData();
        if (component.mailboxStatisticsChart) {
          component.mailboxStatisticsChart.destroy();
        }
        component.mailboxStatisticsChart = HighCharts.chart('mailbox-statistics', component.mailboxStatistics);
        component.mailboxStatisticsChart.options.ngComponent = component;
        component.populateDrilldownLabels(component.mailboxStatisticsChart);
        component.setupEventHandler();
      }, 0);
    }
  }


  handleAgeData(ageData) {
    if (!ageData || !this.chartData || this.inboxViewIndex < 0) {
      return;
    }
    let category = this.chartData[this.inboxViewIndex].category;
    // C!53176-4577: cache/update age data per category
    ageData = Object.assign({}, ageData);
    if (!this.ageDataByName[category]) {
      this.ageDataByName[category] = ageData;
    } else {
      let cachedAgeData = this.ageDataByName[category];
      Object.keys(ageData).forEach(key => {
        if (key && ageData[key]) {
          if (cachedAgeData[key]) {
            cachedAgeData[key] += ageData[key];
          } else {
            cachedAgeData[key] = ageData[key];
          }
        }
      });
      ageData = cachedAgeData;
    }
    this.buildDrilldownLabels(ageData, this.inboxViewIndex, this.mailboxStatisticsChart,
      category);
  }

  buildDrilldownLabels(s, i, chart, category) {
    const name = category;
    // C153176-4755: destroy prev SVGElement if any
    if (this.drilldownLabels[name]) {
      let prev_g = this.drilldownLabels[name];
      if (prev_g) {
        prev_g.hide();
        prev_g.destroy();
        delete this.drilldownLabels[name];
        // DO NOT delete age data here
      }
    }
    let g = chart.renderer.g().add();
    const preText = '<span id="mailbox-statistics-drilldown-label-pre" '
      + 'style="line-height: 15px;font-size: 15px;font-family: \'OpenSansLight\';color=\'#dddddd\';">'
      + 'LATEST INQUIRIES FROM ' + name.toUpperCase() + '</span>';
    const xStart = this.chartWidth * 0.03;
    const yStart = this.chartHeight * 0.92;
    const pre = chart.renderer.text(preText, xStart, yStart, true).add(g);
    let title = category + ' - Age +30 Days: ' + (s['30+'] ? s['30+'].toLocaleString() : 0);
    const g1vtext = '<span id="mailbox-statistics-drilldown-label-g1-' + i
      + '" style="line-height: 17px; font-size: 22px;" title="' + title + '">'
      + (s['30+'] ? s['30+'].toLocaleString() : 0) + '</span>';
    let bbox = pre.getBBox();
    if (bbox.width < 30) {
      bbox.width = 30;
    }
    const g1 = chart.renderer.text(g1vtext, xStart + bbox.width + 20, yStart, true).css({
      fontFamily: 'OpenSansLight',
      color: this.barColors.red,
      fontSize: 22,
      fontWeight: 600,
    }).attr({
      dataToggle: "true",
      zIndex: 100,
      opacity: 0.8,
      title: category + ' (Age +30 Days): ' + s['30+']
    }).add(g);
    title = category + ' - Age +15 Days: ' + (s['15+'] ? s['15+'].toLocaleString() : 0);
    const g2vtext = '<span id="mailbox-statistics-drilldown-label-g2-' + i
      + '" style="line-height:17px;font-size: 22px;" title="' + title + '">'
      + (s['15+'] ? s['15+'].toLocaleString() : 0) + '</span>';
    bbox = g1.getBBox();
    if (bbox.width < 30) {
      bbox.width = 30;
    }
    const g2 = chart.renderer.text(g2vtext, bbox.x + bbox.width + 10, yStart, true).css({
      fontFamily: 'OpenSansLight',
      color: this.barColors.amber,
      fontSize: 22,
      fontWeight: 600,
    }).attr({
      dataToggle: "true",
      zIndex: 100,
      opacity: 0.8,
      title: category + ' (Age 15 Days): ' + s['15+']
    }).add(g);
    title = category + ' - Age +05 Days: ' + (s['05+'] ? s['05+'].toLocaleString() : 0);
    const g3vtext = '<span id="mailbox-statistics-drilldown-label-g3-' + i
      + '" style="line-height:17px;font-size: 22px;" title="' + title + '">'
      + (s['05+'] ? s['05+'].toLocaleString() : 0) + '</span>';
    bbox = g2.getBBox();
    if (bbox.width < 30) {
      bbox.width = 30;
    }
    const g3 = chart.renderer.text(g3vtext, bbox.x + bbox.width + 10, yStart, true).css({
      fontFamily: 'OpenSansLight',
      color: this.barColors.green,
      fontSize: 22,
      fontWeight: 600,
    }).attr({
      title: category + ' (Age +5 Days): ' + s['05+'],
      dataToggle: "true",
      zIndex: 100,
      opacity: 0.8
    }).add(g);
    title = category + ' - Age <=05 Days: ' + (s['lt5'] ? s['lt5'].toLocaleString() : 0);
    const g4vtext = '<span id="mailbox-statistics-drilldown-label-g4-' + i
      + '" style="line-height:17px;font-size: 22px;" title="' + title + '">'
      + (s['lt5'] ? s['lt5'].toLocaleString() : 0) + '</span>';
    bbox = g3.getBBox();
    if (bbox.width < 30) {
      bbox.width = 30;
    }
    chart.renderer.text(g4vtext, bbox.x + bbox.width + 10, yStart, true).css({
      fontFamily: 'OpenSansLight',
      color: this.barColors.darkgreen,
      fontSize: 22,
      fontWeight: 600,
    }).attr({
      dataToggle: "true",
      zIndex: 100,
      opacity: 0.8
    }).add(g);
    if (this.showInboxView && i === this.inboxViewIndex) {
      g = g.show();
    } else {
      g = g.hide();
    }
    this.drilldownLabels[name] = g;
    setTimeout(() => {
      let elems = $('[dataToggle="true"]');
      elems.tooltip({
        html: true,
        placement: "auto-right"
      });
    }, 0);
  }

  populateDrilldownLabels(chart) {
    if (!chart || !chart.series || !chart.series.length || !this.chartData) {
      return;
    }
  }

  setupEventHandler() {
    if (this.mailboxStatisticsChart) {
      this.mailboxStatisticsChart.series.forEach((series) => {
        if (series && series.points) {
          series.points.forEach((p) => {
            if (p && p.graphic) {
              const point = p;
              p.graphic.on('dblclick', () => {
                if (point && point.series && point.series.chart && point.series.chart.options
                  && point.series.chart.options.ngComponent) {
                  let name = point.name;
                  let color = point.color;
                  if (!name || name === 'other') {
                    name = point.series.chart.options.ngComponent.chartData[point.series.index].category;
                    color = point.series.chart.options.ngComponent.pieColors[point.series.index];
                  }
                  point.series.chart.options.ngComponent.handleDoubleClick(point.series.index, name, color, point.y);
                }
              });
            }
          });
        }
      });
    }
  }

  handleMailboxStatistics(data): boolean {
    if (!data || !data.mailBoxStat || !data.mailBoxStat.length) {
      console.log("No mail statistics from service returned!");
      return false;
    }
    const mailstats = data.mailBoxStat;
    if (!mailstats || !mailstats.length) {
      console.log("No mail statistics at all!");
      return false;
    }
    this.chartData = mailstats.map(e => {
      if (e && e.category && e.category.toUpperCase() === 'OUTBOX') {
        e.category = 'Sent';
      }
      return e;
    });
    // C153176-4577 maximum four donuts allowed in mailbox statistics
    if (this.chartData && this.chartData.length > 4) {
      this.chartData.splice(4, this.chartData.length - 4);
    }
    return this.updateChartData();
  }

  updateChartData() {
    if (!this.chartData) {
      return false;
    }
    let chartData: Array<any> = this.chartData;
    this.mailboxStatistics.labels.items = [];
    this.mailboxStatistics.series = [];
    this.mailboxStatistics.annotations = [];


    if (this.isMobile) {
      chartData = [];
      chartData[0] = this.chartData[this.selectedIndex];
    }

    chartData.forEach((e, i) => {
      // set annotations
      if (e.count === undefined) {
        e.count = 0;
      }
      if (e.unReadCount === undefined) {
        e.unReadCount = 0;
      }
      if (e.percentWrtPrevDay === undefined) {
        e.percentWrtPrevDay = 0;
      }
      if (e.upOrDown === undefined) {
        e.upOrDown = 'down';
      }
      this.mailboxStatistics.annotations.push({
        shapes: [{
          point: {
            x: this.annotationInitialPos.x + (i * this.pieCenterIncrementX),
            y: this.annotationInitialPos.y
          },
          type: 'circle',
          r: this.annotationRadius,
          fill: 'rgba(0, 45, 114, 1)',
          strokeWidth: 0,
        }],
        visible: (this.showInboxView && i === this.inboxViewIndex),
        draggable: "",
        events: {
          click: (event) => {
            this.closeInplaceView(event, i);
          },
          dblclick: (event) => {
            this.closeInplaceView(event, i);
          },
        },
        zIndex: 1 // C153176-5113: restore zIndex so as to let label show on top of annotation circle
      });


      // set category label
      this.mailboxStatistics.labels.items.push({
        html: '<span style="line-height: 16px;font-size: 16px;font-family: \'OpenSansLight\';">'
          + e.category + '</span>',
        style: {
          left: '' + (0 + this.categoryInitialPos.x + (i * this.pieCenterIncrementX)
            + this.categoryLabelBias(e.category)) + 'px',
          top: '' + this.categoryInitialPos.y + 'py',
          color: this.labelColor
        }
      });
      // set count label
      this.mailboxStatistics.labels.items.push({
        html: '<span class="mailbox-statistics-count-label-' + i
          + '" style="line-height: 28px;font-size: 28px;font-family: \'OpenSansLight\';">'
          + (e.count !== undefined && e.count !== null ? e.count.toLocaleString() : 0) + '</span>',
        style: {
          left: '' + (0 + this.countInitialPos.x + (i * this.pieCenterIncrementX) + this.countLabelBias(e.count)) + 'px',
          top: '' + this.countInitialPos.y + 'py',
          color: this.labelColor
        },
      });
      // prepare up/down label
      // C153176-4383: handle locale string in percentage field
      let label = '' + this.getPercentage(e.percentWrtPrevDay);
      let color = 'black';
      if (e.upOrDown && e.upOrDown === 'up') {
        if (label !== '0') {
          label = '&uarr;&nbsp;+' + label + '%';
        } else {
          label = '&uarr;&nbsp;' + label + '%';
        }
        color = this.trendColors.up;
      } else {
        if (label !== '0') {
          label = '&darr;&nbsp;-' + label + '%';
        } else {
          label = '&darr;&nbsp;' + label + '%';
        }
        color = this.trendColors.down;
      }
      // set chart series and up/down label
      this.mailboxStatistics.series.push({
        type: 'pie',
        name: e.category,
        data: [{
          name: e.category + this.viewTypeSeparator + 'read',
          y: parseInt(e.count, 10) > 0 ? parseInt(e.count, 10) - this.getUnReadCount(e) : 1,
          trend: label,
          /* color: this.defaultColor */
          color: this.getDonutColor(e.category)
        }, {
          name: e.category + this.viewTypeSeparator + 'unread',
          y: this.getUnReadCount(e),
          color: this.pieColors[i]
        }],
        center: [this.pieInitialPos.x + (i * this.pieCenterIncrementX), this.pieInitialPos.y],
        showInLegend: false,
        emptyData: parseInt(e.count, 10) === 0,
        dataLabels: {
          enabled: true,
          distance: '-99%',
          y: 12,
          formatter() {
            if (!this.point.name.includes('unread')) {
              return '<div><span style="line-height: 16px;font-size: 16px;font-family: \'OpenSansLight\';">'
                + label + '</span></div>';
            } else {
              return null;
            }
          },
          color,
          useHTML: true
        },
        point: {
          events: {
            click: (evt) => {
              // do nothing
            }
          }
        }
      });
      if (this.showInboxView && i === this.inboxViewIndex) {
        setTimeout(() => {
          const elem = $(".mailbox-statistics-count-label-" + i);
          if (elem && elem.length) {
            elem.click(event => {
              if (this.closeCallbackEnabled) {
                this.closeInplaceView(event, i);
              }
            });
          }
        }, 2000);
      }
    });
    return true;
  }

  countLabelBias(count: number): number {
    const label = '' + (count ? count.toLocaleString() : 0);
    if (!label || !label.length) {
      return 0;
    }
    return 15 + (2 - label.length) * 8;
  }

  handleDoubleClick(index, name: string, color, count) {
    if (this.fullScreen) {
      console.log("Skipping double click handling in full screen mode");
      return;
    }
    if (this.inboxViewIndex === -1 || this.inboxViewIndex === index) {
      // toggle the 'showInboxView'
      this.showInboxView = !this.showInboxView;
      this.dashboardService.setDashboardInline(this.showInboxView);
    } else {
      this.showInboxView = true;
      this.dashboardService.setDashboardInline(this.showInboxView);
    }
    if (this.showInboxView) {
      this.inboxViewIndex = index;
    }

    // hide all the drilldown labels
    Object.keys(this.drilldownLabels).forEach((n) => {
      if (this.drilldownLabels[n]) {
        this.drilldownLabels[n].hide();
        this.drilldownLabels[n].destroy();
        delete this.drilldownLabels[n];
        // C153176-4700: delete ageData along with drilldown label
        delete this.ageDataByName[n];
      }
    });
    // show the drilldown label of clicked item
    if (this.showInboxView) {
      this.requestMessageView(name, color, count);
    }
    // show annotation item
    if (index >= 0 && this.mailboxStatisticsChart && this.mailboxStatisticsChart.annotations
      && this.mailboxStatisticsChart.annotations.length) {
      this.mailboxStatisticsChart.annotations.forEach(elem => {
        if (elem && elem.shapes && elem.shapes.length) {
          elem.graphic.hide();
        }
      });
      if (this.showInboxView) {
        this.mailboxStatisticsChart.annotations[index].graphic.show();
      }
    }
    // update count label color
    this.updateCountLabelColor(index, this.showInboxView ? '#FCFCFC' : this.labelColor);
    this.updateCloseCallback(index, this.showInboxView);
  }

  updateCloseCallback(index, enabled) {
    if (this.closeCallbackSet !== true && this.chartData && this.chartData.length) {
      for (let i = 0; i < this.chartData.length; i++) {
        let elem = $(".mailbox-statistics-category-label-" + i);
        if (elem && elem.length) {
          elem.click(event => {
            if (this.closeCallbackEnabled) {
              this.closeInplaceView(event, i);
            }
          });
        }
        elem = $(".mailbox-statistics-count-label-" + i);
        if (elem && elem.length) {
          elem.click(event => {
            if (this.closeCallbackEnabled) {
              this.closeInplaceView(event, i);
            }
          });
        }
      }
      this.closeCallbackSet = true;
    }
    this.closeCallbackEnabled = enabled;
  }

  updateCountLabelColor(index, color) {
    if (index >= 0) {
      const elem = $(".mailbox-statistics-count-label-" + index);
      if (elem && elem.length) {
        elem.parent().css('fill', color);
        elem.parent().css('color', color);
      }
      for (let i = 0; i < this.chartData.length; i++) {
        if (i !== index) {
          const elem = $(".mailbox-statistics-count-label-" + i);
          if (elem && elem.length) {
            elem.parent().css('fill', this.labelColor);
            elem.parent().css('color', this.labelColor);
          }
        }
      }
    } else if (this.chartData && this.chartData.length) {
      for (let i = 0; i < this.chartData.length; i++) {
        const elem = $(".mailbox-statistics-count-label-" + i);
        if (elem && elem.length) {
          elem.parent().css('fill', color);
          elem.parent().css('color', color);
        }
      }
    }
  }

  requestMessageView(name: string, color, count) {
    this.inboxService.startViewLoading({ color });
    let filterStr;
    if (name && name.indexOf(this.viewTypeSeparator) !== -1) {
      const vs = name.split(this.viewTypeSeparator);
      name = vs[0];
      filterStr = vs[1];
    }
    sessionStorage.setItem('dashboardInlineTab', name);

    const options = filterStr ? { viewName: name, count, type: 'readBy', value: filterStr === 'read', isDashboardInlineView: true } :
      { viewName: name, count, type: "Mailbox Statistics", isDashboardInlineView: true };
    if (this.prepareInlineViewRequest(options)) {
      // C153176-5011: trigggering service request and view opening
      this.inboxService.requestView(options);
      this.inboxService.triggerDataChange({ tabName: name.toUpperCase(), dashboardInlineView: true });
    }
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Dashboard", "Mailbox Stats click - actions from dashboard-" + name, "Double Click", "Mailbox Stats click - actions from dashboard" + name, 1);
  }

  prepareInlineViewRequest(options) {
    let viewName = options.viewName;
    let myCount = options.count;
    if (viewName === 'Sent') {
      // set tab data before renaming
      viewName = 'Outbox'; // Business asked to rename Outbox to Sent in UI
    } else {
      if (viewName.indexOf('Escalation') > -1) {
        viewName = 'Potential Escalations';
      }
    }

    const requestObj: any = {};
    requestObj.viewName = viewName;
    if (QmaConstant.inboxGridViewType[viewName]) {
      if (QmaConstant.inboxGridViewType[viewName].viewType) {
        requestObj.viewType = QmaConstant.inboxGridViewType[viewName].viewType;
      } else if (QmaConstant.inboxGridViewType[viewName].isChartView) {
        requestObj.isChartView = QmaConstant.inboxGridViewType[viewName].isChartView;
        myCount = undefined;
      }
      if (viewName === 'Assigned To Me') {
        requestObj.assignedToMe = 'Y';
      }
      if (viewName === 'Unassigned') {
        requestObj.unAssignedMessage = 'Y';
      }
      if (options.type && (options.value !== undefined)) { // must check 'undefined' as 'false' would be a valid value
        const filter = { type: options.type, value: options.value, subtype: undefined, subvalue: undefined };
        if (options.subtype && options.subvalue) {
          filter.subtype = options.subtype;
          filter.subvalue = options.subvalue;
        }
        requestObj.filter = filter;
      }
      // always set 'isRequestFromMailBoxStats=true'
      requestObj.isRequestFromMailBoxStats = true;
      console.debug('mailbox-statistics: Setting inbox request:', requestObj);
      this.inboxService.requestObj = requestObj;
      this.inboxService.resetRequest = true;
      // C153176-5011: returning true so as to trigger service request
      return true;
    } else {
      // C153176-5011: make sure the view exists in local cache before opening inline myview
      let viewIndex = this.inboxService.myViewItems ? this.inboxService.myViewItems.findIndex(item => item && item.name === viewName) : -1;
      if (viewIndex === -1) {
        console.debug('mailbox-statistics: failed to identify viewName = ' + viewName + '!!');
        this.inboxService.stopViewLoading();
      } else {
        // C153176-5011: request myview data so as to finalize the request
        requestObj.viewName = viewName;
        requestObj.viewType = -1;
        if (options.type && (options.value !== undefined)) { // must check 'undefined' as 'false' would be a valid value
          const filter = { type: options.type, value: options.value, subtype: undefined, subvalue: undefined };
          if (options.subtype && options.subvalue) {
            filter.subtype = options.subtype;
            filter.subvalue = options.subvalue;
          }
          requestObj.filter = filter;
        }
        this.myViewRequestObject = requestObj;
        this.myViewRequestOptions = options;
        console.debug('Triggering myview', viewName);
        this.myViewService.getUserView(viewName);
      }
      // C153176-5011 returning false so as not to trigger service request immediately
      return false;
    }
  }

  /**
   * C153176-5011: handling myview inline view request
   */
  initMyViewHandler() {
    this.myViewService.getUserViewSubj().subscribe((userView) => {
      if (!this.myViewRequestObject) {
        return;
      }
      let requestObj = this.myViewRequestObject;
      let searchInType;
      if (userView) {
        // set customized columns to inboxService first
        this.inboxService.customizedColumns = { columns: userView.selectedShowCols };
        if (userView.criteria) {
          const criteria = JSON.parse(userView.criteria);
          if (criteria.$and && criteria.$and.length && criteria.$and[0].viewType) {
            searchInType = criteria.$and[0].viewType;
          }
        }
      }
      // C153176-4946: caching searchInType in request
      if (searchInType) {
        requestObj.myViewSearchInType = searchInType;
      }
      // always set 'isRequestFromMailBoxStats=true'
      requestObj.isRequestFromMailBoxStats = true;
      console.debug('mailbox-statistics: Setting inbox request:', requestObj);
      this.inboxService.requestObj = requestObj;
      this.inboxService.resetRequest = true;
      // C153176-5011: trigggering service request and view opening
      this.inboxService.requestView(this.myViewRequestOptions);
      this.inboxService.triggerDataChange({ tabName: this.myViewRequestOptions.viewName.toUpperCase(), dashboardInlineView: true });
      // reset request and options
      this.myViewRequestObject = undefined;
      this.myViewRequestOptions = undefined;
    }, (error) => {
      console.log('initMyViewHandler', error);
      this.inboxService.stopViewLoading();
      this.myViewRequestObject = undefined;
      this.myViewRequestOptions = undefined;
    });
  }

  closeInplaceView(event, index) {
    if (index !== -1 && (!this.showInboxView || this.inboxViewIndex !== index)) {
      return;
    }
    // toggle the 'showInboxView'
    this.showInboxView = false;
    this.dashboardService.setDashboardInline(this.showInboxView);
    this.inboxViewIndex = -1;
    // disable callback on labels
    this.closeCallbackEnabled = false;
    // hide all the drilldown labels
    Object.keys(this.drilldownLabels).forEach(name => {
      if (this.drilldownLabels[name]) {
        this.drilldownLabels[name].hide();
        this.drilldownLabels[name].destroy();
        delete this.drilldownLabels[name];
        // C153176-4700: delete ageData along with drilldown label
        delete this.ageDataByName[name];
      }
    });
    // hide annotations
    if (this.mailboxStatisticsChart && this.mailboxStatisticsChart.annotations
      && this.mailboxStatisticsChart.annotations.length) {
      this.mailboxStatisticsChart.annotations.forEach(elem => {
        if (elem && elem.shapes && elem.shapes.length) {
          elem.graphic.hide();
        }
      });
    }
    // update count label color
    this.updateCountLabelColor(-1, this.labelColor);
  }

  // handle ESC in case of full screen
  @HostListener('document:keydown.esc', ['$event'])
  onKeydown(event) {
    event.stopPropagation();
    event.preventDefault();
    if (this.fullScreen && event && event.code === 'Escape') {
      this.exitFullScreen(this.mailboxStatisticsChart);
    }
    return false;
  }

  enterFullScreen(chart: any) {
    const elem = $('#mailbox-statistics-chart-box');
    if (elem && elem.length) {
      elem.toggleClass('dashboardModal');
    }
    if (this.closeCallbackEnabled) {
      this.closeInplaceView(event, -1);
    }
    this.fullScreen = true;
    if (chart.exportContextMenu && chart.exportContextMenu.childNodes) {
      chart.exportContextMenu.childNodes.forEach(node => {
        if (node && node.childNodes) {
          node.childNodes.forEach(n => {
            if (n && n.innerText === 'Maximize') {
              n.style.display = 'none';
            }
          });
        }
      });
    }
    if (this.fadingDialogComponent) {
      this.fadingDialogComponent.show($('#mailbox-statistics-box')[0]);
    }
  }

  exitFullScreen(chart: any) {
    if (!chart) {
      return;
    }
    const elem = $('#mailbox-statistics-chart-box');
    if (elem && elem.length) {
      elem.toggleClass('dashboardModal');
    }
    this.fullScreen = false;
    if (this.fadingDialogComponent) {
      this.fadingDialogComponent.hide($('#mailbox-statistics-box')[0]);
    }
    if (chart.exportContextMenu && chart.exportContextMenu.childNodes) {
      chart.exportContextMenu.childNodes.forEach(node => {
        if (node && node.childNodes) {
          node.childNodes.forEach(n => {
            if (n && n.innerText === 'Maximize') {
              n.style.display = '';
            }
          });
        }
      });
    }
    chart.reflow();
  }

  categoryLabelBias(label: string): number {
    if (!label || !label.length) {
      return 0;
    }
    return (14 - label.length) * 3.3 - 28;
  }
  downloadCustomCsv() {
    let csv = 'Category, Total Inquiry Count, UnRead Inquiry Count, % Change with respect to Previous Day\n';
    this.data.mailBoxStat.forEach(element => {
      csv += element.category + ',' + element.count + ',' + (element.unReadCount !== undefined ? element.unReadCount : 0)
        + "," + element.percentWrtPrevDay + '\n';
    });
    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + csv;
    hiddenElement.target = '_blank';
    hiddenElement.download = 'Mail_Box_Statistics.csv';
    hiddenElement.click();
  }

  getUnReadCount(e) {
    if (!e || !e.unReadCount) {
      return 0;
    } else {
      return parseInt(e.unReadCount, 10);
    }
  }

  updateTooltip(tooltip, point, t) {
    const result = [];
    const p0index = point.index;
    const s0index = point.series.index;
    const emptyData = point.series.chart.options.series[s0index].emptyData;
    const total = emptyData ? '0' : '' + (parseInt(point.series.data[0].y) + parseInt(point.series.data[1].y)).toLocaleString();
    result.push('<table style="border-collapse:collapse;">');
    result.push('<tr><td style="bording-spacing:2px;pading:2px;"><span style="color:' + point.series.data[p0index].color + ';">● </span><b>' + point.name +
      ': ' + (emptyData ? 0 : point.series.data[p0index].y.toLocaleString()) + '</b></td></tr>' +
      '<tr><td style="bording-spacing:2px;"><span style="font-size:11px;">' + '<b>&nbsp;&nbsp;&nbsp;( Total:  ' + total + ' )</b></span></td></tr>');
    result.push('</table>');
    return result;
  }

  onCarouselClick(idx: number) {
    this.selectedIndex = idx;
    this.chartWidth = undefined;
    this.chartHeight = undefined;
    this.redrawWidgets(this.mailboxStatisticsChart);
  }

  onSwipe(str: string) {
    if (str === 'left') {
      if (this.selectedIndex < (this.mailboxStats.length - 1)) {
        this.selectedIndex++;
        this.onCarouselClick(this.selectedIndex);
      }
    }
    else {
      if (this.selectedIndex > 0) {
        this.selectedIndex--;
        this.onCarouselClick(this.selectedIndex);
      }
    }

  }

  resetInlineView() {
    if (this.fullScreen) {
      console.log("Skipping resetInlineView in full screen mode");
      return;
    }
    const index = -1;
    this.showInboxView = false;
    this.dashboardService.setDashboardInline(this.showInboxView);
    this.inboxViewIndex = index;

    // hide all the drilldown labels
    Object.keys(this.drilldownLabels).forEach((n) => {
      if (this.drilldownLabels[n]) {
        this.drilldownLabels[n].hide();
        this.drilldownLabels[n].destroy();
        delete this.drilldownLabels[n];
        // C153176-4700: delete ageData along with drilldown label
        delete this.ageDataByName[n];
      }
    });

    // show annotation item
    if (index >= 0 && this.mailboxStatisticsChart && this.mailboxStatisticsChart.annotations
      && this.mailboxStatisticsChart.annotations.length) {
      this.mailboxStatisticsChart.annotations.forEach(elem => {
        if (elem && elem.shapes && elem.shapes.length) {
          elem.graphic.hide();
        }
      });
    }
    // update count label color
    this.updateCountLabelColor(index, this.showInboxView ? '#FCFCFC' : this.labelColor);
    this.updateCloseCallback(index, this.showInboxView);
  }

  getPercentage(value) {
    if (!value) {
      return 0;
    }
    // C153176-4577: support large percentage value
    let p;
    if (typeof value === 'string') {
      p = parseFloat(value.replace(/,/g, ''))
    } else if (typeof value === 'number') {
      p = value;
    }
    if (p >= 1000) {
      return ((p / 1000).toFixed(2) + "k");
    } else {
      return p.toFixed(2);
    }
  }

  getDonutColor(category) {
    let color = "";
    const chartName = category ? category.toUpperCase() : category;
    switch (chartName) {
      case 'SENT': {
        color = '#00B0B9';
        break;
      }
      case 'RESOLVED': {
        color = '#bb86fc';
        break;
      }
      default: {
        color = this.defaultColor;
        break;
      }
    }
    return color;
  }
}