import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { TabDataService } from 'src/app/services/tab-data.service';
import { QmaConstant } from 'src/app/constant/qma-constant';
import { InboxService } from 'src/app/services/inbox.service';


@Injectable({
  providedIn: 'root'
})

export class SideNavigationService {
  private isOpen:boolean = false;
  private subject: Subject<boolean> = new BehaviorSubject<boolean>(false);
  private defaultPageSize = QmaConstant.inboxGridViewType.defaultPageSize;
  constructor(private tabDataService:TabDataService,private inboxService:InboxService) {
  }


  /**
   * To open Navigation Panel
   */
  public open() {
    this.isOpen = true;
    this.subject.next(this.isOpen);    
  }

   /**
   * To close Navigation Panel
   */
  public close() {
    this.isOpen = false;
    this.subject.next(this.isOpen);
  }

   /**
   * To get the current status on Navigation Panel
   */
  public getNavStatus(){
   return this.subject.asObservable();
  }

    /**
   * Toggle Navigation Panel
   */
  public toggle(): void {
    this.isOpen = !this.isOpen;
    this.subject.next(this.isOpen);
  }

  public currentMenuItem(){
    
  }

  formRequestObjectOfGrid(tabName: string) {
    // begin - create requestObj to load inbox component
    // viewName should be the tabName if specified.
    let viewName = tabName ? tabName :  this.tabDataService.savedTabData;
    if (viewName == 'Sent') {
      viewName = 'Outbox'; // Business asked to rename Outbox to Sent in UI
    }
    if(viewName == 'Escalation'){
      viewName = 'Potential Escalations';
    }
    let requestObj: any = {};
    if (viewName == 'Assigned To Me') {
      requestObj['assignedToMe']  = 'Y'; 
    }
    if (viewName == 'Unassigned') {
      requestObj['unAssignedMessage']  = 'Y'; 
    }
    if(viewName == 'Chat'){
      viewName = 'ChatView';
    }
    requestObj['viewName'] = viewName;
    if (QmaConstant.inboxGridViewType[viewName].viewType) {
      requestObj['viewType'] = QmaConstant.inboxGridViewType[viewName].viewType;
    }
  
    // server side pagination
    requestObj['pageNum'] = QmaConstant.inboxGridViewType.defaultPageNo;
    requestObj['pageSize'] = this.defaultPageSize;
    

  // end - create requestObj to load inbox component
    this.inboxService.requestObj = requestObj;
    // set the resetRequest so this will reset the request anew (when user opens a previously opened tab)
    this.inboxService.resetRequest = true;
  }

  setPageSize(loginUserInfo, isMobile) {
    if (!loginUserInfo || !loginUserInfo.qmaUI2Config) {
      return;
    }
    if (isMobile && loginUserInfo.qmaUI2Config.noOfRecordsOnPage_device) {
      this.defaultPageSize = loginUserInfo.qmaUI2Config.noOfRecordsOnPage_device;
    } else if (loginUserInfo.qmaUI2Config.noOfRecordsOnPage_web) {
      this.defaultPageSize = loginUserInfo.qmaUI2Config.noOfRecordsOnPage_web;
    }
  }
}
