
import { timer as observableTimer, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserDataService } from './user-data.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class SessionTimeOut {

  private sessionTimer = 0;
  private sessionAlertTimer = 0;
  private userSessionTimeout = 0;

  sessionWarning = new BehaviorSubject<boolean>(false);
  showSessionRefreshAlert = this.sessionWarning.asObservable();

  private sessionTimedoutAlert = new BehaviorSubject<boolean>(false);
  showSessionTimedoutAlert = this.sessionTimedoutAlert.asObservable();
  private smLogOutUrl: string;

  constructor(private userDataService: UserDataService, private oauthService: OAuthService) {
  }
  initializeSessionConfig(sesionTimeoutInterval, sessionAlertInterval, smLogOutUrl) {
    this.sessionTimer = sesionTimeoutInterval || 1800;
    this.sessionAlertTimer = sessionAlertInterval || 300;
    this.smLogOutUrl = smLogOutUrl || "";
    this.userSessionTimeout = sesionTimeoutInterval || 1800;
  }

  startSessionTimer() {
    observableTimer(0, 1000)
      .subscribe(() => {
        --this.sessionTimer;
        if (this.sessionTimer === this.sessionAlertTimer) {
          this.sessionWarning.next(true);
        }
        if (this.sessionTimer <= 0) {
          this.refresh();
        }
      });
  }

  resetCounter() {
    console.log('resetting session timer');
    this.sessionTimer = this.userSessionTimeout || 1800;
  }


  changeWarning(warn: boolean) {
    this.sessionWarning.next(warn);
  }

  changeAlert(dispaly: boolean) {
    this.sessionTimedoutAlert.next(dispaly);
  }

  refresh() {
    console.log('session timeout');
    this.cvLogout();
  }

  isSessionTimedout() {
    if (this.sessionTimer <= 0) {
      return true;
    }

  }

  cvLogout(): void {
    console.log('capturing CV logout activity in DB');
    this.userDataService.logoutUserSession().subscribe(
      data => {
        console.log(data);
        console.log('calling CV logout url after capturing in db');
        this.oauthService.logOut();
        // window.location.replace(this.smLogOutUrl);
      },
      error => {
        console.log(error);
        console.log('calling CV logout url without capturing in db');
        this.oauthService.logOut();
        // window.location.replace(this.smLogOutUrl);
      }
    );

  }
}