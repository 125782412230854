import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ColorPickerModule } from 'primeng/colorpicker';


@Component({
  selector: 'app-delete-cell-renderer',
  //Jira : 4703 : Confirmation Dialog before delete
  templateUrl: './delete-cell-renderer.component.html',
  styles: [`
  .bottom-button-holder {
    background-color: rgba(0, 189, 242, 0.06);
}

.search-btn,
.clear-btn {
    width: 20% !important;
    height: 35px;
    padding: 0;
    font-family: OpenSansLight;
}

.search-btn {
    color: #FFF;
    background-color: #2E64AF;
}

.clear-btn {
    color: #002D72;
    background-color: #FFFFFF;
    border: 1px solid #002D72;
}

label {
    font-family: "OpenSans";
    font-weight: bold;
    color: #002D72;
    font-size: 16px;
}

`],
})
export class DeleteCellRendererComponent implements ICellRendererAngularComp {
  public params: any;
  public gridApi: any;
  public color: string = '#1976D2';
  //Jira : 4703 : Confirmation Dialog before delete
  public showPopUpDelete: boolean = false;
  public confirmMsgText: string;

  agInit(params: any): void {
    this.params = params;
    this.gridApi = params.api;

  }

  public deleteRow() {
    this.showPopUpDelete = false; //Jira : 4703 : Confirmation Dialog before delete
    var selectedData = this.params.data;
    var res = this.gridApi.applyTransaction({ remove: [selectedData] });
  }
  //Jira : 4703 : Confirmation Dialog before delete
  setConfirmation(): void {
    this.showPopUpDelete = true;
    this.confirmMsgText = "Are you sure want to delete the selected items ?";
  }
  onCancel() {
    this.showPopUpDelete = false;
  }
  onOk() {
    this.deleteRow();
  }
  refresh(): boolean {
    return true;
  }
}