import { LoginUserInfo } from './../../model/LoginUserInfo/LoginUserInfo';
import { Component, OnInit, OnChanges, SimpleChanges, Input, ViewEncapsulation, EventEmitter, Output, ViewChild, SecurityContext, ElementRef, AfterViewChecked, AfterViewInit, HostListener, Optional } from "@angular/core";
import { InboxService } from "src/app/services/inbox.service";
import { QmaConstant } from "src/app/constant/qma-constant";
import { OverlayPanel } from "primeng//overlaypanel";
import { TabDataService } from "src/app/services/tab-data.service";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NewMessageService } from "src/app/services/newMessage/new-message.service";
import { NlpViewComponent } from "src/app/inbox/nlp-view/nlp-view.component";
import { Message } from 'primeng/api';
import { DatePipe } from "@angular/common";
import { UserDataService } from '../../services/user-data.service';
import { DateFormatPipe } from 'src/app/common/pipes/date-format.pipe';
import { AppUtils } from 'src/app/common/utility/appUtil';
import { AttachmentViewerDialogComponent } from '../../common/component/attachment-viewer/attachmentviewer.dialog.component';
import * as _ from 'underscore';
import { UserSignature } from "src/app/model/LoginUserInfo/UserSignature";
import { InquiryTemplate } from "src/app/model/user-profile/inquiryTemplate";
import { environment } from 'src/environments/environment';
import { FormGroup } from '@angular/forms';
import { ContextMenu } from 'src/app/model/Common/ContextMenu';
import { ActionPopupComponent } from 'src/app/common/component/action-popup/action-popup.component';
import { ContextMenuService } from 'src/app/services/contextMenu/context-menu.service';
import { RejectInquiryComponent } from 'src/app/common/component/reject-inquiry/reject-inquiry.component';
import { ResolveInquiryComponent } from 'src/app/common/component/resolve-inquiry/resolve-inquiry.component';
import { MessageService } from 'primeng/api';
import { RequestCacheService } from 'src/app/services/cache/request-cache.service';
import * as moment from 'moment';
import { GlobalDirectoryService } from 'src/app/services/global-directory/global-directory.service';
import { PiwikProUtils } from 'src/app/common/utility/piwikProUtils';
import { ReAgeComponent } from 'src/app/common/component/re-age/re-age.component';
import { HtmlElementsPaginationService } from 'src/app/utility/html-elements-pagination.service';
import * as $ from 'jquery';
import { TaskizeAuthService } from 'src/app/services/taskize-auth.service';
import { InboxViewComponent } from '../inboxView/inboxView.component';
import { CidViewComponent } from '../cid-view/cid-view.component';
interface ConversationOption {
    name: string,
    code: string
}

interface attachmentOption {
    name: string,
    id: string,
    fileInfo: any,
    imageName: string // C153176-4708	display toaster message on click
}

interface replyOption {
    name: string,
    id: string,
    conversation?: any
}

interface Recipient {
    displayName: string,
    emailAddr: string,
    groupId: number,
    toFrom: string,
    userId: string,
    isExternal: boolean
}

interface NominationData {
    reason: string,
    groupFrom: string,
    user: string
}

const EMAIL_ADDR = "EMAIL_ADDR";
const USER_NAME = "USER_NAME";
// C170665-3293 Auto generated email with comments corrupting html contents
// const WILD_CHARACTERS = ['&lt;![endif]--&gt;&lt;![endif]--&gt;', '&lt;![endif]--&gt;', '<![endif]--><![endif]-->',
//  '<![endif]-->', '<!--[if gte mso 10]>','<!--[endif]---->','<!---->','<!\[endif\]-->','<!--']; // C153176-5833

@Component({
    selector: 'mail-detail-view',
    templateUrl: './mailDetailView.component.html',
    styleUrls: ['./mailDetailView.component.scss'],
    providers: [HtmlElementsPaginationService]/* ,
    host: {
        '(document:keydown)': 'OnKeyDown($event)'
    }, */
})
export class MailDetailViewComponent implements OnInit, OnChanges, AfterViewInit {

    // Jira : 4912 : NLP Suggestion box
    displaySuggestionBox: boolean = false;
    mobileToList: any;
    WILD_CHARACTERS = [];
    isReplaceAllWildCharsEnabled = false;

    @ViewChild(NlpViewComponent) nlpViewComponent: any;
    @ViewChild(CidViewComponent) cidViewComponent: any;
    @ViewChild('inlineCompRef', { read: ElementRef }) inlineCompDiv: ElementRef;
    inlineComponentShrinked = false;
    @ViewChild('attachvwr') attachViewer: AttachmentViewerDialogComponent;
    @ViewChild(ActionPopupComponent) actionPopupComponent: any;
    @ViewChild(RejectInquiryComponent) rejInqComponent: any;
    @ViewChild(ResolveInquiryComponent) resolveInqComponent: any;
    @ViewChild('reAge') reAgeComponent: ReAgeComponent;

    @Input() selectedMail?: any;
    @Input() inquirySuject?: string = '';
    @Input() inboxViewMode?: string = 'vertical';
    // Selected Conversation History
    @Input() selectedConvId?: number;
    @Input() selectedInqId?: number;
    @Input() selectedRowData?: any;

    // Mail sent from assigned from
    @Input() assignedGroup?: string;
    @Input() inquiryWorkflow?: any;

    // Selected Trades from CLC
    @Input() trades?: string;

    // Emitter for Attachments, Notes and Audits
    @Output() attachmentEvent = new EventEmitter();
    @Output() notesEvent = new EventEmitter();
    @Output() auditsEvent = new EventEmitter();
    @Output() closeMailDetail = new EventEmitter();
    closeMailDetailView(){
        this.closeMailDetail.emit(true);
    }
    attachByteArray: any;
    sAttachViewUrl: string;
    conversationList: any[] = [];
    detailedConvList: any[] = [];
    // C153176-5257: inquiry-reported conversation count
    @Input() inquiryReportedConvCount?: number = 0;
    totalConversations: number = 0;
    isExpanded: boolean = false;
    isMobile: boolean = false;

    inquiry: any = {};
    @Input() inquirySubject?: string = '';
    audits: any[] = [];  // Audits
    notes: any[] = []; // Notes
    attachments: any[] = [];// Attachments
    attachmentList: any[] = [];// Attachments

    showingTo: any;

    options: ConversationOption[];
    replyOptions: replyOption[];
    downloadOptions: replyOption[];
    quickReplyOptions: replyOption[];
    convRplyOptions: replyOption[];
    convDownloadOptions: replyOption[];
    convQucikReplyOptions: replyOption[];
    attachmentsOptions: attachmentOption[] = [];
    tabName: string;
    myTabName: string;
    disableNLP: boolean = false;
    inquiryIcon: boolean = false;
    guserPrefDateFormat: string = '';
    loggedInUser: LoginUserInfo;
    quickReply: InquiryTemplate;
    visibleQuickReply = false;
    quickReplyActionName = "";
    @Input() mailContainerHeight?: any;
    recipientShow: boolean = false;
    multiToShow: boolean = false;
    isInquiryChanged: boolean = false;
    // force refresh for reply/forward etc on selected mail
    forceRefresh: boolean = false // C170665-148 Reading pane Focus moving itself
    clcTrades: any;
    parser: any;
    nominationData: NominationData;
    @Output() inlineReply = new EventEmitter();
    newMessageData: FormGroup;
    @Output() newMessageForm = new EventEmitter();
    @Output() inlineReplySendFromMailDetail = new EventEmitter();
    @Output() backClick = new EventEmitter();

    // For CLC Suggestion Icon
    clcSuggestionAvailable: boolean = false;
    clcSuggestionRecords: string[] = null;
    clcSuggestionRequest: any;
    autoAssignmentAvailable: boolean = false;

    //Rejection reason
    rejectReason: boolean = false;
    displayNominationConfirmBox: boolean = false;
    nominationRejectionReason: string = ""; // To keep the rejection Reject.
    hasRejectReason: boolean = false; // ENable or Disable Submit for Rejection Nomination
    // C153176-4229 : Push Contact as recipients
    @Input() pushContact?: any;
    singleContact: any;
    // C153176-4910: loading status flag
    @Input() loadingMailInProgress?: boolean = false;

    // C153176-4660: selectedMailRequest from parent component
    @Input() selectedMailRequest?: any;
    allContentLoaded: boolean = false;
    @Input() parentComponent?: any;
    // C153176-4983: tracking inboxViewName so as to show/hide reply/reply-all/forward buttons
    @Input() inboxViewName?: string;
    selectedContactId: string = '';
    //to display contact info
    GDIR_DISPLAY_INFO: boolean;
    // C153176-4708	display toaster message on click

    citiDomains: any[];

    copyAllImage: string = "copy_icon.svg";
    @Output() onInqUnread = new EventEmitter();
    private nonResolveTabs = ['RESOLVED', 'PENDING APPROVALS', 'PENDING APPROVAL', 'DRAFTS', 'SENT'];
    isResolveApplicable: boolean = false;

    //C153176-6021| NLP - When no sugesstion available
    contentNotAvlbelMsg: string = '';
    displayError: boolean = false;

    // C170665-673 | Selected Inquiry Details got from Inbox.
    @Input() selInquiryDetails?: any;
    isAttachemntPanelCollapsed = true;
    isSelectAllAttchClicked = false;
    extensionImgIconMapping: any = [
        { extension: 'xlsx', imgUrl: 'assets/Inbox/icon-excel.svg' },
        { extension: 'xls', imgUrl: 'assets/Inbox/icon-excel.svg' },
        { extension: 'svg', imgUrl: 'assets/Inbox/icon-img.svg' },
        { extension: 'pdf', imgUrl: 'assets/Inbox/icon-pdf.svg' },
        { extension: 'pptx', imgUrl: 'assets/Inbox/icon-power-point.svg' },
        { extension: 'ppt', imgUrl: 'assets/Inbox/icon-power-point.svg' },
        { extension: 'svg', imgUrl: 'assets/Inbox/icon-txt.svg' },
        { extension: 'docx', imgUrl: 'assets/Inbox/icon-word.svg' },
        { extension: 'doc', imgUrl: 'assets/Inbox/icon-word.svg' },
        { extension: 'zip', imgUrl: 'assets/Inbox/icon-zip.svg' },
        { extension: 'unkown', imgUrl: 'assets/Inbox/icon_unknown_file.svg' },
        { extension: 'eml', imgUrl: 'assets/Inbox/icon-email.svg' },
        { extension: 'html', imgUrl: 'assets/Inbox/icon-html.svg' },

        { extension: 'gif', imgUrl: 'assets/Inbox/GIF_icon.svg' },
        { extension: 'jpg', imgUrl: 'assets/Inbox/JPG_icon.svg' },
        { extension: 'jpeg', imgUrl: 'assets/Inbox/JPG_icon.svg' },
        { extension: 'png', imgUrl: 'assets/Inbox/PNG_icon.svg' },
        { extension: 'jfif', imgUrl: 'assets/Inbox/GIF_icon.svg' },
        { extension: 'tiff', imgUrl: 'assets/Inbox/TIFF_icon.svg' },
        { extension: 'txt', imgUrl: 'assets/Inbox/icon_text_attachment.svg' },

    ];
    @Input()
    inboxView: any;
    menuIconImageConfig = {
        copyIconmouseOut: 'assets/Inbox/copy-iconn-darkblue.svg',
        copyIconmouseOn: 'assets/Inbox/copy-iconn-lightblue.svg',
        copyIconSrc: 'assets/Inbox/copy-iconn-darkblue.svg',
        viewIconmouseOut: 'assets/Inbox/eye-icon-darkblue.svg',
        viewIconmouseOn: 'assets/Inbox/eye-icon-lightblue.svg',
        viewIconSrc: 'assets/Inbox/eye-icon-darkblue.svg',
        downloadIconmouseOut: 'assets/Inbox/download-icon_darkblue.svg',
        downloadIconmouseOn: 'assets/Inbox/download-icon_lightblue.svg',
        downloadIconSrc: 'assets/Inbox/download-icon_darkblue.svg',
        copyAllIconmouseOut: 'assets/Inbox/copy-iconn-darkblue.svg',
        copyAllIconmouseOn: 'assets/Inbox/copy-iconn-lightblue.svg',
        copyAllIconSrc: 'assets/Inbox/copy-iconn-darkblue.svg',
        downloadAllIconmouseOut: 'assets/Inbox/download-icon_darkblue.svg',
        downloadAllIconmouseOn: 'assets/Inbox/download-icon_lightblue.svg',
        downloadAllIconSrc: 'assets/Inbox/download-icon_darkblue.svg'
    }
    showAttchementPopup: boolean = false;
    taskizeInquiryId: any;
    htmlPaginationEnabled = false;
    pazeSizeForConvPagination = 0;
    minConvCountForConvPagination = 0;
    minElmCountForConvPagination = 0;
    elmPageSizeForConvPagination = 0;
    startIndexPageConv = 0;
    endIndexPageConv = 0;
    // on demand loading when user click on print button
    forcePrint = false;
    pageNum = 0;
    pageSize = 0;
    toalConvPages = 0;
    isFromCoversationHistoryGrid = false;
    taskizeEnabled: boolean = false;
    ismailboxGridSelectedInqAndDetailviewinInqInSynch = true;
    outOfSynchPopUpOpenedOneTime = false;
    outOfSynchinterval: any;
    displayCustomerIntent: boolean = false;
    isValidGroupForIntent: boolean;
    constructor(private inboxService: InboxService, private tabDataService: TabDataService, private sanatized: DomSanitizer, private newMessageService: NewMessageService, private datePipe: DatePipe,
        private userDataService: UserDataService, private dateFormatPipe: DateFormatPipe, private contextMenu: ContextMenu, private contextMenuService: ContextMenuService, private messageService: MessageService,
        private requestCacheService: RequestCacheService,
        private globalDirectoryService: GlobalDirectoryService,
        private htmlElementsPaginationService: HtmlElementsPaginationService,
        private taskizeService: TaskizeAuthService, @Optional() public inboboxComp?: InboxViewComponent | null) {
        this.options = [
            { name: 'Print', code: QmaConstant.PRINT },
            { name: 'Download As PDF', code: QmaConstant.PDF },
            { name: 'Download As Email', code: QmaConstant.EMAIL },
            { name: 'Download As MSG', code: QmaConstant.MSG },
            { name: 'Download As CSV', code: QmaConstant.CSV }
        ];

        this.convRplyOptions = [
            { name: 'Reply', id: QmaConstant.REPLY },
            { name: 'Forward', id: QmaConstant.FORWARD },
            { name: 'Quick Reply', id: QmaConstant.QUICK_REPLY },
            { name: 'Re-Age', id: QmaConstant.RE_AGE },
            { name: 'Print', id: QmaConstant.PRINT },
            { name: 'Open In New Window', id: QmaConstant.OPEN_IN_NEW_WINDOW },
            { name: 'Download', id: QmaConstant.DOWNLOAD }
        ]

        this.convDownloadOptions = [
            { name: 'Download As PDF', id: QmaConstant.PDF },
            { name: 'Download As MSG', id: QmaConstant.MSG },
            { name: 'Download As CSV', id: QmaConstant.CSV }
        ]

        this.convQucikReplyOptions = [
            { name: 'Reply with thank you', id: QmaConstant.REPLY_THANK_YOU },
            { name: 'Reply with will revert later', id: QmaConstant.REPLY_LATER }
        ]

        let currTabName = AppUtils.getCurrentTab();
        if (currTabName) {
            this.myTabName = currTabName.toUpperCase();
            this.isResolveApplicable = !this.nonResolveTabs.includes(this.myTabName);
        }
        this.parser = new DOMParser();
        if (window.innerWidth <= QmaConstant.MOBILE_WIDTH) {
            this.isMobile = true;
        }
        this.WILD_CHARACTERS = this.userDataService && this.userDataService.loggedInUserInfo &&
            this.userDataService.loggedInUserInfo.customStyleOverrideList &&
            Array.isArray(this.userDataService.loggedInUserInfo.customStyleOverrideList) ?
            [...this.userDataService.loggedInUserInfo.customStyleOverrideList.filter(wc => wc !== '/g')] : [];
        this.isReplaceAllWildCharsEnabled = this.userDataService && this.userDataService.loggedInUserInfo &&
            this.userDataService.loggedInUserInfo.customStyleOverrideList &&
            Array.isArray(this.userDataService.loggedInUserInfo.customStyleOverrideList) ?
            this.userDataService.loggedInUserInfo.customStyleOverrideList.includes('/g') : false;
        // load pagination config from DB
        try {
            if (this.userDataService && this.userDataService.loggedInUserInfo && this.userDataService.loggedInUserInfo.qmaUI2Config) {
                this.htmlPaginationEnabled = this.userDataService.loggedInUserInfo.qmaUI2Config.enableConvPagination;
                this.pazeSizeForConvPagination = this.userDataService.loggedInUserInfo.qmaUI2Config.pazeSizeForConvPagination;
                this.minConvCountForConvPagination = this.userDataService.loggedInUserInfo.qmaUI2Config.minConvCountForConvPagination
                this.minElmCountForConvPagination = this.userDataService.loggedInUserInfo.qmaUI2Config.minElmCountForConvPagination
                this.elmPageSizeForConvPagination = this.userDataService.loggedInUserInfo.qmaUI2Config.elmPageSizeForConvPagination
            }


        } catch (e) {
            console.log('exception during loading conversation pagination config' + e)
        }

        // check if taskizeEnabled for user
        if (this.userDataService.loggedInUserInfo.isTaskizeEnabledForUser && this.userDataService.loggedInUserInfo.isTaskizeEnabledForUser?.toUpperCase() == 'Y') {
            this.taskizeEnabled = true
        } else {
            this.taskizeEnabled = false
        }
    }

    ngOnInit(): void {
        this.loggedInUser = this.userDataService.loggedInUserInfo;

        const globalDirectoryConfig = this.userDataService.loggedInUserInfo.globalDirectoryConfig;
        this.GDIR_DISPLAY_INFO = globalDirectoryConfig.contactInfoConfig.gdirDisplayInfo;
        this.citiDomains = this.loggedInUser.citiDomainList;
        // Quick Reply
        this.quickReply = this.userDataService.getUserTemplateForQuickReply();
        if (this.quickReply) {
            this.convRplyOptions.push({ name: this.quickReply.templateName + '(Quick Reply)', id: QmaConstant.QUICK_REPLY })
        }
        if (this.taskizeEnabled && this.taskizeInquiryId != '') {
            this.convRplyOptions = [
                { name: 'Reply All', id: QmaConstant.REPLY_ALL }
            ]
        }
        this.tabDataService.getClickedIcon().subscribe(clickedIcon => {
            this.inboxViewMode = clickedIcon;
        });

    }

    ngOnChanges(changes: SimpleChanges): void {

        // C170665-673 | Changing of the data input for mail-detail-view
        let selInqDetails = null;
        let selInqDetailsPrevVal = null;
        //flag resests on new mail load
        this.ismailboxGridSelectedInqAndDetailviewinInqInSynch = true;
        this.outOfSynchPopUpOpenedOneTime = false;
        // one time pop up for out of synch
        try {
            // this.outOfSynchinterval = setInterval(() => {
            //     this.checkIfOutOfSynchPopUpRequired();
            // }, 8000);
            // // clear interval after 1 min
            // setTimeout(() => {
            //     clearInterval(this.outOfSynchinterval);
            // }, 16000);

            if (changes.selInquiryDetails && changes.selInquiryDetails.currentValue) {
                selInqDetails = changes.selInquiryDetails.currentValue;
                this.setInquiryDetailsAttributes(selInqDetails);
                this.checkIfOutOfSynchPopUpRequired();
                if (changes.selInquiryDetails.previousValue) {
                    selInqDetailsPrevVal = changes.selInquiryDetails.previousValue;
                }
            }
        }
        catch (ex) {
            console.log("ne time pop up for out of synch:>" + ex);
        }

        // C170665-325 | Hide the Resolve Icon if inquiry open to new tab from Sent folder.
        if (this.inboxViewName) {
            this.isResolveApplicable = !this.nonResolveTabs.includes(this.inboxViewName.toUpperCase());
        }

        this.selectedContactId = '';
        if (this.inboxViewMode === 'tab' && this.isSameInquiryInTab(changes.selectedMail, changes.assignedGroup, changes.inquirySubject, changes.totalConversations)) {
            // for tab view, do not allow another change than the initial one
            return;
        }

        if (changes.selectedMail && changes.selectedMail.currentValue) {
            if (changes.selectedMail.previousValue && changes.assignedGroup) {
                //C153176-4605 : On expand inline reply should get closed and should not get saved in drafts
                if (this.checkIfInquiryChanged(changes.selectedMail, changes.assignedGroup)) {
                    this.isInquiryChanged = true;
                    this.visibleQuickReply = false;
                }
            } else {
                this.isInquiryChanged = true;
                this.visibleQuickReply = false;
            }
        } else if (selInqDetails) {
            if (selInqDetailsPrevVal && selInqDetails.assignedGroup && selInqDetailsPrevVal.assignedGroup) {
                //C153176-4605 : On expand inline reply should get closed and should not get saved in drafts
                if (this.checkIfInquiryChangedV1(selInqDetails.selectedMail, selInqDetailsPrevVal.selectedMail, selInqDetails.assignedGroup, selInqDetailsPrevVal.assignedGroup)) {
                    this.isInquiryChanged = true;
                    this.visibleQuickReply = false;
                }
            } else {
                this.isInquiryChanged = true;
                this.visibleQuickReply = false;
            }
        }

        // C170665-148 Reading pane Focus moving itself
        // if prev & current ids are same but force refresh req
        if (this.checkIfSameInquiryId(changes)) {
            this.isInquiryChanged = false;
        } else if (selInqDetails && selInqDetailsPrevVal && this.checkIfSameInquiryIdV1(selInqDetails.selectedRowData, selInqDetailsPrevVal.selectedRowData)) {
            this.isInquiryChanged = false;
        }
        // C170665-1282 Navigation : Extend Keyboard shortcut option
        // incase of keyboard navigation up and down arrow key mark inq chnaged as true
        if (selInqDetails && selInqDetails.selectedMail && selInqDetails.selectedMail.inquiry
            && selInqDetails.selectedMail.inquiry.isKeyboardNavigation && selInqDetails.selectedMail.inquiry.isKeyboardNavigation === true) {
            this.isInquiryChanged = true;
        }
        // this.isInquiryChanged = true;
        //if prev and current are inquiry ID's different
        if (this.isInquiryChanged) {
            this.detailedConvList = [];
            this.conversationList = [];
            this.isExpanded = false;
            this.allContentLoaded = false; // C153176-4660: reset flag
            this.inquiry = {};
            this.audits = [];
            this.notes = [];
            this.attachments = [];
            this.attachmentList = [];
            this.getConversationInquiryView(this.selectedMail);
            this.handleNominationConfirm(); //To handle Nomination toggle based on Pending
            // C153176-4985: for mobile version, mark the first conversation as read, since its content is shown to user
            if (this.isMobile && this.parentComponent) {
                this.markFirstConversationAsRead();
                this.inboxService.notifyGridDataReady(this.parentComponent.strMailboxView, true);// C153176-5019: force updateOnly to true
            }
        }

        // Add the trade content to new message
        if (changes.trades && changes.trades.currentValue && this.conversationList.length > 0) {
            this.clcTrades = changes.trades.currentValue;
            this.onReplyConversation(QmaConstant.REPLY_ALL, this.conversationList[0]);
        }

        let currTabName = AppUtils.getCurrentTab();
        this.tabName = currTabName.toUpperCase();
        if (currTabName === QmaConstant.inquiryStatusEnum.PENDINGAPPROVAL)
            this.disableNLP = true;

        // C153176-4229 : Push Contact as recipients
        if (changes.pushContact && changes.pushContact.currentValue) {
            this.singleContact = changes.pushContact.currentValue;
        }
    }

    /**
     * Method to Check if selected inquiry is different than currently redered inquiry.
     *
     * @param selectedMail
     * @param assignedGroup
     */
    private checkIfInquiryChanged(selectedMail: any, assignedGroup: any): boolean {
        let currInqId = selectedMail.currentValue.inquiry ? selectedMail.currentValue.inquiry.id : undefined;
        let prevInqId = selectedMail.previousValue.inquiry ? selectedMail.previousValue.inquiry.id : undefined;
        return ((currInqId === prevInqId && assignedGroup.currentValue !== assignedGroup.previousValue) || (currInqId !== prevInqId));
    }

    /**
     * Method to check if websocket update recieved on same inquiry.
     * @param changes
     */
    private checkIfSameInquiryId(changes: SimpleChanges): boolean {
        return changes && changes.selectedRowData && changes.selectedRowData.previousValue && changes.selectedRowData.currentValue
            && changes.selectedRowData.previousValue[0] && changes.selectedRowData.previousValue[0].workflows
            && changes.selectedRowData.currentValue[0] && changes.selectedRowData.currentValue[0].workflows
            && changes.selectedRowData.previousValue[0]._id === changes.selectedRowData.currentValue[0]._id
            && changes.selectedRowData.currentValue[0].workflows[0].assignedGroupId === changes.selectedRowData.previousValue[0].workflows[0].assignedGroupId;
    }

    /**
     * Method to check if the selected inquiry is different than currently rendered inquiry.
     *
     * @param currSelectedMail
     * @param prevSelectedMail
     * @param currAssignedGroup
     * @param prevAssignedGroup
     */
    private checkIfInquiryChangedV1(currSelectedMail: any, prevSelectedMail: any, currAssignedGroup: any, prevAssignedGroup: any): boolean {
        let currInqId = currSelectedMail && currSelectedMail.inquiry ? currSelectedMail.inquiry.id : undefined;
        let prevInqId = prevSelectedMail && prevSelectedMail.inquiry ? prevSelectedMail.inquiry.id : undefined;
        return ((currInqId === prevInqId && currAssignedGroup !== prevAssignedGroup) || (currInqId !== prevInqId));
    }

    /**
     * Method to check if websocket update recieved on same inquiry.
     *
     * @param currSelectedRowData
     * @param prevSelectedRowData
     */
    private checkIfSameInquiryIdV1(currSelectedRowData: any, prevSelectedRowData: any): boolean {
        return prevSelectedRowData && currSelectedRowData && prevSelectedRowData[0].workflows && currSelectedRowData[0].workflows
            && prevSelectedRowData[0]._id === currSelectedRowData[0]._id
            && currSelectedRowData[0].workflows[0].assignedGroupId === prevSelectedRowData[0].workflows[0].assignedGroupId;
    }

    /**
     * C170665-673 | Changing of the data input for mail-detail-view.
     * Method to populate or set variables related to inquiry to component inputs.
     *
     * @param selectInqDetails
     */
    private setInquiryDetailsAttributes(selectInqDetails: any) {
        this.selectedMail = selectInqDetails.selectedMail;
        this.assignedGroup = selectInqDetails.assignedGroup;
        this.selectedConvId = selectInqDetails.selectedConvId;
        this.selectedInqId = selectInqDetails.selectedInqId;
        this.inquiryWorkflow = selectInqDetails.inquiryWorkflow;
        this.inquirySubject = selectInqDetails.inquirySubject;
        this.inquiryReportedConvCount = selectInqDetails.inquiryReportedConvCount;
        this.selectedMailRequest = selectInqDetails.selectedMailRequest;
        this.inboxViewName = selectInqDetails.inboxViewName;
        this.inboxViewMode = selectInqDetails.inboxViewMode;
        this.selectedRowData = selectInqDetails.selectedRowData;
        this.parentComponent = selectInqDetails.parentComponent;
        // set conversation history flag as its needed to disable pagination for conv history grid
        if (this.selInquiryDetails && this.htmlPaginationEnabled && selectInqDetails.isFromCoversationHistoryGrid) {
            this.isFromCoversationHistoryGrid = selectInqDetails.isFromCoversationHistoryGrid;
        }
    }

    /**
     * Method to check if the same inquiry is opened in new tab.
     *
     * @param selectedMail
     * @param assignedGroup
     * @param inquirySubject
     * @param totalConversations
     */
    private isSameInquiryInTab(selectedMail: any, assignedGroup: any, inquirySubject: any, totalConversations: any): boolean {
        return ((selectedMail && selectedMail.previousValue && assignedGroup && assignedGroup.previousValue) ||
            (inquirySubject && inquirySubject.previousValue && totalConversations && totalConversations.previousValue));
    }

    sanetize(value: any, type?: string): SafeHtml {
        let output = value;
        if (type === 'HTML') {
            output = this.sanatized.sanitize(SecurityContext.HTML, value);
        } else if (type === 'SCRIPT') {
            output = this.sanatized.sanitize(SecurityContext.SCRIPT, value);
        } else if (type === 'STYLE') {
            output = this.sanatized.sanitize(SecurityContext.STYLE, value);
        } else {
            output = this.sanatized.bypassSecurityTrustHtml(value);
        }
        return output;
    }

    /**
     * Method to display Nomination confirmbox.
     * @param selectedMail - Selected Inquiry
     */
    handleNominationConfirm(): void {
        this.rejectReason = false;
        let sRow = this.selectedRowData ? this.selectedRowData[0] : null;
        let tabName = AppUtils.getCurrentTab().toUpperCase();

        this.displayNominationConfirmBox = false;
        // C153176-5757 Websocket issue- Nominate ownership
        if (sRow && sRow.workflows.find(wf => wf.assignedGroupName === sRow.openGroups) && sRow.workflows.find(wf => wf.assignedGroupName === sRow.openGroups).direction
            && sRow.workflows.find(wf => wf.assignedGroupName === sRow.openGroups).direction === "NOMINATE_OWNERSHIP") {
            this.displayNominationConfirmBox = true;
        }

        if (this.displayNominationConfirmBox && tabName == "PENDING APPROVAL") {
            let grpFrm = this.loggedInUser.allActiveGroups.find(obj => obj.value == sRow.workflows[0].nominatedBy[0]);  //C153176-5758:group name displays the Nominee instead of the Nominator;
            if (sRow.workflows[0]) {
                this.nominationData = {
                    reason: sRow.workflows[0].nominatingReason,
                    groupFrom: grpFrm ? grpFrm.text : "NA",
                    user: sRow.workflows[0].modBy
                }
            }
        }
    }

    /**
     * Method to display Resolve.
     * @param selectedRowData - Selected Inquiry
     */
    markResolved(): void {
        this.contextMenu._getResolveInquiry(this.selectedRowData, this.resolveInqComponent);
        PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "InquiryView", "Resolve from top of inquiry", "Click", "Resolve from top of inquiry", 0);
    }

    /**
     * Method to populate the Conversation Inquiry View.
     * @param selectedMail - Selected Inquiry
     */
    getConversationInquiryView(selectedMail: any, isAllConvContent: boolean = false): void {
        if (!selectedMail) {
            return;
        }

        // C153176-6119 | No update of inquiry on Expansion of conversations.
        if (!isAllConvContent) {
            this.inquiry = selectedMail.inquiry;
            // C153176-4660: if inquiry is not found in 'selectedMail', get it from request.
            if (!this.inquiry && this.selectedMailRequest) {
                this.inquiry = this.selectedMailRequest.inquiry;
            }
        }

        // C153176-5319 : Need to disable NLP, CLC and Reply Actions
        let sRow = this.selectedRowData ? this.selectedRowData[0] : null;
        this.disableNLP = false;
        if (sRow && sRow.direction) {
            this.disableNLP = ["PENDINGAPPROVAL", "PND_REAGE", "NOMINATE_OWNERSHIP"].includes(sRow.direction);
        }

        if (this.selectedMailRequest) {
            this.allContentLoaded = this.selectedMailRequest.isAllConvContent;
        }
        console.debug('mailDetailView.allContentLoaded = ', this.allContentLoaded);

        const inquiryId = this.inquiry.id;
        const groupId = this.inquiry.workflows[0].assignedGroupId;
        this.audits = this.inquiry.workflowAudit;
        this.notes = this.inquiry.userNotes;
        this.taskizeInquiryId = this.inquiry.taskizeInquiryId ? this.inquiry.taskizeInquiryId : ''
        if (this.taskizeInquiryId != '') {
            this.taskizeService.setIsTaskizeInquiry(true)
        } else {
            this.taskizeService.setIsTaskizeInquiry(false)
        }

        // C153176-4594 #5: CLC Suggestion icon
        this.clcSuggestionAvailable = selectedMail.clcSuggestionAvailable;
        this.clcSuggestionRecords = selectedMail.clcSuggestionRecords;
        this.autoAssignmentAvailable = selectedMail.autoAssignmentAvailable;

        this.totalConversations = selectedMail.conversationList.length;
        const hasNominatedOwnership = this.inquiry?.workflows[0]?.hasNominatedOwnership;
        this.isValidGroupForIntent = (selectedMail.inquiry?.workflows[0]?.intentSuggestionName && selectedMail.inquiry?.workflows[0]?.intentSuggestionName != "") ? true : false
        if (this.totalConversations === 1) {
            // C153176-4660: if there is only one conversation, its content already loaded, set the flag to true
            this.allContentLoaded = true;
            this.conversationList.push(this.getConversationRecipients(selectedMail.conversationList[0], true, selectedMail.suggestionAvailable, selectedMail.suggestionStatus));
            // C153176-4669: mark the inquiry as READ if there is only ONE conversation and opened at this view.
            if (!this.selectedConvId) { // C153176-5120: do not mark inquiry as read in case of loading a conversation's details
                this.markInquiryAsRead();
                //TODO:Performance :: Remove below emit as function onInqUnread() contains no code.
                //this.onInqUnread.emit(this.inquiry);
            }
            // Pass attachments to accumulate all the attachments
            this.getAttachmentList(selectedMail.conversationList[0], groupId, hasNominatedOwnership);

            // Pass attachment list to parent
            this.attachmentEvent.emit(this.attachments);
        } else {
            this.selectedMail.conversationList.forEach((conversation, index) => {
                if (index == 0) {
                    this.detailedConvList.push(this.getConversationRecipients(conversation, true, selectedMail.suggestionAvailable, selectedMail.suggestionStatus, false, isAllConvContent));

                    // Pass attachments to accumulate all the attachments
                    this.getAttachmentList(conversation, groupId, hasNominatedOwnership);
                } else {
                    this.getInquiryConversationById(conversation, groupId, hasNominatedOwnership, isAllConvContent);
                }
            });
        }

        // Disable for 22nd November release. TODO: Need to enable after 22nd Release
        if (!environment.production) {
            this.inquiryIcon = this.inquiry.workflows[0].inquirySource == "Email";
        }

        // on change of selection Inline Reply should hide
        if (!this.isInquiryChanged)
            this.visibleQuickReply = false;

        //TODO:Performance :: Instead of pass the notes and audit from mail detai, it should be passed from inbox component.
        // Pass the audits and notes to parent
        this.notesEvent.emit(this.notes);
        this.auditsEvent.emit(this.audits);
        // in case of pagination enabled dont expand all conv
        // for pagination falg off its existing behaviour
        // [C170665-3394] QMA - Slowness for inquiry with large contents
        if (!this.htmlPaginationEnabled) {
            if (this.inboxViewMode === 'tab' && !this.isExpanded) {
                // force expanding the view if multiple conversations are present
                setTimeout(() => {
                    this.changeMessageView();
                }, 100);
            }
        }

    }

    /**
     * Method to populate the Conversation Inquiry View.
     * @param selectedMail - Selected Inquiry
     */
    getConversationInquiryViewWithServerSidePagination(selectedMail: any, isAllConvContent: boolean = false): void {
        if (!selectedMail) {
            return;
        }

        // C153176-6119 | No update of inquiry on Expansion of conversations.
        if (!isAllConvContent) {
            this.inquiry = selectedMail.inquiry;
            // C153176-4660: if inquiry is not found in 'selectedMail', get it from request.
            if (!this.inquiry && this.selectedMailRequest) {
                this.inquiry = this.selectedMailRequest.inquiry;
            }
        }

        // C153176-5319 : Need to disable NLP, CLC and Reply Actions
        let sRow = this.selectedRowData ? this.selectedRowData[0] : null;
        this.disableNLP = false;
        if (sRow && sRow.direction) {
            this.disableNLP = ["PENDINGAPPROVAL", "PND_REAGE", "NOMINATE_OWNERSHIP"].includes(sRow.direction);
        }

        if (this.selectedMailRequest) {
            this.allContentLoaded = this.selectedMailRequest.isAllConvContent;
        }
        console.debug('mailDetailView.allContentLoaded = ', this.allContentLoaded);

        const inquiryId = this.inquiry.id;
        const groupId = this.inquiry.workflows[0].assignedGroupId;
        this.audits = this.inquiry.workflowAudit;
        this.notes = this.inquiry.userNotes;

        // C153176-4594 #5: CLC Suggestion icon
        this.clcSuggestionAvailable = selectedMail.clcSuggestionAvailable;
        this.clcSuggestionRecords = selectedMail.clcSuggestionRecords;
        this.autoAssignmentAvailable = selectedMail.autoAssignmentAvailable;

        this.totalConversations = selectedMail.conversationList.length;
        const hasNominatedOwnership = this.inquiry?.workflows[0]?.hasNominatedOwnership;
        if (this.totalConversations === 1) {
            // C153176-4660: if there is only one conversation, its content already loaded, set the flag to true
            this.allContentLoaded = true;
            this.conversationList.push(this.getConversationRecipients(selectedMail.conversationList[0], true, selectedMail.suggestionAvailable, selectedMail.suggestionStatus, true));
            // C153176-4669: mark the inquiry as READ if there is only ONE conversation and opened at this view.
            if (!this.selectedConvId) { // C153176-5120: do not mark inquiry as read in case of loading a conversation's details
                this.markInquiryAsRead();
                //TODO:Performance :: Remove below emit as function onInqUnread() contains no code.
                //this.onInqUnread.emit(this.inquiry);
            }
            // Pass attachments to accumulate all the attachments
            this.getAttachmentList(selectedMail.conversationList[0], groupId, hasNominatedOwnership);

            // Pass attachment list to parent
            this.attachmentEvent.emit(this.attachments);
        } else {
            try {
                // pagination change to fetch paginated records
                // this.endIndexPageConv = this.endIndexPageConv + this.pazeSizeForConvPagination;
                for (let index = 0; index <= this.selectedMail.conversationList.length - 1; index++) {
                    if (index == 0) {
                        this.detailedConvList.push(this.getConversationRecipients(this.selectedMail.conversationList[index], true, selectedMail.suggestionAvailable, selectedMail.suggestionStatus, true));

                        // Pass attachments to accumulate all the attachments
                        this.getAttachmentList(this.selectedMail.conversationList[index], groupId, hasNominatedOwnership);
                    }
                    else {
                        this.getInquiryConversationByIdWithPagination(this.selectedMail.conversationList[index], groupId, hasNominatedOwnership);
                    }
                }
                // upate pagination count for next rendering
                this.startIndexPageConv = this.endIndexPageConv + 1;
                // this.pazeSizeForConvPagination = this.pazeSizeForConvPagination + this.pazeSizeForConvPagination;
            } catch (e) {
                console.log("exception in getConversationInquiryViewWithServerSidePagination" + e);
            }

        }

        // Disable for 22nd November release. TODO: Need to enable after 22nd Release
        if (!environment.production) {
            this.inquiryIcon = this.inquiry.workflows[0].inquirySource == "Email";
        }

        // on change of selection Inline Reply should hide
        if (!this.isInquiryChanged)
            this.visibleQuickReply = false;

        //TODO:Performance :: Instead of pass the notes and audit from mail detai, it should be passed from inbox component.
        // Pass the audits and notes to parent
        this.notesEvent.emit(this.notes);
        this.auditsEvent.emit(this.audits);

        if (this.inboxViewMode === 'tab' && !this.isExpanded) {
            // force expanding the view if multiple conversations are present
            setTimeout(() => {
                this.changeMessageView();
            }, 100);
        }
    }
    /**
     * Method to fetch the details of the conversation.
     *
     * @param conversationDetail
     */
    getInquiryConversationById(conversationDetail: any, groupId: number, hasNominatedOwnership: boolean, isAllConvContent = false): void {
        this.detailedConvList.push(this.getConversationRecipients(conversationDetail, false, conversationDetail.suggestionAvailable, conversationDetail.suggestionStatus, isAllConvContent));
        // Pass attachments to accumulate all the attachments
        this.getAttachmentList(conversationDetail, groupId, hasNominatedOwnership);
        if (this.detailedConvList.length === this.totalConversations) {
            this.detailedConvList.sort((conv1, conv2) => conv2.id - conv1.id);

            // Logic to show only selected conversation in expanded view
            if (this.selectedConvId && this.selectedInqId) {
                this.conversationList = this.detailedConvList.filter(conv => conv.id === this.selectedConvId && conv.inquiryId === this.selectedInqId);
                // null check is necessary in case the conversation id doesn't match mail detail (one of them not updated due to timing)
                if (this.conversationList && this.conversationList.length) {
                    this.conversationList[0].isExpandedView = true;
                }
            } else {
                this.conversationList = this.detailedConvList;
            }
            this.attachmentList = this.attachments;

            // Pass attachment list to parent
            this.attachmentEvent.emit(this.attachments);
        }
    }

    /**
     * Method to fetch the details of the conversation.
     *
     * @param conversationDetail
     */
    getInquiryConversationByIdWithPagination(conversationDetail: any, groupId: number, hasNominatedOwnership: boolean): void {
        try {
            this.detailedConvList.push(this.getConversationRecipients(conversationDetail, false, conversationDetail.suggestionAvailable, conversationDetail.suggestionStatus, true));
            // Pass attachments to accumulate all the attachments
            this.getAttachmentList(conversationDetail, groupId, hasNominatedOwnership);
            // if (this.detailedConvList.length === this.totalConversations) {
            if (this.detailedConvList.length === this.selectedMail.conversationList.length) {
                this.detailedConvList.sort((conv1, conv2) => conv2.id - conv1.id);

                // Logic to show only selected conversation in expanded view
                if (this.selectedConvId && this.selectedInqId) {
                    this.conversationList = this.detailedConvList.filter(conv => conv.id === this.selectedConvId && conv.inquiryId === this.selectedInqId);
                    // null check is necessary in case the conversation id doesn't match mail detail (one of them not updated due to timing)
                    if (this.conversationList && this.conversationList.length) {
                        this.conversationList[0].isExpandedView = true;
                    }
                } else {
                    this.conversationList = this.detailedConvList;
                }
                this.attachmentList = this.attachments;

                // Pass attachment list to parent
                this.attachmentEvent.emit(this.attachments);
            }
        }
        catch (e) {
            console.log("exception in getInquiryConversationByIdWithPagination" + e);
        }
    }

    /**
     * Method to get recipients (FROM, TO, CC) description and return to render in html
     *      *
     * @param type - It might FROM, TO and CC
     * @param isRecent - It is recent Conversation
     */
    getConversationRecipients(conversation: any, isRecent: boolean, suggestionAvailable: any, suggestionStatus: any, isFromConvPagination: boolean = false, isLoadAllContnts = false): any {
        let toList: string[] = [];
        let ccList: string[] = [];
        let bccList: string[] = [];
        let from = '';

        // C153176-5000: make sure displayName is populated if email id is present
        this.preProcessRecipients(conversation);

        // Recipients with external flag
        let fromRecipient: Recipient;
        let toRecipients: Recipient[] = [];
        let ccRecipients: Recipient[] = [];
        let bccRecipients: Recipient[] = [];

        // Jira : 4929 : Cancel Button not working
        if (this.isMobile) {
            conversation.modDate1 = this.updateDateFormat(conversation.modDate, 'd MMM');
            conversation.modDate2 = this.updateDateFormat(conversation.modDate, 'h:mm a');
        }

        let conversationCopy = { ...conversation };
        conversation.recipients.forEach(recipient => {
            if (recipient.toFrom === QmaConstant.FROM) {
                from = recipient.displayName;
                fromRecipient = this.getModifiedRecipient(recipient);
            }
            else if (recipient.toFrom === QmaConstant.TO) {
                toList.push(recipient.displayName);
                toRecipients.push(this.getModifiedRecipient(recipient));
            }
            else if (recipient.toFrom === QmaConstant.CC) {
                ccList.push(recipient.displayName);
                ccRecipients.push(this.getModifiedRecipient(recipient));
            }
            else if (recipient.toFrom === QmaConstant.BCC) {
                bccList.push(recipient.displayName);
                bccRecipients.push(this.getModifiedRecipient(recipient));
            }
        });

        conversationCopy.from = from;
        //To check mobile device
        if (screen.width <= QmaConstant.MOBILE_WIDTH) {
            this.mobileToList = toRecipients;
            this.showingTo = this.mobileToList.splice(0, 1);
        }

        conversationCopy.to = toList.join(", ");
        conversationCopy.cc = ccList.join(", ");
        conversationCopy.bcc = bccList.join(", ");

        // Recipients List
        conversationCopy.toRecipients = toRecipients;
        conversationCopy.ccRecipients = ccRecipients;
        conversationCopy.bccRecipients = bccRecipients;
        conversationCopy.fromRecipient = fromRecipient;

        // Formate the date according to loginuser preference
        this.guserPrefDateFormat = this.userDataService.getDefaultDateFormate();
        conversationCopy.modDate = this.dateFormatPipe.transform(conversationCopy.modDate, this.guserPrefDateFormat);

        // Add properties for attachment
        conversationCopy.attachmentCount = conversationCopy.attachments ? conversationCopy.attachments.length : 0;

        // Property for expand or collapse view
        conversationCopy.isExpandedView = isRecent;

        //Check NLP Available and status
        conversationCopy.suggestionAvailable = suggestionAvailable;
        conversationCopy.suggestionStatus = suggestionStatus;

        // if html pagination is enebled always apply element pagination on first conv
        if (this.htmlPaginationEnabled && !isFromConvPagination) {
            // pagination enebled and elements exeeds threshold
            if (this.htmlElementsPaginationService.processOrignalConversation(conversationCopy.content,
                {
                    htmlPaginationEnabled: this.htmlPaginationEnabled,
                    minConvCountForConvPagination: this.minConvCountForConvPagination,
                    pazeSizeForConvPagination: this.pazeSizeForConvPagination,
                    minElmCountForConvPagination: this.minElmCountForConvPagination,
                    elmPageSizeForConvPagination: this.elmPageSizeForConvPagination
                })) {
                return this.splitConversationContentWithPagination(conversationCopy)
            } else {
                return this.splitConversationContentWithPagination(conversationCopy);
            }
        } else {
            return this.splitConversationContent(conversationCopy);
        }


    }

    /**
     * Method to get list of attachment of each conversation and populate the attachment list
     */
    getAttachmentList(conversation: any, groupId: number, hasNominatedOwnership: boolean): void {
        if (conversation.attachments) {
            let attachmentList = conversation.attachments;
            let inquiryId = conversation.inquiryId;
            let convId = conversation.id;

            attachmentList.forEach(attachment => {
                let attmnt = attachment;
                attmnt.time = conversation.modDate;
                attmnt.inquiryId = inquiryId;
                attmnt.convId = convId;
                attmnt.imageName = "copy_icon_blue.svg";// C153176-4708	display toaster message on click
                attmnt.groupId = groupId;
                attmnt.hasNominatedOwnership = hasNominatedOwnership;
            });
            Array.prototype.push.apply(this.attachments, attachmentList);
        }
        if (this.attachments && this.attachments.length > 0) {
            this.attachments.forEach((att: any) => {
                att.showTitle = true;
                att.showMenu = false;
            });
        }

    }

    /**
     * Method to change the view of message to expanded or collapsed.
     */
    changeMessageView(): void {
        this.isExpanded = !this.isExpanded;
        // C153176-4660: if all content is loaded, sort the conv list. Otherwise, load all the content.
        if (this.allContentLoaded) {
            let conversationList = [... this.conversationList];
            conversationList.forEach((conversation, index) => {
                if (index !== 0) {
                    conversation.isExpandedView = this.isExpanded;
                }
            });

            conversationList.sort((conv1, conv2) => conv2.id - conv1.id);
            this.conversationList = conversationList;
        } else if (this.selectedMailRequest && this.selectedMail) {
            this.loadAllContent(Object.assign({}, this.selectedMailRequest), this.selectedMail.inquiry);
        } else {
            console.debug('mailDetailView, cannot load all content!');
        }
        PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "InquiryView", "Expand from top inquiry", "Click", "Expand from top inquiry", 0);
    }




    changeMessageViewWithServerSidePagination(): void {
        this.isExpanded = true;
        this.pageNum = this.pageNum + 1;
        this.selectedMailRequest.pageNum = this.pageNum;
        this.selectedMailRequest.pageSize = this.pazeSizeForConvPagination;
        this.loadAllContentWithConvServerSidePagination(Object.assign({}, this.selectedMailRequest), this.selectedMail.inquiry);
        PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "InquiryView", "Expand from top inquiry", "Click", "Expand from top inquiry", 0);
    }

    /**
     * C153176-4660: content of all the conversations
     * C153176-5352: printOrPdf - Load all the content when print or download action is performed.
     */
    loadAllContent(inquiryData, inquiry, printOrPdf: any = null) {
        // C170665-937 | Spinner not stopping issues - Wrap the logic in try catch block.
        try {
            if (!inquiryData) {
                console.log('mailDetailView, skip loading all content');
                return;
            }
            inquiryData.isAllConvContent = true;
            console.debug('mailDetailView, loading all content, request=', inquiryData);
            if (this.parentComponent) {
                this.parentComponent.showSpinner = true;
                console.debug(":SPINNER:: " + this.parentComponent.showSpinner + ":: mailDetailView.loadAllContent");
            }
            this.inboxService.getInquiryIdExtSelected(inquiryData, inquiry).subscribe(inquiryDetail => {
                //TODO:Performance :: Remove the below code as cache is not in use.
                //this.requestCacheService.updateInquiryCache(inquiry, inquiryData.isAllConvContent, inquiryDetail);
                this.selectedMail = inquiryDetail;
                this.selectedMailRequest = inquiryData;
                this.detailedConvList = [];
                this.conversationList = [];
                this.getConversationInquiryView(this.selectedMail, inquiryData.isAllConvContent);
                // sort conv list
                const conversationList = [... this.conversationList];

                // C153176-5352: Dont expand in UI while print and download.
                if (null == printOrPdf) {
                    conversationList.forEach((conversation, index) => {
                        if (index !== 0) {
                            conversation.isExpandedView = this.isExpanded;
                        }
                    });
                }
                conversationList.sort((conv1, conv2) => conv2.id - conv1.id);
                this.conversationList = conversationList;
                // turn off spinner
                if (this.parentComponent) {
                    this.parentComponent.showSpinner = false;
                    console.debug(":SPINNER:: " + this.parentComponent.showSpinner + ":: mailDetailView.loadAllContent");
                }
                // C153176-4985: for mobile version, mark the inquiry as read, since all its conversations are shown to user
                if (this.isMobile && this.parentComponent) {
                    this.markInquiryAsRead();
                    this.inboxService.notifyGridDataReady(this.parentComponent.strMailboxView, true); // C153176-5019: force updateOnly to true
                }
                // set the allContentLoaded flag to true
                this.allContentLoaded = true;
                console.debug('mailDetailView, loaded all content, request=', inquiryData);

                // C153176-5352: Print and download.
                if (printOrPdf) {
                    this.inboxService.print(printOrPdf);
                }
            },
                error => {
                    console.error('mailDetailView, error while getting response from getInquiryIdExtSelected()');
                    // C170665-937 | Spinner not stopping issues  - turn off spinner if any service exception occurs.
                    if (this.parentComponent) {
                        this.parentComponent.showSpinner = false;
                        console.debug(":SPINNER:: " + this.parentComponent.showSpinner + ":: mailDetailView.loadAllContent");
                    }
                });
        } catch (ex) {
            console.error('QMA 2.0: Exception while loading all content', ex);
            // C170665-937 | Spinner not stopping issues  - turn off spinner if any exception occurs.
            if (this.parentComponent) {
                this.parentComponent.showSpinner = false;
                console.debug(":SPINNER:: " + this.parentComponent.showSpinner + ":: mailDetailView.loadAllContent");
            }
        }
    }

    loadAllContentWithConvServerSidePagination(inquiryData, inquiry, printOrPdf: any = null) {
        // C170665-937 | Spinner not stopping issues - Wrap the logic in try catch block.
        try {
            if (!inquiryData) {
                console.log('mailDetailView, skip loading all content');
                return;
            }
            inquiryData.isAllConvContent = true;
            console.debug('mailDetailView, loading all content, request=', inquiryData);
            if (this.parentComponent) {
                this.parentComponent.showSpinner = true;
                console.debug(":SPINNER:: " + this.parentComponent.showSpinner + ":: mailDetailView.loadAllContent");
            }
            this.inboxService.getAllInquiryConversationsForServerSidePaging(inquiryData, inquiry).subscribe(inquiryDetail => {
                //TODO:Performance :: Remove the below code as cache is not in use.
                //this.requestCacheService.updateInquiryCache(inquiry, inquiryData.isAllConvContent, inquiryDetail);
                // for first page replace existing conversation list
                if (this.pageNum == 1) {
                    this.selectedMail = inquiryDetail;
                } else { // for page number 2 onwards keep adding
                    if (Array.isArray(this.selectedMail.conversationList)) {
                        this.selectedMail.conversationList = this.selectedMail.conversationList.concat(inquiryDetail.conversationList);
                    }
                }

                this.selectedMailRequest = inquiryData;
                this.detailedConvList = [];
                this.conversationList = [];
                // pagiantion related call
                this.getConversationInquiryViewWithServerSidePagination(this.selectedMail, inquiryData.isAllConvContent);
                // sort conv list
                const conversationList = [... this.conversationList];

                // C153176-5352: Dont expand in UI while print and download.
                if (null == printOrPdf) {
                    conversationList.forEach((conversation, index) => {
                        if (index !== 0) {
                            conversation.isExpandedView = this.isExpanded;
                        }
                    });
                }
                conversationList.sort((conv1, conv2) => conv2.id - conv1.id);
                this.conversationList = conversationList;
                // turn off spinner
                if (this.parentComponent) {
                    this.parentComponent.showSpinner = false;
                    console.debug(":SPINNER:: " + this.parentComponent.showSpinner + ":: mailDetailView.loadAllContent");
                }
                // C153176-4985: for mobile version, mark the inquiry as read, since all its conversations are shown to user
                if (this.isMobile && this.parentComponent) {
                    this.markInquiryAsRead();
                    this.inboxService.notifyGridDataReady(this.parentComponent.strMailboxView, true); // C153176-5019: force updateOnly to true
                }
                // set the allContentLoaded flag to true
                this.allContentLoaded = true;
                console.debug('mailDetailView, loaded all content, request=', inquiryData);

                // C153176-5352: Print and download.
                if (printOrPdf) {
                    this.inboxService.print(printOrPdf);
                }
            },
                error => {
                    console.error('mailDetailView, error while getting response from getInquiryIdExtSelected()');
                    // C170665-937 | Spinner not stopping issues  - turn off spinner if any service exception occurs.
                    if (this.parentComponent) {
                        this.parentComponent.showSpinner = false;
                        console.debug(":SPINNER:: " + this.parentComponent.showSpinner + ":: mailDetailView.loadAllContent");
                    }
                });
        } catch (ex) {
            console.error('QMA 2.0: Exception while loading all content', ex);
            // C170665-937 | Spinner not stopping issues  - turn off spinner if any exception occurs.
            if (this.parentComponent) {
                this.parentComponent.showSpinner = false;
                console.debug(":SPINNER:: " + this.parentComponent.showSpinner + ":: mailDetailView.loadAllContent");
            }
        }
    }

    /**
     * C153176-4985: mark inquiry as read
     */
    markInquiryAsRead() {
        try {
            // get inquiries of the conversation view
            let inquiries = this.selectedRowData;
            if (!inquiries && this.parentComponent) { // C153176-5308 add null check
                inquiries = this.parentComponent.selectedRowData;
            }
            if (!inquiries && this.selectedMail.inquiry) {
                inquiries = [this.selectedMail.inquiry];
            }

            // skip multi-selection case (do not mark them as read)
            if (!inquiries || 1 !== inquiries.length || !this.parentComponent) {
                return;
            }
            this.contextMenu.markInquiryAsRead([inquiries[0]], this.parentComponent.loginUserInfo, [inquiries[0]], null);
        }
        catch (e) {
            console.log(e);
            // C170665-937 | Spinner not stopping issues  - turn off spinner if any service exception occurs.
            if (this.parentComponent) {
                this.parentComponent.showSpinner = false;
                console.debug(":SPINNER:: " + this.parentComponent.showSpinner + ":: mailDetailView.markInquiryAsRead");
            }
        }
    }

    /**
     * C153176-4985: mark first conversaion as read
     */
    markFirstConversationAsRead() {
        if (!this.selectedMail || !this.selectedMail.conversationList || !this.selectedMail.conversationList.length || !this.parentComponent) {
            return;
        }
        let conv = Object.assign({}, this.selectedMail.conversationList[0]);
        // get inquiries of the conversation view
        let inquiries = this.selectedRowData;
        if (!inquiries) {
            inquiries = this.parentComponent.selectedRowData;
        }
        if (!inquiries && this.selectedMail.inquiry) {
            inquiries = [this.selectedMail.inquiry];
        }
        // copy workflows of inquiry to conversation as it is needed in markConv call.
        if (inquiries && inquiries.length) {
            conv.workflows = [...inquiries[0].workflows];
        }
        this.contextMenu.markConvHistoryAsReadUnread([conv], "Mark As Read", '', true);
    }

    /**
     * Method to expand or collapse the message trails.
     *
     * @param conversation - Coversation Message
     */
    expandOrCollapseMsg(conversation: any): void {
        conversation.messageExpanded = !conversation.messageExpanded;
    }

    /**
     * Method to split the conversation to expanded or collapsed view to handle expansion and collapse of message view.
     *
     * @param conversationCopy - Conversation Object
     */
    splitConversationContent(conversationCopy: any): any {
        // Expand and Collapse of Message text.
        // Text would be spilted on <hr tag
        let content: string = this.replaceWildCharacters(conversationCopy.content);
        if (content && conversationCopy.content) { // C153167-4660: cover both null and undefined
            let hrIdx = conversationCopy.content.indexOf('<hr style'); // it separates the mail chain. TODO: We need any unique delimiter.
            let splitIdx = hrIdx > 0 ? hrIdx : content.length

            conversationCopy.contentExpanded = content.substring(0, splitIdx).trim();
            if (splitIdx < content.length) {
                conversationCopy.contentCollapsed = content.substring(splitIdx, content.length).trim();
            }
            conversationCopy.messageExpanded = false;

            // Set Message content for collapsed converstaions
            let parsedHtml = this.parser.parseFromString(conversationCopy.content, 'text/html');
            let viewContent = parsedHtml.querySelector('body').innerText || "";
            let mailContent = viewContent;
            conversationCopy.viewContent = mailContent.length < 50 ? mailContent : mailContent.substring(0, 50) + '...';
        } else {
            conversationCopy.contentExpanded = content + "<p>&nbsp;</p>"; // C153167-4660 : Add blank to show mail body if content is blank.
        }

        // start for print change in pagination
        if (this, this.htmlPaginationEnabled && content && conversationCopy.content) {
            let hrIdx = conversationCopy.content.indexOf('<hr style'); // it separates the mail chain. TODO: We need any unique delimiter.
            let splitIdx = hrIdx > 0 ? hrIdx : content.length
            if (splitIdx < content.length) {
                conversationCopy.contentToPrint = content.substring(0, splitIdx).trim();
            } else {
                conversationCopy.contentToPrint = content
            }
        }
        // End for print change in pagination
        return conversationCopy;
    }

    public splitConversationContentWithPagination(conversationCopy: any) {
        // Expand and Collapse of Message text.
        // Text would be spilted on <hr tag
        let content: string = this.replaceWildCharacters(conversationCopy.content);
        if (content && conversationCopy.content) { // C153167-4660: cover both null and undefined
            // start for print change in pagination
            let hrIdx = conversationCopy.content.indexOf('<hr style'); // it separates the mail chain. TODO: We need any unique delimiter.
            let splitIdx = hrIdx > 0 ? hrIdx : content.length
            if (splitIdx < content.length) {
                conversationCopy.contentToPrint = content.substring(0, splitIdx).trim();
            } else {
                conversationCopy.contentToPrint = content
            }
            // End for print change in pagination
            conversationCopy.messageExpanded = false;
            // Set Message content for rendering
            conversationCopy.contentExpanded = this.htmlElementsPaginationService.getNonRenderedNHtmlElements(this.elmPageSizeForConvPagination);
            let parsedHtml = this.parser.parseFromString(conversationCopy.content, 'text/html');
            let viewContent = parsedHtml.querySelector('body').innerText || "";
            let mailContent = viewContent;
            // conversationCopy.viewContent = mailContent.length < 50 ? mailContent : mailContent.substring(0, 50) + '...';
            conversationCopy.viewContent = mailContent;
            setTimeout(() => {
                this.bindClickEventToViewMoreIcon(QmaConstant.QMA_MAIL_VIEW_MORE_ICON_ID, conversationCopy);
            }, 0);
        } else {
            conversationCopy.contentExpanded = content + "<p>&nbsp;</p>"; // C153167-4660 : Add blank to show mail body if content is blank.
        }
        return conversationCopy;
    }

    /**
     * Method to show option for conversation
     *
     * @param event - Operation
     * @param overlaypanel  - Option panel
     */
    showOptions(event, overlaypanel: OverlayPanel): void {
        overlaypanel.toggle(event);
    }

    /**
     * Method to handle the type of request made on options
     *
     * @param event - Selected option
     * @param overlaypanel - Option panel
     */
    onAction(event, overlaypanel: OverlayPanel): void {
        const type = this.options.find(option => option.name === event.target.innerText);
        const groupId = this?.inquiry?.workflows[0]?.assignedGroupId;
        const hasNominatedOwnership = this?.inquiry?.workflows[0]?.hasNominatedOwnership;
        if (QmaConstant.EMAIL === type.code) {
            // C153176-4706: call helper function 'getSubject' to retrive subject of inquiry
            this.inboxService.downloadAsEmail(this.conversationList[0].id, this.getSubject(this.inquiry), groupId, hasNominatedOwnership);
        }

        //C153176-5352 | Download as PDF with NULL content
        if (QmaConstant.PRINT === type.code || QmaConstant.PDF === type.code || QmaConstant.CSV === type.code || QmaConstant.MSG === type.code) {
            if (this.htmlPaginationEnabled) {
                if (this.allContentLoaded) {
                    this.inboxService.print(type.code);
                } else {
                    if (this.selectedMailRequest && this.selectedMail) {
                        this.loadAllContent(Object.assign({}, this.selectedMailRequest), this.selectedMail.inquiry, type.code);
                    }
                }
            } else { // normal flow without pagination
                if (this.allContentLoaded) {
                    this.inboxService.print(type.code);
                } else {
                    if (this.selectedMailRequest && this.selectedMail) {
                        this.loadAllContent(Object.assign({}, this.selectedMailRequest), this.selectedMail.inquiry, type.code);
                    }
                }
            }


        }
        overlaypanel.hide();
    }

    // C153176-4706: retrieve subject from origSubject, fallback to subject if needed
    getSubject(inq) {
        if (!inq) {
            return QmaConstant.NO_SUBJECT;
        }
        if (inq.origSubject) {
            return inq.origSubject;
        }
        if (inq.subject) {
            return inq.subject;
        }
        return QmaConstant.NO_SUBJECT;
    }

    /**
    * Method to show reply option for conversation
    *
    * @param event - Operation
    * @param overlaypanel  - Option panel
    * @param conversation - Conversation object
    */
    showReplyOptions(event, overlaypanel: OverlayPanel, conversation: any): void {
        this.replyOptions = [];
        this.convRplyOptions.forEach(option => {
            let replyOpt = { ...option };
            replyOpt.conversation = conversation;
            this.replyOptions.push(replyOpt);
        });
        overlaypanel.toggle(event);
    }

    /**
    * Method to show reply option for conversation
    *
    * @param event - Operation
    * @param overlaypanel  - Option panel
    * @param conversation - Conversation object
    */
    showDownloadOptions(event, overlaypanel: OverlayPanel, conversation: any): void {
        this.downloadOptions = [];
        this.convDownloadOptions.forEach(option => {
            let downloadOpt = { ...option };
            downloadOpt.conversation = conversation;
            this.downloadOptions.push(downloadOpt);
        });
        overlaypanel.toggle(event);
    }

     /**
    * Method to show reply option for conversation
    *
    * @param event - Operation
    * @param overlaypanel  - Option panel
    * @param conversation - Conversation object
    */
     showQuickReplyOptions(event, overlaypanel: OverlayPanel, conversation: any): void {
        this.quickReplyOptions = [];
        this.convQucikReplyOptions.forEach(option => {
            let quickReplyOpt = { ...option };
            quickReplyOpt.conversation = conversation;
            this.quickReplyOptions.push(quickReplyOpt);
        });
        overlaypanel.toggle(event);
    }


    /**
    * Method to show reply option for mobile for conversation
    *
    * @param event - Operation
    * @param overlaypanel  - Option panel
    * @param conversation - Conversation object
    */
    showReplyOptionsMobile(event, overlaypanel: OverlayPanel, conversation: any): void {
        this.replyOptions = [];
        this.convRplyOptions.forEach(option => {
            let replyOpt = { ...option };
            replyOpt.conversation = conversation;
            this.replyOptions.push(replyOpt);
        });
        overlaypanel.toggle(event);
    }
    /**
     * Method to handle the type of request made on reply options
     *
     * @param event - Selected option
     * @param overlaypanel - Option panel
     */
    onReplyAction(event, overlaypanel: OverlayPanel): void {
        const selectedReplyAction = this.replyOptions.find(option => option.name === event.target.innerText);
        if(event.target.innerText === undefined || event.target.innerText === 'Download' || event.target.innerText === 'Quick Reply'){
            //overlaypanel.show(event);
        }else if(event.target.innerText === 'Re-Age'){
            this.onReAge();
        }else if(event.target.innerText === 'Print' || event.target.innerText === 'Download As PDF' || event.target.innerText === 'Download As MSG' || event.target.innerText === 'Download As CSV'){
            this.onAction(event, overlaypanel);
        }else if(event.target.innerText === 'Open In New Window'){
            const currTabName = AppUtils.getCurrentTab();
                const tabName = currTabName.toUpperCase();

                let selector = 'mail-detail-view > div[data-view-id=\'' + tabName + '\'';

                let clone: any = document.querySelector('head').cloneNode(true);
                let printContents, popupWin;
                printContents = document.querySelector(selector).innerHTML;
                popupWin = window.open('', '_blank');
                popupWin.blur();
                window.focus();
                popupWin.document.write(`
                <!doctype html>
                <html>
                ${clone.innerHTML} 
                <body>
                <link rel="stylesheet" href="assets/css/bootstrap.min.css" crossorigin="anonymous">
                <style>
            
                html, body{
                    height: auto;
                    width: auto;
                    zoom:85%;
                }
                    .print{display: inline-block !important;}
                    .noPrint{display: none !important;}
                    .conversation-container {
                        height: auto !important;
                        width: auto !important;
                        overflow: visible !important;
                        position: relative !important;
                    }
                    .conv-info img[alt="NLP"],.conv-info .coll-expd, .conv-info .ellipsis-icon  {display: none; }
                </style>
                ${printContents}
                </body>
                </html>`
                );

           
        }else if(event.target.innerText === 'Reply with thank you' || event.target.innerText === 'Reply with will revert later'){
            const selectedReplyActionQucik = this.quickReplyOptions.find(option => option.name === event.target.innerText);
            this.onReplyConversation(selectedReplyActionQucik.id, selectedReplyActionQucik.conversation);
        }else{
            
             /* Jira :  4929 : Elipses was not working */
             if (!this.isMobile)
                    overlaypanel.hide();
            this.onReplyConversation(selectedReplyAction.id, selectedReplyAction.conversation);
        }

        
    }

    /**
     * Method to show the list of attachments available to conversation
     *
     * @param event - Operation
     * @param overlaypanel - Overlay Panel
     * @param attachmentList - Conversation Attachments list
     */
    showAttachments(event, overlaypanel: OverlayPanel, attachmentList: any): void {

        this.attachmentsOptions = [];
        this.copyAllImage = "copy_icon.svg";

        let curVal = sessionStorage.getItem('copiedattch');// C153176-4708	display toaster message on click
        let copiedAttachments = curVal !== null ? curVal.split('~') : null;

        // C153176-5494 | For copied all - To distunguish single is copied or copy all
        // copiedAtmntCount is used. If count is matched with original attachments then its a copy all.
        let copiedAtmntCount = 0;
        if (copiedAttachments) {
            let attachmentNames = [];
            let matchedAttachments = [];
            copiedAttachments.forEach(attachment => {
                if (attachment) {
                    attachmentNames.push(attachment.substring(attachment.indexOf(':') + 1, attachment.length).trim());
                }
            });
            matchedAttachments = attachmentList.filter(attachment => attachmentNames.includes(attachment.name));
            copiedAtmntCount = attachmentNames.length === matchedAttachments.length ? attachmentNames.length : 0;
        }

        attachmentList.forEach(attachment => {
            let attachInfo: attachmentOption = {
                name: attachment.name,
                id: attachment.id,
                fileInfo: attachment,
                imageName: "copy_icon.svg"// C153176-4708	display toaster message on click
            };
            // If selected for copy as an attachment. // C153176-4708	display toaster message on click
            if (curVal !== null && curVal.indexOf(attachInfo.name) > -1 && copiedAtmntCount == 0) {
                attachInfo.imageName = "copy_icon_blue.svg";
            }
            this.attachmentsOptions.push(attachInfo);
        });

        if (copiedAtmntCount > 1) {
            this.copyAllImage = "copy_icon_blue.svg";
        }
        if (overlaypanel && event) {
            overlaypanel.toggle(event);
        }

    }

    /**
    * Method to handle to download the attachment name.
    *
    * @param event - Selected option
    * @param overlaypanel - Option panel
    */
    onDownloadAction(event, overlaypanel: OverlayPanel): void {
        const selectedAttachment = this.attachmentsOptions.find(option => option.name === event.target.title);

        if (event.target.name == "copy") {

            // C153176-5490 | One last item shoud be available for attachment for attahment.
            // We need to clear the session storage on new attachment request.
            console.log("Only last item should available to paste for attachment");
            sessionStorage.removeItem("copiedattch");
            this.copyAllImage = "copy_icon.svg";

            let bAddNewAttach: boolean = false;// C153176-4708	display toaster message on click
            let attachments = selectedAttachment.fileInfo;

            this.attachmentsOptions.forEach(attachment => {
                if (attachment.name != attachments.name) {
                    attachment.imageName = 'copy_icon.svg';
                }
            });

            if (Array.isArray(attachments)) {
                if (attachments.length === 1) {
                    console.log('Only one attachment : ', attachments[0].name);
                    let curVal = sessionStorage.getItem('copiedattch');
                    if (curVal == null) {
                        curVal = '';
                        bAddNewAttach = true;
                    } else {
                        if (curVal.indexOf(attachments[0].name) == -1) {
                            bAddNewAttach = true;
                        }
                    }
                    if (bAddNewAttach == true) {
                        sessionStorage.setItem('copiedattch', curVal + '~' + this.inboxService.viewAttachment(attachments[0].id, attachments[0].name, attachments[0].convId, attachments[0].groupId, attachments[0].hasNominatedOwnership) + ':' + attachments[0].name);

                        // C153176-4648 - Show 'Copied' message on copy of an attachment.
                        this.messageService.add({ severity: 'success', key: 'qmaToast', summary: '', detail: 'Copied' });
                    }
                }
            }
            else {
                let curVal = sessionStorage.getItem('copiedattch');
                if (curVal == null) {
                    curVal = '';
                    bAddNewAttach = true;// C153176-4648 - Show 'Copied' message on copy of an attachment.
                } else {
                    if (curVal.indexOf(attachments.name) == -1) {
                        bAddNewAttach = true;
                    }
                }
                if (bAddNewAttach == true) {
                    sessionStorage.setItem('copiedattch', curVal + '~' + this.inboxService.viewAttachment(attachments.id, attachments.name, attachments.convId, attachments.groupId, attachments.hasNominatedOwnership) + ':' + attachments.name);

                    // C153176-4648 - Show 'Copied' message on copy of an attachment.
                    this.messageService.add({ severity: 'success', key: 'qmaToast', summary: '', detail: 'Copied' });
                }
            }
        } else {
            if (overlaypanel) {
                overlaypanel.hide();
            }

            //  this.attachByteArray = this.inboxService.attachBinary;******
            var filename = "";
            if (!Array.isArray(selectedAttachment.fileInfo)) {
                filename = selectedAttachment.fileInfo.name;
            }
            else
                filename = selectedAttachment.fileInfo[0].name;
            var extension = filename.split('.').pop().toLowerCase();

            var isViewed = false;

            console.log('attach file extension ' + extension);

            if ((event && event.target && event.target.classList && event.target.classList.contains('attach-name-opt') === true)
                || event.target.name === "view") {
                this.setAttachmentUrl(selectedAttachment.fileInfo);
                if (extension !== 'doc' &&
                    extension !== "xls" &&
                    extension !== 'ppt' &&
                    extension !== 'pptx' &&
                    extension !== 'zip' &&
                    extension !== 'rar' &&
                    extension !== 'wpd' &&
                    extension !== 'msg' &&
                    extension !== '7z') {
                    isViewed = this.attachViewer.displayDialog();
                } else {
                    alert('The selected file type is not supported for inline preview');
                }
            }

            if (isViewed === true) {   //view attachment
                var attchViewer = this.attachViewer;
                setTimeout(() => {    //<<<---    using ()=> syntax
                    this.attachViewer.showDialog(this.sAttachViewUrl, extension);
                }, 200);
            } else { //downlad attachment
                this.onDownloadAttachment(selectedAttachment.fileInfo);
            }
        }

    }
    onReplyConversation(replyType: string, conversation: any) {
        this.getTabNameAndEmitReply(replyType, conversation);
        if (replyType == 'ReplyAll' && this.taskizeEnabled && this.taskizeInquiryId != '') {
            this.taskizeService.checkTaskizeTokenExpiry(this.userDataService.loggedInUserInfo)
        }
        setTimeout(() => {
            this.onReplyConversationProcessing(replyType, conversation);
        }, 0);
    }

    onReAge() {
        this.reAgeComponent.selectedMenuItem = this.selectedRowData;
        this.reAgeComponent.triggerFalseClick();
    }

    /**
     * Method to perform action on reply action based on type for a conversation
     *
     * @param replyType - Reply Type
     * @param conversation - Conversation object
     */
    onReplyConversationProcessing(replyType: string, conversation: any): void {
        let strtTime = performance.now();

        let tabName = "NEW MESSAGE";
        const recipients = conversation.recipients;
        let subject = this.updateSubject(conversation.subject);
        let content = this.replaceWildCharacters(conversation.content);
        if (!content) {
            content = ''; // C153176-4660: set content to empty if it is null or undefind
            console.log('Conversation content length: ', conversation.content.length);
        }
        let urgentFlag = "N";
        let subPrefix = "RE: ";

        let toRecipient: any = [];
        let ccRecipient: any = [];
        let bccRecipient: any = [];
        let attachments = (this.isNull(conversation.attachments)) ? [] : conversation.attachments;
        const existingConvAttachements = (this.isNull(conversation.attachments)) ? [] : conversation.attachments;
        // C170665-1969 Issue- Maker checker not triggered when attachment from previous email attached during reply.
        // reset attach selected flag selected to false while reopening same message in inline reply mode
        if (existingConvAttachements && Array.isArray(existingConvAttachements)) {
            existingConvAttachements.forEach(ec => {
                ec.isSselected = false;
            });
        }
        // C153176-4459 : Flag to set if reply action is initiated from NLP acceptance.
        let nlpSuggestion: boolean = false;

        // C153176-4594 : Flag to set if reply action is initiated from CLC Suggestion acceptance.
        let clcSuggestion: boolean = false;

        let from: any = this.getRecipients(recipients, "FROM");
        //Array.prototype.push.apply(toRecipient, from);

        let replyContent: string = '';

        switch (replyType) {
            case QmaConstant.REPLY:
                tabName = QmaConstant.REPLY;
                // C153176-4682 : Add condition for 'Sent' folder, 'TO' field should be populated with same TO recipents.
                toRecipient = this.myTabName !== 'SENT' ? this.getRecipients(recipients, "FROM") : this.getRecipients(recipients, "TO");
                attachments = [];
                break;
            case QmaConstant.REPLY_ALL:
                Array.prototype.push.apply(toRecipient, this.getRecipients(recipients, "FROM"));
                Array.prototype.push.apply(toRecipient, this.getRecipients(recipients, "TO"));
                Array.prototype.push.apply(ccRecipient, this.getRecipients(recipients, "CC"));
                //bccRecipient = this.getRecipients(recipients, "BCC");
                tabName = QmaConstant.REPLY_ALL;
                attachments = [];
                break;
            case QmaConstant.REPLY_THANK_YOU:
                // C153176-5066 : Add condition for 'Sent' folder, 'TO' field should be populated with same TO recipent
                toRecipient = this.myTabName !== 'SENT' ? this.getRecipients(recipients, "FROM") : this.getRecipients(recipients, "TO");
                Array.prototype.push.apply(ccRecipient, this.getRecipients(recipients, "CC"));
                replyContent = 'Thanks for your mail';
                tabName = QmaConstant.REPLY;
                attachments = [];
                break;
            case QmaConstant.REPLY_LATER:
                // C153176-5066 : Add condition for 'Sent' folder, 'TO' field should be populated with same TO recipents.
                toRecipient = this.myTabName !== 'SENT' ? this.getRecipients(recipients, "FROM") : this.getRecipients(recipients, "TO");
                Array.prototype.push.apply(ccRecipient, this.getRecipients(recipients, "CC"));
                replyContent = 'Thanks for your mail, we will reply you later';
                tabName = QmaConstant.REPLY;
                attachments = [];
                break;
            case QmaConstant.REASSIGN:
                toRecipient = [];
                tabName = QmaConstant.REASSIGN;
                subPrefix = "FW: ";
                urgentFlag = conversation.urgentFlag; // C153176-4243 Issue 3: Urgent flag is available for forword action.
                break;
            case QmaConstant.QUICK_REPLY:
                const toList = this.quickReply.toList || [];
                const ccList = this.quickReply.ccList || [];
                toRecipient = [...toList];
                ccRecipient = [...ccList];
                replyContent = this.quickReply.templateContent;
                tabName = QmaConstant.REPLY;
                attachments = [];
                break;
            default:
                break;
        };

        // Get Unique values
        if (toRecipient.length > 0) {
            toRecipient = _.uniq(toRecipient, 'email');
        }
        if (ccRecipient.length > 0) {
            ccRecipient = _.uniq(ccRecipient, 'email');
        }
        if (bccRecipient.length > 0) {
            bccRecipient = _.uniq(bccRecipient, 'email');
        }

        // C153176-4224 : If group exculed is true,  We should not included assigned group in toRecipient.
        // C153176-5676 | Resolve- To field appear blank while replying from resolve folder due to 'Exclude group from reply all' is enabled.
        if (this.userDataService.isGroupExcluded(this.assignedGroup) && replyType === QmaConstant.REPLY_ALL) {
            toRecipient = toRecipient.filter(rec => rec.text !== this.assignedGroup);
        }

        // Message content with last mail header
        content = replyContent + this.getHeader(conversation) + content;

        // Logic to add NLP Suggested content
        if (conversation.suggestedContent) {
            content = conversation.suggestedContent + content;

            // C153176-4459 : Set the flag when it is nlp suggestion action
            nlpSuggestion = true;
        }
        subject = subPrefix + subject;

        /* To check that request are coming from CLC component */
        /* C153176-4594 | Need selected CLC trades */
        let selectedCLCTradesStr = this.clcTrades ? this.clcTrades.selectedCLCTradesStr : '';
        let selectedCLCTrades = this.clcTrades ? this.clcTrades.selectedCLCTrades : null;

        /*C153176-4594 - #5 : Add CLC Suggestion Content on reply  */
        // Logic to add CLC Suggested content
        if (conversation.clcSuggestionContent) {
            content = conversation.clcSuggestionContent + content;
            clcSuggestion = true;
        }

        // C170665-578 | Issue in sending mails; FROM field is not get populated.
        try {
            const templateData = [
                {
                    templateContent: content,
                    clcContent: selectedCLCTradesStr,
                    templateSubject: subject,
                    toList: toRecipient,
                    ccList: ccRecipient,
                    bccList: bccRecipient,
                    inquiryId: conversation.inquiryId,
                    nlpContent: nlpSuggestion // C153176-5420: Placeholder should not be there if NLP content.
                }
            ];

            // C170665-578 | Get the workflow based on assigned group.
            console.log("Assigned Group passed from Inbox View : " + this.assignedGroup + ", for Inquiry Id : " + conversation.inquiryId);
            if (this.inquiry && this.inquiry.workflows && this.inquiry.workflows.length > 0) {
                this.inquiry.workflows = [...this.inquiry.workflows.filter(wf => this.assignedGroup == wf.assignedGroupName)];
                console.log("The mail should be sent FROM : ", this.inquiry.workflows[0].assignedGroupName + ", for Inquiry Id : " + this.inquiry._id);
            }

            // Send error to console if the assigned group is different from assigned group from grid and workflow.
            if (this.assignedGroup !== this.inquiry.workflows[0].assignedGroupName) {
                console.error("The from field is blank while reply for the inquiry details : ", JSON.stringify(this.inquiry));
            }

            const recipientData = {
                from: this.inquiry.workflows[0].assignedGroupName,
                queryCount: this.inquiry.workflows[0].queryCount,
                requestType: this.inquiry.workflows[0].requestType,
                inquirySource: this.inquiry.workflows[0].inquirySource,
                processingRegion: this.inquiry.workflows[0].processingRegion,
                rootCause: this.inquiry.workflows[0].rootCause,
                tag: this.inquiry.workflows[0].tag,
                gfcName: this.inquiry.gfcName,
                gfcid: this.inquiry.gfcid,
                gfpName: this.inquiry.gpName,
                gfpid: this.inquiry.gpNum,
                skAccountNo: this.inquiry.skAccountNo,
                branch: this.inquiry.branch,
                fromWhere: 'messages',
                inquirySubStatus: this.inquiry.workflows[0].inquirySubStatus,
                prevInqSubStatus: this.inquiry.workflows[0].prevInqSubStatus
            };

            // C153176-5066 | Inquiry Action Should be 'Reply' for 'Reply with thank you', 'Reply with will revert later' and '(Quick Reply)'
            const customReplyTypes = [QmaConstant.REPLY_LATER, QmaConstant.REPLY_THANK_YOU, QmaConstant.QUICK_REPLY];
            let inquiryAction = customReplyTypes.includes(replyType) ? QmaConstant.REPLY : replyType;

            // C153176-5298 | Pass lock and lockedBy from selected inquiry.
            const replyActionObj = {
                inquiryId: conversation.inquiryId,
                inquiryAction: inquiryAction,
                suggestionConvId: conversation.id,
                userNotes: this.notes,
                templateData: templateData,
                content: content,
                recipientData: recipientData,
                urgentFlag: urgentFlag,
                attachments: attachments,
                groupId: this.inquiry.workflows[0].assignedGroupId,
                lock: this.inquiry.workflows[0].lock,
                lockedBy: this.inquiry.workflows[0].lockedBy,
                nlpSuggestion: nlpSuggestion, // C153176-4459 : Pass nlpSuggestion flag to new-message
                clcSuggestion: clcSuggestion, // C153176-4594 : Pass clcSuggestion flag to new-message
                selectedCLCTrades: selectedCLCTrades, // C153176-4594 | Need selected CLC trades
                inquiry: this.inquiry,
                existingConvAttachements: existingConvAttachements
            };

            tabName = tabName + ": " + conversation.inquiryId;

            /*  this.visibleQuickReply = true;
             this.inlineReply.emit(this.visibleQuickReply); */ // performance refactor
            /* this.quickReplyActionName = tabName; */ // performance refactor

            //TODO:Performance :: End Time.
            let endTime = performance.now();
            console.log(`QMA2.0 Performance :: Mail Detail Component - On Reply Conversation : ${Math.ceil(endTime - strtTime)} miliseconds`);

            //TODO:Performance :: Instead of passing huge amount of data to service. Can we pass inquiryId, Conversation and Assigned group
            // And in new message component we can call service that prepare a date, which we can populate to new-message. Need to check it
            // feasibilty respect to service performance
            this.newMessageService.setInquiryReplyData(replyActionObj);

            // Once reply action done, clear the trades.
            this.clcTrades = null;

        } catch (e) {
            console.error('QMA2.0 : Failed to prepare data while reply/replyAll operation due :', e);
        }
    }

    onExpandEvent(data) {
        let tabName = "NEW MESSAGE";
        tabName = data.inquiryAction;
        tabName = tabName + ": " + data.inquiryId;
        this.visibleQuickReply = false;
        //TODO:Performance :: Instead of passing huge amount of data to service. Can we pass inquiryId, Conversation and Assigned group
        // And in new message component we can call service that prepare a date, which we can populate to new-message. Need to check it
        // feasibilty respect to service performance
        this.newMessageService.setInquiryReplyData(data);
        this.tabDataService.sendTabData(tabName, 0);
        this.inlineReply.emit(false); //C153176-4605 : On expand inline reply should get closed and should not get saved in drafts
    }
    /**
     * Method to update the subject while reply or forward.
     */
    updateSubject(conversationSubject: string): string {
        let subject = conversationSubject;
        if (subject.indexOf('RE:') === 0) {
            //subject = subject.replace(/RE:/gi, '');
            subject = subject.replace('RE:', '');
        }
        if (subject.indexOf('FW:') === 0) {
            subject = subject.replace(/FW:/gi, '');
        }
        return subject.trim();
    }

    /**
     * Method to set attachments urls attached to conversation for preview
     *
     * @param attachments - Conversation Attachment list
     */

    setAttachmentUrl(attachments: any): any {
        if (Array.isArray(attachments)) {

            console.log('Only one attachment : ', attachments[0].name);
            this.sAttachViewUrl = this.inboxService.viewAttachment(attachments[0].id, attachments[0].name, attachments[0].convId, attachments[0].groupId, attachments[0].hasNominatedOwnership);
        }
        else {
            console.log('Only one attachment : ', attachments.name);
            this.sAttachViewUrl = this.inboxService.viewAttachment(attachments.id, attachments.name, attachments.convId, attachments.groupId, attachments.hasNominatedOwnership);
        }

    }
    /**
     * Method to download attachments attached to conversation
     *
     * @param attachments - Conversation Attachment list
     */

    onDownloadAttachment(attachments: any): any {
        if (Array.isArray(attachments)) {
            if (attachments.length === 1) {
                console.log('Only one attachment : ', attachments[0].name);
                this.inboxService.downloadAttachment(attachments[0].id, attachments[0].name, attachments[0].convId, attachments[0].groupId,
                    attachments[0].hasNominatedOwnership);
                this.sAttachViewUrl = this.inboxService.viewAttachment(attachments[0].id, attachments[0].name, attachments[0].convId, attachments[0].groupId, attachments[0].hasNominatedOwnership);
                PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Mailboxes ", "Download attachment - mail detailed view", "Click", "Download attachment - mail detailed view", 0);
            }
            else if (attachments.length > 1) {
                console.log('More than one attachment : ', attachments.length);
                const inquiryId = attachments[0].inquiryId;
                const convId = attachments[0].convId;
                const groupId = attachments && attachments[0] && attachments[0].groupId
                    ? attachments[0].groupId : null;
                this.inboxService.downloadAllAttachments(inquiryId, convId, groupId);
                PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Mailboxes ", "Download all attachments - mail detailed view", "Click", "Download all attachments - mail detailed view", 0);
            }
        }
        else {
            console.log('Only one attachment : ', attachments.name);
            this.inboxService.downloadAttachment(attachments.id, attachments.name, attachments.convId, attachments.groupId, attachments.hasNominatedOwnership);
            this.sAttachViewUrl = this.inboxService.viewAttachment(attachments.id, attachments.name, attachments.convId, attachments.groupId, attachments.hasNominatedOwnership);
            PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Mailboxes ", "Download attachment - mail detailed view", "Click", "Download attachment - mail detailed view", 0);
        }

    }


    /**
     * Method to get the recipient list based on type of recipient
     *
     * @param recipients - Mail Recipients
     * @param type - Type of recipient
     */
    getRecipients(recipients: any, type: string): any {
        return recipients.filter(recipient => recipient.toFrom === type).map(recipient => {

            let emailAddr = recipient.emailAddr;
            let isExternal: boolean = this.isExternalEmailInToCc(emailAddr);
            isExternal = recipient.groupId ? false : isExternal;
            // C170665-1886 Issue-- On Reply all screen on hit of Send button, unable to identify the email address for id in To field .
            // incase of groupid in to field coming as null pull email id from mygroups of user profile
            if (emailAddr === null && type === "FROM" && recipient.groupId && recipient.groupId > 0) {
                // retrieve group email id from my groups by displayName
                if (this.userDataService && this.userDataService.loggedInUserInfo && this.userDataService.loggedInUserInfo.myGroups) {
                    emailAddr = AppUtils.getGroupEmailIdFromGroupId(this.userDataService.loggedInUserInfo.myGroups, recipient.groupId);
                }
            }
            else if (emailAddr === null) {
                emailAddr = AppUtils.extractEmailAddrOrName(EMAIL_ADDR, recipient.displayName);
            }

            // C153176-4429 : Need to show alias name on reply
            let displayName = recipient.displayName;
            if (displayName.indexOf('<') > -1) {
                displayName = AppUtils.extractEmailAddrOrName(USER_NAME, recipient.displayName);
            }

            return {
                text: displayName,
                value: emailAddr,
                email: emailAddr,
                id: recipient.groupId,
                country: '',
                timeZone: '',
                active: true,
                isExternal: isExternal
            };
        });
    }

    /**
     * Method to prepare the post data required to fetch the NLP Suggestion.
     *
     * @param status : Inquiry Status
     * @param event
     * @param idx : Conversation index on which nlp is requested.
     * @param conversation : Conversation Object
     * @param overlaypanel : Overlay reference
     */
    showConvSuggestion(status, event, idx, conversation, overlaypanel: OverlayPanel) {
        const convId = conversation.id;
        let convOrder = this.totalConversations - idx;

        // C153176-5856 | NLP suggestion not available for previous conversation in QMA 2.0.
        //let groupId = this.inquiryWorkflow.groupId;
        let groupId = this.inquiry.workflows[0].assignedGroupId;
        /*  if (Array.isArray(groupId)) {
             groupId = this.inquiryWorkflow.groupId[0];
         } */
        let postdata = {
            "inquiryId": this.inquiry.id || this.inquiry._id,  // C153176-5856 | Inquiry Id becomes undefined.
            "convId": convId,
            "convOrder": convOrder,
            "groupId": groupId, // C153176-4459 - Issue 3: Inquiry Assigned groupId.
            /* "groupName": this.assignedGroup */ // C153176-4459 - Issue 3 : Inquiry Assigned group name.
            "groupName": this.inquiry.workflows[0].assignedGroupName
        };
        let inquiryStatus = this.inquiry.status;
        // Jira : 4912 : NLP Suggestion box
        //overlaypanel.toggle(event);
        this.displaySuggestionBox = true;
        this.nlpViewComponent.showConvSuggestion(postdata, status, inquiryStatus);
    }

    /**
     * Perform reply all operation when NLP suggestion is accepted.
     *
     * @param postData
     */
    replyConvSuggestions(postData) {
        const conversation = this.conversationList.find(conv => conv.id === postData.convId && conv.inquiryId === postData.inquiryId);
        conversation.suggestedContent = postData.suggestionValue;
        window.document.body.click();
        this.onReplyConversation(QmaConstant.REPLY_ALL, conversation);
    }

    showMessageGrowl(postData) {
        if (!this.isNull(postData)) {
            this.messageService.add({ severity: postData.severity, key: 'qmaToast', summary: '', detail: postData.detail })
        }
    }

    // Null value check function
    isNull(input) {
        if (input === "" || input === undefined || input == null) {
            return true;
        }
        return false;
    }

    styleObject() {
        let defaultScreen = this.inboxService.getDefautScreen();
        let height;
        if (this.inboxViewMode === "horizontal") {
            let screenHeight = window.innerHeight - (this.mailContainerHeight + 200);
            return { height: screenHeight + 'px' };
            /*  let screenHeight = defaultScreen ? 37 : 34;
             console.log("this.mailContainerHeight",this.mailContainerHeight);
             let diffSize = Number(this.mailContainerHeight[1]) - Number(this.mailContainerHeight[0]);
             if(this.mailContainerHeight !== "" && this.mailContainerHeight !== undefined && diffSize > 0) {
                 let dynamicHeight = diffSize;
                 let hight = screenHeight + dynamicHeight/2;
                 height = hight +'vh';
                 return {height: height};
             }else{
                 return {height: screenHeight+'vh'};
             }     */
            //this.assignedGroup.
        } else {
            // C153176-4565: compute height based on tab header row height
            let tabRows = this.tabDataService.checkTabHeaderRows();
            if (tabRows > 1) {
                let delta = 190 + (tabRows - 1) * 31;
                return { height: 'calc(100vh - ' + delta + 'px' };//C153176-5191: 100vh --> 120vh
            } else {
                return {};
            }
        }

    }
    /**
     * Method to get the header for Reply actions
     */
    //TODO:Performance :: Move all the function to a common service bcoz it is used in while action and new message.
    getHeader(conversation: any): string {
        const signature = this.getActionSignature();
        const disclaimer = this.getDisclaimerActionInquiry();

        // Regarding C153176-5853 Editor white space is not handled when disclaimer is added to New message
        let temp = document.createElement('div');
        temp.innerHTML = signature;
        let sign = "";
        let signatureContent = temp.textContent;
        let contentDiv = "<div id='contentDiv'></div>"
        if (signatureContent.trim().length == 0 || signatureContent == "") {
            sign = "<div id='signatureReplyMsgDiv' contenteditable = 'false' style='cursor: text;outline: none;'>" + signature + "</div>";
        }
        else {
            sign += "<p style='margin: 0px 0px;'></p><div id='signatureReplyMsgDiv' contenteditable = 'false' style='cursor: text;outline: none;'></div>" + signature + "<br>"
        }
        let header = contentDiv + "<br>" + sign + "<div id='disclaimerReplyMsgDiv' contenteditable = 'false' style='cursor: text;outline: none;'></div>" + disclaimer + "<br>"
            + "<hr style='color:rgb(204, 204, 204); border-style:solid;border-width: 0.05em;margin-bottom: 8px;'>";
        try {
            if (conversation !== null) {
                let convFrom = AppUtils.extractEmailAddrOrName(USER_NAME, conversation.from);
                let sentDate = conversation.modDate;
                if (conversation.crtBy) {
                    let externalEmailLink = '[mailto:' + conversation.crtBy + ']'
                    header += "<strong>From: </strong>" + convFrom + externalEmailLink + '<br>';
                } else {
                    header += "<strong>From: </strong>" + convFrom + '<br>';
                }
                header += "<strong>Sent: </strong>" + sentDate + '<br>';
                header += "<strong>To: </strong>" + conversation.to + '<br>';
                if (conversation.cc) {
                    header += "<strong>Cc: </strong>" + conversation.cc + '<br>';
                }
                header += "<strong>Subject: </strong>" + conversation.subject + '<br><br>';
            }
        } catch (e) {
            console.error("Error while preparing conversation meta data while reply due to : ", e);
        }
        return header;
    }

    /**
     * Method to get the action signature
     */
    getActionSignature(): string {
        const defaultSignature: UserSignature = this.userDataService.getDefaultSignatureforReply();
        return defaultSignature && defaultSignature.content ? defaultSignature.content : "";
    }

    /**
     * Method to get the disclaimer action inquiry from the selected from group
     */
    getDisclaimerActionInquiry(): string {
        return this.userDataService.getDisclaimerForReply(this.assignedGroup);
    }


    /**
     * Method to determine Internal or External. Add flag to recipient object.
     */
    getModifiedRecipient(recipient: any): Recipient {
        // C153176-4429 : Add check for emailAddr is node in recipent
        let emailId = this.isNull(recipient.emailAddr) ? recipient.displayName : recipient.emailAddr;
        if (emailId.indexOf('<') > 0) {
            let id = emailId.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
            emailId = id ? id[0] : '';
        }
        let isExternal: boolean = this.isExternalEmailInToCc(emailId);
        isExternal = recipient.groupId ? false : isExternal;
        let rec: Recipient = {
            displayName: recipient.displayName,
            emailAddr: recipient.emailAddr,
            groupId: recipient.groupId,
            toFrom: recipient.toFrom,
            userId: recipient.userId,
            isExternal: isExternal
        };
        return rec;
    }

    /**
     * Method to check if the entered email is an external.
     *
     * @param email - Entered Email Id
     */
    isExternalEmailInToCc(email: any): boolean {
        this.loggedInUser = this.userDataService.loggedInUserInfo;
        if (!AppUtils.isUndefinedOrNull(email)) {
            if (email && !AppUtils.isCitiDomainEmail(email, this.loggedInUser.citiDomainList))
                return true;
        }
        return false;
    }

    /**
     * Method to close all the popoverlays.
     */
    closePopup(panels: OverlayPanel[]): void {
        panels.forEach(panel => {
            if (panel) {
                panel.hide();
            }
        });
    }


    /**
     * Handle keyboard events
     * @param event
     */
    @HostListener('document:keydown.control.f', ['$event'])
    @HostListener('document:keydown.control.r', ['$event'])
    @HostListener('document:keydown.alt.control.r', ['$event'])
    onKeyDown(event) {
        event.stopPropagation();
        event.preventDefault();
        let replyType;
        if (event && !event.altKey && event.ctrlKey && !event.shiftKey && !event.metaKey) {
            if (event.key === 'f') {
                replyType = 'Forward';
            } else if (event.key === 'r') {
                replyType = 'Reply';
            }
        } else if (event && event.altKey && event.ctrlKey && !event.shiftKey && !event.metaKey) {
            if (event.key === 'r') {
                replyType = 'ReplyAll';
            }
        }
        //console.log('mailDetails action type', replyType);
        if (!replyType) {
            return false;
            // return true;
        }
        if (!this.conversationList || !this.conversationList.length) {
            // returning true because the event is consumed in spite of non-usage
            // return true;
            return false;
        }
        this.onReplyConversation(replyType, this.conversationList[0]);
        return false;

    }

    onQuickReplyCancel(event) {
        this.visibleQuickReply = false;
        this.inlineReply.emit(this.visibleQuickReply); // Jira 4678 - inform inboxview.component that inline reply is cancelled
    }

    /**
     * Method to hide and show recipients
     */
    onManageRecipent(): void {
        this.recipientShow = !this.recipientShow;
    }
    onManageMultipleTo(): void {
        this.multiToShow = !this.multiToShow;
    }
    ngAfterViewChecked() {
        this.onInlineReplyWidthChange();
    }
    onInlineReplyWidthChange() {
        if (this.inlineCompDiv) {
            if (this.inlineCompDiv.nativeElement.clientWidth < 750) {
                this.inlineComponentShrinked = true
            } else {
                this.inlineComponentShrinked = false;
            }
        }
    }

    /**
     * Method to remove unwanted wild characters in the conversation content.
     *
     * @param conversationContent
     */
    replaceWildCharacters(conversationContent: string): string {
        let content = conversationContent;
        if (content) {
            this.WILD_CHARACTERS.forEach(char => {
                if (content.includes(char)) {
                    if (this.isReplaceAllWildCharsEnabled) {
                        // C170665-3293 Auto generated email with comments corrupting html contents
                        content = content.replace(char + '/g', '');
                    } else {
                        content = content.replace(char, '');
                    }
                }
            });

            // C153176-5459 : Replace all the endif charcters from the content
            // content = content.replace(/(<!\[endif\]-->)/g, '');
        }
        return content;
    }

    // C153176-4457 : Get the data from New Message component and emit it ot Inbox
    onNewMessageDataChanged(data) {
        this.newMessageData = data;
        this.newMessageForm.emit(this.newMessageData);
    }

    // C153176-4605 - Fwd functionality issue fixed
    onNewMessageSend(data) {
        this.inlineReplySendFromMailDetail.emit(data);
    }

    onBackClick(evt: any) {
        // C153176-4955: check whether the attachment viewer is open and close it if it is
        if (this.attachViewer && this.attachViewer.isVisible()) {
            this.attachViewer.closeDialog();
            return;
        }
        if (this.visibleQuickReply) {
            this.visibleQuickReply = false;
        }
        else {
            this.backClick.emit();
        }
        /// C153176-4796: do not let the event trigger other actions
        evt.stopPropagation();
    }

    //C153176-4683: inline reply scheduled, emit event to reset inline reply flag in mailbox
    onInlineReplyScheduledData(data) {
        this.inlineReply.emit(data);
    }

    /**
    * Method to prepare the post data required to fetch the CLC Suggestion.
    *
    * @param idx : Conversation index on which nlp is requested.
    * @param conversation : Conversation Object
    * @param clcSuggestionRecords : CLC Suggestion trades references array
    */
    clcConvSuggestions(idx: number, conversation: any, clcSuggestionRecords: string[]): void {
        const convId = conversation.id;
        let convOrder = this.totalConversations - idx;
        let postdata = {
            "inquiryId": this.inquiry.id || this.inquiry._id, // C153176-5856 | Inquiry Id becomes undefined.
            "convId": convId,
            "convOrder": convOrder,
            "groupId": this.inquiry.workflows[0].assignedGroupId, //Inquiry Assigned groupId.
            "groupName": this.inquiry.workflows[0].assignedGroupName, // Inquiry Assigned group name.
            "tradeId": clcSuggestionRecords
        };
        this.clcSuggestionRequest = postdata;
    }

    /**
     * Perform reply all operation when CLC suggestion is accepted.
     *
     * @param postData
     */
    replyClcConvSuggestions(postData: any): void {
        const conversation = this.conversationList.find(conv => conv.id === postData.convId && conv.inquiryId === postData.inquiryId);
        conversation.clcSuggestionContent = postData.clcSuggestionContent;
        this.onReplyConversation(QmaConstant.REPLY_ALL, conversation);
    }

    /**
    * Method to perform action on ACCEPT/REJECT of Nomination
    *
    * @param _action
    */
    onNominationAction(_action: string) {
        try {
            if (_action == "accept") {
                this.rejectReason = false;
                let strMenuOption = 'Approve';
                this.contextMenu._getApproveInquiry(strMenuOption, this.selectedRowData, this.selectedRowData, this.actionPopupComponent, this.loggedInUser, this.selectedMail);
            }
            else if (_action == "reject") {
                this.rejectReason = true;
                let strMenuOption = "Reject";
                this.nominationRejectionReason = ''; //C153176-4483: Reset the Rejection Reason on every record change

            }
            // C170665-937 | Spinner not stopping issues  - turn off spinner if spinner is on.
            if (this.parentComponent && this.parentComponent.showSpinner) {
                this.parentComponent.showSpinner = false;
                console.debug(":SPINNER:: " + this.parentComponent.showSpinner + ":: mailDetailView.onNominationAction");
            }
        } catch (e) {
            console.error('QMA2.0 : Exception while accept or reject nomination', e);
            // C170665-937 | Spinner not stopping issues  - turn off spinner if any service exception occurs and spinner is on.
            if (this.parentComponent && this.parentComponent.showSpinner) {
                this.parentComponent.showSpinner = false;
                console.debug(":SPINNER:: " + this.parentComponent.showSpinner + ":: mailDetailView.onNominationAction");
            }
        }

    }

    /**
     * Method to submit Ownership nomination rejection.
     */
    onRejectionSubmit(): void {
        let strMenuOption = 'Reject';
        let _compObj = { rejInq: this.rejInqComponent };
        this.contextMenu._getRejectInquiry(strMenuOption, this.selectedRowData, this.selectedRowData, this.actionPopupComponent, this.loggedInUser, this.selectedMail);

        this.contextMenuService.httpRejectInquiry(_compObj, this.selectedRowData, { action: strMenuOption }, this.selectedRowData, this.loggedInUser, this.nominationRejectionReason).subscribe(result => {
            if (result && result.processingstatus) { //C153176-4483: Check for null response
                this.rejectReason = false;
                this.nominationRejectionReason = '';
                console.log('Nomination Ownership Rejection is Successful');
            } else {
                console.log('Fail to reject nomination ownership');
            }
        }, error => {
            // C170665-937 | Spinner not stopping issues  - turn off spinner if any service exception occurs and spinner is on.
            if (this.parentComponent && this.parentComponent.showSpinner) {
                this.parentComponent.showSpinner = false;
                console.debug(":SPINNER:: " + this.parentComponent.showSpinner + ":: mailDetailView.onRejectionSubmit");
            }
            throw new Error('Service failed to update inquiry for nomination ownership rejection');
        });
    }

    /**
     * Method is called when user start entering reject reason.
     * @param event
     */
    OnEnterReason(event: any): void {
        let text = event;
        if (text.length > 0 && !this.hasRejectReason) {
            this.hasRejectReason = true;
        }
        else if (text.length === 0 && this.hasRejectReason) {
            this.hasRejectReason = false;
        }
    }

    /**
     * Method to download attachments attached to conversation
     * // C153176-4648 - Show 'Copied' message on copy of an attachment.
     * @param attachments - Conversation Attachment list
     */
    onCopyAttachment(attachments: any): void {
        sessionStorage.removeItem('copiedattch');
        let curVal = sessionStorage.getItem('copiedattch');
        if (curVal == null || curVal.indexOf(attachments.name) == -1) {
            // Change the color of icon to show it as copied
            attachments.imageName = "copy_icon_blue.svg";
        }
        PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Mailboxes ", "Copy attachment - mail detailed view", "Click", "Copy attachment - mail detailed view", 0);
    }

    /**
     * C153176-5494 : Copy all the attachment.
     * @param attachments
     */
    onCopyAllAttachment(attachments: any): void {
        sessionStorage.removeItem('copiedattch');

        let bAddNewAttach: boolean = false;
        attachments.forEach(attach => {
            bAddNewAttach = false;
            let curVal = sessionStorage.getItem('copiedattch');
            if (curVal == null) {
                curVal = '';
                bAddNewAttach = true;
            } else {
                if (curVal.indexOf(attachments.name) == -1) {
                    bAddNewAttach = true;
                }
            }
            if (bAddNewAttach == true) {
                sessionStorage.setItem('copiedattch', curVal + '~' + this.inboxService.viewAttachment(attach.id, attach.name, attach.convId, attach.groupId, attach.hasNominatedOwnership) + ':' + attach.name);
            }
        });

        if (bAddNewAttach) {
            this.copyAllImage = "copy_icon_blue.svg";
            this.messageService.add({ severity: 'success', key: 'qmaToast', summary: '', detail: 'Copied' });
        }
        PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Mailboxes ", "Copy all attachments - mail detailed view", "Click", "Copy all attachments - mail detailed view", 0);
    }

    /**
     * C153176-5000: make sure displayName is populated if email id is present
    */
    preProcessRecipients(conversation) {
        if (!conversation || !conversation.recipients) {
            return;
        }
        conversation.recipients.forEach(recipient => {
            if (recipient) {
                if (!recipient.displayName && recipient.emailAddr) {
                    recipient.displayName = recipient.emailAddr;
                }
            }
        });
    }

    //Display contact info on hover
    async displayContactInfo(event, opContactInfo: OverlayPanel, value: any) {
        try {
            if (opContactInfo && opContactInfo.overlayVisible) {
                opContactInfo.hide();
                return false;
            }
            if (this.GDIR_DISPLAY_INFO && value && AppUtils.isUndefinedNullOrBlank(value.groupId)) {
                //for soeId
                if (value && value.userId && typeof value.userId === "string") {
                    //in case of mail coming from outside QMA, will have userId = emailAdress

                    //validate if it is soeId
                    let regEx = /^(\w{2}\d{5})$/;
                    if (regEx.test(value.userId)) {
                        this.selectedContactId = value.userId.toUpperCase();
                        opContactInfo.toggle(event);
                    } else {
                        //get soeId from email id
                        let soeId = value.emailAddr.split('@')[0];
                        //validate if it is soeId
                        let regEx = /^(\w{2}\d{5})$/;
                        if (regEx.test(soeId)) {
                            this.selectedContactId = soeId.toUpperCase();
                            opContactInfo.toggle(event);
                        }
                    }

                } else if (value && AppUtils.isUndefinedNullOrBlank(value.userId) && AppUtils.isCitiDomainEmail(value.emailAddr, this.citiDomains)) {
                    //get soeId from email id
                    let soeId = value.emailAddr.split('@')[0];
                    //validate if it is soeId
                    let regEx = /^(\w{2}\d{5})$/;
                    if (regEx.test(soeId)) {
                        this.selectedContactId = soeId.toUpperCase();
                        opContactInfo.toggle(event);
                    } else {
                        //get soeId from email
                        let auId = this.userDataService.loggedInUserInfo.globalDirectoryConfig.contactInfoConfig.auid;
                        let contactInfoEndPoint = this.userDataService.loggedInUserInfo.globalDirectoryConfig.contactInfoConfig.contactInfoEndPoint;
                        const suggestionData = await this.globalDirectoryService.getContactSuggestion(value.emailAddr.toUpperCase(), "BASIC", auId, contactInfoEndPoint).toPromise();
                        let jsonObj = this.globalDirectoryService.parseXmlToJson(suggestionData.toString());
                        console.log(jsonObj);
                        if (jsonObj && jsonObj.people && jsonObj.people.Person && jsonObj.people.Person.SoeId && jsonObj.people.Person.SoeId._text) {
                            let soeId = jsonObj.people.Person.SoeId._text;
                            this.selectedContactId = soeId.toUpperCase();
                            opContactInfo.toggle(event);
                        }
                    }
                }
            }
        } catch (e) {
            console.error('error in mailDetailVIew#displayContactInfo', e);
        }
        //this.selectedContactId = '';

    }

    // Jira : 4929 : Cancel Button not working
    updateDateFormat(date: any, format: string) {
        return moment(date).format(format);
    }
    // Jira : 4912 : NLP Suggestion box
    closeSuggestionModel(params) {
        this.displaySuggestionBox = false;
    }
    // C153176-4660: return external flag of showingTo, add null check
    isExternalShowingTo() {
        return this.showingTo && this.showingTo.length && this.showingTo[0].isExternal;
    }
    // C153176-4660: return displayName of showingTo, add null check
    getShowingToDisplayName() {
        return (this.showingTo && this.showingTo.length) ? this.showingTo[0].displayName : '';
    }

    /**
     * Method to handle no suggestion event form NLP component.
     *
     * @param event
     */
    onNoSugesstion(event: any): void {
        this.closeSuggestionModel(null);
        this.displayError = event.displayError;
        this.contentNotAvlbelMsg = event.contentNotAvlbelMsg;
    }

    /**
     * Method to get the notes added from NLP Suggestion and append with existing notes.
     *
     * @param event
     */
    onNoteAdd(event: any): any {
        // This Changes not required, as conversation gets updated with websocket update.
        // if (event.comments) {
        //     this.notes.push(event);
        //     this.notesEvent.emit(this.notes);
        // }
    }

    /**
     * Method to close the NLP no suggestion dialog box.
     *
     * @param _str
     */
    closeModal(_str: any) {
        if (_str == "error") { this.displayError = false; }
    }

    // C170665-51 | QMA Auto assignment : assign ownership without approval
    autoRoutedWorkflows: any = null;
    autoAssignInquiry: number;

    /**
     * Method to open the auto assign dialog with the response.
     *
     * @param inquiryId
     */
    openAutoAssign(inquiryId: number): void {
        try {
            this.inboxService.getAutoRoutedWorkflows({ "inquiryId": inquiryId }).subscribe(response => {
                if (response) {
                    this.autoAssignInquiry = inquiryId;
                    this.autoRoutedWorkflows = [...response["autoRoutedWorkflows"]];
                }
            }, error => {
                console.error('mailDetailView, error while getting response from getAutoRoutedWorkflows()', error);
            });
        } catch (e) {
            console.error("Exception in QMA 2 while openAutoAssign() : ", e);
        }
    }

    // reset scroll position on each refresh
    resetScrollPos(selector) {
        var divs = document.querySelectorAll(selector);
        for (var p = 0; p < divs.length; p++) {
            if (Boolean(divs[p].style.transform)) { //for IE(10) and firefox
                divs[p].style.transform = 'translate3d(0px, 0px, 0px)';
            } else { //for chrome and safari
                divs[p].style['-webkit-transform'] = 'translate3d(0px, 0px, 0px)';
            }
        }
    }
    // C170665-524 Performance Issue - While switching an inquiry in the grid
    ngAfterViewInit() {
        if (this.parentComponent && this.parentComponent.showSpinner) {
            this.parentComponent.showSpinner = false;
            console.debug(":SPINNER:: " + this.parentComponent.showSpinner + ":: mailDetailView.ngAfterViewInit");
        }
    }

    getTabNameAndEmitReply(replyType: string, conversation: any) {
        let tabName = "NEW MESSAGE";
        switch (replyType) {
            case QmaConstant.REPLY:
                tabName = QmaConstant.REPLY;
                break;
            case QmaConstant.REPLY_ALL:
                tabName = QmaConstant.REPLY_ALL;
                break;
            case QmaConstant.REPLY_THANK_YOU:
                tabName = QmaConstant.REPLY;
                break;
            case QmaConstant.REPLY_LATER:
                tabName = QmaConstant.REPLY;
                break;
            case QmaConstant.REASSIGN:
                tabName = QmaConstant.REASSIGN;
                break;
            case QmaConstant.QUICK_REPLY:
                tabName = QmaConstant.REPLY;
                break;
            default:
                break;
        };
        tabName = tabName + ": " + conversation.inquiryId;
        this.quickReplyActionName = tabName;
        this.visibleQuickReply = true;
        this.inlineReply.emit(this.visibleQuickReply);
    }
    onMouseEnterAttTitle(attachment) {
        this.attachments.forEach((att) => {
            if (attachment.id === att.id) {
                attachment.showMenu = true;
                attachment.showTitle = false;
            } else {
                att.showMenu = false;
                att.showTitle = true;
            }
        });
    }
    onMouseLeaveAttTitle(attachment) {
        this.attachments.forEach((att) => {
            if (attachment.id === att.id) {
                attachment.showMenu = false;
                attachment.showTitle = true;
            } else {
                att.showMenu = false;
                att.showTitle = true;
            }
        });
    }
    onMouseEnterAttMenu(attachment) {
        this.attachments.forEach((att) => {
            if (attachment.id === att.id) {
                att.showMenu = true;
                att.showTitle = false;
            } else {
                att.showMenu = false;
                att.showTitle = true;
            }
        });
    }
    onMouseLeaveAttMenu(attachment) {
        this.attachments.forEach((att) => {
            att.showMenu = false;
            att.showTitle = true;
        });
    }
    downloadAttachement(attachement) {
        //let event = {};


    }
    onAttachementMoreClick() {
        this.isAttachemntPanelCollapsed = !this.isAttachemntPanelCollapsed;
    }
    // getAttachementImageByExtension(attachement) {
    //     let extension = attachement && attachement.name ? attachement.name.split('.').pop() : 'unkown';
    //     let configObj = this.extensionImgIconMapping.find(i => i.extension === extension.toLowerCase());
    //     const imgUrl = configObj && configObj.imgUrl ? configObj.imgUrl : "assets/Inbox/icon_unknown_file.svg";
    //     return imgUrl;
    // }
    getAttachementImageByExtension(attachement) {
        let fileName = attachement?.name;
        // Get the file extension
        const extension = fileName.split('.').pop()?.toLowerCase();
        // Return the appropriate icon path based on the file extension
        switch (extension) {
          case 'xls':
          case 'xlsx':
            return 'assets/Inbox/fileTypeXLS.svg';
          case 'png':
            return 'assets/Inbox/fileTypePNG.svg';
          case 'jpg':
          case 'jpeg':
            return 'assets/Inbox/fileTypeJPG.svg';
          case 'zip':
            return 'assets/Inbox/fileTypeZIP.svg';
          case 'ppt':
          case 'pptx':
            return 'assets/Inbox/fileTypePPT.svg';
          case 'doc':
            return 'assets/Inbox/fileTypeDOC.svg';
          case 'docx':
            return 'assets/Inbox/fileTypeDOCX.svg';
          case 'svg':
            return 'assets/Inbox/fileTypeSVG.svg';
          case 'pdf':
            return 'assets/Inbox/fileTypePDF.svg';
          case 'html':
            return 'assets/Inbox/fileTypeHTML.svg';
          case 'csv':
            return 'assets/Inbox/fileTypeCSV.svg';
          default:
            return 'assets/Inbox/fileTypeDOC.svg'; // Default icon for other file types
        }
      }
    onCopyActionRedesigned(attachement) {
        let event = { target: { name: 'copy', title: attachement.name } };
        this.onDownloadAction(event, null);
    }

    onViewctionRedesigned(attachement) {
        let event = { target: { name: 'view', title: attachement.name } };
        this.onDownloadAction(event, null);
    }
    getAttachementCopyIconRedesigned(attachement) {

        return "assets/Inbox/" + this.attachmentsOptions.find(ao => ao.id === attachement.id).imageName;
    }
    hideModal() {
        this.showAttchementPopup = false;
    }
    getReminingAttachementCount(attachGridId, conversation) {
        let columnCount = 0;
        let remainingAttachCount = 0;
        if (this.conversationList && Array.isArray(this.conversationList)) {
            const element = document.getElementById(attachGridId);
            if (!element) {
                return;
            }
            const gridComputedStyle = window.getComputedStyle(element);
            columnCount = gridComputedStyle && gridComputedStyle.getPropertyValue("grid-template-columns")
                && gridComputedStyle.getPropertyValue("grid-template-columns").split(" ").length ?
                gridComputedStyle.getPropertyValue("grid-template-columns").split(" ").length : -1;

        }
        remainingAttachCount = conversation.attachments.length - columnCount;
        // when remaining attachement count is less than 0 it means all attachements are rendered on screen
        // set count to 0 as no more attachements to show
        // C170665-1895 Issue- Attachment count not displayed correctly on  conversation panel when the
        // conversation window is resized. Count is displayed in negative, as user resizes.
        remainingAttachCount = remainingAttachCount < 0 ? 0 : remainingAttachCount;
        return columnCount > 0 ? `+(${remainingAttachCount}) More` : "";
    }
    bindClickEventToViewMoreIcon(viewMoreIconId, conversationCopy) {
        let maildetailViewComp = this;
        $("#" + viewMoreIconId).css('cursor', 'pointer');
        $("#" + viewMoreIconId).bind("click", function () {
            conversationCopy.contentExpanded = maildetailViewComp.htmlElementsPaginationService.getNonRenderedNHtmlElements(maildetailViewComp.elmPageSizeForConvPagination);
            setTimeout(() => {
                maildetailViewComp.bindClickEventToViewMoreIcon(QmaConstant.QMA_MAIL_VIEW_MORE_ICON_ID, conversationCopy);
            }, 0);

        });
    }

    ismailboxGridAndDetailviewinSynch(detailViewInqId) {
        try {
            // if flag is disabled in DB no check
            if (!this.userDataService.loggedInUserInfo.qmaUI2Config.isEmailViewSynchCheckEnabled) {
                return true;
            }
            // before sending reply validate detailpanel onq id with grid selected inq id
            if (this.inboboxComp && this.inboboxComp.gridApi.getSelectedRows().length === 1 && detailViewInqId) {
                let selectedInqIdInGrid = this.inboboxComp.gridApi.getSelectedRows()[0]._id;
                if (detailViewInqId && detailViewInqId !== selectedInqIdInGrid) {
                    return false;
                } else if (detailViewInqId && detailViewInqId === selectedInqIdInGrid) {
                    return true;
                }
            }
            // this.ismailboxGridSelectedInqAndDetailviewinInqInSynch = true;
        } catch (ex) {
            console.log("ismailboxGridAndDetailviewinSynch:>" + ex);
        }

        return true;
    }
    checkIfOutOfSynchPopUpRequired() {
        // if flag is disabled in DB no check
        try {
            if (!this.userDataService.loggedInUserInfo.qmaUI2Config.isEmailViewSynchCheckEnabled) {
                return true;
            }
            // once pop up is closed by user dont open it again
            if (this.outOfSynchPopUpOpenedOneTime) {
                return;
            }
            if (this.inboboxComp && this.inboboxComp.gridApi && this.inboboxComp.gridApi.getSelectedRows().length === 1 && this.conversationList && this.conversationList.length > 0) {
                let selectedInqIdInGrid = this.inboboxComp.gridApi.getSelectedRows()[0]._id;
                if (this.conversationList.length > 0 && selectedInqIdInGrid !== this.conversationList[0].inquiryId) {
                    if (!this.loadingMailInProgress && this.selectedMail) {
                        this.ismailboxGridSelectedInqAndDetailviewinInqInSynch = false;
                    }
                }

            } else {
                this.ismailboxGridSelectedInqAndDetailviewinInqInSynch = true;
            }
        }
        catch (ex) {
            console.log("exception incheckIfOutOfSynchPopUpRequired:-> " + ex);
        }

    }

    hideErrorModal() {
        this.outOfSynchPopUpOpenedOneTime = true;
        this.ismailboxGridSelectedInqAndDetailviewinInqInSynch = true;
    }

    showClientIntentFeedback(conversation) {
        let postdata = this.getClientIntentPostData(conversation)
        this.displayCustomerIntent = true;
        this.cidViewComponent.showClientIntentFeedback(postdata);
    }
    closeCustomerIntentModal(data) {
        this.displayCustomerIntent = data;
    }
    cancelClientIntentFeedback() {
        this.cidViewComponent.resetClientIntentFeedbackData();
    }
    savePositiveClientIntentFeedback(conversation) {
        let postdata = this.getClientIntentPostData(conversation)
        this.cidViewComponent.showClientIntentFeedback(postdata, true);
    }
    getClientIntentPostData(conversation) {
        const convId = conversation.id;
        let groupId = this.inquiry.workflows[0].assignedGroupId;
        let postdata = {
            "inquiryId": this.inquiry.id || this.inquiry._id,
            "convId": convId,
            "groupId": groupId,
            "groupName": this.inquiry.workflows[0].assignedGroupName,
            "nlpRequestType": "I"
        };
        return postdata;
    }
}