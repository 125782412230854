<!-- C153176-4618 Responsive Design-->
<p-dialog [(visible)]="display" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
  [styleClass]="'contextMenuModal settingsModal'" [closable]="false" [dismissableMask]="true" [baseZIndex]="10000"
  [closeOnEscape]="true" [style]="{'height':'830px','max-width':'1252px', 'width': '100%'}" [resizable]="false">
  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between"
      style="margin-left: 1.7rem; margin-top: 1rem;">
      <h6 class="gfid-title" style="font-size: 1.35rem">DASHBOARD SETTINGS</h6>
      <button type="button" (click)="hideModal()" class="d-flex flex-row close qma-text"
        style="margin: 5px 33px 0px 0px;">
        <img src="assets/Inbox/close_icon_u73.svg" alt="Close">
        <h6>CLOSE</h6>
      </button>
    </div>
  </p-header>

  <div class="gfid-body" style="margin-bottom: 32px;">

    <div class="d-flex flex-row mt-3 pr-4">
      <p-tabView class="adminview dashsettingview" [scrollable]="false"
        [style]="{'height':'49.8em','width':'71em','margin-left': '30px', 'padding-top': '15px',overflow: 'hidden', 'position': 'relative'}">
        <p-tabPanel header="MAILBOX STATISTIC" class="tab-view-cls">
          <form [formGroup]="advSearchForm">
            <div style="margin-top:35px; margin-left:21px;">
              <div>
                <div style="font-size:16px; font-family:OpenSans; font-weight: normal; margin-left: 16px;">Please
                  select any four options to display in your status circles</div>
                <div class="d-flex flex-row flex-wrap py-3"
                  style="justify-content:start; margin-top:25px; margin-left:19px">
                  <div class="d-flex flex-column" style="margin-right: 130px">
                    <div class="mb-2">
                      <ins class="heading-style">MESSAGES</ins>
                    </div>
                    <div class="d-flex flex-column flex-nowrap advance-search-elem">
                      <div>
                        <app-checkbox-list #messages [name]="'groups'" [search]="false" [items]="messagesGroups"
                          [listStyle]="{'width':'22em','height':'30.3em', 'margin-top': '0.5em','font-size':'16px !important'}"
                          (onItemChecked)="getSelectedMessage($event)"></app-checkbox-list>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-column">
                    <div class="mb-2">
                      <ins class="heading-style">MY VIEWS</ins>
                    </div>
                    <div class="d-flex flex-column flex-nowrap advance-search-elem">
                      <app-checkbox-list #myViews [placeholder]="'Quick Search...'" [name]="'tag'" [items]="myViewJson"
                        [listStyle]="{'width':'22em','height':'26.5em','font-size':'16px !important'}"
                        (onItemChecked)="getMyViews($event)"></app-checkbox-list>
                    </div>
                  </div>
                </div>
                <div *ngIf="counterFlag"
                  style="font-size:16px; font-family:OpenSans; font-weight: normal; margin-left: 16px; color:#ff0000">
                  Note: Select only four option to display in your status circles.</div>
              </div>
            </div>
          </form>

        </p-tabPanel>
        <p-tabPanel header="ASSIGNED GROUPS">
          <form [formGroup]="advSearchForm" style="padding:2em">

            <div class="d-flex flex-row flex-wrap py-3" style="justify-content:start; margin-left: 50px">
              <div class="d-flex flex-column">
                <div class="mb-2">
                  <ins class="heading-style">Select Groups</ins>
                </div>
                <div class="d-flex flex-column flex-nowrap advance-search-elem">
                  <app-checkbox-list #groupname [placeholder]="'Quick Search...'" [name]="'value'" [items]="groupNames"
                    [listStyle]="{'height':'30.3em', 'width':'25em'}" checked="groupNames.binary"
                    (onItemChecked)="getSelectedGroup($event)"></app-checkbox-list>
                </div>
              </div>
            </div>
          </form>
        </p-tabPanel>
        <p-tabPanel header="Request Type & Owners">
          <div style="margin-left:50px">
            <div class="d-flex flex-row flex-nowrap align-items-end w-75 pr-2 mt-3">
              <label for="from" class="flex-grow" style="color: #002d72;font-size: 16px; font-weight: bold;">Assigned
                Group</label>
              <div class="w-75 pl-2">
                <p-dropdown [styleClass]="'prime-dd-override'" class="drop-down w-25 group-details-input"
                  [(ngModel)]="selectedGrp" [options]="selectedGroup"
                  (onChange)="displayRequestType($event)"></p-dropdown>
              </div>
            </div>
            <div>
              <div class="d-flex flex-row flex-wrap py-3" style="justify-content:start">
                <div class="d-flex flex-column" style="margin-right: 130px">
                  <div class="mb-2">
                    <ins class="heading-style">Request Type</ins>
                  </div>
                  <div class="d-flex flex-column flex-nowrap advance-search-elem">
                    <app-checkbox-list #requestTypes [placeholder]="'Quick Search...'" [name]="'request'"
                      [items]="requestTypes" [listStyle]="{'height':'258px', 'width':'22em'}"
                      (onItemChecked)="getSelectedRequestType($event)"></app-checkbox-list>
                  </div>
                </div>
                <div class="d-flex flex-column">
                  <div class="mb-2">
                    <ins class="heading-style">Owner</ins>
                  </div>
                  <div class="d-flex flex-column flex-nowrap advance-search-elem">
                    <app-checkbox-list #ownerTypes [placeholder]="'Quick Search...'" [name]="'tag'"
                      [items]="assignedOwner" [listStyle]="{'height':'258px', 'width':'22em'}"
                      (onItemChecked)="getSelectedOwner($event)"></app-checkbox-list>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="HEAT MAP">
          <div class="d-flex flex-row flex-nowrap align-items-end w-75 pr-2 mt-3" style="margin-left: 50px">
            <label for="from" class="flex-grow" style="color: #002d72;font-size: 16px; font-weight: bold;">Assigned
              Group</label>
            <div class="w-75 pl-2">
              <p-dropdown [styleClass]="'prime-dd-override'" class="drop-down w-25 group-details-input"
                [(ngModel)]="selectedGrp" [options]="selectedGroup" (onChange)="displayHeatMap($event)"></p-dropdown>
            </div>
          </div>
          <div class="d-flex flex-row flex-wrap top-adv-container py-3">
            <div class="d-flex flex-column">
              <div class="mb-2">
                <ins class="heading-style">Client Category</ins>
              </div>
              <div class="d-flex flex-column flex-nowrap advance-search-elem">
                <app-checkbox-list-input #heatmap [placeholder]="'Quick Search...'" [name]="'heatmap'"
                  [items]="heatMapJson" [listStyle]="{'height':'178px','width':'350px'}" [tags]="tagLimits"
                  (onItemChecked)="getSelectedHeat($event)" [placeholderin]="'Set Limit...'"
                  (onInputChange)="changeInputs($event)"></app-checkbox-list-input>
              </div>
            </div>
          </div>
          <div *ngIf="heatFlag"
            style="font-size:16px; font-family:OpenSans; font-weight: normal; margin-left: 16px; color:#ff0000">A
            maximum of 5 selections can be made.</div>
        </p-tabPanel>
        <p-footer style="position: absolute; width: 100% ; bottom: 0px; right: 0;">
          <div class="d-flex flex-row footer-btn-container"></div>
          <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
            <button type="submit" (click)="postDashboarSettingData()" class="btn search-btn mr-4">Submit</button>
            <button type="reset" class="btn clear-btn" (click)="resetModal()">Reset</button>
          </div>
        </p-footer>
      </p-tabView>
    </div>
  </div>
</p-dialog>
<p-dialog [(visible)]="errMsgFlag" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
  [styleClass]="'contextMenuModal successModal'" [closable]="false" [dismissableMask]="true" [baseZIndex]="10000"
  [closeOnEscape]="true" [style]="{width: '30%', 'margin-left':'150px'}">
  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between">
      <h6 class="success-title">Error Message</h6>
      <button type="button" (click)="closeErrModal()" class="d-flex flex-row close qma-text">
        <img src="assets/Inbox/close_icon_u73.svg" alt="Close">
        <h6>CLOSE</h6>
      </button>
    </div>
  </p-header>
  <div class="success-msg">{{this.errMsg}}</div>
</p-dialog>

<p-toast position="center" (onClose)="onReject()" styleClass="custom-toast" key="custom" [baseZIndex]="5000"></p-toast>