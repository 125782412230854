<div>
    <div *ngIf="searchView && showSearchFeedbackPanel" class="qma-search-panel">
        <div>
            <div style="width:5px;background-color:#00BDF2"></div>
            <span style="margin-left: 10px;">Are you happy with search results?</span>
            <img style="height: 16px;margin-left: 1.5em;margin-right: 0.2em;cursor: pointer" alt="Yes"
                id="cancel-search-topnav" src="assets/Inbox/thumbs-up.svg" (click)="submitSearchFeedback('Yes')">
            <span>
                <span style="cursor: pointer" (click)="submitSearchFeedback('Yes')">
                    Yes
                    <img style="height: 18px;margin: 1em;cursor: pointer" alt="Yes" id="cancel-search-topnav"
                        src="assets/Inbox/divider-line-search.svg">
                </span>
                <span style="cursor: pointer;" (click)="openFeedbackPopup()">
                    <span> No</span>
                    <img style="height: 18px;margin-left: 7px;margin-top: 4px;" alt="Yes" id="cancel-search-topnav"
                        src="assets/Inbox/thumbs-down.svg">
                </span>
            </span>
        </div>
        <span _ngcontent-jlg-c7="" class="clear-input" style="cursor: pointer;margin-right: 10px;">
            <img style="height: 16px;" alt="Cancel" id="cancel-search-topnav" src="assets/newMessage/cancel.svg"
                (click)="closeSearchFeedBackPanelCLick()">
        </span>
    </div>
</div>
<div class="my-container">
    <ng-template #customLoadingTemplate>
        <div class="custom-class-loader">
            <div class="spinner-container">
                <svg class="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
                    <circle cx="170" cy="170" r="160" stroke="#021e48" />
                    <circle cx="170" cy="170" r="135" stroke="#00bdf2" />
                    <circle cx="170" cy="170" r="110" stroke="#021e48" />
                    <circle cx="170" cy="170" r="85" stroke="#00bdf2" />
                </svg>
            </div>
        </div>
    </ng-template>

    <ngx-loading [show]="showSpinner" [config]="{ backdropBorderRadius: '3px' }"
        [template]="customLoadingTemplate"></ngx-loading>

    <div *ngIf="isUserDataServiceSuccess && !isMobile" class="mt-2" [ngClass]="{'container-wth-vertical':(inboxView === 'vertical' && !dashboardInlineTab && !isPanelPinned),
'container-wth-vertical-pinned':(inboxView === 'vertical' && !dashboardInlineTab && isPanelPinned),
'container-wth-horizontal':(inboxView === 'horizontal' && !dashboardInlineTab && !isPanelPinned),
'container-wth-horizontal-pinned':(inboxView === 'horizontal' && !dashboardInlineTab && isPanelPinned),
'container-wth-vertical-mailbox':(inboxView === 'vertical' && dashboardInlineTab),
'container-wth-horizontal-mailbox':(inboxView === 'horizontal' && dashboardInlineTab)}">

        <div [ngClass]="{'inbox-container':inboxView === 'vertical'}" class="mailbox-container"
            [ngStyle]="getMailboxContainerStyle()">
            <as-split unit="percent" (dragEnd)="log($event)" [direction]="splitdirection" #split="asSplit"
                [gutterSize]="5" (dragEnd)="dragEnd('percent', $event)"> <!-- [gutterSize]="30" -->
                <!-- (dragEnd)="dragEnd('percent', $event)"  -->
                <as-split-area [size]="splitAreaMailboxDefaultSize" #asSplitAreaMailbox minSize="35">
                    <!-- C153176-5191: split percentage, C153176-5251: disable splitter while in inlineReply-->
                    <!-- <div class="symphony-top-border"></div> -->
                    <div class="pl-3" style='top: -36px !important;
    position: relative;'
                        [ngClass]="{'inbox-view':inboxView === 'vertical','inbox-view-horizontal':inboxView === 'horizontal'}">
                        <div class='grid-options'>
                            <div style='    top: 20px !important;
    position: relative;'>
                                <span class="inboxHeader">{{messageInstance}}</span>
                            </div>
                            <div class="d-flex align-items-center w-30 py-2 flex-column" style='    top: 36px !important;
    position: relative; z-index: 999;'
                                [ngClass]="{'inbox-options-wide':filterIconHide,'mailbox-options-container': !filterIconHide,'horizontal-view-inbox-options': inboxView == 'horizontal','inbox-options-wide-horizontal':filterIconHideHorizontal}">
                                <div class='flex-row'>
                                    <div class="input-group d-flex py-3 px-2 justify-content-center">
                                        <input class="px-2 py-1 w-100 input-text" type="text" #myInput
                                            placeholder='Search' [(ngModel)]="filterText" (keyup)="onKey($event)" (click)="callUsage()"
                                            style='background-color: #F8F9FC'>
                                        <div class="input-group-btn searchBox">
                                            <button class="btn btn-default">
                                                <span>
                                                    <img style="width:18px;height:24px;"
                                                        src='assets/qwyn/icons/search-icon.svg' alt="Search">
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class='flex-row'>
                                    <span *ngIf="viewType !== -1"
                                        class="inbox-options-item inbox-column-chooser-option icon-separator"
                                        pTooltip="Save Columns" tooltipPosition="right"
                                        tooltipStyleClass='rightTooltip p-tooltip-text'>
                                        <img class="ima-mailbox-filter" src='assets/qwyn/icons/save.svg'
                                            alt='Kiwi standing on oval' (click)="onSaveColConfig()">
                                    </span>
                                    <span *ngIf="viewType == -1"
                                        class="inbox-options-item inbox-column-chooser-option icon-separator"
                                        pTooltip="Save Columns" tooltipPosition="right"
                                        tooltipStyleClass='rightTooltip p-tooltip-text'>
                                        <img class="ima-mailbox-filter" src='assets/qwyn/icons/save.svg'
                                            alt='Kiwi standing on oval' (click)="onSaveColConfig()">
                                    </span>


                                    <span class="inbox-options-item inbox-column-chooser-option icon-separator"
                                        pTooltip="Choose columns" tooltipPosition="right"
                                        tooltipStyleClass='rightTooltip p-tooltip-text'>
                                        <img class="ima-mailbox-filter" src='assets/qwyn/icons/column.svg'
                                            alt='Kiwi standing on oval' (click)="showPopup($event, opColumnChooser)">
                                    </span>

                                    <span class=" icon-separator" pTooltip="Secondary Data Setup"
                                        tooltipPosition="top" tooltipStyleClass='leftTooltip p-tooltip-text'>
                                        <div class="iconWithHoverSeondary" (click)="showPopupSecondaryClmns($event, opSecondaryColumnChooser)">
                                            <img class="iconWithHover" src='assets/qwyn/icons/secondary-column.svg'
                                            alt='Select Secondary Columns'
                                             >
                                             <span *ngIf="secondaryColumnEmpty" class="redDot"></span>
                                        </div>

                                    </span>

                                    <span class="inbox-options-item inbox-download-option icon-separator">
                                        <!-- Jira - 4458 - Download excel correction done -->
                                        <img class="ma-mailbox-filter" name="imgExportToExcel"
                                            src='assets/qwyn/icons/download.svg' alt='Kiwi standing on oval'
                                            (click)="onBtExport()" pTooltip="Download" tooltipPosition="right"
                                            tooltipStyleClass='rightTooltip p-tooltip-text'>
                                    </span>
                                    <span>
                                        <!-- Jira - 4578 - Tooltip has been added -->
                                        <img src="assets/qwyn/icons/filter.svg" class="qma-mailbox-filter"
                                            (click)="showFilterPopup($event, filterChooser)" pTooltip="Inquiry filter"
                                            tooltipPosition="right" tooltipStyleClass='rightTooltip p-tooltip-text'
                                            alt="Filter">
                                        <span class="notify-badge">{{counter}}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div #mailBoxDesktopGridContainer [ngStyle]="styleObject()"
                            [ngClass]="{'grid-container-horizontal':(inboxView === 'horizontal' && !isDragged),
                        'grid-container-vertical':inboxView === 'vertical','grid-container-vertical-search':inboxView === 'vertical' && searchView && showSearchFeedbackPanel}">
                            <!-- TODO:Performance :: Centralize/refactor click and selection events when we click/select any row in the grid -->
                            <ag-grid-angular #inboxGrid id="inboxGrid"
                                class="ag-theme-balham inbox-grid mailbox-grid-unique-class mailbox-grid-tyle context-menu-style-override"
                                [columnDefs]="columnDefs" [masterDetail]="true"
                                [detailCellRenderer]="detailCellRenderer" [frameworkComponents]="frameworkComponents"
                                [rowData]="rowData" (gridReady)="onGridReady($event)" [rowClassRules]="rowClassRules"
                                [rowSelection]="rowSelection" (selectionChanged)="onSelectionChanged($event)"
                                [allowContextMenuWithControlKey]="true" [getContextMenuItems]="getContextMenuItems"
                                [headerHeight]="40" (rowGroupOpened)="onRowGroupOpened($event)" [enableColResize]="true"
                                (componentStateChanged)="inboxStateChanged($event)" [suppressMenuHide]="true"
                                [groupUseEntireRow]="true" [suppressPaginationPanel]="true"
                                [rowGroupPanelShow]="rowGroupPanelShow" [suppressDragLeaveHidesColumns]="true"
                                [suppressMakeColumnVisibleAfterUnGroup]="true"
                                (rowDoubleClicked)="rowDoubleClicked($event)"
                                [groupRowInnerRenderer]="groupRowInnerRenderer"
                                [groupRowRendererParams]="groupRowRendererParams" [popupParent]="popupParent"
                                (filterChanged)="filterChanged($event)" (firstDataRendered)="firstDataRendered($event)"
                                [getRowHeight]="getRowHeight" (rowDataChanged)="rowDataChanged($event)"
                                [enableCellChangeFlash]="false" [gridOptions]="gridOptions"
                                (columnResized)="onColumnResized($event)" (columnMoved)="ColumnMovedEvent($event)"
                                [suppressPropertyNamesCheck]=false (rowSelected)="onRowSelected($event)"
                                [suppressColumnVirtualisation]="true" (rowClicked)="onRowClicked($event)"
                                [modules]="modules" [embedFullWidthRows]="true" (bodyScroll)="onBodyScroll($event)"
                                [rememberGroupStateWhenNewData]="true" [getRowId]="getRowId"
                                [defaultColDef]="defaultColDef" [autoGroupColumnDef]="autoGroupColumnDef"
                                [groupMaintainOrder]="true"
                                (columnRowGroupChanged)="onColumnRowGroupChanged($event)"></ag-grid-angular>
                            <!-- C153176-5062: shortcut key up/down handling -->
                            <!-- C153176-5191 header-height: 35 -> 30 -->
                        </div>
                        <div class="d-flex justify-content-between qma-mailbox-pagination-container">
                            <!-- show ws Error msg if data proceesing fails or ws connection not establised -->
                            <div>
                                <div *ngIf="(showWebSocketProcessingFailureMsg||!isWsConnectionEstablished)">
                                    {{WS_PROCESSING_FAILURE_MSG}}
                                </div>
                            </div>
                            <div class="mr-1">
                                <span class="value" id="lbPageSize">{{lbPageSize}}</span> <span class="separator"> of
                                </span>
                                <span class="value separator" id="lbTotalRecords">{{lbTotalRecords}}</span>
                                <span class="separator separator-right"> Inquiries </span>
                                <!-- C153176-4700: refactor method-->
                                <span *ngIf="showLoadMore()"
                                    class="separator pagination-load-more fa fa-angle-double-right"
                                    (click)="onLoadMoreInquiries()" title="Load More"></span>
                            </div>
                        </div>
                    </div>
                </as-split-area>
                <as-split-area [size]="splitAreaDetailDefaultSize" #asSplitAreaDetailView minSize="30">
                    <!-- C153176-5191: split percentage-->
                    <!-- <div class="symphony-top-border"></div> -->
                    <div class="detail-view-container-with-sidebar">
                        <div [ngClass]="{'detail-view-container':inboxView === 'vertical', 'detail-view-container-horizontal':inboxView === 'horizontal'}"
                            *ngIf="!convSelectionStarted">
                            <!-- C170665-524 Performance Issue - While switching an inquiry in the grid -->
                            <!--TODO:Performance :: Create a single input element instead of passing it through multiple events-->
                            <mail-detail-view *ngIf="!isChatView && !isDraftsView && !isSymphonyView"
                                [selInquiryDetails]="selInquiryDetails" [trades]="trades" [pushContact]="pushContact"
                                [mailContainerHeight]="currentHeight"
                                [loadingMailInProgress]="loadingConversationInProgress"
                                (attachmentEvent)="onAttachment($event)" (notesEvent)="onNote($event)"
                                (auditsEvent)="onAudit($event)" (inlineReply)="inlineReplyOpen($event)"
                                (newMessageForm)="getDataFromNewMessage($event)"
                                (inlineReplySendFromMailDetail)="getDataFromNewMessageSend($event)"
                                (onInqUnread)="onInqUnread($event)" (closeMailDetail)="closeMailDetail($event)"
                                [inboxView]="inboxView" #mailDetailViewComp>
                                <!-- C153176-4910, 4660: show loading in progress of conversation view, C153176-4983: add inbox view name, C153176-5257: conversation count reported from inquiry-->
                            </mail-detail-view>
                            <app-chat-view *ngIf="isChatView==true && !isDraftsView && strMailboxView !== 'CHAT'"
                                [assignedGroup]="assignedGroup" [selectedMail]="selectedMail" [setChatText]="chatText"
                                (attachmentEvent)="onAttachment($event)" (notesEvent)="onNote($event)"
                                (auditsEvent)="onAudit($event)" (clearChatTextFromInbox)="clearChatText()"
                                [tabNameforChatView]="tabNameforChatView" [parentComponent]="thisComponent"
                                [selectedMailRequest]="inqData" #chatViewComponant>
                            </app-chat-view>
                            <app-draft-detail-view *ngIf="isDraftsView" [selectedMail]="selectedDraftMail"
                                [inboxViewMode]="inboxView" (attachmentEvent)="onAttachment($event)"
                                (notesEvent)="onNote($event)">
                            </app-draft-detail-view>
                            <app-symphony-chat-view *ngIf="isSymphonyView" [assignedGroup]="assignedGroup"
                                [setChatText]="chatText" (attachmentEvent)="onAttachment($event)"
                                (notesEvent)="onNote($event)" (auditsEvent)="onAudit($event)"
                                (clearChatTextFromInbox)="clearChatText()"
                                [tabNameforChatView]="tabNameforChatViewSymphony" [parentComponent]="thisComponent"
                                [selectedMail]="selectedMail" [selectedMailRequest]="inqData"
                                #symphonyChatViewComponant>
                            </app-symphony-chat-view>
                        </div>

                    </div>
                    <div *ngIf="convSelectionStarted && !isSymphonyView && !isChatView" class="detail-view-loading">
                        <div class="detail-view-container-with-sidebar">
                            <ng-template #customLoadingTemplate>
                                <div class="custom-class-loader-mail-detail-view">
                                    <div class="spinner-container">
                                        <svg class="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
                                            <circle cx="170" cy="170" r="160" stroke="#021e48" />
                                            <circle cx="170" cy="170" r="135" stroke="#00bdf2" />
                                            <circle cx="170" cy="170" r="110" stroke="#021e48" />
                                            <circle cx="170" cy="170" r="85" stroke="#00bdf2" />
                                        </svg>
                                    </div>
                                </div>
                            </ng-template>
                            <ngx-loading [show]="convSelectionStarted"
                                [config]="{ backdropBorderRadius: '3px',fullScreenBackdrop:false }"
                                [template]="customLoadingTemplate"></ngx-loading>
                        </div>
                    </div>
                </as-split-area>
            </as-split>
        </div>
    </div>
    <!-- // C153176-4998 - Top Contacts not appearing for New Message and reply/reply all -->
    <div
        [ngClass]="{'contact-style-top':!dashboardInlineTab,'contact-style-mailboxstat-top':dashboardInlineTab,'contact-style-top-search':searchView && showSearchFeedbackPanel,'contact-style':!isPanelPinned,'contact-style-pinned':isPanelPinned}">
        <contact-sidebar [attachment]="attachmentsList" [note]="notesList" [audit]="auditsList" [clc]="clc"
            [inboxViewMode]="inboxView" [screen]="'inbox'" [selectedInquiryId]="selectedInquiryId"
            (noteAdded)="onNotesAdded($event)" (tradeAdded)="onTradesAdded($event)"
            (contactEvent)="onPushContact($event)" [assignedGroupName]="assignedGroupName"
            [assignFromGrp]="groupNameFrom" [isTaskizeInquiry]="isTaskizeInquiry">
        </contact-sidebar>
    </div>
</div>

<div *ngIf="isUserDataServiceSuccess && isMobile">
    <div class="mobile-mailbox-grid" [id]="strMailboxView">
        <app-mobile-inbox-view [rowData]="rowData" [columnConfig]="columnConfig" (onRowClick)="rowSelected($event)"
            (onMobileViewReady)="mobileViewReady($event)"></app-mobile-inbox-view>
        <!-- C153176-4483 : To show ownership nomination in mobile view -->
        <p-dialog header="Title" [(visible)]="showConversationView" styleClass='mobile-detail-view' appendTo='body'
            [showHeader]="false" positionTop="0" (onHide)="hideConversationView()">
            <mail-detail-view *ngIf="!isDraftsView" [selectedMail]="selectedMail" [assignedGroup]="assignedGroup"
                [selectedConvId]="selectedConvId" [selectedInqId]="selectedInqId" [inquiryWorkflow]="inquiryWorkflow"
                [inboxViewMode]="inboxView" [trades]="trades" (attachmentEvent)="onAttachment($event)"
                (notesEvent)="onNote($event)" (auditsEvent)="onAudit($event)" [selectedRowData]="selectedRowData"
                (backClick)="hideConversationView()" [loadingMailInProgress]="loadingConversationInProgress"
                [parentComponent]="thisComponent" [inquiryReportedConvCount]="inquiryReportedConvCount"
                [selectedMailRequest]="inqData" [inboxViewName]="getViewType(strMailboxView)" [inboxView]="inboxView">
                <!-- C153176-4910, 4660: show loading in progress of conversation view, C153176-4985: add inbox view name, C153176-5257: inquiry-reported conversation count-->
            </mail-detail-view>
            <app-draft-detail-view *ngIf="isDraftsView" [selectedMail]="selectedDraftMail" [inboxViewMode]="inboxView"
                (attachmentEvent)="onAttachment($event)" (notesEvent)="onNote($event)"
                (backClick)="hideConversationView()">
            </app-draft-detail-view>
        </p-dialog>

    </div>
    <!-- <div class="mobile-new-msg-image-holder">
        <img [src]="assets/core/layout/nav/new_message.svg"  alt="">
    </div> -->
</div>

<p-overlayPanel #opSecondaryColumnChooser [appendTo]="'body'" [style]="{'margin-top': '0px'}"
    [styleClass]="'qma-clmnChooser-overlay'">
    <div class="column-chosser-popup-container">
        <div class="column-chooser-header">
            Choose Secondary Columns
        </div>

        <div class='columns-list'>
            <!-- <ng-scrollbar class="my-scrollbar" [invertY]="false" [trackX]="false" [compact]="false" [shown]="'native'"
                [autoUpdate]="true"> -->
            <app-checkbox-list [placeholder]="'Quick Search...'" [name]="'secondaryClmnChooser'" [search]="true"
                [items]="lstmailBoxSecondaryColumnChooser" [listStyle]="{'height':'221px','padding-left': '6px'}"
                [styleClass]="'qma-clmnChooser'" [maxSelection]="maxSelectionColumnChooser"
                [selectedValue]="SecondarycolumnChooserSelectedColumns" [enableSelectAll]="true"></app-checkbox-list>
            <!-- </ng-scrollbar> -->
            <div class="clm-chooser-btn-container">
                <button class="clm-chooser-btn clm-chooser-load-btn"
                    (click)="secondaryClmnChooserLoad($event, opSecondaryColumnChooser)">Load</button>
                <button class="clm-chooser-btn clm-chooser-btn-reset"
                    (click)="secondaryClmnresetClick($event, opSecondaryColumnChooser)">Reset</button>
            </div>
        </div>
    </div>
</p-overlayPanel>
<app-secondary-column-selection [visible]="secondaryColumnSidebar" [items]="lstmailBoxSecondaryColumnChooser"
    [maxSelection]="maxSelectionColumnChooser" [selectedValue]="SecondarycolumnChooserSelectedColumns"
    (closeSidebar)="closeSecondaryColumnSidebar()"
    (onLoadSelect)="secondaryClmnChooserLoad($event, opSecondaryColumnChooser)"
    (onResetSelect)="secondaryClmnresetClick($event, opSecondaryColumnChooser)">
</app-secondary-column-selection>
<p-overlayPanel #opColumnChooser [appendTo]="'body'" [style]="{'margin-top': '0px'}"
    [styleClass]="'qma-clmnChooser-overlay'">
    <div class="column-chosser-popup-container">
        <div class="column-chooser-header">
            Choose Columns
        </div>

        <div class='columns-list'>
            <!-- <ng-scrollbar class="my-scrollbar" [invertY]="false" [trackX]="false" [compact]="false" [shown]="'native'"
                [autoUpdate]="true"> -->
            <app-checkbox-list [placeholder]="'Quick Search...'" [name]="'clmnChooser'" [search]="true"
                [items]="lstmailBoxColumnChooser" [listStyle]="{'height':'221px','padding-left': '6px'}"
                [styleClass]="'qma-clmnChooser'" [maxSelection]="maxSelectionColumnChooser"
                [selectedValue]="columnChooserSelectedColumns" #primaryColumns
                [enableSelectAll]="true"></app-checkbox-list>
            <!--  </ng-scrollbar> -->
            <div class="clm-chooser-btn-container">
                <button class="clm-chooser-btn clm-chooser-load-btn"
                    (click)="clmnChooserLoad($event, opColumnChooser)">Load</button>
                <button class="clm-chooser-btn clm-chooser-btn-reset"
                    (click)="resetClick($event, opColumnChooser)">Reset</button>
            </div>
        </div>
    </div>
</p-overlayPanel>

<p-overlayPanel #filterChooser [appendTo]="'body'" [dismissable]="true" [style]="{'border' : '1px solid #002d72 '}"
    [styleClass]="'inbox-filter-p-override'" (onHide)="filterDialogHide(filterChooser)">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between">
            <h6 class="filter">FILTERS : {{counter}} applied</h6>
            <button type="button" (click)="hideModal(filterChooser)" class="d-flex flex-row close qma-text"
                style="border:none !important;opacity:1">
                <img src="assets/Inbox/close_icon_u73.svg" alt="Close">
                <h6>CLOSE</h6>
            </button>
        </div>
    </p-header>
    <div style="padding:20px" *ngIf="renderFilterBody">

        <!-- <div style="font-size:16px; font-family:OpenSans; font-weight: normal">Please select any four options to display in your status circles</div> -->
        <div class="d-flex flex-row flex-wrap justify-content-between top-adv-container py-3">
            <div class="d-flex flex-column" style="width:12%">
                <div class="mb-2">
                    <ins class="heading-style">Assigned Groups</ins>
                </div>
                <div class="d-flex flex-column flex-nowrap advance-search-elem">
                    <div class="d-flex justify-content-left flex-column flex-nowrap">

                        <app-checkbox-list #assignedG [placeholder]="'Quick Search...'" [name]="'groups'"
                            [items]="assignedGroups"
                            [listStyle]="{'height':'192px', 'margin-left':'0.8em', 'margin-top': '0.5em'}"
                            (onItemSelect)="getAssignedGroups($event)"></app-checkbox-list>



                    </div>
                </div>
            </div>
            <div class="d-flex flex-column" style="width:13%">
                <div class="mb-2">
                    <ins class="heading-style">Assigned Owners</ins>
                </div>
                <div class="d-flex flex-column flex-nowrap advance-search-elem">

                    <app-checkbox-list #owner [placeholder]="'Quick Search...'" [name]="'owner'"
                        [items]="groupOwnerList"
                        [listStyle]="{'height':'192px', 'margin-left':'0.8em', 'margin-top': '0.5em'}"
                        (onItemSelect)="getOwnerList($event)"></app-checkbox-list>

                </div>
            </div>
            <div class="d-flex flex-column" style="width:13%">
                <div class="mb-2">
                    <ins class="heading-style">Request Type</ins>
                </div>
                <div class="d-flex flex-column flex-nowrap advance-search-elem">
                    <app-checkbox-list #request [placeholder]="'Quick Search...'" [name]="'request'"
                        [items]="requestTypes"
                        [listStyle]="{'height':'192px', 'margin-left':'0.8em', 'margin-top': '0.5em'}"
                        (onItemSelect)="getRequestGroups($event)"></app-checkbox-list>

                </div>
            </div>
            <div class="d-flex flex-column" style="width:13%">
                <div class="mb-2">
                    <ins class="heading-style">Age-Days</ins>
                </div>
                <div class="d-flex flex-column flex-nowrap advance-search-elem">

                    <app-checkbox-list #days [search]="false" [name]="'days'" [items]="ageJson"
                        [listStyle]="{'height':'244px', 'margin-left':'0.8em', 'margin-top': '1.2em'}"
                        (onItemSelect)="getAgeDays($event)"></app-checkbox-list>

                </div>
            </div>
            <div class="d-flex flex-column" style="width:12%">
                <div class="mb-2">
                    <ins class="heading-style">Age-Hours</ins>
                </div>
                <div class="d-flex flex-column flex-nowrap advance-search-elem">

                    <app-checkbox-list #hours [search]="false" [name]="'hours'" [items]="hoursJson"
                        [listStyle]="{'height':'244px', 'margin-left':'0.8em', 'margin-top': '1.2em'}"
                        (onItemSelect)="getAgeHours($event)"></app-checkbox-list>

                </div>
            </div>
            <div class="d-flex flex-column" style="width:12%">
                <div class="mb-2">
                    <ins class="heading-style">Tags</ins>
                </div>
                <div class="d-flex flex-column flex-nowrap advance-search-elem">
                    <app-checkbox-list #tag [placeholder]="'Quick Search...'" [name]="'tag'" [items]="tags"
                        [listStyle]="{'height':'192px', 'margin-left':'0.8em', 'margin-top': '0.5em'}"
                        (onItemSelect)="getTags($event)"></app-checkbox-list>

                </div>
            </div>
            <div class="d-flex flex-column" style="width:12%">
                <div class="mb-2">
                    <ins class="heading-style">Email Flags</ins>
                </div>
                <div class="d-flex flex-column flex-nowrap advance-search-elem">
                    <app-checkbox-list #email [name]="'email'" [search]="false" [items]="emailGroups"
                        [listStyle]="{'height':'244px', 'margin-left':'0.8em', 'margin-top': '1.2em'}"
                        (onItemSelect)="getEmail($event)"></app-checkbox-list>

                </div>
            </div>
            <div class="d-flex flex-column" style="width:12%">
                <div class="mb-2">
                    <ins class="heading-style">Client Category</ins>
                </div>
                <div class="d-flex flex-column flex-nowrap advance-search-elem">
                    <div class="d-flex justify-content-left flex-column flex-nowrap">
                        <app-checkbox-list #customCategoryfltr [placeholder]="'Quick Search...'"
                            [name]="'customCategory'" [items]="customCategories"
                            [listStyle]="{'height':'192px', 'margin-left':'0.8em', 'margin-top': '0.5em'}"
                            (onItemSelect)="getcustomCategory($event)"></app-checkbox-list>
                    </div>
                </div>
            </div>

        </div>

    </div>
    <div class="d-flex flex-row flex-wrap bottom-button-holder p-3 justify-content-end">
        <button type="button" class="btn search-btn mr-5" (click)='filterInbox($event,filterChooser)'>Apply
            Filter</button>
        <button type="reset" class="btn clear-btn" (click)='clearInboxFilter($event,filterChooser)'>Clear
            Filter</button>
    </div>
</p-overlayPanel>

<app-gfid-search></app-gfid-search>
<app-link-exception></app-link-exception>
<app-group-details></app-group-details>
<app-re-age></app-re-age>
<!--<app-update-memo></app-update-memo>-->
<app-ownership></app-ownership>
<app-resolve-inquiry></app-resolve-inquiry>
<app-notify></app-notify>
<app-action-popup></app-action-popup>
<reject-inquiry></reject-inquiry>
<snooze-inquiry></snooze-inquiry>
<app-create-rule></app-create-rule>

<p-dialog [(visible)]="saveedColumnstoDBFlag" [modal]="true" [responsive]="true" [maximizable]="false"
    [appendTo]="'body'" [styleClass]="'contextMenuModal successModal ModelClose'" [closable]="true"
    [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '20%'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between">
            <h6 class="success-title">Success Confirmation</h6>
        </div>
    </p-header>
    <div class="success-msg"> Columns Saved Successfully!</div>
</p-dialog>

<!--C153176-5040 |  Confirmation for Save Column Configuration for Tags -->
<p-dialog [(visible)]="saveColumnConfirm" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
    [styleClass]="'contextMenuModal'" [closable]="false" [dismissableMask]="true" [baseZIndex]="10000"
    [closeOnEscape]="true" [style]="{'max-width':'500px','width':'50%'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1">
            <h6 class="success-title">SAVE COLUMNS FOR TAG</h6>
            <button type="button" (click)="onCancelColConfig()" class="d-flex flex-row close qma-text">
                <img src="assets/Inbox/close_icon_u73.svg" alt="Close">
            </button>
        </div>
    </p-header>
    <div class="mx-2">
        <div class="d-flex flex-row align-items-center mb-4 pb-4">
            <div class="success-msg">Same Column Changes would be applied to all the tags.</div>
        </div>
    </div>
    <p-footer>
        <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
            <button class="btn search-btn mr-4" (click)="onAcceptColConfig()">Ok</button>
            <button class="btn clear-btn" (click)="onCancelColConfig()">Cancel</button>
        </div>
    </p-footer>
</p-dialog>
<div style="display: none">
    <app-symphony-grid-view [fromInbox]="true" #symphonyGridView [sympChatViewComp]="sympChatViewComp">

    </app-symphony-grid-view>
</div>

<p-dialog [(visible)]="showFeedbackPopup" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
    [styleClass]="'smart-search-feedback-popup'" [closable]="true" [dismissableMask]="true" [baseZIndex]="10000"
    [closeOnEscape]="true" [style]="{'width':'32em'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1">
            <h6 class="gfid-title create-chat-popup-header-text"
                style="font-weight: bold;color: white;text-transform: initial;">
                Feedback</h6>
            <span
                style="    cursor: pointer;/* margin-right: 10px; */margin-bottom: 7px;display: flex;align-items: center;">
                <img style="height: 22px;" alt="Cancel" id="cancel-search-topnav"
                    src="assets/newMessage/cancelWhite.svg" (click)="closeSearchFeedPopup()">
            </span>
        </div>
    </p-header>
    <form [formGroup]="feedBackform" (ngSubmit)="submitSearchFeedback('No')" (keydown.enter)="$event.preventDefault()">
        <div style="font-size: 14px;color: #002d72;font-weight: 600;">Additional Feedback:</div>
        <div style="margin-left: 1em;color: #002d72;font-family:interstate">

            <div *ngFor="let feedback of ArrSearchAdditionalFeedback; let i=index">
                <label style="margin-bottom: 0.2rem">
                    <input type="radio" formControlName="feedback" [value]="feedback.value">
                    {{feedback.description}}
                </label>
            </div>
            <textarea id="" name="" rows="3" cols="50" formControlName="feedbackOther" placeholder="Let us know why?"
                *ngIf="feedBackform.controls.feedback.value === 'other'?true:false">
        </textarea>

        </div>

        <p-footer>

            <div class="d-flex flex-row   pb-2 pt-1 ml-3 align-items-center justify-content-center">
                <!-- jira C170665-1974 feature/C170665-1974-Symphony-chat-submit-button-change -->
                <button type="submit" class="btn save-btn mr-3"
                    style="width: 67px !important;height: 35px;padding: 0px;background: #002d72;color: white;"
                    [disabled]="feedBackform.controls.feedback.value === 'other' && feedBackform.controls.feedbackOther.value === ''">Submit</button>
                <button type="button" class="btn clear-btn" (click)="closeSearchFeedPopup()"
                    style="width: 67px !important;height: 35px;padding: 0px;">Cancel</button>
            </div>
        </p-footer>
    </form>
</p-dialog>