<div
  (click)="copyToClipboard()"
  (keydown)="onKeyPress($event)"
  pTooltip="Copy"
  tooltipPosition="left"
  tooltipStyleClass="leftTooltip p-tooltip-text"
  class="ml-auto mr-2 fs-10 id-container"
>
  <span class="id-text">ID : {{ id }}</span>
  <span class="copy-text">
    <img src="assets/Inbox/copy_icon_outline1.svg" alt="Copy icon" width="14" height="16" />
  </span>
</div>