<div #wrapper class="wrapper d-flex flex-column align-items-between" >  <!-- *ngIf="!showSpinner" -->
  <div class="chat-header w-100">
    <div class="pl-2 pr-1 pb-2 pt-1 d-flex flex-column h-100 justify-content-between position-relative">
      <div class="d-flex flex-row align-items-center justify-content-between mb-2">
        <div class="header-text">{{selectedMail?.inquiry?.subject}}</div>
        <div class="d-flex flex-row justify-content-end users-holder" style="width:100%;align-items: center">
            <div style="margin: 1%">ID: {{selectedMail?.inquiry?.id}}</div>
          <div class="conv-container mx-1">
            <img src="assets/Inbox/new-chat-icon-symphony.svg" alt="Conversation Count">
            <span style="color: #fff;">{{conversationList?.length}}</span>
          </div>
        </div>
      </div>

        <div class="d-flex flex-row align-items-center justify-content-between w-100">
            <div class="d-flex flex-row flex-wrap justify-content-start participant-names">
              <ng-template ngFor let-toRecipients [ngForOf]="allUsers" let-idx="index">
                <div *ngIf="idx < 5" class="text-right" [ngClass]="{'external': toRecipients.isExternal}"
                title="{{toRecipients.emailAddr}}">{{toRecipients.participantName}}
                <span *ngIf="idx != allUsers.length-1">;</span></div>
              </ng-template>
            </div>
           <div>
              <img [src]="'assets/Inbox/expand_all_icon_u110_symphony.svg'" #participantToggle (click)="toggleParticipant()" alt="Expand" pTooltip="All Participants" tooltipPosition="left" tooltipStyleClass='leftTooltip p-tooltip-text'>
              <div #participantOverlay>
                  <div *ngIf="displayParticipant" class="additional-users-overlay pl-2 pr-1 py-1">
                    <div class="d-flex flex-row align-items-center justify-content-center">
                      <div class="additional-users-header">Participants</div>
                      <!-- C153176-4022 | Show this if participents are more than 6 -->
                      <div *ngIf="allUsers?.length > 5"><img class="up-chevron" [src]="'assets/core/layout/nav/up_chevron.svg'" alt="Up"></div>
                    </div>
                    <div>
                      <ul class="participant-list">
                        <li *ngFor="let item of allUsers;let i = index" class=" d-flex flex-row align-items-center my-2">
                          <!-- Show Participent name on title  -->
                          <div class="participant-name" title="{{item.displayName}}">{{item.displayName}}</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
           </div>
      </div>

    </div>
  </div>
  <div #chatBody class="chat-body px-2" [ngClass]="bottomBarExpand ? 'expand': ''">
    <div>
        <div *ngIf="displayInfo" class="d-flex flex-row align-items-center justify-content-between info-msg-box pl-2 m-2">
            <div class="">
              <div class="header-info">Information</div>
              <div class="info-txt">Simplified Machine edited email message view. Click on the icon <img src="assets/newMessage/ExpandIcon.png" alt="Expand"> for full message</div>
            </div>
            <div class="close-btn" (click)="closeInfo()">
              <img src="assets/Inbox/close_icon_u73.svg" alt="Close">
            </div>
          </div>
      <!-- // C153176-3245 - check for chat bubble should come in left or right -->
     <!--  symphony change removed d-flex -->
      <div class="bubble-holder my-4  flex-row" *ngFor="let item of conversationList;let idx = index"
       > <!-- [ngClass]="(isLoggedInUser(item.modBy) == false)?'bubble-even':'bubble-odd'" -->
       <!--  symphony UI chnage need to revert -->
        <div class="image-holder d-flex flex-column align-items-center symphony-hide">
          <!-- <img class="display-pic-bubble mr-1" src="assets/core/layout/header/person.jpg">  -->
          <app-image-placeholder [recipients]="item.recipients"></app-image-placeholder>
          <label>{{item.modBy}}</label>
        </div>
        <div [attr.id]="item.id" class="speech-bubble d-flex flex-column justify-content-between">
          <div class="d-flex flex-row justify-content-between">
            <div #contentHolder class="content-holder">
                 <!-- symphony from message image  -->
                <div class="image-holder d-flex" style="width:100%;align-items: center;">
                    <!-- <img class="display-pic-bubble mr-1" src="assets/core/layout/header/person.jpg">  -->
                  <div class="img-holder-symphony">
                    <app-image-placeholder [recipients]="item.recipients" symphonyRedesign="true"></app-image-placeholder>
                  </div>
                    <!-- symphony change -->
                  <div class="mod-by">
                    <label class="mod-by-label">{{item.modBy}}</label>
                  </div>
                  <div class="footer-date"><label>{{item.modDate | date:'dd MMM hh:mm a'}}</label></div>
                </div>
              <div [innerHtml]="item.bubbleContent | safeHtml" [ngClass]="{'collapse-bubble': readMore[idx].display}" class="chat-message"></div>
              <div *ngIf="readMore[idx].display" class="full-message"><span (click)="onFullMessageClick(idx)">VIEW FULL
                  MESSAGE...</span></div>
            </div>
            <div #bubbleOption class="d-flex flex-row align-items-center justify-content-between attachment-holder">
              <div class="icon-size mr-1" *ngIf="item.suggestionAvailable">
                  <img *ngIf="item.suggestionStatus=='red'" [src]="'assets/Inbox/nlp_icon_selected_u104_red.svg'" alt="NLP" style="cursor: pointer" pTooltip="Suggestion Not Available" tooltipPosition="left" tooltipStyleClass='leftTooltip p-tooltip-text'>
                  <img *ngIf="item.suggestionStatus=='orange'" [src]="'assets/Inbox/nlp_icon_selected_u104_orange.svg'" alt="NLP" style="cursor: pointer" pTooltip="Awaiting Suggestion" tooltipPosition="left" tooltipStyleClass='leftTooltip p-tooltip-text'>
                  <img *ngIf="item.suggestionStatus=='green'" [src]="'assets/Inbox/nlp_icon_selected_u104_green.svg'" alt="NLP" style="cursor: pointer" pTooltip="Suggestion Available" tooltipPosition="left" tooltipStyleClass='leftTooltip p-tooltip-text'>
                  <img *ngIf="item.suggestionStatus=='black'" [src]="'assets/Inbox/nlp_icon_selected_u104_black.svg'" alt="NLP" style="cursor: pointer" pTooltip="No Suggestion" tooltipPosition="left" tooltipStyleClass='leftTooltip p-tooltip-text'>
              </div>
              <div class="attachments-icon d-flex flex-row align-items-center">
                <img *ngIf="item.attachments?.length>0" [src]="'assets/Inbox/attachments_icon_selected_u107.svg'" alt="">
                <span *ngIf="item.attachments?.length>0">{{item.attachments?.length}}</span>
              </div>
              <div (click)="op.toggle($event)" class="bubble-options d-flex align-items-center">
                <img [src]="'assets/Inbox/ellipsis_icon_chat.svg'" alt=""></div>
                <!-- // C153176-3245 - check for chat bubble should come in left or right -->
              <p-overlayPanel #op [dismissable]="true" [appendTo]="bubbleOption" [styleClass]="(isLoggedInUser(item.modBy) == false)?'bubble-option-overlay-even':'bubble-option-overlay-odd'"
              [showTransitionOptions] = "'20ms ease-out'" [hideTransitionOptions] = "'20ms ease-in'"
              >
                <div>
                  <div class="option-container">
                    <div class="option-menu p-2">
                      <div class="pb-2" (click)="onReplyConversation('Reply', item, op)">Reply</div>
                      <div class="pb-2" (click)="onReplyConversation('ReplyAll', item, op)">Reply all</div>
                      <div class="pb-2" (click)="onReplyConversation('Forward', item, op)">Reassign</div>
                    </div>
                    <div  class="divider"></div>
                    <div class="sub-option-menu pl-2">
                      <div *ngIf="item.attachments?.length>0" class="py-2">Attachments</div>
                      <div *ngIf="!(item.attachments?.length>0)" class="py-2">No Attachments</div>
                      <div *ngIf="item.attachments?.length>0" class="pb-2 download-all" (click)="onDownloadAllAttachment(selectedMail.inquiry.id,item.id)"><ins>Download all</ins><img class="pl-1" [src]="'assets/Inbox/download_icon_u70.svg'" alt="Download"></div>
                      <div *ngIf="item.attachments?.length>0" class="attachment-list">
                        <div class="d-flex flex-row justify-content-between align-items-center pb-2" *ngFor="let attachment of item.attachments;let i = index">
                          <div class="attachment-item d-flex flex-row"><img src="assets/Inbox/notes_blue.svg" alt="Notes">
                            <div>{{attachment.name}}</div>
                          </div>
                          <div class="attach-icon">
                            <img [src]="'assets/Inbox/download_icon_u70.svg'" (click)="onDownloadAttachment(attachment)" alt="Download">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-overlayPanel>
            </div>
          </div>
          <!-- C153176-4793 - User Feedback Popup - Starts-->
          <div class="d-flex flex-row justify-content-between align-items-center justify-content-xl-end">
             <!--  <div class="footer-date"><label>{{item.modDate | date:'dd MMM hh:mm a'}}</label></div> -->
              <div class="user-feedback-overlay d-flex align-items-end justify-content-xl-end"><!-- C153176-5620: Feedback and full message popup overlap issue  -->
                <div class="feedback-container">
                <img class="user-feedback-icon" [src]="'assets/common/user-feedback.svg'" (click)="feedback.toggle($event)" pTooltip="Send Feedback" tooltipPosition="right" tooltipStyleClass='rightTooltip p-tooltip-text' alt="Send Feedback">
              </div>
              <div class="expand-container">
                <img  src='assets/newMessage/ExpandIcon.png' (click)="showOriginalMsg(item.content)" pTooltip="Actual Message" tooltipPosition="right" tooltipStyleClass='rightTooltip p-tooltip-text' alt="Actual Message">
              </div>
              </div>
          </div>
          <!-- // C153176-3245 - check for chat bubble should come in left or right -->
          <p-overlayPanel #feedback [dismissable]="true" [appendTo]="'body'" [styleClass]="(isLoggedInUser(item.modBy) == false)?'user-feedback-overlay-even':'user-feedback-overlay-odd'"
          [showTransitionOptions] = "'20ms ease-out'" [hideTransitionOptions] = "'20ms ease-in'">
          <div>
              <div class="d-flex flex-column">
                <div class="d-flex flex-row justify-content-between align-items-end p-2">
                  <div class="mr-1"><img class="feedback-sec-icon" [src]="'assets/Inbox/info-icon.svg'" alt="Info"></div>
                  <div class="feedback-header-text mr-2">Send Chat Feedback</div>
                  <div class="mr-auto"><img class="user-feedback-icon" [src]="'assets/common/user-feedback.svg'" alt="Feedback"></div>
                  <div (click)="feedback.hide()" class="close-text"><img class="close-icon" [src]="'assets/Inbox/close_icon_u73.svg'" alt="Close">CLOSE</div>
                </div>
                <div class="d-flex flex-column pl-2">
                  <div class="feedback-text ml-3">{{userFeedbackMsg}}</div>
                  <div class="feedback-text ml-3 pt-1">You see</div>
                  <div class="ml-2">
                    <app-checkbox-list [searchIcon]="false" [name]="'feedbackItem'" [items]="feedbackItems" [listStyle]="{'height':'192px'}"
                      [controlType]="'checkbox'" [search]="false" [resetBehaviour]="true" (onItemSelect)="getSelectedItems($event)"></app-checkbox-list>
                  </div>
                </div>
                <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
                    <button class="btn clear-btn mr-3" (click)="feedback.hide()">Cancel</button>
                    <button type="submit" class="btn search-btn" (click)="onSendReport(item, feedback)">Send Report</button>
                </div>
              </div>
          </div>
        </p-overlayPanel>
        <!-- C153176-4793 - User Feedback Popup - Ends-->
        </div>
      </div>
    </div>
  </div>

  <div class="bottomBar d-flex flex-column align-items-between justify-content-between" #bottomBar [ngClass]="bottomBarExpand ? 'expand': ''" (click)="doExpand(true)"  [formGroup]="chatForm">
    <div class="onExpandHide">
      <div class="d-flex flex-row align-items-start">
        <p-autoComplete (completeMethod)="filterUsers($event)" [minLength]="3" field="text" [multiple]="true" [suggestions]="filteredToUsersMultiple" styleClass="override" class="w-100" [(ngModel)]="toUsersList"
          (onKeyUp)="onKeyUp($event)" formControlName="toUsers">
          <ng-template let-value pTemplate="selectedItem">
            <span [ngClass]="value.isExternal?'external':''" oncontextmenu="return false;"
            class="p-autocomplete-token-label new-message-token"
            (click)="displayContactInfo($event, opContactInfo, value)"
            title="{{value.email}}">{{value.text}}</span>
        </ng-template>
        </p-autoComplete>
        <p-overlayPanel #opContactInfo [appendTo]="'body'" [dismissable]="true" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'">
          <gdir-contact-details *ngIf="selectedContactId?.length>0" [soeId]="selectedContactId"></gdir-contact-details>
      </p-overlayPanel>
        <!-- C153176-5146: validation -->
        <div *ngIf="chatForm.controls.toUsers.errors && (chatForm.controls.toUsers.dirty)"><!--C153176-5055 'To' validation in chat -->
          <img src="assets/newMessage/error.svg" class="error" title="TO is Required" alt="Error">
      </div>

        <!-- (completeMethod)="filterUsers($event)" [minLength]="1" field="text" [multiple]="true" styleClass="override"
          class="w-100" [(ngModel)]="toUsersList" (onKeyUp)="onKeyUp($event, 'to')" -->
        <!-- <div *ngIf="parentForm.controls.groupName.errors && (parentForm.controls.groupName.dirty || parentForm.controls.groupName.touched)"
        class="">
        <img src="assets/newMessage/error.svg" class="error" title="GroupName is Required">
      </div> -->
      </div>
      <div class="d-flex flex-row mobile-form mail-options-panel align-items-start control-group w-100 flex-wrap justify-content-between px-2 py-1">
        <div class="w-20 d-flex flex-row align-items-center request-section flex-wrap">
          <label for="from" >Request Type: </label>
          <p-dropdown class="drop-down" [options]="requestTypes" [styleClass]="'prime-dd-override chat-view-dd'"  formControlName="requestType"
            (onChange)="onRequestTypeChange($event)">
          </p-dropdown>
          <div *ngIf="chatForm.controls.requestType.errors" class="">
            <img src="assets/newMessage/error.svg" class="error" title="RequestType is Required" alt="RequestType is Required">
          </div>
        </div>
        <div class="w-20 d-flex flex-row align-items-center request-section flex-wrap">
          <label for="from" >Root Cause: </label>
          <p-dropdown class="drop-down" [options]="rootCauses" [styleClass]="'prime-dd-override  chat-view-dd'"  formControlName="rootCause">
          </p-dropdown>
          <div *ngIf="chatForm.controls.rootCause.errors"
          class="">
          <img src="assets/newMessage/error.svg" class="error" title="Root Cause is Required" alt="Root Cause is Required">
          </div>
        </div>
        <div class="w-20 d-flex flex-row align-items-center request-section flex-wrap">
          <label for="from">Tag: </label>
          <p-dropdown class="drop-down" [options]="tags" [styleClass]="'prime-dd-override  chat-view-dd'" formControlName="tagList">
          </p-dropdown>
          <div *ngIf="chatForm.controls.tagList.errors"
          class="">
          <img src="assets/newMessage/error.svg" class="error" title="Tags is Required" alt="Tags is Required">
        </div>
        </div>
      </div>
      <!-- C153176-5146: additional input fields -->
      <div class="d-flex flex-row mobile-form mail-options-panel align-items-center control-group w-100 flex-wrap justify-content-between px-2 py-1">
        <div class="w-20 d-flex flex-row align-items-center request-section">
          <label for="from" class="flex-grow">Processing Region: </label>
          <p-dropdown class="drop-down" [options]="processingRegions" formControlName="processingRegion"
          [styleClass]="'override'">
          </p-dropdown>
          <div *ngIf="chatForm.controls.processingRegion.errors" class="">
            <img src="assets/newMessage/error.svg" class="error" title="Processing Region is Required" alt="Processing Region is Required">
          </div>
        </div>
        <div class="w-20 d-flex flex-row align-items-center request-section">
          <label for="from" class="flex-grow">Query Count: </label>
          <input style="width: 60px !important;" type="text" formControlName="queryCount" maxlength="4" (keypress)="isNumber($event)">
        </div>
        <div class="w-20 d-flex flex-row align-items-center request-section1">
          <label for="from" class="flex-grow">Inq Source: </label>
          <p-dropdown class="drop-down" [options]="inquirySourceList" formControlName="inquirySource"
          [styleClass]="'override'">
          </p-dropdown>
          <div *ngIf="chatForm.controls.inquirySource.errors" class="">
            <img src="assets/newMessage/error.svg" class="error" title="Inquiry Source is Required" alt="Inquiry Source is Required">
          </div>
        </div>
        <div class="w-20 d-flex flex-row align-items-center request-section">
                <em class="fa fa-search"></em><a href="#" class="TextGFP" (click)="callGFIDWindow()">GFPID / GFCID Search:</a>
                <!-- C153176-5146 : Issue-4 : Resolve in Chat view needs the 3 mandatory fields. -->
                <!-- <span class="truncate" pTooltip="{{gfpidgfcid}}" tooltipPosition="top" [appendTo]="'body'" title="{{gfpidgfcid}}" tooltipEvent="focus">{{gfpidgfcid}}</span> -->
                <div [innerHTML]="gfpidgfcid"></div>
                <input type="hidden" formControlName="gfcId"  />
                <input type="hidden" formControlName="gfcName" />
                <input type="hidden" formControlName="gfpId"  />
                <input type="hidden" formControlName="gfpName" />
                <input type="hidden" formControlName="skAccountNo" />
                <input type="hidden" formControlName="branch" />
                <div *ngIf="chatForm.controls.gfpId.errors && chatForm.controls.gfcId.errors" class="">
                  <img src="assets/newMessage/error.svg" class="error" alt="Error"><span class="errorMsg"> GFPID/GFCID is mandatory for resolve</span>
               </div>
        </div>
    </div>

      <div #overflowdiv class="w-100 d-flex flex-row align-items-start control-group ellipsis-overflow uploadPanel">
        <div *ngIf="postDataForAttachmentFile" class="pull-left" id="listOffileAttached" >
          <div *ngFor="let attachment of postDataForAttachmentFile" style="display:inline-flex; font-family: OpenSans;">
          <div class="elipsis-attachment">
            <span id="{{attachment.id}}"><img src="assets/newMessage/notes.svg" class="p-1 attachment-img" alt="Notes">
              <a href="#" title="{{attachment.name}}" class="attachment-font" (click)="downloadAttachment(attachment.id,attachment.name)">{{attachment.name}}</a>
            </span>
            </div>
            <span (click)='deleteAttachedFile(attachment.id)'>
              <img src="assets/newMessage/attachdelete.svg" class="p-1 attchment-cross" alt="Delete">
          </span>

          </div>
        </div>

        <input type="hidden" name="attachments">
      </div>
      <a class="d-flex flex-column justify-content-center ellipsis-btn" *ngIf="checkOverFlow(overflowdiv) && !isElipsisShow"
        (click)="showRemainingAttchment($event, attachmentOptions, postDataForAttachmentFile)">
          <img src="assets/newMessage/ellipse.svg" class="p-1 ellipse-size" alt="More">
        </a>
    </div>



    <div class="editorWrapper">
        <div *ngIf="time | async">
            <editor-app [elementId]="editorId" [height]="150" [ngClass]="formatFlg ? '': 'hideToolbar'"
            class="my-editor" #editorApp (onLoad)="loadData()"> </editor-app>
          </div>
    </div>


    <div class="actionBar">
      <div class="w-100">
        <div class="d-flex flex-row justify-content-start">
          <button class="d-flex flex-column button justify-content-center" (click)="sendInquiry($event)">
            <img src="assets/symphony/send-4.png" class="p-1" style="margin-top1%;margin-bottom1%" alt="Send">
          </button>
          <button class="d-flex flex-column justify-content-center button" (click)="cancel($event)">
            <img src="assets/newMessage/cancel.svg" class="" alt="Cancel">
          </button>
          <button class="d-flex flex-column justify-content-center button" (click)="markResolve()"
            [ngClass]="resolve ? 'selected' : ''">
            <img src="assets/newMessage/resolve.svg" class="p-1" alt="Resolve">
          </button>
          <input type="hidden" id="resolveFlg" #resolveFlag value="N" />

          <button class="d-flex flex-column justify-content-center button" (click)="saveDraft($event)">
            <img src="assets/newMessage/save.svg" class="p-1" alt="Save">
          </button>

          <input name="files" id="files" type="file" multiple="true" style="display:none" #uploadFiles
            (change)="fileEvent($event)" />
          <button class="d-flex flex-column justify-content-center button" (click)="uploadFile()">
            <img src="assets/newMessage/attach.svg" class="p-1" alt="Attach">
          </button>

          <button class="d-flex flex-column justify-content-center button" (click)="fotmatToggle()"
            [ngClass]="formatFlg ? 'selected' : ''">
            <img src="assets/newMessage/format.svg" class="p-1" alt="Format">
          </button>
        </div>
      </div>
    </div>
  </div>

    <div class="black-overlay" *ngIf="displayFullMsg">
      <div class="content-body">
        <div class="d-flex flex-row align-items-center justify-content-between">
          <h6 class="fullMsg">FULL MESSAGE</h6>
          <button type="button" (click)="hideModal()" class="d-flex flex-row close qma-text">
            <img src="assets/Inbox/close_icon_u73.svg" alt="Close">
            <h6>CLOSE</h6>
          </button>
        </div>
        <div class="pt-2 full-content" [innerHtml]="fullContent | safeHtml"></div>
      </div>
    </div>

    <div class="black-overlay" *ngIf="originalMsg">
      <div class="content-body">
        <div class="d-flex flex-row align-items-center justify-content-between">
          <h6 class="fullMsg">FULL MESSAGE</h6>
          <button type="button" (click)="hideModal()" class="d-flex flex-row close qma-text">
            <img src="assets/Inbox/close_icon_u73.svg" alt="Close">
            <h6>CLOSE</h6>
          </button>
        </div>
        <div class="pt-2 full-content" [innerHtml]="originalContent | safeHtml"></div>
      </div>
    </div>
</div>

<p-overlayPanel [style]="{'margin-top': '-2em'}"  #attachmentOptions appendTo="body" styleClass="attachmentDropdownChat">
  <p-listbox [listStyle]="{'max-height':'154px', 'max-width':'121px,', 'overflow-x': 'hidden'}" [options]="attachmentsOptions">
      <ng-template let-attachOpt pTemplate="item">
          <div class="opt-items tooltip-text" style="display:inline-flex" >
            <div class="elipsis-attachment">
                <span class="elipsis-attachment">
                    <span ><img src="assets/newMessage/notes.svg" class="p-1 attachment-img" alt="Notes">
                      <a href="#" title="{{attachOpt.name}}" class="attachment-font" (click)="downloadAttachment(attachOpt.id,attachOpt.name)">{{attachOpt.name}}</a>
                    </span>
                  </span>
            </div>
                <span>
                  <a (click)='deleteAttachedFilePanel(attachOpt.id)'>
                  <img src="assets/newMessage/attachdelete.svg" class="p-1 attchment-cross" alt="Delete">
                </a>
              </span>
        </div>
      </ng-template>
  </p-listbox>
</p-overlayPanel>

<p-confirmDialog styleClass="confirmationChat" header="Confirm Action" icon="pi pi-exclamation-triangle" width="425" acceptLabel="Yes" rejectLabel="No"></p-confirmDialog>

<!-- C153176-4793 : Chat Feedback Sussess Message-->
<p-dialog [(visible)]="feedbackFlag" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal successModal ModelClose'"
  [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '20%'}">
  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between">
      <h6 class="success-title">Success Confirmation</h6>
    </div>
  </p-header>
  <div class="success-msg"> Chat feedback is saved successfully!</div>
</p-dialog>


<app-gfid-search [isNewMessage]="true" (addClicked)="getGFID($event)"></app-gfid-search>
<app-global-directory-suggestion *ngIf="displaySuggestion" [displaySuggestion]="displaySuggestion"
[suggestionRecords]="suggestionRecords" [suggestionRecordCount]="suggestionRecordCount"
(citiUserEvent)="addSuggestionToRecipient($event)"></app-global-directory-suggestion>

<!-- C170665-255 Restrict specific file extensions in QMA 2.0 Error pop up -->
<p-dialog [(visible)]="displayError" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'blacklistModel'"
  [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '30%'}">
  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between">
      <h6 class="success-title">Information</h6>
      <div class="d-flex">
        <div class="d-flex">
          <div class="d-flex">
            <img src="assets/Inbox/info-icon.svg" class="icon-setting-out"  (mouseover)="showUploadFilesPanel($event, info_upload_files)" (mouseout)="info_upload_files.hide()" alt="Info"/>
            <p-overlayPanel [styleClass]="'icon-info-upload-files inquiry_rules_info'" [appendTo]="'body'" #info_upload_files [baseZIndex]="9999">
                <div class="d-flex flex-row mb-0.3">
                    <span class="info-content">{{qmaConstant?.QMA_UPLOAD_FILES_INFO}}</span>
                </div>
            </p-overlayPanel>
          </div>
            <button type="button" (click)="closeModal('error')" class="d-flex flex-row close qma-text">
                <img src="assets/Inbox/close_icon_u73.svg" alt="Close">
                <h6>CLOSE</h6>
            </button>
        </div>

    </div>
    </div>
  </p-header>
  <div class="success-msg" [innerHTML]="QMA_INVALID_FILES_MSG"></div>
</p-dialog>
<p-dialog [(visible)]="closeFlagInvalid" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
[styleClass]="'contextMenuModal errorModal ModelClose'" [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true"
[style]="{width: '15%'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between">
            <h6 class="success-title">Information</h6>
        </div>
    </p-header>
    <div class="success-msg">Invalid email entered.</div>
</p-dialog>

<div *ngIf="showSpinner" class="chat-view-loading">
  <div class="detail-view-container-with-sidebar">
      <ng-template #customLoadingTemplate>
          <div class="custom-class-loader-mail-detail-view">
              <div class="spinner-container">
                  <svg class="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
                      <circle cx="170" cy="170" r="160" stroke="#021e48" />
                      <circle cx="170" cy="170" r="135" stroke="#00bdf2" />
                      <circle cx="170" cy="170" r="110" stroke="#021e48" />
                      <circle cx="170" cy="170" r="85" stroke="#00bdf2" />
                  </svg>
              </div>
          </div>
      </ng-template>
      <ngx-loading [show]="showSpinner" [config]="{ backdropBorderRadius: '3px',fullScreenBackdrop:false }" [template]="customLoadingTemplate"></ngx-loading>
  </div>
</div>

<p-dialog [(visible)]="displayErrorFileNameIssues" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'blacklistModel'"
  [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '30%'}">
  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between">
      <h6 class="success-title">Information</h6>
      <div class="d-flex">
        <div class="d-flex">
          <div class="d-flex">
            <img src="assets/Inbox/info-icon.svg" class="icon-setting-out"  (mouseover)="showUploadFilesPanel($event, info_upload_files)" (mouseout)="info_upload_files.hide()" alt="Info"/>
            <p-overlayPanel [styleClass]="'icon-info-upload-files inquiry_rules_info'" [appendTo]="'body'" #info_upload_files [baseZIndex]="9999">
                <div class="flex-row mb-0.3" style="font-family: 'OpenSans'">
                  <div class="info-content" style="font-family: 'OpenSans';font-weight: bold;">
                    List of extensions restricted to upload:-
                  </div>
                    <div class="info-content">{{qmaConstant?.QMA_UPLOAD_FILES_INFO}}</div>

                </div>
                <div class="flex-row mb-0.3  mt-2"  style="font-family: 'OpenSans';">
                  <div class="info-content" style="font-family: 'OpenSans';font-weight: bold;">
                    List of  restricted characters in file name to upload:-
                  </div>
                  <div style="font-family: 'OpenSans';" class="info-content">
                    {{qmaBlockedFileNameChars}}
                  </div>
                </div>
            </p-overlayPanel>
          </div>
            <button type="button" (click)="closeErrorModal()" class="d-flex flex-row close qma-text">
                <img src="assets/Inbox/close_icon_u73.svg" alt="Close">
                <h6>CLOSE</h6>
            </button>
        </div>

    </div>
    </div>
  </p-header>
  <div class="success-msg" style="font-weight: bold;" *ngIf="errMsgRestrcitedCharsInFilename">
    Below uploaded file name contains special characters which is replaced by _  Please refer to information icon for more details
  </div>
  <div class="success-msg" [innerHTML]="errMsgRestrcitedCharsInFilename"></div>
  <div class="success-msg" style="font-weight: bold;" *ngIf="errMsgMultipleDotsInFilename">
    Below uploaded file name contains multiple . characters which is replaced by _
  </div>
  <div class="success-msg" [innerHTML]="errMsgMultipleDotsInFilename"></div>
</p-dialog>