import { Component, OnInit, OnChanges, SimpleChanges, Input, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';
import { UserDataService } from '../../services/user-data.service';
import { MyFavoriteContact, GroupMemberContact } from '../../model/LoginUserInfo/MyFavoriteContact';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { MyGroup } from '../../model/LoginUserInfo/MyGroup';
import { GetContactsService } from '../../services/newMessage/get-contacts.service';
import { NewMessageService } from '../../services/newMessage/new-message.service';
import { TopContacts } from '../../model/LoginUserInfo/TopContacts';
import { AllActiveGroup } from '../../model/LoginUserInfo/AllActiveGroup';
import { AllToCcDBUser } from "src/app/model/LoginUserInfo/AllToCcDBUser";
import { OverlayPanel } from 'primeng/overlaypanel';
import  * as _ from 'underscore';
import { GroupAdminService } from 'src/app/services/group-admin/group-admin.service';
import { PiwikProUtils } from 'src/app/common/utility/piwikProUtils';
import { TabDataService } from 'src/app/services/tab-data.service';

@Component({
  selector: 'contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss'],
})
export class ContactListComponent implements OnInit {

  myFavoriteContacts: MyFavoriteContact[] = [];
  globalContacts: MyFavoriteContact[] = [];
  groupContacts: MyFavoriteContact[] = [];
  newContact: any;
  addTopContact: boolean = false;
  groupNames: MyGroup[];
  groupName: string;
  topContacts: any = [];
  globalTopContactIndex: number = 0;
  userGroupIdList = [];
  updateContactList = [];
  addTopContactForm: FormGroup;
  editTopContactForm: FormGroup;
  query: string;
  groupQuery: string;
  clientQuery: string;
  clientContacts: MyFavoriteContact[] = [];
  allActiveGroups: AllActiveGroup[] = [];
  displayCreateNew: boolean = false;
  submitted = false;
  displaySuccess: boolean = false;
  displayError: boolean = false;
  displayEdit: boolean = false;
  mapToGroupList: string;
  sharedToGroupList: string;
  myGroups: any;
  nameEdit: String = '';
  emailEdit: String = '';
  phoneEdit: String = '';
  addressEdit: String = '';
  noteEdit: String = '';
  filteredGroups: any;
  loggedInUser: string = '';
  groupMembers: GroupMemberContact[] = [];
  selectedGroup = [];
  groupRoles: any = [];
  isUserGroupAdmin: boolean = false;
  loading: boolean = true; /*C153176-4229 add to top contact */

  // C153176-5630 : Update Client Address
  editClientAddr: boolean = false;
  clientContactForm: FormGroup;
  currClientInfo: any;
  assignedGroups: any;
  hideClientAddr: boolean = false;
  contactsTabName:string="INDIVIDUALS";

  // C153176-4229 | Emitter which add the contact from contact list to new-message recipent
  @Output() contactEvent: any = new EventEmitter();
  // C153176-4998 - Top Contacts not appearing for New Message and reply/reply all 
  @Input() fromGroupName: any = "";

  // C153176-5746 : New contact added to Top Contact using right click.
  @Input() topContactAdded: any;
  contactSaveClicked: boolean = false; // C153176-5854 | To handle multiple click on save;

  commaSepEmail = (control: AbstractControl): { [key: string]: any } | null => {
    let forbidden = false;
    if (control.value) {
      const emails = control.value.split(';').map(e => e.trim());
      // C153176-5288: since Validators.email() skips empty value, include validation on empty value (which is invalid email address)
      forbidden = emails.some(email => !email || Validators.email(new FormControl(email)));
    }
    return forbidden ? { 'email': { value: control.value } } : null;
  };

  get add() { return this.addTopContactForm.controls; }

  get edit() { return this.editTopContactForm.controls; }

  get client() { return this.clientContactForm.controls; }

  constructor(private userDataService: UserDataService, private builder: FormBuilder, private getContactsService: GetContactsService,
    private newMessageService: NewMessageService, private groupService: GroupAdminService,private tabDataService: TabDataService) {
    
    // Get the loggedIn User.
    this.loggedInUser = this.userDataService.getLoggedInUser();;

    // C153176-5288: Populate all the contact from the login.
    this.getMyFavoriteContacts();

    this.getContactsService.getGroupContact().subscribe(data => {
      this.groupName = data;
      this.populateFavoriteContacts(this.groupName);
    });
  }

  ngOnInit() {
    this.addTopContactForm = this.builder.group({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, this.commaSepEmail]),
      phone: new FormControl('', []),
      address: new FormControl('', []),
      note: new FormControl('', []),
      mapToGroup: new FormControl('', []),
      sharedToGroup: new FormControl('', [])
    });

    this.editTopContactForm = this.builder.group({
      nameEdit: new FormControl('', [Validators.required]),
      emailEdit: new FormControl('', [Validators.required, this.commaSepEmail]),
      phoneEdit: new FormControl('', []),
      addressEdit: new FormControl('', []),
      noteEdit: new FormControl('', []),
      mapToGroupEdit: new FormControl('', []),
      sharedToGroupEdit: new FormControl('', []),
      index: new FormControl('', []),
      createdBy: new FormControl('', [])
    });

    // C153176-5630 : Client Email Address
    this.clientContactForm = this.builder.group({
      emailAddr: new FormControl('', [Validators.required, Validators.email]),
    });

  }
  // C153176-4998 - Top Contacts not appearing for New Message and reply/reply all
  ngOnChanges(changes: SimpleChanges) {

    // C153176-5288 : This is only excutes when user perform reply operation on the inquiry.
    if (changes.fromGroupName && changes.fromGroupName.currentValue !== changes.fromGroupName.previousValue) {
      this.groupName = changes.fromGroupName.currentValue || this.groupName; // In new message - groupname is populated before, so we need to consider groupName if current value is blank.
      this.populateFavoriteContacts(this.groupName);
    }

    // C153176-5746 : New contact added to Top Contact using right click.
    if (changes.topContactAdded && JSON.stringify(changes.topContactAdded.currentValue) !== JSON.stringify(changes.topContactAdded.previousValue)) {
      this.getMyFavoriteContacts();
      let topContact = changes.topContactAdded.currentValue;
      this.populateFavoriteContacts(topContact.mapToGroupsName);
    }
  }

  getMyFavoriteContacts() {
    this.myFavoriteContacts = [...this.userDataService.getFavContacts()];
    this.groupRoles = [...this.userDataService.getGroupRoles()];
    this.assignedGroups = [...this.userDataService.getAssignedGroups()];

    let groupNameTemp = [];
    groupNameTemp.push({ label: '', value: '' });
    this.assignedGroups.forEach(myGroup => {
      groupNameTemp.push({ label: myGroup.groupName, value: myGroup.groupName });
      this.userGroupIdList.push(myGroup.id);
    });

    this.groupNames = [...groupNameTemp];
    this.myGroups = this.groupNames.slice(1);
    this.initializeTopContacts();
  }

  loadGlobalContacts() {
    this.globalContacts = [];
    this.topContacts.forEach(element => {
      // C153176-4588 : contacts which are not present in map and shared group
      if (this.isNull(element.mapToGroupsName) && this.isNull(element.sharedToGroupsName)) {
        this.globalContacts.push({
          name: element.name,
          email: element.email,
          mapToGroupsName: element.mapToGroupsName,
          sharedToGroupsName: element.sharedToGroupsName,
          createdBy: element.createdBy,
          index: element.index,
          contactAddress: element.contactAddress,
          contactNote: element.contactNote,
          contactPhNumber: element.contactPhNumber
        });
      }
    });
    this.groupContacts = [...this.globalContacts];
    // C153176-5288 | Remove Duplicates
    this.groupContacts = _.uniq(this.groupContacts, 'email');
  }

  loadGroupContacts(groupName) {
    this.groupContacts = [];
    this.topContacts.forEach(element => {
      if (this.isNull(element.mapToGroupsName) && this.isNull(element.sharedToGroupsName)) {
        this.groupContacts.push({
          name: element.name,
          email: element.email,
          mapToGroupsName: element.mapToGroupsName,
          sharedToGroupsName: element.sharedToGroupsName,
          createdBy: element.createdBy,
          index: element.index,
          contactAddress: element.contactAddress,
          contactNote: element.contactNote,
          contactPhNumber: element.contactPhNumber
        });
      }
      // C153176-4588 : contacts which are not present in map and shared group
      if (!this.isNull(element.mapToGroupsName) || !this.isNull(element.sharedToGroupsName)) {
        // C153176-4636 : fix
        if ((element.mapToGroupsName.indexOf(groupName) > -1 && element.createdBy == this.loggedInUser) || element.sharedToGroupsName.indexOf(groupName) > -1) {
          this.groupContacts.push({
            name: element.name,
            email: element.email,
            mapToGroupsName: element.mapToGroupsName,
            sharedToGroupsName: element.sharedToGroupsName,
            createdBy: element.createdBy,
            index: element.index,
            contactAddress: element.contactAddress,
            contactNote: element.contactNote,
            contactPhNumber: element.contactPhNumber,
          });
        }
      }
    });
    // C153176-5288 | Remove Duplicates
    this.groupContacts = _.uniq(this.groupContacts, 'email');
  }

  initializeTopContacts() {
    let contactIndex = 0;
    this.topContacts = []; // Reset Top contacts.
    this.myFavoriteContacts.forEach(element => {
      this.topContacts.push({
        name: element.name,
        email: element.email,
        mapToGroupsName: element.mapToGroupsName,
        sharedToGroupsName: element.sharedToGroupsName,
        createdBy: element.createdBy,
        index: contactIndex,
        contactAddress: element.contactAddress,
        contactNote: element.contactNote,
        contactPhNumber: element.contactPhNumber
      });
      contactIndex++;
    });
    this.globalTopContactIndex = contactIndex;
  }

  populateFavoriteContacts(groupName) {
    if (this.isNull(this.groupName))
      this.loadGlobalContacts();
    else
      this.loadGroupContacts(groupName);
  }

  saveTopContact() {
    let newContact = [];

    let mapToGroup = '';
    if (!this.isNull(this.addTopContactForm.get('mapToGroup').value)) {
      mapToGroup = this.addTopContactForm.get('mapToGroup').value.join(",");
    }
    else
      mapToGroup = '';

    let sharedToGroup = '';
    if (!this.isNull(this.addTopContactForm.get('sharedToGroup').value))
      sharedToGroup = this.addTopContactForm.get('sharedToGroup').value.join(",");
    else
      sharedToGroup = '';

    let name = this.addTopContactForm.get('name').value;
    let email = this.addTopContactForm.get('email').value;
    let phone = this.addTopContactForm.get('phone').value;
    let address = this.addTopContactForm.get('address').value;
    let note = this.addTopContactForm.get('note').value;
    if (this.addTopContact && this.newContact.mapToGroupsName !== "") {
      /*C153176-4229 add to top contact */
      if (Array.isArray(this.newContact) && this.newContact.length > 0 && this.newContact.length != undefined) {
        this.newContact.forEach(contact => {
          newContact.push({ name: contact.name, email: contact.email });
        })
      } else {
        // C153176-5288 | Unwanted three contacts are getting saved from Add to Top Contacts
        const contact = { ...this.newContact };
        newContact.push({ name: contact.name, email: contact.email });
      }
      this.addTopContact = false;
    } else if (this.addTopContact && this.newContact.mapToGroupsName === "") {
      newContact.push({
        name: this.newContact.name,
        email: this.newContact.email
      });
      this.addTopContact = false;
    }
    else {
      newContact.push({
        name: name,
        email: email,
        mapToGroupsName: mapToGroup,
        sharedToGroupsName: sharedToGroup,
        createdBy: this.loggedInUser,
        index: this.globalTopContactIndex,
        contactAddress: address,
        contactNote: note,
        contactPhNumber: phone
      });
    }

    this.topContacts = this.topContacts.concat(newContact);
    // filter out the duplicate 
    // this.topContacts = _.uniq(this.topContacts, 'email');
    // C170665-1172 While Saving New Contact - Although Success message Popped up,still Contact not saved in QMA and backend
    this.topContacts = this.getUniqueContacts(this.topContacts);
    // Filter out blank emails
    this.topContacts = this.topContacts.filter(contact => !this.isNull(contact.email));

    this.updateContactList = this.topContacts;
    let topContact = new TopContacts(this.updateContactList, this.userGroupIdList);
    this.newMessageService.saveTopContact(topContact).subscribe(result => {
      if (result.processingstatus === "true") {
        this.displayCreateNew = false;
        this.displaySuccess = true;
        this.displayError = false;
        this.displayEdit = false;
        this.cancelTopContact('save');
        this.addTopContactForm.reset();
        this.resetForm(this.addTopContactForm);
        // C153176-4998 : Update top Contacts
        this.userDataService.updateFavoriteContacts(this.updateContactList);
        this.contactSaveClicked = false; // Enable the button.
      }
    },
      (error) => {
        this.displayCreateNew = false;
        this.displaySuccess = false;
        this.displayError = true;
        this.displayEdit = false;
        this.addTopContactForm.reset();
        this.cancelTopContact('save');
        this.resetForm(this.addTopContactForm);
        this.contactSaveClicked = false; // Enable the button.
      }
    );
  }

  resetForm(form: FormGroup) {
    Object.keys(form.controls).forEach(key => {
      form.get(key).setErrors(null);
    });
  }

  updateTopContact() {
    let editIndex = this.editTopContactForm.get('index').value;
    let name = this.editTopContactForm.get('nameEdit').value;
    let email = this.editTopContactForm.get('emailEdit').value;
    let phone = this.editTopContactForm.get('phoneEdit').value;
    let address = this.editTopContactForm.get('addressEdit').value;
    let note = this.editTopContactForm.get('noteEdit').value;
    let createdBy = this.editTopContactForm.get('createdBy').value;
    let mapToGroup = '';
    if (!this.isNull(this.editTopContactForm.get('mapToGroupEdit').value)) {
      mapToGroup = this.editTopContactForm.get('mapToGroupEdit').value.join(",");
    }
    else
      mapToGroup = '';

    let sharedToGroup = '';
    if (!this.isNull(this.editTopContactForm.get('sharedToGroupEdit').value))
      sharedToGroup = this.editTopContactForm.get('sharedToGroupEdit').value.join(",");
    else
      sharedToGroup = '';

    this.topContacts.forEach(function (contact, i) {
      if (contact.index === editIndex) {
        contact.name = name;
        contact.email = email;
        contact.mapToGroupsName = mapToGroup;
        contact.sharedToGroupsName = sharedToGroup;
        contact.createdBy = createdBy;
        contact.index = editIndex;
        contact.contactAddress = address;
        contact.contactNote = note;
        contact.contactPhNumber = phone;
      }
    });

    this.updateContactList = this.topContacts;
    let topContact = new TopContacts(this.updateContactList, this.userGroupIdList);
    this.newMessageService.saveTopContact(topContact).subscribe(result => {
      if (result.processingstatus === "true") {
        this.populateFavoriteContacts(this.groupName);
        this.displayCreateNew = false;
        this.displaySuccess = true;
        this.displayError = false;
        this.displayEdit = false;
        this.cancelTopContact('edit');
        this.editTopContactForm.reset();
        this.contactSaveClicked = false; // Enable the button.
      }
    },
      (error) => {
        this.displayCreateNew = false;
        this.displaySuccess = false;
        this.displayError = true;
        this.displayEdit = false;
        this.cancelTopContact('edit');
        this.editTopContactForm.reset();
        this.contactSaveClicked = false; // Enable the button.
      }
    );
  }

  isNull(input) {
    if (input === "" || input === undefined || input == null) {
      return true;
    }
    return false;
  }

  /** method will push single in service to poulate be contact recipent for "TO, CC, BCC "**/
  pushToRecipent(singleContact: {}, field, grp) {
    let contact = new AllToCcDBUser();
    //C153176-4430 - group contact add to 'to,cc,bcc'
    if (grp) {
      contact.text = singleContact['text'];
      contact.value = singleContact['value'];
      contact.email = singleContact['email'];
    } else {
      contact.text = singleContact['name'];
      contact.value = singleContact['email'];
      contact.email = singleContact['email'];
    }

    const pushContact: any = {
      contact: contact,
      field: field
    };
    // C153176-4229 | Let new-message know new contact should added to recpient field
    this.contactEvent.emit(pushContact);
  }
  //C153176-4430 - group contact add to 'to,cc,bcc'
  /** For Group List action & method will push single in service to poulate be contact recipent for "TO, CC, BCC "**/
  tempSingleGroup: any;
  openToolTipForGroup(event, tooltip: OverlayPanel, activeGroup) {
    event.stopPropagation();
    tooltip.toggle(event);
    this.tempSingleGroup = activeGroup;
  }

  showCreateNewDialog() {
    this.selectedGroup = [];
    if (!this.isNull(this.groupName))
      this.selectedGroup.push(this.groupName);
    this.displayCreateNew = true;
  }

  onSaveSubmit() {
    this.submitted = true;
    if (this.addTopContactForm.invalid) {
      return;
    }
    else {
      if (!this.contactSaveClicked) { // C153176-5854 | Handle multiple click on save button.
        this.contactSaveClicked = true;
        this.saveTopContact();
        this.populateFavoriteContacts(this.groupName);
      }
    }
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Sidebar - Top contact save", "Click", "Sidebar - Top contact save", 0);
  }

  onEditSubmit() {
    this.submitted = true;
    if (this.editTopContactForm.invalid)
      return;
    else {
      if (!this.contactSaveClicked) { // C153176-5854 | Handle multiple click on save button.
        this.contactSaveClicked = true;
        this.updateTopContact();
        this.populateFavoriteContacts(this.groupName);
      }
    }
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Sidebar - Top contact edit", "Click", "Sidebar - Top contact edit", 0);
  }

  showEditTopContactDialog(contact, index) {
    let roles = [];
    this.groupRoles.forEach(role => {
      if (this.groupName === role.groupName) {
        roles = role.groupRole.split(',');
        roles.forEach(role => {
          if (role.trim() === 'Group Admin')
            this.isUserGroupAdmin = true;
        });
      }
    });

    if (this.isNull(this.groupName) && (this.isNull(contact.mapToGroupsName) && this.isNull(contact.sharedToGroupsName)) || contact.createdBy === this.loggedInUser)
      this.isUserGroupAdmin = true;

    this.displayEdit = true;
    let mapToGroup = contact.mapToGroupsName;
    if (mapToGroup)
      mapToGroup = mapToGroup.split(",")
    else
      mapToGroup = [];

    //filter for remove empty elements
    mapToGroup = mapToGroup.filter(Boolean);

    let sharedToGroupEdit = contact.sharedToGroupsName;
    if (sharedToGroupEdit)
      sharedToGroupEdit = sharedToGroupEdit.split(",");
    else
      sharedToGroupEdit = [];

    //filter for remove empty elements
    sharedToGroupEdit = sharedToGroupEdit.filter(Boolean);

    this.editTopContactForm.get('nameEdit').setValue(contact.name);
    this.editTopContactForm.get('emailEdit').setValue(contact.email);
    this.editTopContactForm.get('mapToGroupEdit').setValue(mapToGroup);
    this.editTopContactForm.get('addressEdit').setValue(contact.contactAddress);
    this.editTopContactForm.get('phoneEdit').setValue(contact.contactPhNumber);
    this.editTopContactForm.get('noteEdit').setValue(contact.contactNote);
    this.editTopContactForm.get('sharedToGroupEdit').setValue(sharedToGroupEdit);
    this.editTopContactForm.get('index').setValue(index);
    this.editTopContactForm.get('createdBy').setValue(contact.createdBy);
  }

  cancelTopContact(option) {
    console.log('cancelTopContact: ');
    if (option === 'save') {
      this.displayCreateNew = false;
      this.addTopContactForm.get('name').setValue(null);
      this.addTopContactForm.get('address').setValue(null);
      this.addTopContactForm.get('phone').setValue(null);
      this.addTopContactForm.get('note').setValue(null);
      this.addTopContactForm.get('mapToGroup').setValue(null);
      this.addTopContactForm.get('sharedToGroup').setValue(null);
      this.displayCreateNew = false;
    }
    else if (option === 'edit') {
      this.editTopContactForm.get('nameEdit').setValue(null);
      this.editTopContactForm.get('addressEdit').setValue(null);
      this.editTopContactForm.get('phoneEdit').setValue(null);
      this.editTopContactForm.get('noteEdit').setValue(null);
      // this.editTopContactForm.get('mapToGroupEdit').setValue(null);
      // this.editTopContactForm.get('sharedToGroupEdit').setValue(null);
      this.displayEdit = false;
    }
    // C153176-5630 : Onclose Client dialog box.
    else if (option === 'editClient') {
      this.clientContactForm.get('emailAddr').setValue(null);
      this.editClientAddr = false;
    }
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Sidebar - Top contact cancel", "Click", "Sidebar - Top contact cancel", 0);
  }

  filterGroups(event: any) {
    this.filteredGroups = [];
    for (let i = 0; i < this.myGroups.length; i++) {
      let obj = this.myGroups[i];
      let grp = obj.label;
      if (grp.toLowerCase().indexOf(event.query.toLowerCase()) != "-1") {
        this.filteredGroups.push(grp);
      }
    }
  }

  closeModal(_str: any) {
    if (_str == "success") { this.displaySuccess = false; }
    if (_str == "error") { this.displayError = false; }

  }
  getGroupMemberList(groupDetail: AllActiveGroup): void {
    //Jira : 5002 : Multiple API call issue 
    if (groupDetail.toggle) {
      let groupMemberList = [];
      let groupData = {
        'groupName': groupDetail.text
      };
      //Jira : 5002 : the deleted email is reappearing in to/cc/bcc on click of resolve or add contact
      this.userDataService.getGroupMemberList(groupData).subscribe(groupMemberDetails => {
        if (groupMemberDetails) {
          groupMemberDetails.forEach(memberContact => {
            groupMemberList.push({ groupName: groupData.groupName, name: memberContact.userName, email: memberContact.email });
          });
        }
        this.loading = false; /*C153176-4229 add to top contact */
        this.groupMembers = [];
        this.groupMembers = groupMemberList;
        this.allActiveGroups.forEach((el) => {
          el.toggle = (el.text === groupDetail.text) ? false : true;
        });
      });
    }
    groupDetail.toggle = !groupDetail.toggle;
    console.log('lastthis.allActiveGroups: ', this.allActiveGroups);
  }

  toggleItem(item: any) {
    item.toggle = !item.toggle;
    this.loading = !this.loading; /*C153176-4229 add to top contact */
  }

  deleteTopContact() {
    let editIndex = this.editTopContactForm.get('index').value;
    this.topContacts = this.topContacts.filter(function (contact) {
      return (contact.index != editIndex);
    });

    this.updateContactList = this.topContacts;
    let topContact = new TopContacts(this.updateContactList, this.userGroupIdList);
    this.newMessageService.saveTopContact(topContact).subscribe(result => {
      if (result.processingstatus === "true") {
        this.populateFavoriteContacts(this.groupName);
        this.displayCreateNew = false;
        this.displaySuccess = true;
        this.displayError = false;
        this.displayEdit = false;
        this.cancelTopContact('edit');
        this.editTopContactForm.reset();
      }
    },
      (error) => {
        this.displayCreateNew = false;
        this.displaySuccess = false;
        this.displayError = true;
        this.displayEdit = false;
        this.cancelTopContact('edit');
        this.editTopContactForm.reset();
      }
    );
  }

  trackById(index: number, item: any) {
    return item.id;
  }

  /**
   * Method to edit the client email address.
   */
  onEditClient(client: any): void {
    this.editClientAddr = true;
    this.currClientInfo = { ...client }
    this.clientContactForm.get('emailAddr').setValue(client.email);
  }

  /**
   * Method to update the group recipient email.
   * 
   * @param client 
   */
  onEmailSubmit(client: any): void {
    let updatedEmail = this.clientContactForm.get('emailAddr').value;
    client.email = updatedEmail;
    client.isDelete = false;

    this.groupService.updateGroupRecipient(client).subscribe(response => {
      if (response !== null) {
        this.editClientAddr = false;
        let idx = this.clientContacts.findIndex(cnct => cnct.name === client.name && cnct['groupId'] === client.groupId);

        let updatedClient = { ...client };
        updatedClient.name = updatedEmail;
        this.clientContacts[idx] = updatedClient;
      }
    }, error => {
      this.editClientAddr = false;
      console.error(error);
    });
  }

  /**
   * Method to delete the client contact from the group.
   * 
   * @param client 
   */
  onDelete(client: any): void {
    let updatedEmail = this.clientContactForm.get('emailAddr').value;
    client.email = updatedEmail;
    client.isDelete = true;

    this.groupService.updateGroupRecipient(client).subscribe(response => {
      if (response !== null) {
        this.editClientAddr = false;
        this.clientContacts = this.clientContacts.filter(cnct => cnct.name !== client.name && cnct['groupId'] === client.groupId);
      }
    }, error => {
      this.editClientAddr = false;
      console.error(error);
    });
  }

  /**
   * Method to handle the tab change event.
   * @param e 
   */
  handleChange(e) {
    let index = e.index;
    if (2 == index && this.clientContacts.length == 0) {
      // Get the group client contacts.
      let clients = this.userDataService.getGroupClientContacts();
      this.clientContacts = _.uniq(clients, 'email'); // Get the unique contacts
      this.clientContacts = _.sortBy(this.clientContacts, function (i) { return i.email.toLowerCase(); }); // Sort the unique array by email
    }
    else if (1 == index && this.allActiveGroups.length == 0) {
      this.allActiveGroups = [...this.userDataService.getAllActiveGroups()];
    }
  }
   // C170665-1172 While Saving New Contact - Although Success message Popped up,still Contact not saved in QMA and backend
   // Uniqueness of contact names on group level instead entire contact collection
  // i.e 2 or more groups can have same contact name but within group duplicates not allowed
  getUniqueContacts(groupContacts) {
    return _.uniq(groupContacts, v => [v.email, v.sharedToGroupsName].join());
  }
  onPinPanel(){
    this.tabDataService.setSidePanelPin(true)
  }
  changeTab(tabName:string){
    this.contactsTabName =tabName;
    if ('INDIVIDUALS' == tabName && this.clientContacts.length == 0) {
      // Get the group client contacts.
      let clients = this.userDataService.getGroupClientContacts();
      this.clientContacts = _.uniq(clients, 'email'); // Get the unique contacts
      this.clientContacts = _.sortBy(this.clientContacts, function (i) { return i.email.toLowerCase(); }); // Sort the unique array by email
    }
    else if ('GROUPS' == tabName && this.allActiveGroups.length == 0) {
      this.allActiveGroups = [...this.userDataService.getAllActiveGroups()];
    }
  }
  getInitials(name){
    if(typeof name === 'string'){
      const nameParts = name.trim().split(" ");
      let initials;
      if (nameParts.length > 1) {
        initials = nameParts[0][0] + nameParts[1][0];
      } else {
        initials = nameParts[0][0];
      }
      return initials;
    }
    }
    
}

