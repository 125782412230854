import { Component, OnInit, ViewChild, ViewChildren, QueryList, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { UserDataService } from '../../services/user-data.service';
import { ExpTinyComponent } from 'src/app/common/component/exp-tiny/exp-tiny.component';
import { QmaConstant } from "src/app/constant/qma-constant";
import { OverlayPanel } from "primeng//overlaypanel";
import { ProfileSettingService } from "src/app/services/profile-setting/profile-setting.service";
import { UserPreferences } from "src/app/model/user-profile/userPreferences";
import { UserSignature } from "src/app/model/LoginUserInfo/UserSignature";
import { InquiryTemplate } from "src/app/model/user-profile/inquiryTemplate";
import { GroupRole } from "src/app/model/LoginUserInfo/GroupRole";
import { Preference } from "src/app/model/LoginUserInfo/Preference";
import { TabView } from "primeng/tabview";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { LoginUserInfo } from "src/app/model/LoginUserInfo/LoginUserInfo";
import { DomSanitizer } from "@angular/platform-browser";
import { OOOSettings } from "src/app/model/user-profile/oooSettings";
import { DatePipe } from "@angular/common";
import { AllToCcDBUser } from '../../model/LoginUserInfo/AllToCcDBUser';
import { GetContactsService } from '../../services/newMessage/get-contacts.service';
import { AppUtils } from '../../common/utility/appUtil';
import { NotificationSetting } from "src/app/model/LoginUserInfo/notificationSetting";
import { InqTemplateShareToGrp } from "src/app/model/user-profile/inqTemplateShareToGrp";
import * as _ from 'underscore';
import { AllActiveGroup } from "src/app/model/LoginUserInfo/AllActiveGroup";
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { Module } from "@ag-grid-community/core";
import { PiwikProUtils } from 'src/app/common/utility/piwikProUtils';
import { NewMessageService } from 'src/app/services/newMessage/new-message.service';
import { start } from 'repl';
import { TaskizeAuthService } from 'src/app/services/taskize-auth.service';

interface dropDownItem {
  name: string,
  value: string
}

// User Prferences Constants
const CONVERSATION_VIEW_ID: string = "conversationViewId";
const DEFAULT_FROM_ID: string = "defaultFromId";
const GROUP_LEVEL_VIEW_ID: string = "groupLevelViewId";
const DATE_FORMATTER_SELECT_ID: string = "dateFormatterSelectId";
const DISCLAIMER_NEW_INQUIRY_ID: string = "disclaimerNewInquiryId";
const DISCLAIMER_REPLY_FWD_ID: string = "disclaimerReplyFwdId";
const SIGNATURE_NEW_INQUIRY_ID: string = "signatureNewInquiryId";
const SIGNATURE_REPLY_FWD_ID: string = "signatureReplyFwdId";
const EDITOR_FONT_NAME_ID: string = "editorFontNameId";
const EDITOR_FONT_SIZE_ID: string = "editorFontSizeId";
const VIEW_ROW_COUNT: string = "viewRowCount";
const QUICK_REPLY_TEMPLATE: string = "quickReplyTemplateId";

// Signature Message
const SAVE_SUCCESS: string = " Signature saved successfully.";
const DEL_SUCCESS: string = " Signature successfully deleted.";

// Template Message
const TEMP_SAVE_SUCCESS: string = " template saved successfully.";
const TEMP_DEL_SUCCESS: string = " template successfully deleted.";

// Unasaved Messages
const SIGN_MSG: string = "Save or Cancel the signature before submit the profile.";
const TMPT_MSG: string = "Save or Cancel the template before submit the profile.";

// Waring Message
const OUT_OF_OFFICE: string = 'Out of office set up in your profile will make all your \n assigned inquiries to unassigned.';
const SIGN_INQ: string = ' has Include in new inquiry turned on. Do you want to replace?';
const SIGN_RPLY: string = ' has Reply/Reply All/Forward turned on. Do you want to replace?';
const TMPT_QUICK_RPLY: string = ' has quick reply turned on. Do you want to replace?';

//Info Message
const QUICK_REPLY_INFO_MSG: string = 'This template will be available as a quick response option in the conversation.'

// Valid Email Regular Expression
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// HTTPS_PLACEHODER
const HTTPS_PLACEHODER: string = 'https://';
const EWS_EXCHANGE_ENDPOINT: string = "/ews/exchange.asmx";
const MERGE_RECIPIENTS: string = "mergeRecipients ";
@Component({
  selector: 'app-profile-setting',
  templateUrl: './profile-setting.component.html',
  styleUrls: ['./profile-setting.component.scss']
})
export class ProfileSettingComponent implements OnInit {
  modules: Module[] = [ClientSideRowModelModule];

  showSpinner: boolean = false;
  showIcons: boolean = false;
  showIconsCheckBox: boolean = true;
  showTempIcons: boolean = false;
  showTempIconsCheckBox: boolean = true;

  filteredToUsersMultiple: any[] = [];
  allToCcDBUser: AllToCcDBUser[] = [];
  allActiveGroups: AllActiveGroup[] = [];
  toUsersList: AllToCcDBUser[] = [];
  ccUsersList: AllToCcDBUser[] = [];
  groupNamesList: any[];
  shareGroupList: any[] = [];
  delTempGrpList: string[] = [];
  filteredGroups: any;

  public entitlementColumnDefs;
  public subjectWordColumnDefs;
  public subjectWordCheckbox;
  public gridApi;
  public gridColumnApi;
  public errorMessage;

  // Let the parent know that profile is submitted successfully.
  @Output() onSubmit = new EventEmitter<boolean>();

  @Input('showFlag') showFlag: any;
  @ViewChild('profileTabs', { static: true }) profileTabs: TabView;
  @ViewChildren(ExpTinyComponent) editorList: QueryList<ExpTinyComponent>;

  // Field Properties
  profilePic: any;
  userName: string;
  email: string;
  defaultGroupList: dropDownItem[] = [];
  conversationViewList: dropDownItem[] = [];
  // C170665-1280 Navigation : Additional user level preference option
  // to set font from profile settings
  tinyMCEFontFamilyList: dropDownItem[] = [];
  tinyMCEFontSizeList: dropDownItem[] = [];
  dateFormatList: dropDownItem[] = [];
  groupLevelViewList: dropDownItem[] = [];
  groupRoles: GroupRole[] = [];
  signatures: UserSignature[] = [];
  userTemplateList: InquiryTemplate[] = [];
  subjectFlagwords: any[] = [];
  exchangeRegionList: dropDownItem[] = [];

  // Profile Setting Reactive Form
  profileSettingForm: FormGroup;
  signatureForm: FormGroup;
  templateForm: FormGroup;

  loggedInUser: LoginUserInfo;
  preferenceMap = new Map();

  // Editor Contents
  signatureContent: string = '';
  templateContent: string = '';
  oooContent: string = '';
  reqTmpltConent: boolean = false;

  // Messages
  showSignatureMsg: boolean = false;
  signatureMsg: string = '';
  showTemplateMsg: boolean = false;
  templateMsg: string = '';
  saveSignMsg: string = '';
  saveTmptMsg: string = '';

  // Save flag for template and Signature
  updatedSign: string = '';
  updatedTemplate: string = '';
  saveSign: boolean = false;
  saveTemplate: boolean = false;

  // Submit flag
  isSubmitted: boolean = false;

  // Save or Modify Action for template and Signature
  signAction: string = '';
  templateAction: string = '';

  // Confirmation popup.
  showPopUp: boolean = false; // Flag to show or hide
  confirmMsgText: string;
  actionFieldName: string;

  // Tab Indx
  actTabIdx: number = 0;
  actTabName: string = 'PREFERENCES';

  // Calender Minumum date
  minimumDate: Date = new Date();
  isOOO: boolean = true;

  // Info Message
  quickReplyMsg: string = QUICK_REPLY_INFO_MSG;

  // Symphony Enable for Application.
  isSymphonyEnabled: boolean = false;
  isTaskizeEnabled: boolean = false

  // Personal - Subject flagwords
  subDeleteFlag: boolean = true;

  // Enable personal setting
  isPersonalEnable: boolean = false; // Disable for Production release.

  extRecipients = [];
  hasErrors = false;
  constructor(private fb: FormBuilder, public userDataService: UserDataService, private profileSettingService: ProfileSettingService, private sanitizer: DomSanitizer,
    private datePipe: DatePipe, private getContactsService: GetContactsService, private newMessageService: NewMessageService, private taskizeService: TaskizeAuthService) {
    this.getUserProfileDetails();
    this.initialData();
  }

  ngOnInit() {
    this.profileSettingForm = this.fb.group({
      // Preferences
      defaultGroup: [''],
      conversationView: [''],
      groupLevelDefaultView: [''],
      dateFormat: [''],
      rowCount: [''], // Row count is not required.
      sendSecureEmail: [false],
      fitToMyResolution: [false],
      disableAutoComplete: [false],
      attachmentPwdProtectionFlag: [false],
      disclaimerInq: [false],
      disclaimerRply: [false],
      enableSymphony: [false],
      enableTaskize: [false],

      // Notification
      esclation: [false],
      approval: [false],
      snooze: [false],
      followUp: [false],
      symphony: [false],
      autoAssign: [false],

      // Signature
      inInquiry: [false],
      inReply: [false],

      // Template
      quickReply: [false],

      // Out of Office
      startDt: [''],
      endDt: [''],
      outOfOffice: [false],
      notifyManager: [false],

      // Personal
      personalEmailSubscribed: [false],
      calendarSubscribed: [false],
      exchUrl: [''],
      exchDomain: [''],
      exchPassword: [''],
      exchPasswordIV: [''],
      // C170665-1280 Navigation : Additional user level preference option
      // to set font from profile settings
      defaulttinyMCEFontFamily: [''],
      defaulttinyMCEFontSize: ['']
    });

    this.signatureForm = this.fb.group({
      defaultSignature: [''],
      newSignature: ['', Validators.required]
    });

    this.templateForm = this.fb.group({
      defaultTemplate: [''],
      newTemplate: ['', [Validators.required, Validators.maxLength(50)]],
      toList: [[]],
      ccList: [[]],
      subject: [''],
      shareToGrpList: [[]],
      mergeRecipients: [false]
    });

    this.getUserPreferences(this.loggedInUser.preferences);
    this.setPreferences();
    this.setNotification();
    this.setOutOfOffice();
    this.setSignature();
    this.setTemplates();
    this.entitlementColumnDefs = this.getEntitlementColumnDefs();

    this.setPersonal();
    this.subjectWordColumnDefs = this.getSubjectColumnDefs();
    this.subjectWordCheckbox = this.getCheckBoxDef();

    // On form values changes
    this.templateForm.get('newTemplate').valueChanges.subscribe(() => {
      this.reqTmpltConent = false;
    })
  }

  // convenience getter for easy access to form fields
  get psf() { return this.profileSettingForm.controls; }

  // convenience getter for easy access to form fields
  get sf() { return this.signatureForm.controls; }

  // convenience getter for easy access to form fields
  get tf() { return this.templateForm.controls; }

  ngOnChanges(changes: SimpleChanges): void {
    const currentItem = changes.showFlag;
    if (currentItem.currentValue)
      setTimeout(() => this.initAutoResponseContent());
  }

  initialData(): void {
    this.getContactsService.getContact().subscribe((item) => {
      let pushContact = new AllToCcDBUser();
      if (item[0]) {
        pushContact.text = item[0].name;
        pushContact.value = item[0].email;
        this.pushToTo(pushContact, item[1]);
      }
    });
  }

  /**
   * Method to get the user profile details.
   */
  getUserProfileDetails(): void {
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
      this.loggedInUser = loginUserInfo;
      this.userName = loginUserInfo.userName;
      this.email = loginUserInfo.email;

      let userPic = loginUserInfo.profilePic;
      if (userPic) {
        this.profilePic = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + userPic);
      }

      // Default Groups
      this.defaultGroupList.push({ name: 'None', value: "" });
      loginUserInfo.myGroups.forEach(group => {
        this.defaultGroupList.push({ name: group.groupName, value: "" + group.id });
        this.shareGroupList.push({ text: group.groupName, value: "" + group.id });
      });

      // Populate the template list
      this.getUserTemplates(loginUserInfo);

      // Conversation View
      QmaConstant.conversationView.forEach(view => {
        this.conversationViewList.push({ name: view, value: view });
      });

      // Editor font family list
      this.tinyMCEFontFamilyList.push({ name: QmaConstant.tinyMCEFontsNotSelected, value: "" });
      this.userDataService.getEditorFontStyle().map((val) => {
        this.tinyMCEFontFamilyList.push({ name: val, value: val });
      });
      // editor font size list
      this.tinyMCEFontSizeList.push({ name: QmaConstant.tinyMCEFontsNotSelected, value: "" });
      this.userDataService.getEditorFontSize().forEach(ftSize => {
        this.tinyMCEFontSizeList.push({ name: ftSize, value: ftSize });
      });
      // Date formates
      loginUserInfo.dateFormatterSelectItemList.forEach(dateFmt => {
        this.dateFormatList.push({ name: dateFmt, value: dateFmt });
      });

      // CC and BCC DB users - Get To,CC,BCC users list
      loginUserInfo.allToCcDBUsers.forEach(toCcBccUsers => {
        this.allToCcDBUser.push(toCcBccUsers);
      });

      //Get All active groups
      loginUserInfo.allActiveGroups.forEach(activeGroup => {
        this.allActiveGroups.push(activeGroup);
      });


      this.groupRoles = loginUserInfo.groupRoles;
      this.signatures = loginUserInfo.userSignatures || [];
      this.groupLevelViewList.push({ name: "Group Level", value: "Y" });

      // Symphony Enable for application
      let symphonyEnititled = this.userDataService.getSymphonyEntitlement();
      if (loginUserInfo.symphonyConfig && loginUserInfo.symphonyConfig.symphonyEnabled && symphonyEnititled) {
        this.isSymphonyEnabled = loginUserInfo.symphonyConfig.symphonyEnabled && symphonyEnititled;
      } else {
        this.isSymphonyEnabled = false;
      }

      // Enable or Disbale Personal Setting
      this.isPersonalEnable = loginUserInfo.personalEmailSubscribed ? loginUserInfo.personalEmailSubscribed : false;

      // Exchange Region
      this.exchangeRegionList.push({ name: 'Select', value: '' });
      QmaConstant.exhangeRegions.forEach(region => {
        this.exchangeRegionList.push({ name: region, value: region });
      });

      if (this.subjectFlagwords.length == 0 && (loginUserInfo.subjectFlagwords == null || (loginUserInfo.subjectFlagwords && loginUserInfo.subjectFlagwords.length == 0))) {
        this.subjectFlagwords.push({ 'flagword': 'pm-', 'flagwordId': 'pm-' });
      }
    });
  }

  /**
   * Method to populate the userTemplateList.
   *
   * @param loggedInUser
   */
  getUserTemplates(loginUserInfo: LoginUserInfo): void {
    loginUserInfo.myGroups.forEach(group => {
      if (group && group.templates) {
        group.templates.forEach(template => {
          if (template.templateName) {
            let temp = this.userTemplateList.find(tmplt => tmplt.templateName === template.templateName);
            if (temp) {
              Array.prototype.push.apply(temp.shareToGrpList, template.shareToGrpList);
            } else {
              let templateDataObj = {
                name: template.templateName,
                value: template.templateName,
                templateName: template.templateName,
                templateContent: template.templateContent,
                templateSubject: template.templateSubject,
                toList: template.toList,
                ccList: template.ccList,
                shareToGrpList: template.shareToGrpList,
                mergeRecipients: template.mergeRecipients
              };
              this.userTemplateList.push(templateDataObj);
            }
          }
        });
      }
    });

    let templateList = loginUserInfo.userTemplateList || [];
    templateList.forEach(template => {
      let templateDataObj = {
        name: template.templateName,
        value: template.templateName,
        templateName: template.templateName,
        templateContent: template.templateContent,
        templateSubject: template.templateSubject,
        toList: template.toList,
        ccList: template.ccList,
        shareToGrpList: template.shareToGrpList,
        mergeRecipients: template.mergeRecipients === null ? true : template.mergeRecipients
      };
      this.userTemplateList.push(templateDataObj);
    })

    //Code to remove duplicate templates coming from shared groups.
    if (this.userTemplateList.length > 1) {
      this.userTemplateList = _.uniq(this.userTemplateList, 'name');
    }
    // Sort the template array by name
    this.userTemplateList = _.sortBy(this.userTemplateList, function (i) { return i.name.toLowerCase(); });
  }


  /**
   * Method to populate user details from user preferences
   */
  getUserPreferences(userPreferences: Preference[]): void {
    if (userPreferences) {
      userPreferences.map(pref => {
        this.preferenceMap.set(pref.key, pref.value);
      });
    }
  };

  /**
   *  Method to set Preferences on page load
   */
  setPreferences(): void {

    // Default Group
    let groupName = this.defaultGroupList.find(grp => grp.value === this.preferenceMap.get(DEFAULT_FROM_ID));
    if (this.defaultGroupList.length === 1) {
      groupName = this.defaultGroupList[1];
    }
    // When the group name is undefined.
    groupName = groupName !== undefined ? groupName : this.defaultGroupList[0];
    this.profileSettingForm.get('defaultGroup').setValue(groupName);

    // Conversation View
    let convView = this.conversationViewList.find(conv => conv.value === this.preferenceMap.get(CONVERSATION_VIEW_ID));
    if (!convView) {
      convView = this.conversationViewList[0];
    }
    this.profileSettingForm.get('conversationView').setValue(convView);

    // editor font family
    let defaultEditorFontFamily = this.tinyMCEFontFamilyList.find(conv => conv.value === this.preferenceMap.get(EDITOR_FONT_NAME_ID));
    if (!defaultEditorFontFamily) {
      defaultEditorFontFamily = this.tinyMCEFontFamilyList[0];
    }
    this.profileSettingForm.get('defaulttinyMCEFontFamily').setValue(defaultEditorFontFamily);

    // editor font Size
    let defaultEditorFontSize = this.tinyMCEFontSizeList.find(conv => conv.value === this.preferenceMap.get(EDITOR_FONT_SIZE_ID));
    if (!defaultEditorFontFamily) {
      defaultEditorFontSize = this.tinyMCEFontSizeList[0];
    }
    this.profileSettingForm.get('defaulttinyMCEFontSize').setValue(defaultEditorFontSize);



    //Group Level View
    let grpView = this.groupLevelViewList.find(glv => glv.value === this.preferenceMap.get(GROUP_LEVEL_VIEW_ID));
    if (!grpView) {
      grpView = this.groupLevelViewList[0];
    }
    this.profileSettingForm.get('groupLevelDefaultView').setValue(grpView);

    // Date format
    let dateFrmt = this.dateFormatList.find(df => df.value === this.preferenceMap.get(DATE_FORMATTER_SELECT_ID))
    if (!grpView) {
      dateFrmt = this.dateFormatList[0];
    }
    this.profileSettingForm.get('dateFormat').setValue(dateFrmt);

    // Row Count
    this.profileSettingForm.get('rowCount').setValue(this.preferenceMap.get(VIEW_ROW_COUNT));
    this.profileSettingForm.get('sendSecureEmail').setValue(this.loggedInUser.sendSecureEmail);
    this.profileSettingForm.get('fitToMyResolution').setValue(this.loggedInUser.fitToMyResolution);
    this.profileSettingForm.get('disableAutoComplete').setValue(this.loggedInUser.disableAutoComplete);
    this.profileSettingForm.get('attachmentPwdProtectionFlag').setValue(this.loggedInUser.attachmentPwdProtectionFlag);

    let inqDisclaimer = false;
    if (this.preferenceMap.get(DISCLAIMER_NEW_INQUIRY_ID)) {
      inqDisclaimer = this.preferenceMap.get(DISCLAIMER_NEW_INQUIRY_ID) === 'No' ? false : true;
    }
    let rplyDisclaimer = false;
    if (this.preferenceMap.get(DISCLAIMER_REPLY_FWD_ID)) {
      rplyDisclaimer = this.preferenceMap.get(DISCLAIMER_REPLY_FWD_ID) === 'No' ? false : true;
    }
    this.profileSettingForm.get('disclaimerInq').setValue(inqDisclaimer);
    this.profileSettingForm.get('disclaimerRply').setValue(rplyDisclaimer);

    // Enable or Disable Symphony flag.
    let enableSymphony = this.loggedInUser.isSymphonyEnabledForUser ? this.loggedInUser.isSymphonyEnabledForUser == 'Y' : false;
    this.profileSettingForm.get('enableSymphony').setValue(enableSymphony);

    //Enable or Disable Taskize Flag
    let enableTaskize = this.loggedInUser.isTaskizeEnabledForUser ? this.loggedInUser.isTaskizeEnabledForUser?.toUpperCase() == 'Y' : false;
    this.profileSettingForm.get('enableTaskize').setValue(enableTaskize);
  }

  /**
   * Method to set notification properties
   */
  setNotification(): void {
    let notificationSettings = this.loggedInUser.notificationSettings;
    let esclation = notificationSettings ? notificationSettings.escalation : false;
    let approval = notificationSettings ? notificationSettings.approvals : false;
    let snooze = notificationSettings ? notificationSettings.snooze : false;
    let followUp = notificationSettings ? notificationSettings.followUp : false;
    let symphony = notificationSettings ? notificationSettings.symphony : false;
    let autoAssign = notificationSettings ? notificationSettings.autoAssign : false;

    this.profileSettingForm.get('esclation').setValue(esclation);
    this.profileSettingForm.get('approval').setValue(approval);
    this.profileSettingForm.get('snooze').setValue(snooze);
    this.profileSettingForm.get('followUp').setValue(followUp);
    this.profileSettingForm.get('symphony').setValue(symphony);
    this.profileSettingForm.get('autoAssign').setValue(autoAssign);
  }

  /**
   * Set Out of Office properties
   */
  setOutOfOffice(): void {
    const outOfOfficeSettings = this.loggedInUser.outOfOfficeSettings;
    let startDate = '';
    let endDate = '';
    let template = '';
    let notifyManager: boolean = false;
    // This indicate the OOO flag in UI that populates from outOfOfficeSettings. It is different from loginuser->outOfOffice.
    // loginuser-> outOfOffice becomes true when current date lies n between outOfOfficeSettings-> start and end date. Only when  outOfOfficeSettings-> outOfOffice is enabled.
    let outOfOffice: boolean = false;

    if (outOfOfficeSettings) {
      startDate = AppUtils.getFormattedDate(outOfOfficeSettings.fromDate);
      endDate = AppUtils.getFormattedDate(outOfOfficeSettings.toDate);

      let today = new Date();
      today.setHours(0, 0, 0, 0);
      template = outOfOfficeSettings.responseTemplate;
      notifyManager = outOfOfficeSettings.notifyMyManager;
      outOfOffice = outOfOfficeSettings.outOfOffice;
      this.isOOO = !(outOfOffice);
    }
    this.profileSettingForm.get('startDt').setValue(startDate);
    this.profileSettingForm.get('endDt').setValue(endDate);
    this.profileSettingForm.get('notifyManager').setValue(notifyManager);
    this.profileSettingForm.get('outOfOffice').setValue(outOfOffice);

    this.oooContent = template;
  }

  /**
   * Set Signature properties
   */
  setSignature(): void {
    let defaultSign = this.signatures.find(sign => sign.name === this.preferenceMap.get(SIGNATURE_NEW_INQUIRY_ID));
    if (!defaultSign) {
      defaultSign = this.signatures[0];
    }
    // Populate Signature if any
    if (defaultSign) {
      this.signatureForm.get('defaultSignature').setValue(defaultSign);
      this.signatureContent = defaultSign.content;

      // Update action to inquiry and reply/forward
      this.updateSignatureOptions(defaultSign.name, false);
    }
  }

  /**
   * Method to set default template on page load.
   */
  setTemplates(): void {
    // First template should be default template
    const defaultInqTemplate: InquiryTemplate = this.userTemplateList[0];
    if (defaultInqTemplate) {
      this.populateTemplate(defaultInqTemplate, true);

      // Update Quick reply option
      this.updateQuickReplyOption(defaultInqTemplate.templateName, false);
    }
  }

  /**
   * Method to populate the editor content.
   */
  initAutoResponseContent() {
    let exp: ExpTinyComponent;
    this.editorList.forEach(div => {
      if (div.elementId == "signature") {
        exp = div;
        exp.setData(this.signatureContent);
      }
      if (div.elementId == "template") {
        exp = div;
        exp.setData(this.templateContent);
      }
      if (div.elementId == "out-of-office") {
        exp = div;
        exp.setData(this.oooContent);
      }
    });
  }

  /**
   * Method to get the Editor instance.
   *
   * @param id - Editor element id
   */
  getEditorInstance(id: string): ExpTinyComponent {
    let exp: ExpTinyComponent;
    this.editorList.forEach(div => {
      if (div && div.elementId == id)
        exp = div;
    });
    return exp;
  }

  /**
   * Method to capture tab change event
   * @param event : tab event
   */
  onTabChange(event) {
    this.actTabName = this.profileTabs.tabs[event.index].header;
    this.actTabIdx = event.index;
    // [C170665-3196] Regression issue Template- the 1st template on the template screen is does not display the recipients.
    if (this.actTabName && typeof this.actTabName === "string" && this.actTabName.toUpperCase() === "TEMPLATES") {
      this.onTemplateChange();
    }
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Profile Settings", "Profile Settings - " + this.actTabName, "Click", "Profile Settings - " + this.actTabName, 0);
  }

  /**
   * Method to get the Entitlements column definitions
   */
  getEntitlementColumnDefs(): any {
    let columnDefs = [
      {
        colId: 'groupName',
        field: "groupName",
        headerName: 'Group',
        width: 300,
        editable: false
      },
      {
        colId: 'groupRole',
        field: "groupRole",
        headerName: 'Role',
        width: 400,
        editable: false
      }
    ];

    return columnDefs;
  }

  /**
   * Method called on click of save signature.
   */
  onSaveSignature(): void {
    let name = this.signatureForm.value.newSignature;
    const signEditor = this.getEditorInstance("signature");
    let content = signEditor.getData();
    let signVal: boolean = true;

    // If newly create, check duplicate
    let isDuplicate: boolean = this.checkDuplicateSignature(name);

    // Show Error messages
    this.saveSign = true;
    let newSignature = this.signatureForm.get("newSignature");
    if (!isDuplicate && !newSignature.errors) {

      let sign = this.signatures.find(s => s.name === name);
      if (sign) {
        console.log(name, ' is updated');
        sign.content = content;
      } else {
        let signature: UserSignature = {
          name: name,
          content: content,
          hasInlineImage: signVal
        };
        console.log(name, ' is new created');
        this.signatures.push(signature);

        // When user rename the signature, we will delete from the signature list
        if (this.updatedSign) {
          this.signatures = this.signatures.filter(s => s.name !== this.updatedSign);
        }
      }
      this.saveSignature(name, true);
    }
    else if (isDuplicate) {
      console.log('The signature name is already used. Please enter a different name!');
      newSignature.setErrors({ duplicate: true });
    }
  }

  /**
   * Method called on click of delete signature.
   */
  onDeleteSignature(): void {
    let name = this.signatureForm.value.defaultSignature.name;

    // Update action to inquiry and reply/forward
    this.updateSignatureOptions(name, true);
    this.signatures = this.signatures.filter(sign => sign.name !== name);
    this.saveSignature(name, false);
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Profile Settings", "Profile Settings - Delete Signature", "Click", "Profile Settings - Delete Signature", 0);
  }

  /**
   * Method to save user signature.
   *
   * @param name - Signature Name
   * @param oper - Operation = Delete - flase, New/Modify - true
   */
  saveSignature(name: string, oper: boolean): void {
    this.profileSettingService.saveSignatureData(this.signatures).subscribe(result => {
      if (result.processingstatus) {
        console.log('Signatures is saved successfully');
        this.resetSignature();
        this.showSignatureMessage(name, oper);
        this.loggedInUser.userSignatures = this.signatures;
      } else {
        console.log('Fail to save signature');
      }
    }, error => {
      throw new Error('Fail to save signature due to' + error);
    });
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Profile Settings", "Profile Settings - Save Signature", "Click", "Profile Settings - Save Signature", 0);
  }

  /**
   * Method to update signature screen on change of signature.
   */
  onSignatureChange(): void {
    let name = this.signatureForm.value.defaultSignature.name;
    let content = this.signatureForm.value.defaultSignature.content;

    const signEditor = this.getEditorInstance("signature");
    signEditor.setData(content);

    this.updateSignatureOptions(name, false);
  }

  /**
   * Method to update Include signature in new inquiry and reply/forward actions
   *
   * @param signatureName - Signature Name
   * @param isDeleteOpr - true for delete annd false for load.
   */
  updateSignatureOptions(signatureName: string, isDeleteOpr: boolean): void {
    let perfdSignInq = this.preferenceMap.get(SIGNATURE_NEW_INQUIRY_ID) === signatureName;
    let perfdSignRplyFwd = this.preferenceMap.get(SIGNATURE_REPLY_FWD_ID) === signatureName;

    if (isDeleteOpr) {
      let includeInInquiry: boolean = this.profileSettingForm.get('inInquiry').value;
      let includeInRplyFwd: boolean = this.profileSettingForm.get('inReply').value;

      // Set Signature for new inquiry switch button
      if (perfdSignInq && includeInInquiry) {
        this.profileSettingForm.get('inInquiry').setValue(false);
      }

      // Set Signature for for reply and forward switch button
      if (perfdSignRplyFwd && includeInRplyFwd) {
        this.profileSettingForm.get('inReply').setValue(false);
      }
    }
    else {
      // Set Signature for new inquiry switch button
      let selInq = perfdSignInq ? true : false;
      this.profileSettingForm.get('inInquiry').setValue(selInq);

      // Set Signature for for reply and forward switch button
      let selRplyFwd = perfdSignRplyFwd ? true : false;
      this.profileSettingForm.get('inReply').setValue(selRplyFwd);
    }
  }

  /**
   * Method to handle template values changes
   */
  onTemplateChange(): void {
    const selectedTemplate: InquiryTemplate = this.templateForm.get('defaultTemplate').value;
    this.populateTemplate(selectedTemplate, false);

    // Update Quick reply option
    this.updateQuickReplyOption(selectedTemplate.templateName, false);
  }

  /**
   * Method to update the create or modify template screen
   */
  onCreateOrUpdateTemplate(action: string): void {
    this.showTempIcons = true;
    this.showTempIconsCheckBox = false;

    let inquiryTemplate: InquiryTemplate = null;
    if (action === 'create') {
      inquiryTemplate = new InquiryTemplate();
      inquiryTemplate.templateContent = '';
      this.templateForm.get('newTemplate').setValue('');
      // for new template screen keep mergeRecipients as true by default
      inquiryTemplate.mergeRecipients = true;
      this.templateForm.get('mergeRecipients').setValue(true);
    }
    else if (action === 'modify') {
      inquiryTemplate = this.templateForm.get('defaultTemplate').value;
      this.updatedTemplate = inquiryTemplate.templateName;
      this.templateForm.get('newTemplate').setValue(inquiryTemplate.templateName);
    }
    this.templateAction = action;
    this.populateTemplate(inquiryTemplate, false);
  }

  /**
   * Method to reset the signature screen view.
   */
  resetTemplate(isDel: boolean): void {
    this.showTempIcons = false;
    this.showTempIconsCheckBox = true;
    this.saveTmptMsg = '';
    this.updatedTemplate = ''; // reset template name.

    // Hide message
    this.saveTemplate = false;
    let newSign = this.templateForm.get('newTemplate');
    newSign.setErrors({ duplicate: false });

    let inquiryTemplate: InquiryTemplate = this.templateForm.get('defaultTemplate').value;
    // Check if default template is updated. If update get the updated template
    let updatedTemplate = this.userTemplateList.filter(t => inquiryTemplate.templateName === t.templateName);
    if (updatedTemplate && updatedTemplate.length > 0) {
      inquiryTemplate = updatedTemplate[0];
    }
    inquiryTemplate = inquiryTemplate !== null ? inquiryTemplate : this.userTemplateList[0];
    if (isDel) {
      let defaultTmpt: InquiryTemplate = {
        templateName: '',
        templateContent: '',
        templateSubject: ''
      };
      inquiryTemplate = this.userTemplateList.length > 0 ? this.userTemplateList[0] : defaultTmpt;
    }

    if (inquiryTemplate !== null) {
      this.populateTemplate(inquiryTemplate, false);

      // Update Quick reply option
      this.updateQuickReplyOption(inquiryTemplate.templateName, isDel);
    }
  }

  /**
   * Method to populate template screen.
   *
   * @param inqTemplate - Selected Tempalte
   * @param isInit - is called while initialization of component
   */
  populateTemplate(inqTemplate: InquiryTemplate, isInit: boolean): void {
    if (inqTemplate.templateName) {
      this.templateForm.get('defaultTemplate').setValue(inqTemplate);
    }

    this.templateForm.get('toList').setValue(inqTemplate.toList);
    this.templateForm.get('ccList').setValue(inqTemplate.ccList);
    this.templateForm.get('subject').setValue(inqTemplate.templateSubject);
    this.templateForm.get('mergeRecipients').setValue(inqTemplate.mergeRecipients === null ? true : inqTemplate.mergeRecipients);
    //Code to remove duplicate templates coming from shared groups.
    let shareToGrpList: string[] = [];
    if (inqTemplate.shareToGrpList && inqTemplate.shareToGrpList.length > 0) {
      inqTemplate.shareToGrpList.forEach(shrdGrp => {
        shareToGrpList.push(shrdGrp.value);
      });
      if (shareToGrpList.length > 1) {
        shareToGrpList = _.uniq(shareToGrpList);
      }
      inqTemplate.shareToGrpList = _.uniq(inqTemplate.shareToGrpList, 'value');
    }
    this.delTempGrpList = shareToGrpList;
    this.templateForm.get('shareToGrpList').setValue(inqTemplate.shareToGrpList);

    if (isInit) {
      this.templateContent = inqTemplate.templateContent;
    } else {
      const templateEditor = this.getEditorInstance("template");
      templateEditor.setData(inqTemplate.templateContent);
    }
  }

  /**
  * Method to update Include template for quick reply actions
  *
  * @param templateName - Template Name
  * @param isDeleteOpr - true for delete annd false for load.
  */
  updateQuickReplyOption(templateName: string, isDeleteOpr: boolean): void {
    let perfdTmpltInq = this.preferenceMap.get(QUICK_REPLY_TEMPLATE) === templateName;

    if (isDeleteOpr) {
      let quickRply: boolean = this.profileSettingForm.get('quickReply').value;

      // Set Template for quick reply switch button
      if (perfdTmpltInq && quickRply) {
        this.profileSettingForm.get('quickReply').setValue(false);
      }
    }
    else {
      // Set Template for quick reply switch button
      let selTmpt = perfdTmpltInq ? true : false;
      this.profileSettingForm.get('quickReply').setValue(selTmpt);
    }
  }

  /**
   * Method to Save inquiry template.
   */
  onSaveTemplate(): void {
    const template: InquiryTemplate = this.getInquiryTemplate(false);
    this.saveTemplate = true;
    const isDuplicate: boolean = this.checkDuplicateTemplate(template.templateName);
    let newTemplate = this.templateForm.get("newTemplate");

    // Template conent should be required
    this.reqTmpltConent = false;
    if (!template.templateContent) {
      console.log('Please enter content!!');
      this.reqTmpltConent = true;
    }

    if (!isDuplicate && !newTemplate.errors && !this.reqTmpltConent) {
      this.profileSettingService.saveInquiryTemplate(template).subscribe(result => {
        if (result) {
          console.log('Inquiry template is saved successfully');

          // Remove the template which is updated
          if (this.updatedTemplate) {
            this.userTemplateList = [...this.userTemplateList.filter(t => t.templateName !== this.updatedTemplate)];
          }
          this.userTemplateList.push(template);
          this.userTemplateList = _.sortBy(this.userTemplateList, function (i) { return i.name.toLowerCase(); });

          this.resetTemplate(false);
          this.showTemplateMessage(template.templateName, true);
        } else {
          console.log('Fail to save Inquiry template');
        }
      }, error => {
        throw new Error('Fail to save Inquiry template due to' + error);
      });
    }
    else if (isDuplicate) {
      console.log('The template name is already used. Please enter a different name!');
      newTemplate.setErrors({ duplicate: true });
    }
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Profile Settings", "Profile Settings - Save Tempalte", "Click", "Profile Settings - Save Template", 0);
  }

  /**
  * Method to delete inquiry template.
  */
  onDeleteTemplate(): void {
    const template: InquiryTemplate = this.getInquiryTemplate(true);
    if (template.inqTemplateShareToGrp.length > 0) {
      const msg: string = 'Are you sure you want to delete template which is shared across multiple groups?';
      this.setConfirmation('delSharedTemplate', msg);
    } else {
      this.deleteTemplate(template);
    }
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Profile Settings", "Profile Settings - Delete Template", "Click", "Profile Settings - Delete Template", 0);
  }

  /**
   * Method to delete template via service.
   */
  deleteTemplate(template: InquiryTemplate): void {
    this.profileSettingService.deleteInquiryTemplate(template).subscribe(() => {
      console.log('Inquiry template is deleted successfully');
      this.userTemplateList = this.userTemplateList.filter(tmplt => tmplt.templateName !== template.templateName);
      this.resetTemplate(true);
      this.showTemplateMessage(template.templateName, false);
    }, error => {
      throw new Error('Fail to delete Inquiry template due to' + error);
    });
  }

  /**
   * Method to get template object.
   *
   * @param isdel - true for delete request
   */
  getInquiryTemplate(isdel: boolean): InquiryTemplate {
    const templateValues = this.templateForm.value;
    let name = templateValues.defaultTemplate.templateName;
    if (!isdel) {
      name = templateValues.newTemplate.trim();
    }

    const templateEditor = this.getEditorInstance("template");
    let content = templateEditor.getData();

    let inqTempGrpList: InqTemplateShareToGrp[] = [];
    let inqTempGrpValueList: string[] = [];
    if (templateValues.shareToGrpList) {
      templateValues.shareToGrpList.forEach(grp => {
        let inqTempGrp: InqTemplateShareToGrp = {
          text: grp.text,
          value: grp.value,
          groupTemplate: true
        };
        inqTempGrpList.push(inqTempGrp);
        inqTempGrpValueList.push(grp.value);
      });
    }

    let template: InquiryTemplate = {
      name: name,
      value: name,
      templateName: name,
      templateContent: content,
      templateSubject: templateValues.subject,
      inqTemplateShareToGrp: inqTempGrpList,
      shareToGrpList: templateValues.shareToGrpList,
      mergeRecipients: templateValues.mergeRecipients

    };
    if (isdel) {
      template.inqTemplateTo = templateValues.toList || [];
      template.inqTemplateCc = templateValues.ccList || [];
    } else {
      let deleteGroupList = [];
      if (this.delTempGrpList && this.delTempGrpList.length > 0 && inqTempGrpList.length > 0) {
        this.delTempGrpList.forEach(grpId => {
          if (inqTempGrpValueList.indexOf(grpId) == -1) {
            deleteGroupList.push(grpId);
          }
        });
      }
      template.toList = templateValues.toList || [];
      template.ccList = templateValues.ccList || [];
      template.delTempGrpList = deleteGroupList;
    }
    return template;
  }

  /**
   * Method to check whether the newly created template is already exist.
   * @param templateName - Template Name
   */
  checkDuplicateTemplate(templateName: string): boolean {
    let isDuplicate: boolean = false;

    // Check Duplicate
    let isCreatedNew: boolean = false;
    if (this.templateAction === 'create') {
      isCreatedNew = true;
    }
    if (isCreatedNew) {
      let indx: number = this.userTemplateList.findIndex(tmplt => tmplt.templateName === templateName);
      if (indx > -1) {
        console.log('Template is already exist');
        isDuplicate = true;
      }
    }
    return isDuplicate;
  }

  /**
   * Method to check whether the newly created signature is already exist.
   * @param signatureName - signature Name
   */
  checkDuplicateSignature(signatureName: string): boolean {
    let isDuplicate: boolean = false;

    // Check Duplicate
    let isCreatedNew: boolean = false;
    if (this.signAction === 'create') {
      isCreatedNew = true;
    }
    if (isCreatedNew) {
      let indx: number = this.signatures.findIndex(sign => sign.name === signatureName);
      if (indx > -1) {
        console.log('Signature is already exist');
        isDuplicate = true;
      }
    }
    return isDuplicate;
  }

  /**
   * Methad to save the user profile on click of save.
   */
  onSaveUserProfile(): void {
    const profileValues = this.profileSettingForm.value;
    let userPrefs: Preference[] = this.getSelectedPreferences(profileValues);
    let oooSetting: OOOSettings = this.getOOOSettings(profileValues);
    let notifications: NotificationSetting = this.getNotificationSettings(profileValues);
    let isSymphonyEnabledForUser: string = profileValues.enableSymphony ? "Y" : "N";
    let isTaskizeEnabledForUser: string = profileValues.enableTaskize ? "Y" : "N"
    let subjectFlagwords: string[] = this.getSubjectFlagwordsList();
    let exchangeHost: string = profileValues.exchUrl;
    if (exchangeHost) {
      exchangeHost = exchangeHost.includes(HTTPS_PLACEHODER) ? exchangeHost : HTTPS_PLACEHODER.concat(exchangeHost);
      exchangeHost = exchangeHost.includes(EWS_EXCHANGE_ENDPOINT) ? exchangeHost : exchangeHost.concat(EWS_EXCHANGE_ENDPOINT);

    }
    const userPreferences: UserPreferences = {
      preference: userPrefs,
      name: this.userName,
      email: this.email,
      outOfOffice: this.loggedInUser.outOfOffice,
      sendSecureEmail: profileValues.sendSecureEmail,
      fitToMyResolution: profileValues.fitToMyResolution,
      disableAutoComplete: profileValues.disableAutoComplete,
      attachmentPwdProtectionFlag: profileValues.attachmentPwdProtectionFlag,
      notifications: notifications,
      outOfOfficeSettings: oooSetting,
      isSymphonyEnabledForUser: isSymphonyEnabledForUser,
      isTaskizeEnabledForUser: isTaskizeEnabledForUser,
      isPersonalEmailSubscribed: profileValues.personalEmailSubscribed,
      isCalendarSubscribed: profileValues.calendarSubscribed,
      exchDomain: profileValues.exchDomain.value,
      exchUrl: exchangeHost,
      exchPassword: (profileValues.exchPassword) ? btoa(profileValues.exchPassword) : '',
      exchPasswordIV: (profileValues.exchPasswordIV) ? btoa(profileValues.exchPasswordIV) : '',
      subjectFlagwords: subjectFlagwords,
    };
    console.log('Saved: ' + JSON.stringify(userPreferences));

    let isProfileValid: boolean = this.validateUserProfile();

    if (isProfileValid) {
      // If click on save button then loader will come
      this.showSpinner = true;
      console.debug(":SPINNER:: " + this.showSpinner + " ::profile-setting.onSaveUserProfile");
      this.profileSettingService.saveUserDetail(userPreferences).subscribe(result => {
        if (result.processingstatus) {
          // Spinner will be close
          this.showSpinner = false;
          console.debug(":SPINNER:: " + this.showSpinner + " ::profile-setting.onSaveUserProfile");
          console.log('User Profile is saved successfully');

          this.loggedInUser.preferences = userPreferences.preference;
          this.loggedInUser.outOfOffice = userPreferences.outOfOffice;
          this.loggedInUser.sendSecureEmail = userPreferences.sendSecureEmail;
          this.loggedInUser.fitToMyResolution = userPreferences.fitToMyResolution;
          this.loggedInUser.disableAutoComplete = userPreferences.disableAutoComplete;
          this.loggedInUser.attachmentPwdProtectionFlag = userPreferences.attachmentPwdProtectionFlag;
          this.loggedInUser.notificationSettings = userPreferences.notifications;
          this.loggedInUser.userSignatures = this.signatures;
          this.loggedInUser.userTemplateList = this.userTemplateList;
          this.loggedInUser.outOfOfficeSettings = userPreferences.outOfOfficeSettings; // Update the changes on success.
          this.loggedInUser.isSymphonyEnabledForUser = userPreferences.isSymphonyEnabledForUser;
          this.loggedInUser.isTaskizeEnabledForUser = userPreferences.isTaskizeEnabledForUser;
          // Personal Section
          this.loggedInUser.calendarSubscribed = userPreferences.isCalendarSubscribed;
          this.loggedInUser.personalEmailSubscribed = userPreferences.isPersonalEmailSubscribed;
          this.loggedInUser.exchPassword = userPreferences.exchPassword;
          this.loggedInUser.exchPasswordIV = userPreferences.exchPasswordIV;
          this.loggedInUser.exchUrl = userPreferences.exchUrl;
          this.loggedInUser.exchDomain = userPreferences.exchDomain;
          this.loggedInUser.subjectFlagwords = userPreferences.subjectFlagwords;
          this.userDataService.setLoginUserInfo(this.loggedInUser);

          // Profile is submitted successfully
          this.onSubmit.emit(true);
        } else {
          console.log('Fail To Save User Data Please Contact support');
        }
      }, error => {
        throw new Error('Fail To Save User Data Please Contact support' + error);
      });
    }
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Profile Settings", "Profile Settings - Submit", "Click", "Profile Settings - Submit", 0);
  }

  /**
   * Method to update the create or modify signature screen
   */
  onCreateOrUpdate(action: string): void {
    this.showIcons = true;
    this.showIconsCheckBox = false;

    const signEditor = this.getEditorInstance("signature");
    if (action === 'create') {
      this.signatureForm.get('newSignature').setValue('');
      signEditor.setData('');
    }
    else if (action === 'modify') {
      this.updatedSign = this.signatureForm.get('defaultSignature').value.name;
      this.signatureForm.get('newSignature').setValue(this.updatedSign);
    }
    this.signAction = action;
  }

  /**
   * Method to reset the signature screen view.
   */
  resetSignature(): void {
    this.showIcons = false;
    this.showIconsCheckBox = true;
    this.saveSignMsg = '';
    this.updatedSign = '';  // Reset the updated signature.

    // Hide message
    this.saveSign = false;
    let newSignature = this.signatureForm.get("newSignature");
    newSignature.setErrors({ duplicate: true });

    let defaultSign = this.signatures.find(sign => sign.name === this.preferenceMap.get(SIGNATURE_NEW_INQUIRY_ID));
    if (!defaultSign) {
      defaultSign = this.signatures[0];
    }
    this.signatureForm.get('defaultSignature').setValue(defaultSign);
    const signEditor = this.getEditorInstance("signature");
    const content = defaultSign ? defaultSign.content : "";
    signEditor.setData(content);

    const name = defaultSign ? defaultSign.name : "";
    this.updateSignatureOptions(name, false);
  }

  /**
   * Method to populate the selected user preferences and return it.
   *
   * @param profileValues : Selected form values
   */
  getSelectedPreferences(profileValues: any): Preference[] {
    let sign = '';
    if (this.signatureForm.value && this.signatureForm.value.defaultSignature) {
      sign = this.signatureForm.value.defaultSignature.name ? this.signatureForm.value.defaultSignature.name : sign;
    }
    // C170665-672 QMA signature issue where 2nd signature does not work start
    let inquirySignature = this.profileSettingForm.get('inInquiry').value ? sign :
      this.preferenceMap.get(SIGNATURE_NEW_INQUIRY_ID) ? this.preferenceMap.get(SIGNATURE_NEW_INQUIRY_ID) : '';
    let rplyFwdSignature = this.profileSettingForm.get('inReply').value ? sign :
      this.preferenceMap.get(SIGNATURE_REPLY_FWD_ID) ? this.preferenceMap.get(SIGNATURE_REPLY_FWD_ID) : '';
    // C170665-672 QMA signature issue where 2nd signature does not work end
    let tmplte = '';
    if (this.templateForm.value && this.templateForm.value.defaultTemplate) {
      tmplte = this.templateForm.value.defaultTemplate.templateName ? this.templateForm.value.defaultTemplate.templateName : tmplte;
    }
    let quickReplyTmplte = this.profileSettingForm.get('quickReply').value ? tmplte : '';

    const inqDisclaimer = this.profileSettingForm.get('disclaimerInq').value ? 'Yes' : 'No';
    const rplyDisclaimer = this.profileSettingForm.get('disclaimerRply').value ? 'Yes' : 'No';

    let preferences: Preference[] = [];
    preferences.push({ key: DEFAULT_FROM_ID, value: profileValues.defaultGroup.value });
    preferences.push({ key: CONVERSATION_VIEW_ID, value: profileValues.conversationView.value });
    preferences.push({ key: GROUP_LEVEL_VIEW_ID, value: profileValues.groupLevelDefaultView.value });
    preferences.push({ key: DATE_FORMATTER_SELECT_ID, value: profileValues.dateFormat.value });
    preferences.push({ key: DISCLAIMER_NEW_INQUIRY_ID, value: inqDisclaimer });
    preferences.push({ key: DISCLAIMER_REPLY_FWD_ID, value: rplyDisclaimer });
    preferences.push({ key: SIGNATURE_NEW_INQUIRY_ID, value: inquirySignature });
    preferences.push({ key: SIGNATURE_REPLY_FWD_ID, value: rplyFwdSignature });
    /* preferences.push({ key: EDITOR_FONT_NAME_ID, value: this.preferenceMap.get(EDITOR_FONT_NAME_ID) || '' }); */
    // C170665-1280 Navigation : Additional user level preference option
    // to set font from profile settings
    preferences.push({
      key: EDITOR_FONT_NAME_ID, value: profileValues && profileValues.defaulttinyMCEFontFamily
        && profileValues.defaulttinyMCEFontFamily.value
        && QmaConstant.tinyMCEFontsNotSelected !== profileValues.defaulttinyMCEFontFamily.value ?
        profileValues.defaulttinyMCEFontFamily.value : ''
    });
    /*  preferences.push({ key: EDITOR_FONT_SIZE_ID, value: this.preferenceMap.get(EDITOR_FONT_SIZE_ID) || '' }); */
    preferences.push({
      key: EDITOR_FONT_SIZE_ID, value: profileValues && profileValues.defaulttinyMCEFontSize
        && profileValues.defaulttinyMCEFontSize.value
        && QmaConstant.tinyMCEFontsNotSelected !== profileValues.defaulttinyMCEFontSize.value ?
        profileValues.defaulttinyMCEFontSize.value : ''
    });
    preferences.push({ key: VIEW_ROW_COUNT, value: profileValues.rowCount });
    preferences.push({ key: QUICK_REPLY_TEMPLATE, value: quickReplyTmplte });

    return preferences;
  }

  /**
   * Method to get the out of office settings.
   *
   * @param profileValues : Selected form values
   */
  getOOOSettings(profileValues: any): OOOSettings {

    let startDate = '';
    let endDate = '';

    if (profileValues.startDt) {
      startDate = this.datePipe.transform(profileValues.startDt, 'MM/dd/yyyy');
    }

    if (profileValues.endDt) {
      endDate = this.datePipe.transform(profileValues.endDt, 'MM/dd/yyyy');
    }

    let responseTemplate = this.getEditorInstance("out-of-office").getData();
    let notifyMyManager = profileValues.notifyManager;

    const oooSetting: OOOSettings = {
      outOfOffice: profileValues.outOfOffice, // Add new attribute
      fromDate: startDate,
      toDate: endDate,
      responseTemplate: responseTemplate,
      notifyMyManager: notifyMyManager
    };
    return oooSetting;
  }

  /**
   * Method to get the notification settings.
   *
   * @param profileValues : Selected form values
   */
  getNotificationSettings(profileValues: any): NotificationSetting {
    const notificationSettings: NotificationSetting = {
      escalation: profileValues.esclation,
      approvals: profileValues.approval,
      snooze: profileValues.snooze,
      followUp: profileValues.followUp,
      symphony: profileValues.symphony,
      autoAssign: profileValues.autoAssign
    }
    return notificationSettings;
  }

  /**
   * Method to show Signature message for few seconds.
   *
   * @param name - Signature Name
   * @param oper - Operation = Delete - flase, New/Modify - true
   */
  showSignatureMessage(name: string, oper: boolean): void {
    let signName = "(" + name + ")";
    let msg = oper ? SAVE_SUCCESS : DEL_SUCCESS;

    this.signatureMsg = signName + msg;
    this.showSignatureMsg = true;

    setTimeout(() => {
      this.showSignatureMsg = false;
      this.signatureMsg = '';
    }, 4000);
  }

  /**
   * Method to filter the user based on entered text.
   * @param event - Entered Text
   */
  filterUsers(event) {
    let query = event.query;
    let filtered = [];

    this.allToCcDBUser.forEach(record => {
      if ((record.text.toLowerCase().indexOf(query.toLowerCase()) >= 0) || (record.id.toLowerCase().indexOf(query.toLowerCase()) >= 0)) {
        filtered.push(record);
      }
    });

    // Check for groups too.
    this.allActiveGroups.forEach(group => {
      if (group.text.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(group);
      }
    });

    this.filteredToUsersMultiple = filtered;
  }

  /**
   * Method to populate TO and CC fields
   *
   * @param item - User
   * @param field - TO or CC
   */
  pushToTo(item: AllToCcDBUser, field: string) {
    switch (field) {
      case 'to':
        if (!this.isDuplicate(item, 'to'))
          this.toUsersList.push(item);
        break;
      case 'cc':
        if (!this.isDuplicate(item, 'cc'))
          this.ccUsersList.push(item);
        break;
    }
  }

  /**
   * Method to identify the duplicate user pased in TO or CC fields.
   * @param item - Entered User
   * @param toCcBcc - TO or CC
   */
  isDuplicate(item, toCcBcc) {
    let found = false;
    switch (toCcBcc) {
      case 'to':
        this.toUsersList.forEach(element => {
          if (JSON.stringify(element) === JSON.stringify(item)) {
            found = true;
          }
        });
        break;
      case 'cc':
        this.ccUsersList.forEach(element => {
          if (JSON.stringify(element) === JSON.stringify(item)) {
            found = true;
          }
        });
        break;
    }
    return found;
  }

  /**
   * Key press event.
   *
   * @param event
   * @param field
   */
  onKeyUp(event: KeyboardEvent, field) {
    let extEmail: boolean = false;
    if (event.key === "Enter") {
      let tokenInput = event.srcElement as any;
      let email = tokenInput.value.trim();
      if (!Array.isArray(this.toUsersList)) {
        this.toUsersList = [];
      }
      // C170665-2004 Issue- Cannot add external id in CC field for template.
      // for external id only ccuser list becoming undefined so make it blank aaray so it will not fail on push
      if (!Array.isArray(this.ccUsersList)) {
        this.ccUsersList = [];
      }
      this.newMessageService.handleEmail(tokenInput, email, field, this);
    } else if (event && event.ctrlKey && (event.key === "k" || event.key == "K")) {
      let tokenInput = event.srcElement as any;
      let email = tokenInput.value.trim();
      extEmail = AppUtils.isExternalEmailInToCc(email, this.userDataService.loggedInUserInfo.citiDomainList);
      this.newMessageService.resolveFullDisplayName(field, this);
    }

  }

  /**
   * Add External Email.
   *
   * @param tokenInput
   * @param tempEmail
   * @param field
   */
  handleEmail(tokenInput, tempEmail, field) {
    this.filteredToUsersMultiple = [];
    tempEmail = tempEmail.split(';');
    let invalid = [], email = [];
    email = tempEmail.filter(value => {
      value = value.trim();

      // test and extract email from <foo@bar.com>
      if (/\<([^\>]+)\>/g.test(value)) {
        let tempArr = /\<([^\>]+)\>/g.exec(value);
        value = tempArr[1];
      }

      // If User enter soeid who has access to QMA application
      const appUser = this.allToCcDBUser.find(user => user.id === value);
      if (appUser) {
        return true;
      }

      // Push invalid email or soied to display invalid message.
      if (value !== "" && !EMAIL_REGEX.test(value)) {
        invalid.push(value);
        return false;
      } else {
        return true;
      }
    });
    if (invalid.length > 0) {
      tokenInput.value = "";
    }

    if (email.length) {
      email.forEach(element => {
        element = element.trim();
        if (/\<([^\>]+)\>/g.test(element)) {
          let tempArr = /\<([^\>]+)\>/g.exec(element);
          element = tempArr[1];
        }
        if (element !== "") {
          let item = new AllToCcDBUser();

          // If User enter soeid who has access to QMA application
          const appUser = this.allToCcDBUser.find(user => user.id === element);
          if (appUser) {
            item = { ...appUser };
          } else {
            item = { text: element, value: element, email: element, id: '', active: false };
          }

          switch (field) {
            case "to":
              if (!this.isDuplicate(item, 'to')) {
                this.toUsersList.push(item);
              }
              this.templateForm.get('toList').patchValue(this.toUsersList);
              tokenInput.value = "";
              break;
            case "cc":
              if (!this.isDuplicate(item, 'cc')) {
                this.ccUsersList.push(item);
              }
              this.templateForm.get('ccList').patchValue(this.ccUsersList);
              tokenInput.value = "";
              break;
          }
        }
      });
    }
  }

  /**
   *
   *
   * @param value
   */
  contactTextStyle(value): string {
    if (!isNaN(+value)) {
      return "bold";
    } else if (this.isExternalEmailInToCc(value)) {
      return "external";
    } else {
      return "";
    }
  }

  /**
   * Method to check if the entered email is an external.
   *
   * @param email - Entered Email Id
   */
  isExternalEmailInToCc(email: any): boolean {
    let isExternal: boolean = false;
    if (!AppUtils.isUndefinedOrNull(email)) {
      // If User enter soeid who has access to QMA application
      let emailList = email.split(';');
      emailList.forEach(emailValue => {
        emailValue = emailValue.trim();
        const appUser = this.allToCcDBUser.find(user => user.id === emailValue);
        if (emailValue && !AppUtils.isCitiDomainEmail(emailValue, this.loggedInUser.citiDomainList) && !appUser) {
          isExternal = true;
        }
      });
    }
    return isExternal;
  }

  /**
   * Method to find the entered group from the group assigned to loggedin user.
   * @param event - Entered text.
   */
  filterGroups(event: any) {
    this.filteredGroups = [];
    let query = event.query;

    this.shareGroupList.forEach(group => {
      if (group.text.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        this.filteredGroups.push(group);
      }
    });
  }

  /**
   * Method to handle the on-off action of include inquiry switch.
   *
   * @param e  - Event
   */
  onChangeInquiry(e: any): void {
    const isChecked = e.checked;
    if (isChecked) {
      const name = this.signatureForm.get('defaultSignature').value.name;
      const prefSignInq = this.preferenceMap.get(SIGNATURE_NEW_INQUIRY_ID);
      const prefSignInqIdx = this.signatures.findIndex(sign => sign.name === prefSignInq);

      if (prefSignInqIdx > -1 && prefSignInq && name !== prefSignInq) {
        const msg = '( ' + prefSignInq + ' )' + SIGN_INQ;
        this.setConfirmation('inInquiry', msg);
      } else {
        this.preferenceMap.set(SIGNATURE_NEW_INQUIRY_ID, name);
      }
    } else {
      this.preferenceMap.set(SIGNATURE_NEW_INQUIRY_ID, '');
    }
  }

  /**
  * Method to handle the on-off action of include reply action switch
  *
  * @param e  - Event
  */
  onChangeReplyOrFwd(e: any): void {
    const isChecked = e.checked;
    if (isChecked) {
      const name = this.signatureForm.get('defaultSignature').value.name;
      const prefSignRply = this.preferenceMap.get(SIGNATURE_REPLY_FWD_ID);
      const prefSignRplyIdx = this.signatures.findIndex(sign => sign.name === prefSignRply);

      if (prefSignRplyIdx > -1 && prefSignRply && name !== prefSignRply) {
        const msg = '( ' + prefSignRply + ' )' + SIGN_RPLY;
        this.setConfirmation('inReply', msg);
      } else {
        this.preferenceMap.set(SIGNATURE_REPLY_FWD_ID, name);
      }
    } else {
      this.preferenceMap.set(SIGNATURE_REPLY_FWD_ID, '');
    }
  }

  /**
 * Method to handle the on-off action for quick reply switch
 *
 * @param e  - Event
 */
  onChangeQuickReply(e: any): void {
    const isChecked = e.checked;
    if (isChecked) {
      let tmpltName = this.templateForm.get('defaultTemplate').value.templateName;
      const prefQuickRply = this.preferenceMap.get(QUICK_REPLY_TEMPLATE);
      const quickRplyIdx = this.userTemplateList.findIndex(template => template.name === prefQuickRply);

      if (quickRplyIdx > -1 && prefQuickRply && tmpltName !== prefQuickRply) {
        const msg = '( ' + prefQuickRply + ' )' + TMPT_QUICK_RPLY;
        this.setConfirmation('quickReply', msg);
      }
    }
  }


  /**
   * Method to show template message for few seconds.
   *
   * @param name - template Name
   * @param oper - Operation = Delete - flase, New/Modify - true
   */
  showTemplateMessage(name: string, oper: boolean): void {
    let templateName = "(" + name + ")";
    let msg = oper ? TEMP_SAVE_SUCCESS : TEMP_DEL_SUCCESS;

    this.templateMsg = templateName + msg;
    this.showTemplateMsg = true;

    setTimeout(() => {
      this.showTemplateMsg = false;
      this.templateMsg = '';
    }, 4000);
  }

  /**
  * Method to handle the on-off action for out of office switch
  *
  * @param e  - Event
  */
  onOutOfOffice(e: any): void {
    let isChecked = e.checked;
    if (isChecked) {
      this.setConfirmation('outOfOffice', OUT_OF_OFFICE);
    } else {
      this.isOOO = true;
    }
  }

  /**
   * Method to call on click of Ok in confirmation popup.
   *
   *  @param field  - Field name on which this popup executed
   */
  onConfirm(field: string): void {
    if ('outOfOffice' === field) {
      this.isOOO = false;
    }
    if ('inInquiry' === field) {
      let name = this.signatureForm.get('defaultSignature').value.name;
      this.preferenceMap.set(SIGNATURE_NEW_INQUIRY_ID, name);
    }
    if ('inReply' === field) {
      let name = this.signatureForm.get('defaultSignature').value.name;
      this.preferenceMap.set(SIGNATURE_REPLY_FWD_ID, name);
    }
    if ('quickReply' === field) {
      let tmpltName = this.templateForm.get('defaultTemplate').value.templateName;
      this.preferenceMap.set(QUICK_REPLY_TEMPLATE, tmpltName);
    }
    if ('delSharedTemplate' === field) {
      const template: InquiryTemplate = this.getInquiryTemplate(true);
      this.deleteTemplate(template);
    }
    this.resetConfirmation();
  }

  /**
   * Method to call on click of cancel in confirmation popup.
   *
   *  @param field  - Field name on which this popup executed
   */
  onCancel(field: string): void {
    if ('outOfOffice' === field) {
      this.profileSettingForm.get('outOfOffice').setValue(false);
    }
    if ('inInquiry' === field) {
      this.profileSettingForm.get('inInquiry').setValue(false);
    }
    if ('inReply' === field) {
      this.profileSettingForm.get('inReply').setValue(false);
    }
    if ('quickReply' === field) {
      this.profileSettingForm.get('quickReply').setValue(false);
    }
    this.resetConfirmation();
  }

  /**
   * Method to prepare and set the required values to confirmation pop.
   *
   * @param fieldName - Field name on which this popup should open
   * @param message - Message to show
   */
  setConfirmation(fieldName: string, message): void {
    this.showPopUp = true;
    this.actionFieldName = fieldName;
    this.confirmMsgText = message;
  }

  /**
   * Close and reset the confirmation popup.
   */
  resetConfirmation(): void {
    this.showPopUp = false;
    this.actionFieldName = '';
    this.confirmMsgText = '';
  }

  /**
   * Method to validate user profile before submit.
   * If any profile tab has error, it will redirect to that tab.
   * In case of create or modify Signature or template is in progress and user submit the profile.
   * It will show the message to save them first before submit.
   */
  validateUserProfile(): boolean {
    this.errorMessage = '';
    let isProfileValid: boolean = false;
    this.saveSignMsg = '';
    this.saveTmptMsg = '';
    this.isSubmitted = false;

    const rowCount = this.profileSettingForm.get('rowCount');
    const startDt = this.profileSettingForm.get('startDt');
    const endDt = this.profileSettingForm.get('endDt');
    const isOOO = this.profileSettingForm.get('outOfOffice').value;
    // Validate the OOO dates
    if (isOOO) {
      this.validateDate();
    }

    // Validate Re-password in Personal Section
    const exchPwdIV = this.profileSettingForm.get('exchPasswordIV');
    if (exchPwdIV) {
      this.validatePersonal();
    }

    // Navigate to Tabs if any error
    if (rowCount.errors) {
      this.actTabIdx = 0;
    }
    else if (this.showIcons) {
      this.actTabIdx = 2;
      this.saveSignMsg = SIGN_MSG;
    }
    else if (this.showTempIcons) {
      this.actTabIdx = 3;
      this.saveTmptMsg = TMPT_MSG;
    }
    else if (startDt.errors || endDt.errors) {
      this.isSubmitted = true;
      this.actTabIdx = 5;
    }
    else if (exchPwdIV.errors) {
      this.isSubmitted = true;
      this.actTabIdx = 6;
    }
    else {
      isProfileValid = true;
    }
    this.actTabName = this.profileTabs.tabs[this.actTabIdx].header;
    return isProfileValid;
  }

  /**
   * Method to validate Date when out of office is on.
   */
  validateDate(): void {
    this.hasErrors = false;
    let startDateCtrl = this.profileSettingForm.get('startDt');
    let endDateCtrl = this.profileSettingForm.get('endDt');

    //Jira : 4606 : The From and To field are not mandated when user sets OOO.
    if (startDateCtrl.value == '' || startDateCtrl.value == null) {
      startDateCtrl.setErrors({ required: true });
      return;
    }

    if (endDateCtrl.value == '' || endDateCtrl.value == null) {
      endDateCtrl.setErrors({ required: true });
      return;
    }

    let startDt = new Date(startDateCtrl.value);
    let endDt = new Date(endDateCtrl.value);


    //outOfOffice

    let today = new Date();
    let yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    if (startDt && startDt < yesterday) {
      startDateCtrl.setErrors({ prevDate: true });
      if (this.loggedInUser.outOfOffice) {
        this.hasErrors = true;
        this.errorMessage = `You are currently OOO. From date cannot be before today's date. Please modify From date to save. `;
      }
    }

    if (endDt && (endDt < startDt || endDt < yesterday)) {
      endDateCtrl.setErrors({ prevDate: true });
    }
    else if (startDt && endDt && startDt > endDt) {
      startDateCtrl.setErrors({ nextDate: true });
    }
  }

  /**
   * Method to reset the changes to user profile.
   */
  resetChanges(): void {
    this.profileSettingForm.reset();
    this.signatureForm.reset();
    this.templateForm.reset();

    this.setPreferences();
    this.setNotification();
    this.setOutOfOffice();
    this.resetSignature();
    this.resetTemplate(false);
    this.setPersonal();
  }

  /**
   * Method to show Info overlay
   */
  onShowInfo(event, overlaypanel: OverlayPanel): void {
    overlaypanel.toggle(event);
  }

  /**
   * Method to get the Subject flag words column definitions
   */
  getSubjectColumnDefs(): any {
    let columnDefs = [
      {
        colId: 'flagword',
        field: "flagword",
        headerName: 'SUBJECT FLAG WORDS',
        width: 500,
        cellEditor: 'agTextCellEditor',
        editable: (params) => (params.data.flagwordId == "0") ? true : false
      },
      {
        colId: '1',
        field: "flagwordId",
        hide: true,
        headerName: ''
      }
    ];
    return columnDefs;
  }

  /**
   * Method to check if first column.
   * @param params
   */
  isFirstColumn(params) {
    let displayedColumns = params.columnApi.getAllDisplayedColumns();
    return displayedColumns[0] === params.column;
  }

  /**
   * Method to get the check box column definition.
   */
  getCheckBoxDef(): any {
    let checkBoxDef = {
      width: 100,
      checkboxSelection: this.isFirstColumn,
      resizable: true,
      suppressMenu: true,
      suppressMovable: true,
      editable: true,
      cellStyle: { 'background-color': 'white', 'height': '41px' }
    };
    return checkBoxDef;
  }

  /**
   * Method when subject flag words frid is ready.
   *
   * @param params
   */
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

  /**
   * Method to check if row is selected and eligible for delete.
   */
  onSubjectCheck() {
    let selWords = this.gridApi.getSelectedRows();
    if (selWords.length == 0) {
      this.subDeleteFlag = true;
    } else {
      let defaultWord = selWords.filter(word => 'pm-' != word.flagword);
      this.subDeleteFlag = defaultWord.length > 0 ? false : true;
    }
  }

  /**
   * Call back method after new word added to grid.
   *
   * @param params
   */
  onSubjectValueChanged(params) {
    if (this.subjectFlagwords.length > 0) {
      this.subjectFlagwords = _.sortBy(this.subjectFlagwords, function (i) { return i.flagword.toLowerCase(); });
    }
  }

  /**
   * Method to add subject flag word to the grid.
   */
  addSubjectFlagwords(): void {
    if (this.subjectFlagwords.length === 0 || this.subjectFlagwords[0].flagword !== "start typing...") {
      this.subjectFlagwords.splice(0, 0, {
        flagword: "start typing...",
        flagwordId: "0"
      });

      this.gridApi.applyTransaction(this.subjectFlagwords)
      this.gridApi.setFocusedCell(0, "flagword");
      this.gridApi.startEditingCell({
        rowIndex: 0,
        colKey: "flagword"
      });
    }
  }

  /**
   * Method to delete the subject flagwords.
   */
  deleteSubjectFlagwords(): void {
    let selectedFlagwords = this.gridApi.getSelectedRows();
    this.gridApi.applyTransaction({ remove: selectedFlagwords });
    selectedFlagwords.forEach(element => {
      let idx = this.subjectFlagwords.map(function (x) { return x.flagword; }).indexOf(element.flagword);
      this.subjectFlagwords.splice(idx, 1);
    });
  }

  /**
   * Method to get the list of subject flag words.
   */
  getSubjectFlagwordsList(): string[] {
    let flagWords = [];

    this.subjectFlagwords.forEach(word => {
      if (word['flagword'] != "" && "start typing..." != word['flagword']) {
        if (flagWords.indexOf(word['flagword']) == -1) {
          flagWords.push(word['flagword']);
        }
      }
    });
    return flagWords;
  }

  /**
   * Method to set perosnal section properties
   */
  setPersonal(): void {
    let personalEmailSubscribed = this.loggedInUser.personalEmailSubscribed ? this.loggedInUser.personalEmailSubscribed : false;
    let calendarSubscribed = this.loggedInUser.calendarSubscribed ? this.loggedInUser.calendarSubscribed : false;
    let exchPassword = this.loggedInUser.exchPassword || '';
    let exchPasswordIV = this.loggedInUser.exchPasswordIV || '';
    let exchUrl = this.loggedInUser.exchUrl || '';
    let exchDomain = this.loggedInUser.exchDomain || QmaConstant[2];
    let subjectFlagwords = this.loggedInUser.subjectFlagwords || [];

    this.profileSettingForm.get('personalEmailSubscribed').setValue(personalEmailSubscribed);
    this.profileSettingForm.get('calendarSubscribed').setValue(calendarSubscribed);
    this.profileSettingForm.get('exchUrl').setValue(exchUrl);
    this.profileSettingForm.get('exchPassword').setValue(exchPassword);
    this.profileSettingForm.get('exchPasswordIV').setValue(exchPasswordIV);

    let domainIdx = this.exchangeRegionList.findIndex(item => item.value === exchDomain);
    domainIdx = domainIdx > -1 ? domainIdx : 0;
    this.profileSettingForm.get('exchDomain').setValue(this.exchangeRegionList[domainIdx]);

    if (subjectFlagwords && subjectFlagwords.length > 0) {
      subjectFlagwords.forEach(words => {
        this.subjectFlagwords.push({ 'flagword': words, 'flagwordId': words });
      });
      this.subjectFlagwords = _.sortBy(this.subjectFlagwords, function (i) { return i.flagword.toLowerCase(); });
    }
  }

  /**
   * Method to validate Personal.
   */
  validatePersonal(): void {
    let exhPwdCtrl = this.profileSettingForm.get('exchPassword');
    let exchPwdIVCtrl = this.profileSettingForm.get('exchPasswordIV');

    if (exhPwdCtrl.value !== exchPwdIVCtrl.value) {
      exchPwdIVCtrl.setErrors({ mustMatch: true });
      return;
    }
  }
  // Template: Template preference option for To/CC/BCC recipient
  onChangeMergerecipients(e: any) {
    const isChecked = e.checked;
    this.preferenceMap.set(MERGE_RECIPIENTS, isChecked);
  }
  // C170665-1883 Creating new or modify the template with external email it’s not creating in QMA
  onBlur(event: any, field) {
    if (!Array.isArray(this.toUsersList)) {
      this.toUsersList = [];
    }
    // C170665-2004 Issue- Cannot add external id in CC field for template.
    // for external id only ccuser list becoming undefined so make it blank aaray so it will not fail on push
    if (!Array.isArray(this.ccUsersList)) {
      this.ccUsersList = [];
    }
    //upon leaving the field, handle case of external email without breaking suggesstion handling
    let tokenInput = event.srcElement as any;
    let email = tokenInput.value.trim();
    // first check whether the current input match any suggestions
    let suggestions = this.getFilteredToCCUsers(email);
    if (!suggestions || !suggestions.length) {
      let extEmail = false;
      if (email) {
        extEmail = this.newMessageService.handleEmail(tokenInput, email, field, this);

      }
    }

    event.srcElement.value = "";
  }
  // Creating new or modify the template with external email it’s not creating in QMA
  getFilteredToCCUsers(query) {
    let filtered = [];
    // performance refactor
    if (this.allToCcDBUser.length === 0) {
      this.allToCcDBUser = this.userDataService.getAllToCcDBUsers();
    }
    if (this.allActiveGroups.length === 0) {
      this.allActiveGroups = this.userDataService.getAllActiveGroups();
    }

    this.allToCcDBUser.forEach(record => {
      if ((record.text.toLowerCase().indexOf(query.toLowerCase()) >= 0) || (record.id.toLowerCase().indexOf(query.toLowerCase()) >= 0)) {
        filtered.push(record);
      }
    });

    this.allActiveGroups.forEach(record => {
      if ((record.text.toLowerCase().indexOf(query.toLowerCase()) >= 0)) {
        filtered.push(record);
      }
    });

    //External Recipents for suggestion.
    if (this.extRecipients) {
      this.extRecipients.forEach(record => {
        if ((record.text.toLowerCase().indexOf(query.toLowerCase()) >= 0)) {
          filtered.push(record);
        }
      });
    }
    return filtered;
  }




}
