import { Component, OnInit, OnDestroy, Input, AfterContentInit, Output, 
        EventEmitter, ChangeDetectionStrategy, AfterViewInit} from '@angular/core';
import { NewMessageService } from '../services/newMessage/new-message.service';
import { UserDataService } from '../services/user-data.service';
import { ViewChild, ElementRef, SimpleChanges } from '@angular/core';

import { GetContactsService } from '../services/newMessage/get-contacts.service';
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { ExpTinyComponent } from '../common/component/exp-tiny/exp-tiny.component';
import { isNull } from 'util';
import { UserSignature } from "src/app/model/LoginUserInfo/UserSignature";
import { MyGroup } from "src/app/model/LoginUserInfo/MyGroup";
import * as $ from 'jquery';
import { TabDataService } from '../services/tab-data.service';
import { AppUtils } from '../common/utility/appUtil';
import  * as _ from 'underscore';
import { NewMessageRecipentComponent } from './new-message-recipent/new-message-recipent.component';
import { GroupAdminService } from '../services/group-admin/group-admin.service';
import { forkJoin, Subscription } from 'rxjs';
import { PiwikProUtils } from '../common/utility/piwikProUtils';
import { TaskizeAuthService } from '../services/taskize-auth.service';


@Component({
  selector: 'newMessageview-component',
  templateUrl: './new-message.component.html',
  styleUrls: ['./new-message.component.scss'],
  providers: [GetContactsService]
})
export class NewMessageComponent implements OnInit, OnDestroy, AfterContentInit,AfterViewInit {
 
  //this line is important to get or set data in tiny MCE
  //this child variable will be having instance of Editor...
  @ViewChild(ExpTinyComponent, { static: true }) child: ExpTinyComponent;

  // 'count' to maintain the instances for Dynamic "New Message" component
  @Input() count: number;
  countID = this.newMessageService.getIndex();
  formatFlg: boolean = false;
  @Input() showContactSidebar: boolean = true;
  @Input() fromNewMessage: boolean = false;
  @Input() horizontalStyle: boolean = false;
  template:any;
  @Input() messageInstance:any;
  @Input() isInlineReply:boolean = false;
  @Output() cancelInlineReply = new EventEmitter();
  @Output() expandEvent = new EventEmitter();
  @ViewChild('editorAppDiv') elementView: ElementRef;
 
  @ViewChild('wrapper', { static: true }) recipentWraper: ElementRef;

  @Input() inlineViewMode;
  // C153176-4475 skip_focus flag for enabling placeholder text
  @Input() skip_focus: boolean = false;
  
  postDataForAttachmentFileN:any;
  toBccData:any;
  secure: string ='';
  setResolveN : string ='';
  conversationObj:any;
  // Added Note from new-message-recipient
  notesList: string[] = [];
  inquiryId: number = null;
  tabName: string = undefined;

  // Default Group to logged in user.
  groupName: string;
  group : string;
  inlineImage : boolean = false;
  moreField: boolean = false;
  fieldName:String = "";
  templateDataArray:Array<any> = [];
  // CLC
  clc: any = {};
  @Output() dropInProcessing: boolean = false;
  @Output() newFormData = new EventEmitter();
  @Output() inlineReplySendData = new EventEmitter();
  selectedCLCTrades: any[] = []; //C153176-4594 : These denotes the selected trades which will be added to send request.
  // C153176-4683: inline reply scheduled event
  @Output() inlineReplyScheduledData = new EventEmitter();

  // C153176-4229 : Push Contact as recipients
  pushContact: any = null; 
  @Input() singleContact?: any;
  isMobile:boolean = false;
  
  // C153176-5746 : New contact added to Top Contact using right click.
  @Input() topContactAdded?: any;

  // C153176-5896 : Set the value of recipient is updated.
  recipientUpdated: boolean = false;
  // symphony changes
  @Input() symphonyData:any;

  //TODO:Performance :: We can directly use parent form instead of access the component and reduce the cyclic dependency.
  @ViewChild('newMessageRecipent') newMessageRecipent:NewMessageRecipentComponent;

  //TODO:Performance :: Calculate the time.
  startTime:any;
  formatToggle: Subscription;
  splitterChange: Subscription;
  @Input() inboxView: any;
  hasPrevAttach = null;
  originalHeight: number;
  taskizeEnabled: boolean;
  isTaskizeInquiry: boolean;

  constructor(private newMessageService: NewMessageService, 
    private userDataService: UserDataService, 
    private getContactsService: GetContactsService, 
    private builder: FormBuilder, 
    private tabService:TabDataService,
    private groupAdminService: GroupAdminService,
    private taskizeService : TaskizeAuthService) {
    let group: MyGroup = this.userDataService.getDefaultGroup();
    this.groupName = group ? group.groupName : '';
    this.isMobile = AppUtils.isMobileView();

    //TODO:Performance :: Start Time. 
    this.startTime = performance.now();
  }

  newMessageForm: FormGroup;
  offHeight; //= document.getElementsByClassName('tox-tinymce')[0].getBoundingClientRect().top;
  msgWraper; // = document.getElementsByClassName('tox-tinymce');
  ngOnInit() {
    let startTime = performance.now();
    this.isMobile = AppUtils.isMobileView();
    this.newMessageForm = this.builder.group({
      groupName: new FormControl(this.groupName, [Validators.required]),
      toUsers: new FormControl('', [Validators.required]),
      ccUsers: new FormControl('', []),
      bccUsers: new FormControl('', []),
      subject: new FormControl('', []),
      requestType: new FormControl('', [Validators.required]),
      inquirySource: new FormControl('', []),
      inquirySubStatus: new FormControl('', []),
      rootCause: new FormControl('', []),
      processingRegion: new FormControl('', []),
      note: new FormControl('', []),
      queryCount: new FormControl('', []),
      editorData: new FormControl('', []),
      gfcId: new FormControl('', []),
      gfcName: new FormControl('', []),
      gfpId: new FormControl('', []),
      gfpName: new FormControl('', []),
      skAccountNo: new FormControl('', []),
      branch: new FormControl('', []),
      attachments: new FormControl('', []),
      imageAttach : new FormControl(false, []),
      tags : new FormControl('', []),
      hasPrevAttach : new FormControl(false,[])
    });
    // C153176-2907 -subscribing for groupName for top contacts
    this.getContactsService.setGroupContact(this.groupName);

    //create node in serivce 
    this.newMessageService.addIndex();

    //subscribing to set 'flag' for Editor Toolbar 
     //TODO:Performance :: Unsubscribe the subscription that would reduce the memory leak.
    this.formatToggle = this.getContactsService.getFormatToggleFlag().subscribe(val => {
      this.formatFlg = val;
    });
    //TODO:Performance :: Unsubscribe the subscription that would reduce the memory leak.
    /*C153176-4649 called the resize method after split*/
    this.splitterChange = this.userDataService.getSplitterChange().subscribe(data =>{
      if(data){
        this.resize();
      }
    });
    this.templateDataArray = [];
    // symphony changes
    if (this.symphonyData) {
     this.populateSymphonyData(this.symphonyData);
    }
    this.userDataService.userUsageStats("New Message", "New Message").subscribe(result => console.log("StartTime:"+new Date()));
    PiwikProUtils.trackJsonDataForTab(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Tab", "New Message");
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Menu", "New Message", "Click","New Message",0);
    let endTime = performance.now();
    console.log(`QMA2.0 Performance :: New Message Component onint processing took : ${Math.ceil(endTime - startTime)} miliseconds`);
    if (this.userDataService.loggedInUserInfo.taskizeConfig && this.userDataService.loggedInUserInfo.taskizeConfig.taskizeEnabled && this.userDataService.loggedInUserInfo.isTaskizeEnabledForUser && this.userDataService.loggedInUserInfo.isTaskizeEnabledForUser?.toUpperCase() == 'Y') {
      this.taskizeEnabled = true
      this.isTaskizeInquiry = this.taskizeService.checkIsTaskizeInquiry()
    } else {
      this.taskizeEnabled = false
    }
  }

  getActionSignature() {
    const defautSignature: UserSignature = this.userDataService.getDefaultSignatureforNewInquiry();
    const sign = defautSignature ? defautSignature.content :'';
    return '<div id="signatureDiv"> ' + sign + '</div>';
  }
  getDisclaimerActionInquiry() {
    const disclaimer = this.userDataService.getDisclaimerForNewInquiry();
    return '<div id="disclaimerDiv"> ' + disclaimer + '</div>';
  }
  onEditorLoad() {
    let startTime = performance.now();
    $(this.recipentWraper.nativeElement).bind('resize', () => {
      this.resize();
    })

    /* 5403: Disable copy for tinyMCE */
    /* Disable copy for tinyMCE */
    if(this.isMobile){
      $("iframe").contents().find("*").on("cut copy",function(e) {        
        e.preventDefault();
        e.stopPropagation();
        e.originalEvent.clipboardData.clearData()
        return false;
     });

     /*5403: Disable copy for other elements*/
    $("*").on("cut copy",function(e) {        
      e.preventDefault();
      e.stopPropagation();
      e.originalEvent.clipboardData.clearData()
      return false;
   });
	}
   
    // Performance Improvement - We don't require to resize the new-message which already open on tab switch.
    //
    // this.tabService.onTabChange().subscribe(res => {
    //   console.log("FROM NEW MESSAGE :: " + res);
    //  setTimeout(() => {
    //     this.resize();
    //   }, 200); 
    // })
    this.resize();
    
    if (this.fromNewMessage) {
      // setting up default data 
       //Regarding C153176-5853 Editor white space is not handled when disclaimer is added to New message
      let temp = document.createElement('div');
      temp.innerHTML =this.getActionSignature();
      let sign="";
      let signatureContent=temp.textContent;
      if((signatureContent.trim().length==0)||(signatureContent===""))
      {
        sign=this.getActionSignature();
      }
      else{
        sign+="<div></div>"+this.getActionSignature() + "<br>";
      }
      this.child.setData("<br>" //C170665-1501 - Removed placeholder text
        + sign
        + this.getDisclaimerActionInquiry());
    }
    // set message instance to child editor
    this.child.messageInstance = this.messageInstance;
    this.deleteEligibleColumn('span.deleteColumnClc', 'td'); // Add action listener to CLC Column
    this.deleteEligibleColumn('span.deleteColumnNlp', 'th'); // Add action listener to NLP Column BAU
    this.deleteEligibleNLPColumn('strong.deleteColumnNlp', 'td'); // Add action listener to NLP Column
    this.deleteEligibleNLPRow('span.deleteRowNlp', 'tr'); // Add action listener to NLP Row
    if(this.symphonyData) {
      this.populateSyphonyConv(this.symphonyData);
    }

    if (this.isTaskizeInquiry && this.taskizeEnabled) {
      this.child.clearEditorForTaskize()
    }
    let endTime = performance.now();
    console.log(`QMA2.0 Performance :: New Message component onEditor Load func processing took : ${Math.ceil(endTime - startTime)} miliseconds`);
  }
  resize() {
    if(!$(this.recipentWraper.nativeElement).is(':visible')){return;}

    let EditorId = <string>this.child.getId();

    if(!EditorId) return;
    
    let tinyEditorHeightNode: any = document.getElementById(EditorId).nextSibling;
    if (!tinyEditorHeightNode) {
      return;
    }
    this.offHeight = tinyEditorHeightNode.getBoundingClientRect().top;
    let msgWrapers: any = document.getElementsByClassName('tox-tinymce');  
    let hight = 0;
    if(this.isInlineReply)
      hight = window.innerHeight - this.offHeight - 70; // for inline reply height
    else
      hight = window.innerHeight - this.offHeight - 52; // for new message height

    // if(this.fieldName === "moreField"){
    //   hight = this.moreField ? hight - 27 : hight + 27;
    //   if(this.isInlineReply)
    //     hight = this.moreField ? hight - 47 : hight + 47;
    // }
    // if(this.fieldName === "addNote"){
    //   hight = this.moreField ? hight - 144 : hight + 146;
    // }
    // if(this.fieldName === "attachment"){
    //   hight = this.moreField ? hight - 24 : hight + 29;
    // }
    /*C153176-4649 reset after resize*/

    this.userDataService.setSplitterChange(false);

    //C153176-3108 - In Mobile view height for Editor should not less than 300
    if(window.innerWidth < 768 && hight < 300)
      hight = 400;

    tinyEditorHeightNode.style.height = hight + "px";
  }  

  resizeBody(event){
    if(event.fileldFlag){     //c-4475 -newmessage mobile tinymce height&width
    this.moreField = event.fileldFlag;
    this.fieldName = event.fieldName;
    }
    setTimeout(() => {
      this.resize();
    }, 300); 
  }
  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //     event.target.innerHeight;
  //     this.resize();
  // }
  ngOnDestroy() {
    //distroy node from serivce 
    if (this.formatToggle) {
      this.formatToggle.unsubscribe();
    }
    if (this.splitterChange) {
      this.splitterChange.unsubscribe();
    }
  }

  setResolveEnquiry(data) {
    console.log('Resolve Enquiry in new message comp :' + data);
    this.newMessageService.setResolveEnquiry(data);
  }
  ngAfterContentInit() {
    ////subscribing to set 'Template Data to Editor' 
  /*  let s1 = this.getContactsService.getFormatTemplate().subscribe(template => {
     //let template = this.template;
      try {
        this.child.setData(template[0].templateContent);
      //  s1.unsubscribe();
      } catch (e) {
        console.log("error loading" + e);
      } 
   
    }); */
    //TODO:Performance :: unsubscribe s2 in destroy method.
    let s2 = this.getContactsService.getSignature().subscribe(sign => {
      try {
        this.child.appnedData(sign, "signatureDiv");
       // s2.unsubscribe();
      } catch (e) {
        console.log("error loading signatrue data" + e);
      }
     
    });
    /* let s3 = this.getContactsService.getBody().subscribe(body => {
      try {
        this.child.setData(body);
        //s3.unsubscribe();
      } catch (e) {
        console.log("error loading" + e);
      }
      
    }); */

    //TODO:Performance :: End Time. 
    let endTime = performance.now();
    console.log(`QMA2.0 Performance :: New Message loading : ${Math.ceil(endTime - this.startTime)} miliseconds, for group: ${this.groupName}`);
  }
  
  setMessageData(template: any) {
    if (template.clcContent) {     
      this.setEditorContent(template.clcContent, template.templateContent);
    } else {
       //C170665-1501 - Removed placeholder text and it's check
      if (!template.nlpContent) {
        if(template.type == "addTemplate"){
          this.templateDataArray.push(this.child.getData());
          this.child.setData('<div class="custom-template">' +template.templateContent + "</div>" + this.templateDataArray[0]);
        }
        else{
          this.child.setData(template.templateContent);
        }        
      } else {
        this.child.setData(template.templateContent);

        // C170665-124 | Check if it is nlp content.
        if (template.nlpContent) {
          this.deleteEligibleNLPColumn('strong.deleteColumnNlp', 'td'); // Add action listener to NLP Column
          this.deleteEligibleNLPRow('span.deleteRowNlp', 'tr'); // Add action listener to NLP Row
        }
      }
      // 	[C170665-542] Issue-When a Template is applied on a Reply conversation the entire mail body is wiped out.
      const existingToList = this.template ? this.template.toList: []; 
      const existingCcList = this.template ? this.template.ccList: [];
      const existingSubject = this.template ? this.template.templateSubject : null;
      // C170665-1134 Issue- Stale recipient list from previous inquiry is carried when template is applied to another inquiry
      this.template = {...template};
      // C170665-3201 On template switch - Subject has stale data of template 1.
       /* if (existingSubject && this.template) {
        // C170665-18 Allow user to Edit subject from conversation view
        // dont replace subject incase of user defined template else update subject
        if (template &&  template.type && template.type.toLowerCase() === "addtemplate") {
          this.template.templateSubject =  existingSubject;
        }
      }*/
       // C170665-3128 removing this flow from new-message as it needs to be handled in receipients
      // C170665-1221 Template: Template preference option for To/CC/BCC recipient
      // replace existing receipient list if merge recepient is false in profile settings
     /*  if (template && template.mergeRecipients === false) {
        this.template.toList = template.toList;
        this.template.ccList = template.ccList;
      } else { */ // when flag set to  true existing slow of merge
        // append existing list in template
       /*  if (existingToList && existingToList.length > 0 && template && template.toList) { //[C170665-1513] - Template issue fix
          const toListWithTemplateAndDuplicates = existingToList.concat(template.toList);
          this.template.toList = _.uniq(toListWithTemplateAndDuplicates, 'text'); // to skip duplicates
        }

        if (existingCcList && existingCcList.length > 0 && template && template.ccList) {
          const ccListWithTemplateAndDuplicates = existingCcList.concat(template.ccList);
          this.template.ccList = _.uniq(ccListWithTemplateAndDuplicates, 'text'); // to skip duplicates
        }
      } */
     
    }
  }

  getTemplate(template:any){
    template[0].type = "addTemplate";
    this.setMessageData(template[0]);
  }

  getToBccData(recObj){
    this.toBccData = recObj;
  }
  getAttachments(attachments){
    this.postDataForAttachmentFileN = [];
    //let s = this.newMessageService.getAttachmentData().subscribe(attachments => {
      try {
        if (!isNull(attachments)) {
          for (let attachment of attachments) {
            this.postDataForAttachmentFileN.push({
              "name": attachment.name, "id": attachment.id, "secure": attachment.secure, "fileInfo": attachment.fileInfo, "isSecuredByQMA": attachment.isSecuredByQMA
            })
          }
        //  this.parentForm.get('attachments').setValue(this.postDataForAttachmentFileN);
         // s.unsubscribe();
        }
      } catch (e) {
      }
   // });
  }
  /**
  * Method to handled the note event emmitted from new-message-recipient component and populate the notes.
  */
  onNote(notes: any, action: string): void {
    try {
      let notesList = [];
      if ('new' === action) {
        notesList = this.notesList.length > 0 ? this.notesList : [];
      }
      this.notesList = [];
      if (notesList.length > 0) {
        this.notesList = [...notesList]; //  C153176-6047 : Local notes varaiable should be updated.
        this.notesList.forEach(note => note["createdDate"] = new Date(note["commentDate"]["$date"]).getTime());
        this.notesList = _.uniq(this.notesList, 'createdDate');
      }
      // When we add multi notes from reply or new message, the notes become doubled. To handle this we get unique based on createddate.
      notes = notes === undefined ? [] : notes; // when notes are undefined.
      if (notes && notes.length > 0) {
        notes.forEach(note => note["createdDate"] = new Date(note["commentDate"]["$date"]).getTime());
        notes = _.uniq(notes, 'createdDate'); // Remove the duplicate entries when added while reply.
      }
      Array.prototype.push.apply(this.notesList, notes);

      if (this.notesList.length > 0) {
        // this.notesList contains notes object too, so we need to get the unique notes.
        this.notesList = _.uniq(this.notesList, 'createdDate'); // Remove the duplicate entries when added while reply.
      }
    } catch (e) {
      console.error("Exception in QMA 2 while onNote() : ", e);
      this.notesList = [];
      notes = notes === undefined ? [] : notes;
      Array.prototype.push.apply(this.notesList, notes);
    }
  }

  /**
   * Method to handle the selected Inquiry Id emitted from new-message-actions component and populated the inquiryId
   */
  setInquiryId(inquiryId: number): void {
    this.inquiryId = inquiryId;
  }

  updateSecure(data){
    this.secure= data;
  }
  updatePrevAttach(data){
    this.hasPrevAttach = data;
  }
  setResolve(data) {
    this.setResolveN = data;
  }

  getConversationObj(coversationObj:any){
    //TODO:Performance :: start time
    let strtTime = performance.now();

    this.conversationObj = coversationObj;

    // Set the CLC
    let clcData = {
      inquiryId: coversationObj.inquiryId,
      groupId: coversationObj.groupId,
      groupName: coversationObj.recipientData.from,
      tabId: coversationObj.inquiryId
    };
    this.clc = { ...clcData };

    //TODO:Performance :: End Time. 
    let endTime = performance.now();
    console.log(`QMA2.0 Performance :: New Message - getConversationObj() : ${Math.ceil(endTime - strtTime)} miliseconds`);
  }

  /**
   * Method to handle child event on disclaimer changed.
   */
  onDisclaimerChange(disclaimer: string): void {
    //TODO:Performance :: start time
    let strtTime = performance.now();

    if (this.fromNewMessage) {
      // this.child.setData("<br><br><br><div id=\"bubleContentSeparator\"><span id=\"bubbleContentSeparatorSpan\"></span></div><br>"
      //   + this.getActionSignature() + "<br>"
      //   + disclaimer);
      let $content = this.child.getData();
      $content = $('<div></div>').append($content);
      if($content.find("#disclaimerDiv").length){
        $content.find("#disclaimerDiv").html($(disclaimer).html());
        this.child.setData($content.html());
      }else{
        $content.append('<br><br>'+disclaimer);
        this.child.setData($content.html());
      }
    }

    //TODO:Performance :: End Time. 
    let endTime = performance.now();
    console.log(`QMA2.0 Performance :: New Message - onDisclaimerChange() : ${Math.ceil(endTime - strtTime)} miliseconds`);
  } 

  onGroupChange(data){
    //TODO:Performance :: start time
    let strtTime = performance.now();

    this.group = data;

    //TODO:Performance :: End Time. 
    let endTime = performance.now();
    console.log(`QMA2.0 Performance :: New Message - onGroupChange() : ${Math.ceil(endTime - strtTime)} miliseconds`);
  }

  inlineImagePresent(data){
    this.inlineImage = data;
    this.newMessageForm.get("imageAttach").setValue(data);
  }
  /* NLP && CLC assist may insert a table in message editor. 
  The below method can delete the column of the table on click of cross button */
  deleteEligibleColumn(className, tableTag) {
    let countId = this.countID;
    let htmlElement: any = document.getElementById('my-editor' + countId + '_ifr');
    const htmlElements: HTMLElement[] = htmlElement.contentDocument.querySelectorAll(className);
    const htmlRowElements: HTMLElement[] = htmlElement.contentDocument.querySelectorAll("tr");

    htmlElements.forEach(el => {
      el.addEventListener("click", (event: any) => {
        let removeElement: HTMLElement[] = [];
        htmlRowElements.forEach(elt => {
          removeElement.push(elt.querySelectorAll(tableTag)[event.currentTarget.parentElement.cellIndex]);
        });
        removeElement.forEach(el => {
          el.remove();
        });
      });
    });
  }

  deleteEligibleNLPColumn(className, tableTag) {
    let countId = this.countID;
    let htmlElement: any = document.getElementById('my-editor' + countId + '_ifr');
    const htmlElements: HTMLElement[] = htmlElement.contentDocument.querySelectorAll(className);
    const htmlRowElements: HTMLElement[] = htmlElement.contentDocument.querySelectorAll("tr");

    htmlElements.forEach(el => {
      el.addEventListener("click", (event: any) => {
        const columnIndexToRemove = event.currentTarget.parentElement.cellIndex ;
         // to remove  column from each row
        for (let item of event.currentTarget.parentElement.parentElement.parentElement.parentElement.tBodies[0].rows) {
          item.deleteCell(columnIndexToRemove);
      }
       // to remove header column
       event.currentTarget.parentElement.parentElement.deleteCell(columnIndexToRemove);
      
      });
    });
  }

  // Add action listener to NLP Row
  deleteEligibleNLPRow(className, tableTag) {
    let countId = this.countID;
    let htmlElement: any = document.getElementById('my-editor' + countId + '_ifr');
    const htmlElements: HTMLElement[] = htmlElement.contentDocument.querySelectorAll(className);
    const htmlRowElements: HTMLElement[] = htmlElement.contentDocument.querySelectorAll("tr");

    htmlElements.forEach(el => {
      el.addEventListener("click", (event: any) => {
        const columnIndexToRemove = event.currentTarget.parentElement.parentElement.rowIndex;
       // to remove selected row
       event.currentTarget.parentElement.parentElement.parentElement.parentElement.deleteRow(columnIndexToRemove)
       this.child.setNlpEditedFlag('Edited')
      });
    });
  }
 
  cancelInlineAction(){
    this.cancelInlineReply.emit('true');
  }

  /**
   * This method is called when inline message component is expanded to new tab.
   * @param data 
   */
  onExpandEvent(data) {
    //TODO:Performance :: start time
    let strtTime = performance.now();

    let expandedReplyObj = { ...data };
    expandedReplyObj.inquiryId = this.conversationObj.inquiryId;
    expandedReplyObj.inquiryAction = this.conversationObj.inquiryAction;
    expandedReplyObj.suggestionConvId = this.conversationObj.suggestionConvId;
    expandedReplyObj.userNotes = this.conversationObj.userNotes;
    expandedReplyObj.urgentFlag = this.conversationObj.urgentFlag;
    expandedReplyObj.groupId = this.conversationObj.groupId;
    
    // C153176-4459:  Flag to keep the nlp suggestion
    expandedReplyObj.nlpSuggestion = this.conversationObj.nlpSuggestion;

    // C153176-4594:  Flag to keep the selected clc trades
    expandedReplyObj.selectedCLCTrades = this.conversationObj.selectedCLCTrades;
    
    // C153176-4594:  Flag to keep the clc suggestion
    expandedReplyObj.clcSuggestion = this.conversationObj.clcSuggestion;

    // C153176-5298: Flag to keep for locked inquiry required for resolve
    expandedReplyObj.lock = this.conversationObj.lock;
    expandedReplyObj.lockedBy = this.conversationObj.lockedBy;

    // C170665-124 | Set the nlpContent and clcContent to template.
    expandedReplyObj.templateData[0].nlpContent = this.conversationObj.templateData[0].nlpContent;
    expandedReplyObj.templateData[0].clcContent = this.conversationObj.templateData[0].clcContent;
    expandedReplyObj.inquiry = this.conversationObj.inquiry;
    this.expandEvent.emit(expandedReplyObj);

    //TODO:Performance :: End Time. 
    let endTime = performance.now();
    console.log(`QMA2.0 Performance :: New Message - onExpandEvent() : ${Math.ceil(endTime - strtTime)} miliseconds`);
  }

  // C153176-4457 : Code changes related to Editor 
  editorDataChange(){
    let val =this.newMessageForm.value;
    this.onNewMessageDataChanged(val);
  }
  
  // C153176-4457 : Get the data from New message receipent and emit it to New Message
  onNewMessageDataChanged(data) {
    // TODO:Performance :: start time
    let strtTime = performance.now();

    let currentTab = AppUtils.getCurrentTab();
    console.log('Current tab in New Message : ', currentTab);

    // Performance Improvement - The data emit is required for draft functionality if user click away from editor while reply funcationalty.
    if (currentTab && !currentTab.includes('NEW MESSAGE')) {
      console.log('Data emit from New Message for  : ', currentTab);
      data.editorData = this.child.getData();
      data.fontStyle = this.child.getSelectedFont();
      this.newFormData.emit(data);
    }

    //TODO:Performance :: End Time. 
    let endTime = performance.now();
    console.log(`QMA2.0 Performance :: New Message - onNewMessageDataChanged() : ${Math.ceil(endTime - strtTime)} miliseconds`);
  }

  /**
   * Method to get the selected CLC trade 
   */
  onTradesAdded(trades: any): void {
    this.selectedCLCTrades = trades.selectedCLCTrades;
    this.setEditorContent(trades.selectedCLCTradesStr);
  }

  /**
   * Method to update clc trades to the editor.
   */
  setEditorContent(trades: any, templateContent?: string) : void {
    const clcTrades = $('<div></div>').append(trades);
    let clcTables = clcTrades.find("#conatinerDivForCLCTable").html();

    let $content = this.child.getData();
    // This is the case when perform clc reply from conversation.
    if (!$content) {
      $content = templateContent;
    }
    $content = $('<div></div>').append($content);
    //C170665-1501 - Removed placeholder text
    if ($content.find("#conatinerDivForCLCTable").length) {
      $content.find("#conatinerDivForCLCTable").html(clcTables);
      let html_content = $content.html();
      //C170665-1501 - Removed placeholder text
      this.child.setData(html_content);
    } else {
      const contents = trades + $content.html();
      //C170665-1501 - Removed placeholder text
      this.child.setData(contents);
    }
    this.deleteEligibleColumn('span.deleteColumnClc', 'td'); // Add action listener to CLC Column
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.inlineViewMode){
      this.resize();
    }

    // C153176-4229 : Push Contact as recipients
    if (changes.singleContact && changes.singleContact.currentValue) {
      this.onPushContact(changes.singleContact.currentValue);
    }
  }

  // C153176-4605 - Fwd functionality issue fixed
  onNewMessageSend(data){
    console.log('Data inside new message comp:',data);
    this.inlineReplySendData.emit(data);
  }

  // C153176-4683: inline reply scheduled event
  onInlineReplyScheduled(data) {
    this.inlineReplyScheduledData.emit(data);
  }

  /**
   * C153176-4229: Method to handle the contact push from contact list to new-message
   */
  onPushContact(pushContact: any): void {    
    this.pushContact = pushContact;
  }

  /**
   * C153176-5746 : On add top contact from right click.
   * @param data 
   */
  onTopContactAdded(data: any): void {
    //TODO:Performance :: start time
    let strtTime = performance.now();

    this.topContactAdded = data;

    //TODO:Performance :: End Time. 
    let endTime = performance.now();
    console.log(`QMA2.0 Performance :: New Message - onTopContactAdded() : ${Math.ceil(endTime - strtTime)} miliseconds`);
  }

  /**
   * C153176-5896 : Method to capture event of recipient updation from child component.
   * @param data 
   */
  onRecipientUpdate(data: boolean): void {
    //TODO:Performance :: start time
    let strtTime = performance.now();

    console.log("Is recipient updated: " + data);
    this.recipientUpdated = data;

    //TODO:Performance :: End Time. 
    let endTime = performance.now();
    console.log(`QMA2.0 Performance :: New Message - onRecipientUpdate() : ${Math.ceil(endTime - strtTime)} miliseconds`);
  }

  // symphony changes
  populateSymphonyData(symphonydata: any) {
    // populate to field
   this.populateToFieldBySymphonyData(symphonydata);
  // update form
   this.newMessageForm.patchValue({
      subject: symphonydata.subject,
      toUsers: this.newMessageForm.get('toUsers')
    });

  }
  // symphony auto populate editor data
  populateSyphonyConv(symphonydata: any) {
    // update editor contents
    if (symphonydata.convHistory) {
      this.child.setData(symphonydata.convHistory);
    }
  }

  populateToFieldBySymphonyData(symphonydata: any) {
    /* let toList = [{"text":"*GT IN ICG IN ExpMgmt XMC Manual Group1","value":"2533","email":"manualgroup1@citi.com","id":null,"country":"IN","timeZone":"IST","active":true}]
    this.newMessageRecipent.toUsersList = toList; */
    // step 1 if group selected auto populate group in to field
    let grpMembersListRes = [];
    if(symphonydata && symphonydata.mailboxGridRow && 
      symphonydata.mailboxGridRow.groupList && symphonydata.mailboxGridRow.groupList.length > 0) {
        symphonydata.mailboxGridRow.groupList.forEach(grpId=> {
          let grp :any;
          grp = this.userDataService.loggedInUserInfo.allActiveGroups.find(ag=>ag.value == grpId);
          grp.id = null;
          if(!grp){
            return;
          }
          // update groups in to field
          this.newMessageForm.get('toUsers').patchValue(grp);
          
          const grpMembersList =  this.groupAdminService.getGroupDataEntitlement(grp.text);
          grpMembersListRes.push(grpMembersList);
        });
        
      }
      // step 2. check if any chat member not in group add them separatly
      let allQMAUsers = symphonydata.mailboxGridRow.symphonyChatRoomMember;
      if(grpMembersListRes.length>0) {
      forkJoin(...[grpMembersListRes]).subscribe(grpEnt=>{
        let allMembersOfAllGroups:any;
          allMembersOfAllGroups = grpEnt.flat().map(am=>am.userId);
        let membersTobeAddedinTo = allQMAUsers.filter(aqs=>!allMembersOfAllGroups.includes(aqs));
        // update individual users which are not part of any group
        if(membersTobeAddedinTo && membersTobeAddedinTo.length > 0) {
          membersTobeAddedinTo.forEach(userid => {
            let memberTobeAddedinTo = this.userDataService.loggedInUserInfo.allToCcDBUsers.find(user=>user.id == userid);
           // this.newMessageRecipent.toUsersList.push(memberTobeAddedinTo);
            this.newMessageForm.get('toUsers').patchValue(memberTobeAddedinTo);
            
          });
        }
        console.log(grpEnt);
      });
    } else { // no group dl all members added separately
      if(allQMAUsers && allQMAUsers.length > 0) {
        allQMAUsers.forEach(userid => {
          let memberTobeAddedinTo = this.userDataService.loggedInUserInfo.allToCcDBUsers.find(user=>user.id == userid);
         // this.newMessageRecipent.toUsersList.push(memberTobeAddedinTo);
          this.newMessageForm.get('toUsers').patchValue(memberTobeAddedinTo);
          
        });
      }
    }
  // populate external TO field
  if(symphonydata.mailboxGridRow && symphonydata.mailboxGridRow.externalUserList
      && symphonydata.mailboxGridRow.externalUserList.length>0) {
        symphonydata.mailboxGridRow.externalUserList.forEach(eu => {
          if(eu!="QMASymphony.bot@citi.com") {
            this.newMessageForm.get('toUsers').patchValue(
              {
              "text": eu, "value": eu, "email": eu, "id": "", "active": false
              }); 
          }
        });
      }
  }

  getEditorStyle() {
    console.log('Get Editor Height!!!!!!!!!!!!!!!');
    let currentHeight: number;
    if (this.elementView != undefined)  {
      currentHeight = this.elementView.nativeElement.offsetHeight;
      console.log(currentHeight);
      this.originalHeight = (this.originalHeight == undefined || (currentHeight > this.originalHeight))? currentHeight: this.originalHeight;
      if (this.originalHeight - currentHeight > 20) {
        console.log('!!!!!! Something has opened on the screen.!!!!!!');
        return {'max-height': '200px'};
      }
    }
    
    console.log('Get Editor Height End!!!!!!!!!!!!!');
    // alert('Alert works');
    // return { 'max-width': (this.elementView ? this.elementView.nativeElement.clientWidth : 370) - 190 + 'px' };
  }
  ngAfterViewInit(): void {
  // call child component receipient
  
  this.newMessageRecipent.messageInstance = this.messageInstance;
  this.newMessageRecipent.parentForm = this.newMessageForm;
  this.newMessageRecipent.ngOnInitTrigeredByNewMsgComp();
  let endTime = performance.now();
  console.log(`QMA2.0 Performance :: New Message ngAfterViewInit loading : ${Math.ceil(endTime - this.startTime)} miliseconds, for group: ${this.groupName}`);

  }
}

