import { Component, OnInit, ViewChild, ViewChildren, QueryList, ElementRef, Inject, HostListener } from '@angular/core';
import { UserDataService } from '../../services/user-data.service';
import { TabView } from 'primeng/tabview';
import { TextValue } from '../../model/Common/TextValue';
import { GroupAdminDetails } from '../../model/group-admin/groupAdminDetails';
import { GroupAdminService } from "src/app/services/group-admin/group-admin.service";
import { ExpTinyComponent } from 'src/app/common/component/exp-tiny/exp-tiny.component';
import { HeirarchyData } from "src/app/model/LoginUserInfo/HeirarchyData";
import { QmaConstant } from "src/app/constant/qma-constant";
import { AgGridCheckboxComponent } from './ag-grid-checkbox.component';
import { OverlayPanel } from "primeng/overlaypanel";
import { SaveInquiryRules } from 'src/app/model/group-admin/saveInquiryRules';
import { GroupInquiryCriteriaList } from 'src/app/model/group-admin/groupInquiryCriteriaList';
import { GroupInquiryActions } from 'src/app/model/group-admin/groupInquiryActions';
import { ProfileSettingComponent } from 'src/app/core/profile-setting/profile-setting.component';
import { MessageService } from 'primeng/api';
import { DeleteCellRendererComponent } from './delete-cell-renderer.component';
import { ColorPickerComponent } from './color-picker.component';
import { DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT } from "@angular/common";
import { ExcelService, ExcelExport, InqRulesReorderInfo } from "src/app/services/common/excel.service";
import { CheckboxListComponent } from 'src/app/common/component/checkbox-list/checkbox-list.component';
import { UserEntitlementCheckboxComponent } from './user-entitlement-checkbox.component';
// Jira : C153176-5182 Group Admin - issue- Design change
import { UserEntitlementAddCheckboxComponent } from './user-entitlement-add-checkbox.component';
import { ClientCategory } from "src/app/model/LoginUserInfo/clientCategory";
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { GridOptions, Module } from "@ag-grid-community/core";
import { InquiryOrderComponent } from './inquiry-order-component';
import { ReadUnReadConv } from 'src/app/services/contextMenu/context.model';
import * as _ from "lodash";
import * as $ from 'jquery'; // C153176-4445
import { RightNavigationService } from 'src/app/services/panel/panel.service';
import { forkJoin } from 'rxjs';
// C153176-5511,C153176-5517
import { transition, trigger, animate, style, state } from "@angular/animations";
import { SessionTimeOut } from 'src/app/services/sessiontimeout.service';
import { SuperAdminSetupService } from 'src/app/services/super-admin-setup/super-admin-setup.service';
import { LoggingService } from 'src/app/services/global-error/logging.service';
import { AppUtils } from 'src/app/common/utility/appUtil';
import { GlobalDirectoryService } from 'src/app/services/global-directory/global-directory.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  providers: [MessageService],
  animations: [
    trigger('panelInOut', [
      state('*', style({ 'overflow-y': 'hidden' })),
      state('void', style({ 'overflow-y': 'hidden' })),
      transition('* => void', [
        style({ height: '*' }),
        animate('250ms ease-out', style({ height: 0 }))
      ]),
      transition('void => *', [
        style({ height: 0 }),
        animate('250ms ease-in', style({ height: '*' }))
      ])
    ])
  ]
})
export class UserComponent implements OnInit {
  // inquiry redesign C153176-4445
  metadataBackup: any = [];
  routeListBackup: any = [];
  modules: Module[] = [ClientSideRowModelModule];
  showSpinner: boolean = false;
  ClientNameValidation: string = '';
  showGroupName: boolean = false;
  gridRoleApi: any;
  bulkUploadData: any = [];
  uploadData: any = [];
  errAndSuccess: string = "";
  closeFlagBulkUpload: boolean;
  errorUpload: boolean = false;
  selectedFormat: string = "CSV";
  domainMsg: string = "";
  entitleListBackup: any = [];
  errorMessageForGroupAdmin: string;
  inqprocessingRegionList: any = [];
  inqTagsList: any = [];
  inqRequestTypeList: any = [];
  requestTypes = [];
  displayProfile: boolean = false;
  editorFlagProfile: boolean = false;
  display: boolean = false;
  profileFlag: boolean = false;
  editorFlag: boolean = false;
  domainValidationFlag: boolean = false;
  inquirySourceList = [];
  metadataTypeList: any = [];
  selectedGroupList = [];
  public metaRowData: any;
  public addNewUserData: any = [];
  public addNewUserColumns;
  public newUserSoeid: string = '';
  public addNewUsercolumnDefs: any;
  newUserGridFlag: boolean = true;
  public entitleData: any;
  public entitleColumns;
  public metaColumnDefs;
  public pinnedTopRowData;
  public pinnedBottomRowData;
  public clientCategoryColumnDefs: any = [];
  public clientCategoryRowData: any = [];
  requestTypeList = [];
  rootCauseList = [];
  tagsList = [];
  originalTagList = [];
  processingRegionList = [];
  metaDataType = 'RequestTypes';
  isProcessingRegionMandatory: boolean = false;
  isTagMandatory: boolean = false;
  isRootCauseMandatory: boolean = false;
  isRootCauseMandatoryWOReply: boolean = false;
  isRootCauseMandatoryAlways: boolean = true;
  dropdownChangeRequest: any = [];
  reqList: any = [];
  metaDeleteFlag: boolean = true;
  domainDeleteFlag: boolean = true;
  metadataRecords: number = 0;
  domainRecords: number = 0;
  entitlementView: boolean = false;
  tabArray: Array<boolean> = [true, false];
  successFlag: boolean = false;
  closeFlag: boolean = false;
  closeFlagAddGroup: boolean = false;
  isExist: boolean = false;
  isGroupAdmin: boolean = false;
  metaTagValidationFlag: boolean = false;
  selectedCustomClientList: any = [];
  grpSaveMsg: string = "";
  /*C153176-5006 to show error message if user id is not available */
  userEntitlementFlag: boolean = false;
  entitlementValidationMsg: string = "";

  // Jira:4703 : Confirmation Dialog before delete
  showPopUpDelete: boolean = false;
  confirmMsgText: string;
  groupTab: string;
  restoreHideFlag: boolean = false;

  public groupManager: string = '';
  //  Icon Messages in admin
  public infoGroup: string = 'This section displays allows you to select the group to see and edit group details.';
  public infoEntitlement: string = 'Displays the current Group Entitlement details.Users can be added/removed from their entitlements.';
  public infoMetadata: string = 'Displays the current Group\'s inquiry related details.Request types, Root Cause Tags and Processing Region can be added/edited in this section.';
  public infoPreferences: string = 'Group Preferences can be enabled or disabled for the selected Group.';
  public infoPeerReview: string = 'Peer Review Domains can be added/removed from their respective group.Some valid domain names are XXXX';
  public infoEmailDisclaimer: string = 'Disclaimers to be shown for the group can be added here';
  public infoAutoResponse: string = 'Automated response for the group can be configured here.';
  public infoInquiryRules: string = 'Inquiry Rules for the group can be configured here.'
  public infoInquiryRulesExtTitle: string = 'Mark as Non-Inquiry:';
  public infoInquiryRulesExt: string = 'This option will route all incoming emails that match the rule criteria into a separate bucket.'
    + ' All such inquiries will be systematically resolved at the end of the business day.';
  public ruleDisclaimer: string = '>> Rules are displayed and processed by system in the order provided below. Rules are applied from top to bottom. Once rule matches an inquiry no other rules will be processed/applied. Re-ordering of the rules is currently unavailable.';
  public infoEsclaition: string = 'Group level escalation criteria can be configured here.';
  public infoClientCat: string = 'This section allows the user to define the custom client category.';
  public errorMessageForClientCategory: string = 'Maximum two custom categories allowed. Please delete any existing to create new.';
  public errorMessageForMetadata: string = "";
  public userAddGroupMsg: string = "";
  public concurrentUpdateMsg: string = "Your group's preferences have been updated by another user. \nPlease refresh your screen to get latest updates and make your changes again. \nThe changes that you’ve just made will not be saved.";
  public userSavedSuccess: string = 'Group Details saved successfully!';

  heirarchyData: HeirarchyData;
  version: number;
  gfcid: any = [];
  subjectEscalationList: any = [];

  public clientCategoryDataGridApi;
  public clientCategoryDataGridColumnApi;

  public metaDataGridApi;
  public metaDataGridColumnApi;
  public gridApi;
  public gridColumnApi;
  public addNewUserGridApi;
  public addNewUserGridColumnApi;
  public disablePaginationNextLast: boolean = false;
  public disablePaginationPrevFirst: boolean = false;
  selectedGroup: any = {};
  selectedAdminGroupName: string = "";

  @ViewChild('adminTabs', { static: true }) adminTabs: TabView;
  @ViewChild('entitlementUser') entitlementUser: ElementRef;
  @ViewChild('uploadFiles') uploadFiles: ElementRef;
  @ViewChild('duplicate', { static: true }) overlaypanel: OverlayPanel;
  @ViewChildren(ExpTinyComponent) editorList: QueryList<ExpTinyComponent>;
  @ViewChildren(CheckboxListComponent) checkboxListComponent: any;

  // Jira : 4445 : upload inquiry
  @ViewChild('uploadFilesForInquiry') uploadFilesForInquiry: ElementRef;
  public entitleGridApi;
  public entitleGridColumnApi;
  public entitleColDef;
  public metaCheckbox;
  public domainCheckbox;
  rowSelection;
  entitleSelUser: number = 0;
  entitleDelAndGropFlag: boolean = true;
  finAnaArr = [];
  finPeerRevArr = [];
  finSupArr = [];
  finGrpAdmArr = [];
  public lbTotalRecords: number = 0;
  public lbPageSize: number = 0;
  public lbCurrentPage: number = 0;
  public lbTotalPages: number = 0;



  // Peer Review Domain
  public prdColumn;
  public prdData: any = [];
  validationFlag: boolean = false;
  enableAutCliAck: boolean = false;
  autoResFlagUI: boolean = false;
  autoResFlagDetails: boolean = true;
  //  Preferences
  makerCheckerRqd: boolean = false;
  attachmentBasedMakerChecker: boolean = false;
  predictiveRecipients: boolean = false;
  enableAutoAssignment: boolean = false;
  enableAutoReplySuggestion: boolean = false;
  isAutoAssignmentRollOutOrgs: boolean = false;
  isEmailSharingBlocked: boolean = false;
  isInquirySourceMandatory: boolean = false;
  isGfidMandatory: boolean = false;
  isInqReferenceIdReq: boolean = false;
  excludeMyGroupWhileReplyAll: boolean = false;

  // C153176-5630 | Enable/disable client contact list visibility for group members
  hideClientList: boolean = false;
  // C153176-6148 | Enable/disable flag for Re-age need supervisor approval
  supervisiorApprovalForReage: boolean = false;

  // C170665-10 | Request Type and Root Cause Linking
  requestTypeRootCauseFilter: boolean = false;

  holidayAndShiftDetails: boolean = false;
  isInquirySubStatusEnabledByOrgAdmin: boolean = false;
  enableWorkShiftDiv: boolean = true;
  enablePeerReviewDiv: boolean = true;
  peerRevDomain: string = 'All';
  selectedEntGrpName: any = [];
  entGrpName: any = [];
  selectedWeekelyOff: any = [];

  weekelyOff: any[];
  countryList: any[] = [];
  timeZones: any[] = [];
  selectedCountry: object = {};
  selectedTimeZone: object = {};
  shifttimeList: any[] = [{ "value": "00:00" }, { "value": "00:30" },
  { "value": "01:00" }, { "value": "01:30" }
    , { "value": "02:00" }, { "value": "02:30" }, { "value": "03:00" }, { "value": "03:30" }
    , { "value": "04:00" }, { "value": "04:30" }, { "value": "05:00" }, { "value": "05:30" }
    , { "value": "06:00" }, { "value": "06:30" }, { "value": "07:00" }, { "value": "07:30" }
    , { "value": "08:00" }, { "value": "08:30" }, { "value": "09:00" }, { "value": "09:30" }
    , { "value": "10:00" }, { "value": "10:30" }, { "value": "11:00" }, { "value": "11:30" }
    , { "value": "12:00" }, { "value": "12:30" }, { "value": "13:00" }, { "value": "13:30" }
    , { "value": "14:00" }, { "value": "14:30" }, { "value": "15:00" }, { "value": "15:30" }
    , { "value": "16:00" }, { "value": "16:30" }, { "value": "17:00" }, { "value": "17:30" }
    , { "value": "18:00" }, { "value": "18:30" }, { "value": "19:00" }, { "value": "19:30" }
    , { "value": "20:00" }, { "value": "20:30" }, { "value": "21:00" }, { "value": "21:30" }
    , { "value": "22:00" }, { "value": "22:30" }, { "value": "23:00" }, { "value": "23:30" }];
  selectedStartTime: object = {};
  selectedEndTime: object = {};
  //  end Preferences

  //  email disc
  emailDisc: string = '';

  //  auto response
  autoResponse: string = '';

  //  Escl criteria
  converThreshold: number;
  resTimeThreHr: number;
  resTimeThreMin: number;
  penApprThreHr: number;
  penApprThreMin: number;

  public columnDefs;
  public defaultColDef;

  public loginInfoResponse: any;
  //  inquiry rules
  public routesList: any = [];
  public routesColumn: any = [];
  entitleDataUserName: any = [];
  public gridApiInquiry;
  public gridColumnApiInquiry;
  public inquiryFlag = 0;
  public filterIconHide: boolean = false;
  public filterIconHideHorizontal: boolean = false;
  public filterText = "";
  public validationCheck: boolean = true;
  public validationCheckForAction: boolean = true;
  public validationCheckForEmpty: boolean = true;
  public validationRules;
  public inquiryRulesChecbox;
  public rulesDeleteFlag: boolean = true;
  public lastLogin: any;
  displaySuccess: boolean = false;
  displayError: boolean = false;
  errorMessage: boolean = false;
  private frameworkComponents;
  // metaTagValidationFlag: boolean = false;
  metaTagValidationMsg: string = "";

  //  Flag to check if user have group role.
  hasGroupRole: boolean = true;

  // Show QMA Admin only if configure Admin Role is not null
  isQMAAdmin: boolean = false;
  parser: any;

  // C153176-4566 : to show client category and its color code in custom client category
  clientCategories: ClientCategory[] = [];

  // C153176-4445: Inquiry Rules Upload rules - Required to validate the values
  groupRequestTypes = [];
  groupProcessingRegion = [];
  groupTags = [];
  groupUsers = [];
  invalidRowMessage = [];
  uploadedValidInqRules = [];

  // C153176-4445: Message attributes
  inquiryRulesUpload: boolean = false;
  inquiryRulesMsgTitle: string = '';
  inquiryRulesMsg: string = '';
  inquiryRulesConfirm: boolean = false;
  inquiryRulesRejected: boolean = false;
  duplicateColumn: any = [];
  duplicateList: any = [];
  duplicateCheckBox: any;

  // C153176-5522: BCC Group Inquiry Rules
  isBccGrpSelected = false;
  inqAssignedGroups: any = [];
  groupSetupBoolean: boolean = false;

  // C153176-5839: Meta data type value.
  selectedMetaDataType: string = 'RequestTypes';
  start_typing_placehoder: string = " start typing..";

  //C153176-5633 Restore button implemention for the rules screen
  restoreInquiryDate: Date;
  restoreStatus: boolean = false;

  // C153176-5511 : Flag to display User Admin
  displayUserAdmin: boolean = false;
  displaySendUserNotification: boolean = false;
  // C153176-5511,C153176-5517
  activeMenuArray: Array<boolean> = [false, true, false, false, false, false, false, false];
  hideSubMenu: boolean = false;
  newMsgIndexes: number[] = [];

  //C153176-5516
  displayDatabaseAdmin: boolean = false;

  // C153176-5824 | A pop-up blocks user to create request types
  workshiftErrMsg: string = "";

  // Admin Screen entitilements
  isUserAdmin: boolean = false;
  isGrpSetupAdmin: boolean = false;
  isMgmtHierarchyAdmin: boolean = false;
  isDBAdmin: boolean = false;
  canSendNotification: boolean = false; // C153176-6183
  //mailbox dl mapping onboarding
  isMailBoxDLMapping: boolean = false;
  isMailBoxDLMappingPopup: boolean = false;
  // userGroup assignment
  isUserGroupAssignment: boolean = false;
  isUserGroupAssignmentPopup: boolean = false;
  displayAdminSetupPopup: boolean = false;


  // C170665-10 | Variables for Request type and Root Cause mapping
  reqTypeRootCauseMap: any[] = [];
  updatedReqTypeCauseMap: any[] = [];
  groupRootCauses: any[] = [];
  isSuperUser: boolean = true;
  displayAdminSetup: boolean = false;
  //sesion time out
  sessionAlertPopup: boolean = false;
  // for super admin
  displaySuperAdminSetup: boolean = false;
  isSuperAdminRole: boolean = false;
  isProdSupport: boolean = false;

  //C170665-185 for enable org metadata in hard code data point
  enableOrgMetaData: boolean = false;
  displayEnableOrgMetaDataOption: boolean = false;
  orgData: any = [];
  disableGroupMetadataAddOption: boolean = false;
  selectedOrgMetaDataType: any;
  orgName: any;
  disableAddMetaDataOption: boolean = false;
  autoAssignmentRollOutOrgsList: any[] = [];
  // it will true only when org level metadata is enable if org org level metdata is not enable then it
  displayOrgMetadata: boolean = false; //C170665-850 flag for display org level metadata in hard code data point
  blankStringValidationMsg: string = "UserId cannot be blank.";
  newUserSoeIdValidationFlag: boolean = false;
  enableInquirySubStatus: boolean;
  enableInquirySubStatusFlag: any;
  overrideCheckbox = false;
  isL6AndBelow = false;
  isManagerSelected: boolean;
  groupManagerID: any;
  groupManagerLevel: any;
  auId: string;
  contactInfoEndPoint: string;
  selectedL1: any;
  selectedL2: any;
  selectedL1UserId: any;
  selectedL2UserId: any;
  selectedBusinessSegment: any;
  isUserActiveGD: boolean;
  managedSegmentData: any = { 'msL6': null, 'psL5': '', 'psL6': '', 'psL7': '' };
  isLookupClicked = false;
  constructor(private userDataService: UserDataService, private groupAdminService: GroupAdminService, private messageService: MessageService, private rightNavigationService: RightNavigationService, private sanatized: DomSanitizer, @Inject(DOCUMENT) private document: any, private excelService: ExcelService,
    private sessionTimeoutService: SessionTimeOut, private superAdminSetupSerivce: SuperAdminSetupService,
    private loggingService: LoggingService, private globalDirectoryService: GlobalDirectoryService) {

    this.subscribeSessionAlert();

    //  Starting function
    this.showInitial();

    this.countryList.splice(0, 0, {
      countryCode: "", countryName: "Select country"
    });
    this.timeZones.splice(0, 0, {
      timeZone: "",
      timeZoneCode: "",
      timeZoneName: "Select time zone",
      utcOffset: ""
    });

    //  Temporary show Last Login
    this.lastLogin = Date.now();

    this.parser = new DOMParser();
    this.rightNavigationService.getNavStatus().subscribe(clickedIndex => {
      // added this Nav status call to reset the chevron
      this.activeMenuArray[7] = false;
    });
  }

  showInitial() {
    this.entGrpName = [];
    this.reqList = [];
    this.invalidRowMessage = []; // C153176-4445 : Reset the invalid Rows.
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
      //setting seesion timeout config
      if (loginUserInfo && loginUserInfo.sessionConfig) {
        this.sessionTimeoutService.initializeSessionConfig(loginUserInfo.sessionConfig.sesionTimeoutInterval,
          loginUserInfo.sessionConfig.sessionAlertInterval, loginUserInfo.sessionConfig.smLogoutUrl);
        this.sessionTimeoutService.startSessionTimer();
      }
      //set logger flag
      if (loginUserInfo && loginUserInfo.uiConsoleLogConfig) {
        let uiConsoleLogConfig = loginUserInfo.uiConsoleLogConfig;
        this.loggingService.enableDebugLog(uiConsoleLogConfig);
      }


      this.loginInfoResponse = loginUserInfo;
      const groups = loginUserInfo.myGroups;
      //Jira : C153176-4562 Issue- Admin page
      const groupRoles = loginUserInfo.groupRoles;
      const roleName = 'Group Admin';
      const roleNameAdd = 'Supervisor';  //C153176-5207 Populate group with “Group Admin” or “Supervisor” role.
      this.autoAssignmentRollOutOrgsList = loginUserInfo.autoAssignmentRollOutOrgsList;
      //C153176-5001 : Reset the list before populating again.
      this.entGrpName = [];
      this.selectedGroupList = [];
      groups.forEach(element => {
        groupRoles.forEach(group => {
          if ((element.groupName == group.groupName) && (group.groupRole.includes(roleName) || group.groupRole.includes(roleNameAdd))) {
            this.selectedGroupList.push({ 'name': element.groupName });
            this.entGrpName.push({ label: element.groupName, value: element.groupName });
          }
        });
      });
      //Jira : C153176-4562 Issue- Admin page
      // Check if user don't have admin access.
      if (this.selectedGroupList === undefined || this.selectedGroupList == null || Object.keys(this.selectedGroupList).length == 0) {
        this.hasGroupRole = false;
      }
      //C170665-185 check org admin user is present or not
      if (loginUserInfo.orgAdminRole && loginUserInfo.orgAdminRole.length > 0) {
        this.isSuperAdminRole = true;
      }
      // Check if loggedInUser has Configured User Admin Role.
      // C153176-6183 | Check for notification Acess
      this.canSendNotification = loginUserInfo.adminRoles && loginUserInfo.adminRoles.indexOf('Prod Support') > -1;
      // C170665-666 | ISA Admin user can not see the QMA Admin option in QMA 2
      this.isUserAdmin = loginUserInfo.adminRoles && loginUserInfo.adminRoles.indexOf('IS Admin') > -1;
      this.isProdSupport = loginUserInfo.adminRoles && loginUserInfo.adminRoles.indexOf('Prod Support') > -1;
      if (loginUserInfo.configUserAdminRoles && loginUserInfo.configUserAdminRoles.length > 0) {
        this.isUserAdmin = this.isUserAdmin || loginUserInfo.configUserAdminRoles.indexOf('userAdmin') > -1;
        this.isGrpSetupAdmin = loginUserInfo.configUserAdminRoles.indexOf('groupSetupAdmin') > -1;
        this.isMgmtHierarchyAdmin = loginUserInfo.configUserAdminRoles.indexOf('mgmtHierarchyAdmin') > -1;
        this.isDBAdmin = loginUserInfo.configUserAdminRoles.indexOf('dbAdmin') > -1;
        // C153176-6183 | Check for notification Access
        this.canSendNotification = this.canSendNotification || this.isGrpSetupAdmin;
        this.isMailBoxDLMapping = loginUserInfo.configUserAdminRoles.indexOf('groupSetupAdmin') > -1;
        this.displayAdminSetup = loginUserInfo.configUserAdminRoles.indexOf('groupSetupAdmin') > -1;
      }
      //"[C170665-1274] - Group Admin: Bulk group access assignment in QMA"
      loginUserInfo.groupRoles.forEach(element => {
        if ((element.groupRole.indexOf("Group Admin") !== -1 || element.groupRole.indexOf("Supervisor") !== -1)) {
          this.isUserGroupAssignment = true;
        }
      });
      this.isQMAAdmin = this.isUserAdmin || this.isGrpSetupAdmin || this.isDBAdmin || this.canSendNotification || this.isUserGroupAssignment;

      // C153176-5001: Reset the list before populating again.
      this.reqList = [];
      if (loginUserInfo.highlevelRequestTypeList) {
        loginUserInfo.highlevelRequestTypeList.forEach(highlevelRequestType => {
          this.reqList.push(highlevelRequestType);
        });
        this.reqList.unshift("");
      }
      if (this.selectedGroupList.length > 0) {
        this.selectedGroup = this.selectedGroupList[0];
        this.selectedAdminGroupName = this.selectedGroup.name;
      }
      this.countryList = loginUserInfo.countryList;
      this.timeZones = loginUserInfo.timeZones;


    })

  }
  /* For Dashboard Popup by OM */
  showAddtoGrpPanel(event, overlaypanel: OverlayPanel): void {
    overlaypanel.toggle(event);
  }

  /* For Profile Setting Popup by OM */

  openProfileSettingDialog() {
    this.displayProfile = true;
    this.editorFlagProfile = true;
    //this.rightNavigationService.close();
  }
  hideProfileModal(isSuccess: boolean) {
    this.displayProfile = false;
    this.editorFlagProfile = false;

    if (isSuccess) {
      this.profileFlag = true;
      //  Timeout Message after Saved Profile Setting
      setTimeout(() => { this.profileFlag = false; }, 3000);
    }
  }


  openAdminDialog() {
    this.display = true;
    this.editorFlag = true;
    /* C153176-4993 service call getGroupDataById called in
    open admin panel for performance improvement ,
    previously it was in on initial load*/
    this.onBack()
    this.groupChange();
    //this.rightNavigationService.close();
  }
  groupChangesForInit() {
    this.editorFlag = false;
    this.editorFlag = true;
    this.groupChange();
  }

  initAutoResponseContent() {
    let exp: ExpTinyComponent;
    if (this.editorList.length > 0) {
      this.editorList.forEach(div => {
        if (div.elementId == "autoResponse") {
          exp = div;
          if (this.autoResponse) {
            exp.setData(this.autoResponse);
          }
        }
        if (div.elementId == "emailDisc") {
          exp = div;
          if (this.emailDisc) {
            exp.setData(this.emailDisc);
          }
        }
      });
    }
  }

  showBlankValidation() {

    const flag = this.groupManager == '' ? this.groupManager.trim().length == 0 : false;
    return flag;
  }

  callLookupService(isButtonClicked) {

    this.isLookupClicked = true;

    if (!AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.groupManager)) {
      this.isManagerSelected = true;
      const userID = isButtonClicked ? this.groupManager.trim() : this.groupManagerID;
      this.groupAdminService.getUserDataById({ 'userId': userID }).subscribe(userDetails => {
        this.isL6AndBelow = false;
        if (userDetails !== null) {
          this.validationFlag = false;
          this.groupManagerLevel = userDetails.level;
          let psL5 = userDetails.psL5;
          let psL6 = userDetails.psL6;
          this.managedSegmentData.msL6 = userDetails.msL6;
          this.managedSegmentData.psL5 = userDetails.psL5;
          this.managedSegmentData.psL6 = userDetails.psL6;
          this.managedSegmentData.psL7 = userDetails.psL7;

          this.isUserActiveGD = userDetails.gdActive;
          let userName = userDetails.userName.trim();
          const splitOnSpace = userName.split(' ');
          const first = splitOnSpace[0];
          let last = splitOnSpace[1];
          let name = '';
          if (last != null) {
            last = last.trim();
            name = last != '' ? (last + ', ' + first) : first;
          }
          if (userDetails.gdActive) {
            psL5 = psL5 != null ? psL5.trim() : psL5;
            psL6 = psL6 != null ? psL6.trim() : psL6;
            if (psL6 == name || psL5 == name) {
              this.isL6AndBelow = true;
              if (isButtonClicked) {
                this.closeFlag = true;
              }
              this.grpSaveMsg = 'The Group Manager selected is L6 or above. Please select a Group Manager at an L7 or L8 level or check the attestation box below before your submit.';
              this.inquiryFlag = 0;
            } else {
              this.isL6AndBelow = false;
              this.overrideCheckbox = false;
            }
            if (this.heirarchyData) {
              this.heirarchyData.thirdLevelHeirarchy[0].userName = this.groupManager = userDetails.userName;
              this.heirarchyData.thirdLevelHeirarchy[0].userId = this.groupManagerID = userDetails.userId;
            } else {
              this.groupManager = userDetails.userName;
              this.groupManagerID = userDetails.userId;
            }
          } else {
            this.grpSaveMsg = 'User selected is not active in the Global Directory. Please select another user.';
            this.inquiryFlag = 0;
            this.closeFlag = true;
          }
        } else {
          this.validationFlag = true;
          this.isL6AndBelow = false;
        }
      });
    } else {
      this.isManagerSelected = false;
    }
  }

  getMgtData() {

    if (this.managedSegmentData.msL6) {
      return true;
    }
  }

  previewMail() {
    this.autoResFlagUI = true;
    this.autoResFlagDetails = false;
    this.autoResponse = this.getEditorInstance("autoResponse").getData();
  }
  backToEditor() {
    this.autoResFlagUI = false;
    this.autoResFlagDetails = true;
  }
  download_csv() {
    if (this.selectedFormat == "CSV") {
      var csv = 'soeid\n';
      var hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + csv;
      hiddenElement.target = '_blank';
      hiddenElement.download = 'Bulk Upload Users Form.csv';
      hiddenElement.click();
    } else if (this.selectedFormat === "XLSX") {
      const NOTES_DETAILS = {
        FILE_NAME: "Bulk Upload Users Form.xlsx",
        SHEET_NAME: "Bulk Upload Users Form",
        HEADERS: ["soeid"]
      }

      const notesDetails: ExcelExport = {
        fileName: NOTES_DETAILS.FILE_NAME,
        sheetName: NOTES_DETAILS.SHEET_NAME,
        header: NOTES_DETAILS.HEADERS,
        data: [],
        width: [15],
        height: 20
      };
      this.excelService.generateExcel(notesDetails);
    }
    else if (this.selectedFormat === "XLS") {
      const BULK_DETAILS = {
        FILE_NAME: "Bulk Upload Users Form.xls",
        SHEET_NAME: "Bulk Upload Users Form",
        HEADERS: ["soeid"]
      }

      const bulkDetails: ExcelExport = {
        fileName: BULK_DETAILS.FILE_NAME,
        sheetName: BULK_DETAILS.SHEET_NAME,
        header: BULK_DETAILS.HEADERS,
        data: [],
        width: [15],
        height: 20
      };
      this.excelService.generateExcel(bulkDetails);
    }
  }


  onGridReadyInquiry(params) {
    this.gridApiInquiry = params.api;
    this.gridColumnApiInquiry = params.columnApi;
  }
  gridApiDuplicateInq;
  gridColumnApiDuplicateInquiry;

  onGridDuplicate(params) {
    this.gridApiDuplicateInq = params.api;
    this.gridColumnApiDuplicateInquiry = params.columnApi;
  }

  // C153176-4445: select clicked inquiry rule (and make sure it is visible)
  selectInquiryRule(event: any) {
    if (event && event.currentTarget && (event.currentTarget.innerText !== undefined)) {
      let index = parseInt(event.currentTarget.innerText);
      index = Math.max(0, index - 1);
      if (this.gridApiInquiry) {
        let c_node = this.gridApiInquiry.getDisplayedRowAtIndex(index);
        if (c_node) {
          c_node.setSelected(true, true);
          this.gridApiInquiry.ensureIndexVisible(index, 'middle');
          if (this.overlaypanel) {
            this.overlaypanel.hide();
          }
        }
      }
    }
  }

  // C153176-4445: handle inquiry index selection
  handleDupInqSelection() {
    const me = this;
    setTimeout(() => {
      let elems = $('#inquiryRulesGridDuplicate .duplicate-badge');
      if (!elems || !elems.length) {
        return;
      }
      elems.click(event => {
        me.selectInquiryRule(event);
      });
    }, 1000);
  }
  // C153176-4445 | Download Inquiry rules as excel

  /**
   * C153176-4445 : Method to to download Inquiry rules as Excel file
   */
  download_rules() {
    const displayedColumns = this.gridApiInquiry.getAllDisplayedColumns();
    let columnList = displayedColumns.filter(col => col.colId !== "order").map(column => column.colId);
    let headerMsg = [
      [{ data: { type: "String", value: "The rules in this file will overwrite the rules shown in the screen." }, mergeAcross: 6 }], []
    ];
    let params = {
      customHeader: headerMsg,
      columnKeys: columnList,
      fileName: "Inquiry Rules",
      sheetName: "Inquiry Rules"
    };
    this.gridApiInquiry.exportDataAsExcel(params);
  }

  /**
   * C153176-4445: Method to open the file explorer to browse the file.
   */
  uploadInquiry() {
    this.uploadFilesForInquiry.nativeElement.click();
  }

  /**
   * C153176-4445: Method to upload the inquiry rule and Read the inquiry rules.
   */
  fileEventForInquiry(evt) {
    this.invalidRowMessage = [];
    this.uploadedValidInqRules = [];

    const fileValidationObj = this.validateFileUpload(evt.target.files[0].name);
    if (fileValidationObj.isValid) {
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        // Reset the upload value for the same file to upload.
        evt.target.value = null;

        let arrayBuffer = fileReader.result;
        let headers = this.excelService.getHeaderRow(arrayBuffer, 2);

        const headerValidateObj = this.validateFileHeader(headers);
        if (headerValidateObj.isValid) {
          let uploadInquiryRules = this.excelService.readExcelContent(arrayBuffer, false, 2);
          const inquiryRulesObj = this.getValidAndInvalidRules(uploadInquiryRules);
          let rejectRowMap = inquiryRulesObj.invalid;
          this.uploadedValidInqRules = [...inquiryRulesObj.valid];

          if (rejectRowMap.size > 0) {
            rejectRowMap.forEach((val, key) => {
              let rejectRowsDetails = 'Record #' + key + ' is rejected due to invalid data in ' + val.join(', ') + ' field.\r\n';
              this.invalidRowMessage.push(rejectRowsDetails);
            });
            // Show Rejected Inquiry Rules
            this.inquiryRulesRejected = true;
          }
          else if (this.uploadedValidInqRules.length > 0) {
            this.onInquiryRulesApply();
          }
        }
        else {
          this.onInquiryRulesShowMessage(false, headerValidateObj.errorMessage);
        }
      }
      fileReader.readAsArrayBuffer(evt.target.files[0]);
    }
    else {
      // Reset the upload value for the same file to upload.
      evt.target.value = null;
      this.onInquiryRulesShowMessage(false, fileValidationObj.errorMessage);
    }
  }

  fileEvent(evt) {
    this.errAndSuccess = "";
    this.uploadData = [];
    let files = evt.target.files;
    if (evt.target.files[0].name.includes(".csv")) {
      let file = files[0];
      let reader = new FileReader();
      reader.readAsText(file);
      reader.onload = (event) => {
        // null handling for result
        if (!event.target['result']) {
          return;
        }
        let arr = (<string>event.target['result']).split('\r\n');
        arr.splice(0, 1)
        if (arr.length > 0) {
          this.getUserListByUpload(arr); //  Here you can call the above function.
          this.isGroupAdmin = true;
          this.errAndSuccess = "Success Confirmation"
          this.errorMessageForGroupAdmin = " File Uploaded successfully";
          setTimeout(() => { this.isGroupAdmin = false; }, 3000);

        }
      }
    } else if (evt.target.files[0].name.includes(".xlsx") || evt.target.files[0].name.includes(".xls")) {
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        let arrayBuffer = fileReader.result;
        this.bulkUploadData = this.excelService.readExcelContent(arrayBuffer, false);
        this.bulkUploadData.forEach(element => {
          this.uploadData.push(element.soeid)
        });
        if (this.uploadData.length > 0) {
          this.getUserListByUpload(this.uploadData); // Here you can call the above function.
          this.isGroupAdmin = true;
          this.errAndSuccess = "Success Confirmation"
          this.errorMessageForGroupAdmin = " File Uploaded successfully";
          setTimeout(() => { this.isGroupAdmin = false; }, 3000);
        }
      }
      fileReader.readAsArrayBuffer(evt.target.files[0]);
    } else {
      this.isGroupAdmin = true;
      this.errAndSuccess = "Error Message"
      this.errorMessageForGroupAdmin = "File uploaded failed";
      setTimeout(() => { this.isGroupAdmin = false; }, 3000);
    }
  }
  getUserListByUpload(userList) {
    this.groupAdminService.getValidateBulkUsers({ "userIdList": userList }).subscribe(response => {
      if (response != undefined && response != null) {
        let length = this.addNewUserData.length;
        response.validation.forEach(element => {
          if (element.status == "Valid") {
            element.role = 'Standard User';
          } else {
            element.role = 'NA';
          }
          this.addNewUserData.push(
            { 'user': element.name, 'soeid': element.userId, 'role': element.role, 'status': element.status, 'validID': element.name }
          )
        });
        this.addNewUserGridApi.applyTransaction({ add: this.addNewUserData });
        this.addNewUserData.length == 0 ? this.newUserGridFlag = true : this.newUserGridFlag = false;
        this.uploadFiles.nativeElement.value = "";
        for (var index = length; index < this.addNewUserData.length; index++) {
          this.addNewUserGridApi.startEditingCell({
            rowIndex: index,
            colKey: "ROLE"
          });
        }

      }
    });
  }

  upload_csv() {
    this.uploadFiles.nativeElement.click();
  }

  keyDownFunction(event) {
    this.userEntitlementFlag = false;
    this.newUserSoeIdValidationFlag = false;
    if (event.keyCode == 13) {
      console.log(this.addNewUserData.some(item => item.soeid === this.newUserSoeid))
      if (!AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.newUserSoeid)) {
        this.groupAdminService.getUserDataById({ 'userId': this.newUserSoeid.trim() }).subscribe(userDetails => {
          if (userDetails !== null) {

            let rowData: any[] = [];
            this.entitlementValidationMsg = ''; /*C153176-5006 hide error message if user id is available */
            this.addNewUserData.splice(0, 0, { 'user': userDetails.userName, 'soeid': userDetails.userId, 'role': 'Standard User', 'status': 'Valid' });
            this.addNewUserData.length == 0 ? this.newUserGridFlag = true : this.newUserGridFlag = false;
            rowData.splice(0, 0, { 'user': userDetails.userName, 'soeid': userDetails.userId, 'role': 'Standard User', 'status': 'Valid' });

            this.addNewUserGridApi!.applyTransaction({
              add: rowData,
              addIndex: this.addNewUserData.length,
            })!;
            //this.addNewUserGridApi.applyTransaction(this.addNewUserData);
            this.addNewUserGridApi.setFocusedCell(0, "role");
            this.addNewUserGridApi.startEditingCell({
              rowIndex: 0,
              colKey: "ROLE"
            });
          } else if (userDetails == null) {                  /*C153176-5006 show error message if user id is not available */
            this.userEntitlementFlag = true;
            this.entitlementValidationMsg = 'User Id not found!';

          }
          this.newUserSoeid = '';
        });
      } else {
        this.newUserSoeIdValidationFlag = true;
      }
    }
  }

  onSelectionChanged() {
    let arr = this.entitleGridApi.getSelectedRows();
    this.entitleSelUser = arr.length;
    if (this.entitleSelUser == 0) {
      this.entitleDelAndGropFlag = true;
    } else {
      this.entitleDelAndGropFlag = false;
    }
  }
  onSelectionCheck() {
    let arr = this.metaDataGridApi.getSelectedRows();
    this.metadataRecords = arr.length;
    if (this.metadataRecords == 0) {
      this.metaDeleteFlag = true;
    } else {
      this.metaDeleteFlag = false;
    }
  }
  onDomainCheck() {
    let arr = this.gridApi.getSelectedRows();
    this.domainRecords = arr.length;
    if (this.domainRecords == 0) {
      this.domainDeleteFlag = true;
    } else {
      this.domainDeleteFlag = false;
    }
  }

  onSelectionRules() {
    let selectedRouteCount = 0;
    let arr = this.gridApiInquiry.getSelectedRows();
    selectedRouteCount = arr.length;
    if (selectedRouteCount == 0) {
      this.rulesDeleteFlag = true;
    } else {
      this.rulesDeleteFlag = false;
    }
  }

  isFirstColumn(params) {
    var displayedColumns = params.api.getAllDisplayedColumns();
    var thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  onTabChange(event) {
    this.inquiryFlag = event.index;
    if (this.adminTabs.tabs[event.index].header == "USER ENTITLEMENTS") {
      let res = this.entitleGridApi.getSelectedRows();
      if (res.length == 0) {
        this.entitleDelAndGropFlag = true;
      } else {
        this.entitleDelAndGropFlag = false;
      }
    }
    // Not to show group name at group details page - Jira:4316 - 4 point - By Om
    this.showGroupName = (this.adminTabs.tabs[event.index].header == "GROUP DETAILS") ? false : true;


  }
  onEntitlementUserChange(_index: number) {
    this.tabArray.fill(false);
    this.tabArray[_index] = true;
  }


  deleteUserFromEntitlement() {
    this.showPopUpDelete = false; // Jira:4703 : Confirmation Dialog before delete
    this.errorMessageForGroupAdmin = "";
    let selectedCount = 0;
    let totalCount = 0;
    let lastIndex = 0;

    var selectedData = this.entitleGridApi.getSelectedRows();
    for (let index = 0; index < selectedData.length; index++) {
      if (selectedData[index].role.includes("Group Admin")) {
        selectedCount++;
        lastIndex = index;
      }
    }

    for (let i = 0; i < this.entitleListBackup.length; i++) {
      if (this.entitleListBackup[i].role.includes("Group Admin")) {
        totalCount++;
      }
    }
    if (selectedCount === totalCount) {
      this.isGroupAdmin = true;
      this.errorMessageForGroupAdmin = "User '" + selectedData[lastIndex].user + "' cannot be removed! The group should have atleast one Group Admin."
      selectedData.splice(lastIndex, 1);
      setTimeout(() => { this.isGroupAdmin = false; }, 3000);
    }
    if (selectedCount === totalCount) {
      this.isGroupAdmin = true;
      this.errAndSuccess = "Error Message";
      this.errorMessageForGroupAdmin = "User '" + selectedData[lastIndex].user + "' cannot be removed! The group should have atleast one Group Admin."
      selectedData.splice(lastIndex, 1);
    }

    selectedData.forEach(element => {
      if (element['role'].indexOf(',') != -1) {
        let roleArr = element['role'].split(',')
        roleArr = roleArr.map(function (el) {
          return el.trim();
        });
        roleArr.forEach(role => {
          if (role == 'Analyst') {
            this.finAnaArr.push(element['soeid']);
          } else if (role == 'Peer Reviewer') {
            this.finPeerRevArr.push(element['soeid']);
          } else if (role == 'Supervisor') {
            this.finSupArr.push(element['soeid']);
          } else if (role == 'Group Admin') {
            this.finGrpAdmArr.push(element['soeid']);
          }
        });
      } else {
        if (element['role'] == 'Analyst') {
          this.finAnaArr.push(element['soeid']);
        } else if (element['role'] == 'Peer Reviewer') {
          this.finPeerRevArr.push(element['soeid']);
        } else if (element['role'] == 'Supervisor') {
          this.finSupArr.push(element['soeid']);
        } else if (element['role'] == 'Group Admin') {
          this.finGrpAdmArr.push(element['soeid']);
        }
      }
    });

    var res = this.entitleGridApi.applyTransaction({ remove: selectedData });
    for (var j = 0; j < selectedData.length; j++) {
      let indx = this.entitleListBackup.map(function (x) { return x.soeid; }).indexOf(selectedData[j].soeid);
      this.entitleListBackup.splice(indx, 1);
    }

  }

  deleteMetaRecords() {
    this.showPopUpDelete = false; // Jira:4703 : Confirmation Dialog before delete
    this.errorMessageForMetadata = "";
    let selectedMetaData = this.metaDataGridApi.getSelectedRows();
    this.routesList.forEach(element => {
      if (element.at === selectedMetaData[0].tags) {
        this.isExist = true;
        this.errorMessageForMetadata = "Some of Inquiry rules are enabled with Tag '" + element.at + "'. " +
          "please remove '" + element.at + "' Tag from Inquiry rules and ensure '" + element.at + "' Tag is not referred on any of Inquiry Rules then delete the Tag."
        setTimeout(() => { this.isExist = false; }, 3000);
      } else if (element.apr === selectedMetaData[0].processingRegion) {
        this.isExist = true;
        this.errorMessageForMetadata = "Some of Inquiry rules are enabled with Processing Region '" + element.apr + "'. " +
          "please remove '" + element.apr + "' Processing Region from Inquiry rules and ensure '" + element.apr + "' is not referred on any of Inquiry Rules then delete the Processing Region."
        setTimeout(() => { this.isExist = false; }, 3000);
      }
    });
    if (this.metaDataType === 'Root Cause' || this.metaDataType === 'RequestTypes' || !this.isExist) {
      this.metaDataGridApi.applyTransaction({ remove: selectedMetaData });

      // Update metaRowData to remove the selected rows
      selectedMetaData.forEach(element => {
        let key = Object.keys(element)[0];
        let ind = this.metaRowData.findIndex(x => x[key] === element[key]);
        if (ind !== -1) {
          this.metaRowData.splice(ind, 1);  // Remove from metaRowData
        }
      });

      // C170665-10 | Update the request type and root cause mapping
      this.updateRequestTypeRootCauseMapping(selectedMetaData, this.metaDataType);
    }
  }

  deleteDomainRecords() {
    this.showPopUpDelete = false;
    let selectedDomainData = this.gridApi.getSelectedRows();
    this.gridApi.applyTransaction({ remove: selectedDomainData });
    selectedDomainData.forEach(element => {
      let ind = this.prdData.map(function (x) { return x.domain; }).indexOf(element.domain);
      this.prdData.splice(ind, 1);
    });
  }
  deleteInquiryRulesRecords() {
    this.showPopUpDelete = false; // Jira:4703 : Confirmation Dialog before delete

    // Get selected rows from the grid
    let selectedInquiryRules = this.gridApiInquiry.getSelectedRows();
 if (!selectedInquiryRules || selectedInquiryRules.length === 0) {
      return;
    }

    // Apply transaction to remove selected rows from the grid
    this.gridApiInquiry.applyTransaction({ remove: selectedInquiryRules });

    // Update routesList by filtering out the selected rows
    this.routesList = this.routesList.filter(route =>
      !selectedInquiryRules.some(selectedRule => route.ruleOrder === selectedRule.ruleOrder)
    );

    // Recalculate order for the remaining routes
    this.routesList.forEach((element, index) => {
      element.order = index + 1; // Ensure correct ordering
    });
this.gridApiInquiry.setRowData(this.routesList);
  }

  // Inquiry Rules screen redesign and Rules Reordering C153176-4445
  copyInquiryRecords() {
    const selectedInquiryforCopied = JSON.parse(JSON.stringify(this.gridApiInquiry.getSelectedRows()));
    const coipedRow = selectedInquiryforCopied.length;

    selectedInquiryforCopied.forEach((element, index) => {
      element['order'] = '';
    });
    selectedInquiryforCopied.push(...this.routesList);
    this.routesList = selectedInquiryforCopied;
    this.routesList.forEach((element, index) => {
      element.order = index + 1;
    });
    this.gridApiInquiry.applyTransaction({ add: this.routesList });

    //C153176-4445 | Select the copied row from the grid.
    setTimeout(() => {
      this.gridApiInquiry.forEachNode(function (node, index) {
        if (index < coipedRow) {
          node.setSelected(true);
        }
        else {
          node.setSelected(false);
        }
      });
    }, 200);
  }

  checkDuplicate(event, overlaypanel: OverlayPanel): void {
    overlaypanel.toggle(event);
    this.duplicateColumn = [
      // disabling inq rules reorder will be enabled later
      {
        colId: 'rule1',
        field: "rule1",
        headerName: 'RULE',
        width: 145,
        editable: false,
        cellStyle: { 'color': '#00bdf2', 'height': '41px', 'width': '140px' },

      },
      {
        colId: 'value1',
        field: "value1",
        headerName: 'VALUE',
        width: 200,
        editable: false,
        tooltipField: 'value1',
        cellStyle: { 'left': '158px !important', 'width': '215px' }
      },
      {
        colId: 'rule2',
        field: "rule2",
        headerName: 'RULE',
        width: 137,
        editable: false,
        cellStyle: { 'color': '#00bdf2', 'height': '41px', 'left': '350px !important', 'width': '115px' },


      },
      {
        colId: 'value2',
        field: "value2",
        headerName: 'VALUE',
        width: 200,
        editable: false,
        tooltipField: 'value2',
        cellStyle: { 'left': '497px !important', 'width': '190px' }
      },
      {
        colId: 'rule3',
        field: "rule3",
        headerName: 'RULE',
        width: 100,
        editable: false,
        cellStyle: { 'color': '#00bdf2', 'height': '41px', 'left': '690px !important', 'width': '90px' },


      },
      {
        colId: 'value3',
        field: "value3",
        headerName: 'VALUE',
        cellStyle: { 'color': '#00bdf2', 'height': '41px', 'left': '800px !important', 'width': '165px' },
        width: 155,
        editable: false,
        tooltipField: 'value3',
        enableBrowserTooltips: true,
      },
      {
        colId: 'order',
        field: "order",
        headerName: 'RULE#',
        width: 101,
        editable: false,
        cellStyle: { 'left': '945px !important' },
        //cellRendererFramework: InquiryOrderComponent
        cellRenderer: (params) => {
          let indexHtml = '';
          let temp = [];
          params.data.indexList.forEach(element => {
            temp.push(element);
            if (temp.length < 2) {
              // C153176-4454: add class for the span elements
              indexHtml += '<span class="notify-badge-inq-rules duplicat-badge-color-normal duplicate-badge" >' + element + '</span>';
            } else {
              indexHtml += '<span class="notify-badge-inq-rules duplicat-badge-color-warning duplicate-badge" >' + element + '</span>';
            }

          });
          return indexHtml;
        }
      }
    ];
    this.duplicateCheckBox = {
      resizable: true,
      suppressMovable: true,
      suppressMenu: true,
    };
    this.duplicateList = [];
    this.routeListBackup = [];
    var launchOptimistic = this.routesList.map((elem) => {
      return {
        rule1: elem.rule1,
        value1: elem.value1,
        rule2: elem.rule2,
        value2: elem.value2,
        rule3: elem.rule3,
        value3: elem.value3
      }
    });
    let launchFilterd = _.filter(
      _.uniq(
        _.map(launchOptimistic, function (elem) {
          if (_.filter(launchOptimistic, {
            rule1: elem.rule1,
            value1: elem.value1,
            rule2: elem.rule2,
            value2: elem.value2,
            rule3: elem.rule3,
            value3: elem.value3
          }).length > 1) {
            return elem;
          }

          return false;
        })),
      function (value) { return value; });
    let t2 = _.uniqWith(launchFilterd, _.isEqual);
    this.duplicateList = t2;
    this.gridApiDuplicateInq.applyTransaction({ add: this.duplicateList });
    this.duplicateList.forEach(elem => {
      let index = this.routesList.filter(x => (x.rule1 == elem.rule1 && x.value1 == elem.value1 && x.rule2 == elem.rule2 && x.value2 == elem.value2 && x.rule3 == elem.rule3 && x.value3 == elem.value3)).map(x => x.order);
      elem.indexList = index;
    })
  }

  restoreRulesShowPopUp(event, overlaypanel: OverlayPanel): void {
    overlaypanel.toggle(event);
    // for C153176-5633 Restore button implemention for the rules screen
    this.groupAdminService.getGroupDataByGroup(this.selectedGroup['name']).subscribe((data) => {
      if (data.backupRouteDate) {
        this.restoreInquiryDate = new Date(data.backupRouteDate);
        this.restoreStatus = true;
      }
      else {
        this.restoreStatus = false;
      }
    });
  }
  closeRestorePopup(event, overlayPanel) {
    overlayPanel.hide();
  }
  // C153176-4445: row data has been populated, registering callback for user clicks
  dupGridRowDataChanged(event: any) {
    this.handleDupInqSelection();
  }

  restoreRules(): void {
    this.routesList = [];
    this.routesList = [...JSON.parse(sessionStorage.getItem('routeListBackup'))];
    this.gridApiInquiry.applyTransaction({ add: [...JSON.parse(sessionStorage.getItem('routeListBackup'))] });
    this.validationRules = '';
  }
  hideDuplicateModal(overlaypanel) {
    overlaypanel.hide();
  }

  resetMetadata() {
    this.metaDataType = 'RequestTypes';
    this.selectedMetaDataType = 'RequestTypes';
    this.metaRowData = [];
    this.metaRowData = JSON.parse(JSON.stringify(this.metadataBackup));
    this.metaDataGridApi.applyTransaction({ add: this.metadataBackup });
  }

  onBack() {
    this.inquiryFlag = 0;
  }

  getAssignedGroups(selAssignedGrps: any) {
    this.selectedEntGrpName = [];
    selAssignedGrps.forEach(data => {
      this.selectedEntGrpName.push(data.value);
    });
  }

  entitleAggGroup(event, overlaypanel) {
    var selectedData = this.entitleGridApi.getSelectedRows();
    let userList = [];
    selectedData.forEach(element => {
      let roleArr = element.role.split(',');
      if (roleArr.length == 0) {
        userList.push({ "userName": element.user, "soeId": element.soeid, "userRole": element.role });
      } else {
        roleArr.forEach(role => {
          userList.push({ "userName": element.user, "soeId": element.soeid, "userRole": role });
        });
      }
    });
    overlaypanel.hide();
    this.callAssignGroupService(userList, this.selectedEntGrpName)
    this.closeFlagAddGroup = true;
    setTimeout(() => { this.closeFlagAddGroup = false; }, 3000);
    this.selectedEntGrpName = [];
  }

  callAssignGroupService(userList, selectedEntGrpName) {
    this.groupAdminService.getBulkAssignUsersToGroups({
      "addToGroup": {
        "groupList": selectedEntGrpName,
        "userList": userList
      }
    }
    ).subscribe(response => {
      if (response !== null) {
        this.userAddGroupMsg = response.message;
      }
    });
  }

  /**
   * C153176-5629 | Issue- Unable to save rules for any group error displayed.
   *
   * Method to populate the group entitlements.
   *
   * @param elementList
   */
  entitlementDetails(elementList: any): void {
    this.finAnaArr = [];
    this.finPeerRevArr = [];
    this.finSupArr = [];
    this.finGrpAdmArr = [];
    this.entitleListBackup = [];
    this.entitleDataUserName = [];
    this.groupUsers = [];
    this.entitleData = [];
    elementList.forEach(element => {
      let role = new Set<string>();
      let groupRole = '';
      if (element.groupRoles != null) {
        element.groupRoles.forEach(roleEle => {
          role.add(roleEle.groupRole);
        });
        const roles = Array.from(role);
        groupRole = roles.join(', ');
      }
      this.entitleData.push({ 'user': element.userName, 'soeid': element.userId, 'role': groupRole })
      this.entitleListBackup = JSON.parse(JSON.stringify(this.entitleData));
      // C153176-4445: Populate the User for the group
      this.groupUsers.push(element.userName);
    });
    this.routesList.forEach(element => {
      this.entitleData.forEach(element1 => {
        if (element1.soeid === element.user) {
          element.user = element1.user;
        }
      });
    });
    for (let i = -1; i < this.entitleData.length; i++) {
      if (i === -1) {
        this.entitleDataUserName.push('');
      } else {
        this.entitleDataUserName.push(this.entitleData[i].user);
      }
    }
    this.routesColumn.forEach(i => {
      if (i.colId === 'user') {
        i.cellEditorParams.values = this.entitleDataUserName;
      }
    });
  }

  resetValue() {
    this.version = 0;
    this.gfcid = [];
    this.subjectEscalationList = [];

    this.groupManager = "";
    this.makerCheckerRqd = false;
    this.predictiveRecipients = false;
    this.enableAutoAssignment = false;
    this.enableAutoReplySuggestion = false;
    this.isAutoAssignmentRollOutOrgs = false;
    this.isEmailSharingBlocked = false;
    this.isInquirySourceMandatory = false;
    this.isGfidMandatory = false;
    this.isInqReferenceIdReq = false;
    this.excludeMyGroupWhileReplyAll = false;
    this.hideClientList = false;
    this.supervisiorApprovalForReage = false;
    this.holidayAndShiftDetails = false;
    this.autoResponse = '';
    this.emailDisc = '';
    this.enableAutCliAck = false;
    this.entitleGridApi.deselectAll();
    this.converThreshold = 0;
    this.resTimeThreHr = null;
    this.resTimeThreMin = null;
    this.penApprThreHr = null;
    this.penApprThreMin = null;
    this.getEditorInstance('autoResponse').setData("");
    this.getEditorInstance('emailDisc').setData("Disclaimer");
  }

  groupChange() {
    // C153176-4493 - Validation error should be reset on group change
    this.ClientNameValidation = '';
    this.validationRules = ''; // Inquiry rules validation should be blank if group change

    // C153176-4445 - Reset the values before assign new values.
    this.invalidRowMessage = [];
    this.groupRequestTypes = [];
    this.groupProcessingRegion = [];
    this.groupTags = [];
    this.groupRootCauses = [];

    // C153176-5408 | Admin : Upload User list should clear on change of group.
    this.newUserGridFlag = true;
    this.uploadData = [];
    this.addNewUserData = [];
    this.inqAssignedGroups = [];

    if (!this.hasGroupRole) {
      return;
    }

    // C153176-5629 | Issue- Unable to save rules for any group error displayed
    const groupEntitlement$ = this.groupAdminService.getGroupDataEntitlement(this.selectedGroup['name']);
    const groupDatabyGroup$ = this.groupAdminService.getGroupDataByGroup(this.selectedGroup['name']);

    // Wait until both the services got resolved.
    forkJoin([groupEntitlement$, groupDatabyGroup$]).subscribe(resultList => {
      let groupEntitlements = resultList[0];
      let groupDetailsData = resultList[1];

      this.entitlementDetails(groupEntitlements);
      this.populateGroupDataDetails(groupDetailsData);
    }, error => {
      console.error('Error while getting response from getGroupDataByGroup() and getGroupDataEntitlement()');
    });

    /* Automated Response main page will come if group changes */
    this.backToEditor();
  }

  /**
   * C153176-5629 | Issue- Unable to save rules for any group error displayed
   *
   * Method to populate group details.
   * @param element
   */
  populateGroupDataDetails(element: any): void {
    this.selectedAdminGroupName = this.selectedGroup['name']
    this.resetValue();
    this.inqAssignedGroups = this.userDataService.getGroupListForRule();
    this.createRouteColumn();
    this.overrideCheckbox = element.groupManagerAboveL7 == null ? false : element.groupManagerAboveL7;
    // Inquiry Rules screen redesign and Rules Reordering C153176-4445
    this.routesList = [];
    this.routeListBackup = [];
    if (element.routes) {
      element.routes.forEach((route, index) => {
        let obj: any = {};
        obj.order = index + 1;
        obj.field1 = 'From';
        obj.rule1 = route.fromOperator;
        obj.value1 = route.from;
        obj.field2 = 'Subject';
        obj.rule2 = route.subjectOperator;
        obj.value2 = route.subject;
        obj.field3 = 'To';
        obj.rule3 = route.toOperator;
        obj.value3 = route.to;
        obj.checkbox = route.actions.markAsNonInquiry;
        obj.attachment = route.attachment;
        // Inquiry Rules screen redesign and Rules Reordering C153176-4445
        let processing = [];
        if (element.processingRegionList) {
          for (let i = 0; i < element.processingRegionList.length; i++) {
            if (element.processingRegionList[i] === route.actions.assignProcessingRegion)
              processing.push(route.actions.assignProcessingRegion);
          }
        }
        obj.apr = processing;
        let assignRequest = [];
        if (element.requestTypeMappings) {
          for (let i = 0; i < element.requestTypeMappings.length; i++) {
            if (element.requestTypeMappings[i].requestType === route.actions.assignRequestType)
              assignRequest.push(route.actions.assignRequestType);
          }
        }
        obj.art = assignRequest;
        let assigntTag = [];
        if (element.tags) {
          for (let i = 0; i < element.tags.length; i++) {
            if (element.tags[i] === route.actions.assignToTag)
              assigntTag.push(route.actions.assignToTag);
          }
        }
        obj.at = assigntTag;
        let assignUser = [];
        if (this.entitleData) {
          for (let i = 0; i < this.entitleData.length; i++) {
            if (this.entitleData[i].soeid === route.actions.assignToUserId)
              assignUser.push(this.entitleData[i].user);
          }
        }
        obj.user = assignUser;
        obj.assign = route.actions.assignGroup ? route.actions.assignGroup : "";
        obj.ruleOrder = route.ruleOrder;
        obj.deletion = route.actions.markForDeletion;
        this.routesList.push(obj);
      });
    }
    sessionStorage.setItem('routeListBackup', JSON.stringify(this.routesList));
    /* Inquiry Rules screen redesign and Rules Reordering C153176-4445 end */
    // Group Details
    if (element.heirarchyData != null && element.heirarchyData.thirdLevelHeirarchy != null) {
      this.heirarchyData = element.heirarchyData;
      this.groupManager = this.heirarchyData.thirdLevelHeirarchy[0].userName;
      this.groupManagerID = this.heirarchyData.thirdLevelHeirarchy[0].userId;
      this.callLookupService(false);
    }

    // Preferences
    this.makerCheckerRqd = element.makerCheckerRqd;
    this.makerCheckerRqd == true ? this.enablePeerReviewDiv = false : this.enablePeerReviewDiv = true;
    element.attachmentBasedMakerChecker == true ? this.peerRevDomain = "Attachments" : this.peerRevDomain = "All";
    this.predictiveRecipients = element.predictiveRecipients;
    this.enableAutoAssignment = element.enableAutoAssignment;
    this.enableAutoReplySuggestion = element.enableAutoReplySuggestion;
    this.isEmailSharingBlocked = element.isEmailSharingBlocked;
    this.isInquirySourceMandatory = element.isInquirySourceMandatory;
    this.isGfidMandatory = element.isGfidMandatory;
    this.isInqReferenceIdReq = element.isInqReferenceIdReq;
    this.excludeMyGroupWhileReplyAll = element.excludeMyGroupWhileReplyAll;
    this.hideClientList = element.hideClientList;
    this.supervisiorApprovalForReage = element.supervisiorApprovalForReage;
    this.requestTypeRootCauseFilter = element.requestTypeRootCauseFilter; // C170665-10 : Request Type and Root Cause Linking
    this.isRootCauseMandatory = element.isRootCauseMandatory;
    this.isRootCauseMandatoryWOReply = element.isRootCauseMandatoryWOReply;
    this.isProcessingRegionMandatory = element.isProcessingRegionMandatory;
    this.isTagMandatory = element.isTagMandatory;
    this.enableInquirySubStatus = element.enableInquirySubStatus; //[C170665-1719] DCC Requirement: Add Case status field


    if (element.holidayAndShiftDetails != null) {
      this.holidayAndShiftDetails = element.holidayAndShiftDetails.holidayBasedAgeCalculation;
    }
    this.holidayAndShiftDetails == true ? this.enableWorkShiftDiv = false : this.enableWorkShiftDiv = true;

    let country = { countryCode: "", countryName: "Select country" }; // C153176-6027 | Set with default value
    this.countryList.filter(function (ele) {
      if (ele.countryCode == element.country) {
        country = ele;
      }
    });
    this.selectedCountry = country; // It is required field to save request type.

    let timeZone = { timeZone: "", timeZoneCode: "", timeZoneName: "Select time zone", utcOffset: "" }; // C153176-6027 | Set with default value
    this.timeZones.filter(function (ele) {
      if (ele.timeZoneCode == element.timeZone) {
        timeZone = ele;
      }
    });
    this.selectedTimeZone = timeZone;

    if (element.holidayAndShiftDetails != null) {
      let startTime;
      this.shifttimeList.filter(function (ele) {
        if (ele.value == element.holidayAndShiftDetails.shiftStartTime) {
          startTime = ele;
        }
      });

      this.selectedWeekelyOff = element.holidayAndShiftDetails.weeklyOffDays;

      let endTime;
      this.shifttimeList.filter(function (ele) {
        if (ele.value == element.holidayAndShiftDetails.shiftEndTime) {
          endTime = ele;
        }
      });
      this.selectedStartTime = startTime;
      this.selectedEndTime = endTime;
    }
    let keepGoing = true;
    if (element && element.heirarchyData && element.heirarchyData.orgHierarchy) {
      element.heirarchyData.orgHierarchy.forEach(obj => {
        if (obj.level == "L2") {
          let orgHierarchyOrgName = obj.orgName.toLowerCase();
          if (this && this.autoAssignmentRollOutOrgsList) {
            this.autoAssignmentRollOutOrgsList.forEach(orgsKeys => {
              if (orgHierarchyOrgName.includes(orgsKeys) && keepGoing) {
                this.isAutoAssignmentRollOutOrgs = true;
                keepGoing = false;
              }
            });
          }
        }
      });
    }


    // Peer review domain
    this.prdData = [];
    if (element.makerCheckerDomains != null) {
      element.makerCheckerDomains.forEach(domainEle => {
        this.prdData.push({ "domain": domainEle, 'domainId': domainEle });
      });
      if (this.gridApi) {
        this.gridApi.applyTransaction({ add: this.prdData })
      }
    }

    // Email Disclaimer
    this.emailDisc = element.disclaimer;

    // Automated Response
    this.autoResponse = element.autoResponseDetails != null ? this.autoResponse = element.autoResponseDetails[0].value : this.autoResponse = '';
    this.enableAutCliAck = element.autoReplyEnable;
    this.initAutoResponseContent();

    // Esc criteria
    this.converThreshold = element.convCountThresholdForEscalation > 0 ? element.convCountThresholdForEscalation : 0;

    let escRespTimeThresholdHrs;
    let escRespTimeThresholdMin
    // Group escalation criteria
    if (element.responseTimeThresholdForEscalation && element.responseTimeThresholdForEscalation > 0) {
      escRespTimeThresholdHrs = Math.floor(element.responseTimeThresholdForEscalation / 60);
      escRespTimeThresholdMin = element.responseTimeThresholdForEscalation % 60;

      if (escRespTimeThresholdHrs > 0) {
        this.resTimeThreHr = escRespTimeThresholdHrs;
      }
      if (escRespTimeThresholdMin > 0) {
        this.resTimeThreMin = escRespTimeThresholdMin;
      }
    } else {
      this.resTimeThreHr = null;
      this.resTimeThreMin = null;
    }
    console.log('!!!element.responseTimeThresholdForEscalation!!!!!!!! ' + element.responseTimeThresholdForEscalation);
    console.log('!!!this.resTimeThreHr!!!!!!!! ' + escRespTimeThresholdHrs);
    console.log('!!!this.resTimeThreMin!!!!!!!! ' + escRespTimeThresholdMin);

    let paEscRespThresholdHrs;
    let paEscRespThresholdMin;
    // Add Escalation criteria for 'Pending Approval' emails
    if (element.paResponseTimeThresholdForEscalation && element.paResponseTimeThresholdForEscalation > 0) {
      paEscRespThresholdHrs = Math.floor(element.paResponseTimeThresholdForEscalation / 60);
      paEscRespThresholdMin = element.paResponseTimeThresholdForEscalation % 60;

      if (paEscRespThresholdHrs > 0) {
        this.penApprThreHr = paEscRespThresholdHrs;
      }
      if (paEscRespThresholdMin > 0) {
        this.penApprThreMin = paEscRespThresholdMin;
      }
    } else {
      this.penApprThreHr = null;
      this.penApprThreMin = null;
    }

    console.log('!!!element.paResponseTimeThresholdForEscalation!!!!!!!! ' + element.paResponseTimeThresholdForEscalation);
    console.log('!!!this.penApprThreHr!!!!!!!! ' + escRespTimeThresholdHrs);
    console.log('!!!this.penApprThreMin!!!!!!!! ' + escRespTimeThresholdMin);

    // Addition details
    this.version = element.version;
    this.gfcid = element.gfcid;
    this.subjectEscalationList = element.subjectEscalationList;
    // metadata
    this.requestTypeList = [];
    this.inqRequestTypeList = [];
    // C153176-5114 | If requestTypeMappings is null from DB
    if (element.requestTypes && element.requestTypeMappings) {
      element.requestTypeMappings.forEach(requestTypeMapping => {
        requestTypeMapping.requestID = element.requestType;
        this.requestTypeList.push(requestTypeMapping);
        this.inqRequestTypeList.push(requestTypeMapping.requestType);
      });
      this.inqRequestTypeList.unshift("");
    }
    else if (element.requestTypes) {
      element.requestTypes.forEach(requestType => {
        let requestTypeMapping = {};
        requestTypeMapping['requestType'] = requestType;
        requestTypeMapping['highlevelRequestType'] = "";
        requestTypeMapping['requestID'] = element.requestType;

        this.requestTypeList.push(requestTypeMapping);
        this.inqRequestTypeList.push(requestTypeMapping['requestType']);
      });
      this.inqRequestTypeList.unshift("");
    }
    this.rootCauseList = [];
    if (element.rootCauseList) {
      element.rootCauseList.forEach(rootCause => {
        this.rootCauseList.push({ 'rootCause': rootCause, 'rootID': rootCause });
      });
    }
    this.tagsList = [];
    this.inqTagsList = [];
    this.originalTagList = [];
    if (element.tags) {
      this.originalTagList = element.tags;
      element.tags.forEach(tags => {
        this.tagsList.push({ 'tags': tags, 'tagID': tags });
        this.inqTagsList.push(tags);
      });
      this.inqTagsList.unshift("");
    }

    this.processingRegionList = [];
    this.inqprocessingRegionList = [];
    if (element.processingRegionList) {
      element.processingRegionList.forEach(processingRegion => {
        this.processingRegionList.push({ 'processingRegion': processingRegion, 'processID': processingRegion });
        this.inqprocessingRegionList.push(processingRegion);
      });
      this.inqprocessingRegionList.unshift("");
    }

    // C170665-10 | Populate the Request type and Root Cause Mapping
    this.reqTypeRootCauseMap = [];
    this.updatedReqTypeCauseMap = []; // Clear this on group change
    let reqTypeRootCauseMap = element.requestTypeRootCauseMapping;
    if (reqTypeRootCauseMap) {
      for (const [key, value] of Object.entries(reqTypeRootCauseMap)) {
        if (Array.isArray(value)) {
          this.reqTypeRootCauseMap.push({ 'requestType': key, 'rootCause': value });
        }
      }
    }

    this.selectMetaDataType(this.metaDataType, 'onload');
    // inquiry rules
    this.routesColumn.forEach(i => {
      if (i.colId === 'art') {
        i.cellEditorParams.values = this.inqRequestTypeList;
      } else if (i.colId === 'at') {
        i.cellEditorParams.values = this.inqTagsList;
      } else if (i.colId === 'apr') {
        i.cellEditorParams.values = this.inqprocessingRegionList;
      }
    });

    // ClientCategory Data on change of Group
    this.clientCategoryRowData = [];
    if (element.customClientCategory != null && element.customClientCategory != undefined) {
      let clientValue = element.customClientCategory;
      clientValue.forEach(clientValuesRecord => {
        this.clientCategoryRowData.push({
          'categoryName': clientValuesRecord.categoryName,
          'colorCode': clientValuesRecord.colorCode,
          'field': clientValuesRecord.rules.field,
          'operators': clientValuesRecord.rules.operators,
          'value': clientValuesRecord.rules.value
        });
      });
    }
    // C153176-4445: Populate the values from the Group
    this.groupRequestTypes = element.requestTypes ? [...element.requestTypes].sort() : [];
    this.groupProcessingRegion = element.processingRegionList ? [...element.processingRegionList].sort() : [];
    this.groupTags = element.tags ? [...element.tags].sort() : [];
    this.groupRootCauses = element.rootCauseList ? [...element.rootCauseList].sort() : [];

    //C170665-185 for hard code data point
    let secondLevelUserName;
    let orgHierarchyOrgName;
    try {
      // for check second level heirarchy is null or not in hard code data point
      if (element.heirarchyData && element.heirarchyData.orgHierarchy && element.heirarchyData.secondLevelHeirarchy) {
        element.heirarchyData.secondLevelHeirarchy.forEach(obj => {
          secondLevelUserName = obj.userName;
        })
        element.heirarchyData.orgHierarchy.forEach(obj => {
          if (obj.level == "L3") {
            orgHierarchyOrgName = obj.orgName;
          }
        })
        if (secondLevelUserName && orgHierarchyOrgName) {
          this.orgName = orgHierarchyOrgName + '(' + secondLevelUserName + ')';
          let data = { "orgName": this.orgName }
          this.superAdminSetupSerivce.getOrgData(data).subscribe((data: any[]) => {
            this.orgData = data;
            //[C170665-1719] DCC Requirement: Add Case status field
            if (this.orgData && this.orgData.orgPreferences.enableInquirySubStatus) {
              this.isInquirySubStatusEnabledByOrgAdmin = this.orgData.orgPreferences.enableInquirySubStatus == "true" ? true : false;
              if (this.isInquirySubStatusEnabledByOrgAdmin) {
                this.enableInquirySubStatus = this.isInquirySubStatusEnabledByOrgAdmin;
              } else {
                this.enableInquirySubStatus = element.enableInquirySubStatus;
              }
            }
            if (this.orgData) {
              let enableMetaDataStatus = this.orgData.enableOrgLevelMetaData;
              if (enableMetaDataStatus === undefined) {
                enableMetaDataStatus = false;
              }
              if (enableMetaDataStatus) {// for disable org meta data
                this.displayOrgMetadata = true;
                // check for when enable group level metadata flag is not present in db for hard code data point
                if (this.orgData.orgPreferences && this.orgData.orgPreferences.enableGroupLevelOverride) {
                  let enableStatus = this.orgData.orgPreferences.enableGroupLevelOverride == "true" ? true : false;
                  if (enableStatus) {
                    this.disableGroupMetadataAddOption = enableStatus;
                    if (this.metaDataType === 'RequestTypes' || this.metaDataType === 'Root Cause' || this.metaDataType === 'reqTypeCauseMapping') {
                      this.disableAddMetaDataOption = enableStatus;
                    } else {
                      this.disableAddMetaDataOption = false;
                      this.disableGroupMetadataAddOption = false;
                    }
                    this.setOrgRequestTypeForRuleScreen(this.orgData);
                    this.inqRequestTypeList.sort();
                    this.inqRequestTypeList.unshift("");
                  } else {
                    this.disableAddMetaDataOption = false;
                    this.disableGroupMetadataAddOption = false;
                    this.setOrgRequestTypeForRuleScreen(this.orgData);
                    this.setRequestTypeForRuleScreen(element);
                    this.inqRequestTypeList.sort();
                    this.inqRequestTypeList.unshift("");
                  }
                } else {
                  this.setRequestTypeForRuleScreen(element);
                  this.inqRequestTypeList.sort();
                  this.inqRequestTypeList.unshift("");
                  this.disableGroupMetadataAddOption = false;
                  this.disableAddMetaDataOption = false;
                }
              } else {
                this.displayOrgMetadata = false;
                this.setRequestTypeForRuleScreen(element);
                this.inqRequestTypeList.sort();
                this.inqRequestTypeList.unshift("");
                this.disableGroupMetadataAddOption = false;
                this.disableAddMetaDataOption = false;
              }
            } else {
              this.setRequestTypeForRuleScreen(element);
              this.inqRequestTypeList.sort();
              this.inqRequestTypeList.unshift("");
              this.disableGroupMetadataAddOption = false;
              this.disableAddMetaDataOption = false;
            }
          });
        } else {
          this.setRequestTypeForRuleScreen(element);
          this.inqRequestTypeList.sort();
          this.inqRequestTypeList.unshift("");
          this.orgName = '';
          this.disableGroupMetadataAddOption = false;
          this.disableAddMetaDataOption = false;
          this.displayOrgMetadata = false;
        }
      } else {
        this.setRequestTypeForRuleScreen(element);
        this.inqRequestTypeList.unshift("");
        this.orgName = '';
        this.disableGroupMetadataAddOption = false;
        this.disableAddMetaDataOption = false;
        this.displayOrgMetadata = false;
      }
    } catch (error) {
      console.log("Exception has been occured while getting org detail" + error.message);
    }
  }

  enableShiftCng() {
    this.holidayAndShiftDetails == true ? this.enableWorkShiftDiv = false : this.enableWorkShiftDiv = true;
    // C153176-5824 - Clear the message when holidayAndShiftDetails is false;
    if (!this.holidayAndShiftDetails) {
      this.workshiftErrMsg = "";
    }
  }

  enableInquirySubStatusChanged() {
    console.log("In enable Inquiry Sub-Status");
  }

  enablePeerReview() {
    this.makerCheckerRqd == true ? this.enablePeerReviewDiv = false : this.enablePeerReviewDiv = true;
  }

  /**
    * Method to get the Editor instance.
    *
    * @param id - Editor element id
    */
  getEditorInstance(id: string): ExpTinyComponent {
    let exp: ExpTinyComponent;
    this.editorList.forEach(div => {
      if (div && div.elementId == id)
        exp = div;
    });
    return exp;
  }

  hideModal() {
    this.display = false;
    this.editorFlag = false;
    this.validationRules = '';
    this.resetForm();
    this.resetValue();
    this.onBack();   /*<!-- C153176-4445 inquiry redesign->*/
  }
  resetForm() {
    this.showInitial();
    this.ngOnInit();
    this.restoreRules();
    this.resetMetadata();
    this.checkboxListComponent.forEach((comp) => {
      comp.resetCheckList();
    });
    this.selectedEntGrpName = [];
    this.newUserGridFlag = true;
    this.addNewUserData = [];
    this.clientCategoryRowData = [];
    this.resetValue();
    this.prdData = [];
  }

  closeModal(_str: any) {
    if (_str == "success") { this.displaySuccess = false; }
    if (_str == "error") { this.displayError = false; }
  }


  onBtStartEditing(key, char, pinned) {
    this.metaTagValidationFlag = false;
    this.metaTagValidationMsg = '';
    let focussedCell = '';
    let newRowData = null;

    if (this.metaDataType === 'RequestTypes') {
      if (this.metaRowData.length === 0 || this.metaRowData[0].requestType !== this.start_typing_placehoder || this.metaRowData[0].requestType !== "") {
        focussedCell = 'requestType';
        newRowData = {
          requestID: "0",
          requestType: this.start_typing_placehoder,
          highlevelRequestType: "select request type"
        };
      }
    } else if (this.metaDataType === 'Root Cause') {
      if (this.metaRowData.length === 0 || this.metaRowData[0].rootCause !== this.start_typing_placehoder || this.metaRowData[0].rootCause !== "") {
        focussedCell = 'rootCause';
        newRowData = {
          rootCause: this.start_typing_placehoder,
          rootID: "0"
        };
      }
    } else if (this.metaDataType === 'Tags') {
      if (this.metaRowData.length === 0 || this.metaRowData[0].tags !== this.start_typing_placehoder || this.metaRowData[0].tags !== "") {
        focussedCell = 'tags';
        newRowData = {
          tags: this.start_typing_placehoder,
          tagID: "0"
        };
      }
    } else if (this.metaDataType === 'Processing Region') {
      if (this.metaRowData.length === 0 || this.metaRowData[0].processingRegion !== this.start_typing_placehoder || this.metaRowData[0].processingRegion !== "") {
        focussedCell = 'processingRegion';
        newRowData = {
          processingRegion: this.start_typing_placehoder,
          processID: "0"
        };
      }
    }

    if (newRowData) {
      // Add only the new row, not the entire array
      this.metaRowData.splice(0, 0, newRowData);
      // this.metaDataGridApi.applyTransaction({ add: [newRowData] });

      // Apply transaction to add the new row at the top of the grid (row index 0)
      this.metaDataGridApi.applyTransaction({ add: [newRowData], addIndex: 0 });


      // Set focus on the new row and start editing
      this.metaDataGridApi.setFocusedCell(0, focussedCell, pinned);
      this.metaDataGridApi.startEditingCell({
        rowIndex: 0,
        colKey: focussedCell,
        rowPinned: pinned
      });
    }
  }


  addDomain(key, char, pinned) {
    if (this.prdData.length === 0 || this.prdData[0].domain !== "start typing...") {
      this.prdData.splice(0, 0, {
        domain: "start typing...",
        domainId: "0"
      });

      this.gridApi.applyTransaction({ add: this.prdData })

      this.gridApi.setFocusedCell(0, "domain");
      this.gridApi.startEditingCell({
        rowIndex: 0,
        colKey: "domain"
      });
    }
  }

  addRules(key, char, pinned) {
    // Create a new rule object with default empty values for each field
    let newRule = {
      order: 1,        // Set the initial order
      field1: 'From',
      rule1: 'Begins With',
      value1: '',      // Empty value to be filled later
      field2: 'Subject',
      rule2: 'Begins With',
      value2: '',      // Empty value to be filled later
      field3: 'To',
      rule3: 'Begins With',
      value3: '',      // Empty value to be filled later
      checkbox: false,
      apr: '',
      art: '',
      at: '',
      user: '',
      assign: '',
      attachment: false
    };

    // Insert the new rule at the beginning of the routesList
    this.routesList.unshift({ ...newRule }); // Ensure a fresh copy of newRule is added

    // Update the order for all rules in the list
    this.routesList = this.routesList.map((element, index) => {
      return {
        ...element,
        order: index + 1  // Re-index the order for all rows
      };
    });

    // Apply the updated list to ag-Grid
    this.gridApiInquiry.setRowData(this.routesList);  // Update the grid with new data

    // Set focus and start editing the newly added rule
    this.gridApiInquiry.setFocusedCell(0, "rule1");
    this.gridApiInquiry.startEditingCell({
      rowIndex: 0,
      colKey: "rule1"
    });
  }


  selectMetaDataType(metaDataTypeVar, mode?: string) {
    //Jira :  4703 : Metadata Validation for request type
    this.metaTagValidationFlag = false;
    this.metaTagValidationMsg = '';
    this.metaDeleteFlag = true; //Jira :  4703 : Metadata delete button should come when we select any record
    if (mode != 'onload') {
      if (this.metaDataType === 'RequestTypes') {
        this.requestTypeList = this.metaRowData;
      } else if (this.metaDataType === 'Root Cause') {
        this.rootCauseList = this.metaRowData;
      } else if (this.metaDataType === 'Processing Region') {
        this.processingRegionList = this.metaRowData;
      } else if (this.metaDataType === 'Tags') {
        this.tagsList = this.metaRowData;
      }
      // C170665-10 | If not onload, Update the request type - root cause map from the updaed one if any
      if (metaDataTypeVar !== 'reqTypeCauseMapping') {
        if (this.updatedReqTypeCauseMap.length > 0) {
          this.reqTypeRootCauseMap = [...this.updatedReqTypeCauseMap];
          this.updatedReqTypeCauseMap = [];
        }
      }
    }
    this.metaDataType = metaDataTypeVar;
    if (this.metaDataType === 'RequestTypes') {
      this.metaRowData = this.requestTypeList;
      this.metaColumnDefs = [
        {
          colId: 'requestType',
          field: "requestType",
          headerName: 'Request type',
          width: 200,
          cellStyle: { 'display': 'inline', 'width': '250px' },          // css alignment issue
          editable: (params) => (params.data.requestID == "0") ? true : false,
          sortable: false,
          comparator: function (a, b) {
            if (typeof a === 'string') {
              return a.localeCompare(b);
            } else {
              return (a > b ? 1 : (a < b ? -1 : 0));
            }
          }
        },
        {
          colId: 'highlevelRequestType',
          field: "highlevelRequestType",
          headerName: 'Generic request type',
          width: 200,
          editable: true,
          sortable: false,
          cellEditor: "agSelectCellEditor",
          cellStyle: { 'left': '235px !important', 'width': '257px' },// css alignment issue
          cellEditorParams: { values: this.reqList }
        },
        {
          colId: '1',
          field: "requestID",
          hide: true,
          headerName: ''
        }
      ];

    } else if (this.metaDataType === 'Root Cause') {
      this.metaRowData = this.rootCauseList;
      this.metaColumnDefs = [
        {
          field: "rootCause",
          width: 493,
          editable: (params) => (params.data.rootID == "0") ? true : false,
          sortable: false,
          comparator: function (a, b) {
            if (typeof a === 'string') {
              return a.localeCompare(b);
            } else {
              return (a > b ? 1 : (a < b ? -1 : 0));
            }
          }
        },
        {
          colId: '1',
          field: "rootID",
          hide: true,
          headerName: ''
        }
      ];
    } else if (this.metaDataType === 'Tags') {
      this.metaRowData = this.tagsList;
      this.metaColumnDefs = [
        {
          field: "tags",
          width: 493,
          colId: 'tags',           //C153176-4418 tag esc issue
          sortable: false,
          comparator: function (a, b) {
            if (typeof a === 'string') {
              return a.localeCompare(b);
            } else {
              return (a > b ? 1 : (a < b ? -1 : 0));
            }
          }
        },
        {
          colId: '1',
          field: "tagId",
          hide: true,
          headerName: ''
        }

      ];
    } else if (this.metaDataType === 'Processing Region') {
      this.metaRowData = this.processingRegionList;
      this.metaColumnDefs = [
        {
          field: "processingRegion",
          width: 493,
          editable: (params) => (params.data.processID == "0") ? true : false,
          sortable: false,
          comparator: function (a, b) {
            if (typeof a === 'string') {
              return a.localeCompare(b);
            } else {
              return (a > b ? 1 : (a < b ? -1 : 0));
            }
          }
        },
        {
          colId: '1',
          field: "processID",
          hide: true,
          headerName: ''
        }
      ];
    }

    this.metaCheckbox = {
      width: 100,
      checkboxSelection: this.isFirstColumn,
      resizable: true,
      suppressMenu: true,
      suppressMovable: true,
      editable: true,
      cellStyle: { 'height': '41px' }
    };
    //C170665-185 disable add option when org admin disable group level edit option in hard code
    if (this.disableGroupMetadataAddOption) {
      if (this.metaDataType === 'RequestTypes' || this.metaDataType === 'Root Cause' || this.metaDataType === 'reqTypeCauseMapping') {
        this.disableAddMetaDataOption = true;
      } else {
        this.disableAddMetaDataOption = false;
      }
    }
    this.metadataBackup = JSON.parse(JSON.stringify(this.metaRowData));
  }

  ngOnInit() {
    this.setHeaderForClientCategory();
    this.auId = this.userDataService.loggedInUserInfo.globalDirectoryConfig.contactInfoConfig.auid;
    this.contactInfoEndPoint = this.userDataService.loggedInUserInfo.globalDirectoryConfig.contactInfoConfig.contactInfoEndPoint;
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
      this.enableInquirySubStatusFlag = loginUserInfo.enableInquirySubStatusFlag;
    });
    this.metadataTypeList = [
      { label: 'Request Types', value: 'RequestTypes' }, //Speling Mistake
      { label: 'Root Cause', value: 'Root Cause' },
      { label: 'Request Type/ Root Cause Mapping', value: 'reqTypeCauseMapping' }, // C170665-10 : Request Type and Root Cause Linking
      { label: 'Tags', value: 'Tags' },
      { label: 'Processing Region', value: 'Processing Region' },
    ];
    this.weekelyOff = [
      { label: 'Sunday', value: '0' },
      { label: 'Monday', value: '1' },
      { label: 'Tuesday', value: '2' },
      { label: 'Wednesday', value: '3' },
      { label: 'Thursday', value: '4' },
      { label: 'Friday', value: '5' },
      { label: 'Saturday', value: '6' }
    ];
    this.entitleColumns = [
      {
        field: "user",
        headerName: 'USER',
        width: 185,
        editable: false,
        // cellStyle: { 'width': '185x !important' }

      },
      {
        field: "soeid",
        headerName: 'SOEID',
        width: 120,
        editable: false
      },
      {
        field: "role",
        headerName: 'ROLE',
        cellEditor: "agSelectCellEditor",
        width: 220, //Jira : C153176-4323 Issue- Multiple delete actions needed to delete the user from Group Admin.
        cellStyle: { 'white-space': 'normal' },
        cellRenderer: UserEntitlementCheckboxComponent
        // cellEditorParams: {
        //   values: ["Standard User", "Group Admin", "Peer Reviewer", "Supervisor"]
        // }
      }
    ];

    this.entitleColDef = {
      width: 100,
      headerCheckboxSelection: this.isFirstColumn,
      checkboxSelection: this.isFirstColumn,
      resizable: true,
      editable: false,
      cellStyle: { 'background-color': 'white' },
      sortable: false,
    };
    this.rowSelection = "multiple";


    this.addNewUserColumns = [
      {
        field: "user",
        headerName: 'USER',
        width: 150,
        editable: false,
      },
      {
        field: "soeid",
        headerName: 'SOEID',
        width: 100,
        editable: false
      },
      {
        field: "status",
        headerName: 'STATUS',
        width: 105,
        editable: false,
        sort: "asc"
      },
      // Jira : C153176-5182 Group Admin - issue- Design change
      {
        field: "role",
        headerName: 'ROLE',
        cellEditor: "agSelectCellEditor",
        width: 220, //Jira : C153176-4323 Issue- Multiple delete actions needed to delete the user from Group Admin.
        cellStyle: { 'white-space': 'normal', 'height': '38px' },
        cellRenderer: UserEntitlementAddCheckboxComponent
      },
      {
        colId: '1',
        field: "validID",
        hide: true,
        headerName: ''
      }
    ];

    this.prdColumn = [
      {
        field: "domain",
        width: 568,
        cellEditor: 'agTextCellEditor',
        editable: (params) => (params.data.domainId == "0") ? true : false,
        sortable: false
      },
      {
        colId: '1',
        field: "domainId",
        hide: true,
        headerName: ''

      }]
    this.domainCheckbox = {
      width: 100,
      checkboxSelection: this.isFirstColumn,
      resizable: true,
      suppressMenu: true,
      suppressMovable: true,
      editable: true,
      cellStyle: { 'background-color': 'white', 'height': '41px' }
    };


    this.columnDefs = [
      {
        field: "name",
        width: 100
      },
      {
        field: "gender",
        width: 90
      },
      {
        field: "country",
        width: 100,
        cellEditor: "agRichSelectCellEditor",
        cellEditorParams: {
          cellHeight: 50,
          values: ["Ireland", "USA"]
        }
      },
      {
        field: "city",
        width: 70
      },
      {
        field: "address",
        width: 200,
        cellEditor: "agLargeTextCellEditor"
      }
    ];

    this.defaultColDef = {
      editable: true,
      suppressMovable: true,
      resizable: true
    };
    this.pinnedTopRowData = this.getPinnedTopData();

    // C153176-4566 : Populate the system default Client Category to show color code in Custom Client Category screen
    this.clientCategories = this.userDataService.getClientCategory();

    // inq rule reorder
    this.excelService.getinqRulesReorderInfo().subscribe((data: InqRulesReorderInfo) => {
      this.updateInqRuleOrder(data);
    });
  }


  /* C153176-4445 inquiry rule redesign */
  createRouteColumn() {

    // C153176-5522 - Check for BCC group.
    this.isBccGrpSelected = this.userDataService.isBccGroupSelected(this.selectedGroup.name);
    this.routesColumn = [

      {
        colId: 'order',
        field: "order",
        headerName: '#',
        width: 101,
        editable: false,
        cellRenderer: InquiryOrderComponent,
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        rowGroupPanelShow: 'always',
        animateRows: true,
        debug: true,
        showToolPanel: true,
        suppressAggFuncInHeader: true,
        purgeClosedRowNodes: true,
        sortable: false,
      },
      {
        colId: 'rule1',
        field: "rule1",
        headerName: 'FROM RULE',
        width: 145,
        editable: true,
        cellStyle: { 'color': '#00bdf2', 'height': '41px', 'left': '112px !important', 'width': '140px' },
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: ["Begins With", "Contains", "Not Contains", "Equal", "Not Equal"]
        },
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        rowGroupPanelShow: 'always',
        animateRows: true,
        debug: true,
        showToolPanel: true,
        suppressAggFuncInHeader: true,
        purgeClosedRowNodes: true,
        sortable: false,
      },
      {
        colId: 'value1',
        field: "value1",
        headerName: 'FROM VALUE',
        width: 200,
        editable: true,
        tooltipField: 'value1',
        cellStyle: { 'left': '250px !important', 'width': '215px' },
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        rowGroupPanelShow: 'always',
        animateRows: true,
        debug: true,
        showToolPanel: true,
        suppressAggFuncInHeader: true,
        purgeClosedRowNodes: true,
        sortable: false,
      },
      {
        colId: 'rule2',
        field: "rule2",
        headerName: 'SUBJECT RULE',
        width: 137,
        editable: true,
        cellStyle: { 'color': '#fffff', 'height': '41px', 'left': '460px !important', 'width': '115px' },
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: ["Begins With", "Contains", "Not Contains", "Equal", "Not Equal"]
        },
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        rowGroupPanelShow: 'always',
        animateRows: true,
        debug: true,
        showToolPanel: true,
        suppressAggFuncInHeader: true,
        purgeClosedRowNodes: true,
        sortable: false,
      },
      {
        colId: 'value2',
        field: "value2",
        headerName: 'SUBJECT VALUE',
        width: 200,
        editable: true,
        tooltipField: 'value2',
        cellStyle: { 'left': '590px !important', 'width': '190px' },
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        rowGroupPanelShow: 'always',
        animateRows: true,
        debug: true,
        showToolPanel: true,
        suppressAggFuncInHeader: true,
        purgeClosedRowNodes: true,
        sortable: false,
      },
      {
        colId: 'rule3',
        field: "rule3",
        headerName: 'TO RULE',
        width: 100,
        editable: true,
        cellStyle: { 'color': '#00bdf2', 'height': '41px', 'left': '790px !important', 'width': '90px' },
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: ["Begins With", "Contains", "Not Contains", "Equal", "Not Equal"]
        },
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        rowGroupPanelShow: 'always',
        animateRows: true,
        debug: true,
        showToolPanel: true,
        suppressAggFuncInHeader: true,
        purgeClosedRowNodes: true,
        sortable: false,
      },
      {
        colId: 'value3',
        field: "value3",
        headerName: 'TO VALUE',
        cellStyle: { 'color': '#00bdf2', 'height': '41px', 'left': '895px !important', 'width': '165px' },
        width: 155,
        editable: true,
        tooltipField: 'value3',
        enableBrowserTooltips: true,
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        rowGroupPanelShow: 'always',
        animateRows: true,
        debug: true,
        showToolPanel: true,
        suppressAggFuncInHeader: true,
        purgeClosedRowNodes: true,
        sortable: false,
      },
      {
        colId: 'attachment',
        field: "attachment",
        headerName: 'ATTACHMENT',
        width: 142,
        editable: false,
        cellStyle: { 'left': '1070px !important' },
        cellRenderer: AgGridCheckboxComponent, cellRendererParams: { fieldname: 'attachment' },
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        rowGroupPanelShow: 'always',
        animateRows: true,
        debug: true,
        showToolPanel: true,
        suppressAggFuncInHeader: true,
        purgeClosedRowNodes: true,
        sortable: false,
      }
    ];

    this.inquiryRulesChecbox = {
      width: 100,
      checkboxSelection: this.isFirstColumn,
      headerCheckboxSelection: this.isFirstColumn,
      resizable: true,
      suppressMovable: true,
      //suppressMenu: true,
      editable: true,
      cellStyle: { 'height': '41px' },
      filter: true
    };
    this.rowSelection = "multiple";

    if (this.isBccGrpSelected) {
      this.routesColumn.push({
        colId: 'assign',
        field: "assign",
        headerName: 'Assign To Group',
        width: 500,
        editable: true,
        cellEditor: "agSelectCellEditor",
        cellStyle: { 'left': '1148px !important', 'width': '450px' },
        cellEditorParams: {
          values: this.inqAssignedGroups
        },
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        rowGroupPanelShow: 'always',
        animateRows: true,
        debug: true,
        showToolPanel: true,
        suppressAggFuncInHeader: true,
        purgeClosedRowNodes: true
      });
    }
    else {
      this.routesColumn.push({
        colId: 'checkbox',
        field: "checkbox",
        headerName: 'NON-INQUIRY',
        width: 110,
        editable: false,
        cellStyle: { 'left': '1190px !important' },
        cellRenderer: AgGridCheckboxComponent, cellRendererParams: { fieldname: 'checkbox' },
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        rowGroupPanelShow: 'always',
        animateRows: true,
        debug: true,
        showToolPanel: true,
        suppressAggFuncInHeader: true,
        purgeClosedRowNodes: true
      }, {
        colId: 'user',
        field: "user",
        headerName: 'USER',
        width: 150,
        editable: true,
        cellEditor: "agSelectCellEditor",
        cellStyle: { 'left': '1300px !important', 'width': '155px' },
        cellEditorParams: {
          values: this.entitleDataUserName
        },
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        rowGroupPanelShow: 'always',
        animateRows: true,
        debug: true,
        showToolPanel: true,
        suppressAggFuncInHeader: true,
        purgeClosedRowNodes: true
      }, {
        colId: 'art',
        field: "art",
        headerName: 'REQUEST TYPE',
        width: 110,
        editable: true,
        cellEditor: "agSelectCellEditor",
        tooltipField: 'art',
        enableBrowserTooltips: true,
        cellStyle: { 'left': '1450px !important', 'width': '110px' },
        cellEditorParams: {
          values: this.inqRequestTypeList
        },
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        rowGroupPanelShow: 'always',
        animateRows: true,
        debug: true,
        showToolPanel: true,
        suppressAggFuncInHeader: true,
        purgeClosedRowNodes: true
      }, {
        colId: 'at',
        field: "at",
        headerName: 'TAG',
        width: 110,
        editable: true,
        cellEditor: "agSelectCellEditor",
        tooltipField: 'at',
        enableBrowserTooltips: true,
        cellStyle: { 'left': '1545px !important', 'width': '130px' },
        cellEditorParams: {
          values: this.inqTagsList
        },
        filter: 'agTextColumnFilter',
        menuTabs: ["filterMenuTab"],
        rowGroupPanelShow: 'always',
        animateRows: true,
        debug: true,
        showToolPanel: true,
        suppressAggFuncInHeader: true,
        purgeClosedRowNodes: true
      },
        {
          colId: 'apr',
          field: "apr",
          headerName: 'REGION',
          width: 130,
          editable: true,
          cellEditor: "agSelectCellEditor",
          tooltipField: 'apr',
          enableBrowserTooltips: true,
          cellStyle: { 'left': '1675px !important', 'width': '128px' },
          cellEditorParams: {
            values: this.inqprocessingRegionList
          },
          filter: 'agTextColumnFilter',
          menuTabs: ["filterMenuTab"],
          rowGroupPanelShow: 'always',
          animateRows: true,
          debug: true,
          showToolPanel: true,
          suppressAggFuncInHeader: true,
          purgeClosedRowNodes: true
        }
      );
    }
  }

  getPinnedTopData() {
    return [{
      requestType: "",
      highlevelRequestType: ""
    }];
  }

  onCellValueTxt(params) {
    this.domainMsg = "";
    var colId = params.column.getId();
    if (colId === "domain") {
      this.domainValidationFlag = false;
      let flag = false;
      QmaConstant.domainListAllowed.forEach(element => {
        if (params.data.domain.indexOf(element) !== -1) {
          flag = true;
        }
      });
      if (flag == false) {
        this.domainValidationFlag = true;
        this.domainMsg = "Inappropriate Domain value!";
        this.gridApi.setFocusedCell(params.rowIndex, "domain");
        this.gridApi.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: "domain"
        });
      } else {
        this.domainValidationFlag = false;
      }
    }
  }

  isMetaTagValid(charStr): boolean {
    // Check for Letter and numbers
    const letterNumber = /^[0-9a-zA-Z\.\-\/&\s]+$/;
    const regex = /[↑,←,→,↔,↕,↨,↓,▲,▼,►,◄]/g;
    if ((charStr.match(regex))) {
      return false;
    }
    //C153176-4418
    if (charStr == "") {
      return false;
    }
    if ((charStr.match(letterNumber))) {
      return true;
    }
    // Check for non english character
    else {
      const nonEnglish = /[^\u0000-\u007F]+/;
      if ((charStr.match(nonEnglish))) {
        return true;
      }
    }
    return false;
  }
  isRequestTypeValid(charStr): boolean {
    const regex = /[↑,←,→,↔,↕,↨,↓,▲,▼,►,◄]/g;
    if ((charStr.match(regex))) {
      return false;
    } else {
      return true;
    }
  }
  //C153176-4418 tag esc issue
  onkeydown(e) {
    if (e.event.keyCode == 27 && e.data.tagID !== "0") {
      e.node.setDataValue("tags", e.data.tagID);
      this.metaDataGridApi.stopEditing();
    } else if (e.event.keyCode == 27 && e.data.tagID == "0") {
      e.node.setDataValue("tags", null);
      this.metaDataGridApi.startEditingCell({
        rowIndex: e.rowIndex,
        colKey: "tags"
      });
    }
  }

  onCellValueChanged(params) {
    // C153176-4418 : Reset the Meta Tag Vaildation flag and Message before start validation again.
    this.metaTagValidationFlag = false;
    this.metaTagValidationMsg = ""
    var colId = params.column.getId();
    if (colId === "requestType") {
      const selectedReqType = params.data.requestType;
      const request = this.requestTypeList.filter(reqType => reqType.requestType !== selectedReqType);
      const requestTypeExist = request.map((request, index) => { if (index !== params.rowIndex) return request.requestType }).indexOf(selectedReqType) !== -1;
      const validTag = this.isRequestTypeValid(selectedReqType);
      if (!validTag) {
        this.metaTagValidationFlag = true;
        this.metaTagValidationMsg = 'Invalid name! Only ./&- special characters are allowed.';
        this.metaDataGridApi.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: "requestType"
        });
        return false;
      }
      if (requestTypeExist) {
        this.metaTagValidationFlag = true;
        this.metaTagValidationMsg = 'Request type already exist.';
        this.metaDataGridApi.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: "requestType"
        });
      }
      else if (selectedReqType == "" || selectedReqType == this.start_typing_placehoder) {
        this.metaTagValidationFlag = true;
        this.metaTagValidationMsg = 'Request type value cannot be blank.';
        this.metaDataGridApi.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: "requestType"
        });
      } else if (params.data.highlevelRequestType == "select request type" || params.data.highlevelRequestType == "") {
        this.metaTagValidationFlag = true;
        this.metaTagValidationMsg = 'Please select matching generic request type in order to add a new request type';
        this.metaDataGridApi.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: "requestType"
        });
      } else {
        this.updateRequestTypeRootCauseOnAdd("requestType", [...this.metaRowData]);
      }
    }
    else if (colId === "rootCause") {
      const selectedRootCause = params.data.rootCause;
      const root = this.rootCauseList.filter(rc => rc.rootcause !== selectedRootCause); // C153176-5839 | Update logic for already exist
      const rootCausExist = root.map((rc, index) => { if (index !== params.rowIndex) return rc.rootCause }).indexOf(selectedRootCause) !== -1;
      if (rootCausExist) {
        this.metaTagValidationFlag = true;
        this.metaTagValidationMsg = 'Root Cause already exist.';
        this.metaDataGridApi.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: "rootCause"
        });
      }
      else if (selectedRootCause == "" || selectedRootCause == this.start_typing_placehoder) {
        this.metaTagValidationFlag = true;
        this.metaTagValidationMsg = 'Root Cause value can not be blank.';
        this.metaDataGridApi.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: "rootCause"
        });
      } else {
        this.updateRequestTypeRootCauseOnAdd("rootCause", [...this.metaRowData]);
      }
    }
    else if (colId === "tags") {
      const selectedTags = params.data.tags;
      if (selectedTags)
        this.metaTagValidationFlag = false;
      const tag = this.tagsList.filter(tg => tg.tags !== selectedTags); // C153176-5839 | Update logic for already exist
      const tagsExit = tag.map((tg, index) => { if (index !== params.rowIndex) return tg.tags }).indexOf(selectedTags) !== -1;
      const validTag = this.isMetaTagValid(selectedTags);
      if (tagsExit) {
        this.metaTagValidationFlag = true;
        this.metaTagValidationMsg = 'Tag already exist.';
        this.metaDataGridApi.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: "tags"
        });
      }
      else if (selectedTags == "" || selectedTags == this.start_typing_placehoder) {
        this.metaTagValidationFlag = true;
        this.metaTagValidationMsg = 'Tag value can not be blank.';
        this.metaDataGridApi.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: "tags"
        });
      }

      else if (!validTag) {
        this.metaTagValidationFlag = true;
        this.metaTagValidationMsg = 'Invalid name! Only ./&- special characters are allowed.';
        this.metaDataGridApi.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: "tags"
        });
      }
    }
    else if (colId === "processingRegion") {
      const selectedProcessingRegion = params.data.processingRegion;
      const process = this.processingRegionList.filter(pr => pr.processingRegion !== selectedProcessingRegion);; // C153176-5839 | Update logic for already exist
      const prRegionExit = process.map((pr, index) => { if (index !== params.rowIndex) return pr.processingRegion }).indexOf(selectedProcessingRegion) !== -1;
      if (prRegionExit) {
        this.metaTagValidationFlag = true;
        this.metaTagValidationMsg = 'Processing Region already exist.';
        this.metaDataGridApi.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: "processingRegion"
        });
      }
      else if (selectedProcessingRegion == "" || selectedProcessingRegion == this.start_typing_placehoder) {
        this.metaTagValidationFlag = true;
        this.metaTagValidationMsg = 'Processing Region value can not be blank.';
        this.metaDataGridApi.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: "processingRegion"
        });
      }
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

  onMetaDataGridReady(params) {
    this.metaDataGridApi = params.api;
    this.metaDataGridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

  onAddNewDataGridReady(params) {
    this.addNewUserGridApi = params.api;
    this.addNewUserGridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

  onEntitleGridReady(params) {
    this.entitleGridApi = params.api;
    this.entitleGridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

  setIsRootCauseMandatoryWOReply(val) {
    this.isRootCauseMandatoryWOReply = val;
  }
  /**
   * Method to save the admin group details.
   */
  // inquiry rules
  onRowDataChanged(event) {
    event.api.forEachNode(function (rowNode, index) {
      console.log(rowNode + '' + index);
    });
  }

  onCellEditingStopped() {
    // this.onColumnResized();
  }
  onPaginationChanged() {
    if (this.gridApiInquiry) {
      this.lbTotalRecords = this.gridApiInquiry.paginationGetRowCount();
      this.lbPageSize = this.gridApiInquiry.paginationGetPageSize();
      this.lbCurrentPage = this.gridApiInquiry.paginationGetCurrentPage() + 1;
      this.lbTotalPages = this.gridApiInquiry.paginationGetTotalPages();

      let currentPage = this.gridApiInquiry.paginationGetCurrentPage() + 1;
      let lastPage = this.gridApiInquiry.paginationGetTotalPages();
      if (currentPage == lastPage) {
        this.disablePaginationNextLast = true;
      }
      else {
        this.disablePaginationNextLast = false;
      }
      if (currentPage == 1) {
        this.disablePaginationPrevFirst = true;
      }
      else {
        this.disablePaginationPrevFirst = false;
      }
      if (this.lbPageSize > this.lbTotalRecords) {
        this.lbPageSize = this.lbTotalRecords;
      }
      else {
        this.lbPageSize = this.gridApiInquiry.paginationGetPageSize();
      }
    }
  }
  setText(selector, text) {
    document.querySelector(selector).innerHTML = text;
  }
  setLastButtonDisabled(disabled) {
  }
  onBtNext() {
    this.gridApiInquiry.paginationGoToNextPage();
  }
  onBtPrevious() {
    this.gridApiInquiry.paginationGoToPreviousPage();
  }
  onBtLast() {
    this.gridApiInquiry.paginationGoToLastPage();
  }
  onBtFirst() {
    this.gridApiInquiry.paginationGoToFirstPage();
  }
  // search for inquiry rules
  onKey(event: any) {
    this.gridApiInquiry.setQuickFilter(event.target.value);
  }
  filterIconClick() {
    this.filterIconHide = true;
  }
  filterCloseClick() {
    this.filterIconHide = false;
    this.filterText = "";
    this.gridApiInquiry.setQuickFilter("");
  }

  /*  To close success message for Admin */
  closeSuccess() {
    this.closeFlag = false;
  }
  closeAddGroup() {
    this.closeFlagAddGroup = false;
  }

  saveInquiryRules() {
    let saveInquiryRules: SaveInquiryRules = new SaveInquiryRules();
    saveInquiryRules.groupName = this.selectedGroup.name;
    saveInquiryRules.version = this.version;
    saveInquiryRules.criteriaList = [];
    this.validationCheck = true;
    this.validationCheckForAction = true;
    this.validationCheckForEmpty = true;
    let validationFailedIds = "";
    // C153176-4445:inquiry redesign
    this.routesList.every((element, index) => {
      if ((element.value1 !== '' && element.value1 !== null) || (element.value2 !== '' && element.value2 !== null) || (element.value3 !== '' && element.value3 !== null)) {
        let groupInquiryCriteriaList: GroupInquiryCriteriaList = new GroupInquiryCriteriaList();
        groupInquiryCriteriaList.from = element.value1;
        groupInquiryCriteriaList.fromOperator = element.rule1;
        groupInquiryCriteriaList.ruleOrder = (this.routesList.length - index) * 10;
        groupInquiryCriteriaList.subject = element.value2;
        groupInquiryCriteriaList.subjectOperator = element.rule2;
        groupInquiryCriteriaList.to = element.value3;
        groupInquiryCriteriaList.toOperator = element.rule3;
        groupInquiryCriteriaList.attachment = element.attachment;
        groupInquiryCriteriaList.actions = new GroupInquiryActions();
        // C153176-4445 : Only values sholud be populated.
        //C153176-5643: Upload Error
        if (element.apr && element.apr.length != 0) {
          groupInquiryCriteriaList.actions.assignProcessingRegion = Array.isArray(element.apr) ? element.apr[0] : element.apr;
        }

        if (element.art && element.art.length != 0) {
          groupInquiryCriteriaList.actions.assignRequestType = Array.isArray(element.art) ? element.art[0] : element.art;
        }

        if (element.at && element.at.length != 0) {
          groupInquiryCriteriaList.actions.assignToTag = Array.isArray(element.at) ? element.at[0] : element.at;
        }

        // C153176-4445 : Use soeid instead of user name.
        if (element.user && element.user.length != 0) {
          let userName = Array.isArray(element.user) ? element.user[0] : element.user;
          let entitleUser = this.entitleData.find(u => u.user === userName);
          groupInquiryCriteriaList.actions.assignToUserId = entitleUser ? entitleUser.soeid : '';
        }

        if (element.assign && element.assign.length != 0) {
          groupInquiryCriteriaList.actions.assignGroup = Array.isArray(element.assign) ? element.assign[0] : element.assign;
        }

        groupInquiryCriteriaList.actions.markAsNonInquiry = element.checkbox;
        groupInquiryCriteriaList.actions.markForDeletion = element.deletion;

        if (this.isBccGrpSelected) {
          if (!groupInquiryCriteriaList.actions.assignGroup) {
            this.validationCheck = false;
          }
        } else {
          /* C153176-5629: Validation issue on save Inquiry */
          this.validationCheck = (!(element.hasOwnProperty("apr") && element.apr.length == 0) ||
            !(element.hasOwnProperty("art") && element.art.length == 0) ||
            !(element.hasOwnProperty("at") && element.at.length == 0) ||
            !(element.hasOwnProperty("user") && element.user.length == 0) ||
            element.checkbox || element.attachment);
        }
        if (!this.validationCheck) {
          this.validationCheckForAction = false;
          if (validationFailedIds === "") {
            validationFailedIds = element.order;
          } else {
            validationFailedIds = validationFailedIds + ", " + element.order;
          }
        }
        if (this.validationCheck) {
          saveInquiryRules.criteriaList.push(groupInquiryCriteriaList);
        }
        return true;
      } else {
        this.validationCheck = false;
        this.validationCheckForEmpty = false;
        if (validationFailedIds === "") {
          validationFailedIds = element.order;
        } else {
          validationFailedIds = validationFailedIds + ", " + element.order;
        }
        return true;
      }
    });
    if (!this.validationCheckForAction && !this.validationCheckForEmpty) {
      this.validationRules = 'Rules should have atleast one of the To, From & Subject Values  and one action defined: ' + validationFailedIds;
      return false;
    }
    if (this.validationCheckForAction && !this.validationCheckForEmpty) {
      this.validationRules = 'To, From & Subject Values should not be empty for the rule id: ' + validationFailedIds;
      return false;
    }
    if (!this.validationCheckForAction && this.validationCheckForEmpty) {
      this.validationRules = 'Please select at least one action for the rule id: ' + validationFailedIds;
      return false;
    }
    if (this.validationCheckForAction && this.validationCheckForEmpty) {
      saveInquiryRules.criteriaList.forEach(element => {
        this.entitleData.forEach(element1 => {
          if (element.actions.assignToUserId === element1.user) {
            element.actions.assignToUserId = element1.soeid;
          }
        });
      });
      // If click on save button then loader will come
      this.showSpinner = true;
      console.debug(":SPINNER:: " + this.showSpinner + " ::user.saveInquiryRules");

      this.groupAdminService.saveInquiryData(saveInquiryRules).subscribe(result => {
        //this.display = true;
        this.displaySuccess = true;
        this.showSpinner = false;
        console.debug(":SPINNER:: " + this.showSpinner + " ::user.saveInquiryRules");
        this.version = result.version;  /*<!-- C153176-4445 inquiry redesign*/
        // this.resetValue();
        this.onBack();
        setTimeout(() => { this.displaySuccess = false; }, 3000);
      },
        error => {
          this.display = false;
          this.showSpinner = false;
          console.debug(":SPINNER:: " + this.showSpinner + " ::user.saveInquiryRules");
          this.displayError = true;
          setTimeout(() => { this.displayError = false; }, 3000);
        });
    }
  }

  saveGroup(): void {

    if (this.checkManagerSelected()) {

      if (this.isUserActiveGD) {

        if (this.checkManagerLevel()) {

          if (this.metaTagValidationFlag) {
            this.inquiryFlag = 2; // Navigate to Metadata.
            return;
          }
          let groupDetails: GroupAdminDetails = new GroupAdminDetails();
          let rows = [];
          let clientCtgryList = [];

          this.clientCategoryDataGridApi.forEachNode(node => rows.push(node.data));
          this.clientCategoryRowData = rows;

          this.clientCategoryRowData.forEach(clientCatgry => {
            if ((clientCatgry.categoryName != 'start typing...') && (this.ClientNameValidation == '') && (clientCatgry.field != 'select field...') && (clientCatgry.operators != 'select rule...') && (clientCatgry.value != 'start typing...')) {
              let clientCategory = {
                "categoryName": clientCatgry.categoryName,
                "colorCode": clientCatgry.colorCode,
                "rules": {
                  "field": clientCatgry.field,
                  "operators": clientCatgry.operators,
                  "value": clientCatgry.value
                }
              };
              clientCtgryList.push(clientCategory);
            }
          });
          groupDetails.customClientCategory = [...clientCtgryList];


          if (this.holidayAndShiftDetails == true) {
            if (this.selectedCountry['countryCode'] == "" ||
              this.selectedTimeZone['timeZoneCode'] == "" ||
              this.selectedStartTime == undefined ||
              this.selectedStartTime['value'] == "" ||
              this.selectedEndTime == undefined ||
              this.selectedEndTime['value'] == "") {
              this.workshiftErrMsg = "Country, TimeZone, Shift start and end time are mandatory fields for this configuration"; // C153176-5824 - Set the message
              this.inquiryFlag = 3;
              return;
            }
          } else {
            this.workshiftErrMsg = ""; // C153176-5824 - Clear the message
          }

          // Group Details
          groupDetails.groupName = this.selectedGroup.name;
          if (this.heirarchyData)
            groupDetails.heirarchyData = this.heirarchyData;

          //let arrvalue:any[] = this.gridRoleApi.getSelectedRows();

          console.log(this.entitleData);
          console.log(this.entitleListBackup);

          // entitlement
          let finAddAnaArr = [];
          let finAddPeerRevArr = [];
          let finAddSupArr = [];
          let finAddGrpAdmArr = [];

          let removeSupArr = [];
          let removeAnaArr = [];
          let removeGrpAdmArr = [];
          let removePeerRevArr = [];

          this.addNewUserData.forEach(element => {
            // Jira : C153176-5182 Group Admin - issue- Design change
            if ((element['role'].indexOf('Analyst') > -1) || (element['role'].indexOf('Standard User') > -1)) {
              finAddAnaArr.some(item => item === element['soeid']) ? null : finAddAnaArr.push(element['soeid']);
            }
            if ((element['role'].indexOf('Peer Reviewer') > -1)) {
              finAddPeerRevArr.some(item => item === element['soeid']) ? null : finAddPeerRevArr.push(element['soeid']);
            }
            if ((element['role'].indexOf('Supervisor') > -1)) {
              finAddSupArr.some(item => item === element['soeid']) ? null : finAddSupArr.push(element['soeid']);
            }
            if ((element['role'].indexOf('Group Admin') > -1)) {
              finAddGrpAdmArr.some(item => item === element['soeid']) ? null : finAddGrpAdmArr.push(element['soeid']);
            }
          });

          this.entitleData.forEach(element => {
            this.entitleListBackup.forEach(oldElement => {
              if (oldElement['soeid'] == element['soeid']) {
                /* Add in Array */
                if ((element['role'].includes('Analyst') || element['role'].includes('Standard User')) && !(oldElement['role'].includes('Analyst'))) {
                  finAddAnaArr.some(item => item === element['soeid']) ? null : finAddAnaArr.push(element['soeid']);
                }
                if (element['role'].includes('Peer Reviewer') && !(oldElement['role'].includes('Peer Reviewer'))) {
                  finAddPeerRevArr.some(item => item === element['soeid']) ? null : finAddPeerRevArr.push(element['soeid']);
                }
                if (element['role'].includes('Supervisor') && !(oldElement['role'].includes('Supervisor'))) {
                  finAddSupArr.some(item => item === element['soeid']) ? null : finAddSupArr.push(element['soeid']);
                }
                if (element['role'].includes('Group Admin') && !(oldElement['role'].includes('Group Admin'))) {
                  finAddGrpAdmArr.some(item => item === element['soeid']) ? null : finAddGrpAdmArr.push(element['soeid']);
                }
                /* Remove from Array */
                //C153176-4323 : User Entitlement drop down not refelecting after save.
                if ((oldElement['role'].indexOf('Analyst') >= 0 || oldElement['role'].indexOf('Standard User') >= 0) && (element['role'].indexOf('Analyst') == -1)) {
                  this.finAnaArr.some(item => item === element['soeid']) ? null : this.finAnaArr.push(element['soeid']);
                }
                if ((oldElement['role'].indexOf('Peer Reviewer') >= 0) && (element['role'].indexOf('Peer Reviewer') == -1)) {
                  this.finPeerRevArr.some(item => item === element['soeid']) ? null : this.finPeerRevArr.push(element['soeid']);
                }
                if ((oldElement['role'].indexOf('Supervisor') >= 0) && (element['role'].indexOf('Supervisor') == -1)) {
                  this.finSupArr.some(item => item === element['soeid']) ? null : this.finSupArr.push(element['soeid']);
                }
                if ((oldElement['role'].indexOf('Group Admin') >= 0) && (element['role'].indexOf('Group Admin') == -1)) {
                  this.finGrpAdmArr.some(item => item === element['soeid']) ? null : this.finGrpAdmArr.push(element['soeid']);
                }
              }
            });
          });

          groupDetails.supervisorAddList = finAddSupArr;
          groupDetails.analystAddList = finAddAnaArr;
          groupDetails.adminAddList = finAddGrpAdmArr;
          groupDetails.peerRevAddList = finAddPeerRevArr;

          //C153176-4497 : Unable to add and delete users in User entitlments
          //C153176-4323 : User Entitlement drop down not refelecting after save.
          groupDetails.supervisorRemoveList = this.finSupArr;
          groupDetails.analystRemoveList = this.finAnaArr;
          groupDetails.adminRemoveList = this.finGrpAdmArr;
          groupDetails.peerRevRemoveList = this.finPeerRevArr;


          // Prefrences
          groupDetails.domainReqd = this.makerCheckerRqd;
          this.peerRevDomain == "Attachments" ? groupDetails.attachmentIncluded = true : groupDetails.attachmentIncluded = false;
          groupDetails.predictiveRecipients = this.predictiveRecipients;
          groupDetails.enableAutoAssignment = this.enableAutoAssignment;
          groupDetails.enableAutoReplySuggestion = this.enableAutoReplySuggestion;
          groupDetails.isEmailSharingBlocked = this.isEmailSharingBlocked;
          groupDetails.isInquirySourceMandatory = this.isInquirySourceMandatory;
          groupDetails.isGfidMandatory = this.isGfidMandatory;
          groupDetails.enableInquirySubStatus = this.enableInquirySubStatus; ////[C170665-1719] DCC Requirement: Add Case status field
          groupDetails.isInqReferenceIdReq = this.isInqReferenceIdReq;
          groupDetails.excludeMyGroupWhileReplyAll = this.excludeMyGroupWhileReplyAll;
          groupDetails.hideClientList = this.hideClientList;
          groupDetails.supervisiorApprovalForReage = this.supervisiorApprovalForReage;

          // C170665-10 | Set requestTypeRootCauseFilter from UI to group.
          groupDetails.requestTypeRootCauseFilter = this.requestTypeRootCauseFilter;
          groupDetails.isHolidayBasedAgeCalculation = this.holidayAndShiftDetails;

          groupDetails.groupCountryValue = this.selectedCountry['countryCode'];
          groupDetails.groupTimeZoneValue = this.selectedTimeZone['timeZoneCode'];
          groupDetails.groupShiftStartTime = this.selectedStartTime && this.selectedStartTime['value'] ? this.selectedStartTime['value'] : "";
          groupDetails.groupShiftEndTime = this.selectedEndTime && this.selectedEndTime['value'] ? this.selectedEndTime['value'] : "";
          groupDetails.weekelyOff = this.selectedWeekelyOff || [];

          // Peer review Domain
          groupDetails.domainList = [];
          if ((this.prdData).length > 0 && this.prdData[0].domain == "start typing...") {
            this.domainValidationFlag = true;
            this.domainMsg = "Field value can not be blank."
            this.inquiryFlag = 4;
            return;
          }

          this.prdData.forEach(element => {
            if (element['domain'] != "")
              groupDetails.domainList.push(element['domain'])
          });

          // Emain Disc
          this.emailDisc = this.getEditorInstance('emailDisc').getData();
          groupDetails.disclaimer = '';

          // C153176-4589 | Add check condition if disclaimer contains only spaces
          if (this.emailDisc) {
            let parsedHtml = this.parser.parseFromString(this.emailDisc, 'text/html');
            let viewContent = parsedHtml.body.innerText || "";
            if (viewContent.replace(/\s/g, '').length > 0) {
              groupDetails.disclaimer = this.emailDisc;
            }
          }

          // Auto Response
          groupDetails.autoReplyEnable = this.enableAutCliAck;
          if (this.autoResponse != null) {
            groupDetails.autoResponseDetails.push({
              "key": "groupName",
              "value": this.getEditorInstance('autoResponse').getData().trim()
            });
          }


          // Escalation Criteria
          // C153176-4907: handling 0 and undefined cases (in form and component)
          let convCountThresholdForEscalation = 0;
          if (groupDetails.convCountThresholdForEscalation) {
            convCountThresholdForEscalation += (+groupDetails.convCountThresholdForEscalation);
          }
          if (this.converThreshold) {
            convCountThresholdForEscalation += (+this.converThreshold);
          }
          groupDetails.convCountThresholdForEscalation = convCountThresholdForEscalation;

          let responseTimeThresholdForEscalation = 0;
          if (this.resTimeThreMin) {
            responseTimeThresholdForEscalation = responseTimeThresholdForEscalation + (+this.resTimeThreMin);
          }
          if (this.resTimeThreHr) {
            responseTimeThresholdForEscalation = responseTimeThresholdForEscalation + (+this.resTimeThreHr) * 60;
          }
          groupDetails.responseTimeThresholdForEscalation = responseTimeThresholdForEscalation;

          //  Add Escalation criteria for 'Pending Approval' emails
          let paResponseTimeThresholdForEscalation = 0;
          if (this.penApprThreMin) {
            paResponseTimeThresholdForEscalation = paResponseTimeThresholdForEscalation + (+this.penApprThreMin);
          }
          if (this.penApprThreHr) {
            paResponseTimeThresholdForEscalation = paResponseTimeThresholdForEscalation + (+this.penApprThreHr) * 60;
          }
          groupDetails.paResponseTimeThresholdForEscalation = paResponseTimeThresholdForEscalation;

          groupDetails.version = this.version;
          if (this.gfcid)
            groupDetails.gfcid = this.gfcid;
          groupDetails.subjectEscalationList = this.subjectEscalationList;

          // Metadata
          if (this.metaRowData) {
            if (this.metaDataType === 'RequestTypes') {
              this.requestTypeList = this.metaRowData;
            } else if (this.metaDataType === 'Root Cause') {
              this.rootCauseList = this.metaRowData;
            } else if (this.metaDataType === 'Processing Region') {
              this.processingRegionList = this.metaRowData;
            } else if (this.metaDataType === 'Tags') {
              this.tagsList = this.metaRowData;
            }
          }

          if (((this.metaRowData).length > 0) && (this.metaRowData[0].requestType == this.start_typing_placehoder ||
            this.metaRowData[0].highlevelRequestType == this.start_typing_placehoder || this.metaRowData[0].tags == this.start_typing_placehoder ||
            this.metaRowData[0].rootCause == this.start_typing_placehoder || this.metaRowData[0].processingRegion == this.start_typing_placehoder)) {
            this.metaTagValidationFlag = true;
            this.metaTagValidationMsg = 'Field value can not be blank.';
            this.inquiryFlag = 2;
            return;
          }
          else {
            this.metaTagValidationFlag = false;
            this.metaTagValidationMsg = "";

          };
          groupDetails.rootCauseList = [];
          this.rootCauseList.forEach(el => groupDetails.rootCauseList.push(el.rootCause));
          groupDetails.processingRegionList = [];
          this.processingRegionList.forEach(el => groupDetails.processingRegionList.push(el.processingRegion));
          groupDetails.requestTypeMappings = this.requestTypeList;
          groupDetails.requestTypeMappings.forEach(el => delete el['requestID'])
          groupDetails.requestTypeList = [];
          this.requestTypeList.forEach(el => groupDetails.requestTypeList.push(el.requestType));
          groupDetails.isTagMandatory = this.isTagMandatory;
          groupDetails.isRootCauseMandatory = this.isRootCauseMandatory;
          groupDetails.addGroupTags = [];
          groupDetails.editGroupTags = [];
          groupDetails.deleteGroupTags = [];
          this.tagsList.forEach(el => {
            if (el.tagID == "0") groupDetails.addGroupTags.push(el.tags);
            else if (el.tagID !== "0" && el.tagID !== el.tags) groupDetails.editGroupTags.push({ 'tagName': el.tagID, "newtagName": el.tags });
          });
          for (let el of this.originalTagList) {
            let isExists = this.tagsList.filter(tags => tags.tagId != '0' && tags.tagID == el)[0];
            if (!isExists) groupDetails.deleteGroupTags.push(el);
          }
          groupDetails.isRootCauseMandatoryWOReply = this.isRootCauseMandatoryWOReply;
          groupDetails.isProcessingRegionMandatory = this.isProcessingRegionMandatory;

          // C153176-5119,  C153176-5114 : Validate Request type and Request type mapping should have atleast one value.
          if (groupDetails.requestTypeList.length == 0 || groupDetails.requestTypeMappings.length == 0) {
            this.metaTagValidationFlag = true;
            this.metaTagValidationMsg = 'At least one request type is required.'; //Jira :  4703 : Metadata Validation for request type
            this.inquiryFlag = 2;
            return;
          }

          // C153176-5839 | User can get the validation error when there is request type in QMA 2.0 without mapping to generic request type.
          let nonGenericRequestType = groupDetails.requestTypeMappings.filter(reqType => reqType.highlevelRequestType == "");
          if (nonGenericRequestType && nonGenericRequestType.length > 0) {
            // Pre-select the meta-data type as Request type and populates the value
            this.selectedMetaDataType = 'RequestTypes';
            this.selectMetaDataType('RequestTypes', 'onchange');
            this.metaTagValidationFlag = true;
            this.metaTagValidationMsg = 'Request type should be mapped with generic request type.';
            this.inquiryFlag = 2;
            return;
          }
          // C170665-10 | Populate Request Type and Root Cause mapping.
          groupDetails.requestTypeRootCauseMapping = this.getReqTypeRootCauseMappingList(groupDetails.requestTypeList, groupDetails.rootCauseList);
          //C170665-185 | enable organization meta data
          groupDetails.enableOrganizationMetaData = this.enableOrgMetaData;
          console.log('enable org level meta data flag' + this.enableOrgMetaData);
          // If click on save button then loader will come
          this.showSpinner = true;
          console.debug(":SPINNER:: " + this.showSpinner + " ::user.saveGroup");
          groupDetails.isGroupManagerAboveL7 = this.overrideCheckbox;
          groupDetails.managerSoeId = this.groupManagerID;
          this.groupAdminService.saveGroupData(groupDetails).subscribe(result => {

            // Group Data Saved Successfully
            if (result && result.success) {
              this.resetValue();
              this.onBack();
              this.addNewUserData = [];
              this.version = result.version;
              this.grpSaveMsg = this.userSavedSuccess;
              this.showSpinner = false;
              console.debug(":SPINNER:: " + this.showSpinner + " ::user.saveGroup");
              this.closeFlag = true;
              if (this.closeFlag) {
                setTimeout(() => { this.closeFlag = false; }, 3000);
              }
              // C153176-5001 : Update the group to loggedIn User once group is updated successfully.
              this.userDataService.setGroupDetails(groupDetails);
            }
            //C153176-4413 : If another user has already updated the details
            else {
              // C153176-5119 : Show validation message from service.
              let errMsg = result.validationMessage ? result.message : this.concurrentUpdateMsg;
              this.grpSaveMsg = errMsg;
              this.closeFlag = true;
              this.showSpinner = false;
              console.debug(":SPINNER:: " + this.showSpinner + " ::user.saveGroup");
              if (this.closeFlag) {
                setTimeout(() => { this.closeFlag = false; }, 10000);
              }
            }
            this.display = false;
          },
            error => {
              this.showSpinner = false;
              console.debug(":SPINNER:: " + this.showSpinner + " ::user.saveGroup");
              console.error('Error while saving group admin details in saveGroup()');
            });
        } else {
          this.grpSaveMsg = 'The Group Manager selected is L6 or above. Please select a Group Manager at an L7 or L8 level or check the attestation box below before your submit.';
          this.closeFlag = true;
          this.inquiryFlag = 0;
          this.isManagerSelected = false;
          return;
        }
      } else {
        this.grpSaveMsg = 'User selected is not active in Global Directory. Please select another user.';
        this.closeFlag = true;
        this.inquiryFlag = 0;
        return;
      }
    } else {
      this.grpSaveMsg = 'Group Manager is not selected';
      this.closeFlag = true;
      this.inquiryFlag = 0;
      return;
    }
  }

  // Check if Group Manager is selected
  checkManagerSelected(): boolean {

    this.isManagerSelected = true;
    if (AppUtils.isUndefinedNullOrBlankStrAfterTrim(this.groupManager)) {
      this.isManagerSelected = false;
    } else {
      this.isManagerSelected = true;
    }
    return this.isManagerSelected;
  }

  // Check if level is correct
  checkManagerLevel(): boolean {

    return !this.isL6AndBelow || (this.isL6AndBelow && this.overrideCheckbox);
  }
  /* Set header for client category - 12-09-2019 */
  setHeaderForClientCategory() {
    this.clientCategoryColumnDefs = [
      {
        colId: 'customClientCategory',
        field: "categoryName",
        headerName: 'CUSTOM CATEGORY',
        width: 170,
        editable: true,
        sortable: false
      },
      {
        colId: 'displayColor',
        field: "colorCode",
        headerName: 'COLOUR CODE',
        width: 135,
        editable: false,
        cellRenderer: ColorPickerComponent,
        sortable: false
      },
      {
        colId: 'field',
        field: "field",
        headerName: 'FIELD',
        width: 100,
        editable: true,
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: ["From", "To", "Subject", "Tag"],
        },
        sortable: false
      },
      {
        colId: 'operators',
        field: "operators",
        headerName: 'RULE',
        width: 120,
        editable: true,
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: ["Contains", "Starts With", "Ends With", "Equals"]
        },
        sortable: false
      },
      {
        colId: 'value',
        field: "value",
        headerName: 'VALUE',
        width: 120,
        editable: true,
        sortable: false
      },
      {
        colId: 'delete',
        field: "",
        headerName: '',
        width: 100,
        editable: false,
        cellRenderer: DeleteCellRendererComponent,
        sortable: false
      }
    ];
  }
  /**
   * Method to call when client category grid is ready.
   * @param params
   */
  onGridReadyClient(params) {
    this.clientCategoryDataGridApi = params.api;
    this.clientCategoryDataGridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

  /* Add new Custom category */
  onAddCustomCategory() {
    let rows = [];
    this.clientCategoryDataGridApi.forEachNode(node => rows.push(node.data));
    this.clientCategoryRowData = rows;

    if (this.clientCategoryRowData.length <= 1) {
      this.clientCategoryRowData.splice(0, 0, {
        categoryName: "start typing...",
        colorCode: "",
        field: "select field...",
        operators: "select rule...",
        value: "start typing..."
      });

      this.clientCategoryDataGridApi.applyTransaction({ add: this.clientCategoryRowData });
      this.clientCategoryDataGridApi.startEditingCell({
        rowIndex: 0,
      });

    } else {
      this.errorMessage = true;
      setTimeout(() => { this.errorMessage = false; }, 3000);


    }
  }
  onCellValueChangedClient(params) {
    this.ClientNameValidation = '';
    let catName = params.data.categoryName;
    if (this.clientCategoryRowData.length > 1) {
      if ((this.clientCategoryRowData[1].categoryName).toLowerCase() == catName.toLowerCase()) {
        this.ClientNameValidation = 'Custom category name already exist.';
        this.clientCategoryDataGridApi.startEditingCell({
          rowIndex: 0,
          colKey: "customClientCategory"
        });
      }
    }
  }

  closeErrorModal() {
    this.errorMessage = false;
  }

  closeErrorMetadata() {
    this.isExist = false;
  }
  closeErrorUserEnt() {
    this.isGroupAdmin = false;

  }

  /**
   * Method to close profile setting popup on success.
   * @param event
   */
  closeProfileSetting(event: any): void {
    if (event) {
      this.hideProfileModal(event);
    }
  }

  onReject() {
    this.messageService.clear('c');
  }

  sanetize(value: any): any {
    return this.sanatized.bypassSecurityTrustHtml(value);
  }

  /**
   * Method to Navigate to QMA 1.0
   */
  openQMAdmin(): void {
    this.groupSetupBoolean = true;
  }
  onGrpSetupMdlClosed(data) {
    this.groupSetupBoolean = false;
  }
  updateInqRuleOrder(inqRulesReorderInfo: InqRulesReorderInfo) {
    if (Object.entries(inqRulesReorderInfo).length === 0 && inqRulesReorderInfo.constructor === Object) {
      return;
    }
    this.removeInqRuleFromGrid(inqRulesReorderInfo);

  }

  removeInqRuleFromGrid(inqRulesReorderInfo: InqRulesReorderInfo) {
    // Inquiry Rules screen redesign and Rules Reordering C153176-4445
    const inquiryRuletoDelete = this.routesList.findIndex(irowData => irowData.order === inqRulesReorderInfo.originalOrderNumber);
    this.routesList.splice(inquiryRuletoDelete, 1);
    // Inquiry Rules screen redesign and Rules Reordering C153176-4445
    let nodeToReOrder = [];

    this.gridApiInquiry.forEachNode(function (node) {
      if (inqRulesReorderInfo.originalOrderNumber === node.data.order) {
        // Inquiry Rules screen redesign and Rules Reordering C153176-4445
        nodeToReOrder.push(node.data)
      }
    });
    if (nodeToReOrder.length > 0) {
      this.gridApiInquiry.applyTransaction({ remove: nodeToReOrder });
    }
    this.addInqRuleAtNewPosition(inqRulesReorderInfo, nodeToReOrder[0]);

  }
  addInqRuleAtNewPosition(inqRulesReorderInfo: InqRulesReorderInfo, nodeToReOrder) {
    this.routesList.splice(inqRulesReorderInfo.newOrderNumber - 1, 0, nodeToReOrder);
    this.gridApiInquiry.applyTransaction({
      add: [nodeToReOrder],
      addIndex: inqRulesReorderInfo.newOrderNumber - 1
    });
  }

  // Jira:4703 : Confirmation Dialog before delete
  /**
  * Method to prepare and set the required values to confirmation pop.
  * */
  setConfirmation(tab): void {
    //Jira :  4703 : Metadata Validation for request type
    this.metaTagValidationFlag = false;
    this.metaTagValidationMsg = '';
    if (tab == 'metaData' && this.metaRowData.length == 1 && this.metaDataType === 'RequestTypes') {
      this.metaTagValidationFlag = true;
      this.metaTagValidationMsg = 'At least one request type is required.';
      this.inquiryFlag = 2;
      return;
    }
    else if (tab == 'metaData' && this.metaRowData.length == 1 && this.metaDataType === 'Root Cause') {
      this.metaTagValidationFlag = true;
      this.metaTagValidationMsg = 'At least one root cause is required.';
      this.inquiryFlag = 2;
      return;
    }
    else if (tab == 'metaData' && this.metaRowData.length == 1 && this.metaDataType === 'Processing Region') {
      this.metaTagValidationFlag = true;
      this.metaTagValidationMsg = 'At least one processing region is required.';
      this.inquiryFlag = 2;
      return;
    }
    else if (tab == 'metaData' && this.metaRowData.length == 1 && this.metaDataType === 'Tags') {
      this.metaTagValidationFlag = true;
      this.metaTagValidationMsg = 'At least one tags is required.';
      this.inquiryFlag = 2;
      return;
    }
    // C153176-4445 | Delete dialog should show if inquiry rules is selected.
    else if (tab == "inquiryRules") {
      const selectedRules = this.gridApiInquiry.getSelectedRows();
      if (null == selectedRules || selectedRules.length == 0) {
        return;
      }
    }
    this.showPopUpDelete = true;
    this.confirmMsgText = "Are you sure want to delete the selected items ?";
    this.groupTab = tab;
  }
  onCancel() {
    this.showPopUpDelete = false;
  }
  onOk() {
    if (this.groupTab == 'peerReview') {
      this.deleteDomainRecords();
    }
    if (this.groupTab == 'metaData') {
      this.deleteMetaRecords();
    }
    if (this.groupTab == 'userEntitlement') {
      this.deleteUserFromEntitlement();
    }
    if (this.groupTab == 'inquiryRules') {
      this.deleteInquiryRulesRecords();
    }

  }

  // C153176-4445 : Validation for Uploaded Inquiry Rules
  /**
   * Method to validate the uploaded file type.
   * @param fileName
   */
  validateFileUpload(fileName: string): any {
    let validateObj = { isValid: true, errorMessage: '' };

    // Upload file should be Excel i.e extension should be .xlsx
    if (!fileName.includes('.xlsx')) {
      validateObj.isValid = false;
      validateObj.errorMessage = 'File upload failed due to invalid file type; only supported file type is .xlsx';
    }
    return validateObj;
  }

  /**
   * Method to validate the upload file has same header as of grid header except sequence column.
   * @param headers
   */
  validateFileHeader(headers: any): any {
    let validateObj = { isValid: true, errorMessage: '' };
    let columnList = this.gridApiInquiry.columnModel.displayedColumns.filter(col => col.colId !== "order").map(column => column.colDef.headerName);

    // Validate that upload Excel and Grid have same columns.
    let isHeaderValid = true;
    if (columnList.length === headers.length && columnList.every(col => headers.includes(col))) {
      for (let idx = 0; idx < columnList.length; idx++) {
        isHeaderValid = columnList[idx] === headers[idx];
        if (!isHeaderValid) {
          return;
        }
      }
    } else {
      isHeaderValid = false;
    }

    if (!isHeaderValid) {
      validateObj.isValid = isHeaderValid;
      validateObj.errorMessage = 'File Upload failed due to incorrect headers';
    }
    return validateObj;
  }

  /**
   * Method to get the valid and invalid inquiry rules from the uploaded rules.
   * @param uploadInquiryRules
   */
  getValidAndInvalidRules(uploadInquiryRules: any): any {

    let rejectRowMap = new Map();
    let validInquiryRules = [];

    for (let i = 0; i < uploadInquiryRules.length; i++) {
      let rejectedColArr = [];
      let rowData = uploadInquiryRules[i];

      if (this.isBccGrpSelected) {
        if (rowData['Assign To Group'] && !this.inqAssignedGroups.includes(rowData['Assign To Group'])) {
          rejectedColArr.push('Assign To Group');
        }
      }
      else {
        // C153176-4445 : Validate the fields
        if (rowData['USER'] && !this.groupUsers.includes(rowData['USER'])) {
          rejectedColArr.push('User');
        }

        if (rowData['REQUEST TYPE'] && !this.groupRequestTypes.includes(rowData['REQUEST TYPE'])) {
          rejectedColArr.push('Request Type');
        }

        if (rowData['TAG'] && !this.groupTags.includes(rowData['TAG'])) {
          rejectedColArr.push('Tag');
        }

        if (rowData['REGION'] && !this.groupProcessingRegion.includes(rowData['REGION'])) {
          rejectedColArr.push('Region');
        }
      }

      if (rejectedColArr.length > 0) {
        rejectRowMap.set(i + 1, rejectedColArr);
      } else {
        validInquiryRules.push(rowData);
      }
    };

    const inquiryRulesObj = {
      'invalid': rejectRowMap,
      'valid': validInquiryRules
    }
    return inquiryRulesObj;
  }

  /**
   * Method to update the inquiry rules grid with upload inquiry rules.
   * @param validInquiryRules
   */
  updateInquiryRulesGrid(validInquiryRules: any): void {
    if (validInquiryRules.length > 0) {
      /* jira C170665-1815-DL-rule-not-working-when-bulk-rules-upload-is-done-through-UI */
      const defValue = "Begins With";
      let inquiryRuleList = [];
      validInquiryRules.forEach((rowData, i) => {
        let inquiryRule = {
          'order': i + 1, // C153176-4445 - Show row seq as per number of records.
          'rule1': rowData['FROM RULE'] != "" ? rowData['FROM RULE'] : defValue,
          'value1': rowData['FROM VALUE'],
          'rule2': rowData['SUBJECT RULE'] != "" ? rowData['SUBJECT RULE'] : defValue,
          'value2': rowData['SUBJECT VALUE'],
          'rule3': rowData['TO RULE'] != "" ? rowData['TO RULE'] : defValue,
          'value3': rowData['TO VALUE'],
          'checkbox': "true" === rowData['NON-INQUIRY'],
          'user': rowData['USER'],
          'art': rowData['REQUEST TYPE'],
          'at': rowData['TAG'],
          'apr': rowData['REGION'],
          'assign': rowData['Assign To Group'], //C153176-5643:Upload FIeld
          'attachment': "true" === rowData['ATTACHMENT']
        };
        inquiryRuleList.push(inquiryRule);
      });
      this.gridApiInquiry.applyTransaction({ add: inquiryRuleList });
      this.routesList = [];
      this.routesList = [...inquiryRuleList];

      let message = 'Inquiry Rules are created successfully !';
      this.onInquiryRulesShowMessage(true, message);
    }
  }

  /**
   * C153176-4445: On Show Inquiry Rules Upload messages
   */
  onInquiryRulesShowMessage(isSuccess: boolean, message: string): void {
    this.inquiryRulesUpload = true;
    this.inquiryRulesMsgTitle = isSuccess ? 'Success Message' : 'Error Message';
    this.inquiryRulesMsg = message;

    //  Timeout Message
    setTimeout(() => {
      this.inquiryRulesUpload = false;
      this.inquiryRulesMsgTitle = '';
      this.inquiryRulesMsg = '';
    }, 3000);
  }

  /**
   * C153176-4445: Method to show the overwrite rule confirmation popup.
   */
  onInquiryRulesApply(): void {
    let overwriteMsg = 'The rules from the uploaded file will overwrite the existing set of rules in the screen.';
    this.inquiryRulesConfirm = true;
    this.inquiryRulesMsg = overwriteMsg;
  }

  /**
   *  C153176-4445: Method called when user confirm to override the existing inquiry rules.
   */
  onInquiryRulesConfirm(): void {
    this.onInquiryRulesCancel();
    this.updateInquiryRulesGrid(this.uploadedValidInqRules);
  }

  /**
   *  C153176-4445: Method called when user cancle to override the existing inquiry rules.
   */
  onInquiryRulesCancel(): void {
    this.inquiryRulesConfirm = false;
    this.inquiryRulesMsg = '';
  }

  /**
   *  C153176-4445: Method called when user confirm or cancel for Rejected inquiry rules.
   */
  onInquiryRulesRejectedConfirm(): void {
    this.inquiryRulesRejected = false;
    if (this.uploadedValidInqRules.length > 0) {
      this.onInquiryRulesApply();
    }
  }

  /**
   * Method to download failed Inquiry rules
   */
  onDownloadLogs(): void {
    this.excelService.downloadAsText(this.invalidRowMessage, "Rejected_Inquiry_Rules");
  }

  /**
   * Method to close Panel
   */
  closePanel() {
    this.rightNavigationService.close();
    this.activeMenuArray[7] = false;
  }

  /**
   * C153176-5652 | Admin - escalation criteria can be saved with -ve values.
   * On enter number.
   *
   * @param e
   */
  onEnterNumber(e: KeyboardEvent): boolean {
    if (!((e.keyCode > 95 && e.keyCode < 106) || (e.keyCode > 47 && e.keyCode < 58 || e.keyCode == 8))) {
      return false;
    }
  }

  // C153176-5633 Restore button implemention for the rules screen
  restoreRule(event, overlayPanel): void {
    this.routesList = [];
    this.validationRules = '';
    const restoreRulesDatabyGroup$ = this.groupAdminService.getGroupRestoreRulesDataById(this.selectedGroup['name']);
    restoreRulesDatabyGroup$.subscribe((data) => {
      let groupData = data;
      this.populateGroupDataDetails(groupData);
    });
    overlayPanel.hide();
  }
  // for cancel restore operation
  cancelRestorePopup(event, overlayPanel) {
    const groupDatabyGroup$ = this.groupAdminService.getGroupDataByGroup(this.selectedGroup['name']);
    groupDatabyGroup$.subscribe((data) => {
      let groupData = data;
      console.log('restore privious rule is:' + JSON.stringify(groupData));
      this.populateGroupDataDetails(groupData);
    });
    overlayPanel.hide();
  }
  // C153176-5511 - Open QMA Admin modal windows
  openQMAAdmin(): void {
    this.displayUserAdmin = true;
  }
  closeQMAAdmin(data: any): void {
    console.log('it is user admin method');
    console.log(this.displayUserAdmin);
    this.displayUserAdmin = false;
  }
  //C153176-5517 - Open send user notification windows modal
  sendEmailNotification(): void {
    this.displaySendUserNotification = true;
  }

  closeSendEmailNotification(data: any): void {
    console.log('close send email notification');
    console.log(this.displaySendUserNotification);
    this.displaySendUserNotification = false;
  }

  // C153176-5511,C153176-5517
  onMenuClick(_clickedItemIndex: number, _expand: boolean, _option: string = '', _count?: number) {
    let clickedItem = this.activeMenuArray[_clickedItemIndex];
    this.activeMenuArray.fill(false);
    this.activeMenuArray[_clickedItemIndex] = _expand ? !clickedItem : true;
    this.hideSubMenu = _expand;
    if (_option) {
      this.hideSubMenu = false;
    }
  }
  //C153176-5516
  openDatabaseAdmin(): void {
    this.displayDatabaseAdmin = true;
  }
  closeDatabaseAdmin(data: any): void {
    this.displayDatabaseAdmin = false;
  }

  /**
   * C170665-10 | Method to update the request type and root cause mapping on delete operation.
   *
   * @param deleteItem
   * @param metaDataType
   */
  updateRequestTypeRootCauseMapping(deleteItem: any, metaDataType: string): void {
    let rtrcMap = [...this.reqTypeRootCauseMap];
    let updatedMap = [];
    let rootCausesList = [];
    let requestTypeList = []

    if (metaDataType === 'Root Cause') {
      rootCausesList = this.metaRowData.map(data => data.rootCause);
      let deletedItem = deleteItem[0].rootCause;

      rtrcMap.forEach(item => {
        let rootCause = item.rootCause.filter(rc => rc !== deletedItem);
        if (rootCause && rootCause.length > 0) {
          updatedMap.push({ 'requestType': item.requestType, 'rootCause': rootCause });
        }
      });
    }
    else if (metaDataType === 'RequestTypes') {
      requestTypeList = this.metaRowData.map(data => data.requestType);
      let deletedItem = deleteItem[0].requestType;

      rtrcMap.forEach(item => {
        if (item.requestType !== deletedItem) {
          updatedMap.push({ 'requestType': item.requestType, 'rootCause': item.rootCause });
        }
      });
    }

    // Update the Request Type and Root cause mapping.
    if (requestTypeList.length > 0) {
      this.groupRequestTypes = [...requestTypeList].sort();
    }
    if (rootCausesList.length > 0) {
      this.groupRootCauses = [...rootCausesList].sort();
    }
    this.reqTypeRootCauseMap = [...updatedMap];
  }

  /**
   * Method to update the request type and root cause on adding new.
   *
   * @param metaDataType
   * @param metaData
   */
  updateRequestTypeRootCauseOnAdd(metaDataType: string, metaData: any[]): void {
    let rootCausesList = [];
    let requestTypeList = []

    if (metaDataType === "requestType") {
      requestTypeList = metaData.map(data => data.requestType);
      this.groupRequestTypes = [...requestTypeList].sort();
    }
    else if (metaDataType === "rootCause") {
      rootCausesList = metaData.map(data => data.rootCause);
      this.groupRootCauses = [...rootCausesList].sort();
    }
  }

  /**
   * Method to handle the request when request type and root cause mapping is applied.
   * @param event
   */
  onApplyMapping(event: any): void {
    this.updatedReqTypeCauseMap = [...event];
  }

  /**
   * Method to validate and get the request type and root cause mapping list.
   *
   * @param requestTypes
   * @param rootCauses
   */
  getReqTypeRootCauseMappingList(requestTypes: string[], rootCauses: any): any {
    let requestTypeRootCauseMapping = {};
    let mapping = [];

    if (this.updatedReqTypeCauseMap.length > 0) {
      mapping = [...this.updatedReqTypeCauseMap];
    }
    else {
      mapping = [...this.reqTypeRootCauseMap];
    }

    mapping.forEach(item => {
      if (requestTypes.includes(item.requestType)) {
        let rootCauseList: [] = item['rootCause'];
        let existingRootCauseList = rootCauseList.filter(e => rootCauses.includes(e));
        if (existingRootCauseList.length > 0) {
          requestTypeRootCauseMapping[item.requestType] = existingRootCauseList;
        }
      }
    });
    return requestTypeRootCauseMapping;
  }

  openMailBoxDLMapping(): void {
    this.isMailBoxDLMappingPopup = true;
  }
  closeMailboxDLMappingPopup(event): void {
    this.isMailBoxDLMappingPopup = false;
  }
  openUserGroupAssignment(): void {
    this.isUserGroupAssignmentPopup = true;
  }
  closeUserGroupAssignment(event): void {
    this.isUserGroupAssignmentPopup = false;
  }
  openAdminSetup(): void {
    this.displayAdminSetupPopup = true;
  }
  closeAdminSetup(data: any): void {
    this.displayAdminSetupPopup = false;
  }
  logout(): void {
    this.sessionTimeoutService.cvLogout();
  }
  activateSession(): void {
    this.sessionAlertPopup = false;
    this.userDataService.restoreUserSession().subscribe(
      data => {
        console.log(data);
        this.sessionTimeoutService.resetCounter();
      },
      error => {
        console.log(error);
      }
    );

  }
  closeSessionPopup(): void {
    this.sessionAlertPopup = false;
  }
  subscribeSessionAlert(): void {
    this.sessionTimeoutService.sessionWarning.subscribe(
      data => {
        this.sessionAlertPopup = data;
      },
      error => {
        console.log(error);
      }
    );
  }

  openSuperAdminDialog() {
    this.displaySuperAdminSetup = true;
  }
  closeSuperAdminDialog(data: any): void {
    this.displaySuperAdminSetup = false;
  }
  getOrgData(orgName) {
    console.log("org Name is:" + orgName);
    try {
      let data = {
        "orgName": orgName
      }
      this.superAdminSetupSerivce.getOrgData(data).subscribe((data: any[]) => {
        this.orgData = data;
        if (this.orgData && this.orgData.orgPreferences.enableGroupLevelOverride) {
          this.disableGroupMetadataAddOption = this.orgData.orgPreferences.enableGroupLevelOverride;
        } else {
          this.disableGroupMetadataAddOption = false;
        }
      });
    } catch (error) {
      console.log("Exceptin has occured in UserComponent.getOrgData" + error.message);
    }
  }
  setRequestTypeForRuleScreen(element) {
    if (element.requestTypeMappings) {
      element.requestTypeMappings.forEach(requestTypeMapping => {
        this.inqRequestTypeList.push(requestTypeMapping.requestType);
      })
    }
  }
  // for set org request type in rule screen
  setOrgRequestTypeForRuleScreen(orgData) {
    if (orgData.highLevelRequestTypeMapping) {
      orgData.highLevelRequestTypeMapping.forEach(requestTypeMapping => {
        this.inqRequestTypeList.push(requestTypeMapping.requestType);
      });
    }
  }

  overrideChange(e) {
    this.overrideCheckbox = e.target.checked;
  }
}