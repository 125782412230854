import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Injectable, importProvidersFrom } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxUiLoaderModule } from 'ngx-ui-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { UserModule } from './user/user.module';
import { InboxService } from "src/app/services/inbox.service";
import { DatePipe } from "@angular/common";
import { ErrorHandler } from "@angular/core";
import { GlobalErrorHandler } from "src/app/global-error-handler";
import { ServerErrorInterceptor } from "src/app/server-error.interceptor";
import { DateFormatPipe } from 'src/app/common/pipes/date-format.pipe';
import { InquiryCache } from 'src/app/common/cache/inquiry-cache';
import { ToastModule } from 'primeng/toast';
import { WebsocketService } from './services/websocket/websocket.service';
import * as Hammer from 'hammerjs';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { DashboardService } from './services/dashboard/dashboard.service';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from '@dchtools/ngx-loading-v18';
import { ResponseInterceptor } from './services/http-response-interceptor';
import { SessionTimeOut } from './services/sessiontimeout.service';
import { AuthenticationInterceptor } from './services/http-interceptor';
import { MicrofrontendInboxModule } from './microfrontend-inbox/microfrontend-inbox.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { provideOAuthClient, OAuthModule } from 'angular-oauth2-oidc';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: { direction: Hammer.DIRECTION_HORIZONTAL },
  };
  //C153176-5178:Added to fix the scroll blocking on swipe event on mailbox stats mobile
  buildHammer(element: HTMLElement) {
    let options = {};

    if (element.attributes['data-mc-options']) {
      try {
        options = JSON.parse(element.attributes['data-mc-options'].nodeValue);
      }
      catch (err) {
        console.error('An error occurred when attempting to parse Hammer.js options: ', err);
      }
    }

    const mc = new Hammer(element, options);
    // retain support for angular overrides object
    for (const eventName of Object.keys(this.overrides)) {
      mc.get(eventName).set(this.overrides[eventName]);
    }
    return mc;
  }
}

export function initConfiguration(dashboardService: DashboardService) {
  return () => dashboardService.onLoad();
}

@NgModule({
  declarations: [
    AppComponent,
    DateFormatPipe,
    //SafePipe,
    //AttachmentViewerDialogComponent

  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    UserModule,
    NgxUiLoaderModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      backdropBorderRadius: '4px',
      primaryColour: '#021e48',
      secondaryColour: '#021e48',
      tertiaryColour: '#021e48',
      fullScreenBackdrop: true
    }),
    ToastModule,
    FontAwesomeModule,
    MicrofrontendInboxModule,
    OAuthModule.forRoot()
  ],
  providers: [
    InboxService,
    DashboardService,
    WebsocketService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: initConfiguration, multi: true, deps: [DashboardService] },
    DatePipe,
    DateFormatPipe,
    InquiryCache,
    ToastModule,
    SessionTimeOut,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    importProvidersFrom(HttpClientModule),
    provideOAuthClient()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
