import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { Module } from '@ag-grid-community/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Subscription } from 'rxjs';
import { DateFormatPipe } from 'src/app/common/pipes/date-format.pipe';
import { AppUtils } from 'src/app/common/utility/appUtil';
import { MicrofrontendInboxService } from 'src/app/microfrontend-inbox/microfrontend-inbox.service';
import { RequestCacheService } from 'src/app/services/cache/request-cache.service';
import { InboxService } from 'src/app/services/inbox.service';
import { ColDefsService } from 'src/app/services/mailbox/get-ColDefsService.service';
import { GridOperationService } from 'src/app/services/mailbox/grid-operation.service';
import { GridUpdateService } from 'src/app/services/mailbox/mailbox-grid-update.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { ContextMenuService } from '../../services/contextMenu/context-menu.service';
import { TabDataService } from '../../services/tab-data.service';
import { WebsocketService } from '../../services/websocket/websocket.service';
import { DragGripCellRenderer } from './drag-grip-cell-renderer.component';

@Component({
  selector: 'app-annex-renderer',
  styleUrls: ['./inboxView.component.scss'],
  template: `
  <ng-container *ngIf="!isGroupNode; else emptyDiv">
  <div class="annex-column">
    <div (click)="showOverlay($event)">
      <img src="assets/Inbox/secondary_icon_table.svg" alt="Secondary data" width="14" height="14" class="iconWithHover" pTooltip="View Secondary Data" tooltipPosition="top" tooltipStyleClass='leftTooltip p-tooltip-text'/>
    </div>
    <div [innerHTML]="annexHtml"></div>
  </div>
  
  <p-overlayPanel #opSecondaryColumns  [appendTo]="'body'" [styleClass]="'qma-secondary-overlay'">
        <div class="qma-secondary-data-header-container">
            <div class="qma-secondary-data-header">
                INQUIRY SECONDARY DATA
            </div>
            <div class="qma-secondary-data-close" (click)="opSecondaryColumns.hide()" >
                <img src="assets/Inbox/close_choose_secondary_column.svg" alt="Copy icon" width="18" height="18" />
            </div>
        </div>
        <div class="grid-text" *ngIf="!loading">
            <div class="row">
                <div class="col-6 secondary-row-min-height" *ngFor="let secondaryMenu of secondaryMenu">
                    <div pTooltip="{{secondaryMenu.headerName}}" tooltipPosition="bottom" class="grid-detail-header">
                        {{secondaryMenu.headerName}}
                        </div>
                    <div pTooltip="{{secondaryMenu.field}}" tooltipPosition="bottom" class="grid-detail-data">
                        {{secondaryMenu.field}}
                    </div>
                </div>
            </div>
        </div>
    </p-overlayPanel>
  </ng-container>
  <ng-template #emptyDiv>
            <div></div> <!-- Empty div for group nodes -->
        </ng-template>
  `,
})
export class AnnexRendererComponent implements OnInit {
  @ViewChild('opSecondaryColumns') opSecondaryColumns: OverlayPanel;
  modules: Module[] = [ClientSideRowModelModule];

  // C153176-4456: cache of opened conversation histories, aged with periodic timer
  private static cacheMap: any = {};
  private static cacheMapCleanupTimer: any;
  private static CACHE_TIMER_INTERVAL: number = 300000; // 5-minute
  private static CACHE_TTL_MS = 3600 * 1000; // 1-hour
  private cacheMatch = {
    NOT_FOUND: 0,
    PARTIAL: 1,
    EXACT: 2,
  };
  loading: boolean = true;

  public params: any;
  public masterGridApi;
  public masterRowIndex;
  public selectedColumns = [];
  public colDefs = [];
  public rowData = [];
  public popupParent: any;
  public rowSelection: string = 'single';
  public secondaryMenuData = [];
  public secondaryMenu = [];
  public childInqueryarr = new Array();
  public guserPrefDateFormat: string = '';
  public dateFormatPipe: DateFormatPipe;
  public rowClassRules: any;
  public loginUserInfo: any;
  public currentTab: string;
  public dateFormatterUnixMap = new Map<string, string>();
  public format: string;
  public clickedIcon: string;
  public height = 200;
  public gridApi: any;
  public previousSelectedConv = [];
  public selectedConv = [];
  public annexHtml: string;
  private reDrawSubs: Subscription;
  private convContextSubs: Subscription;
  private allConvReadUnreadSubs: Subscription;
  private inqSelection: Subscription;
  private skippedSelectedNode: any;
  public isGroupNode: boolean = false; 
  // websocket subscription
  private webSocketSubscription: Subscription;
  // keys to ignore for ws update of inq
  private wsKeysToIgnore = [
    'action',
    'approvalBypassed',
    'attachments',
    'audFlag',
    'bubbleContent',
    'content',
    'crtBy',
    'crtDate',
    'id',
    'inActive',
    'inquiryId',
    'isS3Migrated',
    'latestMsgRef',
    'memo',
    'modBy',
    'modDate',
    'nominatedRecipients',
    'parentConversationId',
    'readBy',
    'receiptDate',
    'recipients',
    'sentDate',
    'subject',
    'suggestionAvailable',
    'suggestionStatus',
    'urgentFlag',
    'version',
  ];
  private isWebSocketRedesignEnable = false;
  public gridOptions: any;
  defaultColDef = {
    cellStyle: function (params) {
      // C170665-260 AG Grid Issues
      // algin detail grid with parent grid
      if (!params.context) {
        return {
          marginLeft: '7px',
        };
      }
    },
    resizable: true,
  };
  conversationIdforInquiry: any[];

  constructor(
    private colDefsService: ColDefsService,
    // private inboxService: InboxService,
    // private userDataService: UserDataService,
    // private gridOprService: GridOperationService,
    // private tabDataService: TabDataService,
    // private gridUpdateService: GridUpdateService,
    // private contextMenuService: ContextMenuService,
    // private requestCacheService: RequestCacheService,
    // private wsService: WebsocketService,
    // private micrfrontendInboxService: MicrofrontendInboxService
  ) {
    this.dateFormatPipe = new DateFormatPipe();
    this.dateFormatterUnixMap.set(
      'dd/MMM/yy h:mm:ss tt',
      'DD/MMM/YY h:mm:ss A'
    );
    this.dateFormatterUnixMap.set(
      'M/dd/yyyy h:mm:ss tt',
      'M/DD/YYYY h:mm:ss A'
    );
    this.dateFormatterUnixMap.set(
      'dd/M/yyyy h:mm:ss tt',
      'DD/M/YYYY h:mm:ss A'
    );
    this.dateFormatterUnixMap.set('dd/M/yyyy hh:mm:ss', 'DD/M/YYYY hh:mm:ss');
    this.dateFormatterUnixMap.set('dd Month', 'DD MMMM');
    this.dateFormatterUnixMap.set('dd Mmm yy hh:mm tt', 'DD MMM YY hh:mm A');
  }
  showOverlay(event: Event) {
    this.currentTab = AppUtils.getCurrentTab().toUpperCase();
    this.colDefs = this.colDefsService.getStoredArrColDefs(this.currentTab);
    this.selectedColumns = this.colDefs.filter(
      (col) =>
        col.field !== 'cheveron' &&
        col.field !== 'expander' &&
        col.cellRendererFramework !== DragGripCellRenderer &&
        col.showInSecondary
    );
    this.selectedColumns.forEach((element) => {
      // C153176-4660: add null check
      let data =
        this.params && this.params.data
          ? this.params.data[element.field]
          : undefined;
      if (data && (typeof data === 'string' || typeof data === 'number')) {
        this.secondaryMenuData[element.field] = data.toString();
      } else if (element.field.includes('$date')) {
        data =
          this.params.data[element.field.replace('$date', '').replace('.', '')];
        this.secondaryMenuData[element.field] = this.retiriveDate(data);
      } else {
        this.secondaryMenuData[element.field] = this.retiriveDate(data);
      }
    });
    this.onGridReady();
    
    console.log('this.secondaryMenuData: ', this.secondaryMenuData);
    
    this.loading = false;
    this.opSecondaryColumns.show(event); // Pass the event to the show method
  }
  ngOnInit() {
    console.log('this isGroupNode',this.isGroupNode);
    if(this.isGroupNode){

    }
    else{
      // this.currentTab = AppUtils.getCurrentTab().toUpperCase();
      // this.colDefs = this.colDefsService.getStoredArrColDefs(this.currentTab);
      // // C170665-103 Email Icon is cropped in intensity heatmap graphs
      // // remove symphony column from inner grid if not in parent grid
      // if (
      //   !this.params.context.componentParent.gridApi
      //     .getAllDisplayedColumns()
      //     .some((c) => c.colId === 'isSymphonyChatGridCol')
      // ) {
      //   if (this.colDefs) {
      //     this.colDefs.splice(
      //       this.colDefs.findIndex(
      //         (col) => col.field === 'isSymphonyChatGridCol'
      //       ),
      //       1
      //     );
      //   }
      // }
      // if (!this.colDefs) {
      //   return;
      // }
      // this.colDefs.forEach((col, key) => {
      //   if (col.field && col.field != '') {
      //     if (col.field.indexOf('.$date') != -1) {
      //       let colArr = col.field.split('.');
      //       col.field = colArr[0];
      //       col.tooltipField = colArr[0];
      //     }
      //     if (col.field == 'expander') {
      //       col.cellClass = 'qma-mailbox-cell';
      //       col.cellRenderer = '';
      //       col.enableRowGroup = true;
      //       col.hide = false;
      //       col.resizable = true;
      //       col.showInSecondary = false;
      //       col.suppressMenu = true;
      //       col.suppressMovable = false;
      //       col.cellStyle = '';
      //       col.checkboxSelection = false;
      //       col.width = 17;
      //     } else if (col.cellRendererFramework === DragGripCellRenderer) {
      //       col.cellClass = 'qma-mailbox-cell';
      //       col.cellRendererFramework = '';
      //       col.cellRenderer = '';
      //       col.enableRowGroup = true;
      //       col.hide = false;
      //       col.resizable = false;
      //       col.showInSecondary = false;
      //       col.suppressMenu = true;
      //       col.suppressMovable = false;
      //       col.cellStyle = '';
      //       col.width = 15;
      //     }
      //     //Converting field name as per API, Jira - 4456
      //     /*  if(col.field == '_id'){
      //             col.field = "inquiryId"
      //         } */
      //   }
      // });
      // this.popupParent = document.querySelector('body');
      // // Jira 5652
      // this.selectedColumns = this.colDefs.filter(
      //   (col) =>
      //     col.field !== 'cheveron' &&
      //     col.field !== 'expander' &&
      //     col.cellRendererFramework !== DragGripCellRenderer &&
      //     col.showInSecondary
      // );
      // this.selectedColumns.forEach((element) => {
      //   // C153176-4660: add null check
      //   let data =
      //     this.params && this.params.data
      //       ? this.params.data[element.field]
      //       : undefined;
      //   if (data && (typeof data === 'string' || typeof data === 'number')) {
      //     this.secondaryMenuData[element.field] = data.toString();
      //   } else if (element.field.includes('$date')) {
      //     data =
      //       this.params.data[element.field.replace('$date', '').replace('.', '')];
      //     this.secondaryMenuData[element.field] = this.retiriveDate(data);
      //   } else {
      //     this.secondaryMenuData[element.field] = this.retiriveDate(data);
      //   }
      // });
  
      // // this.reDrawConvGrid();
      // // this.convContextMenu();
      // this.markAllConvReadUnread();
      // // this.subscribeInquirySelection();
      // this.onGridReady();
      this.annexHtml = this.cellRenderer(this.params);
    }
 
  }

  cellRenderer(params) {
    let prevtimeTakenAnnex = sessionStorage.getItem('annexTimeTaken');
    let t0 = performance.now();
    var followupFlag = '';
    var autoAssignedFlag = '';
    var notesFlag =
      params.data.notesFlag === 'Y'
        ? "<span class='qma-inbox-notes'><img class='mailbox-notes' src='assets/Inbox/notes_icon_selected_u108.svg' alt='Kiwi standing on oval'></span>"
        : "<span class='qma-inbox-notes'></span>";
    // C153176-5407 Use workflow attachment flag to show attachemtn in grid
    var attachFlag = "<span class='qma-inbox-attachement'></span>";
    let matchingWf = params.data.workflows.find(
      (wf) => wf.assignedGroupName === params.data.openGroups
    );
    if (matchingWf) {
      if (matchingWf.attchFlag && matchingWf.attchFlag === 'Y') {
        attachFlag =
          "<span class='qma-inbox-attachement'><img class='' src='assets/Inbox/attachments_icon_selected_u107.svg' alt='Kiwi standing on oval'></span>";
      }
    }
    var esclationFlag =
      params.data.isEscalated === 'Y'
        ? "<span class='qma-inbox-escalated'><i class='fa fa-exclamation-triangle' style='color:red;' title='" +
          params.data.escalationReason +
          "'></i></span>"
        : "<span class='qma-inbox-escalated'></span>";
    var important =
      params.data.urgentFlag === 'Y'
        ? "<span class='qma-inbox-urgentFlg'> <i class='fa fa-exclamation' style='color: #751919;'></i></span>"
        : "<span class='qma-inbox-urgentFlg'></span>";
    if (
      (params.data.followUp && params.data.followUp === 'Y') ||
      (params.data.followUp &&
        params.data.followUp.flag &&
        params.data.followUp.flag === 'Generic')
    ) {
      followupFlag =
        "<span class='qma-inbox-followup'><img src='assets/Inbox/follow_up_icon_u112.svg' class='qma-grid-followup' alt='Kiwi standing on oval' class='qma-grid-followup'></span>";
    } else {
      followupFlag = "<span class='qma-inbox-followup'></span>";
    }

    // var nlp = (params.data.suggestionIndicator === "O" || params.data.suggestionIndicator === "R" || params.data.suggestionIndicator === "B" || params.data.suggestionIndicator === "G") ? "<span class='qma-inbox-nplm'><img src='assets/Inbox/nlp_icon_selected_u104.svg' alt='Kiwi standing on oval'></span>" : "";
    /*C153176-4660 Nlp icon some times blank so annex icon distorted*/
    let nlp = "<span class='qma-inbox-nplm mailbox-img-nlp'></span>";
    if (params.data.suggestionIndicator) {
      switch (params.data.suggestionIndicator) {
        case 'O': {
          nlp =
            "<span class='qma-inbox-nplm'><img src='assets/Inbox/nlp_icon_selected_u104_orange.svg' class='mailbox-img' alt='Kiwi standing on oval'></span>";
          break;
        }
        case 'R': {
          nlp =
            "<span class='qma-inbox-nplm'><img src='assets/Inbox/nlp_icon_selected_u104_red.svg' class='mailbox-img' alt='Kiwi standing on oval'></span>";
          break;
        }
        case 'B': {
          nlp =
            "<span class='qma-inbox-nplm'><img src='assets/Inbox/nlp_icon_selected_u104.svg' class='mailbox-img' alt='Kiwi standing on oval'></span>";
          break;
        }
        case 'G': {
          nlp =
            "<span class='qma-inbox-nplm'><img src='assets/Inbox/nlp_icon_selected_u104_green.svg' class='mailbox-img' alt='Kiwi standing on oval'></span>";
          break;
        }
        default: {
          nlp = "<span class='qma-inbox-nplm mailbox-img-nlp'></span>";
          break;
        }
      }
    }

    if (params.data.autoAssigned && params.data.autoAssigned === 'Y') {
      autoAssignedFlag =
        "<span class='qma-inbox-nplm'><img src='assets/Inbox/auto-assign.svg' class='mailbox-img' alt='Kiwi standing on oval'></span>";
    } else {
      autoAssignedFlag = "<span class='qma-inbox-nplm mailbox-img-nlp'></span>";
    }
    var t1 = performance.now();
    if (prevtimeTakenAnnex) {
      const newtimeTaken =
        JSON.parse(sessionStorage.getItem('annexTimeTaken')) + (t1 - t0);
      sessionStorage.setItem('annexTimeTaken', newtimeTaken);
    } else {
      sessionStorage.setItem('annexTimeTaken', JSON.stringify(t1 - t0));
    }
    if (params.data.age && params.data.modDate.$date) {
      return (
        "<div class='qma-mailbox-annex'>" +
        "<div class='mailbox-icons-placeholder'>" +
        important +
        esclationFlag +
        nlp +
        followupFlag +
        attachFlag +
        notesFlag +
        autoAssignedFlag +
        '</div>' +
        '</div>'
      );
    } else {
      return (
        "<div class='qma-mailbox-annex-single-line'>" +
        important +
        esclationFlag +
        nlp +
        followupFlag +
        attachFlag +
        notesFlag +
        autoAssignedFlag +
        '</div>'
      );
    }
  }
  onGridReady() {
    // this.gridApi = params.api;
    // var detailGridId = "detail_" + this.masterRowIndex;

    // var gridInfo = {
    //     id: detailGridId,
    //     api: params.api,
    //     columnApi: params.columnApi
    // };
    let key = 0;
    this.selectedColumns.forEach((element) => {
      if (element.field !== 'cheveron_1') {
        this.secondaryMenu[key] = {
          headerName: element.headerName,
          field: this.secondaryMenuData[element.field],
        };
        key++;
      }
    });
    
    console.log('this.secondaryMenu: ', this.secondaryMenu);
    // if (this.masterGridApi) {
    //     this.masterGridApi.addDetailGridInfo(detailGridId, gridInfo);
    // }
    // // C153176-4660: enforce conv selection
    // setTimeout(()=> {
    //     this.handleSelectedConv(this.params.node.data,
    //         this.params.node.data.openGroups,
    //         [this.params.data.workflows[0].assignedGroupId]);
    // }, 100);
  }
  // markAllConvReadUnread() {
  //   this.allConvReadUnreadSubs = this.gridUpdateService
  //     .getReadUnreadInqIds()
  //     .subscribe((conversationIds) => {
  //       if (Object.entries(conversationIds).length > 0) {
  //         if (conversationIds[0].status === 'read') {
  //           this.markAllConvRead(conversationIds);
  //         } else {
  //           this.markAllConvUnRead(conversationIds);
  //         }
  //       }
  //     });
  // }
  // markAllConvRead(conversationIds) {
  //   // C153176-4669: skip checking 'this.gridApi' here so that the readBy is updated in rowData
  //   if (Object.entries(conversationIds).length > 0) {
  //     if (conversationIds[0].inqId) {
  //       let readInqIdList = new Array();
  //       conversationIds.forEach((conv) => {
  //         this.rowData.forEach((rData, key) => {
  //           if (rData.inquiryId === conv.inqId) {
  //             if (rData.readBy) {
  //               rData.readBy.push(this.loginUserInfo.userId);
  //             } else {
  //               // C153176-4660: set readBy to conversation in separate array
  //               let readBy = new Array();
  //               readBy.push(this.loginUserInfo.userId);
  //               rData.readBy = readBy;
  //             }
  //           }
  //         });
  //       });
  //       if (this.gridApi) {
  //         this.gridApi.redrawRows();
  //       }
  //     }
  //   }
  // }

  // markAllConvUnRead(conversationIds) {
  //   // C153176-4669: skip checking 'this.gridApi' here so that the readBy is updated in rowData
  //   if (Object.entries(conversationIds).length > 0) {
  //     if (conversationIds[0].inqId) {
  //       let inquiryIndex;
  //       conversationIds.forEach((conv) => {
  //         // C153176-4669: should use 'inqId' vs. 'inquiryId'
  //         inquiryIndex = this.rowData.findIndex(
  //           (irowData) => irowData.inquiryId === conv.inqId
  //         );
  //         if (inquiryIndex === -1) {
  //           return;
  //         }
  //         let readById = this.loginUserInfo.userId;
  //         let readyBy = this.rowData[inquiryIndex].readBy
  //           ? this.rowData[inquiryIndex].readBy.filter((d) => d !== readById)
  //           : undefined;
  //         this.rowData.forEach((rData, key) => {
  //           if (rData.inquiryId === conv.inqId) {
  //             // C153176-4669: set readBy to conversation in separate array
  //             rData.readBy = [];
  //             if (readyBy && readyBy.length) {
  //               rData.readBy = [...readyBy];
  //             }
  //           }
  //         });
  //       });
  //       if (this.gridApi) {
  //         this.gridApi.redrawRows();
  //       }
  //     }
  //   }
  // }
  // handleInquirySelection(data) {
  //   if (!this.gridApi || !data || !data._id) {
  //     return;
  //   }
  //   // deselect all the conversations if any
  //   const selectedNodes = this.gridApi.getSelectedNodes();
  //   if (selectedNodes) {
  //     selectedNodes.forEach((node) => {
  //       if (node && node.isSelected()) {
  //         node.setSelected(false);
  //       }
  //     });
  //   }
  // }
  // subscribeInquirySelection() {
  //   this.inqSelection = this.gridOprService
  //     .getInquirySelection()
  //     .subscribe((data) => {
  //       this.handleInquirySelection(data);
  //     });
  // }
  // convContextMenu() {
  //   this.convContextSubs = this.gridUpdateService
  //     .getReadUnreadConvInqIds()
  //     .subscribe((conversation) => {
  //       if (Object.entries(conversation).length > 0) {
  //         if (conversation.readStatus) {
  //           this.markConvRead(conversation);
  //         } else {
  //           this.markConvUnread(conversation);
  //         }
  //       }
  //     });
  // }

  // markConvRead(conversation) {
  //   // C153176-4669: skip checking 'this.gridApi' here so that the readBy is updated in rowData
  //   if (Object.entries(conversation).length > 0) {
  //     if (conversation.data) {
  //       let readBy = new Array();
  //       let readInqIdList = new Array();
  //       let convIds = [];
  //       conversation.data.forEach((conv) => {
  //         this.rowData.forEach((rData, key) => {
  //           if (rData.id === conv.id) {
  //             if (rData.readBy) {
  //               // C153176-4669: avoid duplication of readBy elements
  //               if (
  //                 rData.readBy.findIndex(
  //                   (r) => r && r === this.loginUserInfo.userId
  //                 ) === -1
  //               ) {
  //                 rData.readBy.push(this.loginUserInfo.userId);
  //               }
  //             } else {
  //               readBy.push(this.loginUserInfo.userId);
  //               rData.readBy = readBy;
  //             }
  //           }
  //           if (
  //             rData &&
  //             rData.readBy &&
  //             rData.readBy.includes(this.loginUserInfo.userId)
  //           ) {
  //             // C153176-4669: avoid count dups in 'readInqIdList' as its count will be used to determine whether the inquiry should be set to Read
  //             if (convIds.findIndex((v) => v && v === rData.id) === -1) {
  //               convIds.push(rData.id);
  //               readInqIdList.push({
  //                 inqId: conversation.data[0].inquiryId,
  //                 agrpId: this.params.node.data.workflows[0].assignedGroupId,
  //               });
  //             }
  //           }
  //         });
  //       });
  //       if (this.gridApi) {
  //         this.gridApi.redrawRows();
  //         if (readInqIdList.length == this.rowData.length) {
  //           // Inquery and group id updated - Jira - 4456
  //           this.gridUpdateService.setreadInqIds([
  //             {
  //               inqId: conversation.data[0].inquiryId,
  //               // null check
  //               agrpId:
  //                 this.params.node &&
  //                 this.params.node.data &&
  //                 this.params.node.data.workflows &&
  //                 this.params.node.data.workflows.length
  //                   ? this.params.node.data.workflows[0].assignedGroupId
  //                   : undefined,
  //               status: 'conversation',
  //             },
  //           ]);
  //         }
  //       }
  //     }
  //   }
  //   return;
  // }

  // markConvUnread(conversation) {
  //   // C153176-4669: skip checking 'this.gridApi' here so that the readBy is updated in rowData
  //   if (Object.entries(conversation).length > 0) {
  //     if (conversation.data) {
  //       let inquiryIndex;
  //       let unreadInqIdList = new Array();
  //       conversation.data.forEach((conv) => {
  //         // C153176-4669: act on the conversation which matches the incoming one, by matching conv id, not the inquiryId
  //         this.rowData.forEach((rData) => {
  //           if (rData.id === conv.id) {
  //             let readById = this.loginUserInfo.userId;
  //             let readyBy = rData.readBy
  //               ? rData.readBy.filter((d) => d !== readById)
  //               : undefined;
  //             // assign new readBy array
  //             rData.readBy = [];
  //             rData.readBy = readyBy;
  //             // null check
  //             let groupId =
  //               this.params.node &&
  //               this.params.node.data &&
  //               this.params.node.data.workflows &&
  //               this.params.node.data.workflows.length
  //                 ? this.params.node.data.workflows[0].assignedGroupId
  //                 : undefined;
  //             // C153176-4669: avoid dup entry in 'unreadInqIdList'
  //             if (
  //               unreadInqIdList.findIndex(
  //                 (em) =>
  //                   em && em.inqId === rData.inquiryId && em.agrpId === groupId
  //               ) === -1
  //             ) {
  //               unreadInqIdList.push({
  //                 inqId: rData.inquiryId,
  //                 agrpId: groupId,
  //               });
  //             }
  //           }
  //         });
  //       });
  //       if (this.gridApi) {
  //         this.gridApi.redrawRows();
  //         this.gridUpdateService.setunreadInqIds(unreadInqIdList);
  //       }
  //     }
  //   }
  //   return;
  // }

  // This method is called by AG Grid when initializing the cell renderer
  agInit(params: any): void {
    this.params = params;
    this.isGroupNode = params.node.group;
  }
  retiriveDate(dateField) {
    if (dateField) {
      return this.convertDate(dateField.$date, this.guserPrefDateFormat);
    } else {
      return '';
    }
  }
  convertDate(unixDate, dateFormat) {
    this.dateFormatterUnixMap.forEach((value: string, key: string) => {
      if (key === dateFormat) {
        this.format = value;
      }
    });
    return moment(unixDate).format(this.format) === 'Invalid date'
      ? unixDate
      : moment(unixDate).format(this.format);
  }
  // reDrawConvGrid() {
  //   this.reDrawSubs = this.colDefsService
  //     .getConvRow()
  //     .subscribe((conversationNode) => {
  //       if (Object.entries(conversationNode).length > 0 && this.gridApi) {
  //         conversationNode.forEach((conv) => {
  //           this.rowData.forEach((rData, key) => {
  //             if (rData.id === conv.id) {
  //               this.rowData[key].read = conv.readBy;
  //             }
  //           });
  //         });
  //         this.gridApi.redrawRows();
  //         //this.gridApi.redrawRows({rowNodes: conversationNode});
  //         //
  //       }
  //     });
  // }

  // // Handle button click

  // // AG Grid also requires this method, which can be left as is for now
  // refresh(params: any): boolean {
  //   return false;
  // }
}
