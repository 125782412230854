<div [ngClass]="styleClass">
  <div *ngIf="search" class="input-group d-flex py-3 px-2 justify-content-center">
    <input class="px-2 py-1 w-100 input-text" type="text" #myInput placeholder='{{placeholder}}' (input)="filterItem(myInput.value)" style='background-color: #F8F9FC'>
    <div class="input-group-btn searchBox">
        <button class="btn btn-default">
            <span>
               <img style="width:16px;height:16px;" src='assets/qwyn/icons/search-icon.svg' alt="Search">
            </span>
        </button>
      </div>
  </div>
  <div>
      <ul class="list-disp pl-2 pr-1"  [ngStyle]="listStyle">
        <li *ngIf="enableSelectAll">
          <div class="custom-control custom-checkbox">
              <input #checkboxes type="checkbox" class="custom-control-input" value="selectAll" [checked]="selectAllBinary"
                id="selectAllOption" name="selectOpt" (change)="onSelectAllClick($event)" custom-filled-input>
                <img class="search-icon" [src]="'assets/qwyn/icons/search-icon.svg'" alt="">
              <label class="custom-control-label" for="selectAllOption">Select All</label>
            </div>
        </li>
        <li *ngFor="let item of filteredItems; let i = index" class="mb-2" [ngSwitch]="controlType">
            <div class="custom-control custom-radio" *ngSwitchCase="'radio'">
              <input #radioboxes type="radio" class="custom-control-input" [attr.value]="filteredItems[i].label" [attr.id]="assignDynamicId(filteredItems[i], i)"
                [attr.name]="name" (click)="getSelection($event)" [checked]="filteredItems[i].binary">
              <label class="custom-control-label" [attr.for]="assignDynamicId(filteredItems[i], i)">{{filteredItems[i].label}}</label>
            </div>
          <div class="custom-control custom-checkbox" *ngSwitchDefault>

            <input #checkboxes type="checkbox" class="custom-control-input" [attr.value]="filteredItems[i].label"
              [attr.id]="assignDynamicId(filteredItems[i],i)" [attr.name]="name" [checked]="filteredItems[i].binary" (click)="getSelection($event)" (change)="updateSelectedItems($event)" >
            <label class="custom-control-label" [attr.for]="assignDynamicId(filteredItems[i],i)">{{filteredItems[i].label}}</label>
          </div>
        </li>
      </ul>
  </div>
</div>