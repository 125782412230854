import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-id-copy',
  templateUrl: './id-copy.component.html',
  styleUrl: './id-copy.component.scss',
})
export class IdCopyComponent {
  @Input() id: string = ''; // Input property for the ID number

  // Function to copy the ID to the clipboard
  copyToClipboard() {
    navigator.clipboard.writeText(this.id).then(() => {
      console.log('ID copied to clipboard!');
    });
  }

  onKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault(); // Prevent the default behavior for Space key (page scroll)
      this.copyToClipboard();
    }
  }
}
