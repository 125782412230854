<div class="audit-container" *ngIf="attachmentCount > 0">
  <div (click)="onDownloadAllAttachment()" class="downloadAllButton">
    <img [src]="'assets/Inbox/downloadBlue.svg'" alt="Download" />
    <div class="downloadAllText">Download All</div>
  </div>
  <div
    class="pinContainer"
    pTooltip="Pin Side Bar"
    tooltipPosition="top"
    tooltipStyleClass="rightTooltip p-tooltip-text"
    (click)="onPinPanel()"
  >
    <img class="default-icon" [src]="'assets/Inbox/pin.svg'" alt="PIN" />
    <img
      class="hover-icon"
      [src]="'assets/Inbox/pinHighlight.svg'"
      alt="PIN"
      
    />
  </div>
</div>
<div class="container attachment-list" *ngIf="attachmentCount > 0">
  <div class="d-flex flex-column">
    <ng-template ngFor let-attachment [ngForOf]="attachmentList">
      <div class="fileItem">
        <div>
          <img [src]="getFileIcon(attachment.name)" alt="FileType" />
        </div>
        <div class="fileDescBox">
          <div class="attach-name" title="{{attachment.name}}">
            {{ (attachment.name.length>30) ? (attachment.name | slice:0:30)+'...':(attachment.name) }}
          </div>
          <div class="attach-time">
            {{attachment.time | date:'dd MMM hh:mm a'}}
          </div>
        </div>
        <div class=" attach-download">
          <img
            class="default-icon"
            [src]="'assets/Inbox/downloadBlack.svg'"
            alt="Download"
            (click)="onDownloadAttachment(attachment)"
          />
          <img
            class="hover-icon"
            [src]="'assets/Inbox/downloadBlue.svg'"
            alt="Download"
            (click)="onDownloadAttachment(attachment)"
            pTooltip="Download"
            tooltipPosition="top"
            tooltipStyleClass="rightTooltip p-tooltip-text"
          />
        </div>
        <div class="icon-divider"></div>
        <div class="attach-download">
          <img
            class="default-icon"
            src="assets/Inbox/copyBlack.svg"
            alt="Copy"
            name="copy"
            (click)="onCopyAttachment(attachment)"
            title="{{attachment?.copiedMsg}}"
          />
          <img
            class="hover-icon"
            src="assets/Inbox/copyBlue.svg"
            alt="Copy"
            name="copy"
            (click)="onCopyAttachment(attachment)"
            title="{{attachment?.copiedMsg}}"
            pTooltip="Copy"
            tooltipPosition="top"
            tooltipStyleClass="rightTooltip p-tooltip-text"
          />
        </div>
      </div>
    </ng-template>
  </div>
</div>
