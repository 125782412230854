<p-sidebar
  [(visible)]="visible"
  [position]="'right'"
  [modal]="false"
  [showCloseIcon]="false"
  (onHide)="hideSlideBar($event)"
  class="sideBarStyle"
>
  <ng-template pTemplate="header">
    <div class="headingText">Secondary Data</div>
    <button class="sidebarCloseIcon" (click)="hideSlideBar($event)">
      <img
        src="assets/Inbox/close_choose_secondary_column.svg"
        alt="Copy icon"
        width="18"
        height="18"
      />
    </button>
  </ng-template>
  <div class="search-container" *ngIf="search">
    <input
      type="text"
      placeholder="Search"
      class="search-input"
      #myInput
      (input)="filterItem(myInput.value)"
    />
    <img
      src="assets/Inbox/search_secondary_column.svg"
      alt="Search"
      width="18"
      height="18"
    />
  </div>
  <div class="listItemsContainer">
    <div class="checkbox-container">
      <input
        #checkboxes
        type="checkbox"
        value="selectAll"
        [checked]="selectAllBinary"
        id="selectAllOption"
        name="selectOpt"
        (change)="onSelectAllClick($event)"
        class="checkbox-input"
      />
      <div class="checkbox-label" for="selectAllOption">Select All</div>
    </div>
    <div
      *ngFor="let item of filteredItems; let i = index"
      [ngSwitch]="controlType"
    >
      <div class="checkbox-container" *ngSwitchCase="'radio'">
        <input
          #radioboxes
          type="radio"
          class="checkbox-input"
          [attr.value]="filteredItems[i].label"
          [attr.id]="assignDynamicId(filteredItems[i], i)"
          [attr.name]="name"
          (click)="getSelection($event)"
          [checked]="filteredItems[i].binary"
        />
        <div
          class="checkbox-label"
          [attr.for]="assignDynamicId(filteredItems[i], i)"
        >
          {{filteredItems[i].label}}
        </div>
      </div>
      <div class="checkbox-container" *ngSwitchDefault>
        <input
          #checkboxes
          type="checkbox"
          class="checkbox-input"
          [attr.value]="filteredItems[i].label"
          [attr.id]="assignDynamicId(filteredItems[i],i)"
          [attr.name]="name"
          [checked]="filteredItems[i].binary"
          (click)="getSelection($event)"
          (change)="updateSelectedItems($event)"
        />
        <div
          class="checkbox-label"
          [attr.for]="assignDynamicId(filteredItems[i],i)"
        >
          {{filteredItems[i].label}}
        </div>
      </div>
    </div>
  </div>
  <div class="sidebar-footer">
    <button class="reset-btn" (click)="resetColumn($event)">Reset</button>
    <button class="apply-btn" (click)="applyColumn($event)">Apply</button>
  </div>
</p-sidebar>
