import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-collapsible-list',
  templateUrl: './collapsible-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./collapsible-list.component.scss'],
})
export class CollapsibleListComponent implements OnInit, OnChanges {
  @Input() placeholder: String;
  @Input() items: Array<object>;
  @Input() listStyle: any = { height: '220px' };
  @Output() onItemSelect = new EventEmitter();

  @Input() active: Boolean;
  @Output() onTagsClick = new EventEmitter();
  @Input() activeTab: String;
  currentActiveTab: String;
  filteredItems: Array<object>;
  native: any;

  ngOnInit() {
    this.assignCopy(); //On Server call
    this.currentActiveTab = this.activeTab;
  }

  assignCopy() {
    this.filteredItems = Object.assign([], this.items);
  }
  ngOnChanges(changes: SimpleChanges) {
    if ('items' in changes) {
      this.filteredItems = changes.items.currentValue;
    }
    if ('activeTab' in changes) {
      this.currentActiveTab = changes.activeTab.currentValue;
    }
  }

  filterItem(value: string) {
    if (!value) {
      this.assignCopy();
    }
    this.filteredItems = Object.assign([], this.items).filter((item) => {
      if (this.isInChild(item, value)) {
        return true;
      }
    });
  }

  isInChild(_item: any, _val: any) {
    let found = false;
    for (var i = 0; i < _item.childList.length; i++) {
      if (
        _item.childList[i].name.toLowerCase().indexOf(_val.toLowerCase()) > -1
      ) {
        found = true;
        break;
      }
    }
    return found;
  }

  itemSelected(par: any, child: any) {
    let obj: any = {};
    obj.parent = par;
    obj.selectedChild = child;
    this.onItemSelect.emit(obj);
  }

  toggleItem(item: any) {
    item.toggle = !item.toggle;
  }
  menuSelected(event: any) {
    this.onTagsClick.emit(event);
  }
}
