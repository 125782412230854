import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-add-notes-modal',
  templateUrl: './add-notes-modal.component.html',
  styleUrl: './add-notes-modal.component.scss'
})
export class AddNotesModalComponent {
  noteText: string = '';
  isVisible: boolean=false;
  @Output() dataEmitter = new EventEmitter<string>();
  public openModal(){
    this.isVisible =true;
  }
  public closeModal(){
    this.isVisible =false;
  }
  submitNotes(){
    this.dataEmitter.emit(this.noteText);
    this.closeModal();
  }
}
