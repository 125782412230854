<div>
  <!-- Menu close icon -->
  <div class="menuActionContainer">
    <div class="menuIcon" (click)="close()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M7.00015 6.79999L17 16.7998M7 17.0135L16.9998 7.01362"
          stroke="#272727"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div class="menuName">Messages</div>
  </div>
  <div class="menucontainer">
    <div class="menuHeading" (click)="onMenuClick(3, true)">
      <div class="menuText">Message Box</div>
      <svg
        *ngIf="!activeMenuArray[3]"
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="12"
        viewBox="0 0 8 12"
        fill="none"
      >
        <path
          d="M1.75 1L6.25 6L1.75 11"
          stroke="#000F29"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <svg
        *ngIf="activeMenuArray[3]"
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="8"
        viewBox="0 0 12 8"
        fill="none"
      >
        <path
          d="M1 6.25L6 1.75L11 6.25"
          stroke="#000F29"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <!-- for message submenu -->
    <div *ngIf="activeMenuArray[3]" class="childWrapper">
      <div *ngFor="let item of msgItems">
        <div
          [ngClass]="{'active-menu': activeTab?.header === item.name, 'menuChild': activeTab?.header !== item.name}"
        >
          <div class="textlink">
            <div
              class="menuChildText"
              (click)=" onClickMenuItem(item.label, item.count)"
            >
              {{item.name}}
            </div>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="hoverIcon"
            >
              <g id="Vector">
                <path
                  d="M3.27525 13H9.6003C10.8653 13 11.8843 11.981 11.8843 10.716V6.85065C11.8843 6.55197 11.6559 6.32357 11.3573 6.32357C11.0586 6.32357 10.8302 6.55197 10.8302 6.85065V10.716C10.8302 11.4012 10.2855 11.9458 9.6003 11.9458H3.27525C2.59004 11.9458 2.04538 11.4012 2.04538 10.716V4.39092C2.04538 3.7057 2.59004 3.16105 3.27525 3.16105H7.14056C7.43924 3.16105 7.66764 2.93264 7.66764 2.63396C7.66764 2.33528 7.43924 2.10687 7.14056 2.10687H3.27525C2.01025 2.10687 0.991211 3.12591 0.991211 4.39092V10.716C0.991211 11.981 2.01025 13 3.27525 13Z"
                  fill="#7E819B"
                />
                <path
                  d="M13.0089 4.75989V1.54466C13.0089 1.50952 13.0089 1.47438 12.9913 1.43924C12.9913 1.42167 12.9913 1.4041 12.9738 1.4041C12.9738 1.38653 12.9562 1.36896 12.9562 1.35139C12.9562 1.33382 12.9386 1.31625 12.921 1.29868C12.921 1.28111 12.9035 1.26354 12.9035 1.26354C12.8683 1.21083 12.8156 1.15813 12.7629 1.12299L12.7278 1.10542C12.6926 1.05271 12.6575 1.03514 12.6224 1.03514L12.5697 1.01757C12.5345 1.01757 12.4994 1 12.4642 1H9.24901C8.95033 1 8.72192 1.2284 8.72192 1.52709C8.72192 1.82577 8.95033 2.05417 9.24901 2.05417H11.1992L6.3676 6.90337C6.15677 7.11421 6.15677 7.44803 6.3676 7.64129C6.57844 7.85213 6.91226 7.85213 7.10552 7.64129L11.9547 2.80966V4.75989C11.9547 5.05857 12.1831 5.28697 12.4818 5.28697C12.7629 5.28697 12.9913 5.041 13.0089 4.75989Z"
                  fill="#7E819B"
                />
                <path
                  d="M3.27525 13H9.6003C10.8653 13 11.8843 11.981 11.8843 10.716V6.85065C11.8843 6.55197 11.6559 6.32357 11.3573 6.32357C11.0586 6.32357 10.8302 6.55197 10.8302 6.85065V10.716C10.8302 11.4012 10.2855 11.9458 9.6003 11.9458H3.27525C2.59004 11.9458 2.04538 11.4012 2.04538 10.716V4.39092C2.04538 3.7057 2.59004 3.16105 3.27525 3.16105H7.14056C7.43924 3.16105 7.66764 2.93264 7.66764 2.63396C7.66764 2.33528 7.43924 2.10687 7.14056 2.10687H3.27525C2.01025 2.10687 0.991211 3.12591 0.991211 4.39092V10.716C0.991211 11.981 2.01025 13 3.27525 13Z"
                  stroke="white"
                  stroke-width="0.25"
                />
                <path
                  d="M13.0089 4.75989V1.54466C13.0089 1.50952 13.0089 1.47438 12.9913 1.43924C12.9913 1.42167 12.9913 1.4041 12.9738 1.4041C12.9738 1.38653 12.9562 1.36896 12.9562 1.35139C12.9562 1.33382 12.9386 1.31625 12.921 1.29868C12.921 1.28111 12.9035 1.26354 12.9035 1.26354C12.8683 1.21083 12.8156 1.15813 12.7629 1.12299L12.7278 1.10542C12.6926 1.05271 12.6575 1.03514 12.6224 1.03514L12.5697 1.01757C12.5345 1.01757 12.4994 1 12.4642 1H9.24901C8.95033 1 8.72192 1.2284 8.72192 1.52709C8.72192 1.82577 8.95033 2.05417 9.24901 2.05417H11.1992L6.3676 6.90337C6.15677 7.11421 6.15677 7.44803 6.3676 7.64129C6.57844 7.85213 6.91226 7.85213 7.10552 7.64129L11.9547 2.80966V4.75989C11.9547 5.05857 12.1831 5.28697 12.4818 5.28697C12.7629 5.28697 12.9913 5.041 13.0089 4.75989Z"
                  stroke="white"
                  stroke-width="0.25"
                />
              </g>
            </svg>
          </div>
          <div *ngIf="item.count>0" class="menuChildCount">{{item.count}}</div>

          <div *ngIf="item.childList" (click)="onTagsClick(0)">
            <svg
              *ngIf="!activeTagsMenu[0]"
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
            >
              <path
                d="M1.75 1L6.25 6L1.75 11"
                stroke="#000F29"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <svg
              *ngIf="activeTagsMenu[0]"
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
            >
              <path
                d="M1 6.25L6 1.75L11 6.25"
                stroke="#000F29"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <!-- for pottential escalations submenu -->
        <div *ngIf="item.childList" [hidden]="!activeTagsMenu[0]">
          <div
            *ngFor="let listItem of item.childList"
            class="menuChild"
            [ngClass]="{'active-menu': activeTab?.header === 'Esc: '+listItem.name, 'menuChild': activeTab?.header !== 'Esc: '+listItem.name}"
          >
            <div class="thirdChildWrapper">
              <div
                class="menuChildText"
                (click)="onClickMenuItemSubMenu(listItem.label, listItem.count, listItem.name)"
              >
                {{listItem.name}}
              </div>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="hoverIcon"
              >
                <g id="Vector">
                  <path
                    d="M3.27525 13H9.6003C10.8653 13 11.8843 11.981 11.8843 10.716V6.85065C11.8843 6.55197 11.6559 6.32357 11.3573 6.32357C11.0586 6.32357 10.8302 6.55197 10.8302 6.85065V10.716C10.8302 11.4012 10.2855 11.9458 9.6003 11.9458H3.27525C2.59004 11.9458 2.04538 11.4012 2.04538 10.716V4.39092C2.04538 3.7057 2.59004 3.16105 3.27525 3.16105H7.14056C7.43924 3.16105 7.66764 2.93264 7.66764 2.63396C7.66764 2.33528 7.43924 2.10687 7.14056 2.10687H3.27525C2.01025 2.10687 0.991211 3.12591 0.991211 4.39092V10.716C0.991211 11.981 2.01025 13 3.27525 13Z"
                    fill="#7E819B"
                  />
                  <path
                    d="M13.0089 4.75989V1.54466C13.0089 1.50952 13.0089 1.47438 12.9913 1.43924C12.9913 1.42167 12.9913 1.4041 12.9738 1.4041C12.9738 1.38653 12.9562 1.36896 12.9562 1.35139C12.9562 1.33382 12.9386 1.31625 12.921 1.29868C12.921 1.28111 12.9035 1.26354 12.9035 1.26354C12.8683 1.21083 12.8156 1.15813 12.7629 1.12299L12.7278 1.10542C12.6926 1.05271 12.6575 1.03514 12.6224 1.03514L12.5697 1.01757C12.5345 1.01757 12.4994 1 12.4642 1H9.24901C8.95033 1 8.72192 1.2284 8.72192 1.52709C8.72192 1.82577 8.95033 2.05417 9.24901 2.05417H11.1992L6.3676 6.90337C6.15677 7.11421 6.15677 7.44803 6.3676 7.64129C6.57844 7.85213 6.91226 7.85213 7.10552 7.64129L11.9547 2.80966V4.75989C11.9547 5.05857 12.1831 5.28697 12.4818 5.28697C12.7629 5.28697 12.9913 5.041 13.0089 4.75989Z"
                    fill="#7E819B"
                  />
                  <path
                    d="M3.27525 13H9.6003C10.8653 13 11.8843 11.981 11.8843 10.716V6.85065C11.8843 6.55197 11.6559 6.32357 11.3573 6.32357C11.0586 6.32357 10.8302 6.55197 10.8302 6.85065V10.716C10.8302 11.4012 10.2855 11.9458 9.6003 11.9458H3.27525C2.59004 11.9458 2.04538 11.4012 2.04538 10.716V4.39092C2.04538 3.7057 2.59004 3.16105 3.27525 3.16105H7.14056C7.43924 3.16105 7.66764 2.93264 7.66764 2.63396C7.66764 2.33528 7.43924 2.10687 7.14056 2.10687H3.27525C2.01025 2.10687 0.991211 3.12591 0.991211 4.39092V10.716C0.991211 11.981 2.01025 13 3.27525 13Z"
                    stroke="white"
                    stroke-width="0.25"
                  />
                  <path
                    d="M13.0089 4.75989V1.54466C13.0089 1.50952 13.0089 1.47438 12.9913 1.43924C12.9913 1.42167 12.9913 1.4041 12.9738 1.4041C12.9738 1.38653 12.9562 1.36896 12.9562 1.35139C12.9562 1.33382 12.9386 1.31625 12.921 1.29868C12.921 1.28111 12.9035 1.26354 12.9035 1.26354C12.8683 1.21083 12.8156 1.15813 12.7629 1.12299L12.7278 1.10542C12.6926 1.05271 12.6575 1.03514 12.6224 1.03514L12.5697 1.01757C12.5345 1.01757 12.4994 1 12.4642 1H9.24901C8.95033 1 8.72192 1.2284 8.72192 1.52709C8.72192 1.82577 8.95033 2.05417 9.24901 2.05417H11.1992L6.3676 6.90337C6.15677 7.11421 6.15677 7.44803 6.3676 7.64129C6.57844 7.85213 6.91226 7.85213 7.10552 7.64129L11.9547 2.80966V4.75989C11.9547 5.05857 12.1831 5.28697 12.4818 5.28697C12.7629 5.28697 12.9913 5.041 13.0089 4.75989Z"
                    stroke="white"
                    stroke-width="0.25"
                  />
                </g>
              </svg>
            </div>
            <div *ngIf="listItem.count > 0" class="menuChildCount">
              {{listItem.count}}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="divider"></div>

    <!-- Search for views and group tags -->
    <div class="search-container">
      <input
        type="text"
        placeholder="Search Views And Tags"
        class="search-input"
        #myInput
        (input)="filterItem(myInput.value)"
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <path
          d="M8.14295 8.14297L11 11M5.09089 1C4.28179 1 3.49086 1.23993 2.81811 1.68944C2.14537 2.13895 1.62103 2.77786 1.3114 3.52537C1.00177 4.27289 0.920759 5.09543 1.07861 5.88898C1.23645 6.68254 1.62607 7.41146 2.1982 7.98358C2.77032 8.5557 3.49924 8.94532 4.2928 9.10317C5.08635 9.26102 5.90889 9.18001 6.65641 8.87038C7.40392 8.56075 8.04283 8.03641 8.49234 7.36367C8.94185 6.69092 9.18178 5.89999 9.18178 5.09089C9.18171 4.00594 8.75068 2.96545 7.98351 2.19827C7.21633 1.43109 6.17584 1.00007 5.09089 1Z"
          stroke="#7E819B"
          stroke-width="1.4"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
      </svg>
    </div>
    
    <!-- for views -->
    <app-search-list
      [name]="'myViews'"
      [placeholder]="viewSearchPlaceholder"
      [items]="filteredItems"
      [listStyle]="{'height':'180px','text-decoration':'underline'}"
      (onItemSelect)="onMyViewsItem($event)"
      (onMenuClick)="onMenuClick(4, true)"
      (openMyViewsPopUp)="openMyViewsPopUp(undefined)"
      [activeTab]="activeTab"
    >
    </app-search-list>

    <div class="divider"></div>

    <!-- for group tags -->
    <app-collapsible-list
      [placeholder]="tagsSearchPlaceholder"
      [items]="filteredItemsGroupTags"
      (onItemSelect)="OnGroupTagSelect($event)"
      (onTagsClick)="onTagsClick(1)"
      [active]="activeTagsMenu[1]"
      [activeTab]="activeTab"
    >
    </app-collapsible-list>
  </div>
</div>

<!-- different modal popups -->
<p-dialog
  [(visible)]="myViewBoolean"
  modal="true"
  class="myView"
  [styleClass]="'viewModal'"
  [style]="{'top':'120px !important','border':'1px solid #002d72','background':'#fff'}"
  appendTo="body"
  showHeader="true"
  [resizable]="false"
  closable="false"
>
  <p-header>
    <div
      class="d-flex flex-row align-items-center justify-content-between"
      style="margin-left:27px"
    >
      <div class="header" style="width: 100%;">
        <div class="d-flex flex-row align-items-center justify-content-between">
          <div>
            <h6
              _ngcontent-qyn-c159=""
              class="gfid-title ng-tns-c159-5"
              style="font-size: 20px;"
            >
              MY VIEW CRITERIA
            </h6>
          </div>
          <div>
            <button
              type="button"
              (click)="hideMyViewModal()"
              class="d-flex flex-row close "
              style="border:none !important;opacity:1;color:#002d72;"
            >
              <img
                src="assets/Inbox/close_icon_u73.svg"
                style="opacity:1"
                alt="Close"
              />
              <h6>CLOSE</h6>
            </button>
          </div>
        </div>
        <div>
          <div
            style="font-weight: normal;font-size: 12px;font-family: 'InterstateLight';"
          >
            <img
              _ngcontent-bnh-c182=""
              src="assets/Inbox/info-icon.svg"
              alt=""
              class="icon-setting-out ng-tns-c182-10"
            />
            Last {{allowedMonthsInMyViews}} months data accessible from My view.
            Use QMA search function for old records.
          </div>
        </div>
      </div>
    </div>
  </p-header>
  <app-myviews [myViewsItems]="myViewsItems"></app-myviews>
</p-dialog>
<!-- Responsive Design-->
<p-dialog
  [(visible)]="myReportBoolean"
  [styleClass]="'reportModal'"
  modal="true"
  class="myView"
  [style]="{'top':'120px !important','border':'1px solid #002d72','background':'#fff'}"
  appendTo="body"
  showHeader="false"
  closeIcon=""
  [resizable]="false"
>
  <p-header>
    <div
      class="d-flex flex-row align-items-center justify-content-between"
      style="margin-left:27px"
    >
      <h6 class="gfid-title" style="font-size: 20px">REQUEST MIS REPORT</h6>

      <button
        type="button"
        (click)="hideReportModal($event)"
        class="d-flex flex-row close qma-text"
        style="border:none !important;opacity:1;outline: none;"
      >
        <img
          src="assets/Inbox/close_icon_u73.svg"
          style="opacity:1"
          alt="Close"
        />
        <h6>CLOSE</h6>
      </button>
    </div>
  </p-header>
  <app-reports
    (modalClosed)="hideReportModal($event)"
    [modalOpen]="myReportBoolean"
  ></app-reports>
</p-dialog>
<app-action-popup></app-action-popup>

<!-- symphony changes -->
<p-dialog
  [(visible)]="isServiceSymponyError"
  [styleClass]="'symphony-service-error'"
  header=" Symphony services unavailable please try again later"
  [style]="{'font-size': '12px !important','font-family':'InterstateLight !important','color': '#002d72 !important','text-transform': 'none !important'}"
>
</p-dialog>
