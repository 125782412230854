<div class="audit-container" *ngIf="auditCount > 0">
  <div class="export-container">
    <div class="export-text">Export as</div>
    <img
      [src]="'assets/Inbox/fileTypeXLS.svg'"
      alt="XSL"
      (click)="downloadAutitTrails()"
      pTooltip="Export as XSL"
      tooltipPosition="top"
      tooltipStyleClass="rightTooltip p-tooltip-text"
      class="exportIcon"
    />
    <div class="icon-divider"></div>
    <img
      [src]="'assets/Inbox/fileTypePDF.svg'"
      alt="PDF"
      (click)="downloadAutitTrailsAsPDF()"
      pTooltip="Export as PDF"
      tooltipPosition="top"
      tooltipStyleClass="rightTooltip p-tooltip-text"
      class="exportIcon"
    />
  </div>
  <div
    class="pinContainer"
    pTooltip="Pin Side Bar"
    tooltipPosition="top"
    tooltipStyleClass="rightTooltip p-tooltip-text"
  >
    <img class="default-icon" [src]="'assets/Inbox/pin.svg'" alt="PIN" />
    <img class="hover-icon" [src]="'assets/Inbox/pinHighlight.svg'" alt="PIN"  (click)="onPinPanel()"  />
  </div>
</div>
<div></div>
<div class="audit-wrapper" *ngIf="auditCount > 0">
  <div class="audit-progress">
    <ul *ngIf="auditList.length>0">
      <li *ngFor="let audit of auditList">
        <em class="fa fa-circle"></em>
        <div class="audit-text">
          <span
            class="dateStyle"
            >{{audit.modDate | date:'dd MMM hh:mm a'}}</span
          >
          <span
            title="{{audit.actionDetails}}"
            [ngClass]="{
                        'external': audit.isExternal,
                        'internal': audit.isInternal,
                        'other-details': !audit.isExternal && !audit.isInternal
                    }"
          >
            {{(audit.actionDetails.length>30) ? (audit.actionDetails | slice:0:30)+'...':(audit.actionDetails)}}
          </span>
          <div title="{{audit.actionBy}}" class="replyByStyle">
            {{(audit.actionBy.length>40) ? (audit.actionBy | slice:0:40)+'...':(audit.actionBy)}}
          </div>
          <span *ngIf="audit.isClientChaseEscalation">
            {{audit.isClientChaseEscalation}}
          </span>
          <span *ngIf="audit.isConvCountEscalation">
            {{audit.isConvCountEscalation}}
          </span>
          <span *ngIf="audit.isSubjectEscalation">
            {{audit.isSubjectEscalation}}
          </span>
          <span *ngIf="audit.ispendingApprovalEscalation">
            {{audit.ispendingApprovalEscalation}}
          </span>
        </div>
      </li>
      <li>
        <em class="fa fa-circle"></em>
      </li>
    </ul>
  </div>
</div>
