import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Injectable,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { ActionPopupComponent } from 'src/app/common/component/action-popup/action-popup.component';
import { PiwikProUtils } from 'src/app/common/utility/piwikProUtils';
import { CoreComponent } from 'src/app/core/core.component';
import { MyviewsComponent } from 'src/app/core/myviews/myviews.component';
import { SearchListComponent } from 'src/app/core/search-list/search-list.component';
import { TextValue } from 'src/app/model/Common/TextValue';
import { TaskizeAuthService } from 'src/app/services/taskize-auth.service';
import { SymphonyChatServiceService } from 'src/app/symphony/service/symphony-chat-service.service';
import { SymphonyCreateChatComponent } from 'src/app/symphony/symphony-create-chat/symphony-create-chat.component';
import { environment } from 'src/environments/environment';
import { QmaConstant } from '../../constant/qma-constant';
import { ListCount, NameCount } from '../../model/Common/NameCount';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import { InboxService } from '../../services/inbox.service';
import { MyViewsService } from '../../services/myViews/my-views.service';
import { TabDataService } from '../../services/tab-data.service';
import { UserDataService } from '../../services/user-data.service';
import { SideNavigationService } from './sidenav.service';
declare var window: any;
@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.scss',
})
export class SidenavComponent implements OnInit, AfterViewInit {
  ngAfterViewInit(): void {
    // performance changes
  }
  myViewBoolean: boolean = false;
  myReportBoolean: boolean = false;
  shouldShow: boolean = false;
  viewRequest: Subscription;
  closeView: Subscription;
  myViewUpdate: Subscription;
  unreadSubscription: Subscription;
  tabDataSubscription: Subscription;
  activeTab: string;
  deleteViewName: string;
  myViewRequestObject: any;
  MAX_CHAR: number = 3;
  userName: string;
  email: string;
  profilePic: any;
  userId: string = 'TEST';
  isFirstLoad: boolean = true;
  @ViewChild('leftNavigation', { static: true }) leftNavigation: ElementRef;
  @ViewChild(MyviewsComponent, { static: true }) myviewsComponent: any;
  @ViewChild(ActionPopupComponent, { static: true }) actionPopupComponent: any;
  @ViewChildren(SearchListComponent) searchListComponents: any;
  @ViewChild('sympcrtChatComp') sympcrtChatComp: SymphonyCreateChatComponent;

  @Output() getSelectedMenu = new EventEmitter();
  @Output() closeDrawer = new EventEmitter<void>();
  close() {
    this.closeDrawer.emit(); // Emit the event to notify parent to close the drawer
    this.activeMenuArray=[
      false,
      true,
      false,
      true,
      false,
      false,
      false,
      false,
      false,
    ]
  }
  public fgsColor: string;
  private isMobile: boolean = false;
  //symphony stream id
  @Output() streamId = new EventEmitter();
  symphonyEnabled: boolean = false;
  taskizeEnabled: boolean = false;
  calendarSubscribed: boolean = false;
  personalMailSubscribed: boolean = false;
  contextmenu = false;
  contextmenuX = 0;
  contextmenuY = 0;
  displayChatRoomPopUp: boolean = false;
  dashboardCountSubs$: Subscription;
  showOldSymphony: boolean = false;
  showSpinner = false;
  isServiceSymponyError = false;
  // Issue- Create Symphony chat room does not work from New message- New chat room menu
  isCreateSympChatPopupOpen = false;
  allowedMonthsInMyViews = 0;
  constructor(
    private tabDataService: TabDataService,
    private userDataService: UserDataService,
    private inboxService: InboxService,
    private dashboardService: DashboardService,
    private myViewService: MyViewsService,
    private renderer: Renderer2,
    private coreComponent: CoreComponent,
    private sideNavigationService: SideNavigationService,
    private sanitizer: DomSanitizer,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private symphonyChatServiceService: SymphonyChatServiceService,
    private ref: ChangeDetectorRef,
    private taskizeService: TaskizeAuthService
  ) {
    this.getDataFromLocalUserInfo();
    this.isMobile = window.innerWidth <= QmaConstant.MOBILE_WIDTH;
    this.renderer.listen('window', 'click', (e: Event) => {
      if (
        this.leftNavigation &&
        !this.leftNavigation.nativeElement.contains(event.target)
      ) {
        this.hideSubMenu = false;
        if ((event.target as Element).id != 'mobileMenuClick') {
          this.sideNavigationService.close();
        }
        // performance change
        // if my views, my reports and symphony already open dont detach
        if (
          this.myViewBoolean ||
          this.myReportBoolean ||
          this.isCreateSympChatPopupOpen
        ) {
          this.reattch();
        } else {
          setTimeout(() => {
            this.detach();
          }, 0);
        }
      }
    });

    // Populate Group tag field
    this.getGroupTags();

    /*Mobile change */
    //TO fix the count issue on mobile[load directly as dashboard does not have count]
    if (this.isMobile) {
      this.openDefaultMobile();
    } else {
      this.getFolderCount();
    }
    this.isSymphonyEnabled();
    this.isTaskizeEnabled();

    this.calendarSubscribed =
      this.userDataService.loggedInUserInfo.calendarSubscribed;
    this.calendarSubscribed = false;
    this.personalMailSubscribed =
      this.userDataService.loggedInUserInfo.personalEmailSubscribed;
    this.personalMailSubscribed = false; // Do not show Personal Tab anywhere in QMA2, to be removed in future

    this.dashboardService.getOpenInquiryGroupSubject.subscribe((data) => {
      if (data) {
        this.getGroupTags();
      }
    });
    this.allowedMonthsInMyViews =
      this.userDataService &&
      this.userDataService.loggedInUserInfo &&
      this.userDataService.loggedInUserInfo.defaultViewPerformanceConfig
        ? this.userDataService.loggedInUserInfo.defaultViewPerformanceConfig.viewsConfig.find(
            (vc) => vc.viewName === 'CustomViews'
          ).months
        : 3;
  }
  filteredItems: Array<object>;
  filteredItemsGroupTags: Array<object>;

  assignCopy() {
    this.filteredItems = Object.assign([], this.myViewsItems);
  }
  assignCopyGroupTags() {
    this.filteredItemsGroupTags = Object.assign([], this.groupTagItems);
  }

  filterItem(value: string) {
    if (!value) {
      this.assignCopy();
    }
    this.filteredItems = Object.assign([], this.myViewsItems).filter(
      (item) => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
    );
    this.filterItemGroupTags(value);
  }
  filterItemGroupTags(value: string) {
    if (!value) {
      this.assignCopyGroupTags();
    }
    this.filteredItemsGroupTags = Object.assign([], this.groupTagItems).filter(
      (item) => {
        if (this.isInChild(item, value)) {
          return true;
        }
      }
    );
  }
  isInChild(_item: any, _val: any) {
    let found = false;
    for (var i = 0; i < _item.childList.length; i++) {
      if (
        _item.childList[i].name.toLowerCase().indexOf(_val.toLowerCase()) > -1
      ) {
        found = true;
        break;
      }
    }
    return found;
  }

  /**
   * Method to get the group tags.
   */
  getGroupTags() {
    // Update the group tags based on selected assigned groups from dashboard setting
    let selGroups = JSON.parse(localStorage.getItem('groupNames')) || [];
    this.groupTagItems = [];
    this.userDataService.assignedGroups.forEach((assignedGroup) => {
      assignedGroup.toggle = true;
      if (
        selGroups &&
        selGroups.length > 0 &&
        selGroups.includes(assignedGroup.name)
      ) {
        this.groupTagItems.push(assignedGroup);
      } else if (selGroups.length == 0) {
        this.groupTagItems.push(assignedGroup);
      }
    });
  }

  // populate leftNav item's name & count - replace this after backend service populates count
  // for all left nav items
  getFolderCount() {
    this.dashboardCountSubs$ = this.dashboardService
      .getDashboardCountsServiceSubject()
      .subscribe((dashSnapshotCnts) => {
        let boxCounts = [];
        if (dashSnapshotCnts) {
          if (dashSnapshotCnts.boxCounts) {
            dashSnapshotCnts.boxCounts.forEach((val: any) => {
              let t = this.msgItems.find(
                (item) =>
                  item.label.toUpperCase() == Object.keys(val)[0].toUpperCase()
              ); //-Assign to Me count issue fix
              //:Default view, Push items if total count is more than 0
              if (
                val[Object.keys(val)[0]].count.$numberLong > 0 ||
                val[Object.keys(val)[0]].count > 0
              ) {
                boxCounts.push(Object.keys(val)[0]);
              }
              if (t) {
                t.count =
                  val[Object.keys(val)[0]].unReadCount.$numberLong ||
                  val[Object.keys(val)[0]].unReadCount; //-Assign to Me count issue fix
              }
            });
            if (this.isMobile && this.isFirstLoad) {
              this.onMenuClick(3, true);
              this.getDefaultViewMobile(boxCounts); // Default Landing Page
              this.sideNavigationService.close();
              this.isFirstLoad = false;
            }
          }
        }
      });
  }
  // Blue Circle on tab is not showing correct number
  getUpdatedFolderUnreadCount() {
    // Blue Circle on tab is not showing correct number
    this.dashboardService.getUpdatedUnreadCount().subscribe((data) => {
      if (this.isObjetEmpty(data)) {
        return;
      }
      let t = this.msgItems.find(
        (item) => item.label.toUpperCase() == data.tabName
      );
      if (t) {
        t.count = data.updatedUnread;
      }
    });
  }
  //  Blue Circle on tab is not showing correct number
  isObjetEmpty(obj) {
    return Object.keys(obj).length === 0 ? true : false;
  }
  /* TO get the default view for mobile based on total Count */
  getDefaultViewMobile(obj: any) {
    let default_sequence = QmaConstant.MOBILE_DEFAULT_SEQUENCE;
    let getFolderName;

    for (let item of default_sequence) {
      if (obj.indexOf(item) != -1) {
        getFolderName = item;
        break;
      }
    }

    let getItemFromArray = this.msgItems.find(
      (val) => val.label.toUpperCase() == getFolderName.toUpperCase()
    );
    this.onClickMenuItem(getItemFromArray.label, getItemFromArray.count);
  }

  openDefaultMobile() {
    this.dashboardService.getDashboardCountsService();
    this.tabDataService.onTabComponentReady().subscribe((res) => {
      this.getFolderCount();
    });
  }

  openMyViewsPopUp(viewName) {
    // performance change
    this.reattch();
    // make it tru when resume the task for myviews
    this.hideSubMenu = false;
    if (!viewName) {
      this.myviewsComponent.clearSearch(true);
    } else {
      this.myviewsComponent.initMyView(viewName);
    }
    this.myViewBoolean = true;
    PiwikProUtils.trackJsonDataForEvent(
      this.userDataService.loggedInUserInfo.pivotProConfigEnabled,
      this.userDataService.loggedInUserInfo.userId,
      'Menu',
      'My views - Create new',
      'Click',
      'My views - Create new',
      0
    );
  }

  openMyReportPopUp() {
    this.hideSubMenu = false;
    this.myReportBoolean = true;
  }

  openMISDashboard() {
    this.hideSubMenu = false;
    window.open(environment.MIS_DASHBOARD, '_blank');
    PiwikProUtils.trackJsonDataForTab(
      this.userDataService.loggedInUserInfo.pivotProConfigEnabled,
      this.userDataService.loggedInUserInfo.userId,
      'Tab',
      'Reports - MIS Reports'
    );
    PiwikProUtils.trackJsonDataForEvent(
      this.userDataService.loggedInUserInfo.pivotProConfigEnabled,
      this.userDataService.loggedInUserInfo.userId,
      'Menu',
      'Reports - MIS Reports',
      'Click',
      'Reports - MIS Reports',
      0
    );
  }

  hover: any = 'hover';
  activeMenuArray: Array<boolean> = [
    false,
    true,
    false,
    true,
    false,
    false,
    false,
    false,
    false,
  ];
  activeTagsMenu: Array<boolean> = [false, false, false];
  tagsSearchPlaceholder = 'Search tag...';
  viewSearchPlaceholder = 'Search my views...';
  myViewsItems: NameCount[] = [];
  native: any;
  // TO DO - the counts are hard coded here. These will be overwritten by backend service call later
  myCovItems: Array<object> = [
    {
      name: 'Pending Approvals',
      count: 0,
    },
    {
      name: 'Potential Escalations',
      count: 0,
    },
    {
      name: 'Custom view',
      count: 0,
    },
    {
      name: 'Tag view',
      count: 0,
    },
  ];
  // TO DO - the counts are hard coded here. These will be overwritten by backend service call later
  msgItems: Array<NameCount> = [
    {
      name: 'Inbox',
      label: 'Inbox',
    },
    {
      name: 'Sent',
      label: 'Sent',
    },
    {
      name: 'Pending Approval',
      label: 'Pending Approval',
    },
    {
      name: 'Potential Escalations',
      label: 'Escalation',
      childList: [
        { name: 'Client Chaser', label: 'CLIENTCHASER', count: 0 },
        { name: 'Conversation Count', label: 'CONVERSATIONCOUNT', count: 0 },
        { name: 'Response Time', label: 'RESPONSETIME', count: 0 },
        { name: 'Subject Based', label: 'SUBJECTBASED', count: 0 },
        { name: 'Pending Approval', label: 'PENDINGAPPROVAL', count: 0 },
      ],
    },
    {
      name: 'Resolved',
      label: 'Resolved',
    },
    {
      name: 'Assigned To Me',
      label: 'Assigned To Me', // label aligned to name
    },
    {
      name: 'Unassigned',
      label: 'Unassigned',
    },
    {
      name: 'Snoozed',
      label: 'Snoozed',
    },
    {
      name: 'Drafts',
      label: 'Drafts',
    },
    {
      name: 'Non Inquiries',
      label: 'Non Inquiries',
    },
    // {
    //   name: 'Auto Assign FYI',
    //   label: 'Auto Assign FYI',
    // },
  ];
  // TO DO - the counts are hard coded here. These will be overwritten by backend service call later
  groupTagItems: NameCount[] = [];

  hideSubMenu: boolean = false;
  newMsgIndexes: number[] = [];

  // Flag to check if user have group role.
  hasGroupRole: boolean = true;

  getDataFromLocalUserInfo() {
    this.userDataService.LocalGetLoginUserInfo().subscribe((loginUserInfo) => {
      this.sideNavigationService.setPageSize(loginUserInfo, this.isMobile);
      if (window.innerWidth <= QmaConstant.MOBILE_WIDTH) {
        this.userName = loginUserInfo.userName;
        this.userId = loginUserInfo.userId;
        this.email = loginUserInfo.email;

        let userPic = loginUserInfo.profilePic;
        if (userPic) {
          this.profilePic = this.sanitizer.bypassSecurityTrustResourceUrl(
            'data:image/png;base64,' + userPic
          );
        }
      }
      loginUserInfo.myGroups.forEach((myGroup) => {
        // adv search assigned group user list
        //  accept group with null tags
        if (myGroup) {
          let listItem = myGroup.tags
            ? myGroup.tags.map((item) => new ListCount(item, 0))
            : [];
          let textValue = new NameCount(
            myGroup.groupName,
            myGroup.groupName,
            myGroup.id,
            0,
            listItem
          );
          this.userDataService.assignedGroups.push(textValue);
          this.userDataService.requestObj.groupList.push(textValue);
        }
        // adv search request type
        if (myGroup.groupName && myGroup.requestTypes) {
          myGroup.requestTypes.forEach((requestType) => {
            if (requestType) {
              let textValue = new TextValue(
                requestType,
                requestType,
                requestType
              );
              this.userDataService.requestTypes.push(textValue);
            }
          });
        }
        // adv search tags
        if (myGroup.groupName && myGroup.tags) {
          myGroup.tags.forEach((tag) => {
            let textValue = new TextValue(tag, tag, tag);
            this.userDataService.tags.push(textValue);
          });
        }

        // Assigned group to loggedInUser
        this.userDataService.userGroupIdList.push(myGroup.id);
      });

      // Check if user don't have admin access.
      const groups = loginUserInfo.myGroups;
      if (groups === undefined || groups == null || groups.length == 0) {
        this.hasGroupRole = false;
      } else {
        this.hasGroupRole = true;
      }

      if (loginUserInfo.userViews) {
        this.myViewsItems = [];
        loginUserInfo.userViews.forEach((userView) => {
          const label =
            userView.name.length > 29
              ? userView.name.slice(0, 27) + '...'
              : userView.name;
          this.myViewsItems.push({ name: userView.name, label: label });
        });
        // update myview component's list as well
        if (this.myviewsComponent) {
          this.myviewsComponent.myViewsItems = this.myViewsItems;
        }
        // update myview list at inbox service
        this.inboxService.myViewItems = this.myViewsItems;
      }
    });
  }

  onClickMenuItem(
    name: any,
    count?: number,
    updateCount?: boolean,
    _symphonyData?: any
  ) {
    this.hideSubMenu = false;
    let tempTabData;
    let ob: any;
    let tabDataName: string;
    let myCount: number = count;
    switch (name) {
      case 'NEW MESSAGE': {
        if (this.isMobile) {
          tabDataName = name;
        } else {
          tabDataName = name + '[' + this.getNewMsgIndex() + ']';
        }
        break;
      }
      case QmaConstant.QMA_NEW_BUBBLE: {
        tabDataName = name + '[' + this.getNewMsgIndex() + ']';
        break;
      }
      case 'Chat': {
        tabDataName = name;
        /*  tabDataName = "ChatView"; */
        break;
      }

      default: {
        try {
          ob = this.msgItems.find((val) => val.label == name);
          if (updateCount && ob && !myCount) {
            myCount = ob.count;
          }

          // Call the Dashboard Count service
          this.dashboardService.getDashboardCountsEvent();
        } catch (e) {
          console.log('Click Name Error');
        }
        tabDataName = ob ? ob.name : undefined;
        tempTabData = name;
      }
    }
    // form the request object of grid and save in inbox service
    if (tabDataName) {
      if (this.inboxService.requestObj) {
        // reset the 'from' prop.
        this.inboxService.requestObj.from = undefined;
      }
    }
    if (name !== 'NEW MESSAGE' && name !== 'NEW BUBBLE' && tabDataName) {
      //  use tabDataName for view type look-up (instead of using label)
      this.sideNavigationService.formRequestObjectOfGrid(tabDataName);
    }
    // invoke sendTabData (to open tab) *after* setting the request object
    if (tabDataName) {
      this.tabDataService.sendTabData(
        tabDataName,
        myCount,
        'leftNav',
        _symphonyData
      );
      this.close();
    }

    if (tempTabData == 'Dashboard') {
      this.tabDataService.sendTabData(tempTabData.toUpperCase(), 0);
      this.close();
    }
    if (tempTabData == 'CALENDAR') {
      this.tabDataService.sendTabData(tempTabData.toUpperCase(), 0);
      this.close();
    }
    //For Mobile Screens
    this.sideNavigationService.toggle();
    if (
      !(
        name === 'Dashboard' ||
        name === 'NEW MESSAGE' ||
        name === QmaConstant.QMA_NEW_BUBBLE
      )
    ) {
      PiwikProUtils.trackJsonDataForEvent(
        this.userDataService.loggedInUserInfo.pivotProConfigEnabled,
        this.userDataService.loggedInUserInfo.userId,
        'Menu',
        'Messages - ' + name,
        'Click',
        'Messages - ' + name,
        0
      );
    }
  }

  onMenuClick(
    _clickedItemIndex: number,
    _expand: boolean,
    _option: string = '',
    _count?: number,
    _symphonyData?: any
  ) {
    if (_expand) {
      this.reattch();
    }
    if (_clickedItemIndex === 4) {
      // '4' --> MyViews, call /getUserView in prep for user creating or editing new myview.
      // This call is necessary but called only when user clicks MyViews menu for performance optimization.
      this.myViewService.getUserView();
      PiwikProUtils.trackJsonDataForEvent(
        this.userDataService.loggedInUserInfo.pivotProConfigEnabled,
        this.userDataService.loggedInUserInfo.userId,
        'Menu',
        'My Views',
        'Click',
        'My views',
        0
      );
    }
    let clickedItem = this.activeMenuArray[_clickedItemIndex];
    this.activeMenuArray.fill(false);

    // We need to expand Personal Mail box section all the time
    this.activeMenuArray[_clickedItemIndex] =
      _clickedItemIndex === 8 ? true : _expand ? !clickedItem : true;
    this.hideSubMenu = _expand;

    if (_option) {
      this.onClickMenuItem(_option, _count, undefined, _symphonyData);
    }

    if (_option === 'Dashboard') {
      //this.shouldShow = false;
      this.sideNavigationService.close(); //shouldShow
    }
    if (_option === 'CALENDAR') {
      //this.shouldShow = false;
      this.sideNavigationService.close(); //shouldShow
    }
    if (_option === 'NEW MESSAGE' || _option === 'NEW BUBBLE') {
      let isMobile = true;
      this.tabDataService.setMobile(isMobile);
    } else {
      let isMobile = false;
      this.tabDataService.setMobile(isMobile);
    }
    this.dashboardService.setDashboardInline(false);
    if (_clickedItemIndex === 5) {
      PiwikProUtils.trackJsonDataForTab(
        this.userDataService.loggedInUserInfo.pivotProConfigEnabled,
        this.userDataService.loggedInUserInfo.userId,
        'Tab',
        'Reports'
      );
      PiwikProUtils.trackJsonDataForEvent(
        this.userDataService.loggedInUserInfo.pivotProConfigEnabled,
        this.userDataService.loggedInUserInfo.userId,
        'Menu',
        'Reports',
        'Click',
        'Reports',
        0
      );
    }
    if (_clickedItemIndex === 6) {
      PiwikProUtils.trackJsonDataForTab(
        this.userDataService.loggedInUserInfo.pivotProConfigEnabled,
        this.userDataService.loggedInUserInfo.userId,
        'Tab',
        'Symphony'
      );
      PiwikProUtils.trackJsonDataForEvent(
        this.userDataService.loggedInUserInfo.pivotProConfigEnabled,
        this.userDataService.loggedInUserInfo.userId,
        'Menu',
        'Symphony',
        'Click',
        'Symphony',
        0
      );
    }
    if (_clickedItemIndex === 7) {
      PiwikProUtils.trackJsonDataForTab(
        this.userDataService.loggedInUserInfo.pivotProConfigEnabled,
        this.userDataService.loggedInUserInfo.userId,
        'Tab',
        'Calendar'
      );
      PiwikProUtils.trackJsonDataForEvent(
        this.userDataService.loggedInUserInfo.pivotProConfigEnabled,
        this.userDataService.loggedInUserInfo.userId,
        'Menu',
        'Calendar',
        'Click',
        'Calendar',
        0
      );
    }
    if (_clickedItemIndex === 8) {
      PiwikProUtils.trackJsonDataForTab(
        this.userDataService.loggedInUserInfo.pivotProConfigEnabled,
        this.userDataService.loggedInUserInfo.userId,
        'Tab',
        'Personal Email'
      );
      PiwikProUtils.trackJsonDataForEvent(
        this.userDataService.loggedInUserInfo.pivotProConfigEnabled,
        this.userDataService.loggedInUserInfo.userId,
        'Menu',
        'Personal Email',
        'Click',
        'Personal Email',
        0
      );
    }
  }

  onTagsClick(clickedItemIndex: number) {
    //this.hideSubMenu = false;
    for (var i = 0; i < this.activeTagsMenu.length; i++) {
      let val = this.activeTagsMenu[i];
      this.activeTagsMenu[i] = clickedItemIndex === i ? !val : false;
    }
    PiwikProUtils.trackJsonDataForTab(
      this.userDataService.loggedInUserInfo.pivotProConfigEnabled,
      this.userDataService.loggedInUserInfo.userId,
      'Tab',
      'Group Tag'
    );
    PiwikProUtils.trackJsonDataForEvent(
      this.userDataService.loggedInUserInfo.pivotProConfigEnabled,
      this.userDataService.loggedInUserInfo.userId,
      'Menu',
      'Group Tag',
      'Click',
      'Group Tag',
      0
    );
  }

  OnGroupTagSelect(evt: any) {
    this.hideSubMenu = false;
    let requestObj: any = {};

    requestObj.groupName = evt.parent.name;
    requestObj.tagSearch = 'Y'; // QmaConstant.inboxGridViewType["Tag"].viewType;
    requestObj.viewName = evt.selectedChild.name;

    console.log(requestObj);
    this.inboxService.requestObj = requestObj;
    // set the reset flag as it is opening a new tab
    this.inboxService.resetRequest = true;
    this.tabDataService.sendTabData(
      'Tag:' + evt.selectedChild.name,
      0,
      null,
      'triggeredFromTag'
    );
    this.close();
    this.sideNavigationService.close(); //LeftNav close Issue
    PiwikProUtils.trackJsonDataForTab(
      this.userDataService.loggedInUserInfo.pivotProConfigEnabled,
      this.userDataService.loggedInUserInfo.userId,
      'Tab',
      'DL then tag'
    );
    PiwikProUtils.trackJsonDataForEvent(
      this.userDataService.loggedInUserInfo.pivotProConfigEnabled,
      this.userDataService.loggedInUserInfo.userId,
      'Menu',
      'DL then Tag',
      'Click',
      'DL then Tag',
      0
    );
  }
  //Escalated menu item click handeler
  onClickMenuItemSubMenu(viewName: any, count?: number, label?: string) {
    this.hideSubMenu = false;
    let requestObj: any = {};
    label = 'Esc: ' + label;
    if (
      viewName == 'CLIENTCHASER' ||
      viewName == 'CONVERSATIONCOUNT' ||
      viewName == 'RESPONSETIME' ||
      viewName == 'SUBJECTBASED' ||
      viewName == 'PENDINGAPPROVAL'
    ) {
      requestObj['viewName'] = QmaConstant.inboxGridViewType[viewName].viewName;
      requestObj['viewType'] = QmaConstant.inboxGridViewType[viewName].viewType;
      requestObj['viewFilter'] =
        QmaConstant.inboxGridViewType[viewName].filterType;
    }
    // end - create requestObj to load inbox component
    this.inboxService.requestObj = requestObj;
    //  set the reset flag as it is opening a new tab
    this.inboxService.resetRequest = true;
    this.tabDataService.sendTabData(label, count);
    this.close();
    this.sideNavigationService.close(); //:LeftNav close Issue
    PiwikProUtils.trackJsonDataForTab(
      this.userDataService.loggedInUserInfo.pivotProConfigEnabled,
      this.userDataService.loggedInUserInfo.userId,
      'Tab',
      'Messages - Potential Escalations'
    );
    PiwikProUtils.trackJsonDataForEvent(
      this.userDataService.loggedInUserInfo.pivotProConfigEnabled,
      this.userDataService.loggedInUserInfo.userId,
      'Menu',
      'Messages - Potential Escalations - ' + viewName,
      'Click',
      'Messages - Potential Escalations - ' + viewName,
      0
    );
  }

  ngOnInit() {
    this.assignCopy();
    this.assignCopyGroupTags();
    this.sideNavigationService.getNavStatus().subscribe((data) => {
      this.shouldShow = data;
      if (this.isMobile) {
        this.hideSubMenu = true;
        this.ref.detectChanges();
      }
    });
    this.getSelectedMenu.emit(this.activeMenuArray);
    this.viewRequest = this.inboxService.viewRequest.subscribe((options) => {
      if (!options || options.loading || (!options.type && !options.isMyView)) {
        return;
      }
      console.log('LeftNavComponent.viewRequest: options=', options);
      this.coreComponent.handleViewRequestEvent(options);
      this.handleViewOpenRequest(options);
    });
    this.closeView = this.myViewService.onCloseMyView.subscribe((event) => {
      this.closeMyView(event);
    });
    this.myViewUpdate = this.myViewService.onMyViewUpdate.subscribe((event) => {
      let viewname = event.viewName;
      let type = event.type;
      this.updateMyViewList(viewname, type, 0); // count set to 0, for the time being
    });
    this.myViewService.getDeleteUserViewSubj().subscribe((response) => {
      console.log('deleteUserView, response =', response);
      if (response && Object.keys(response).length) {
        if (response.processingstatus === 'true') {
          this.myViewService.onMyViewUpdate.emit({
            viewName: this.deleteViewName,
            type: 'remove',
          });
          this.showMessage(
            "MyView '" + this.deleteViewName + "' deleted successfully!"
          );

          // Update the On delete of view.
          this.userDataService.setUserView(this.deleteViewName, false);
        } else {
          this.showMessage("Failed to delete '" + this.deleteViewName + "'!");
        }
      }
    });
    this.initMyViewHandler();
    this.inboxService.searchTabEvent.subscribe((event: any) => {
      if (!event || !event.action) {
        return;
      }
      if (
        event.action === 'close' &&
        event.tab &&
        event.tab.startsWith('NEW MESSAGE[') &&
        event.tab.indexOf(']') !== -1
      ) {
        this.updateNewMsgIndex(
          parseInt(
            event.tab.substring('NEW MESSAGE['.length, event.tab.indexOf(']'))
          )
        );
      } else if (
        event.action === 'close' &&
        event.tab &&
        event.tab.startsWith('NEW BUBBLE[') &&
        event.tab.indexOf(']') !== -1
      ) {
        this.updateNewMsgIndex(
          parseInt(
            event.tab.substring('NEW BUBBLE['.length, event.tab.indexOf(']'))
          )
        );
      }
    });
    //  subscribe to tab service to update unread count at tab header
    this.unreadSubscription = this.tabDataService
      .onUnReadCount()
      .subscribe((tuple) => {
        let name = tuple.name;
        let count = tuple.count;
        if (this.msgItems && name && count !== undefined) {
          let index = this.msgItems.findIndex(
            (item) =>
              (item.name && item.name.toUpperCase() === name.toUpperCase()) ||
              (item.label && item.label.toUpperCase() === name.toUpperCase())
          );
          if (index > -1) {
            this.msgItems[index].count = count;
          }
        }
      });
    this.tabDataSubscription = this.tabDataService
      .getTabData()
      .subscribe((data) => {
        this.activeTab = data; // Update the local variable whenever savedTabData changes
      });
    //  Blue Circle on tab is not showing correct number
    this.getUpdatedFolderUnreadCount();
    this.openNewMsgForSymphonyChat();
  }

  handleViewOpenRequest(options) {
    if (options && options.isDashboardInlineView) {
      console.log('LeftNav: ignore inboxView request', options);
      return;
    }
    this.openMessageView(options);
  }

  openMessageView(options) {
    let viewName = options.viewName;
    let previewName = options.viewName;
    let myCount = options.count;
    if (viewName == 'Sent') {
      // set tab data before renaming
      viewName = 'Outbox'; // Business asked to rename Outbox to Sent in UI
    } else if (viewName === 'Assigned To Me') {
      viewName = 'Assigned To Me';
    } else {
      if (viewName === 'Escalation') {
        previewName = viewName = 'Potential Escalations';
      }
    }
    let requestObj: any = {};
    requestObj['viewName'] = viewName;
    if (QmaConstant.inboxGridViewType[viewName]) {
      // this.onMenuClick(3, false); //commenting to fix the issue of message dropdown opening in view select
      if (QmaConstant.inboxGridViewType[viewName].viewType) {
        requestObj['viewType'] =
          QmaConstant.inboxGridViewType[viewName].viewType;
      } else if (QmaConstant.inboxGridViewType[viewName].isChartView) {
        requestObj['isChartView'] =
          QmaConstant.inboxGridViewType[viewName].isChartView;
        if (requestObj['isChartView'] === 'Y') {
          if (options.groupId) {
            requestObj['groupId'] = options.groupId;
          }
          if (options.groupName) {
            requestObj['groupName'] = options.groupName;
          }
          // request with more params: 'requestType', 'assignedOwnerId', 'ageBand' and 'isReqFromQma2Dashboard'
          if (options.isReqFromQma2Dashboard) {
            requestObj['isReqFromQma2Dashboard'] =
              options.isReqFromQma2Dashboard;
          }
          if (options.assignedOwnerId) {
            requestObj['assignedOwnerId'] = options.assignedOwnerId;
          }
          if (options.ageBand) {
            requestObj['ageBand'] = options.ageBand;
          }
          if (options.requestType) {
            requestObj['requestType'] = options.requestType;
          }
          if (options.categoryName) {
            requestObj['categoryName'] = options.categoryName;
            previewName = options.categoryName;
          }
        }
        myCount = undefined;
      }
      if (options.type && options.value) {
        let filter = { type: options.type, value: options.value };
        if (options.subtype && options.subvalue) {
          filter['subtype'] = options.subtype;
          filter['subvalue'] = options.subvalue;
        }
        requestObj['filter'] = filter;
      }
      // end - create requestObj to load inbox component
      this.inboxService.requestObj = requestObj;
      // set the reset flag as it is opening a new tab
      this.inboxService.resetRequest = true;
      this.tabDataService.sendTabData(previewName, myCount, 'leftNav');
      this.close();
    } else if (options.isMyView) {
      this.myViewRequestObject = {
        previewName: previewName,
        myCount: myCount,
        options: options,
        requestObj: requestObj,
      };
      if (this.myviewsComponent) {
        this.myviewsComponent.allowGetUserView = false;
      }
      this.myViewService.getUserView(viewName);
      return; // return here
    } else {
      console.log('Failed to identify viewType for ' + viewName + '!!');
      this.inboxService.stopViewLoading();
    }
  }

  initMyViewHandler() {
    this.myViewService.getUserViewSubj().subscribe(
      (userView) => {
        if (!this.myViewRequestObject || !this.myViewRequestObject.requestObj) {
          return;
        }
        const previewName = this.myViewRequestObject.previewName;
        let myCount = this.myViewRequestObject.myCount;
        const options = this.myViewRequestObject.options;
        const requestObj = this.myViewRequestObject.requestObj;
        let searchInType;
        if (userView) {
          // set customized columns to inboxService first
          this.inboxService.customizedColumns = {
            columns: userView.selectedShowCols,
          };
          // handling and caching viewType info at service layer
          if (userView.criteria) {
            const criteria = JSON.parse(userView.criteria);
            if (
              criteria.$and &&
              criteria.$and.length &&
              criteria.$and[0].viewType
            ) {
              searchInType = criteria.$and[0].viewType;
            }
          }
        }
        // this.onMenuClick(3, false); //commenting to fix the issue of message dropdown opening in view select
        if (options.isChartView) {
          requestObj['isChartView'] = options.isChartView;
          if (options.isCharView) {
            // request with more params: 'groupId', requestType', 'assignedOwnerId', 'ageBand' and 'isReqFromQma2Dashboard'
            if (options.groupId) {
              requestObj['groupId'] = options.groupId;
            }
            if (options.isReqFromQma2Dashboard) {
              requestObj['isReqFromQma2Dashboard'] =
                options.isReqFromQma2Dashboard;
            }
            if (options.assignedOwnerId) {
              requestObj['assignedOwnerId'] = options.assignedOwnerId;
            }
            if (options.ageBand) {
              requestObj['ageBand'] = options.ageBand;
            }
            if (options.requestType) {
              requestObj['requestType'] = options.requestType;
            }
          }
          myCount = options.count;
        } else {
          requestObj['viewType'] = -1;
          // caching searchInType in request
          if (searchInType) {
            requestObj['myViewSearchInType'] = searchInType;
          }
          myCount = options.count;
          PiwikProUtils.trackJsonDataForTab(
            this.userDataService.loggedInUserInfo.pivotProConfigEnabled,
            this.userDataService.loggedInUserInfo.userId,
            'Tab',
            'Custom views'
          );
          PiwikProUtils.trackJsonDataForEvent(
            this.userDataService.loggedInUserInfo.pivotProConfigEnabled,
            this.userDataService.loggedInUserInfo.userId,
            'Menu',
            'Custom views',
            'Click',
            'Custom Views',
            0
          );
        }
        this.inboxService.requestObj = requestObj;
        // reset the inboxView
        this.inboxService.resetRequest = true;
        this.tabDataService.sendTabData(previewName, myCount, 'leftNav');
        this.close();
        this.myViewRequestObject = undefined;
      },
      (error) => {
        console.log('handleMyViewOpenRequest', error);
        this.inboxService.stopViewLoading();
        this.myViewRequestObject = undefined;
      }
    );
    // end - create requestObj to load inbox component
    return true;
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    if (this.viewRequest) {
      this.viewRequest.unsubscribe();
    }
    if (this.closeView) {
      this.closeView.unsubscribe();
    }
    if (this.myViewUpdate) {
      this.myViewUpdate.unsubscribe();
    }
    if (this.unreadSubscription) {
      this.unreadSubscription.unsubscribe();
    }
    if (this.dashboardCountSubs$) {
      this.dashboardCountSubs$.unsubscribe();
    }
  }

  onEnter() {
    this.hideSubMenu = true;
  }
  onLeave() {
    this.hideSubMenu = false;

    //For mobile screens
    // this.shouldShow = false;
    this.sideNavigationService.close();
  }

  onMyViewsItem(event) {
    this.hideSubMenu = true;
    //this.shouldShow = false;
    this.sideNavigationService.close();
    let viewName = event.currentTarget
      ? event.currentTarget.id
      : event.srcElement.id;
    let className = event.currentTarget
      ? event.currentTarget.className
      : event.srcElement.className;
    if (className) {
      if (className.includes('item-open')) {
        this.requestMessageView(viewName, '#021E48', null, null);
      } else if (className.includes('item-edit')) {
        this.openMyViewsPopUp(viewName);
      } else if (className.includes('item-delete')) {
        const me = this;
        this.showConfirmation(
          'Delete MyView',
          "Do you really want to delete '" + viewName + "'?",
          'yes',
          function () {
            me.deleteMyView(viewName);
          }
        );
      }
    }
  }

  requestMessageView(viewName, color, count, isChartView = 'Y') {
    this.inboxService.startViewLoading({ color: color });
    this.inboxService.requestView({
      viewName: viewName,
      count: count,
      isMyView: true,
      isChartView: isChartView,
    });
  }

  closeMyView(event) {
    this.myViewBoolean = false;
  }

  showConfirmation(header, message, callbackName, callback) {
    if (this.actionPopupComponent) {
      this.actionPopupComponent.open(
        header,
        message,
        false,
        false,
        false,
        true,
        true,
        callbackName,
        callback
      );
    } else {
      console.log(message);
    }
  }

  deleteMyView(viewName) {
    console.log('Delete view : ' + viewName);
    this.deleteViewName = viewName;
    this.myViewService.deleteUserView(viewName);
  }

  showMessage(message) {
    if (this.actionPopupComponent) {
      this.actionPopupComponent.open(
        'MyViews',
        message,
        false,
        true,
        false,
        false,
        false
      );
    } else {
      console.log(message);
    }
  }

  updateMyViewList(viewname, type, count) {
    if (
      viewname &&
      type &&
      this.searchListComponents &&
      this.searchListComponents.length
    ) {
      this.searchListComponents.forEach((s: any) => {
        if (s && s.name && s.name === 'myViews') {
          s.updateItemList(viewname, type);
          if (type === 'remove') {
            this.myViewsItems = s.items;
            if (this.myviewsComponent) {
              this.myviewsComponent.myViewsItems = this.myViewsItems;
            }
            this.inboxService.myViewItems = this.myViewsItems;
          }
        }
      });
    }
  }

  displayEllipsis(_count: number) {
    if (_count >= 1000) {
      return (_count / 1000).toFixed(1) + 'k';
    } else {
      return _count;
    }
  }

  hideMyViewModal() {
    let closePermitted = true;
    if (this.myviewsComponent) {
      closePermitted = this.myviewsComponent.clearSearch(false);
    }
    if (closePermitted) {
      this.myViewBoolean = false;
    }
  }

  hideReportModal(evt?: any) {
    this.myReportBoolean = false;
  }

  /**
   * Hnadle keyboard events
   */
  @HostListener('document:keydown.alt.i', ['$event'])
  @HostListener('document:keydown.alt.o', ['$event'])
  @HostListener('document:keydown.alt.x', ['$event'])
  @HostListener('document:keydown.alt.p', ['$event'])
  @HostListener('document:keydown.alt.n', ['$event'])
  onKeyDown(event) {
    event.stopPropagation();
    event.preventDefault();
    if (
      event &&
      event.altKey &&
      !event.ctrlKey &&
      !event.shiftKey &&
      !event.metaKey
    ) {
      if (event.key == 'i') {
        this.onClickMenuItem('Inbox');
      } else if (event.key == 'o') {
        this.onClickMenuItem('Sent');
      } else if (event.key == 'x') {
        this.onClickMenuItem('Resolved');
      } else if (event.key == 'p') {
        this.onClickMenuItem('Pending Approval');
      } else if (event.key == 'n') {
        this.onMenuClick(0, false, 'NEW MESSAGE', 0);
      }
      return false;
    }
    return true;
  }

  /**
   * Return a usable index for New Message from new message indexes
   */
  getNewMsgIndex(): number {
    let index = this.newMsgIndexes.findIndex((e, i) => e === undefined);
    if (index === -1) {
      // no empty element found, push a new one at the end
      const v = this.newMsgIndexes.length + 1;
      this.newMsgIndexes.push(v);
      return v;
    } else {
      // found empty slot, fill and return the value
      const v = index + 1;
      this.newMsgIndexes[index] = v;
      return v;
    }
  }

  /**
   * Update new message index per removed tab's idnex
   */
  updateNewMsgIndex(removedIndex: number) {
    removedIndex--;
    if (removedIndex < 0 || removedIndex >= this.newMsgIndexes.length) {
      return;
    }
    this.newMsgIndexes[removedIndex] = undefined;
  }

  /**
   * Close Mobile Navigation
   */
  closeNavigation() {
    this.sideNavigationService.close();
  }

  openSymphonyChatView(streamId: String) {
    console.log(
      'chat is being opened for streamId  from left nav: ' + streamId
    );
    this.streamId.emit(streamId);
  }
  isSymphonyEnabled() {
    this.isServiceSymponyError = false;
  }

  isTaskizeEnabled() {
    let userInfo = this.userDataService.loggedInUserInfo;
    if (
      userInfo &&
      userInfo.taskizeConfig &&
      userInfo.taskizeConfig.taskizeEnabled
    ) {
      if (
        userInfo.isTaskizeEnabledForUser &&
        userInfo.isTaskizeEnabledForUser &&
        userInfo.isTaskizeEnabledForUser?.toUpperCase() == 'Y'
      ) {
        this.taskizeEnabled = true;
      } else {
        this.taskizeEnabled = false;
      }
    }
  }

  itemSelected(event: any, optionClicked: string) {
    this.hideContextMenu(event);
    if (optionClicked === 'newMsg') {
      this.onMenuClick(0, false, 'NEW MESSAGE', 0);
    } else if (optionClicked === 'crtChat') {
      this.sympcrtChatComp.displayChatRoomPopup();
    }
  }
  showSympCreateChatPopup() {
    // C170665-1090 Issue- Create Symphony chat room does not work from New message- New chat room menu
    PiwikProUtils.trackJsonDataForTab(
      this.userDataService.loggedInUserInfo.pivotProConfigEnabled,
      this.userDataService.loggedInUserInfo.userId,
      'Tab',
      'New Chat room'
    );
    PiwikProUtils.trackJsonDataForEvent(
      this.userDataService.loggedInUserInfo.pivotProConfigEnabled,
      this.userDataService.loggedInUserInfo.userId,
      'Menu',
      'New Chat Room',
      'Click',
      'New Chat Room',
      0
    );
    this.isCreateSympChatPopupOpen = true;
    if (!this.sympcrtChatComp) {
      return;
    }
    this.sympcrtChatComp.displayChatRoomPopup();
  }
  showContextMenu(event, id) {
    this.contextmenuX = document
      .getElementById(id)
      .getBoundingClientRect().left;
    this.contextmenuY = document.getElementById(id).getBoundingClientRect().top;
    this.contextmenu = true;
  }
  hideContextMenu(event) {
    this.contextmenuX = 0;
    this.contextmenuY = 0;
    // this.itemId = event.currentTarget.id;
    this.contextmenu = false;
  }
  private loadSymphonyScript() {
    window['onloadCallback'] = function () {
      console.log('symphony');
    };
    let script = this._renderer2.createElement('script');
    script.type = 'text/javascript';
    // script.src = "XXXXX";
    script.src =
      this.userDataService.loggedInUserInfo.symphonyConfig.symphonyJS;
    script.dataset.onload = 'onloadCallback';
    script.id = 'symphony-ecm-sdk';
    script.async = true;
    script.defer = true;
    this._renderer2.appendChild(this._document.head, script);
  }

  showHideSymphonySpinner(event) {
    this.showSpinner = event;
    console.debug(
      ':SPINNER:: ' + this.showSpinner + ' ::leftnav.showHideSymphonySpinner'
    );
  }

  // Symphony to open new message in new tab when creating inq from chat
  openNewMsgForSymphonyChat() {
    this.symphonyChatServiceService
      .notifysymponyChatToInq()
      .subscribe((symObj) => {
        if (
          symObj &&
          Object.keys(symObj).length === 0 &&
          symObj.constructor === Object
        ) {
          return;
        }
        this.onMenuClick(0, false, 'NEW MESSAGE', 0, symObj);
      });
  }

  detach() {
    this.ref.detach();
  }
  reattch() {
    this.ref.reattach();
  }
  // C170665-1090 Issue- Create Symphony chat room does not work from New message- New chat room menu
  onSympCrtChatPopupClose(event) {
    this.isCreateSympChatPopupOpen = event;
  }
}
