import { Injectable, Renderer2, Inject, RendererFactory2 } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, Subject, BehaviorSubject, forkJoin } from 'rxjs';
import { LoginUserInfo } from '../model/LoginUserInfo/LoginUserInfo';
// import { SelectItem } from 'primeng/api';
import { SelectItem } from '../model/LoginUserInfo/SelectItem';
import { TextValue } from '../model/Common/TextValue';
import { MyGroup } from '../model/LoginUserInfo/MyGroup';
import { QmaConstant } from "src/app/constant/qma-constant";
import { NameCount } from '../model/Common/NameCount';
import { InquiryTemplate } from "src/app/model/user-profile/inquiryTemplate";
import  * as _ from 'underscore';
import { UserSignature } from "src/app/model/LoginUserInfo/UserSignature";
import { Preference } from "src/app/model/LoginUserInfo/Preference";
import { UserView } from "src/app/model/LoginUserInfo/UserView";
import { AllActiveGroup } from "src/app/model/LoginUserInfo/AllActiveGroup";
import { ClientCategory } from "src/app/model/LoginUserInfo/clientCategory";
import { GroupAdminDetails } from "src/app/model/group-admin/groupAdminDetails";
import { MyFavoriteContact } from "src/app/model/LoginUserInfo/MyFavoriteContact";
import { ExchangeFolder } from '../model/LoginUserInfo/ExchangeFolder';
import { SymphonyConfig } from '../model/user-profile/symphonyConfig';
import { HeirarchyData } from '../model/LoginUserInfo/HeirarchyData';
import { OrgHierarchy } from '../model/LoginUserInfo/OrgHierarchy';
import { AllToCcDBUser } from '../model/LoginUserInfo/AllToCcDBUser';
import { Template } from '../model/LoginUserInfo/Template';
import { DOCUMENT } from '@angular/common';
import { PiwikProUtils } from '../common/utility/piwikProUtils';

const httpFormPostOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true'
  })
};

// User Prferences Constants
const CONVERSATION_VIEW_ID: string = "conversationViewId";
const DEFAULT_FROM_ID: string = "defaultFromId";
const GROUP_LEVEL_VIEW_ID: string = "groupLevelViewId";
const DATE_FORMATTER_SELECT_ID: string = "dateFormatterSelectId";
const DISCLAIMER_NEW_INQUIRY_ID: string = "disclaimerNewInquiryId";
const DISCLAIMER_REPLY_FWD_ID: string = "disclaimerReplyFwdId";
const SIGNATURE_NEW_INQUIRY_ID: string = "signatureNewInquiryId";
const SIGNATURE_REPLY_FWD_ID: string = "signatureReplyFwdId";
const EDITOR_FONT_NAME_ID: string = "editorFontNameId";
const EDITOR_FONT_SIZE_ID: string = "editorFontSizeId";
const VIEW_ROW_COUNT: string = "viewRowCount";
const QUICK_REPLY_TEMPLATE: string = "quickReplyTemplateId";

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  baseURL: string;
  // begin advance search fields 
  assignedGroups: NameCount[] = [];
  requestTypes: SelectItem[] = [];
  tags: SelectItem[] = [];
  status: SelectItem[] = [];
  public userGroupIdList: number[] = [];
  groupNames: MyGroup[];
  requestObj: { "groupList": NameCount[] } = { "groupList": [] };
  // end advance search fields 

  LocalLoginUserInfo = new BehaviorSubject({});
  isServiceSuccess = new BehaviorSubject(false);
  loggedInUserInfo: LoginUserInfo;
  allActiveGroupList: string[] = [];
  allActiveGroupIds: any = {};
  /*C153176-4649 added for catch the change in splitter*/
  splitted: boolean=false;
  private subject = new BehaviorSubject(this.splitted);
  splittedFlag = this.subject.asObservable();

  // C153176-5746 : Keep favorite contacts.
  favContacts: any = [];

  // C153176-6010 - Dashboard refresh interval
  dashboardRefreshInterval: number = QmaConstant.GETDASHBOARDCOUNTS_INERVAL_IN_MS;
  private renderer: Renderer2;
  private document:any;
  private smartSearchUrl = "";
  constructor(private httpClient: HttpClient,private rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document2) {
    this.baseURL = QmaConstant.BASE_URL + '/users';
    this.smartSearchUrl = QmaConstant.BASE_URL + '/smartSearch';
    this.renderer = rendererFactory.createRenderer(null, null);
    this.document = document2;
  }

  httpGetLoginUserInfo() {
    // C153176-5490 | Remove the copied attachments from the session
    sessionStorage.removeItem("copiedattch");
    try {
      this.httpClient.post<LoginUserInfo>(this.baseURL + '/getLoginUserInfo', {}, httpFormPostOptions).subscribe(loginUserInfo => {
        PiwikProUtils.initalizePiwikPro(loginUserInfo.pivotProConfigEnabled,this.renderer, this.document);
        this.setUserInformation(loginUserInfo);
      });
    } catch (e) {
      console.error('Error while getting response from httpGetLoginUserInfo()', e);
    }
  }
  httpgetSearchKeyWords() {
  return this.httpClient.get(this.baseURL + '/getSearchKeyWords',httpFormPostOptions);
  }
  saveSmartSearchFeedback(req) {
    return this.httpClient.post(this.baseURL + '/saveSmartSearchDetails',req,httpFormPostOptions);
    }

  /**
   * Method to pupulate login user information to the service.
   * 
   * @param loginUserInfo 
   */
  private setUserInformation(loginUserInfo: LoginUserInfo) {
    this.loggedInUserInfo = loginUserInfo;
    // Populate User Preffered Details.
    this.setUserTemplates(loginUserInfo);
    this.setUserPreferences(loginUserInfo);
    this.setSignature(loginUserInfo);
    this.setAllActiveGroupList(loginUserInfo.allActiveGroups);
    // C153176-5746 - Populate the favorite contacts.
    this.favContacts = [];
    this.favContacts = [...loginUserInfo.myFavoriteContacts];

    // C153176-6010 - Dashboard refresh interval 
    if (loginUserInfo && loginUserInfo.dashboardCountRefreshIntervalInMs) {
      this.dashboardRefreshInterval = loginUserInfo.dashboardCountRefreshIntervalInMs;
    }

    // Set the metadata
    this.setMetaDataMap(loginUserInfo.myGroups);

    this.LocalLoginUserInfo.next(loginUserInfo);
    this.isServiceSuccess.next(true);
  }

  // Split Login user info into three parts

  /**
   * Method to get Logged in user DB related data.
   */
  private getLoginUserInfoDBData(): Observable<LoginUserInfo> {
    return this.httpClient.post<LoginUserInfo>(this.baseURL + '/getLoginUserInfoDBData', {}, httpFormPostOptions);
  }

  /**
   * Method to get Logged in user Static data.
   */
  private getLoginUserInfoStaticData(): Observable<LoginUserInfo> {
    return this.httpClient.post<LoginUserInfo>(this.baseURL + '/getLoginUserInfoStaticData', {}, httpFormPostOptions);
  }

  /**
   * Method to get Logged in user configuration related data.
   */
  private getLoginUserInfoConfigData(): Observable<LoginUserInfo> {
    return this.httpClient.post<LoginUserInfo>(this.baseURL + '/getLoginUserInfoConfigData', {}, httpFormPostOptions);
  }
 
  LocalGetLoginUserInfo(): Observable<any> {
    return this.LocalLoginUserInfo.asObservable();
  }

  // Update the login user info when profile is saved successfully
  setLoginUserInfo(loginUserInfo: LoginUserInfo): void {

    // Update the loginuser details on successful profile setting update.
    this.loggedInUserInfo = loginUserInfo;
    
    // Populate User Preffered Details.
    this.setUserTemplates(loginUserInfo);
    this.setUserPreferences(loginUserInfo);
    this.setSignature(loginUserInfo);

    this.LocalLoginUserInfo.next(loginUserInfo);
  }

  getServiceStatus(): Observable<any> {
    return this.isServiceSuccess.asObservable()
  }

  getGroupMemberList(groupData: any): Observable<any> {
    return this.httpClient.post<any>(this.baseURL + '/getGroupMemberList', groupData, httpFormPostOptions);
  }

  /**
   * Method to reset ooo settings.
   */
  resetOutOfOffice(): Observable<any> {
    return this.httpClient.post<any>(this.baseURL + '/resetOutOfOffice', httpFormPostOptions);
  }

  // Profile Setting Utility functions
  userTemplateList: InquiryTemplate[] = [];
  signatures: UserSignature[] = [];
  preferenceMap = new Map();

  /**
   * Method to populate the User templates as per user profile.
   */
  setUserTemplates(loginUserInfo: LoginUserInfo): void {
    try {
      loginUserInfo.myGroups.forEach(group => {
        if (group && group.templates) {
          group.templates.forEach(template => {
            if (template.templateName) {
              let temp = this.userTemplateList.find(tmplt => tmplt.templateName === template.templateName);
              if (temp) {
                Array.prototype.push.apply(temp.shareToGrpList, template.shareToGrpList);
              } else {
                let templateDataObj = {
                  name: template.templateName,
                  value: template.templateName,
                  templateName: template.templateName,
                  templateContent: template.templateContent,
                  templateSubject: template.templateSubject,
                  toList: template.toList,
                  ccList: template.ccList,
                  shareToGrpList: template.shareToGrpList,
                  mergeRecipients:template.mergeRecipients
                };
                this.userTemplateList.push(templateDataObj);
              }
            }
          });
        }
      });

      let templateList = loginUserInfo.userTemplateList || [];

      // Set the userTemplates to new user
      loginUserInfo.userTemplateList = [...templateList];

      templateList.forEach(template => {
        let templateDataObj = {
          name: template.templateName,
          value: template.templateName,
          templateName: template.templateName,
          templateContent: template.templateContent,
          templateSubject: template.templateSubject,
          toList: template.toList,
          ccList: template.ccList,
          shareToGrpList: template.shareToGrpList
        };
        this.userTemplateList.push(templateDataObj);
      });

      //Code to remove duplicate templates coming from shared groups.
      if (this.userTemplateList.length > 1) {
        this.userTemplateList = _.uniq(this.userTemplateList, 'name');
      }
      // Sort the template array by name
      this.userTemplateList = _.sortBy(this.userTemplateList, function (i) { return i.name.toLowerCase(); });
    } catch (e) {
      console.error("Exception in QMA 2 while setUserTemplates() : ", e);
    }
  }

  /**
   * Method to populate logged in user preferences.
   */
  setUserPreferences(loginUserInfo: LoginUserInfo): void {
    try {
      if (loginUserInfo.preferences) {
        loginUserInfo.preferences.map(pref => {
          this.preferenceMap.set(pref.key, pref.value);
        });
      }

      // Get the Preference or set default preferences
      let groupName = this.preferenceMap.get(DEFAULT_FROM_ID) ? this.preferenceMap.get(DEFAULT_FROM_ID) : "";
      let convView = this.preferenceMap.get(CONVERSATION_VIEW_ID) ? this.preferenceMap.get(CONVERSATION_VIEW_ID) : QmaConstant.conversationView[1];
      let grouplevel = this.preferenceMap.get(GROUP_LEVEL_VIEW_ID) ? this.preferenceMap.get(GROUP_LEVEL_VIEW_ID) : 'Y';
      let dateFormat = this.preferenceMap.get(DATE_FORMATTER_SELECT_ID) ? this.preferenceMap.get(DATE_FORMATTER_SELECT_ID) : loginUserInfo.dateFormatterSelectItemList[0];
      let discInq = this.preferenceMap.get(DISCLAIMER_NEW_INQUIRY_ID) ? this.preferenceMap.get(DISCLAIMER_NEW_INQUIRY_ID) : 'No';
      let discRply = this.preferenceMap.get(DISCLAIMER_REPLY_FWD_ID) ? this.preferenceMap.get(DISCLAIMER_REPLY_FWD_ID) : 'No';
      let signInq = this.preferenceMap.get(SIGNATURE_NEW_INQUIRY_ID) ? this.preferenceMap.get(SIGNATURE_NEW_INQUIRY_ID) : '';
      let signRply = this.preferenceMap.get(SIGNATURE_REPLY_FWD_ID) ? this.preferenceMap.get(SIGNATURE_REPLY_FWD_ID) : '';
      let fontName = this.preferenceMap.get(EDITOR_FONT_NAME_ID) ? this.preferenceMap.get(EDITOR_FONT_NAME_ID) : loginUserInfo.defaultAppFontSize;
      let fontSize = this.preferenceMap.get(EDITOR_FONT_SIZE_ID) ? this.preferenceMap.get(EDITOR_FONT_SIZE_ID) : loginUserInfo.defaultAppFontStyle;
      let rowCount = this.preferenceMap.get(VIEW_ROW_COUNT) ? this.preferenceMap.get(VIEW_ROW_COUNT) : '500';
      let quickRply = this.preferenceMap.get(QUICK_REPLY_TEMPLATE) ? this.preferenceMap.get(QUICK_REPLY_TEMPLATE) : '';

      // Update the Prferences
      let preferences: Preference[] = [];
      preferences.push({ key: DEFAULT_FROM_ID, value: groupName });
      preferences.push({ key: CONVERSATION_VIEW_ID, value: convView });
      preferences.push({ key: GROUP_LEVEL_VIEW_ID, value: grouplevel });
      preferences.push({ key: DATE_FORMATTER_SELECT_ID, value: dateFormat });
      preferences.push({ key: DISCLAIMER_NEW_INQUIRY_ID, value: discInq });
      preferences.push({ key: DISCLAIMER_REPLY_FWD_ID, value: discRply });
      preferences.push({ key: SIGNATURE_NEW_INQUIRY_ID, value: signInq });
      preferences.push({ key: SIGNATURE_REPLY_FWD_ID, value: signRply });
      preferences.push({ key: EDITOR_FONT_NAME_ID, value: fontName });
      preferences.push({ key: EDITOR_FONT_SIZE_ID, value: fontSize });
      preferences.push({ key: VIEW_ROW_COUNT, value: rowCount });
      preferences.push({ key: QUICK_REPLY_TEMPLATE, value: quickRply });

      loginUserInfo.preferences = [...preferences];
      loginUserInfo.preferences.map(pref => {
        this.preferenceMap.set(pref.key, pref.value);
      });
    } catch (e) {
      console.error("Exception in QMA 2 while setUserPreferences() : ", e);
    }
  }

  /**
   * Method to populate logged in user signature.
   */
  setSignature(loginUserInfo: LoginUserInfo): void {
    try {
      this.signatures = loginUserInfo.userSignatures || [];
      loginUserInfo.userSignatures = [...this.signatures];
    } catch (e) {
      console.error("Exception in QMA 2 while setSignature() : ", e);
    }
  }

  /**
   * Method to get signature for new inquiry.
   */
  getDefaultSignatureforNewInquiry(): UserSignature {
    try {
      return this.signatures.find(sign => sign.name === this.preferenceMap.get(SIGNATURE_NEW_INQUIRY_ID));
    } catch (e) {
      console.error("Exception in QMA 2 while getDefaultSignatureforNewInquiry() : ", e);
    }
  }

  /**
  * Method to get signature for Reply actions.
  */
  getDefaultSignatureforReply(): UserSignature {
    try {
      return this.signatures.find(sign => sign.name === this.preferenceMap.get(SIGNATURE_REPLY_FWD_ID));
    } catch (e) {
      console.error("Exception in QMA 2 while getDefaultSignatureforReply() : ", e);
    }
  }

  /**
   * Method to get User templates.
   */
  getUserTemplates(): InquiryTemplate[] {
    return this.userTemplateList;
  }

  /**
   * Method to get the User Template for Quick Reply.
   */
  getUserTemplateForQuickReply(): InquiryTemplate {
    try {
      return this.userTemplateList.find(template => template.templateName === this.preferenceMap.get(QUICK_REPLY_TEMPLATE));
    } catch (e) {
      console.error("Exception in QMA 2 while getUserTemplateForQuickReply() : ", e);
    }
  }

  /**
   * Method to get default group.
   */
  getDefaultGroup(): MyGroup {
    try {
      return this.loggedInUserInfo.myGroups.find(grp => grp.id === +this.preferenceMap.get(DEFAULT_FROM_ID));
    } catch (e) {
      console.error("Exception in QMA 2 while getDefaultGroup() : ", e);
    }
  }

  /**
   * Method to get the date format.
   */
  getDefaultDateFormate(): string {
    try {
      return this.loggedInUserInfo.dateFormatterSelectItemList.find(frmt => frmt === this.preferenceMap.get(DATE_FORMATTER_SELECT_ID));
    } catch (e) {
      console.error("Exception in QMA 2 while getDefaultDateFormate() : ", e);
    }
  }

  /**
   * Method to get loggedUser other preferences.
   */
  getUserOtherPrefs(): LoginUserInfo {
    return this.loggedInUserInfo;
  }

  /**
   * Method to get Disclaimer for new inquiry.
   */
  getDisclaimerForNewInquiry(groupName?: string): string {
    try {
      let isDisclaimer: boolean = this.preferenceMap.get(DISCLAIMER_NEW_INQUIRY_ID) === 'Yes' ? true : false;
      let group: MyGroup = groupName ? this.loggedInUserInfo.myGroups.find(grp => grp.groupName === groupName) : this.getDefaultGroup();
      return isDisclaimer && group ? (group.disclaimer ? group.disclaimer : '') : '';
    } catch (e) {
      console.error("Exception in QMA 2 while getDisclaimerForNewInquiry() : ", e);
    }
  }

  /**
  * Method to get Disclaimer for Reply actions.
  */
  getDisclaimerForReply(groupName: string): string {
    try {
      let isDisclaimer: boolean = this.preferenceMap.get(DISCLAIMER_REPLY_FWD_ID) === 'Yes' ? true : false;
      let group: MyGroup = this.loggedInUserInfo.myGroups.find(grp => grp.groupName === groupName);
      return isDisclaimer && group ? (group.disclaimer ? group.disclaimer : '') : '';
    } catch (e) {
      console.error("Exception in QMA 2 while getDisclaimerForReply() : ", e);
    }
  }

  /**
   * Method to get flag to 'Exclude My Group While Reply'
   */
  isGroupExcluded(groupName: string) : boolean {
    try {
      let isGroupExcluded: boolean = false;
      let group: MyGroup = this.loggedInUserInfo.myGroups.find(grp => grp.groupName === groupName);
      if (group) {
        isGroupExcluded = group.excludeMyGroupWhileReplyAll;
      }
      return isGroupExcluded;
    } catch (e) {
      console.error("Exception in QMA 2 while isGroupExcluded() : ", e);
    }
  }

  /**
   * Method to set new created or update user view.
   */
  setUserView(viewName: string, isCreate: boolean): void {
    try {
      let userViews: UserView[];
      if (isCreate) {
        let userView: UserView = { name: viewName, columnsToShow: null };
        userViews = this.loggedInUserInfo.userViews ? [...this.loggedInUserInfo.userViews] : [];
        userViews.push(userView);
      }
      else {
        userViews = this.loggedInUserInfo.userViews.filter(uv => uv.name !== viewName);
      }
      this.loggedInUserInfo.userViews = [...userViews];
      this.setLoginUserInfo(this.loggedInUserInfo);
    }
    catch (e) {
      console.error("Exception in QMA 2 while setUserView() : ", e);
    }
  }

  /**
   * Method to get the Editor font size.
   */
  getEditorFontSize(): string[] {
    return this.loggedInUserInfo.editorFontSize;
  }

  /**
   * Method to get the Editor font size.
   */
  getEditorFontStyle(): string[] {
    let fonts: string[] = [];
    fonts = [...this.loggedInUserInfo.editorFontStyle];
    return fonts;
  }

  /**
   * Method to get the Font Name and Size for Editor
   */
  getDefaultFontDetail(): any {
    let fontName = this.preferenceMap.get(EDITOR_FONT_NAME_ID);
    let fontSize = this.preferenceMap.get(EDITOR_FONT_SIZE_ID);

    const fontStyle = {
      fontName: fontName,
      fontSize: fontSize
    }
    return fontStyle;
  }

  /**
   * Method to set the all active group list
   * @param activeGroups 
   */
  setAllActiveGroupList(activeGroups: AllActiveGroup[]): void {
    if (activeGroups && activeGroups.length > 0) {
      this.allActiveGroupList = activeGroups.map(group => {
        if (group.email) {
          return group.email.toLowerCase();
        }
      });
      activeGroups.forEach(group => {
        if (group.text && group.value) {
          this.allActiveGroupIds[group.text]=group.value; 
        }
      });
    }
  }

  /**
   * Method to get the TinyMCE URL configured from DB
   */
  getTinyMceUrl(): string {
    return this.loggedInUserInfo.tinyMCEUrl;
  }

  /**
   * Method to get the system default client category.
   */
  getClientCategory(): ClientCategory[] {
    return this.loggedInUserInfo.clientCategories; // C153176-4566
  }
  /*C153176-4649 added for catch the change in splitter*/
  setSplitterChange(splitted: any): void {   
    this.splittedFlag = this.subject.asObservable();
    this.subject.next(splitted);
  }
  getSplitterChange(): Observable<any> {
    return this.subject.asObservable();
  }

  /**
   * C153176-5001 | Need to refresh the screen post group admin changes
   * 
   * Method to update the group details once successful admin changes submit.
   */
  setGroupDetails(groupDetails: GroupAdminDetails): void {
    try{
      let loggedInUserInfo = { ...this.loggedInUserInfo }; // C153176-5001 : Create copy of object.

      if (loggedInUserInfo.myGroups) {
        loggedInUserInfo.myGroups.forEach(myGroup => {
          if (myGroup.groupName === groupDetails.groupName) {
            // Set the group values
            myGroup.requestTypes = groupDetails.requestTypeList;
            myGroup.rootCauseList = groupDetails.rootCauseList;
            myGroup.isRootCauseMandatory = groupDetails.isRootCauseMandatory;
            myGroup.isProcessingRegionMandatory = groupDetails.isProcessingRegionMandatory;
            myGroup.processingRegionList = groupDetails.processingRegionList;
            myGroup.isInquirySourceMandatory = groupDetails.isInquirySourceMandatory;
            myGroup.isInqReferenceIdReq = groupDetails.isInqReferenceIdReq;
            myGroup.autoReplyEnable = groupDetails.autoReplyEnable;
            myGroup.isRootCauseMandatoryWOReply = groupDetails.isRootCauseMandatoryWOReply;
            myGroup.autoResponseDetails = groupDetails.autoResponseDetails;
            myGroup.isGfidMandatory = groupDetails.isGfidMandatory;
            myGroup.isTagMandatory = groupDetails.isTagMandatory;
            myGroup.convCountThresholdForEscalation = groupDetails.convCountThresholdForEscalation;
            myGroup.responseTimeThresholdForEscalation = groupDetails.responseTimeThresholdForEscalation;
            myGroup.paResponseTimeThresholdForEscalation = groupDetails.paResponseTimeThresholdForEscalation;
            myGroup.excludeMyGroupWhileReplyAll = groupDetails.excludeMyGroupWhileReplyAll;
            myGroup.hideClientList = groupDetails.hideClientList;
            if (groupDetails.heirarchyData) {
              myGroup.heirarchyData.thirdLevelHeirarchy = groupDetails.heirarchyData.thirdLevelHeirarchy;
            }
            myGroup.requestTypeMappings = groupDetails.requestTypeMappings;
            myGroup.subjectEscalationList = groupDetails.subjectEscalationList;
          }
        });

        // Set the metadata when group is saved.
        this.setMetaDataMap(loggedInUserInfo.myGroups);
        
        // Let application knows about the change in group settings.
        this.LocalLoginUserInfo.next(loggedInUserInfo);
      }
    } catch(e){
      console.error("Exception in QMA 2 while setGroupDetails() : ", e);
    }
  }

  /**
   * C153176-4998
   * Method to update favorite Contacts on adding new contact to Local Login User.
   */
  updateFavoriteContacts(contacts : MyFavoriteContact[]): void {
    try{
      let loggedInUserInfo = { ...this.loggedInUserInfo }; 
      loggedInUserInfo.myFavoriteContacts = [];
      contacts = _.uniq(contacts, 'email');
      Array.prototype.push.apply(loggedInUserInfo.myFavoriteContacts, contacts);
      this.favContacts = loggedInUserInfo.myFavoriteContacts; // C153176-5746 : update favorite contacts.
  
      // Let application knows favorite contact change.
      this.LocalLoginUserInfo.next(loggedInUserInfo);
    } catch(e){
      console.error("Exception in QMA 2 while updateFavoriteContacts() : ", e);
    }
  }

  /**
   * C153176-5522 : Method to check if the group is BCC group or not.
   * 
   * @param groupName 
   */
  isBccGroupSelected(groupName: string): boolean {
    let isBccGroup = false;
    let groupIdSelected;
    let bccGroupIdList = this.loggedInUserInfo.bccGroupIdList;

    if (this.loggedInUserInfo.myGroups) {
      this.loggedInUserInfo.myGroups.forEach(myGroup => {
        if (myGroup.groupName === groupName) {
          groupIdSelected = myGroup.id;
        }
      });
    }
    isBccGroup = bccGroupIdList && bccGroupIdList.length > 0 && bccGroupIdList.indexOf(groupIdSelected) > -1;
    return isBccGroup;
  }

  /**
   * Method to get all the assigned group.
   */
  getGroupListForRule() : any[] {
    let assignGroupList = [];
    let allGroups = this.loggedInUserInfo.allActiveGroups;
    allGroups.forEach(group => {
      assignGroupList.push(group.text);
    });
    assignGroupList.unshift("");
    return assignGroupList;
  }

  /**
   * Method to get the user assigned group id list
   */
  getAssignedGroupIdList(): string[] {
    let groupIdList: string[] = [];
    this.loggedInUserInfo.myGroups.forEach(myGroup => {
      groupIdList.push(myGroup.id + "");
    });
    return groupIdList;
  }

  /**
   * Method to get the top contacts
   */
  getTopContacts(): any[] {
    let topContacts: any[] = [];
    const favContacts = [...this.favContacts];
    favContacts.forEach((element, index) => {
      topContacts.push({
        name: element.name,
        email: element.email,
        mapToGroupsName: element.mapToGroupsName,
        sharedToGroupsName: element.sharedToGroupsName,
        createdBy: element.createdBy,
        index: index,
        contactAddress: element.contactAddress,
        contactNote: element.contactNote,
        contactPhNumber: element.contactPhNumber
      });
    });
    return topContacts;
  }

  /**
   * Method to get Currently loggedIn user
   */
  getLoggedInUser() : string {
    return this.loggedInUserInfo.userId;
  }

  /**
   * Method to get Currently loggedIn username
   */
  getLoggedInUserName() : string {
    return this.loggedInUserInfo.userName;
  }

  /**
   * Method to fetch the dateformate.
   */
  getDateFormate(): string {
    let dateFormat = '';
    this.loggedInUserInfo.preferences.map(pref => {
      if (pref.key === DATE_FORMATTER_SELECT_ID) {
        dateFormat = pref.value;
      }
    });
    return dateFormat;
  }

  /**
   * Symphony Entitlement Check.
   */
  private symphonyEntitlement: boolean;
  setSymphonyEntitlement(enable: boolean) {
    this.symphonyEntitlement = enable;
  }

  getSymphonyEntitlement(): boolean {
    return this.symphonyEntitlement;
  }

  /**
   * Method to get the Exchange folder details.
   */
  getExchangeFolderDetails(): ExchangeFolder[] {
    let exchangeFolders: ExchangeFolder[] = [];
    let personalMailboxGrpId: number = this.loggedInUserInfo.personalMailboxGrpId;
    if(personalMailboxGrpId){
      let groupDetails: MyGroup[] = this.loggedInUserInfo.myGroups.filter(group => personalMailboxGrpId === group.id);
      if (groupDetails.length > 0) {
        let group = groupDetails[0];
        exchangeFolders = [...group.exchFolders];
      }
    }
    return exchangeFolders;
  }
  
  /**
   * Method to get the Symphony Bot Name.
   */
  getSymphonyBot(): string {
    let botName: string = '';
    try {
      if (this.loggedInUserInfo && this.loggedInUserInfo.symphonyConfig) {
        const symphonyConfig: SymphonyConfig = this.loggedInUserInfo.symphonyConfig;
        botName = symphonyConfig.symphonyBot ? symphonyConfig.symphonyBot : botName;
      }
    } catch (e) {
      console.error("Exception in QMA 2 while getSymphonyBot() : ", e);
    }
    return botName;
  }

  /**
   * Method to reset session settings.
   */
  restoreUserSession(): Observable<any> {
    return this.httpClient.post<any>(this.baseURL + '/restoreUserSession', httpFormPostOptions);
  }

  /**
   * Method to capture logout session .
   */
  logoutUserSession(): Observable<any> {
    return this.httpClient.post<any>(this.baseURL + '/logoutUserSession', httpFormPostOptions);
  }
  userUsageStats(moduleAsString: any, functionAsString :any): Observable<any> {
    let requestObj = { module: moduleAsString, function:functionAsString};
    return this.httpClient.post(this.baseURL+'/userUsageStats', JSON.stringify(requestObj), httpFormPostOptions);
  }
  /**
   * Method to get the favorite contacts.
   */
  getFavContacts(): MyFavoriteContact[] {
    let myFavoriteContacts: MyFavoriteContact[] = [];
    this.loggedInUserInfo.myFavoriteContacts.forEach(myFavoriteContact => {
      myFavoriteContacts.push(myFavoriteContact);
    });
    return myFavoriteContacts;
  }

  /**
   * Method to get the group roles.
   */
  getGroupRoles(): any {
    return this.loggedInUserInfo.groupRoles;
  }

  /**
   * Method to get the assigned groups.
   */
  getAssignedGroups(): MyGroup[] {
    return this.loggedInUserInfo.myGroups || [];
  }

  /**
   * Method to get the group client contacts.
   */
  getGroupClientContacts(): any[] {
    let clients = [];
    let groupRoles = this.getGroupRoles();

    // Group Roles Map
    let rolesMap = new Map();
    groupRoles.map(role => {
      let isAdmin = role.groupRole.indexOf('Group Admin') > -1;
      rolesMap.set(role.groupId, isAdmin);
    });

    this.getAssignedGroups().forEach(myGroup => {
      // Check if the flag is true for hide client contacts
      let hideClientContacts = myGroup.hideClientList == null ? false : myGroup.hideClientList;
      if (!hideClientContacts) {
        let isAdmin: boolean = rolesMap.get(myGroup.id);
        if (myGroup.recipients) {
          myGroup.recipients.forEach(item => {
            clients.push({ name: item, email: item, groupId: myGroup.id, groupname: myGroup.groupName, isAdmin: isAdmin });
          });
        }
      }
    });
    return clients;
  }

 

  /**
   * Method to check if the group is of Custody.
   * @param groupName 
   */
  isCustodyGroup(groupName: string): boolean {
    let isCustody: boolean = false;
    if (groupName) {
      let group: MyGroup = this.getAssignedGroups().find(grp => groupName === grp.groupName);
      if (group) {
        let heirarchyData: HeirarchyData = group.heirarchyData;
        if (heirarchyData.orgHierarchy) {
          // C170665-845 | AMACAR configuration at L3 ORG
          let orgHierarchy: OrgHierarchy = heirarchyData.orgHierarchy.find(hrchy => "L3" == hrchy.level);
          if (orgHierarchy) {
            let custodyAccounts = this.loggedInUserInfo.custodyAccounts;
            if (custodyAccounts) {
              isCustody = custodyAccounts.includes(orgHierarchy.orgName.trim());
            }
          }
        }
      }
    }
    return isCustody;
  }

  /**
   * Method to check if Amcar Integration is enable or not.
   */
  isAmcarIntegrationEnable(): boolean {
    return this.loggedInUserInfo.enableAmcarIntegration;
  }

  /**
   * Assigned Group Meta Data Map.
   */
  requestTypeMap = new Map<string, string[]>();
  processingRegionMap = new Map<string, string[]>();
  rootCauseMap = new Map<string, string[]>();
  tagsMap = new Map<string, string[]>();
  reqTypeRootCauseMap = new Map<string, any>();
  recipientsMap = new Map<string, any[]>();
  userAssignedGroup: string[] = [];
  taskizeAssignedGroup: string[] = [];
  userSignaturesMap = new Map<string, string>();
  templatesMap = new Map<string, Template[]>();

  /**
   * Method to set the assigned group meta data.
   * 
   * @param assignedGroups 
   */
  setMetaDataMap(assignedGroups: MyGroup[]): void {

    // Clear all the metadata maps
    this.requestTypeMap.clear();
    this.rootCauseMap.clear();
    this.reqTypeRootCauseMap.clear();
    this.processingRegionMap.clear();
    this.tagsMap.clear();
    this.recipientsMap.clear();
    this.userAssignedGroup = [];
    this.taskizeAssignedGroup = [];
    this.userSignaturesMap.clear();
    this.templatesMap.clear();

    assignedGroups.forEach(myGroup => {
      this.requestTypeMap.set(myGroup.groupName, myGroup.requestTypes);
      this.processingRegionMap.set(myGroup.groupName, myGroup.processingRegionList);
      this.rootCauseMap.set(myGroup.groupName, myGroup.rootCauseList);
      this.tagsMap.set(myGroup.groupName, myGroup.tags);
      if (myGroup.streamId && myGroup.streamId == 'Taskize') {
        this.taskizeAssignedGroup.push(myGroup.groupName)
      } else {
        this.userAssignedGroup.push(myGroup.groupName);
      }

      // C170665-10 | Populate the map of group with request type and root cause mapping.
      if (myGroup.requestTypeRootCauseFilter) {
        this.reqTypeRootCauseMap.set(myGroup.groupName, myGroup.requestTypeRootCauseMapping);
      }

      // C153176-5496 | Get the recipients of the group.
      let recipents = null;
      // Populate the recipients only when predictiveRecipients flag is true for the group.
      if (myGroup.predictiveRecipients && myGroup.recipients) {
        recipents = myGroup.recipients.map(rec => ({ text: rec, value: rec, email: rec }));
        this.recipientsMap.set(myGroup.groupName, recipents);
      }

      if (myGroup.templates && myGroup.templates.length > 0) {
        // for (let i = 0; i < myGroup.templates.length; i++) {
        //   this.commonTemplates.add(myGroup.templates[i].templateName);
        // }
        this.templatesMap.set(myGroup.groupName, myGroup.templates);
      }

    });

    // Populate the User Sgnatire Map.
    this.loggedInUserInfo.userSignatures.forEach(userSignature => { this.userSignaturesMap.set(userSignature.name, userSignature.content); });
  }

  /**
   * Method to get the list of request type based on group.
   * @param groupName 
   */
  getRequestType(groupName: string): string[] {
    let requestTypes: string[] = [];
    requestTypes = this.requestTypeMap.get(groupName) || [];
    return requestTypes;
  }

  /**
   * Method to get the list of root cause based on group.
   * @param groupName 
   */
  getRootCause(groupName: string): string[] {
    let rootCauses: string[] = [];
    rootCauses = this.rootCauseMap.get(groupName) || [];
    return rootCauses;
  }

  /**
   * Method to get the list of processing region based on group.
   * @param groupName 
   */
  getProcessingRegion(groupName: string): string[] {
    let processingRegions: string[] = [];
    processingRegions = this.processingRegionMap.get(groupName) || [];
    return processingRegions;
  }

  /**
   * Method to get the list of tags based on group.
   * @param groupName 
   */
  getTags(groupName: string): string[] {
    let tags: string[] = [];
    tags = this.tagsMap.get(groupName) || [];
    return tags;
  }

  /**
   * Method to get the request type root cause mapping object.
   * @param groupName 
   */
  getReqTypeRootCauseMapping(groupName: string): any {
    let requestTypeRootCauseMapping: any = {};
    requestTypeRootCauseMapping = this.reqTypeRootCauseMap.get(groupName) || {};
    return requestTypeRootCauseMapping;
  }

  /**
   * Method to get the group recipients based on group name.
   * @param groupName 
   */
  getGroupRecipients(groupName: string): any[] {
    let groupRecipients: any[] = [];
    groupRecipients = this.recipientsMap.get(groupName) || [];
    return groupRecipients;
  }

  /**
   * Method to get the user signatures map.
   */
  getUserSignatures() : Map<string, string> {
    return this.userSignaturesMap;
  }

  /**
   * Method to get the user assigned group.
   */
  getUserAssignedGroup(): string[] {
    return this.userAssignedGroup || [];
  }

   /**
   * Method to get the taskize assigned group.
   */
  getTaskizeAssignedGroup(): string[] {
    return this.taskizeAssignedGroup || [];
  }

  // All group level recipients.
  allToCcDBUser: AllToCcDBUser[] = [];

  /**
   * Method to get all the To and CC DB users.
   */
  getAllToCcDBUsers(): AllToCcDBUser[] {
    let startTime = performance.now();
    if (this.allToCcDBUser && this.allToCcDBUser.length == 0) {
      this.loggedInUserInfo.allToCcDBUsers.forEach(toCcBccUsers => {
        let individualRecipent: AllToCcDBUser = { ...toCcBccUsers };
        individualRecipent.email = individualRecipent.value;
        this.allToCcDBUser.push(individualRecipent);
      });
    }
    let endTime = performance.now();
    console.log(`QMA2.0 Performance :: New Message receipients getAllToCcDBUsers() processing took : ${Math.ceil(endTime - startTime)} miliseconds`);
    return this.allToCcDBUser;
  }

  // All Active Group
  allActiveGroups: AllActiveGroup[] = [];

  /**
   * Method to get the all active groups.
   */
  getAllActiveGroups(): AllActiveGroup[] {
    if (this.allActiveGroups && this.allActiveGroups.length == 0) {
      this.loggedInUserInfo.allActiveGroups.forEach(activeGroup => {
        let group = activeGroup;
        group.toggle = true;
        this.allActiveGroups.push(group);
      });
    }
    return this.allActiveGroups;
  }
}