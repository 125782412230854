<div class="cidFeedbackPanel" style="padding:2%">
    <div>
        <div>
            <textarea [placeholder]="feedbackPlaceholder" rows="4" cols="85" [(ngModel)]= "txtUserComments" id="txtFeedbackPanel"></textarea>
        </div>
        <div style="margin-top: 5px;margin-bottom: 15px;">
            <img src="./assets/Inbox/send-feedback-icon.svg" alt="Send">
            <span style="font-family: 'InterstateBold';color: #021E4A;font-size: 12px;">Give Feedback</span>
        </div>
        <div style="font-family: 'OpenSans';color: #E2302D;font-size: 11px;font-style: italic;margin-bottom: 15px;">
        **Please review the highlighted attributes in the email below and let us know if any were not matched correctly
        by selecting the correct category in the dropdown menu **
        </div>
        <div>
            <div class="d-flex" *ngFor = "let intentFeedback of cidFeedback; let i = index">
                <div class="intent-sentence">
                    {{intentFeedback.sentence}} &nbsp;&nbsp;
                </div>
                <div>
                    <p-dropdown #dropdown_intent appendTo="body" [styleClass]="'prime-dd-override-cid'" placeholder="Select Intent" class="drop-down w-10"[(ngModel)]="intentFeedback.intentOptions" [options]="intentCollection" (onChange)="onIntentValueChange(i,$event)">
                    </p-dropdown>
                </div>
                <div *ngIf ="showOtherIntentBox[i]" style= "padding: 6px;">
                    <input #other_intent type="text" style="font-size: 12px;" placeholder="Other Intent..." pInputText [(ngModel)]="intentFeedback.otherIntentValue" />
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex flex-row justify-content-end">
        <button class="btn search-btn mr-4 pop-up-attch-button-cid" (click)="saveClientIntentFeedback(false)" style="margin-top: 0px;margin-bottom: 0px;">
            Save
        </button>
        <button class="btn search-btn mr-4 pop-up-attch-button-cid pop-up-reject-btn-cid" (click)="cancelClientIntentFeedback()" style="margin-top: 0px;margin-bottom: 0px;">
            Cancel
        </button>
    </div>
</div>